<template>
  <div class="py-3" v-loading="loading">
    <div class="text-right mb-4">
      <el-button class="el-button__primary" size="large" @click="onCreate">
        <span class="text-white">新規作成</span>
      </el-button>
    </div>
    <data-table
      :fields="fields"
      :items="negatives.data"
      :paginate="negatives.meta"
      :current-page="filter.page || 1"
      disable-table-info
      footer-center
      paginate-background
      @page="handleCurrentPage"
      class="el-table-header__black"
    >
      <template #icon>
        <i class="fa-solid fa-face-frown text-2xl" style="color: #483d8b" />
      </template>
      <template #keyword_type="{ row }">
        <el-checkbox
          class="checkbox-comment"
          v-if="row"
          v-model="row.keyword_type"
          disabled
          size="large"
          :false-label="0"
          :true-label="1"
        />
      </template>
      <template #category="{ row }">
        <p>{{ row?.category?.name }}</p>
      </template>
      <template #actions="{ row }">
        <button class="px-6 py-1.5 bg-blue-600 rounded button-shadow text-white" @click="onEdit(row)">
          <span class="text-white">変更</span>
        </button>
        <!-- <el-button class="el-button__primary rounded-sm" type="primary" >
          <span>変更</span>
        </el-button> -->
        <button
          class="px-6 ml-2 py-1.5 bg-button_table rounded button-shadow mr-2 text-white"
          type="info"
          @click="onDelete(row)"
        >
          <span class="text-white">削除</span>
        </button>
      </template>
    </data-table>

    <el-dialog
      v-model="dialogVisible"
      :title="form.id ? '更新' : '新規作成'"
      width="40%"
      draggable
      :key="dialogVisible"
      append-to-body
    >
      <el-form ref="ruleFormRef" :rules="rules" :model="form" label-position="top" @submit.prevent="">
        <el-form-item label="ワード" prop="keyword">
          <el-input v-model="form.keyword" placeholder="ワードを入力" />
        </el-form-item>
        <el-form-item label="ワード/フレーズワード" prop="keyword_type">
          <div class="flex items-center text-sm">
            <el-radio-group v-model="form.keyword_type" class="ml-2 checkbox-comment">
              <el-radio :label="0" size="large">ワード</el-radio>
              <el-radio :label="1" size="large">フレーズワード</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="カテゴリ" prop="comment_category_id">
          <el-select
            v-model="form.comment_category_id"
            clearable
            placeholder="カテゴリを選択"
            style="width: 100%"
            @change="getListGenres"
            size="large"
          >
            <el-option v-for="category in categories" :key="category.id" :label="category.name" :value="category.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="ジャンル" prop="genre">
          <el-select
            v-model="form.genre"
            clearable
            filterable
            placeholder="ジャンルを選択"
            size="large"
            style="width: 100%"
          >
            <el-option v-for="genre in genres" :key="genre" :label="genre" :value="genre" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" size="large">キャンセル</el-button>
          <el-button type="primary" @click="onSubmit" :loading="button_loading" size="large" class="el-button__primary">
            <span class="text-white">{{ form.id ? '更新' : '新規作成' }}</span>
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import DataTable from '@/Components/UI/DataTable.vue'

export default {
  name: 'Negative',
  components: { DataTable },
  props: ['categories', 'activeTab'],
  data() {
    return {
      loading: false,
      fields: [
        { key: 'icon', label: '絵文字（批判的）', width: 150, align: 'center' },
        { key: 'keyword', label: 'ワード', width: 300, align: 'center' },
        { key: 'keyword_type', label: 'フレーズワード', width: 'w-full', align: 'center' },
        { key: 'category', label: 'カテゴリ', width: 'w-full', align: 'center' },
        { key: 'genre', label: 'ジャンル', width: 'w-full', align: 'center' },
        { key: 'actions', label: '操作', width: '300', align: 'center' },
      ],
      negatives: [],
      filter: {
        limit: 10,
        page: 1,
        name: '',
      },
      form: {
        type: 0,
        keyword_type: 0,
        comment_category_id: '',
        type: 1,
      },
      dialogVisible: false,
      button_loading: false,
      genres: [],
      rules: {
        keyword: { required: true, message: 'この項目は必須項目です', trigger: 'change' },
        keyword_type: { required: true, message: 'この項目は必須項目です', trigger: 'change' },
        comment_category_id: { required: true, message: 'この項目は必須項目です', trigger: 'change' },
        genre: { required: true, message: 'この項目は必須項目です', trigger: 'change' },
      },
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    activeTab: function () {
      this.fetchData()
    },
  },

  methods: {
    async fetchData() {
      this.loading = true
      await axios
        .get(
          route('api.comment-keywords.index', {
            limit: this.filter?.limit,
            page: this.filter?.page,
            type: 1,
          })
        )
        .then((res) => {
          this.negatives = res?.data
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    onCreate() {
      this.form = { keyword_type: 0, comment_category_id: null, type: 1 }
      this.genres = []
      this.dialogVisible = !this.dialogVisible
    },
    onEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.genres = row.category.genres
      this.dialogVisible = !this.dialogVisible
    },
    getListGenres(val) {
      this.form.genre = ''
      const category = this.categories.find((v) => v.id === val)
      this.genres = category.genres
      // this.form.genre = this.genres[0]
    },
    onDelete(row) {
      this.$confirm('削除でよろしいでしょうか？', '警告', {
        confirmButtonText: '同意',
        cancelButtonText: 'キャンセル',
        type: 'warning',
      }).then(async () => {
        this.loading = true
        await axios
          .delete(route('api.comment-keywords.destroy', row?.id))
          .then(() => {
            this.$toast.success('ネガテイブワードの削除が完了しました。')
            this.fetchData()
          })
          .catch(() => {
            this.$toast.error('ネガテイブワードの削除が失敗しました。')
          })
      })
    },
    onSubmit() {
      this.$refs.ruleFormRef.validate(async (valid) => {
        if (valid) {
          this.button_loading = true
          if (!this.form.id) {
            await axios
              .post(route('api.comment-keywords.store'), this.form)
              .then(() => {
                this.$toast.success('ネガテイブワードの作成が完了しました。')
                this.dialogVisible = this.button_loading = false
                this.fetchData()
              })
              .catch(() => {
                this.button_loading = false
                this.$toast.error('ネガテイブワードの作成が失敗しました。')
              })
          } else {
            await axios
              .put(route('api.comment-keywords.update', this.form?.id), this.form)
              .then(() => {
                this.$toast.success('ネガテイブワードの更新が完了しました。')
                this.dialogVisible = this.button_loading = false
                this.fetchData()
              })
              .catch(() => {
                this.button_loading = false
                this.$toast.error('ネガテイブワードの更新が失敗しました。')
              })
          }
        } else {
          this.button_loading = false
          return false
        }
      })
    },
    handleCurrentPage(value) {
      this.filter.page = value
      this.fetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.el-button__primary {
  background-color: #5264cd;
  border: 1px solid #5264cd;
}

.el-button__primary span {
  color: white !important;
}

.el-button__info {
  background-color: #be3d8f;
  border: 1px solid #be3d8f;
}

.el-button__info span {
  color: white !important;
}
</style>
