import { TOP_HASHTAG_IMAGE, WORST_HASHTAG_IMAGE, EMPTY_IMG } from '../Mixins/media.js'
import { TABLE_SLIDE9_1 } from '../Mixins/enums.js'

export default {
  methods: {
    genSlideFollower(pptx, dataSlideAccount, text) {
      const topFollower = dataSlideAccount.topFollower
      const bestFollower = dataSlideAccount.bestFollower

      const slideHashtag = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideHashtag.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      slideHashtag.addText('グループ内フォロワー増加ランキング', {
        x: 2.45,
        y: 0.25,
        w: 8.01,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 22,
      })
      slideHashtag.addText('テキストをここに記載します。テキストをここに記載します。テキストをここに記載します', {
        x: 0.36,
        y: 1.03,
        w: 7.4,
        h: 0.3,
        align: 'left',
        color: '808080',
        fontSize: 12,
      })
      slideHashtag.addImage({ path: TOP_HASHTAG_IMAGE, x: 2.39, y: 1.56, w: 0.72, h: 0.72 })
      slideHashtag.addText('ベストアカウント', {
        x: 3.09,
        y: 2.0,
        w: 1.97,
        h: 0.31,
        align: 'center',
        bold: true,
        color: '303030',
        fontSize: 12.6,
      })
      if (topFollower?.length > 0) {
        slideHashtag.addShape(pptx.shapes.RECTANGLE, {
          x: 0.63,
          y: 2.3,
          w: 4.24,
          h: 1.31,
          fill: { color: 'e7289e' },
        })
        slideHashtag.addShape(pptx.shapes.RIGHT_TRIANGLE, {
          x: 0.63,
          y: 3.61,
          w: 0.2,
          h: 0.2,
          fill: { color: '8c4905' },
          rotate: 180,
        })
        slideHashtag.addShape(pptx.shapes.RIGHT_TRIANGLE, {
          x: 4.67,
          y: 3.61,
          w: 0.2,
          h: 0.2,
          fill: { color: '8c4905' },
          rotate: 90,
        })
        slideHashtag.addTable(TABLE_SLIDE9_1, { x: 0.82, y: 3.6, w: 3.87, h: 3.7 })

        slideHashtag.addText(
          '1位:' +
            (topFollower[0]
              ? topFollower[0]?.account.username
                ? topFollower[0]?.account.username
                : 'アカウント名'
              : 'アカウント名'),
          {
            x: 0.8,
            y: 2.54,
            w: 2.75,
            h: 0.37,
            align: 'center',
            bold: true,
            color: 'ffffff',
            fontSize: 16.3,
          }
        )
        slideHashtag.addText('こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。', {
          x: 0.99,
          y: 2.92,
          w: 2.19,
          h: 0.59,
          align: 'left',
          color: 'ffffff',
          fontSize: 8,
        })
        slideHashtag.addText(
          '増減数\n' + (topFollower[0] ? (topFollower[0]?.followers ? topFollower[0]?.followers : 0) : 0),
          {
            shape: pptx.shapes.ROUNDED_RECTANGLE,
            x: 3.55,
            y: 2.67,
            w: 1.04,
            h: 0.58,
            fill: { color: 'ffffff' },
            rectRadius: 0.1,
            bold: true,
            align: 'center',
            valign: 'middle',
            fontSize: 10,
          }
        )
        if (topFollower[1]) {
          slideHashtag.addText(
            '2位:' +
              (topFollower[1]
                ? topFollower[1]?.account.username
                  ? topFollower[1]?.account.username
                  : 'アカウント名'
                : 'アカウント名'),
            {
              x: 0.8,
              y: 3.8,
              w: 2.6,
              h: 0.31,
              align: 'center',
              bold: true,
              color: '303030',
              fontSize: 12.6,
            }
          )
          slideHashtag.addText('こちらに簡単な説明を記載してください。', {
            x: 0.99,
            y: 4.1,
            w: 2.19,
            h: 0.24,
            align: 'left',
            color: '595959',
            fontSize: 7.3,
          })
          slideHashtag.addText(
            '増減数\n' + (topFollower[1] ? (topFollower[1]?.followers ? topFollower[1]?.followers : 0) : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 3.55,
              y: 3.8,
              w: 1.04,
              h: 0.58,
              fill: { color: 'ffffff' },
              rectRadius: 0.1,
              bold: true,
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }
        if (topFollower[2]) {
          slideHashtag.addText(
            '3位:' +
              (topFollower[2]
                ? topFollower[2]?.account.username
                  ? topFollower[2]?.account.username
                  : 'アカウント名'
                : 'アカウント名'),
            {
              x: 0.8,
              y: 4.7,
              w: 2.6,
              h: 0.31,
              align: 'center',
              bold: true,
              color: '303030',
              fontSize: 12.6,
            }
          )
          slideHashtag.addText('こちらに簡単な説明を記載してください。', {
            x: 0.99,
            y: 5.0,
            w: 2.19,
            h: 0.24,
            align: 'left',
            color: '595959',
            fontSize: 7.3,
          })
          slideHashtag.addText(
            '増減数\n' + (topFollower[2] ? (topFollower[2]?.followers ? topFollower[2]?.followers : 0) : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 3.55,
              y: 4.7,
              w: 1.04,
              h: 0.58,
              fill: { color: 'ffffff' },
              rectRadius: 0.1,
              bold: true,
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }
        if (topFollower[3]) {
          slideHashtag.addText(
            '4位:' +
              (topFollower[3]
                ? topFollower[3]?.account.username
                  ? topFollower[3]?.account.username
                  : 'アカウント名'
                : 'アカウント名'),
            {
              x: 0.8,
              y: 5.6,
              w: 2.6,
              h: 0.31,
              align: 'center',
              bold: true,
              color: '303030',
              fontSize: 12.6,
            }
          )
          slideHashtag.addText('こちらに簡単な説明を記載してください。', {
            x: 0.99,
            y: 5.9,
            w: 2.19,
            h: 0.24,
            align: 'left',
            color: '595959',
            fontSize: 7.3,
          })
          slideHashtag.addText(
            '増減数\n' + (topFollower[3] ? (topFollower[3]?.followers ? topFollower[3]?.followers : 0) : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 3.55,
              y: 5.6,
              w: 1.04,
              h: 0.58,
              fill: { color: 'ffffff' },
              rectRadius: 0.1,
              bold: true,
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }
        if (topFollower[4]) {
          slideHashtag.addText(
            '5位:' +
              (topFollower[4]
                ? topFollower[4]?.account.username
                  ? topFollower[4]?.account.username
                  : 'アカウント名'
                : 'アカウント名'),
            {
              x: 0.8,
              y: 6.5,
              w: 2.6,
              h: 0.31,
              align: 'center',
              bold: true,
              color: '303030',
              fontSize: 12.6,
            }
          )
          slideHashtag.addText('こちらに簡単な説明を記載してください。', {
            x: 0.99,
            y: 6.8,
            w: 2.19,
            h: 0.24,
            align: 'left',
            color: '595959',
            fontSize: 7.3,
          })
          slideHashtag.addText(
            '増減数\n' + (topFollower[4] ? (topFollower[4]?.followers ? topFollower[4]?.followers : 0) : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 3.55,
              y: 6.5,
              w: 1.04,
              h: 0.58,
              fill: { color: 'ffffff' },
              rectRadius: 0.1,
              bold: true,
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )

          slideHashtag.addShape(pptx.shapes.LINE, {
            x: 5.08,
            y: 2.06,
            w: 0.0,
            h: 5.25,
            line: { color: '595959', width: 0.5 },
          })
        }
      } else {
        slideHashtag.addImage({ path: EMPTY_IMG, x: 0.88, y: 3.2, w: 3.75, h: 3.75 })
      }
      slideHashtag.addImage({ path: WORST_HASHTAG_IMAGE, x: 5.28, y: 1.91, w: 0.59, h: 0.59 })
      slideHashtag.addText('ワーストアカウント', {
        x: 5.87,
        y: 1.99,
        h: 0.31,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 12.6,
      })

      if (bestFollower?.length > 0) {
        slideHashtag.addText('1', {
          x: 5.37,
          y: 2.57,
          w: 0.46,
          h: 0.55,
          align: 'center',
          bold: true,
          color: '002060',
          fontSize: 27.3,
        })
        slideHashtag.addShape(pptx.shapes.LINE, {
          x: 5.6,
          y: 3.18,
          w: 0.0,
          h: 0.49,
          line: { color: '7030a0', width: 6 },
        })
        slideHashtag.addText(
          bestFollower[0]
            ? bestFollower[0]?.account.username
              ? bestFollower[0]?.account.username
              : 'アカウント名'
            : 'アカウント名',
          {
            x: 5.97,
            y: 2.69,
            h: 0.31,
            align: 'left',
            bold: true,
            color: '303030',
            fontSize: 12.6,
          }
        )
        slideHashtag.addText(
          'こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。',
          { x: 5.97, y: 3.0, w: 3.1, h: 0.59, align: 'left', color: '595959', fontSize: 8 }
        )
        slideHashtag.addText(
          '増減数\n' + (bestFollower[0] ? (bestFollower[0]?.followers ? bestFollower[0]?.followers : 0) : 0),
          {
            shape: pptx.shapes.ROUNDED_RECTANGLE,
            x: 9.28,
            y: 2.92,
            w: 1.04,
            h: 0.58,
            fill: { color: '808080' },
            rectRadius: 0.1,
            bold: true,
            color: 'ffffff',
            align: 'center',
            valign: 'middle',
            fontSize: 10,
          }
        )
        slideHashtag.addText('2', {
          x: 5.41,
          y: 3.67,
          w: 0.39,
          h: 0.44,
          align: 'center',
          bold: true,
          color: '002060',
          fontSize: 20.5,
        })
        slideHashtag.addShape(pptx.shapes.LINE, {
          x: 5.6,
          y: 4.12,
          w: 0.0,
          h: 0.62,
          line: { color: '7030a0', width: 6 },
        })
        if (bestFollower[1]) {
          slideHashtag.addText(
            bestFollower[1]
              ? bestFollower[1]?.account.username
                ? bestFollower[1]?.account.username
                : 'アカウント名'
              : 'アカウント名',
            {
              x: 5.97,
              y: 3.77,
              h: 0.31,
              align: 'left',
              bold: true,
              color: '303030',
              fontSize: 12.6,
            }
          )
          slideHashtag.addText(
            'こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。',
            { x: 5.97, y: 4.01, w: 3.1, h: 0.59, align: 'left', color: '595959', fontSize: 8 }
          )
          slideHashtag.addText(
            '増減数\n' + (bestFollower[1] ? (bestFollower[1]?.followers ? bestFollower[1]?.followers : 0) : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 9.28,
              y: 3.92,
              w: 1.04,
              h: 0.58,
              fill: { color: '808080' },
              rectRadius: 0.1,
              bold: true,
              color: 'ffffff',
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }

        slideHashtag.addText('3', {
          x: 5.41,
          y: 4.74,
          w: 0.39,
          h: 0.44,
          align: 'center',
          bold: true,
          color: '002060',
          fontSize: 20.5,
        })
        slideHashtag.addShape(pptx.shapes.LINE, {
          x: 5.6,
          y: 5.19,
          w: 0.0,
          h: 0.56,
          line: { color: '7030a0', width: 6 },
        })
        if (bestFollower[2]) {
          slideHashtag.addText(
            bestFollower[2]
              ? bestFollower[2]?.account.username
                ? bestFollower[2]?.account.username
                : 'アカウント名'
              : 'アカウント名',
            {
              x: 5.97,
              y: 4.77,
              h: 0.31,
              align: 'left',
              bold: true,
              color: '303030',
              fontSize: 12.6,
            }
          )
          slideHashtag.addText(
            'こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。',
            { x: 5.97, y: 5, w: 3.1, h: 0.59, align: 'left', color: '595959', fontSize: 8 }
          )
          slideHashtag.addText(
            '増減数\n' + (bestFollower[2] ? (bestFollower[2]?.followers ? bestFollower[2]?.followers : 0) : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 9.28,
              y: 4.82,
              w: 1.04,
              h: 0.58,
              fill: { color: '808080' },
              rectRadius: 0.1,
              bold: true,
              color: 'ffffff',
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }

        slideHashtag.addText('4', {
          x: 5.41,
          y: 5.74,
          w: 0.39,
          h: 0.44,
          align: 'center',
          bold: true,
          color: '002060',
          fontSize: 20.5,
        })
        slideHashtag.addShape(pptx.shapes.LINE, {
          x: 5.6,
          y: 6.13,
          w: 0.0,
          h: 0.52,
          line: { color: '7030a0', width: 6 },
        })
        if (bestFollower[3]) {
          slideHashtag.addText(
            bestFollower[3]
              ? bestFollower[3]?.account.username
                ? bestFollower[3]?.account.username
                : 'アカウント名'
              : 'アカウント名',
            {
              x: 5.97,
              y: 5.77,
              h: 0.31,
              align: 'left',
              bold: true,
              color: '303030',
              fontSize: 12.6,
            }
          )
          slideHashtag.addText(
            'こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。',
            { x: 5.97, y: 6, w: 3.1, h: 0.59, align: 'left', color: '595959', fontSize: 8 }
          )
          slideHashtag.addText(
            '増減数\n' + (bestFollower[3] ? (bestFollower[3]?.followers ? bestFollower[3]?.followers : 0) : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 9.28,
              y: 5.72,
              w: 1.04,
              h: 0.58,
              fill: { color: '808080' },
              rectRadius: 0.1,
              bold: true,
              color: 'ffffff',
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }
        slideHashtag.addText('5', {
          x: 5.41,
          y: 6.74,
          w: 0.39,
          h: 0.44,
          align: 'center',
          bold: true,
          color: '002060',
          fontSize: 20.5,
        })
        if (bestFollower[4]) {
          slideHashtag.addText(
            bestFollower[4]
              ? bestFollower[4]?.account.username
                ? bestFollower[4]?.account.username
                : 'アカウント名'
              : 'アカウント名',
            {
              x: 5.97,
              y: 6.77,
              h: 0.31,
              align: 'left',
              bold: true,
              color: '303030',
              fontSize: 12.6,
            }
          )
          slideHashtag.addText(
            'こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。',
            { x: 5.97, y: 7, w: 3.1, h: 0.59, align: 'left', color: '595959', fontSize: 8 }
          )
          slideHashtag.addText(
            '増減数\n' + (bestFollower[4] ? (bestFollower[4]?.followers ? bestFollower[4]?.followers : 0) : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 9.28,
              y: 6.62,
              w: 1.04,
              h: 0.58,
              fill: { color: '808080' },
              rectRadius: 0.1,
              bold: true,
              color: 'ffffff',
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }
      } else {
        slideHashtag.addImage({ path: EMPTY_IMG, x: 6.39, y: 3.2, w: 3.75, h: 3.75 })
      }

      return slideHashtag
    },
  },
}
