<template>
  <div class="fixed top-0 left-0 w-full z-40">
    <Transition name="bounce">
      <div v-if="active" class="fixed top-1/4 left-1/2 -translate-x-1/2 z-50 px-9 pb-8.5 pt-6 bg-white">
        <svg
          @click="closePopup"
          class="ml-auto hover:cursor-pointer"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.9799 2.71099L19.8583 0.589355L11.447 9.00064L3.03569 0.589355L0.914062 2.71099L9.32535 11.1223L0.914062 19.5336L3.03569 21.6552L11.447 13.2439L19.8583 21.6552L21.9799 19.5336L13.5686 11.1223L21.9799 2.71099Z"
            fill="#8D8E92"
          />
        </svg>

        <h3 class="flex items-center justify-center text-2xl text-alert font-semibold gap-4.5 mt-5">
          <img src="/images/trash.png" alt="" />
          プランを削除する
        </h3>
        <p class="text-lg text-center text-item_link mt-8.5 mb-7">削除よろしいでしょうか？</p>
        <div class="flex gap-3">
          <button @click="closePopup" class="bg-blue-600 text-white py-2 rounded button-shadow mx-1 px-8.5 whitespace-nowrap">
            キャンセル
          </button>
          <button @click="handleUserDelete" class="bg-alert text-white py-2 rounded button-shadow mx-1 flex-1 px-16 whitespace-nowrap">
            同意
          </button>
        </div>
      </div>
    </Transition>
    <Transition name="fade">
      <div v-if="active" class="h-screen overlay w-full absolute z-40 bg-black opacity-70 top-0 left-0"></div>
    </Transition>
  </div>
</template>

<script>
export default {
  props: ['type', 'active'],
  methods: {
    closePopup() {
      this.$emit('updateStatusPopup', false)
    },
    handleUserDelete() {
      this.$emit('handleUserDelete')
    },
  },
}
</script>

<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 0.4s;
}

.bounce-leave-active {
  animation: bounce-in 0.2s reverse;
}

@keyframes bounce-in {
  0% {
    top: 0;
    left: 50%;
  }

  100% {
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
