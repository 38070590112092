<template>
<div class="linechart-contain" :class="{'chart-scroll__pdf' : isExport, 'chart--scroll' : !isExport}">
  <Bar :chart-data="chartData" :chart-options="chartOptions" :plugins="plugins" :id="canvasID"/>
</div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { CategoryScale } from 'chart.js'
import {
  Chart,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from 'chart.js'
import dayjs from 'dayjs'
import { Inertia } from "@inertiajs/inertia";

Chart.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement
)

const legendMargin = {
  id: 'legendMargin',
  beforeInit(chart, legend, options) {
    const fitValue = chart.legend.fit

    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)()
      return (this.height += 40)
    }
  },
}

export default {
  name: 'BarLineChart',
  components: { Bar },
  props: {
    dateRange: { type: Array, default: () => [] },
    chartData: { type: Object, default: () => {} },
    chartOptions: { type: Object, default: () => {} },
    canvasID: { type: String}
  },
  data() {
    return {
      plugins: [legendMargin],
    }
  },
    computed: {
    isExport() {
      return Inertia?.page?.url === '/export/report-preview'
    }
  },
}
</script>

<style lang="scss">
.linechart-contain{
    #bar-chart{
        height: 400px !important;
    }
}
  .chart-scroll__pdf {
    padding: 1rem 0 !important;
    width: 100%;
    overflow-x: auto;
    & > div {
      width: 1400px;
      height: 400px;
    }
  }
@media (max-width: 640px) {
.linechart-contain{
    #bar-chart{
        height: 400px !important;
        }
    }
  .chart--scroll {
    padding: 1rem 0 !important;
    width: 100%;
    overflow-x: scroll;
    & > div {
      width: 800px;
      height: 400px;
    }
  }
}
</style>
