<template>
  <DrashBoard>
    <LinkInstagram v-if="!showLinkInstagram" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li>
          <Link :href="route('admin.hashtag-analysis.index')" class="font-normal">指定ハッシュタグ一覧</Link>
        </li>
        <span class="font-normal text-notice_date mx-2">></span>
        <li>
          <Link :href="route('admin.hashtag-analysis.detail', igHashtagId)" class="font-normal">
            指定ハッシュタグ分析
          </Link>
        </li>
        <span class="font-normal text-notice_date mx-2">></span>
        <li>
          <Link :href="route('admin.hashtag-analysis.ranking', igHashtagId)" class="font-normal breakcumb-active">
            同時使用ハッシュタグランキング
          </Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-2 relative pb-5 active">同時使用ハッシュタグランキング</li>
        </ul>
      </div>
    </template>
    <div class="bg-background pb-20" v-loading="loading">
      <UserNav />
      <div class="statistic md:px-10 sm:px-5">
        <p class="text-button_pink font-semibold pt-5 mb-[-20px] md:mb-5 text-3sm">同時使用ハッシュタグランキング</p>

        <!-- Search -->
        <div
          class="hashtag-search-box flex md:flex-row sm:flex-col flex-wrap justify-between gap-y-2 xl:gap-y-4 text-2sm"
        >
          <div class="flex lg:mb-0 lg:justify-start sm:justify-between">
            <el-input
              :clearable="true"
              @keyup.enter="fetchData()"
              @change="fetchData()"
              placeholder="検索キーワードを入力してください"
              type="text"
              v-model="filter.name"
              class="md:!w-72"
              size="large"
            />
            <button
              type="button"
              @click.prevent="fetchData"
              class="bg-blue-600 h-10 px-7 whitespace-nowrap rounded text-white md:ml-5 md:mr-5 sm:ml-5 sm:mr-0"
            >
              検索
            </button>
          </div>
          <div class="text-button_pink text-3sm my-3 text-center sm:-order-1 md:order-none">{{ hashtagName }}</div>
          <div class="date-picker-custom flex">
            <div class="">
              <el-date-picker
                v-model="dateRange"
                :type="'daterange'"
                size="large"
                range-separator="-"
                start-placeholder="Start date"
                end-placeholder="End date"
                :disabled-date="disabledDate"
                :clearable="false"
                @change="fetchData"
                popper-class="customDatePickerRanking"
              />
            </div>

            <button
              type="button"
              @click="exportCsv()"
              class="bg-blue-600 h-10 md:my-0 sm:my-4 px-5 rounded text-white md:ml-6 sm:ml-0"
            >
              CSVダウンロードする
            </button>
          </div>
        </div>

        <!-- Table -->
        <div class="section-analysis-info mt-7">
          <data-table
            :fields="fields"
            :items="dataHashtags.data"
            :paginate="dataHashtags.meta"
            :current-page="filter.page || 1"
            disable-table-info
            footer-center
            paginate-background
            @page="handleCurrentPage"
            class="el-table-header__black mt-5"
          >
            <template #hashtag="{ row }">
              <a
                :href="`${URL_INSTAGRAM}/${row?.key?.replace('#', '')}`"
                class="text-button_pink underline font-medium text-[13px] cursor-pointer"
                target="_blank"
              >
                {{ row?.key }}
              </a>
            </template>
            <template #posts_count="{ row }">
              <p>{{ formatToInteger(row?.doc_count) }}</p>
            </template>
            <template #likes_count="{ row }">
              <p>{{ formatToInteger(row?.likes_count?.value) }}</p>
            </template>
            <template #comments_count="{ row }">
              <p>{{ formatToInteger(row?.comments_count?.value) }}</p>
            </template>
          </data-table>
        </div>

        <div class="flex items-center justify-center w-full mt-10">
          <button
            type="button"
            class="bg-pink-400 text-white w-72 lg:px-10 sm:px-5 py-4 rounded-sm button-shadow text-btn-submit"
            @click="$inertia.visit(route('admin.hashtag-analysis.detail', igHashtagId))"
          >
            戻る
          </button>
        </div>
      </div>
    </div>
  </DrashBoard>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import DataTable from '@/Components/UI/DataTable.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import dayjs from 'dayjs'
import { indexOf } from 'lodash-es'

export default {
  name: 'HashtagAnalysisRanking',
  components: { DrashBoard, UserNav, DataTable, Link, BreadCrumb, LinkInstagram },
  props: ['igHashtagId', 'hashtagName'],
  data() {
    const since = new Date(new Date().setDate(new Date().getDate() - 29))
    const until = new Date(new Date().setDate(new Date().getDate()))

    return {
      loading: false,
      showLinkInstagram: true,
      URL_INSTAGRAM: 'https://www.instagram.com/explore/tags',
      filter: {
        page: 1,
        name: '',
        limit: 10,
      },
      dateRange: [since, until],
      isCheckOfAccount: false,
      showModalHashtag: false,
      fields: [
        { key: 'hashtag', label: 'ハッシュタグ', align: 'center', minWidth: 300 },
        { key: 'posts_count', label: '使用投稿数', align: 'center', width: 300 },
        { key: 'likes_count', label: '平均いいね数', align: 'center', width: 300 },
        { key: 'comments_count', label: '平均コメント数', align: 'center', width: 300 },
      ],
      dataHashtags: {
        data: [],
        meta: {},
      },
      hashtagList: [],
    }
  },
  created() {
    const url = window.location.href
    const queryString = url?.split('?')[1]?.split('&')
    if (queryString?.length) {
      queryString.forEach((element, index) => {
        const param = element.split('=')
        this.dateRange[index] = element ? param[1] : this.dateRange[index]
      })
    }
    this.fetchData()
  },
  mounted() {
    this.customDatePickerText()
  },
  methods: {
    onInstaSubmitSuccess() {
      this.showLinkInstagram = true
    },
    // fetch data
    async fetchData() {
      const startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
      const endDate = dayjs(this.dateRange[1]).format('YYYY-MM-DD')

      this.loading = true
      await axios
        .get(route('api.analysis-hashtag.rankings', this.igHashtagId), {
          params: {
            start: startDate,
            end: endDate,
          },
        })
        .then((res) => {
          this.hashtagList = res?.data?.hashtagList

          if (this.filter.name) {
            const name = this.filter.name.toLowerCase()
            this.hashtagList = this.hashtagList.filter(function (hashtag) {
              return hashtag.key.toLowerCase().includes(name)
            })
          }

          this.getPaginate()
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },

    // link instagram
    async checkLinkInstagram() {
      const check = await accountAPI.checkInsAccount()
      if (check.data) {
        this.showLinkInstagram = true
      } else {
        this.showLinkInstagram = false
      }
    },

    disabledDate(date) {
      let d = new Date()
      return date > d.setDate(d.getDate() - 1)
    },

    handleCurrentPage(value) {
      this.filter.page = value
      this.getPaginate()
    },

    exportCsv() {
      const startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
      const endDate = dayjs(this.dateRange[1]).format('YYYY-MM-DD')

      const url =
        route('api.ranking-export-csv') +
        '?ig_hashtag_id=' +
        this.igHashtagId +
        '&startDate=' +
        startDate +
        '&endDate=' +
        endDate +
        '&name=' +
        this.filter.name

      window.location.href = url
    },
    getPaginate() {
      const current_page = this.filter.page
      const page_size = this.filter.limit

      this.dataHashtags.meta = {
        current_page: current_page,
        per_page: page_size,
        total: this.hashtagList.length,
      }

      this.dataHashtags.data = this.hashtagList.slice((current_page - 1) * page_size, current_page * page_size)
    },
    customDatePickerText() {
      let datePicker = document.getElementsByClassName('customDatePickerRanking')

      let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[0]
      const preYearNode = document.createTextNode('<<前年')
      btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0])
      btnPreYear.style.cssText += 'margin: 0 5px 0 0'

      let btnPreMonth =
        datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[1]
      const preMonthNode = document.createTextNode('<前月')
      btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0])
      btnPreMonth.style.cssText += 'margin: 0'

      let btnNextYear =
        datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[2]
      const nextYearNode = document.createTextNode('翌年>>')
      btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0])
      btnNextYear.style.cssText += 'margin: 0 0 0 5px'

      let btnNextMonth =
        datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[3]
      const nextMonthNode = document.createTextNode('翌月>')
      btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0])
      btnNextMonth.style.cssText += 'margin: 0'
    },
  },
}
</script>

<style lang="scss" scoped>
.el-button__primary {
  background-color: #5264cd;
  border: 1px solid #5264cd;
}

.el-button__primary span {
  color: white !important;
}

.text-pink {
  &:hover,
  p {
    color: #be3d8f !important;
    text-decoration: underline;
  }
}

.el-link.is-underline:hover:after {
  border-bottom: none !important;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 0 !important;
}
</style>
<style>
.dialog-hashtag .el-dialog__header {
  padding: 0 !important;
  border-bottom: none !important;
  background: white !important;
}
.date-picker-custom .el-date-editor {
  --el-date-editor-daterange-width: 250px;
  --el-date-editor-monthrange-width: 200px;
}
@media (max-width: 640px) {
  .date-picker-custom .el-date-editor {
    --el-date-editor-daterange-width: 100%;
    --el-date-editor-monthrange-width: 100%;
  }
  .date-picker-custom {
    flex-direction: column;
  }
}
</style>
