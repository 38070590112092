<template>
  <dashboard>
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li>
          <Link :href="route('admin.notifications.index')" class="text-13 breakcumb-active">お知らせ管理</Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-2 relative pb-5 active text-13">お知らせ管理</li>
        </ul>
      </div>
    </template>
    <div class="body flex-grow-1 p-8 notification-index bg-background min-h-screen">
      <div class="flex justify-between items-center">
        <h2 class="text-button_pink font-semibold py-5 text-14">お知らせ管理</h2>
        <Link
          :href="route('admin.notifications.create')"
          as="button"
          class="bg-button_profile text-white py-2 px-4 rounded button-shadow mx-1 text-13"
        >
          お知らせを作成
        </Link>
      </div>
      <Table :label="label">
        <template v-slot:first-header>
          <th
            class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2 min-w-[170px]"
          >
            配信日時
          </th>
        </template>
        <template v-slot:end-header>
          <th
            class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2"
            style="width: 120px"
          >
            操作
          </th>
        </template>
        <template v-slot:custom-tbody>
          <tbody class="bg-body_table" v-if="totalData > 0">
            <tr class="text-2sm border-b hover:bg-gray-200" v-for="(notify, index) in dataTable" :key="index">
              <td class="py-2">
                <div class="px-3">
                  <h1 class="whitespace-nowrap">
                    {{ notify.deliveredTime }}
                  </h1>
                  <div v-if="notify.status == 1" class="text-center">
                    <!-- <input
                                            type="radio"
                                            class="text-pink-400"
                                            :name="notify.id"
                                            id="radio1"
                                            @change="handlechange(e)"
                                            checked
                                        /> -->
                    <div class="flex items-center justify-center">
                      <svg
                        viewBox="0 0 18 18"
                        fill="currentColor"
                        class="h-5 w-5 text-pink"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 17.2822C13.4183 17.2822 17 14.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                          fill="white"
                          stroke="#DBDBDB"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                          fill="#BE3D8F"
                        />
                      </svg>
                      <label for="radio1" class="ml-2 text-pink-400">配信済</label>
                    </div>
                  </div>
                  <div v-else class="text-center">
                    <!-- <input
                                            type="radio"
                                            class="text-indigo-400"
                                            :name="notify.id"
                                            id="radio1"
                                            checked
                                        /> -->
                    <div class="flex items-center justify-center">
                      <svg
                        viewBox="0 0 18 18"
                        fill="currentColor"
                        class="h-5 w-5 text-pink"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                          fill="white"
                          stroke="#DBDBDB"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                          fill="#5264cd"
                        />
                      </svg>
                      <label class="ml-2 text-blue-600">配信予定</label>
                    </div>
                  </div>
                </div>
              </td>

              <td class="py-2">
                <p class="whitespace-nowrap px-2 text-center">{{ notify.label }}</p>
              </td>

              <td class="py-2">
                <p class="text-center wrap-text w-[200px]" v-html="parseString(notify.title)"></p>
              </td>

              <td class="py-2">
                <p class="text-center wrap-text" v-html="parseString(notify.content)"></p>
              </td>

              <td class="py-2">
                <div class="p-5 flex justify-center" style="width: 205px">
                  <Link
                    :href="route('admin.notifications.edit', notify.id)"
                    class="bg-blue-600 text-white py-2 px-5 rounded-sm whitespace-nowrap"
                  >
                    編集
                  </Link>
                  <button
                    @click="deleteObj(notify.id)"
                    class="py-2 px-5 bg-button_table rounded button-shadow text-white ml-3"
                  >
                    削除
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </Table>
      <el-empty description="データはありません" v-if="!totalData > 0" />
      <div class="pt-4 pb-9 flex justify-center">
        <el-pagination
          :page-size="Number(10)"
          :pager-count="5"
          layout="prev, pager, next"
          :total="Number(totalData)"
          :current-page="currentPage || 1"
          @current-change="handleCurrentChange"
          class="is-background"
        />
      </div>
    </div>
  </dashboard>
</template>

<script>
import { ElMessageBox } from 'element-plus'
import Dashboard from '@/Layouts/Authenticated.vue'
import Table from '@/Components/Table'
import Pagination from '@/Components/Pagination'
import { Link } from '@inertiajs/inertia-vue3'
import axios from 'axios'
import { parseTagA } from '@/Helpers/xss'
import BreadCrumb from '@/Components/Breadcrumb.vue'
export default {
  name: 'Index',

  components: {
    Dashboard,
    Table,
    Pagination,
    BreadCrumb,
    Link,
  },

  data() {
    return {
      totalData: 0,
      dataTable: [],
      currentPage: 1,
      totalPage: 1,
      meta: {},
      label: [
        {
          text: 'ラベル',
          key: 'label',
        },

        {
          text: 'タイトル',
          key: 'title',
        },

        {
          text: '本文',
          key: 'content',
          minWidth: 150,
        },
      ],
    }
  },

  created() {
    this.getListNotification()
  },

  methods: {
    deleteObj(id) {
      ElMessageBox.confirm(this.$t('do you want to delete this notification'), '警告', {
        confirmButtonText: '同意',
        cancelButtonText: 'キャンセル',
        type: 'warning',
      }).then(async () => {
        axios
          .delete(route('api.delivery_notices.destroy', id))
          .then((res) => {
            if (res?.data?.status === 1) {
              this.$toast.error(res?.data?.msg)
            } else if (res?.data?.status === 2) {
              this.$toast.error(this.$t('something went wrong'))
            } else {
              this.$toast.success(this.$t('delete notification successfully'))
              this.$inertia.visit(route('admin.notifications.index'))
            }
          })
          .catch(() => {
            this.$toast.error(this.$t('something went wrong'))
          })
      })
    },
    handleCurrentChange(value) {
      this.currentPage = value
    },
    parseString(str) {
      return parseTagA(str)
    },
    getListNotification(page = null) {
      axios
        .get('/api/delivery_notices?page=' + (page ? page : 1))
        .then((response) => {
          this.totalData = response.data.meta.total
          this.currentPage = response.data.meta.current_page
          this.totalPage = response.data.meta.last_page
          this.dataTable = response.data.data
          this.meta = response.data.meta
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },

  watch: {
    currentPage() {
      this.getListNotification(this.currentPage)
    },
  },
}
</script>
<style>
.notification-index .wrap-text {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  max-height: 48px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding: 0 18px;
}

.break-word-all {
  word-break: break-all;
  overflow-wrap: anywhere;
}
</style>

<style lang="scss">
.notification-index table {
  //   font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;

  tr {
    box-shadow: 0px 1px 0px rgba(169, 169, 169, 0.25);
    border-bottom: solid 1px rgba(169, 169, 169, 0.25);
  }

  tr:last-of-type {
    border-bottom: 0;
  }

  &.light {
    tr {
      background-color: #ffffff;
    }

    thead tr {
      border: 1px solid #e2e2e2;
    }

    th {
      background: #ffffff;
      color: #414141;
      border: 0;
    }
  }
}
.el-button--primary {
  background-color: #5264cd !important;
  border: 1px solid #5264cd !important;
}
.el-button--primary span {
  color: #ffffff !important;
}
.text-13 {
  font-size: 13px !important;
}
.text-14 {
  font-size: 14px !important;
}
</style>
