<template>
  <div
    v-if="account != null"
    class="flex items-center justify-between md:py-8.5 sm:py-3 border-b border-graylight bg-background"
  >
    <div class="flex items-center ml-5 md:ml-12 mr-14.5 user__nav">
      <img
        v-if="account.profile_picture_url != null"
        class="md:w-24 sm:w-12 h-auto rounded-full mr-5.5 border-2 border-pink"
        :src="account.profile_picture_url"
        alt=""
      />
      <p
        v-else
        class="flex justify-center items-center object-cover md:w-24 md:h-24 sm:w-12 sm:h-12 rounded-full border-solid border-2 border-pink text-2xl mr-2"
      >
        {{ account?.name?.[0] }}
      </p>

      <div class="mr-5">
        <span class="name-account">@{{ account.username }}</span>
        <p class="text-gray info-desc" v-if="userDesc">アカウント名：@kigeki_inc</p>
      </div>
      <div v-if="status">
        <p class="ml-8 text-bluelight flex items-center mb-3">
          <span class="mutil-border w-2.5 h-2.5 bg-bluelight block mr-3 rounded-full"></span>
          契約中
        </p>
        <p class="text-gray">今月のログイン回数：3</p>
      </div>
    </div>
    <div v-if="action" class="flex items-center">
      <span>アカウント情報を</span>
      <button class="bg-black py-4 px-8.5 text-white mr-2 ml-5 button-shadow rounded">PDF出力</button>
      <button class="bg-black py-4 px-8.5 text-white button-shadow rounded">CSV出力</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['action', 'status', 'userDesc'],
  computed: {
    account() {
      if (this.$page.props.auth?.account !== null) {
        return this.$page.props.auth?.account
      }
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.mutil-border {
  box-shadow: 0 0 0 3px #ffffff, 0 0 0 5px #dbdbdb;
}
.name-account {
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  color: #020202;
}
</style>
