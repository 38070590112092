const chartData = {
  datasets: [
    {
      label: 'Benchmark1',
      data: randomNumber(50, 136),
      type: 'line',
      borderColor: '#5264CD',
      backgroundColor: '#5264CD',
      lineTension: 0,
      borderWidth: 3,
      pointRadius: 3,
      yAxisID: 'A',
    },
    {
      label: 'Benchmark2',
      data: randomNumber(50, 136),
      type: 'line',
      borderColor: '#FF1616',
      backgroundColor: '#FF1616',
      lineTension: 0,
      borderWidth: 3,
      pointRadius: 3,
    },
    {
      label: 'Benchmark3',
      data: randomNumber(50, 136),
      type: 'line',
      borderColor: '#D02D92',
      backgroundColor: '#D02D92',
      lineTension: 0,
      borderWidth: 3,
      pointRadius: 3,
    },
    {
      label: 'Benchmark4',
      data: randomNumber(50, 136),
      type: 'line',
      borderColor: '#00B05C',
      backgroundColor: '#00B05C',
      lineTension: 0,
      borderWidth: 3,
      pointRadius: 3,
    },
  ],
  labels: ['2022-01-20', '2022-01-19', '2022-01-18', '2022-01-17', '2022-01-16', '2022-01-15'],
}
const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  scales: {
    y1: {
      type: 'linear',
      display: false,
      position: 'right',

      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
}

export function randomNumber(min, max) {
  let array = []
  for (let index = 0; index < 6; index++) {
    array.push(Math.floor(Math.random() * (max - min + 1) + min))
  }
  return array
}

const dataAccounts = {
  data: [
    {
      thumbnail_url: '/image/story.png',
      account_name: 'フォロワー数',
      followed: '131,890',
      followed_change: '239',
      followed_change_rate: '0.18',
      post_last_week: '13',
    },
    {
      thumbnail_url: '/image/story.png',
      account_name: 'フォロワー数',
      followed: '131,890',
      followed_change: '239',
      followed_change_rate: '0.18',
      post_last_week: '13',
    },
    {
      thumbnail_url: '/image/story.png',
      account_name: 'フォロワー数',
      followed: '131,890',
      followed_change: '239',
      followed_change_rate: '0.18',
      post_last_week: '13',
    },
    {
      thumbnail_url: '/image/story.png',
      account_name: 'フォロワー数',
      followed: '131,890',
      followed_change: '239',
      followed_change_rate: '0.18',
      post_last_week: '13',
    },
  ],
  links: {
    first: 'http://localhost/api/hashtags?page=1',
    last: 'http://localhost/api/hashtags?page=4',
    next: 'http://localhost/api/hashtags?page=2',
    prev: null,
  },
  meta: {
    current_page: 1,
    from: 1,
    last_page: 4,
    links: [
      { url: null, label: '&laquo; 前へ', active: false },
      { url: 'http://localhost/api/hashtags?page=1', label: '1', active: true },
      { url: 'http://localhost/api/hashtags?page=2', label: '2', active: false },
      { url: 'http://localhost/api/hashtags?page=3', label: '3', active: false },
      { url: 'http://localhost/api/hashtags?page=4', label: '4', active: false },
      { url: 'http://localhost/api/hashtags?page=2', label: '次へ &raquo;', active: false },
    ],
    path: 'http://localhost/api/hashtags',
    per_page: 10,
    to: 10,
    total: 40,
  },
}

export { dataAccounts, chartOptions, chartData }
