<template>
  <nav class="lg:py-5 md:py-4 sm:py-2 md:pl-10 md:pr-0 sm:pl-2 sm:pr-2 breakcumb-container">
    <ol class="breadcrumb flex items-center whitespace-nowrap">
      <li>
        <Link href="/dashboard" class="font-normal text-notice_date">ダッシュボード</Link>
        <span class="font-normal text-notice_date mx-2">></span>
      </li>
      <slot></slot>
    </ol>
    <NotificationCpm />
  </nav>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import NotificationCpm from '@/Components/Notice.vue'
export default {
  components: {
    Link,
    NotificationCpm,
  }
}
</script>

<style scoped>
.breadcrumb li,
.breadcrumb span {
  font-size: 13px;
}
</style>
