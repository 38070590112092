<template>
  <div class="linechart-contain" :class="{'chart--scroll' : !isExport, 'chart--scroll__export': isExport}">
    <Bubble
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
      :id="canvasID"
    />
  </div>
</template>

<script>
import { Bubble } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Inertia } from "@inertiajs/inertia";
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, PointElement, LinearScale, ChartDataLabels)

export default {
  name: 'BubbleChart',
  components: { Bubble },
  props: {
    chartId: { type: String, default: 'bubble-chart' },
    datasetIdKey: { type: String, default: 'label' },
    width: { type: Number, default: 400 },
    height: { type: Number, default: 600 },
    cssClasses: { default: '', type: String },
    styles: { type: Object, default: () => {} },
    plugins: { type: Array, default: () => [] },
    chartData: { type: Object, default: () => {} },
    chartOptions: { type: Object, default: () => {} },
    canvasID: { type: String}
  },
  computed: {
    isExport() {
      return Inertia?.page?.url === '/export/report-preview'
    }
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.linechart-contain {
  #bar-chart {
    height: 300px !important;
  }
}
.chart--scroll__export {
  padding: 1rem 0;
  width: 100%;
  overflow-x: auto;
  & > div {
    width: 1455px;
    height: 500px;
  }
}
@media (max-width: 640px) {
  .linechart-contain {
    #bar-chart {
      height: 350px !important;
    }
  }
  .chart--scroll {
    padding: 1rem 0;
    width: 100%;
    overflow: scroll;
    & > div {
      width: 1300px;
      height: 500px;
    }
  }
}
</style>
