<template>
    <UserNav />
    <div>
        <div class="flex md:flex-row sm:flex-col justify-between md:items-center sm:items-start">
            <div class="flex items-center md:relative">
                <div class="w-72 flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                    <h2 class="text-xl text-button_pink font-semibold text-14">競合アカウント管理</h2>
                    <div class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer" @click="toogleManual">
                        <img :src="suggestIcon" />
                    </div>
                </div>
                <UserManualCompete :isShow="isShowManualCompete" :content="arrContentCompete"
                    @closeManualPopupCompete="toogleManual" />
            </div>
            <div class="flex">
                <p v-if="this.$page.props.auth?.user.roles[0].name !== 'master'" class="mt-10 mr-2">
                    あと {{ resultAccount }} 件登録できます
                </p>
                <button :disabled="disabledAddAccount()" @click="showDialogSearch()"
                    class="bg-blue-600 text-white py-2.5 px-6 rounded block button-shadow text-14">
                    競合アカウントの追加
                </button>
            </div>
        </div>
        <!-- start dialog -->
        <el-dialog title="競合アカウントの追加" v-model="openDialogSearch" append-to-body>
            <div class="w-full">
                <el-form @submit.prevent="submitSearch()" :model="formData" ref="ruleFormRef" class="w-full"
                    :rules="rules">
                    <div class="flex justify-center">
                        <div class="w-full">
                            <el-form-item prop="keyword">
                                <el-input size="large" v-model="formData.keyword" placeholder="URLまたユーザー名を入力してください">
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="">
                            <el-button size="large" class="el-button_search ml-2" @click="submitSearch()">
                                <span class="text-white">検索</span>
                            </el-button>
                        </div>
                    </div>
                </el-form>
            </div>
            <!-- result after search  -->

            <div v-if="resultSearch !== null" class="flex w-full p-2 border-b-2">
                <el-image v-if="resultSearch.profile_picture_url" class="w-1/3 md:w-1/12 h-20"
                    :src="resultSearch.profile_picture_url" :fit="fit" />
                <span v-else
                    class="inline-flex justify-center items-center avatar w-20 h-20 rounded-full header__avatar header__avatar--border border-2 border-solid">
                    {{ resultSearch.name.charAt(0) }}
                </span>
                <div class="w-1/3 md:w-9/12 ml-6">
                    {{ resultSearch.name }}
                </div>
                <div class="w-1/3 md:w-1/12 ml-4 mt-4 text-right">
                    <el-button @click="saveCompetitorAccount(resultSearch)" class="el-button_search" type="primary">
                        選択
                    </el-button>
                </div>
            </div>
            <div class="text-red-500 mt-[-20px] text-[13px]" v-if="resultSearch === null && checkEmpty">
                {{ textEmptyDataSearch }}
            </div>
            <!-- end result after search  -->
        </el-dialog>
        <!-- end dialog -->
        <!-- show data -->
        <div class="mt-10">
            <el-table id="customer-table" class="el-table-header__black" :data="tableData" style="width: 100%">
                <el-table-column align="center" label="画像" width="110">
                    <template #default="scope">
                        <!-- <el-image v-if="scope.row.image_url" class="h-20" :src="scope.row.image_url" fit="fill" /> -->
                        <div v-if="scope.row.image_url" class="w-20 h-20 flex items-center">
                            <el-image class="rounded-full" :src="scope.row.image_url" lazy />
                        </div>
                        <span v-else
                            class="inline-flex justify-center items-center avatar w-20 h-20 rounded-full header__avatar header__avatar--border border-2 border-solid">
                            {{ scope.row.fullname.charAt(0) }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column class="font-bold text-center" align="center" label="名前">
                    <template #default="scope">
                        <div class="flex justify-center">
                            <div class="w-12/12">
                                {{ scope.row.fullname }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="100">
                    <template #default="scope">
                        <div class="">
                            <button @click="showPopupDelete(scope.row.business_account_id)"
                                class="px-5 py-2 bg-button_table rounded button-shadow text-white">
                                削除
                            </button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-empty v-if="tableData.length === 0" description="データはありません" />
        </div>
        <!--end show data -->
        <Panigation v-if="totalPage > 1" :totalPage="totalPage" :currentPage="currentPage"
            @updateCurrentPage="updateCurrentPage" />
    </div>
</template>

<script>
import Panigation from '@/Components/Pagination.vue'
import UserNav from '@/Components/UserNav.vue'
import Notification from '@/Components/Notifications.vue'
import { filterCompetitorAccount, saveCompetitorAccount, deleteCompetitorAccount } from '@/Api/Account'
import UserManualCompete from '@/Components/UserManualCompete.vue'
export default {
    name: 'ManageAccount',
    components: {
        Notification,
        Panigation,
        UserNav,
        UserManualCompete
    },

    props: {
        tableData: {
            type: Object,
            require: true,
        },
        numberAccountCompetitor: {
            type: Number,
            require: true,
        },
        isShowManualCompete: {
            type: Boolean,
        },
        suggestIcon: {
            type: String,
        },
        arrContentCompete: {
            type: Array,
            default: ['競合アカウントを登録することができる画面です。', '画面右上の「競合アカウントの追加」からアカウント情報を追加することができます。', '追加したアカウントは競合アカウント分析で確認することができます。']
        },
    },

    watch: {
        tableData: function () {
            this.NumberAccountCompetitor()
        },
    },
    mounted() {
        this.NumberAccountCompetitor()
    },

    data() {
        return {
            isShowManualCompete: false,
            resultAccount: '',
            openDialogSearch: false,
            deleteSelect: false,
            resultSearch: null,
            totalPage: '',
            currentPage: 1,
            businessAccountId: '',
            textEmptyDataSearch: null,
            checkEmpty: false,
            isShowData: true,
            formData: {
                keyword: '',
            },
            rules: {
                keyword: [
                    {
                        required: true,
                        message: this.$i18n.t('Please input select account'),
                        trigger: 'blur',
                    },
                ],
            },
        }
    },

    methods: {
        toogleManual() {
            this.isShowManualCompete = !this.isShowManualCompete
        },
        NumberAccountCompetitor() {
            const data = this.tableData.filter(function (item) {
                return item.is_owner == 0
            })

            this.resultAccount = this.numberAccountCompetitor - data.length
        },

        disabledAddAccount() {
            if (this.resultAccount == 0 && this.$page.props.auth?.user.roles[0].name !== 'master') {
                return true
            }
        },

        showDialogSearch() {
            this.openDialogSearch = true
            this.formData.keyword = ''
            this.resultSearch = null
            this.textEmptyDataSearch = null
        },
        showPopupDelete(business_account_id) {
            this.deleteSelect = true
            this.businessAccountId = business_account_id
            this.$confirm('この競合アカウントを削除しても宜しいですか。', '警告', {
                confirmButtonText: '同意',
                cancelButtonText: 'キャンセル',
                type: 'warning',
            })
                .then(() => {
                    this.deleteAccount()
                })
                .catch(() => { })
        },

        async deleteAccount() {
            this.deleteSelect = true
            await deleteCompetitorAccount(this.businessAccountId)
                .then(async (res) => {
                    this.$toast.success(this.$t('delete susscess account competitor'))
                })
                .catch(() => { })
            this.deleteSelect = false
            this.$emit('getAllAccountCompetitor', this.currentPage)
            this.NumberAccountCompetitor()
        },

        updateCurrentPage: function (page) {
            this.currentPage = page
            this.$emit('getAllAccountCompetitor', this.currentPage)
        },

        async submitSearch() {
            this.$refs['ruleFormRef'].validate(async (valid) => {
                if (valid) {
                    await filterCompetitorAccount(this.formData.keyword)
                        .then(async (res) => {
                            if (res.data.error != null) {
                                this.textEmptyDataSearch = res.data.error
                                this.checkEmpty = true
                            } else {
                                this.resultSearch = res.data
                            }
                        })
                        .catch(() => { })
                } else {
                    this.checkEmpty = false
                }
            })
        },

        async saveCompetitorAccount(item) {
            await saveCompetitorAccount(item)
                .then(async (res) => {
                    if (res.data.error == null) {
                        this.$toast.success(this.$t('競合アカウントを追加しました。'))
                    } else {
                        this.$toast.error(this.$t(res.data.error))
                    }
                })
                .catch(() => { })
            this.openDialogSearch = false
            this.$emit('getAllAccountCompetitor', this.currentPage)
        },
    },
}
</script>

<style lang="scss" scoped>
.el-button_search {
    background: #5264cd;
}

.header__avatar.header__avatar--border {
    border-color: #bf3d8f;
}
</style>
<style>
.popup-delete .el-overlay .el-overlay-dialog .el-dialog .el-dialog__header {
    background: white;
    border-bottom: none;
}

.el-table_3_column_16 .cell,
.el-table_3_column_17 .cell {
    text-align: center;
}
</style>

<style>
#customer-table .el-table__empty-block {
    display: none;
}

.text-13 {
    font-size: 13px;
}

.text-14 {
    font-size: 14px;
}

.el-table__inner-wrapper::before {
    display: none;
}

.el-form-item__error {
    font-size: 13px;
}
</style>
