import axios from "axios";

export async function getUserList(searchData, currentPage) {
    return await axios.get(route("api.users.index"), {
        params: {
            search: searchData,
            page: currentPage,
        },
    });
}

export async function getUserDetail(id) {
    return await axios.get(route("api.users.show", id));
}

export async function getUserDetailByAccountId(account_id) {
    return await axios.get(route("api.users.show_by_account", account_id));
}

export async function insAccountLink(data, userId) {
    const params = {
        access_token: data.access_token,
        business_id: data.id,
        user_id: userId
    };

    return await axios.post(route("api.accounts.store", params));
}

export async function getListInvoice(user_id) {
    return await axios.get(route("api.invoices.index") + '?user_id=' + user_id);
}


export async function getDataZipCode(zip_code) {
    return await axios.get(route("api.users.get-data-zip-code", zip_code));
}
