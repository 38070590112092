<template>
  <div class="section-comment py-5">
    <div class="bg-white p-5 comment-setting">
      <div class="flex justify-between items-center">
        <h2 class="text-xl text-button_pink font-semibold py-5">コメント設定</h2>
      </div>
      <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-change="handle">
        <el-tab-pane label="カテゴリ" name="category">
          <Category @getCategories="getCategories" />
        </el-tab-pane>
        <el-tab-pane label="ポジティブワード" name="positive">
          <Positive :activeTab="activeTab" :categories="categories" />
        </el-tab-pane>
        <el-tab-pane label="ネガテイブワード" name="negative">
          <Negative :activeTab="activeTab" :categories="categories" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import DataTable from '@/Components/UI/DataTable.vue'
import Category from './Category.vue'
import Positive from './Positive.vue'
import Negative from './Negative.vue'
import axios from 'axios'

export default {
  name: 'CommentIndex',
  components: { DataTable, Category, Positive, Negative },
  data() {
    return {
      activeTab: '',
      activeName: 'category',
      categories: [],
    }
  },
  created() {
    this.getCategories()
  },
  methods: {
    async getCategories() {
      await axios
        .get(route('api.categories.getCategories'))
        .then((res) => {
          this.categories = res?.data
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    handle() {
      this.activeTab = this.activeName
    },
  },
}
</script>

<style lang="scss" scoped>
.el-button__primary {
  background-color: #5264cd;
  border: 1px solid #5264cd;
}

.el-button__primary span {
  color: white !important;
}

.el-select__input {
  --tw-ring-shadow: 0 !important;
}
i.fa-face-frown {
  color: #483d8b;
}
</style>

<style>
.comment-setting .el-tabs--card > .el-tabs__header .el-tabs__item:hover {
  color: #be3d8f;
}

.comment-setting .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #be3d8f !important;
  color: white;
  border: 1px solid #be3d8f !important;
}
.comment-setting .el-tabs__item.is-active {
  color: white;
}

.rounded-sm {
  border-radius: 0.125rem !important;
}
</style>
