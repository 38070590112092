<template>
    <Dashboard>
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li>
                    <Link :href="route('admin.users.index')" class="font-normal breakcumb-active">管理画面</Link>
                </li>
                <span class="font-normal text-notice_date mx-2">></span>
                <li>
                    <Link :href="route('admin.users.create')" class="font-normal breakcumb-active">アカウント登録画面</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-8 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5 active">お知らせ投稿</li>
                </ul>
            </div>
        </template>
        <div class="p-12 bg-background">
            <div class="text-pink-400 font-semibold text-xl">アカウント登録画面</div>
            <!-- form -->
            <form @submit.prevent="submitForm">
                <div class="grid grid-cols-2 gap-40 py-8">
                    <div class="form-left">
                        <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                            <label class="text-black col-span-1">
                                親アカウント名
                                <span class="text-red-500">*</span>
                            </label>
                            <input v-model="form.parent_user_name" disabled type="text"
                                class="col-span-2 h-14 border border-solid border-input rounded" />
                        </div>

                        <div v-if="false" class="form-group align-items mb-4 grid grid-cols-3 items-center">
                            <label class="text-black col-span-1">アカウント名</label>
                            <input type="text" class="col-span-2 h-14 border border-solid border-input rounded" />
                        </div>
                    </div>
                    <div class="form-right">
                        <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                            <label class="text-black col-span-1">会社名</label>
                            <input v-model="form.company" type="text"
                                class="col-span-2 h-14 border border-solid border-input rounded" />
                        </div>
                        <div v-if="false" class="form-group align-items mb-4 grid grid-cols-3 items-center">
                            <label class="text-black col-span-1">連携アカウント名</label>
                            <input type="text" class="col-span-2 h-14 border border-solid border-input rounded" />
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-2 gap-40 py-8">
                    <div class="form-left">
                        <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                            <label class="text-black col-span-1">
                                氏名（姓）
                                <span class="text-red-500">*</span>
                            </label>
                            <input v-model="form.first_name" type="text"
                                class="col-span-2 h-14 border border-solid border-input rounded" />
                        </div>

                        <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                            <label class="text-black col-span-1">
                                電話番号
                                <span class="text-red-500">*</span>
                            </label>
                            <input v-model="form.phone" type="number"
                                class="col-span-2 h-14 border border-solid border-input rounded" />
                        </div>
                    </div>
                    <div class="form-right">
                        <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                            <label class="text-black col-span-1">
                                名前（名）
                                <span class="text-red-500">*</span>
                            </label>
                            <input v-model="form.last_name" type="text"
                                class="col-span-2 h-14 border border-solid border-input rounded" />
                        </div>
                        <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                            <label class="text-black col-span-1">
                                メールアドレス
                                <span class="text-red-500">*</span>
                            </label>
                            <input v-model="form.email" disabled type="email"
                                class="col-span-2 h-14 border border-solid border-input rounded" />
                        </div>
                    </div>
                </div>

                <div class="grid grid-cols-payment py-8">
                    <h1>
                        権限
                        <span class="text-red-500">*</span>
                    </h1>
                    <div class="grid grid-cols-4">
                        <div class="mb-4">
                            <input id="first" type="radio" name="role" v-model="form.role" value="admin_agency"
                                class="mr-2 border-input text-pink-400" />
                            <label for="first">代理店管理者</label>
                        </div>

                        <div class="mb-4">
                            <input id="first1" type="radio" name="role" v-model="form.role" value="admin_oem"
                                class="mr-2 border-input text-pink-400" />
                            <label for="first1">OEM管理者</label>
                        </div>

                        <div class="mb-4">
                            <input id="first2" type="radio" name="role" v-model="form.role" value="admin_enterprise"
                                class="mr-2 border-input text-pink-400" />
                            <label for="first2">エンタープライズ管理者</label>
                        </div>

                        <div>
                            <input id="first3" type="radio" name="role" v-model="form.role" value="user_normal"
                                class="mr-2 border-input text-pink-400" />
                            <label for="first3">直販ユーザー</label>
                        </div>

                        <div>
                            <input id="first4" type="radio" name="role" v-model="form.role" value="user_agency"
                                class="mr-2 border-input text-pink-400" />
                            <label for="first4">代理店ユーザー</label>
                        </div>

                        <div>
                            <input id="first5" type="radio" name="role" v-model="form.role" value="user_oem"
                                class="mr-2 border-input text-pink-400" />
                            <label for="first5">OEMユーザー</label>
                        </div>

                        <div>
                            <input id="first6" type="radio" name="role" v-model="form.role" value="user_enterprise"
                                class="mr-2 border-input text-pink-400" />
                            <label for="first6">エンタープライズユーザー</label>
                        </div>
                    </div>
                </div>

                <!-- change amount plan for role-user -->
                <div class="grid grid-cols-payment py-8" v-if="form.role != null">
                    <h1>プラン金額入力</h1>
                    <div class="flex flex-wrap">
                        <template v-for="(plan, index) in plans" :key="index">
                            <div class="flex w-1/4 items-center mt-3">
                                <input v-model="form.plan_id" :value="plan.id" type="radio"
                                    class="mr-2 border-input text-pink-400" />
                                <h1 class="px-3">{{ plan.name }}</h1>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="flex items-center justify-center my-12 w-full">
                    <input type="checkbox" class="mr-2 border-input text-pink-400" />
                    <label>
                        <Link class="text-pink-400 underline border-input">利用規約</Link>
                        に同意する
                    </label>
                </div>

                <div class="flex items-center justify-center my-12 w-full">
                    <button type="submit" class="text-white bg-button_profile px-20 py-6 rounded-sm">利用規約に同意する</button>
                </div>
            </form>
        </div>
    </Dashboard>
</template>

<script>
import Dashboard from '@/Pages/Dashboard.vue'
import { Link } from '@inertiajs/inertia-vue3'
import axios from 'axios'
import BreadCrumb from '@/Components/Breadcrumb.vue'
export default {
    props: {
        user_id: {
            type: Number,
            required: true,
        },
    },

    components: {
        Dashboard,
        Link,
        BreadCrumb,
    },

    data() {
        return {
            form: {
                last_name: '',
                first_name: '',
                email: '',
                phone: '',
                company: '',
                parent_user_id: null,
                role: null,
                plan_id: null,
                parent_user_name: '',
            },
            plans: [],
        }
    },

    created() {
        this.fetchDataPlan()
        this.fetchDataUser()
    },

    methods: {
        async fetchDataPlan(page = null) {
            await axios
                .get('/api/plans?page=' + (page ? page : 1))
                .then((res) => {
                    this.plans = res.data.data
                })
                .catch((err) => {
                    console.error(err)
                })
        },

        async fetchDataUser() {
            axios
                .get(route('api.users.show', this.user_id))
                .then((res) => {
                    this.form = JSON.parse(JSON.stringify(res.data.data))
                })
                .catch((err) => {
                    console.error(err)
                })
        },
    },
}
</script>

<style>
.button_disabled {
    background: #e3e3e3;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
}
</style>
