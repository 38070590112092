import { IMG_DEMO } from '../Mixins/media.js'
import { EMPTY_IMG } from '../Mixins/media.js'

export default {
  methods: {
    genSlideCreativePost(pptx, items, type, text, title) {
      const slideCreativePost = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideCreativePost.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      if (items?.length > 0) {
        items.forEach((v, index) => {
          let post = []
          for (const [key, value] of Object.entries(v)) {
            post.push((post[`${key}`] = value))
          }
          slideCreativePost.addText(type + (index + 1), {
            x: 0.36 + index * 2.09,
            y: 1.85,
            w: 1.5,
            h: 0.2,
            align: 'left',
            color: type === 'BEST' ? 'e7289e' : '3e63a3',
            fontSize: 11,
          })
          slideCreativePost.addImage({
            x: 0.47 + index * 2.09,
            y: 2.13,
            w: 1.73,
            h: 2.76,
            path: post['thumbnail_url'] + '?time=' + Math.floor(Date.now() / 1000),
            altText: 'top ' + index + ' creative',
          })
          slideCreativePost.addText('投稿日: ' + this.formatTime(post['create_post']), {
            x: 0.36 + index * 2.09,
            y: 5.01,
            w: 2,
            h: 0.25,
            align: 'left',
            color: '303030',
            fontSize: 9,
          })
          slideCreativePost.addText(
            'エンゲージメント数: ' +
              Number(post['likes'] + post['comments'] + post['saved']) +
              '\n' +
              'アカウント名: ' +
              post['username'],
            {
              x: 0.36 + index * 2.09,
              y: 5.5,
              w: 2,
              h: 0.4,
              align: 'left',
              color: '303030',
              fontSize: 9,
            }
          )
        })
      } else {
        slideCreativePost.addImage({ path: EMPTY_IMG, x: 3.75, y: 1.84, w: 3.56, h: 3.56 })
      }
      slideCreativePost.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 8.01,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 22,
      })
      slideCreativePost.addText('■エンゲージメント数 ', {
        x: 0.36,
        y: 1.03,
        w: 7.4,
        h: 0.3,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 12,
      })
      slideCreativePost.addText(type === 'BEST' ? '上位ランキング' : '下位ランキング', {
        x: 2.09,
        y: 1.03,
        w: 7.4,
        h: 0.3,
        align: 'left',
        bold: true,
        color: type === 'BEST' ? 'e7289e' : '3e63a3',
        fontSize: 12,
      })
      if (items?.length > 0) {
        slideCreativePost.addShape(pptx.shapes.LINE, {
          x: 0.44,
          y: 5.28,
          w: 10.02,
          h: 0,
          line: { width: 0.5, color: '595959' },
        })
      }

      slideCreativePost.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.29,
        w: 10.14,
        h: 1.56,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slideCreativePost
    },
    formatTime(date) {
      if (!date) {
        return
      }
      let check = date.slice(4, 5)
      if (check === '-') {
        let listDate = date.slice(0, 10).split('-')
        let listTime = date.slice(11, 19)
        let dateFormat = listDate[0] + '/' + listDate[1] + '/' + listDate[2] + '/' + ' ' + listTime
        return dateFormat
      }
      return date
    },
  },
}
