import { Inertia } from "@inertiajs/inertia"
import { computed } from "vue";

const useProps = _ => {
  return computed( _ => Inertia?.page?.props);
}

export {
  useProps
}
