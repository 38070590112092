export default {
  methods: {
    async genCommentAnalyticPage(doc, postSelectedImg) {
      doc.setFontSize(14) // set font size
      doc.setTextColor(190, 61, 143)
      doc.text('コメント分析', 13, 30)
      doc.text('コメント分析', 13, 30)
      doc.text('コメント分析', 13, 30)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(10)
      let base64Img = String(postSelectedImg + '?time=' + Math.floor(Date.now() / 1000))
      let cmtLable = this.cmtPostCategory == 'normal' ? 'エンゲージメント率' : 'エンゲージメント数'
      doc.autoTable({
        styles: {
          font: 'GenShinGothic',
          fontSize: 8,
          halign: 'center',
          valign: 'middle',
        },
        columnStyles: {
          0: { cellWidth: 26 },
          2: { cellWidth: 20 },
          3: { cellWidth: 20 },
        },
        didDrawCell: function (data) {
          if (data.column.index === 0 && data.cell.section === 'body') {
            var textPos = data.cell
            doc.addImage(base64Img, 'jpeg', textPos.x + 1, textPos.y + 1, 20, 20)
          }
        },
        bodyStyles: { minCellHeight: 26 },

        startY: 45,
        head: [
          [
            '投稿画像',
            'タイプ',
            '投稿内容',
            'ハッシュタグ',
            '投稿日時',
            'いいね数',
            'コメント数',
            '保存数',
            'リーチ数',
            cmtLable,
          ],
        ],
        body: this.postSelected,
      })
      const commentDataMap = JSON.parse(JSON.stringify(this.postSelectedComment))
      if (commentDataMap.length > 0) {
        if (this.postSelectedComment.length > 28) {
          doc.autoTable({
            styles: {
              font: 'GenShinGothic',
              fontSize: 8,
              halign: 'center',
              valign: 'middle',
            },
            columnStyles: {
              0: { cellWidth: 30 },
            },
            startY: 85,
            head: [['ユーザー名', 'ネガポジ', '投稿日時', 'コメント内容']],
            body: commentDataMap.slice(0, 28),
          })
          doc.addPage()
          doc.autoTable({
            styles: {
              font: 'GenShinGothic',
              fontSize: 8,
              halign: 'center',
              valign: 'middle',
            },
            columnStyles: {
              0: { cellWidth: 30 },
            },
            startY: 40,
            head: [['ユーザー名', 'ネガポジ', '投稿日時', 'コメント内容']],
            body: commentDataMap.splice(28),
          })
        } else if (this.postSelectedComment.length <= 28) {
          doc.autoTable({
            styles: {
              font: 'GenShinGothic',
              fontSize: 8,
              halign: 'center',
              valign: 'middle',
            },
            columnStyles: {
              0: { cellWidth: 30 },
            },
            startY: 80,
            head: [['ユーザー名', 'ネガポジ', '投稿日時', 'コメント内容']],
            body: commentDataMap,
          })
        }
      } else {
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 30 },
          },
          startY: 80,
          head: [['ユーザー名', 'ネガポジ', '投稿日時', 'コメント内容']],
          body: [],
        })
        doc.addImage(this.getEmptyImg(), 'PNG', 79, 90)
      }
    },
  },
}
