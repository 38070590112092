<template>
  <div class="fixed w-screen h-full overlay-loading z-50 top-0">
    <div class="loadding"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.overlay-loading {
  background: rgba(196, 196, 196, 0.5);
}
.loadding {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 5px solid #ddd;
  border-left: 5px solid #ffb5fb;
  border-radius: 50%;
  animation: loadding-animation 1.2s linear infinite;
}
@keyframes loadding-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
