import { TOP_HASHTAG_IMAGE, WORST_HASHTAG_IMAGE, EMPTY_IMG } from '../Mixins/media.js'
import { TABLE_SLIDE9_1 } from '../Mixins/enums.js'

export default {
  methods: {
    genSlideHashtag(pptx, dataSlideAccount, text, title) {
      const hastagTop5BestEgement =
        dataSlideAccount.sumData.dataSeletedMonth?.hashtagRankingAccount?.hastagTop5BestEgement
      const hastagTop5WorstEgment =
        dataSlideAccount.sumData.dataSeletedMonth?.hashtagRankingAccount?.hastagTop5WorstEgment

      const slideHashtag = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideHashtag.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      slideHashtag.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 8.01,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 22,
      })
      slideHashtag.addText('テキストをここに記載します。テキストをここに記載します。テキストをここに記載します', {
        x: 0.36,
        y: 1.03,
        w: 7.4,
        h: 0.3,
        align: 'left',
        color: '808080',
        fontSize: 12,
      })
      slideHashtag.addImage({ path: TOP_HASHTAG_IMAGE, x: 2.39, y: 1.56, w: 0.72, h: 0.72 })
      slideHashtag.addText('ベストハッシュタグ', {
        x: 3.09,
        y: 2.0,
        w: 1.97,
        h: 0.31,
        align: 'center',
        bold: true,
        color: '303030',
        fontSize: 12.6,
      })
      if (hastagTop5BestEgement?.length > 0) {
        slideHashtag.addShape(pptx.shapes.RECTANGLE, {
          x: 0.63,
          y: 2.3,
          w: 4.24,
          h: 1.31,
          fill: { color: 'e7289e' },
        })
        slideHashtag.addShape(pptx.shapes.RIGHT_TRIANGLE, {
          x: 0.63,
          y: 3.61,
          w: 0.2,
          h: 0.2,
          fill: { color: '8c4905' },
          rotate: 180,
        })
        slideHashtag.addShape(pptx.shapes.RIGHT_TRIANGLE, {
          x: 4.67,
          y: 3.61,
          w: 0.2,
          h: 0.2,
          fill: { color: '8c4905' },
          rotate: 90,
        })
        slideHashtag.addTable(TABLE_SLIDE9_1, { x: 0.82, y: 3.6, w: 3.87, h: 3.7 })

        slideHashtag.addText(
          '1位:' +
            (hastagTop5BestEgement[0] ? (hastagTop5BestEgement[0]?.name ? hastagTop5BestEgement[0]?.name : '') : ''),
          {
            x: 0.8,
            y: 2.54,
            w: 2.75,
            h: 0.37,
            align: 'left',
            bold: true,
            color: 'ffffff',
            fontSize: 16.3,
          }
        )
        slideHashtag.addText('こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。', {
          x: 0.99,
          y: 2.92,
          w: 2.19,
          h: 0.59,
          align: 'left',
          color: 'ffffff',
          fontSize: 8,
        })
        slideHashtag.addText(
          'リーチ数\n' +
            (hastagTop5BestEgement[0]
              ? hastagTop5BestEgement[0]?.sumReachs
                ? hastagTop5BestEgement[0]?.sumReachs
                : 0
              : 0),
          {
            shape: pptx.shapes.ROUNDED_RECTANGLE,
            x: 3.55,
            y: 2.67,
            w: 1.04,
            h: 0.58,
            fill: { color: 'ffffff' },
            rectRadius: 0.1,
            bold: true,
            align: 'center',
            valign: 'middle',
            fontSize: 10,
          }
        )

        slideHashtag.addText(
          '2位:' +
            (hastagTop5BestEgement[1] ? (hastagTop5BestEgement[1]?.name ? hastagTop5BestEgement[1]?.name : '') : ''),
          {
            x: 0.8,
            y: 3.8,
            w: 2.22,
            h: 0.31,
            align: 'left',
            bold: true,
            color: '303030',
            fontSize: 12.6,
          }
        )
        slideHashtag.addText(hastagTop5BestEgement[1] ? 'こちらに簡単な説明を記載してください。' : '', {
          x: 0.99,
          y: 4.1,
          w: 2.19,
          h: 0.24,
          align: 'left',
          color: '595959',
          fontSize: 7.3,
        })
        if (hastagTop5BestEgement[1]) {
          slideHashtag.addText(
            'リーチ数\n' +
              (hastagTop5BestEgement[1]
                ? hastagTop5BestEgement[1]?.sumReachs
                  ? hastagTop5BestEgement[1]?.sumReachs
                  : 0
                : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 3.55,
              y: 3.8,
              w: 1.04,
              h: 0.58,
              fill: { color: 'ffffff' },
              rectRadius: 0.1,
              bold: true,
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }

        slideHashtag.addText(
          '3位:' +
            (hastagTop5BestEgement[2] ? (hastagTop5BestEgement[2]?.name ? hastagTop5BestEgement[2]?.name : '') : ''),
          {
            x: 0.8,
            y: 4.7,
            w: 2.22,
            h: 0.31,
            align: 'left',
            bold: true,
            color: '303030',
            fontSize: 12.6,
          }
        )
        slideHashtag.addText(hastagTop5BestEgement[2] ? 'こちらに簡単な説明を記載してください。' : '', {
          x: 0.99,
          y: 5.0,
          w: 2.19,
          h: 0.24,
          align: 'left',
          color: '595959',
          fontSize: 7.3,
        })
        if (hastagTop5BestEgement[2]) {
          slideHashtag.addText(
            'リーチ数\n' +
              (hastagTop5BestEgement[2]
                ? hastagTop5BestEgement[2]?.sumReachs
                  ? hastagTop5BestEgement[2]?.sumReachs
                  : 0
                : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 3.55,
              y: 4.7,
              w: 1.04,
              h: 0.58,
              fill: { color: 'ffffff' },
              rectRadius: 0.1,
              bold: true,
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }

        slideHashtag.addText(
          '4位:' +
            (hastagTop5BestEgement[3] ? (hastagTop5BestEgement[3]?.name ? hastagTop5BestEgement[3]?.name : '') : ''),
          {
            x: 0.8,
            y: 5.6,
            w: 2.22,
            h: 0.31,
            align: 'left',
            bold: true,
            color: '303030',
            fontSize: 12.6,
          }
        )
        slideHashtag.addText(hastagTop5BestEgement[3] ? 'こちらに簡単な説明を記載してください。' : '', {
          x: 0.99,
          y: 5.9,
          w: 2.19,
          h: 0.24,
          align: 'left',
          color: '595959',
          fontSize: 7.3,
        })
        if (hastagTop5BestEgement[3]) {
          slideHashtag.addText(
            'リーチ数\n' +
              (hastagTop5BestEgement[3]
                ? hastagTop5BestEgement[3]?.sumReachs
                  ? hastagTop5BestEgement[3]?.sumReachs
                  : 0
                : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 3.55,
              y: 5.6,
              w: 1.04,
              h: 0.58,
              fill: { color: 'ffffff' },
              rectRadius: 0.1,
              bold: true,
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }
        slideHashtag.addText(
          '5位:' +
            (hastagTop5BestEgement[4] ? (hastagTop5BestEgement[4]?.name ? hastagTop5BestEgement[4]?.name : '') : ''),
          {
            x: 0.8,
            y: 6.5,
            w: 2.22,
            h: 0.31,
            align: 'left',
            bold: true,
            color: '303030',
            fontSize: 12.6,
          }
        )
        slideHashtag.addText(hastagTop5BestEgement[4] ? 'こちらに簡単な説明を記載してください。' : '', {
          x: 0.99,
          y: 6.8,
          w: 2.19,
          h: 0.24,
          align: 'left',
          color: '595959',
          fontSize: 7.3,
        })
        if (hastagTop5BestEgement[4]) {
          slideHashtag.addText(
            'リーチ数\n' +
              (hastagTop5BestEgement[4]
                ? hastagTop5BestEgement[4]?.sumReachs
                  ? hastagTop5BestEgement[4]?.sumReachs
                  : 0
                : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 3.55,
              y: 6.5,
              w: 1.04,
              h: 0.58,
              fill: { color: 'ffffff' },
              rectRadius: 0.1,
              bold: true,
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }
      } else {
        slideHashtag.addImage({ path: EMPTY_IMG, x: 0.88, y: 3.2, w: 3.75, h: 3.75 })
      }
      slideHashtag.addShape(pptx.shapes.LINE, {
        x: 5.08,
        y: 2.06,
        w: 0.0,
        h: 5.25,
        line: { color: '595959', width: 0.5 },
      })
      slideHashtag.addImage({ path: WORST_HASHTAG_IMAGE, x: 5.28, y: 1.91, w: 0.59, h: 0.59 })
      slideHashtag.addText('ワーストハッシュタグ', {
        x: 5.87,
        y: 1.99,
        h: 0.31,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 12.6,
      })

      if (hastagTop5WorstEgment?.length > 0) {
        slideHashtag.addText('1', {
          x: 5.37,
          y: 2.57,
          w: 0.46,
          h: 0.55,
          align: 'center',
          bold: true,
          color: '002060',
          fontSize: 27.3,
        })
        slideHashtag.addShape(pptx.shapes.LINE, {
          x: 5.6,
          y: 3.18,
          w: 0.0,
          h: 0.49,
          line: { color: '7030a0', width: 6 },
        })
        slideHashtag.addText(
          hastagTop5WorstEgment[0] ? (hastagTop5WorstEgment[0]?.name ? hastagTop5WorstEgment[0]?.name : '') : '',
          {
            x: 5.97,
            y: 2.69,
            h: 0.31,
            align: 'left',
            bold: true,
            color: '303030',
            fontSize: 12.6,
          }
        )
        slideHashtag.addText(
          hastagTop5WorstEgment[0]
            ? 'こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。'
            : '',
          { x: 5.97, y: 3.0, w: 3.1, h: 0.59, align: 'left', color: '595959', fontSize: 8 }
        )
        if (hastagTop5WorstEgment[0]) {
          slideHashtag.addText(
            'リーチ数\n' +
              (hastagTop5WorstEgment[0]
                ? hastagTop5WorstEgment[0]?.sumReachs
                  ? hastagTop5WorstEgment[0]?.sumReachs
                  : 0
                : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 9.28,
              y: 2.92,
              w: 1.04,
              h: 0.58,
              fill: { color: '808080' },
              rectRadius: 0.1,
              bold: true,
              color: 'ffffff',
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }

        slideHashtag.addText('2', {
          x: 5.41,
          y: 3.67,
          w: 0.39,
          h: 0.44,
          align: 'center',
          bold: true,
          color: '002060',
          fontSize: 20.5,
        })
        slideHashtag.addShape(pptx.shapes.LINE, {
          x: 5.6,
          y: 4.12,
          w: 0.0,
          h: 0.62,
          line: { color: '7030a0', width: 6 },
        })
        slideHashtag.addText(
          hastagTop5WorstEgment[1] ? (hastagTop5WorstEgment[1]?.name ? hastagTop5WorstEgment[1]?.name : '') : '',
          {
            x: 5.97,
            y: 3.77,
            h: 0.31,
            align: 'left',
            bold: true,
            color: '303030',
            fontSize: 12.6,
          }
        )
        slideHashtag.addText(
          hastagTop5WorstEgment[1]
            ? 'こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。'
            : '',
          { x: 5.97, y: 4.01, w: 3.1, h: 0.59, align: 'left', color: '595959', fontSize: 8 }
        )
        if (hastagTop5WorstEgment[1]) {
          slideHashtag.addText(
            'リーチ数\n' +
              (hastagTop5WorstEgment[1]
                ? hastagTop5WorstEgment[1]?.sumReachs
                  ? hastagTop5WorstEgment[1]?.sumReachs
                  : 0
                : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 9.28,
              y: 3.92,
              w: 1.04,
              h: 0.58,
              fill: { color: '808080' },
              rectRadius: 0.1,
              bold: true,
              color: 'ffffff',
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }

        slideHashtag.addText('3', {
          x: 5.41,
          y: 4.74,
          w: 0.39,
          h: 0.44,
          align: 'center',
          bold: true,
          color: '002060',
          fontSize: 20.5,
        })
        slideHashtag.addShape(pptx.shapes.LINE, {
          x: 5.6,
          y: 5.19,
          w: 0.0,
          h: 0.56,
          line: { color: '7030a0', width: 6 },
        })
        slideHashtag.addText(
          hastagTop5WorstEgment[2] ? (hastagTop5WorstEgment[2]?.name ? hastagTop5WorstEgment[2]?.name : '') : '',
          {
            x: 5.97,
            y: 4.77,
            h: 0.31,
            align: 'left',
            bold: true,
            color: '303030',
            fontSize: 12.6,
          }
        )
        slideHashtag.addText(
          hastagTop5WorstEgment[2]
            ? 'こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。'
            : '',
          { x: 5.97, y: 5, w: 3.1, h: 0.59, align: 'left', color: '595959', fontSize: 8 }
        )
        if (hastagTop5WorstEgment[2]) {
          slideHashtag.addText(
            'リーチ数\n' +
              (hastagTop5WorstEgment[2]
                ? hastagTop5WorstEgment[2]?.sumReachs
                  ? hastagTop5WorstEgment[2]?.sumReachs
                  : 0
                : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 9.28,
              y: 4.82,
              w: 1.04,
              h: 0.58,
              fill: { color: '808080' },
              rectRadius: 0.1,
              bold: true,
              color: 'ffffff',
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }

        slideHashtag.addText('4', {
          x: 5.41,
          y: 5.74,
          w: 0.39,
          h: 0.44,
          align: 'center',
          bold: true,
          color: '002060',
          fontSize: 20.5,
        })
        slideHashtag.addShape(pptx.shapes.LINE, {
          x: 5.6,
          y: 6.13,
          w: 0.0,
          h: 0.52,
          line: { color: '7030a0', width: 6 },
        })
        slideHashtag.addText(
          hastagTop5WorstEgment[3] ? (hastagTop5WorstEgment[3]?.name ? hastagTop5WorstEgment[3]?.name : '') : '',
          {
            x: 5.97,
            y: 5.77,
            h: 0.31,
            align: 'left',
            bold: true,
            color: '303030',
            fontSize: 12.6,
          }
        )
        slideHashtag.addText(
          hastagTop5WorstEgment[3]
            ? 'こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。'
            : '',
          { x: 5.97, y: 6, w: 3.1, h: 0.59, align: 'left', color: '595959', fontSize: 8 }
        )
        if (hastagTop5WorstEgment[3]) {
          slideHashtag.addText(
            'リーチ数\n' +
              (hastagTop5WorstEgment[3]
                ? hastagTop5WorstEgment[3]?.sumReachs
                  ? hastagTop5WorstEgment[3]?.sumReachs
                  : 0
                : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 9.28,
              y: 5.72,
              w: 1.04,
              h: 0.58,
              fill: { color: '808080' },
              rectRadius: 0.1,
              bold: true,
              color: 'ffffff',
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }

        slideHashtag.addText('5', {
          x: 5.41,
          y: 6.74,
          w: 0.39,
          h: 0.44,
          align: 'center',
          bold: true,
          color: '002060',
          fontSize: 20.5,
        })
        slideHashtag.addText(
          hastagTop5WorstEgment[4] ? (hastagTop5WorstEgment[4]?.name ? hastagTop5WorstEgment[4]?.name : '') : '',
          {
            x: 5.97,
            y: 6.77,
            h: 0.31,
            align: 'left',
            bold: true,
            color: '303030',
            fontSize: 12.6,
          }
        )
        slideHashtag.addText(
          hastagTop5WorstEgment[4]
            ? 'こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。こちらに簡単な説明を記載してください。'
            : '',
          { x: 5.97, y: 7, w: 3.1, h: 0.59, align: 'left', color: '595959', fontSize: 8 }
        )
        if (hastagTop5WorstEgment[4]) {
          slideHashtag.addText(
            'リーチ数\n' +
              (hastagTop5WorstEgment[4]
                ? hastagTop5WorstEgment[4]?.sumReachs
                  ? hastagTop5WorstEgment[4]?.sumReachs
                  : 0
                : 0),
            {
              shape: pptx.shapes.ROUNDED_RECTANGLE,
              x: 9.28,
              y: 6.62,
              w: 1.04,
              h: 0.58,
              fill: { color: '808080' },
              rectRadius: 0.1,
              bold: true,
              color: 'ffffff',
              align: 'center',
              valign: 'middle',
              fontSize: 10,
            }
          )
        }
      } else {
        slideHashtag.addImage({ path: EMPTY_IMG, x: 6.39, y: 3.2, w: 3.75, h: 3.75 })
      }
      return slideHashtag
    },
  },
}
