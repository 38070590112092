<template>
  <DrashBoard>
      <!-- Some Magical Thing -->
      <div class="max-w-full mx-auto sm:px-6 lg:px-8 mt-12">
        <h1 class="capitalize text-button_pink font-bold font-sans text-xl">レポート</h1>
        <div class="flex flex-wrap -mb-4 mt-4">
            <Link
              :href="route('admin.export.report.preview')"
              class="flex items-center text-[15px] w-1/3 justify-between cursor-pointer mb-4 h-20 link__item drop-shadow-md bg-white transform hover:text-white px-3 hover:bg-button_pink transition duration-450 ease-in-out font-sans"
            >
              <h1>PDFレポート</h1>
              <i class="fa-solid fa-plus text-xl"></i>
            </Link>
              
          <Link
            :href="route('admin.export.report.preview')"
            class="flex items-center text-[15px] w-1/3 font-sans justify-between cursor-pointer mb-4 h-20 link__item drop-shadow-md bg-white hover:bg-button_pink hover:text-white px-3"
          >
            <h1>CSVエクスポート</h1>
            <i class="fa-solid fa-plus text-xl"></i>
          </Link>
        </div>
      </div>
  </DrashBoard>
</template>

<script>
import DrashBoard from '@/Layouts/Authenticated.vue'
import { Link } from '@inertiajs/inertia-vue3'
import { useInstagramMenu } from '@/Composables/menu'

export default {
  components: { DrashBoard, Link },
  data() {
    return {
    }
  },

  created() {
  },

  watch: {
  },

  methods: {
 
  },
}
</script>

<style lang="scss" scoped>
.link__item {
  width: calc(33.3333% - 10px);

  &:nth-child(3n + 1) {
    margin-right: 10px;
  }

  &:nth-child(3n + 2) {
    margin: 0 5px;
  }

  &:nth-child(3n + 3) {
    margin-left: 10px;
  }
}
</style>
