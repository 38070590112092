import axios from 'axios'

export async function getGroupList(page, keyword) {
  return await axios.get(route('api.groups.index'), {
    params: {
      page: page,
      search: keyword,
    },
  })
}
export async function getGroupDetail(id) {
  return await axios.get(route('api.groups.show', id))
}
export async function addGroup(nameGroup, accountId) {
  await axios.post(route('api.groups.store'), {
    name: nameGroup,
    account: accountId,
  })
}

export async function editGroup(id, nameGroup, listAccountId) {
  await axios.put(route('api.groups.update', id), {
    name: nameGroup,
    account: listAccountId,
  })
}

export async function deleteGroup(groupId) {
  await axios.delete(route('api.groups.destroy', groupId))
}
export async function getAccountList() {
  return await axios.get(route('api.accounts.index'))
}
export async function getAccountListByAdmin() {
  return await axios.get(route('api.accounts.get_by_admin'))
}
