<template>
  <data-table
    :fields="fields"
    :items="followers"
    disable-table-info
    footer-center
    paginate-background
    class="el-table-header__black mt-7"
    :class="{ datatable__hidden: checkEmpty }"
  >
    <template v-if="!checkEmpty" #followers="{ row }">
      <p class="font-bold">{{ formatToInteger(row?.followers) }}</p>
    </template>
    <template v-if="!checkEmpty" #follower_change>
      <p class="font-bold" :class="{ plus_active: followerChange() > 0, minus_active: followerChange() < 0 }">
        {{ formatFollowerChange(followerChange()) }}
      </p>
    </template>
    <template v-if="!checkEmpty" #follower_change_average="{ row }">
      <p
        class="font-bold"
        :class="{ plus_active: followerChangeAverage(row) > 0, minus_active: followerChangeAverage(row) < 0 }"
      >
        {{ formatFollowerChangeAverage(followerChangeAverage(row)) }}
      </p>
    </template>
    <template v-if="!checkEmpty" #follower_increase>
      <p class="font-bold" :class="{ plus_active: followerIncrease() > 0, minus_active: followerIncrease() < 0 }">
        {{ checkFollowerChange() }}
      </p>
    </template>
    <template v-if="!checkEmpty" #follower_increase_level>
      <p
        class="font-bold"
        :class="{ plus_active: followerIncreaseLevel() > 0, minus_active: followerIncreaseLevel() < 0 }"
      >
        {{ checkFollowerIncreaseLevel() }}
      </p>
    </template>
    <template v-if="!checkEmpty" #follower_increase_ratio>
      <p
        class="font-bold"
        :class="{ plus_active: followerIncreaseRatio() > 0, minus_active: followerIncreaseRatio() < 0 }"
      >
        {{ checkFollowerIncreaseRatio() }}
      </p>
    </template>
    <template v-if="!checkEmpty" #follower_fell>
      <p class="font-bold" :class="{ plus_active: followerFell() > 0, minus_active: followerFell() < 0 }">
        {{ formatollowerFell(followerFell()) }}
      </p>
    </template>
    <template v-if="!checkEmpty" #follower_fell_average>
      <p class="font-bold" :class="{ plus_active: followerFellAverage() > 0, minus_active: followerFellAverage() < 0 }">
        {{ formatFollowerFellAverage(followerFellAverage()) }}
      </p>
    </template>
    <template v-if="!checkEmpty" #follower_fell_ratio>
      <p class="font-bold" :class="{ plus_active: followerFellRatio() > 0, minus_active: followerFellRatio() < 0 }">
        {{ formatDataMinus(followerFellRatio()) }}
      </p>
    </template>
  </data-table>
  <el-empty v-if="checkEmpty" description="お知らせはありません。" class="mt-2"></el-empty>
</template>

<script>
import DataTable from '@/Components/UI/DataTable.vue'

export default {
  name: 'TableFollower',
  components: {
    DataTable,
  },
  props: {
    range: {
      type: Object,
      default: 0,
    },
    groupTable: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      followers: [],
      fields: [
        {
          key: 'followers',
          label: 'フォロワー数',
          align: 'center',
          width: 150,
        },
        {
          key: 'follower_change',
          label: 'フォロワー純増減数',
          align: 'center',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
        },
        {
          key: 'follower_change_average',
          label: '平均フォロワー純増減数',
          align: 'center',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
        },
        {
          key: 'follower_increase',
          label: 'フォロワー増加数',
          align: 'center',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
        },
        {
          key: 'follower_increase_level',
          label: '平均フォロワー増加数',
          align: 'center',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
        },
        {
          key: 'follower_increase_ratio',
          label: 'フォロワー増加率',
          align: 'center',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
        },
        {
          key: 'follower_fell',
          label: 'フォロワー減少数',
          align: 'center',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
        },
        {
          key: 'follower_fell_average',
          label: '平均フォロワー減少数',
          align: 'center',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
        },
        {
          key: 'follower_fell_ratio',
          label: 'フォロワー減少率',
          align: 'center',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
        },
      ],
      interval: 1,
      lastDaySelected: {},
      previousDaySelected: {},
      lastDayPrevious: {},
      checkEmpty: false,
    }
  },
  async created() {
    this.fetchData()
  },
  watch: {
    range: function () {
      this.fetchData()
    },
    groupTable: function () {
      this.fetchData()
    },
  },
  methods: {
    async fetchData() {
      if (!this.range.length) return
      const datatable = JSON.parse(JSON.stringify(this.groupTable))
      this.followers = datatable?.lastDaySelected ? Array(datatable?.lastDaySelected) : []
      this.lastDaySelected = this.groupTable?.lastDaySelected
      this.previousDaySelected = this.groupTable?.previousDaySelected
      this.lastDayPrevious = this.groupTable?.lastDayPrevious
      this.interval = datatable?.interval
      this.checkData()
    },
    //follower change
    followerChange() {
      const lastDaySelected = this.lastDaySelected?.followers ?? 0
      const lastDayPrevious = this.lastDayPrevious?.followers ?? 0
      let followers = lastDaySelected - lastDayPrevious
      return followers
    },
    formatFollowerChange(followers) {
      return followers === 0
        ? followers
        : followers > 0
        ? '+' + this.formatToFloat(followers)
        : this.formatToFloat(followers)
    },
    followerChangeAverage() {
      const followers = this.getSubtractFollower()
      const average = followers / this.interval
      return average
    },
    formatFollowerChangeAverage(average) {
      return average === 0 ? average : average > 0 ? '+' + this.formatToFloat(average) : this.formatToFloat(average)
    },
    followerIncrease() {
      const followers = this.getSubtractFollower()
      return followers
    },
    formatFollowerIncrease(followers) {
      return followers === 0 ? followers : followers > 0 ? '+' + this.formatToFloat(followers) : ''
    },
    followerIncreaseLevel() {
      const followers = this.getSubtractFollower()
      const average = followers / this.interval
      return average
    },
    formatFollowerIncreaseLevel(average) {
      return average === 0 ? average : average > 0 ? '+' + this.formatToFloat(average) : ''
    },
    followerIncreaseRatio() {
      const lastDaySelected = this.lastDaySelected?.followers ?? 0
      const lastDayPrevious = this.lastDayPrevious?.followers ?? 0
      const followers = lastDaySelected - lastDayPrevious
      if (followers < 0 || followers === 0) {
        return followers < 0 ? '' : 0
      } else {
        return followers * 100
      }
    },
    followerFell() {
      const followers = this.getSubtractFollower()
      return followers
    },
    formatollowerFell(followers) {
      return followers === 0 ? followers : followers < 0 ? this.formatToInteger(followers) : ''
    },
    followerFellAverage() {
      const followers = this.getSubtractFollower()
      const average = followers / this.interval
      return average
    },
    formatFollowerFellAverage(average) {
      return average === 0 ? average : average < 0 ? this.formatToFloat(average) : ''
    },
    followerFellRatio() {
      const lastDaySelected = this.lastDaySelected?.followers ?? 0
      const lastDayPrevious = this.lastDayPrevious?.followers ?? 0
      const followers = lastDaySelected - lastDayPrevious
      if (followers > 0 || followers === 0) {
        return followers > 0 ? '' : ''
      }
      return followers * 100
    },
    getSubtractFollower() {
      const previousDaySelected = this.previousDaySelected?.followers ?? 0
      const lastDaySelected = this.lastDaySelected?.followers ?? 0
      const followers = lastDaySelected - previousDaySelected
      return followers
    },
    checkData() {
      if (
        this.followerChange() == 0 &&
        this.followerChangeAverage() == 0 &&
        this.followerFellRatio() == 0 &&
        this.followerFell() == 0 &&
        this.followerIncrease() == 0 &&
        this.followerIncreaseLevel() == 0 &&
        this.followerIncreaseRatio() == 0 &&
        this.followerFellAverage() == 0
      ) {
        this.checkEmpty = true
      } else {
        this.checkEmpty = false
      }
    },
    formatDataPlus(temp) {
      return temp > 0 ? '+' + this.formatToFloat(temp + '%') : ''
    },
    formatDataMinus(temp) {
      return temp < 0 ? this.formatToFloat(temp + '%') : ''
    },
    checkFollowerChange() {
      return this.followerChange() > 0 ? this.formatFollowerIncrease(this.followerIncrease()) : ''
    },
    checkFollowerIncreaseLevel() {
      return this.followerChange() > 0 ? this.formatFollowerIncreaseLevel(this.followerIncreaseLevel()) : ''
    },
    checkFollowerIncreaseRatio() {
      return this.followerChange() > 0 ? this.formatDataPlus(this.followerIncreaseRatio()) : ''
    },
  },
}
</script>
