<template>
  <dashboard>
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li class="mr-4">
          <Link :href="route('admin.notifications.index')" class="font-normal">お知らせ管理</Link>
        </li>
        <span class="font-normal text-notice_date mx-2">></span>
        <li class="mr-4">
          <Link :href="route('admin.notifications.edit', deliveryNotice?.id)" class="font-normal breakcumb-active">
            お知らせ編集
          </Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="mt-12 ml-8 tabs">
        <ul class="flex"></ul>
      </div>
    </template>
    <div class="body flex-grow-1 p-6 bg-background min-h-screen">
      <h2 class="text-xl text-button_pink font-semibold py-5">お知らせ編集</h2>
      <form v-on:submit.prevent="submitForm" class="notification-form">
        <div class="grid grid-cols-1 gap-4 py-8">
          <div class="flex items-center justify-between w-full">
            <h1 class="sm:w-48 md:w-60 text-nomal">
              タイトル
              <span class="text-red-500">*</span>
            </h1>

            <div class="w-full" :class="{ 'failed-validate': errors.title?.first() }">
              <el-input :disabled="status === 1" v-model="form.title" class="w-full" size="large" clearable />
              <span class="msg-error-validate">{{ errors.title?.first() }}</span>
            </div>
          </div>

          <div class="flex items-center justify-between w-full">
            <h1 class="sm:w-48 md:w-60 text-nomal">
              ラベル
              <span class="text-red-500">*</span>
            </h1>

            <div class="w-full" :class="{ 'failed-validate': errors.label?.first() }">
              <el-select
                :disabled="status === 1"
                v-model="form.label"
                class="w-full"
                placeholder="ラベルを選択"
                size="large"
              >
                <el-option v-for="item in labels" :key="item" :label="item" :value="item" />
              </el-select>
              <span class="msg-error-validate">{{ errors.label?.first() }}</span>
            </div>
          </div>

          <div class="flex justify-between w-full">
            <h1 class="sm:w-48 md:w-60 text-nomal">
              本文
              <span class="text-red-500">*</span>
            </h1>

            <div class="w-full" :class="{ 'failed-validate': errors.content?.first() }">
              <el-input v-model="form.content" :rows="6" type="textarea" class="w-full" :disabled="status === 1" />
              <span class="msg-error-validate">{{ errors.content?.first() }}</span>
            </div>
          </div>

          <div class="flex items-center w-full md:flex-nowrap sm:flex-wrap md:gap-0 sm:gap-4">
            <h1 class="md:w-60 sm:w-full text-nomal">
              配信スケジュール
              <span class="text-red-500">*</span>
            </h1>

            <div class="flex w-full justify-start md:flex-nowrap sm:flex-wrap md:gap-0 sm:gap-4">
              <div class="flex items-center mr-8">
                <input type="radio" id="now" :disabled="status === 1 && form.type === 1" v-model="form.type" :value="0" class="text-pink-400 mr-2" />

                <label for="now" class="whitespace-nowrap">即時配信</label>
              </div>

              <div class="flex items-center mr-8 sm:my-2 md:my-0">
                <input
                  :disabled="status === 1"
                  type="radio"
                  id="schedule"
                  :value="1"
                  v-model="form.type"
                  class="text-pink-400 mr-2"
                />

                <label for="schedule" class="whitespace-nowrap">予約配信</label>
              </div>

              <div v-if="form.type === 1" :class="{ 'failed-validate': errors.date?.first() }">
                <el-date-picker
                  v-model="form.date"
                  type="datetime"
                  size="large"
                  format="YYYY-MM-DD HH:mm"
                  value-format="YYYY-MM-DD HH:mm"
                  :disabled-date="disabledDate"
                  :disabled="status === 1"
                  :clearable="false"
                  placeholder="日時入力"
                  popper-class="cusDatePicker"
                />
                <span class="msg-error-validate">{{ errors.date?.first() }}</span>
              </div>
            </div>
          </div>

          <div class="flex md:flex-row sm:flex-col md:items-center w-full mt-5">
            <h1 class="text-nomal w-60">
              配信権限
              <span class="text-red-500">*</span>
            </h1>
            <div class="w-full">
              <div
                class="md:flex sm:grid grid-cols-2 justify-between md:gap-y-2 sm:gap-4 md:mt-2 sm:mt-4 flex flex-wrap"
              >
                <div class="flex items-center float-left lg:w-1/5 md:w-4/12 sm:w-1/2 roles-item">
                  <input
                    type="checkbox"
                    id="role0"
                    v-model="form.role"
                    :value="0"
                    class="rounded-sm text-pink-400 mr-2"
                    @click="handleCheckAllRole"
                    :disabled="status === 1"
                  />
                  <label for="role0">全て</label>
                </div>
                <div
                  v-for="role in roles"
                  :key="role.id"
                  class="roles-item flex items-center float-left lg:w-1/5 md:w-4/12 sm:w-1/2"
                >
                  <input
                    type="checkbox"
                    :id="'role' + role.id"
                    v-model="form.role"
                    :value="role.id"
                    class="rounded-sm text-pink-400 mr-2"
                    @change="handleCheckRole"
                    :disabled="status === 1"
                  />
                  <label :for="'role' + role.id">{{ role.name }}</label>
                </div>
              </div>
              <div class="block" :class="{ 'failed-validate': errors.role?.first() }">
                <span class="msg-error-validate">{{ errors.role?.first() }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-center my-12 w-full gap-6">
          <button
            @click="goBack"
            type="submit"
            class="text-14 md:py-5.5 sm:py-3 bg-button_pink w-72 px-10 text-white button-shadow rounded"
          >
            取り消し
          </button>

          <button
            @click="updateObj"
            type="submit"
            class="text-14 md:py-5.5 sm:py-3 bg-button_profile text-white w-72 sm:px-2 md:px-10 rounded-sm button-shadow"
            :disabled="status === 1"
          >
            配信内容を更新する
          </button>
        </div>
      </form>
    </div>
  </dashboard>
</template>

<script>
import Dashboard from '@/Layouts/Authenticated.vue'
import { getListRole } from '@/Api/Role'
import _ from 'lodash'
import { ElMessageBox } from 'element-plus'
import { Back } from '@element-plus/icons-vue'
import { Link } from '@inertiajs/inertia-vue3'
import BreadCrumb from '@/Components/Breadcrumb.vue'
export default {
  name: 'Edit',
  components: {
    Dashboard,
    Back,
    BreadCrumb,
    Link,
  },

  created() {
    this.getListRole()
  },
  props: {
    deliveryNotice: {
      type: Object,
    },
  },
  mounted() {
    if (this.status === 1) {
      const elements = document.querySelectorAll('input,select,textarea,checkbox')
      for (const el of elements) {
        el.disabled = true
      }
    }
  },
  data() {
    return {
      roles: [],
      normalDate: '',
      form: {
        role: [],
        type: this.deliveryNotice?.type,
        label: this.deliveryNotice?.label,
        date: this.deliveryNotice?.date,
        title: this.deliveryNotice?.title,
        content: this.deliveryNotice?.content,
      },
      status: this.deliveryNotice?.status,
      errors: [],
      labels: ['お知らせ', '不具合', 'アップデート', '最新情報', 'アンケート'],
    }
  },
  computed: {
    rolesId() {
      return this.roles.map((item) => item.id)
    },
  },
  mounted() {
    this.cusDatePickerText()
  },
  methods: {
    goBack() {
      return this.$inertia.visit(route('admin.notifications.index'))
    },
    disabledDate(time) {
      let d = new Date()
      return time.getTime() < d.setDate(d.getDate() - 1)
    },
    getListRole() {
      getListRole().then((res) => {
        this.roles = res.data
        this.form.role = this.deliveryNotice?.notice_roles.map((item) => item.role_id)
        if (_.isEqual(this.form.role.sort(), this.rolesId.sort())) {
          this.form.role = [0, ...this.rolesId]
        }
      })
    },
    updateObj() {
      axios.put(route('api.delivery_notices.update', this.deliveryNotice?.id), this.form).then((res) => {
        if (res?.data?.status === 2) {
          this.$toast.error(this.$t('something went wrong'))
        } else {
          this.$toast.success(this.$t('update notification successfully'))
          this.$inertia.visit(route('admin.notifications.index'))
        }
      })
    },
    deleteObj() {
      ElMessageBox.confirm(this.$t('do you want to delete this notification'), '警告', {
        confirmButtonText: '同意',
        cancelButtonText: 'キャンセル',
        type: 'warning',
      }).then(async () => {
        axios
          .delete(route('api.delivery_notices.destroy', this.deliveryNotice?.id))
          .then((res) => {
            if (res?.data?.status === 1) {
              this.$toast.error(res?.data?.msg)
            } else if (res?.data?.status === 2) {
              this.$toast.error(this.$t('something went wrong'))
            } else {
              this.$toast.success(this.$t('delete notification successfully'))
              this.$inertia.visit(route('admin.notifications.index'))
            }
          })
          .catch(() => {
            this.$toast.error(this.$t('something went wrong'))
          })
      })
    },
    handleCheckAllRole(e) {
      if (e.target.checked) {
        this.form.role = [0, ...this.rolesId]
      } else {
        this.form.role = []
      }
    },
    handleCheckRole(e) {
      if (!e.target.checked) {
        this.form.role = this.form.role.filter((item) => item !== 0)
      } else if (_.isEqual(this.form.role.sort(), this.rolesId.sort())) {
        this.form.role = [0, ...this.rolesId]
      }
    },
    cusDatePickerText() {
      if (this.deliveryNotice?.type == 0) return

      let datePicker = document.getElementsByClassName('cusDatePicker')

      let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0]
      const preYearNode = document.createTextNode('<<前年')
      btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0])
      btnPreYear.style.cssText += 'margin: 0 5px 0 0'

      let btnPreMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[1]
      const preMonthNode = document.createTextNode('<前月')
      btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0])
      btnPreMonth.style.cssText += 'margin: 0'

      let btnNextYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[4]
      const nextYearNode = document.createTextNode('翌年>>')
      btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0])
      btnNextYear.style.cssText += 'margin: 0 0 0 5px'

      let btnNextMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[5]
      const nextMonthNode = document.createTextNode('翌月>')
      btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0])
      btnNextMonth.style.cssText += 'margin: 0'
    },
  },
  watch: {
    'form.type': {
      handler(val) {
        val === 0 && (this.form.date = null)
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss">
.notification-form {
  & label {
    font-size: 13px;
  }
  .el-input__inner {
    height: 48px;
    color: #000;
    font-size: 16px;
  }
  .el-textarea__inner {
    color: #000;
    padding-top: 20px;
    font-size: 16px;
  }
  input[type='radio'] {
    padding: 10px;
  }
  input[type='text']:disabled,
  select:disabled,
  textarea:disabled {
    background: #dddddd73;
  }
}
.el-button--primary {
  background-color: #5264cd !important;
  border: 1px solid #5264cd !important;
}

.el-button--primary span {
  color: white !important;
}
</style>
