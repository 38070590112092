<template>
  <div class="bg-white py-4 chart-scroll__pdf">
    <Line
      :chart-options="chartOptions"
      :chart-data="chartData"
      :plugins="plugins"
      :id="canvasID"
      v-if="chartData.datasets[0].data.length !== 0"
    />
    <el-empty description="データはありません" v-else />
  </div>
</template>

<script>
import { Inertia } from '@inertiajs/inertia'
import { Line } from 'vue-chartjs'
export default {
  name: 'LineChart',
  components: { Line },
  props: {
    range: { type: Object, default: 0 },
    analyticType: { type: String, default: 'month' },
    plugins: { type: Object, default: () => {} },
    dataFollowers: { type: Array, default: () => {} },
    dataLabels: { type: Array, default: () => {} },
    canvasID: { type: String },
  },
  computed: {
    isExport() {
      return Inertia?.page?.url === '/export/report-preview'
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: { left: 20, right: 10, top: 20, bottom: 20 },
        },
        plugins: {
          legend: {
            display: true,
            labels: { boxHeight: 1, position: 'top', align: 'start' },
          },
          datalabels: {
            display: false,
          },
        },
        tooltips: {
          intersect: true,
        },
        scales: {
          x: { grid: { display: false } },
          y: { grid: { display: false } },
        },
      },
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            label: 'フォロワー推移',
            borderColor: '#3e95cd',
            fill: false,
            lineTension: 0.5,
          },
        ],
      },
    }
  },

  watch: {
    range: function () {
      this.fetchData()
    },
    dataFollowers: function () {
      this.fetchData()
    },
  },
  methods: {
    async fetchData() {
      this.chartData.labels = this.dataLabels
      this.chartData.datasets[0].data = this.dataFollowers
    },
  },
}
</script>
<style lang="scss">
.chart-scroll__pdf {
  padding: 1rem 0 !important;
  width: 100%;
  overflow-x: auto;
  & > div {
    width: 1510px;
    height: 400px;
  }
}
</style>
