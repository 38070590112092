<script setup>
import AppHeader from '@/Layouts/AppHeader'
</script>
<template>
  <div class="flex h-screen overflow-hidden">
    <div class="wrapper relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-gray-100">
      <AppHeader />
      <div class="paymentSuccess">
        <div class="mb-10">
          <img src="/img/favicon-upscale.png" alt="Favicon Upscale" width="152" height="28" />
        </div>
        <div class="text-center font-normal lg:mb-16 md:mb-10 sm:mb-8">
          <p class="text-nomal" v-if="update == '1'">
            Insta分析ツール（insta-tools.com） {{ plan_name }} へのアップグレードありがとうございます。
            <br />
            ご登録のメールアドレスにおアップグレードの詳細をお送りしていますのでご確認ください
          </p>
          <p class="text-nomal" v-else>
            Insta分析ツール（insta-tools.com） {{ plan_name }} へのお申し込みありがとうございます。
            <br />
            ご登録のメールアドレスにお申し込み情報の詳細をお送りしていますのでご確認ください
          </p>
        </div>
        <div class="md:flex justify-around mt-16 lg:gap-10 sm:gap-5">
          <Link
            :href="route('admin.users_infor')"
            method="get"
            class="bg-indigo-500 hover:bg-indigo-700 md:py-5 sm:py-3 text-center rounded-sm sm:mr-2 text-white text-btn-submit min-w-[165px] sm:px-2"
          >
            管理画面に戻る
          </Link>
          <Link
            :href="route('logout')"
            method="post"
            class="bg-button_pink hover:bg-pink-600 md:py-5 sm:py-3 text-center rounded-sm text-white text-btn-submit min-w-[180px] sm:px-2"
          >
            ログアウトして閉じる
          </Link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
export default {
  props: {
    plan_name: {
      type: String,
      required: false,
    },
    update: {
      type: String,
      required: false,
    },
  },

  components: {
    Link,
  },
}
</script>

<style lang="scss" scoped>
.paymentSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dadada;
  padding: 40px 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
