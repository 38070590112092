import sanitizeHtml from "sanitize-html";
import { replaceUrl } from "@/Helpers/dom";

export default function(xhtml) {
    return sanitizeHtml(xhtml, {
        nonTextTags: [ 'style', 'script', 'textarea', 'option', 'noscript', 'input' ],
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img', 'font' ]),
        allowedClasses: {
            '*': [ '*' ]
        },
        allowedAttributes: {
            '*': [ 'title', 'style', 'href', 'target' ]
        }
    })
}


export const linkifyXss = str => {
    const markup = str
        .replace(/</g, "&lt;")
      return markup;
}

export const parseTagA = str => {
    return replaceUrl(linkifyXss(str)).replace(/(?:\r|\n|\r\n)/g, '<br>')
}
