export default {
  methods: {
    genSlideTitle(pptx, title, sub_menu) {
      const slideTitle = pptx.addSlide()
      slideTitle.addText(title, {
        x: 0.76,
        y: 3.33,
        w: 9.51,
        h: 1.6,
        align: 'center',
        bold: true,
        color: 'e7299e',
        fontSize: 40,
      })
      slideTitle.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 1.7,
        y: 5.9,
        w: 8.5,
        h: 1.8,
        rectRadius: 0.2,
        line: '898989',
        lineSize: 0.5,
      })

      if (sub_menu) {
        slideTitle.addText(String(sub_menu[0]), {
          x: 2.2,
          y: 6.1,
          w: 8,
          h: 0.6,
          align: 'left',
          color: '303030',
          fontSize: 13,
          bullet: { code: '2022', fontSize: 30 },
        })
        slideTitle.addText(sub_menu[1], {
          x: 2.2,
          y: 6.8,
          w: 8,
          h: 0.36,
          align: 'left',
          color: '303030',
          fontSize: 13,
          bullet: { code: '2022', fontSize: 30 },
        })
      }

      return slideTitle
    },
  },
}
