<template>
  <Dashboard>
    <div class="flex justify-center lg:pt-10 md:pt-5">
      <BreezeApplicationLogo class="w-64 h-20 fill-current text-gray-500" />
    </div>
    <h2 class="text-center">有料プランへのアップグレード</h2>

    <div class="flex items-center justify-between lg:py-5 lg:px-20 md:px-10 md:py-5 sm:px-4 sm:py-4">
      <p class="text-button_pink font-medium text-title">現在のプラン</p>
      <p>{{ user?.plan?.name }}</p>
    </div>

    <p class="text-button_pink pl-4 md:pl-[5rem] font-medium text-title">プラン選択</p>
    <div class="flex lg:py-5 lg:px-20 md:px-10 md:py-5 sm:px-4 sm:py-4 bg-background">
      <div class="w-full overflow-auto">
        <div class="bg-white flex w-[1200px] border md:w-full">
          <div class="w-4/12">
            <div class="border-b mt-2 pl-4 h-16"></div>
<!--            <div class="border-b mt-4 pl-4 h-13 text-2sm">アカウント数</div>-->
            <div class="border-b mt-4 pl-4 h-13 text-2sm">料金（税込）</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">データ保有期間</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">アカウント分析</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">ハッシュタグ分析</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">競合分析</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">投稿分析</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">投稿/予約投稿機能</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">時間・曜日別分析</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">クリエイティブ分析</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">初速分析</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">広告分析</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">ストーリーズ分析</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">リール分析</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">コメント分析</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">指定ハッシュタグ分析</div>
            <div class="border-b mt-4 pl-4 h-13 text-2sm">PDFレポーティング機能</div>
            <div class="mt-4 pl-4 h-13">pptxレポーティング機能</div>
          </div>
          <div
            class="w-4/12 text-center border-l "
            :class="{ 'border-red-600 border': planSelected == item.id || item.id == user?.plan?.id,
                      'bg-slate-300' :planId == item.id || item.id == user?.plan?.id}"
            v-for="item in plans"
            :key="item"
          >
            <div class="border-b cursor-pointer h-16 mt-2">
              <input class="w-5 h-5 border-gray-200" :disabled="planId == item.id || item.id == user?.plan?.id"
                     type="radio"
                     :value="item.id"
                     v-model="planSelected" />
              <div class="mt-2 font-bold">{{ item.name }}</div>
            </div>

<!--            <div class="mt-4 border-b h-13 text-2sm">-->
<!--              {{ item?.number_accounts.toLocaleString() }}-->
<!--            </div>-->

            <div class="mt-4 border-b h-13 text-2sm">
              {{ item?.amount_after_tax == 0 ? '無料' : item?.amount_after_tax.toLocaleString() + '円' }}
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <div v-if="item?.storage_time == 0">無制限</div>
              <div v-if="item?.storage_time != 0">{{ item?.storage_time }}日</div>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.account_analysis == 1" src="/images/check-yes.svg"></el-image>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.post_hashtag == 1" src="/images/check-yes.svg"></el-image>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.co_opetition_analysis == 1" src="/images/check-yes.svg"></el-image>
              <el-image v-else src="/images/check-no.svg"></el-image>
              <span v-if="item?.number_account_competition > 0" class="ml-2">
                競合 {{ item?.number_account_competition.toLocaleString() }}アカウントまで
              </span>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.post_analysis == 1" src="/images/check-yes.svg"></el-image>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.reserved_post_function == 1" src="/images/check-yes.svg"></el-image>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.user_analysis == 1" src="/images/check-yes.svg"></el-image>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.creative_analysis == 1" src="/images/check-yes.svg"></el-image>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.initial_velocity_analysis == 1" src="/images/check-yes.svg"></el-image>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.advertising_analysis == 1" src="/images/check-yes.svg"></el-image>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.story_analysis == 1" src="/images/check-yes.svg"></el-image>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.reel_analysis == 1" src="/images/check-yes.svg"></el-image>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.comment_analysis == 1" src="/images/check-yes.svg"></el-image>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.hashtag_analysis == 1" src="/images/check-yes.svg"></el-image>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>

            <div class="mt-4 border-b h-13 text-2sm">
              <el-image v-if="item?.report_pdf == 1" src="/images/check-yes.svg"></el-image>
              <span v-if="item?.number_report_pdf > 0 && item?.report_pdf == 1" class="ml-2">
                毎月 {{ item?.number_report_pdf.toLocaleString() }} 回まで出力出来ます。
              </span>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>

            <div class="mt-4 h-13 text-2sm">
              <el-image v-if="item?.report_pptx == 1" src="/images/check-yes.svg"></el-image>
              <span v-if="item?.number_report_pptx > 0 && item?.report_pptx == 1" class="ml-2">
                毎月 {{ item?.number_report_pptx.toLocaleString() }} 回まで出力出来ます。
              </span>
              <el-image v-else src="/images/check-no.svg"></el-image>
            </div>
          </div>
        </div>
        <div class="mt-6 hidden md:block">
          <a class="text-pink text-2sm" target="_blank" href="https://lp.insta-tools.com/multiple_form/">
            複数アカウントの場合はこちら
          </a>
        </div>
      </div>
    </div>
    <div class="mt-6 pl-4 block md:hidden">
      <a class="text-pink text-2sm" target="_blank" href="https://lp.insta-tools.com/multiple_form/">
        複数アカウントの場合はこちら
      </a>
    </div>

    <div class="flex items-center justify-center w-full bg-background py-10">
      <button
        @click="callbackProfile"
        class="md:py-5.5 sm:py-2.5 bg-button_pink md:px-29 sm:px-10 text-white button-shadow rounded text-btn-submit"
      >
        戻る
      </button>
      <button
        :disabled="planSelected == user?.plan?.id"
        @click="updatePlanUser()"
        as="button"
        type="submit"
        class="md:py-5.5 bg-button_profile md:ml-6 md:px-29 sm:px-10 sm:py-2.5 sm:ml-3 text-white button-shadow rounded text-btn-submit"
      >
        決済画面へ
      </button>
    </div>
  </Dashboard>
</template>

<script>
import Dashboard from '@/Pages/Dashboard.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeLabel from '@/Components/Label.vue'
import BreezeValidationErrors from '@/Components/ValidationErrors.vue'
import { Link } from '@inertiajs/inertia-vue3'
import BreezeApplicationLogo from '@/Components/ApplicationLogo.vue'

export default {
  props: {
    user_id: {
      type: Number,
      required: true,
    },
    plan_id: {
      type: Number,
      required: true,
    },
  },

  components: {
    Dashboard,
    BreezeValidationErrors,
    BreezeInput,
    BreezeLabel,
    Link,
    BreezeApplicationLogo,
  },

  data() {
    return {
      planSelected: '',
      plans: [],
      user: {},
      planId:null
    }
  },

  created() {
    this.planId = this.plan_id
    this.fetchDataPlan()
    this.fetchDataUser()
  },

  methods: {
    callbackProfile() {
      return this.$inertia.visit(route('admin.users_infor'))
    },

    choosePlanActive(plan_id) {
      this.plans = this.plans.map((plan) => ({
        id: plan.id,
        name: plan.name,
        amount: plan.amount,
        note: plan.note,
        free: plan.free,
        checkbox: plan.id == plan_id ? true : false,
      }))
    },

    async fetchDataPlan() {
      await axios
        .get('/api/get_plan_user/' + this.user_id)
        .then((res) => {
          this.plans = res.data.data
              .filter((plan) => {
                return plan.is_show_user == 1
              })
        })
        .catch((err) => {
          console.error(err)
        })
    },

    async fetchDataUser() {
      await axios
        .get(route('api.users.show', this.user_id))
        .then((res) => {
          this.user = res.data.data
          this.planSelected = this.user?.plan.id
        })
        .catch((err) => {
          console.error(err)
        })
    },

    updatePlanUser() {
      if (this.planSelected != '') {
        window.location.href = route('admin.users-payment-plan', this.planSelected)
      } else {
        this.$toast.error(this.$t('Please select the plan you want to upgrade'))
      }
    },
  },
}
</script>
<style scoped>
.lh-2 {
  line-height: 1.5rem;
}
</style>
