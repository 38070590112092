import { formatToInteger } from '@/Plugins/numberFormat'
export default {
  methods: {
    genHashtagHourAnalyticPage(doc) {
      doc.setFontSize(14) // set font size
      doc.setTextColor(190, 61, 143)
      doc.text('指定ハッシュタグ分析', 13, 30)
      doc.text('指定ハッシュタグ分析', 13, 30)
      doc.text('指定ハッシュタグ分析', 13, 30)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(10)
      let arrChart = document.getElementsByClassName('hashtag-analytic-chart')
      let dataHashtagsAnalysisMap = this.mapDataHashtag(this.dataHashtagsAnalysis)
      let listChart = []
      for (const [index, item] of Object.entries(arrChart)) {
        if (index >= 2) {
          listChart.push(item.childNodes[0])
        }
      }
      const arrDataMap = JSON.parse(JSON.stringify(dataHashtagsAnalysisMap))
      if (dataHashtagsAnalysisMap.length <= 7) {
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 30 },
            1: { cellWidth: 20 },
            2: { cellWidth: 20 },
            3: { cellWidth: 20 },
            4: { cellWidth: 20 },
          },
          didDrawCell: function (data) {
            if (data.column.index === 5 && data.cell.section === 'body') {
              var textPos = data.cell
              doc.addImage(listChart[data.row.index], 'PNG', textPos.x + 1, textPos.y + 1, 70, 30)
            }
          },
          bodyStyles: { minCellHeight: 30 },
          startY: 40,
          head: [
            ['ハッシュタグ', '投稿数', '総いいね数', '総コメント数', '人気投稿に掲載された自分の投稿', '投稿数推移'],
          ],
          body: arrDataMap,
        })
      } else {
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 30 },
            1: { cellWidth: 20 },
            2: { cellWidth: 20 },
            3: { cellWidth: 20 },
            4: { cellWidth: 20 },
          },
          didDrawCell: function (data) {
            if (data.column.index === 5 && data.cell.section === 'body') {
              var textPos = data.cell
              doc.addImage(listChart[data.row.index], 'PNG', textPos.x + 1, textPos.y + 1, 70, 30)
            }
          },
          bodyStyles: { minCellHeight: 32 },
          startY: 40,
          head: [
            ['ハッシュタグ', '投稿数', '総いいね数', '総コメント数', '人気投稿に掲載された自分の投稿', '投稿数推移'],
          ],
          body: arrDataMap.slice(0, 7),
        })
        doc.addPage()
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 30 },
            1: { cellWidth: 20 },
            2: { cellWidth: 20 },
            3: { cellWidth: 20 },
            4: { cellWidth: 20 },
          },
          didDrawCell: function (data) {
            if (data.column.index === 5 && data.cell.section === 'body') {
              var textPos = data.cell
              doc.addImage(listChart[data.row.index + 7], 'PNG', textPos.x + 1, textPos.y + 1, 70, 30)
            }
          },
          bodyStyles: { minCellHeight: 32 },
          startY: 40,
          head: [
            ['ハッシュタグ', '投稿数', '総いいね数', '総コメント数', '人気投稿に掲載された自分の投稿', '投稿数推移'],
          ],
          body: arrDataMap.slice(7),
        })
      }
    },
    mapDataHashtag(data) {
      let dataMap = []
      data.forEach((item) => {
        let arr = []
        arr.push(item.name)
        arr.push(formatToInteger(this.sumArray(item.analysis.posts_count)))
        arr.push(formatToInteger(this.sumArray(item.analysis.likes_count)))
        arr.push(formatToInteger(this.sumArray(item.analysis.comments_count)))
        arr.push(formatToInteger(this.sumArray(item.analysis.top_media_count)))
        dataMap.push(arr)
      })
      return dataMap
    },
    sumArray(arr) {
      if (!arr) return 0
      return arr.reduce((a, b) => a + b, 0)
    },
  },
}
