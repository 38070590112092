<template>
    <div>
        <Bar
            class="mx-auto p-4"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :plugins="plugins"
            style="position: relative; height: 150vh; width: 45vw"
        />
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs'

export default {
    name: 'AllBarChart',
    components: {
        Bar,
    },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart',
        },
        plugins: {
            type: Array,
            default: () => [],
        },
        accountInsights: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            listCountry: [],
            showModal: false,
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: 'フォロワー',
                        backgroundColor: '#0097D3',
                        data: [],
                    },
                ],
            },
            chartOptions: {
                maintainAspectRatio: false,
                indexAxis: 'y',
                plugins: {
                    tooltip: {
                        xAlign: {
                            label: function (context) {
                                context.dataset.label.xAlign = 'left'
                                return context.dataset.label.xAlign
                            },
                        },
                    },
                    legend: {
                        position: 'top',
                        align: 'start',
                        title: {
                            display: true,
                        },
                    },
                },
                elements: {
                    bar: {
                        borderWidth: 2,
                    },
                },
                scales: {
                    x: {
                        grid: {
                            borderColor: 'transparent',
                        },
                    },
                    y: {
                        grid: {
                            display: false,
                            borderColor: 'transparent',
                        },
                        ticks: {
                            beginAtZero: true,
                            precision: 0,
                        },
                    },
                },
            },
        }
    },
    created() {
        this.getData()
    },
    watch: {
        accountInsights: function () {
            this.getData()
        },
    },
    methods: {
        async getData() {
            if (this.accountInsights.length > 0) {
                this.prepareDataForChart()
            } else {
                this.showData = false
            }
        },
        prepareDataForChart() {
            this.listCountry = []
            this.accountInsights.forEach((accountInsight) => {
                if (accountInsight.audience_city) {
                    this.listCountry.push(accountInsight.audience_city)
                }
            })

            let totalDataCountry = {}
            this.listCountry.forEach((country) => {
                const dataCountry = JSON.parse(country)

                for (const [index, item] of Object.entries(dataCountry)) {
                    if (totalDataCountry.hasOwnProperty(index)) {
                        totalDataCountry[index] += item
                    } else {
                        totalDataCountry[index] = item
                    }
                }
            })

            for (const [key, item] of Object.entries(totalDataCountry)) {
                if (!this.chartData.labels.includes(key)) {
                    this.chartData.labels.push(key)
                }
            }

            for (const city of this.chartData.labels) {
                this.chartData.datasets[0].data = [...this.chartData.datasets[0]?.data, totalDataCountry[city]]
            }

            this.chartData.labels = this.chartData.labels.map((label) => {
                return this.$t(label);
            })
        },
    },
}
</script>

<style>
</style>
