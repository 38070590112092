<template>
  <DrashBoard>
    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-2 relative pb-5 active">投稿画面</li>
        </ul>
      </div>
    </template>
    <div class="bg-background">
      <UserNav />
      <div class="flex px-14.5 pb-20">
        <div class="basis-9/12 pr-12">
          <h2 class="title-md mt-20">投稿画面</h2>
          <div class="flex gap-10 mt-12.5 border-b-2 pb-6">
            <label for="">アカウント名:</label>
            <p class="text-base text-request font-normal">KIGEKI 株式会社</p>
          </div>
          <div class="mt-6 border-b-2 pb-8.5">
            <p class="text-label_dark">キャンペーンの目的を選択</p>
            <form
              class="grid form-wrapper_3col gap-x-14 gap-y-7.5 mt-7"
              action=""
            >
              <div
                class="flex gap-3.2"
                v-for="(item, index) in purposeCampaign"
                :key="index"
              >
                <input type="checkbox" name=""  />
                <label class="label_dark" for="">{{ item.label }}</label>
              </div>
            </form>
          </div>
          <div class="flex gap-10 items-center py-7 border-b-2">
            <label class="w-1/6" for="">キャンペーン名</label>
            <input class="flex-1 py-4 rounded" type="text" />
          </div>
          <div class="gap-x-7.5 items-center py-7 border-b-2">
            <label for="">キャンペーン名</label>
            <ul class="flex justify-between mt-7.5">
              <li class="flex items-center" v-for="item in traffic" :key="item">
                <input v-model="item.model" type="checkbox" name=""  />
                <label class="ml-3.5" for="">{{ item.label }}</label>
              </li>
            </ul>
          </div>
          <div class="items-center py-7 flex gap-10">
            <label class="w-1/6" for="">1日の予算</label>
            <div class="flex justify-between flex-1 gap-10">
              <select
                class="
                  h-full
                  pl-4
                  pr-24.5
                  border border-select
                  py-4
                  px-5
                  basis-1/2
                  rounded
                "
              >
                <option value="0">1日の予算</option>
                <option value="1">Audi</option>
                <option value="2">BMW</option>
                <option value="3">Citroen</option>
              </select>
              <select
                class="
                  h-full
                  pl-4
                  pr-24.5
                  border border-select
                  py-4
                  basis-1/2
                  rounded
                "
              >
                <option value="0">1日の予算</option>
                <option value="1">Audi</option>
                <option value="2">BMW</option>
                <option value="3">Citroen</option>
              </select>
            </div>
          </div>
          <div class="flex items-center pb-7 border-b-2 gap-10">
            <label class="w-1/6" for="">開始日時</label>
            <div class="flex flex-1 gap-10">
              <v-date-picker class="basis-1/2" v-model="since" mode="dateTime">
                <template v-slot="{ inputEvents }">
                  <div class="relative w-4/6">
                    <input
                      class="text-option w-full py-4 rounded"
                      type="text"
                      v-on="inputEvents"
                      v-model="untilDate"
                    />
                    <img
                      class="absolute right-3 top-1/2 -translate-y-1/2"
                      src="/images/calendar.svg"
                      alt=""
                    />
                  </div>
                </template>
              </v-date-picker>
              <div class="flex items-center basis-1/2 gap-10">
                <label class="ml-1" for=""> 終了日</label>
                <v-date-picker
                  class="relative flex-1 ml-3"
                  v-model="until"
                  mode="dateTime"
                >
                  <template v-slot="{ inputEvents }">
                    <input
                      v-model="untilDate"
                      class="w-full py-4 rounded text-option"
                      type="text"
                      v-on="inputEvents"
                    />
                    <img
                      class="absolute right-3 top-1/2 -translate-y-1/2"
                      src="/images/calendar.svg"
                      alt=""
                    />
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>
          <div class="pt-6 border-b-2 pb-6">
            <label for="">オーディエンス設定</label>
            <div class="flex flex-1 gap-10 mt-6">
              <label class="basis-2/12" for="">地域　日本　</label>
              <span class="text-link_pink basis-5/12">編集</span>
              <label class="basis-2/12" for="">性別　女性</label>
              <span class="text-link_pink basis-3/12">編集</span>
            </div>
            <div class="flex flex-1 gap-10 mt-7.5">
              <label class="basis-2/12" for="">年齢　18-65+</label>
              <span class="text-link_pink basis-5/12">編集</span>
              <label class="basis-2/12" for="">詳細ターゲット設定</label>
              <span class="text-link_pink basis-3/12">編集</span>
            </div>
          </div>
          <div class="pt-7">
            <label for="">広告設定</label>
            <div class="mt-7">
              <input type="checkbox" name=""  />
              <label class="label_dark ml-3 mr-20" for=""
                >シングル画像または動画</label
              >
              <input type="checkbox" name=""  />
              <label class="label_dark ml-3 mr-24" for="">カルーセル</label>
              <input type="checkbox" name=""  />
              <label class="label_dark ml-3" for="">コレクション</label>
            </div>
          </div>
          <div class="mt-10">
            <div
              v-for="campain in purposeCampaign"
              :key="campain"
              class="flex items-center mt-5 gap-10"
            >
              <label class="w-1/6">{{ campain.label }}</label>
              <input class="py-4 rounded flex-1" type="text" />
            </div>
          </div>
          <button
            class="
              py-5.5
              px-29
              bg-blue-600
              button-shadow
              text-white
              my-28
              block
              mx-auto
              rounded
            "
          >
            公開する
          </button>
        </div>
        <div class="basis-3/12">
          <IphoneScreen :pl="47" />
        </div>
      </div>
    </div>
  </DrashBoard>
</template>

<script>
import DrashBoard from "@/Layouts/Authenticated.vue";
import IphoneScreen from "@/Components/IphoneScreen.vue";
import UserNav from "@/Components/UserNav.vue";
export default {
  components: {
    IphoneScreen,
    DrashBoard,
    UserNav,
  },
  data() {
    return {
      until: new Date(),
      since: new Date(),
      untilDate: new Date(),
      campainSelected: [],
      purposeCampaign: [
        {
          label: "ブランドの認知アップ",
          model: 1,
        },
        {
          label: "リーチ　○トラフィック",
          model: 1,
        },
        {
          label: "エンゲージメント",
          model: 1,
        },
        {
          label: "アプリのインストール",
          model: 1,
        },
        {
          label: "動画の再生数アップ",
          model: 1,
        },
        {
          label: "リード獲得",
          model: 1,
        },
        {
          label: "メッセージ",
          model: 1,
        },
        {
          label: "コンバージョン",
          model: 1,
        },
        {
          label: "カタログ販売",
          model: 1,
        },
        {
          label: "来店数の増加",
          model: 1,
        },
      ],
      traffic: [
        {
          label: "Webサイト",
          model: 1,
        },
        {
          label: "アプリ",
          model: 1,
        },
        {
          label: "Messenger",
          model: 1,
        },
        {
          label: "WhatsApp",
          model: 1,
        },
        {
          label: "通話",
          model: 1,
        },
      ],
    };
  },
  methods: {
    formartDate() {
      this.untilDate =
        this.untilDate.toISOString().split("T")[0].replaceAll("-", "/") +
        " " +
        this.untilDate.getHours() +
        ":" +
        this.untilDate.getMinutes();
    },
  },
  mounted() {
    this.formartDate();
  },
  watch: {},
};
</script>

<style scoped>
.title img,
label img {
  margin-right: 6px;
}
.title {
  margin-top: 23px;
}
.form__campaign {
  padding: 20px 0;
  grid-gap: 22px 0px;
  grid-template-columns: max-content max-content auto;
}
.form-wrapper_2col {
  grid-template-columns: 15% auto;
}
.form-wrapper_3col {
  grid-template-columns: max-content max-content auto;
}
.grid-4-col {
  grid-template-columns: max-content auto max-content auto;
}
input[type="checkbox"] {
  padding: 10px;
}
select {
  background-image: url("/images/down-arrow.svg");
  background-size: auto;
}
</style>
