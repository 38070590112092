<template>
  <section class="mt-2.5">
    <div class="w-full overflow-hidden shadow-lg">
      <div class="w-full overflow-x-auto">
        <table :class="{ light: mode }" class="mt-2.5 overflow-y-scroll">
          <thead>
            <tr
              class="
                text-md
                font-semibold
                text-left
                bg-gray-100
                border-b border-gray-600
              "
            >
              <slot name="first-header"> </slot>
            <th
                class="
                  text-2sm
                  py-4
                  font-normal
                  bg-header_table
                  text-white
                  border-r
                  text-center
                  rounded-md
                  border-2
                  whitespace-nowrap
                "
                v-for="(item, index) in label"
                :key="index"
                :style="{
                  width: item?.width + 'px',
                  minWidth: (item?.minWidth ? item.minWidth : 150) + 'px',
                }"
              >
                {{ item.text }}
              </th>
              <slot name="end-header"> </slot>
            </tr>
          </thead>
          <tbody class="bg-body_table">
            <tr v-for="(item, index) in data" :key="index">
              <slot name="first-column" :firstData="item"> </slot>
              <td class="py-5.5" v-for="(label, index) in label" :key="index">
                <p
                  class="text-center"
                  :class="{ [label.key]: true }"
                  v-html="xss(item[label.key])"
                ></p>
              </td>
              <slot name="end-column" :endData="item"></slot>
            </tr>
          </tbody>
          <slot name="custom-tbody"> </slot>
        </table>
      </div>
    </div>
  </section>
</template>
<script>
import xss from "@/Helpers/xss";
export default {
  props: ["label", "data", "action", "dataFirstColumn", "mode"],
  methods: {
    xss,
    checkStatus: function (data) {},
  },
};
</script>
<style lang="scss" scoped>
table {
  // font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;

  tr {
    box-shadow: 0px 1px 0px rgba(169, 169, 169, 0.25);
    border-bottom: solid 1px rgba(169, 169, 169, 0.25);
  }

  tr:last-of-type {
    border-bottom: 0;
  }

  &.light {
    tr {
      background-color: #ffffff;
    }

    thead tr {
      border: 1px solid #e2e2e2;
    }

    th {
      background: #ffffff;
      color: #414141;
      border: 0;
    }
  }
}
</style>
