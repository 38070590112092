<script setup>
import BreezeApplicationLogo from '@/Components/ApplicationLogo.vue'
import { Link } from '@inertiajs/inertia-vue3'
</script>

<template>
    <div>
        <!-- Topbar -->
        <header class="sticky z-30 bg-white px-3">
            <div class="flex px-5 items-center justify-between py-4 mt-2">
                <!-- Header: Left side -->
                <div class="flex">
                    <Link class="py-2">
                        <img src="/img/favicon-upscale.png" alt="Favicon Upscale" width="152" height="28" />
                    </Link>
                </div>
                <!-- Header: Right side -->
                <ul class="flex items-center">
                    <li class="inline-flex items-center mx-2 relative">
                        <button class="inline-flex justify-center items-center mx-13">
                            <Link
                                :href="route('register')"
                                class="flex items-center border-r-2 px-4"
                                :class="route().current() == 'register' ? 'disabled opacity-60 pointer-events-none' : false"
                            >
                                Sign Up
                            </Link>
                            <Link
                                :class="route().current() == 'formLogin.user' ? 'disabled opacity-60 pointer-events-none' : false"
                                :href="route('formLogin.user')"
                                class="flex items-center border-r-2 px-4"
                            >
                                Sign In
                            </Link>
                        </button>
                    </li>
                    <li class="inline-flex items-center">
                        <div class="help text-help font-normal text-base">サービスサポート</div>
                        <button
                            class="w-6 h-6 flex items-center justify-center hover:bg-gray-200 transition duration-150 rounded ml-2"
                            aria-controls="search-modal"
                            >
                            <span class="sr-only">Help</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                d="M10.345 4.16667H17.5C17.721 4.16667 17.933 4.25446 18.0893 4.41074C18.2456 4.56702 18.3334 4.77899 18.3334 5V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.933 17.4122 17.721 17.5 17.5 17.5H2.50002C2.27901 17.5 2.06704 17.4122 1.91076 17.2559C1.75448 17.0996 1.66669 16.8877 1.66669 16.6667V3.33333C1.66669 3.11232 1.75448 2.90036 1.91076 2.74408C2.06704 2.5878 2.27901 2.5 2.50002 2.5H8.67835L10.345 4.16667ZM3.33335 4.16667V15.8333H16.6667V5.83333H9.65502L7.98835 4.16667H3.33335ZM10 10V7.5L13.3334 10.8333L10 14.1667V11.6667H6.66669V10H10Z"
                                fill="#172444"
                                />
                            </svg>
                        </button>
                    </li>
                </ul>
            </div>
        </header>
        <div class="shadow-inner h-1"></div>
        <div class="min-h-screen flex flex-col justify-start items-center pt-6 sm:pt-0 bg-slate-100">
        <!-- Content -->
            <div class="my-20 border px-29 py-16">
                <div class="flex justify-center">
                    <Link href="#">
                        <BreezeApplicationLogo class="w-64 h-20 fill-current text-gray-500" />
                    </Link>
                </div>
                <div class="px-6 py-4 bg-transparent overflow-hidden sm:rounded-lg">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>
