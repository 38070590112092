<template>
  <DashBoard>
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li>
          <Link :href="route('admin.group-post-manage')" class="font-normal breakcumb-active">投稿分析</Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <Loading v-if="isLoading" />
      <div class="overflow-auto pt-8 md:pl-10 tabs bg-white" style="margin-top: 0 !important">
        <ul class="flex">
          <li
            class="px-10 relative pb-5 px-sm"
            @click.prevent="onChangeTab('list_all')"
            :class="{ active: currentTab == 'list_all' }"
          >
            投稿一覧
          </li>
          <li
            class="px-10 relative pb-5 px-sm"
            @click.prevent="onChangeTab('list_posted')"
            :class="{ active: currentTab == 'list_posted' }"
          >
            投稿済み一覧
          </li>
          <li
            class="px-10 relative pb-5 px-sm"
            @click.prevent="onChangeTab('list_dedicated_to')"
            :class="{ active: currentTab == 'list_dedicated_to' }"
          >
            予約投稿一覧
          </li>
          <li
            class="px-10 relative pb-5 px-sm"
            @click.prevent="onChangeTab('list_draft')"
            :class="{ active: currentTab == 'list_draft' }"
          >
            下書き保存一覧
          </li>
          <li
            class="px-10 relative pb-5 px-sm"
            @click.prevent="onChangeTab('list_error')"
            :class="{ active: currentTab == 'list_error' }"
          >
            投稿失敗一覧
          </li>
        </ul>
      </div>
    </template>

    <div class="statistic md:px-10 sm:px-4 bg-background min-h-screen">
      <div class="flex justify-between items-center md:block">
        <h2 class="text-3sm text-button_pink font-semibold py-5">{{ titleTab }}</h2>
        <Link
          href="/group-post-create"
          as="button"
          type="button"
          class="button-mobile sm:mb-3 text-3sm bg-blue-600 text-white px-7 py-2.5 w-max rounded md:mt-0 sm:mt-4 h-10 flex md:ml-auto sm:ml-0"
        >
          +新規作成
        </Link>
      </div>
      <!-- search -->
      <div class="flex flex-col-reverse md:grid md:grid-cols-2">
        <div class="flex sm:gap-4 md:gap-0">
          <div class="md:mr-5 sm:mr-0 md:w-96 sm:w-72 md:mb-0 sm:mb-2 md:h-10">
            <el-input
              v-model="filter.search"
              placeholder="検索キーワードを入力してください"
              size="large"
              class="text-2sm"
              clearable
              @keyup.enter="fetchData"
              @change="fetchData"
            />
          </div>
          <el-button class="el-button__primary md:mb-0 md:!h-10 md:w-20 sm:mb-2" size="large" @click="fetchData">
            <span class="text-white">検索</span>
          </el-button>
        </div>
        <Link
          href="/group-post-create"
          as="button"
          type="button"
          class="button-des sm:mb-3 text-3sm bg-blue-600 text-white px-7 py-2.5 w-max rounded md:mt-0 sm:mt-4 h-10 flex md:ml-auto sm:ml-0"
        >
          +新規作成
        </Link>
      </div>

      <!-- table -->
      <data-table
        :fields="fields"
        :items="posts.data"
        :paginate="posts.meta"
        :current-page="Number(filter.page) || 1"
        disable-table-info
        footer-center
        paginate-background
        @page="handleCurrentPage"
        class="el-table-header__black mt-6"
      >
        <template #file="{ row }">
          <template v-if="row">
            <div class="flex justify-center">
              <video
                v-if="isTypeVideo(row?.is_carousel ? row?.files[0] : row?.file)"
                controls
                width="112"
                style="height: 64px"
              >
                <source :src="row?.is_carousel ? row?.files[0] : row?.file" />
                Your browser does not support the video tag.
              </video>
              <img v-else class="w-28 h-16" :src="row?.is_carousel ? row?.files[0] : row?.file" alt="" />
            </div>
          </template>
        </template>

        <template #content="{ row }">
          <p v-if="row" class="post-content showFullText break-all whitespace-pre-line">
            {{ row.content }}
          </p>
          <p
            v-if="row"
            @click="viewDetailContent(row)"
            class="text-xs text-button_profile font-semibold text-right block mt-2 hover:cursor-pointer"
          >
            全文を見る
          </p>
        </template>

        <template v-if="currentTab == 'list_posted'" #engagement="{ row }">
          <p v-if="row && row.post_category == '通常'">
            {{ Math.round((row.engagement + Number.EPSILON) * 100) / 100 }}%
          </p>
          <p v-else>{{ ' - ' }}</p>
        </template>

        <template #updated_at="{ row }">
          <span v-if="row?.status === 1">{{ formatTime(row?.scheduled_time) }}</span>
          <span v-else-if="row?.status === 2">下書き保存</span>
          <span v-else>{{ formatTime(row?.updated_at) }}</span>
        </template>
        <template v-if="currentTab === 'list_draft'" #edit="{ row }">
          <Link
            :href="'post-group/edit/' + row?.id"
            as="button"
            type="button"
            class="rounded button-shadow w-16 px-2 py-2 bg-blue-600 text-white block mx-auto cursor-pointer"
          >
            編集
          </Link>
        </template>

        <template #hashtag="{ row }">
          <template v-if="row">
            <p class="paragraph-overflow">{{ row?.hashtag?.replaceAll(',', ' ') }}</p>
          </template>
        </template>

        <template v-if="currentTab == 'list_posted'" #analytic="{ row }">
          <Link
            v-if="row"
            :href="route('admin.post-group-analytic')"
            :data="{ id: row.id, tab: currentTab, page: filter.page }"
            class="rounded button-shadow px-5 py-2 bg-blue-600 text-white block mx-auto"
            as="button"
          >
            確認する
          </Link>
        </template>

        <template v-if="currentTab == 'list_posted'" #permalink="{ row }">
          <a v-if="row" :href="row.permalink" target="_blank" as="button" style="width: 70px; color: #be3d8f">
            <div class="flex justify-center">
              <i class="fab fa-instagram text-3xl cursor-pointer"></i>
            </div>
          </a>
        </template>

        <template v-if="currentTab == 'list_dedicated_to'" #delete="{ row }">
          <!-- has button delete post -->
          <p
            @click="deletePost(row?.id)"
            class="rounded button-shadow w-16 px-2 py-2 bg-button_table text-white block mx-auto cursor-pointer"
          >
            削除
          </p>
        </template>
      </data-table>

      <!-- dialog detail content -->
      <el-dialog v-model="dialogShowPost" width="50%" title="投稿内容" append-to-body>
        <!-- <p>{{postSelected.content}}</p> -->
        <PopupReviewPost
          :content="postSelected.content"
          :file="postSelected?.is_carousel ? postSelected.files[0] : postSelected.file"
          :fileType="getTypeFile(postSelected?.is_carousel ? postSelected.files[0] : postSelected.file)"
          :carouselItems="postSelected?.is_carousel ? postSelected.files : []"
          :hashtag="postSelected.hashtag"
          :tag="postSelected.tag"
          :username="postAuthor"
          :group="true"
        />
      </el-dialog>
      <!-- end dialog detail content -->
    </div>
  </DashBoard>
</template>
<script>
import DashBoard from '@/Layouts/Authenticated.vue'
import Loading from '@/Components/Loadding'
import CreatePost from './CreatePost.vue'
import DataTable from '@/Components/UI/DataTable.vue'
import * as postAPI from '@/Api/Posts'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import PopupReviewPost from '@/Components/PopupReviewPost.vue'
import { getUserDetailByAccountId } from '@/Api/Users'
import { ElMessageBox } from 'element-plus'

export default {
  props: ['userId', 'groups'],
  components: {
    DashBoard,
    Link,
    Loading,
    BreadCrumb,
    CreatePost,
    DataTable,
    Link,
    PopupReviewPost,
  },
  data() {
    return {
      isLoading: false,
      currentTab: 'list_all',
      titleTabList: [
        { key: 'list_all', text: '投稿一覧' },
        { key: 'list_posted', text: '投稿済み一覧' },
        { key: 'list_dedicated_to', text: '予約投稿一覧' },
        { key: 'list_draft', text: '下書き保存一覧' },
        { key: 'list_error', text: '投稿失敗一覧' },
      ],
      titleTab: '',
      posts: [],
      filter: {
        search: '',
        page: 1,
        name: null,
        limit: 6,
      },
      fields: [],
      fieldsListAll: [
        { key: 'group_name', label: 'グループ', align: 'center', width: 200 },
        { key: 'account_name', label: 'アカウント名', align: 'center', width: 200 },
        { key: 'file', label: '投稿画像', align: 'center', width: 200 },
        { key: 'post_category', label: 'タイプ', align: 'center', width: 150 },
        { key: 'content', label: '投稿内容', align: 'center', minWidth: 200 },
        { key: 'hashtag', label: 'ハッシュタグ', align: 'center', width: 180 },
        { key: 'updated_at', label: '投稿日時', align: 'center', width: 180 },
      ],
      fieldsListSchedule: [
        { key: 'group_name', label: 'グループ', align: 'center', width: 200 },
        { key: 'account_name', label: 'アカウント名', align: 'center', width: 200 },
        { key: 'file', label: '投稿画像', align: 'center', width: 200 },
        { key: 'post_category', label: 'タイプ', align: 'center', width: 150 },
        { key: 'content', label: '投稿内容', align: 'center', minWidth: 200 },
        { key: 'hashtag', label: 'ハッシュタグ', align: 'center', width: 180 },
        { key: 'updated_at', label: '投稿日時', align: 'center', width: 180 },
        { key: 'delete', label: '削除', align: 'center', width: 180 },
      ],
      fieldsListPosted: [
        { key: 'group_name', label: 'グループ', align: 'center', width: 200 },
        { key: 'account_name', label: 'アカウント名', align: 'center', width: 200 },
        { key: 'file', label: '投稿画像', align: 'center', width: 150 },
        { key: 'post_category', label: 'タイプ', align: 'center', width: 150 },
        {
          key: 'content',
          label: '投稿内容',
          align: 'center',
          width: screen.width / 10 >= 320 ? screen.width / 10 : 600,
        },
        { key: 'hashtag', label: 'ハッシュタグ', align: 'center', width: 180 },
        { key: 'updated_at', label: '投稿日時', align: 'center', width: 180 },
        { key: 'like', label: 'いいね数', align: 'center', width: 150 },
        { key: 'comment', label: 'コメント数', align: 'center', width: 150 },
        { key: 'saved', label: '保存数', align: 'center', width: 150 },
        { key: 'reach', label: 'リーチ数', align: 'center', width: 150 },
        { key: 'engagement', label: 'エンゲージメント率', align: 'center', width: 150 },
        { key: 'analytic', label: '初速分析', align: 'center', width: 150 },
        { key: 'permalink', label: 'Instaページ', align: 'center', width: 100 },
      ],
      fieldsListDraft: [
        { key: 'group_name', label: 'グループ', align: 'center', width: 200 },
        { key: 'account_name', label: 'アカウント名', align: 'center', width: 200 },
        { key: 'file', label: '投稿画像', align: 'center', width: 200 },
        { key: 'post_category', label: 'タイプ', align: 'center', width: 150 },
        { key: 'content', label: '投稿内容', align: 'center', minWidth: 200 },
        { key: 'hashtag', label: 'ハッシュタグ', align: 'center', width: 180 },
        { key: 'updated_at', label: '投稿日時', align: 'center', width: 180 },
        { key: 'edit', label: '操作', align: 'center', width: 100 },
      ],
      fieldsListError: [
        { key: 'group_name', label: 'グループ', align: 'center', width: 200 },
        { key: 'account_name', label: 'アカウント名', align: 'center', width: 200 },
        { key: 'error_ins', label: 'エラーメッセージ', align: 'center', width: 200 },
        { key: 'file', label: '投稿画像', align: 'center', width: 200 },
        { key: 'post_category', label: 'タイプ', align: 'center', width: 150 },
        { key: 'content', label: '投稿内容', align: 'center', minWidth: 200 },
        { key: 'hashtag', label: 'ハッシュタグ', align: 'center', width: 180 },
        { key: 'updated_at', label: '投稿日時', align: 'center', width: 180 },
      ],
      dialogShowPost: false,
      postSelected: {},
      postAuthor: {},
    }
  },
  created() {
    this.fields = this.fieldsListAll

    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    this.currentTab = searchParams.get('tab') ?? 'list_all'
    this.filter.page = searchParams.get('page') ?? 1

    const dataTab = this.titleTabList.find((item) => item.key == this.currentTab)
    this.titleTab = dataTab.text

    this.fetchData()
  },
  watch: {
    currentTab() {
      const dataTab = this.titleTabList.find((item) => item.key == this.currentTab)
      this.titleTab = dataTab.text
      switch (this.currentTab) {
        case 'list_all':
          this.fields = this.fieldsListAll
          return
        case 'list_posted':
          this.fields = this.fieldsListPosted
          return
        case 'list_dedicated_to':
          this.fields = this.fieldsListSchedule
          return
        case 'list_draft':
          this.fields = this.fieldsListDraft
          return
        case 'list_error':
          this.fields = this.fieldsListError
          return
      }
      this.fetchData()
    },
  },
  methods: {
    async deletePost(id) {
      ElMessageBox.confirm('この投稿を削除しても宜しいですか。', '警告', {
        confirmButtonText: '同意',
        cancelButtonText: 'キャンセル',
        type: 'warning',
      }).then(async () => {
        await axios.delete(route('api.posts.destroy', id)).then((res) => {
          if (res.status) {
            this.$toast.success('投稿削除しました。')
            this.fetchData()
          }
        })
      })
    },

    onChangeTab(tab) {
      if (!tab) return
      this.$inertia.get(route('admin.group-post-manage'), { tab: tab, page: 1 })
    },
    async fetchData() {
      await postAPI
        .getListPostOfGroup(this.currentTab, this.filter.search, this.filter?.limit, this.filter?.page)
        .then((response) => {
          this.posts = response.data
        })
        .catch((err) => {})
    },
    handleCurrentPage(value) {
      this.filter.page = value
      this.fetchData()
    },
    isTypeVideo(filePath) {
      const arr = filePath.split('.')
      if (arr[arr.length - 1] == 'mp4' || arr[arr.length - 1] == 'm4v') {
        return true
      } else {
        return false
      }
    },
    async viewDetailContent(post) {
      await this.getAccountPost(post)

      this.postSelected = post
      this.dialogShowPost = true
    },
    async getAccountPost(post) {
      if (!post.account_id) return

      await getUserDetailByAccountId(post.account_id)
        .then((account) => {
          this.postAuthor = account?.data?.data
        })
        .catch((err) => {
          this.postAuthor = {}
        })
    },
    getTypeFile(fileName) {
      return fileName.split('.').pop()
    },
    formatTime(date) {
      if (!date) {
        return
      }
      let listDate = date.slice(0, 10).split('-')
      let listTime = date.slice(11, 16)
      let dateFormat = listDate[0] + '年' + listDate[1] + '月' + listDate[2] + '日' + ' ' + listTime
      return dateFormat
    },
  },
}
</script>
<style>
.button-mobile {
  display: none;
}

.el-button__primary {
  background-color: #5264cd !important;
  border: 1px solid #5264cd !important;
}

.el-button__primary span {
  color: white !important;
}
@media screen and (max-width: 1024px) {
  .button-mobile {
    display: inline-block;
  }
  .button-des {
    display: none;
  }
}

.showFullText {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}
</style>
<style type="scss" scoped>
.paragraph-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
