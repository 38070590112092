<template>
  <DrashBoard>
    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-2 relative pb-5 active">投稿</li>
          <li class="px-2 relative pb-5">投稿済一覧</li>
        </ul>
      </div>
    </template>
    <div class="bg-background">
      <UserNav />
      <div class="px-10">
        <h2 class="text-title text-button_pink font-semibold py-5">
          投稿初速分析
        </h2>
        <Table :label="postTableLabel" :data="tableData">
          <template v-slot:first-column>
            <td class="flex justify-center items-center py-4">
              <img src="/images/unsplash_yRELK_NOAGM.png" alt="" />
            </td>
          </template>
          <template v-slot:end-column>
            <td>
              <button
                class="rounded button-shadow py-2 px-5 bg-blue-600 text-white"
              >
                確認
              </button>
            </td>
          </template>
        </Table>
        <Table :label="staticTableLabel" :data="staticTableData" />
      </div>
    </div>
  </DrashBoard>
</template>

<script>
import DrashBoard from "@/Layouts/Authenticated.vue";
import Table from "@/Components/Table.vue";
import UserNav from "@/Components/UserNav.vue";
export default {
  components: {
    Table,
    DrashBoard,
    UserNav
  },
  data() {
    return {
      postTableLabel: [
        "投稿画像",
        "タイプ",
        "投稿内容",
        "ハッシュタグ",
        "投稿日時",
        "いいね数",
        "コメント数",
        "リーチ数",
        " エンゲージメ",
        "初速分析",
      ],
      staticTableLabel: ["time", "post", "コメント数", "リーチ数", "保存数"],
      staticTableData: [
        {
          title: "13:30",
          tag: 3,
          Authority: 4,
          plan: 40,
          payment: 40,
        },
        {
          title: "13:30",
          tag: 3,
          Authority: 4,
          plan: 40,
          payment: 40,
        },
        {
          title: "13:30",
          tag: 3,
          Authority: 4,
          plan: 40,
          payment: 40,
        },
        {
          title: "13:30",
          tag: 3,
          Authority: 4,
          plan: 40,
          payment: 40,
        },
        {
          title: "13:30",
          tag: 3,
          Authority: 4,
          plan: 40,
          payment: 40,
        },
        {
          title: "13:30",
          tag: 3,
          Authority: 4,
          plan: 40,
          payment: 40,
        },
        {
          title: "13:30",
          tag: 3,
          Authority: 4,
          plan: 40,
          payment: 40,
        },
        {
          title: "13:30",
          tag: 3,
          Authority: 4,
          plan: 40,
          payment: 40,
        },
        {
          title: "13:30",
          tag: 3,
          Authority: 4,
          plan: 40,
          payment: 40,
        },
        {
          title: "13:30",
          tag: 3,
          Authority: 4,
          plan: 40,
          payment: 40,
        },
      ],
      tableData: [
        {
          title: "通常",
          tag: "esttesttetstetstetstetsttetstetsttetstettsttettettat",
          Authority: "#test #test",
          plan: "2022/1/1 10:00",
          payment: "100",
          amount: "3",
          startDate: "1",
          endDate: "5.26%",
        },
      ],
    };
  },
};
</script>

<style>
</style>
