export async function fetchNumberExport(type, btn_id) {
  return await axios.get(
    route('api.user.exported', {
      type: type,
      btn_id: btn_id,
    })
  )
}

export async function addNumberExport(type, btn_id) {
  return await axios.post(route('api.user.export'), {
    type: type,
    btn_id: btn_id,
  })
}
