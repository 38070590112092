<template>
    <dashboard>
        <Loading v-if="isLoading" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li v-if="check_role_admin">
                    <Link :href="route('admin.users.index', { set_old_params: true })" class="font-normal">管理画面</Link>
                </li>
                <span v-if="check_role_admin" class="font-normal text-notice_date mx-2">></span>
                <li>
                    <Link :href="route('admin.users.create')" class="font-normal breakcumb-active">アカウント情報</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li @click="toggleTabs(1)" class="sm:px-3 md:px-6 relative pb-5">アカウント管理</li>
                    <li @click="toggleTabs(2)" class="sm:px-3 md:px-6 relative pb-5 active">決済方法・プラン管理</li>
                    <li v-if="checkHidden()" @click="toggleTabs(3)" class="sm:px-3 md:px-6 relative pb-5">ご利用明細</li>
                </ul>
            </div>
        </template>
        <div class="py-5">
            <UserInformation :userSelected="user" />
        </div>

        <div class=" md:p-12 sm:p-5 bg-background">
            <div class="text-pink-400 font-semibold text-3sm">プラン管理</div>

            <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-x-40" v-if="user.plan !== null">
                <div class="form-left">
                    <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                        <label class="text-black col-span-1">現在の契約</label>
                        <input type="text" class="col-span-2 h-14 border border-solid border-input rounded"
                            :value="user.plan?.name" disabled />
                    </div>
                </div>
                <div v-if="user.plan?.amount > 0 && !['admin_agency', 'admin_oem', 'user_agency', 'user_oem'].includes(user.role_name)"
                    class="form-right">
                    <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                        <label class="text-black col-span-1">決済状態</label>
                        <input type="text" class="col-span-2 h-14 border border-solid border-input rounded"
                            :value="user.apply == 1 ? '決済済み' : '未決済'" disabled />
                    </div>
                </div>
            </div>

            <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-x-40" v-if="user.plan !== null">
                <div class="form-left">
                    <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                        <label class="text-black col-span-1">利用開始日</label>
                        <input v-if="['未決済', 'パスワード未設定'].includes(user?.status)"
                            class="col-span-2 h-14 border border-solid border-input rounded" disabled />
                        <input v-else type="text" class="col-span-2 h-14 border border-solid border-input rounded"
                            :value="formatDate(user.started_at)" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                        <label class="text-black col-span-1">月額金額（税込）</label>
                        <input type="text" class="col-span-2 h-14 border border-solid border-input rounded" :value="
                            user?.amount_plan_crawl
                                ? getAmountPlanCrawl(user.amount_plan_crawl).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
                                : user.plan?.amount_after_tax
                                    ? user.plan.amount_after_tax.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
                                    : '¥0'
                        " disabled />
                    </div>
                </div>
                <div class="form-right">
                    <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                        <label class="text-black col-span-1">契約終了日</label>
                        <input v-if="['未決済', 'パスワード未設定'].includes(user?.status)"
                            class="col-span-2 h-14 border border-solid border-input rounded" disabled />
                        <input v-else type="text" class="col-span-2 h-14 border border-solid border-input rounded"
                            :value="formatDate(user.ended_at)" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                        <label class="text-black col-span-1">自動更新回数</label>
                        <input type="text" class="col-span-2 h-14 border border-solid border-input rounded"
                            :value="user.count_update" disabled />
                    </div>
                </div>
            </div>
        </div>

        <!-- show plan -->
        <template v-if="(role_auth == 'admin_oem' || role_auth == 'master') && user.status != '解約中'">
            <hr class="md:my-5" v-if="user.role_name !== 'user_normal' && checkHidden()" />
            <div class="grid md:grid-cols-3 sm:grid-cols-2 md:px-12 sm:px-5 items-center text-2sm bg-background">
                <p class="md:py-0 sm:py-4">
                    プラン選択
                    <br />
                    <span v-if="user.suggest_update" class="text-pink-400 mt-2 text-sm whitespace-nowrap">
                        ユーザーに新たなプランを開放しました。
                    </span>
                </p>
                <div class="flex flex-wrap col-span-2">
                    <!-- <div class="w-full" v-if="user.role_name == 'user_oem'">
                                                                                        <button @click="fetchDataPlan()" type="button"
                                                                                            class="text-white bg-button_profile px-3 py-2 text-xs rounded-sm mb-5">
                                                                                            プラン料金をリセットする
                                                                                        </button>
                                                                                    </div> -->
                    <template v-for="(plan, index) in plans" :key="index">
                        <div class="flex w-full md:w-1/2 h-10 lg:h-8 items-center mt-3">
                            <input v-model="plan.checkbox" @change="choosePlanActive(plan.id)"
                                :disabled="checkDisablePlan(plan)" type="checkbox"
                                class="mr-2 border-input text-pink-400" />
                            <p class="px-3" :class="user.suggest_plan_id == plan.id ? 'text-pink-400' : ''">
                                {{ plan.name }}
                            </p>

                            <!-- check 50 < amount < 9999999 -->
                            <!-- <template v-if="user.role_name == 'user_oem' && plan.checkbox && plan.free == 0">
                                                                                                <input v-model="plan.amount" min="50" max="9999999"
                                                                                                    :disabled="user?.plan?.id == plan.id ? 'disabled' : false" type="number"
                                                                                                    class="rounded-sm p-1 w-24" />
                                                                                                <p class="px-3">{{ plan.amount == 0 ? 0 : '' }}円（税抜）</p>
                                                                                            </template> -->
                        </div>
                    </template>
                </div>
            </div>

            <hr class="md:my-5" />
            <div class="flex items-center justify-center w-full py-10 bg-background">
                <button @click="updatePlanUser()" as="button" type="submit"
                    class="bg-button_profile text-white px-20 py-6 mx-4 rounded-sm text-3sm"
                    :disabled="check_button_update">
                    プランを変更する
                </button>
            </div>
            <!-- <p v-else :class="user.plan !== null ? 'md:py-56' : 'md:py-10'"></p> -->
        </template>
</dashboard>
</template>

<script>
import Dashboard from '../../Dashboard.vue'
import { Link } from '@inertiajs/inertia-vue3'
import UserInformation from '@/Components/UserInformation'
import Loading from '@/Components/Loadding'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { ElMessageBox } from 'element-plus'
export default {
    props: {
        user: {
            type: Object,
            required: true,
        },

        toggleTabs: {
            type: Function,
            required: true,
        },

        fetchDataUser: {
            type: Function,
            require: true,
        },
    },

    components: {
        Dashboard,
        Link,
        UserInformation,
        Loading,
        BreadCrumb,
    },

    data() {
        return {
            plans: [],
            role_auth: false,
            isLoading: false,
            subscription_user: null,
            check_role_admin: false,
            check_cancel_date: false,
            check_plan_use_pay: null,
            check_button_update: true,
            plancheckbox: null,
        }
    },

    created() {
        this.fetchDataPlan()
        this.$page.props.auth.user.roles.map((role) => {
            this.role_auth = role.name
        })
        this.checkSubscriptionUser()
        this.checkPlanUsePayjp()
        this.checkRoleAdmin()
    },

    methods: {
        checkDisablePlan(plan) {
            return ['admin_agency', 'admin_oem', 'user_agency', 'user_oem'].includes(this.user.role_name) ? this.user?.plan?.id == plan.id ? true : false :
                this.user?.plan?.id == plan.id || this.user.suggest_plan_id == plan.id || this.check_plan_use_pay.includes(plan.id)
                    ? true
                    : false

        },
        checkHidden() {
            let checkHidden = true
            let adRole = ['admin_agency', 'admin_oem', 'user_agency', 'user_oem']
            let currPage = window.location.pathname.split('/')[1]
            if (currPage === 'users-detail') {
                checkHidden = adRole.includes(this.user.role_name) ? false : true
            } else {
                checkHidden = adRole.includes(this.$page.props.auth.user.roles[0].name) ? false : true
            }
            return checkHidden
        },
        getAmountPlanCrawl(amount) {
            const tax = 10;
            let result = amount + (amount * tax / 100);
            return Number(result)
        },
        formatDate(oldDate) {
            if (oldDate) {
                const date = new Date(oldDate)
                let day = date.getDate()
                let month = date.getMonth() + 1
                let year = date.getFullYear()
                return `${year}年${month}月${day}日`
            }
        },

        async checkSubscriptionUser() {
            await axios
                .get(route('api.user.subscriptions', this.user.id))
                .then((res) => {
                    if (res.data.status) {
                        this.subscription_user = res.data.status
                        this.check_cancel_date =
                            this.subscription_user == 'active' ||
                            (this.subscription_user == 'canceled' && this.user.check_cancel_date)
                    } else {
                        this.check_cancel_date = true
                    }
                })
                .catch((err) => { })
        },

        async checkPlanUsePayjp() {
            await axios
                .get(route('api.plans.use.pay', this.user.id))
                .then((res) => {
                    console.log(res.data)
                    this.check_plan_use_pay = res.data ?? null
                })
                .catch((err) => {
                    this.check_plan_use_pay = null
                })
        },

        checkRoleAdmin() {
            this.$page.props.auth.user.roles.filter((role) => {
                if (
                    role.name == 'user_agency' ||
                    role.name == 'user_oem' ||
                    role.name == 'user_enterprise' ||
                    role.name == 'user_normal'
                ) {
                    this.check_role_admin = false
                } else {
                    this.check_role_admin = true
                }
            })
        },

        choosePlanActive(plan_id) {
            this.plans = this.plans.map((plan) => ({
                id: plan.id,
                name: plan.name,
                amount: plan.amount,
                free: plan.free,
                checkbox: plan.id == plan_id ? true : false,
            }))
            this.plancheckbox = plan_id
        },

        async fetchDataPlan(page = null) {
            this.isLoading = true
            if (this.user.role == 'OEMユーザー') {
                await axios
                    .get('/api/get_plan_oem/' + this.user.id)
                    .then((res) => {
                        this.plans = res.data.data
                            .filter((plan) => {
                                //return plan.trial == 0 && plan.amount >= 50 && plan.check_ended_at == true
                                return plan.is_show_admin == 1
                            })
                            .map((plan) => ({
                                id: plan.id,
                                name: plan.name,
                                amount: plan.amount,
                                free: plan.free,
                                checkbox: plan.checkbox,
                            }))
                        this.isLoading = false
                    })
                    .catch((err) => {
                        this.isLoading = false
                    })
            } else {
                await axios
                    .get('/api/get_plan_user/' + this.user.id)
                    .then((res) => {
                        this.plans = res.data.data
                            .filter((plan) => {
                                //return plan.trial == 0 && plan.amount >= 50 && plan.check_ended_at == true
                                return plan.is_show_admin == 1
                            })
                            .map((plan) => ({
                                id: plan.id,
                                name: plan.name,
                                amount: plan.amount,
                                free: plan.free,
                                checkbox: plan.checkbox,
                            }))
                        this.isLoading = false
                    })
                    .catch((err) => {
                        this.isLoading = false
                    })
            }
        },

        async updatePlanUser() {
            this.isLoading = true
            let plan_choose = this.plans.find((plan) => {
                return plan.checkbox == true
            })

            if (this.user?.plan?.id == plan_choose.id) {
                this.isLoading = false
                return this.$toast.error(this.$t('Suggest error: User has this plan'))
            }

            let msg = this.$t('do_you_really_want_to_assign_plan', { plan_name: plan_choose.name })
            ElMessageBox.confirm(msg, '警告', {
                confirmButtonText: '同意',
                cancelButtonText: 'キャンセル',
                type: 'warning',
            })
                .then(async () => {
                    await axios
                        .post(route('api.users.user_oem_update_plan', this.user.id), plan_choose)
                        .then((res) => {
                            this.$toast.success(this.$t('Suggest plan for user successfully'))
                            this.isLoading = false
                            this.fetchDataUser(2)
                        })
                        .catch((err) => {
                            this.$toast.error(this.$t('Suggest plan for user error'))
                            this.isLoading = false
                        })
                })
                .catch(() => {
                    this.isLoading = false
                })
        },
    },

    watch: {
        //Check có phải là plan đang được gán or sủ dụng để disabled nút gán plan
        plans: function () {
            if (this.plancheckbox != null) {
                this.check_button_update = false
            }
        },
    },
}
</script>

<style lang="scss">
.el-button--primary {
    background-color: #5264cd !important;
    border: 1px solid #5264cd !important;
}

.el-button--primary span {
    color: white !important;
}
</style>
