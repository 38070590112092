<template>
  <Loading v-if="isLoading" />
  <div class="md:px-8 pb-10 bg-background">
    <div class="flex justify-between items-center">
      <h2 class="text-xl text-button_pink font-semibold py-5">PDF出力設定画面</h2>
    </div>

    <div class="container mx-auto">
      <div class="grid grid-cols-1 lg:grid-cols-12 gap-5 xl:gap-16">
        <!-- Perview -->
        <div class="p-2 border rounded-md shadow-gray-700 col-span-1 lg:col-span-6 mb-20">
          <div class="py-3 flex md:flex-row sm:flex-col md:items-center justify-between md:gap-0 sm:gap-3">
            <div class="text-title font-bold" style="color: #be409c">アカウント分析</div>
            <div class="flex flex-wrap justify-between w-2/3">
              <p class="text-nomal text-center">
                Username
                <br />
                <br />
                ST-MTP
              </p>
              <p class="text-nomal text-center">
                Instagram ID
                <br />
                <br />
                12345678910
              </p>
              <p class="text-nomal text-center">
                Source
                <br />
                <br />
                <template v-if="form.name_source == []">
                  <span class="text-pink font-bold text-title">1.Enter name source</span>
                </template>
                <template v-else>
                  {{ form.name_source }}
                </template>
              </p>
            </div>
          </div>
          <div class="py-3">
            <img :src="'/images/Pdf1.PNG'" alt="" class="w-full h-auto" />
          </div>
          <div class="py-3">
            <img :src="'/images/Pdf2.PNG'" alt="" class="w-full h-auto" />
          </div>
          <div class="py-3">
            <img :src="'/images/Pdf3.PNG'" alt="" class="w-full h-auto" />
          </div>

          <div class="flex justify-end items-end">
            <!-- perview img_copyright -->
            <div class="w-1/3 flex flex-col">
              <div class="flex items-center justify-center">
                <template v-if="urlCopyright == null">
                  <p class="text-pink font-bold text-sm pr-2">2.</p>
                  <img :src="'/images/logo_tryhatch.png'" alt="" class="w-20 h-auto" />
                </template>
                <template v-else>
                  <img :src="urlCopyright" alt="Logo" class="w-20 h-auto" />
                </template>
              </div>

              <template v-if="form.text_copyright == []">
                <p class="text-pink font-bold text-nomal text-center pt-2">3.Enter text copyright</p>
              </template>
              <template v-else>
                <p class="text-nomal text-center pt-2">{{ form.text_copyright }}</p>
              </template>
            </div>
            <!-- perview logo -->
            <div class="w-1/3 flex justify-end items-center">
              <template v-if="urlLogo == null">
                <p class="text-pink font-bold text-sm pr-2">4.</p>
                <img :src="'/img/favicon-upscale.png'" alt="" class="w-20 h-auto" />
              </template>
              <template v-else>
                <img :src="urlLogo" alt="Logo" class="w-20 h-auto" />
              </template>
            </div>
          </div>
        </div>

        <!-- Form -->
        <div class="col-span-1 lg:col-span-6">
          <form @submit.prevent="submitForm" class="md:px-5 pb-5 flex flex-col flex-wrap">
            <div class="w-full pb-5">
              <p class="font-bold text-title text-red-500">※変更したいものは以下にご設定ください。</p>
            </div>
            <div class="pt-5 w-full">
              <div class="pb-4">
                <p class="text-nomal">
                  1.Name source
                  <span class="text-red-500">*</span>
                </p>
              </div>
              <input
                v-model="form.name_source"
                @change="form.errors.name_source = ''"
                class="py-4 w-full border-gray-200 rounded-md"
                type="text"
                placeholder="Insta Cheki"
              />
              <div class="text-left text-red-600 text-nomal mt-2">{{ form.errors.name_source ?? null }}</div>
            </div>
            <div class="pt-5 w-full">
              <div class="pb-4">
                <p class="text-nomal">2.Company Logo (copyright)</p>
              </div>
              <div class="pt-2">
                <button
                  v-if="urlCopyright == null"
                  @click="fileUploadCopyright()"
                  type="button"
                  class="text-white bg-button_profile px-8 py-2 text-xs rounded"
                >
                  アップロード
                </button>
                <div v-else class="flex items-center">
                  <p class="pr">{{ nameFileCopyright }}</p>
                  <p class="px-5 text-button_pink underline cursor-pointer text-nomal" @click="fileUploadCopyright()">
                    ロゴを変更する
                  </p>
                  <div class="text-left text-red-600 text-sm mt-2">{{ form.errors.img_copyright ?? null }}</div>
                </div>
                <img v-if="urlCopyright" class="mt-3" :src="urlCopyright" style="width: 270px; height: 70px" />
              </div>
              <div class="text-left text-red-600 text-sm mt-2">{{ form.errors.img_copyright ?? null }}</div>
              <UploadImage
                field="img_copyright"
                @crop-success="cropSuccess"
                @crop-upload-success="cropUploadSuccess"
                @crop-upload-fail="cropUploadFail"
                v-model="showCropCopuright"
                :width="180"
                :height="50"
                langType="ja"
                img-format="png"
              ></UploadImage>
            </div>
            <div class="pt-5 w-full">
              <div class="pb-4">
                <p class="text-nomal">3.Text Copyright</p>
              </div>
              <input
                v-model="form.text_copyright"
                class="py-4 w-full border-gray-200 rounded-md"
                type="text"
                placeholder="2022 Insta Cheki"
              />
            </div>
            <div class="pt-5 w-full">
              <div class="pb-4">
                <p class="text-nomal">4.Insta Cheki Logo</p>
              </div>
              <div class="pt-2">
                <button
                  v-if="urlLogo == null"
                  @click="fileUploadLogo()"
                  type="button"
                  class="text-white bg-button_profile px-8 py-2 text-xs rounded"
                >
                  アップロード
                </button>
                <div v-else class="flex items-center">
                  <p class="pr">{{ nameFileLogo }}</p>
                  <p class="px-5 text-button_pink underline cursor-pointer text-nomal" @click="fileUploadLogo()">
                    ロゴを変更する
                  </p>
                  <div class="text-left text-red-600 text-sm mt-2">{{ form.errors.img_logo ?? null }}</div>
                </div>
                <img v-if="urlLogo" class="mt-3" :src="urlLogo" style="width: 270px; height: 70px" />
              </div>
              <UploadImage
                field="img_logo"
                @crop-success="cropSuccess"
                @crop-upload-success="cropUploadSuccess"
                @crop-upload-fail="cropUploadFail"
                v-model="showCropLogo"
                langType="ja"
                :width="180"
                :height="50"
                img-format="png"
              ></UploadImage>
              <div class="text-left text-red-600 text-sm mt-2">{{ form.errors.img_logo ?? null }}</div>
            </div>
            <!-- button submit -->
            <div class="flex justify-around md:gap-0 sm:gap-2">
              <button
                :disabled="disapbleReset"
                style="background: #be3d8f"
                @click="resetData()"
                class="mt-10 md:py-3 sm:py-1 md:w-52 sm:w-1/2 text-white button-shadow rounded text-btn-submit"
                type="button"
              >
                リセット
              </button>
              <button
                class="mt-10 md:py-3 sm:py-1 bg-blue-600 md:w-52 sm:w-1/2 text-white button-shadow rounded text-btn-submit"
                type="submit"
              >
                変更
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DrashBoard from '@/Layouts/Authenticated.vue'
import { Link, useForm } from '@inertiajs/inertia-vue3'
import { ElMessageBox } from 'element-plus'
import Loading from '@/Components/Loadding'
import UploadImage from 'vue-image-crop-upload'

export default {
  components: {
    DrashBoard,
    Link,
    Loading,
    useForm,
    UploadImage,
  },
  data() {
    return {
      form: useForm({
        name_source: '',
        text_copyright: '',
        img_logo: null,
        img_copyright: null,
      }),
      urlLogo: null,
      nameFileLogo: null,
      urlCopyright: null,
      nameFileCopyright: null,
      isLoading: false,
      showCropLogo: false,
      showCropCopuright: false,
      disapbleReset: true,
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    async resetData() {
      ElMessageBox.confirm('設定したものを取り消してもよろしいでしょうか？', '警告', {
        confirmButtonText: '同意',
        cancelButtonText: 'キャンセル',
        type: 'warning',
      }).then(() => {
        axios.get(route('api.reset.pdf.setting')).then((res) => {
          if (res.data) {
            this.fetchData()
            this.form = useForm({
              name_source: '',
              text_copyright: '',
              img_logo: null,
              img_copyright: null,
            })
            this.urlLogo = null
            this.urlCopyright = null
            this.$toast.success(this.$t('Reset form pdf success'))
            this.disapbleReset = true
          }
        })
      })
    },
    async fetchData() {
      await axios
        .get(route('api.get-pdf-page-by-admin'))
        .then((res) => {
          if (Object.keys(res.data.data).length !== 0) {
            this.disapbleReset = false
            this.form = useForm(JSON.parse(JSON.stringify(res.data.data)))
            this.urlLogo = this.form.img_logo
            this.urlCopyright = this.form.img_copyright
            this.form.img_logo = null
            this.form.img_copyright = null
          }
        })
        .catch((err) => {})
    },

    fileUploadLogo() {
      this.showCropLogo = !this.showCropLogo
      this.form.img_logo = null
    },

    fileUploadCopyright() {
      this.showCropCopuright = !this.showCropCopuright
      this.form.img_copyright = null
    },

    submitForm() {
      this.isLoading = true
      if (this.form.id) {
        const formData = new FormData()
        formData.append('user_id', this.form.user_id)
        formData.append('name_source', this.form.name_source)
        formData.append('text_copyright', this.form.text_copyright)
        if (this.form.img_logo !== null) {
          formData.append('img_logo', this.form.img_logo)
        }
        if (this.form.img_copyright !== null) {
          formData.append('img_copyright', this.form.img_copyright)
        }
        formData.append('_method', 'PUT')

        axios
          .post(route('api.api-pdf-page.update', this.form.id), formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((res) => {
            this.$toast.success('update form pdf successfully')
            this.fetchData
            this.isLoading = false
          })
          .catch((err) => {
            console.log(JSON.parse(JSON.stringify(err.errors)))
            // this.fetchData
            this.isLoading = false
          })
      } else {
        this.form.post(route('api.api-pdf-page.store'), {
          onFinish: () => {
            if (!this.form.hasErrors) {
              this.$toast.success(this.$t('create form pdf successfully'))
              this.fetchData
              this.disapbleReset = false
              this.isLoading = false
            } else {
              this.isLoading = false
            }
          },
        })
      }
    },

    cropSuccess(imgDataUrl, field) {
      console.log('-------- crop success --------')
      if (field == 'img_logo') {
        this.urlLogo = imgDataUrl
        this.form.img_logo = this.dataURLtoFile(imgDataUrl, 'img_logo')
      } else {
        this.urlCopyright = imgDataUrl
        this.form.img_copyright = this.dataURLtoFile(imgDataUrl, 'img_copyright')
      }
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },

    cropUploadSuccess(jsonData, field) {
      console.log('-------- upload success --------')
      if (field == 'img_logo') {
        console.log(jsonData, field)
      } else {
        console.log(jsonData, field)
      }
    },

    cropUploadFail(status, field) {
      console.log('-------- upload fail --------')
      if (field == 'img_logo') {
        console.log(status, field)
      } else {
        console.log(status, field)
      }
    },
  },

  watch: {},
}
</script>

<style lang="scss">
.vicp-operate a:first-child {
  background: #be3d8f;
  color: white !important;
  &:hover {
    background: #be3d8f !important;
  }
}
.vicp-operate-btn {
  background: #5264cd !important;
  border-radius: 4px !important;
  color: white !important;
  &:hover {
    background: #5264cd !important;
  }
}
.el-button--primary {
  background-color: #5264cd !important;
  border: 1px solid #5264cd !important;
}

.el-button--primary span {
  color: white !important;
}
</style>
