<template>
  <div class="chart--scroll linechart-contain">
    <Bar
      :chart-data="dataChart"
      :width="width"
      :height="height"
      :chart-options="chartOptions"
      :max_post="max_post"
      :max_engagement="max_engagement"
      :id="canvasId"
    />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Line } from 'vue-chartjs'
import { CategoryScale } from 'chart.js'
import {
  Chart,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from 'chart.js'

Chart.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement
)

export default {
  name: 'BarChart',
  components: { Bar, Line },
  props: {
    chartId: { type: String, default: 'bar-chart' },
    datasetIdKey: { type: String, default: 'label' },
    width: { type: Number, default: 400 },
    height: { type: Number, default: 400 },
    cssClasses: { default: '', type: String },
    styles: { type: Object, default: () => {} },
    dataChart: { label: [], datasets: [] },
    max_post: { type: Number, default: 0 },
    max_engagement: { type: Number, default: 0 },
    canvasId: { type: String },
  },
  data() {
    return {
      chartOptions: {
       responsive: true,
       maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || ''
                if (label == 'エンゲージメント率') {
                  label += ': ' + context.parsed.y + ' %'
                } else {
                  label += ': ' + context.parsed.y
                }
                return label
              },
            },
          },
          datalabels: {
            display: false,
          },
        },
        scales: {
          A: {
            type: 'linear',
            position: 'left',
            grid: {
              display: false,
            },
            max: this.max_post,
            ticks: {
              stepSize: this.max_post / 10,
            },
          },
          B: {
            type: 'linear',
            position: 'right',
            grid: {
              display: true,
            },
            max: this.max_engagement,
            ticks: {
              stepSize: this.max_engagement / 10,
            },
            min: 0,
          },
        },
      },
    }
  },
  mounted() {
    this.hiddenLegend()
  },
  watch: {},
  methods: {
    // get number
    resizeNumber(numberValue) {
      if (numberValue) {
        if (numberValue % 100 === 0) {
          return numberValue
        }
        numberValue++
        return this.resizeNumber(numberValue)
      }
      return 0
    },
    hiddenLegend() {
      if (this.max_post) {
        this.chartOptions.plugins.legend.display = true
      } else {
        this.chartOptions.plugins.legend.display = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.linechart-contain {
  #bar-chart {
    height: 400px !important;
  }
}
  .chart--scroll {
    padding: 1rem 0 !important;
    width: 100%;
    overflow: scroll;
    & > div {
      width: 1453px;
      height: 400px;
    }
  }
@media (max-width: 640px) {
  .linechart-contain {
    #bar-chart {
      height: 350px !important;
    }
  }
}
</style>