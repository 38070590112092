import { formatToInteger } from '@/Plugins/numberFormat'
import dayjs from 'dayjs'
import GetThumbnailUrl from '../../../Mixins/thumbnail.js'

export default {
  mixins: [GetThumbnailUrl],
  methods: {
    genHashtagDetailAnalyticPage(doc) {
      doc.addPage()
      doc.setFontSize(14) // set font size
      doc.setTextColor(190, 61, 143)
      doc.text('指定ハッシュタグ分析画面', 13, 30)
      doc.text('指定ハッシュタグ分析画面', 13, 30)
      doc.text('指定ハッシュタグ分析画面', 13, 30)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(10)

      doc.text('サマリー', 13, 40)
      let pageW = doc.internal.pageSize.getWidth()
      let textHashtagWidth = this.dataElacticHashtag.hashtagName
        ? doc.getTextWidth(this.dataElacticHashtag.hashtagName)
        : 10
      doc.text(String(this.dataElacticHashtag?.hashtagName), (pageW - textHashtagWidth) / 2, 40)

      const detailHashtagstartDate = dayjs(this.detailHashtagRange[0]).format('YYYY/MM/DD')
      const detailHashtagendDate = dayjs(this.detailHashtagRange[1]).format('YYYY/MM/DD')
      let compareDate = String(detailHashtagstartDate + ' - ' + detailHashtagendDate)

      const storyCanvas = document.createElement('canvas')
      storyCanvas.width = 250
      storyCanvas.height = 120

      let storyCanvasCtx = storyCanvas.getContext('2d')
      let x = 0
      let y = 0
      let widthStory = 220
      let heightStory = 95
      let radius = 10
      radius = { tl: radius, tr: radius, br: radius, bl: radius }
      storyCanvasCtx.beginPath()
      storyCanvasCtx.moveTo(x + radius.tl, y)
      storyCanvasCtx.lineTo(x + widthStory - radius.tr, y)
      storyCanvasCtx.quadraticCurveTo(x + widthStory, y, x + widthStory, y + radius.tr)
      storyCanvasCtx.lineTo(x + widthStory, y + heightStory - radius.br)
      storyCanvasCtx.quadraticCurveTo(x + widthStory, y + heightStory, x + widthStory - radius.br, y + heightStory)
      storyCanvasCtx.lineTo(x + radius.bl, y + heightStory)
      storyCanvasCtx.quadraticCurveTo(x, y + heightStory, x, y + heightStory - radius.bl)
      storyCanvasCtx.lineTo(x, y + radius.tl)
      storyCanvasCtx.quadraticCurveTo(x, y, x + radius.tl, y)
      storyCanvasCtx.closePath()
      storyCanvasCtx.fillStyle = '#f5f5f5'
      storyCanvasCtx.fill()

      doc.addImage(storyCanvas, 'PNG', 13, 45)
      doc.addImage(storyCanvas, 'PNG', 76, 45)
      doc.addImage(storyCanvas, 'PNG', 139, 45)

      doc.text('投稿数', 15, 50)
      doc.setFontSize(8)
      doc.text(compareDate, 15, 65)
      doc.setFontSize(10)
      let textTotalPostWidth = doc.getTextWidth(String(this.dataElacticHashtag.summary.posts_count))
      doc.text(
        String(formatToInteger(this.dataElacticHashtag.summary.posts_count)),
        69.208333334 - textTotalPostWidth,
        65
      )
      doc.text(
        String(formatToInteger(this.dataElacticHashtag.summary.posts_count)),
        69.208333334 - textTotalPostWidth,
        65
      )
      doc.text('いいね数', 78, 50)
      doc.setFontSize(8)
      doc.text(compareDate, 78, 65)
      doc.setFontSize(10)
      let textTotalLikesWidth = doc.getTextWidth(String(this.dataElacticHashtag.summary.likes_count))
      doc.text(String(formatToInteger(this.dataElacticHashtag.summary.likes_count)), 132.2083 - textTotalLikesWidth, 65)
      doc.text(String(formatToInteger(this.dataElacticHashtag.summary.likes_count)), 132.2083 - textTotalLikesWidth, 65)

      doc.text('コメント数', 141, 50)
      doc.setFontSize(8)
      doc.text(compareDate, 141, 65)
      doc.setFontSize(10)
      let textTotalCmtsWidth = doc.getTextWidth(String(this.dataElacticHashtag.summary.comments_count))
      doc.text(
        String(formatToInteger(this.dataElacticHashtag.summary.comments_count)),
        195.2083 - textTotalCmtsWidth,
        65
      )
      doc.text(
        String(formatToInteger(this.dataElacticHashtag.summary.comments_count)),
        195.2083 - textTotalCmtsWidth,
        65
      )

      const pageWidth = doc.internal.pageSize.getWidth()
      const pageHeight = doc.internal.pageSize.getHeight()

      const hashtagDetailAnalyticChart = document.getElementById('hashtag-detail-analytic-chart')
      const hashtagDetailAnalyticChartCanvas = hashtagDetailAnalyticChart
        ? hashtagDetailAnalyticChart.childNodes[0]
        : null
      const hashtagDetailAnalyticChartCanvasWRatio = (pageWidth / 1536) * 0.9
      const hashtagDetailAnalyticChartCanvasHRatio = (pageHeight / 600) * 0.9
      const accRatio =
        hashtagDetailAnalyticChartCanvasWRatio > hashtagDetailAnalyticChartCanvasHRatio
          ? hashtagDetailAnalyticChartCanvasHRatio
          : hashtagDetailAnalyticChartCanvasWRatio
      const hashtagDetailAnalyticChartWidth = 1536 * accRatio
      const hashtagDetailAnalyticChartHeight = 600 * accRatio

      if (hashtagDetailAnalyticChart) {
        doc.addImage(
          hashtagDetailAnalyticChartCanvas,
          'PNG',
          10,
          75,
          hashtagDetailAnalyticChartWidth,
          hashtagDetailAnalyticChartHeight
        )
      }
      doc.text('同時使用ハッシュタグランキング', 13, hashtagDetailAnalyticChartHeight + 80)
      doc.autoTable({
        styles: {
          font: 'GenShinGothic',
          fontSize: 8,
          halign: 'center',
          valign: 'middle',
        },
        columnStyles: {
          0: { cellWidth: 80 },
        },
        startY: hashtagDetailAnalyticChartHeight + 85,
        head: [['ハッシュタグ', '使用投稿数', '平均いいね数', '平均コメント数']],
        body: this.mapHashtagDetailTableData(this.hashtagTableData),
      })
    },
    mapHashtagDetailTableData(data) {
      let arrData = []
      data.forEach((item) => {
        let mapData = []
        mapData.push(item?.key)
        mapData.push(formatToInteger(item?.doc_count))
        mapData.push(formatToInteger(item?.likes_count?.value.toFixed(2)))
        mapData.push(formatToInteger(item?.comments_count?.value.toFixed(2)))
        arrData.push(mapData)
      })

      return arrData
    },
  },
}
