<template>
  <div class="bg-white p-4">
    {{ titte }}
    <div v-if="chartData.datasets.length > 0" class="pt-4">
      <Bar :chart-options="chartOptions" :chart-data="chartData" />
    </div>
    <el-empty v-else description="データはありません"  ></el-empty>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
export default {
  name: 'BarChart',
  components: {
    Bar,
  },
  props: {
    titte: {
      type: String,
    },
    chartData: {
      type: Object,
      require: true,
    },
  },

  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: '',
          },
          datalabels: {
            display: false,
          },
        },
      },
    }
  },
}
</script>
