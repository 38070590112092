<template>
    <div>
        <table class="table__info">
            <tr v-for="(value, label) in data" :key="label">
                <td class="py-4 pl-7">{{ label }}</td>
                <td class="py-4 bg-white pl-8 text-zinc-400">{{ value }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: ["data"],
};
</script>

<style scoped>
.table__info {
    /* font-family: Arial, Helvetica, sans-serif; */
    border-collapse: collapse;
    width: 100%;
}

.table__info td,
.table__info th {
    border: 1px solid #ddd;
}

.table__info tr td:first-of-type {
    background-color: #f2f2f2;
}

.table__info tr:hover {
    background-color: #ddd;
}
</style>
