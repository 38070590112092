<template>
    <div class="detail px-6">
        <div class="flex justify-between border-b-2 border-zinc-400 pt-2 pb-7">
            <div class="user flex items-center gap-x-4">
                <img src="/images/user-avatar.png" alt="" />
                <div>
                    <h2>KIGEKI</h2>
                    <p>今月のログイン回数:30</p>
                </div>
            </div>
            <div class="flex items-center gap-x-5">
                <button class="bg-fuchsia-700 text-white py-3 px-6">
                    代理ログイン
                </button>
                <button class="bg-black text-white py-3 px-6">
                    代理ログイン
                </button>
                <button class="bg-black text-white py-3 px-6">
                    代理ログイン
                </button>
            </div>
        </div>
        <div class="flex py-5">
            <div class="basis-3/6">
                <h2 class="mb-5 font-semibold text-purple-400">口座情報</h2>
                <InfoTable :data="infoTable" />
            </div>
            <div class="basis-3/6 pl-8">
                <h2 class="mb-5 font-semibold text-purple-400">利用明細</h2>
                <Table :label="tableLabel" :data="tableData" :action="'請求書'" />
            </div>
        </div>
        <div class="flex justify-center gap-x-4">
            <button class="bg-blue-600 text-white py-3 px-6">
                代理ログイン
            </button>
            <button
                class="bg-white py-3 px-6 border text-blue-600 border-blue-600"
            >
                代理ログイン
            </button>
        </div>
    </div>
</template>

<script>
import Table from "@/Components/Table.vue";
import InfoTable from "@/Components/InfoTable.vue";
export default {
    components: {
        Table,
        InfoTable,
    },
    data() {
        return {
            tableLabel: ["Date", "Trạng thái", "Số tiền"],
            tableData: [
                {
                    startDate: "2021/12/1",
                    status: "決済完了",
                    amount: "35,000",
                },
                {
                    startDate: "2021/12/1",
                    status: "決済完了",
                    amount: "35,000",
                },
                {
                    startDate: "2021/12/1",
                    status: "決済完了",
                    amount: "35,000",
                },
                {
                    startDate: "2021/12/1",
                    status: "決済完了",
                    amount: "35,000",
                },
                {
                    startDate: "2021/12/1",
                    status: "決済完了",
                    amount: "35,000",
                },
                {
                    startDate: "2021/12/1",
                    status: "決済完了",
                    amount: "35,000",
                },
            ],
            infoTable: {
                ステータス: "契約中",
                権限: "代理店管理者",
                プラン名: "エンタープライズプラン",
                "月額料金(税別)": 55000,
                決済方法: "クレジットカード",
                利用開始日: "2022/1/1",
                満了日: "2022/6/30",
                自動更新回数: 0,
            },
        };
    },
};
</script>

<style scoped>
.detail {
    background: #f5f5f5;
}
.detail button {
    box-shadow: 0px 0px 6px rgba(81, 5, 52, 0.25);
}
</style>
