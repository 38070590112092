<template>
  <DrashBoard>
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li>
          <Link :href="route('admin.group-list.index')" class="font-normal breakcumb-active">グルーピング</Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="md:pt-8 md:pl-10 sm:pt-0 sm:pl-0 tabs bg-white" style="margin-top: 0 !important">
        <ul class="flex">
          <Link :href="route('admin.group-list.index')" as="li" class="px-2 relative pb-5 active">グルーピング</Link>
        </ul>
      </div>
    </template>
    <div class="bg-background pb-20 min-h-screen">
      <div class="px-6">
        <div class="flex justify-between items-center md:block">
          <h2 class="text-3sm text-button_pink font-bold py-5">グループ一覧</h2>
          <div class="button-mobile pb-3 md:pb-0 md:ml-auto sm:ml-0">
            <Link
              :href="route('admin.group-list.create')"
              as="button"
              type="button"
              class="bg-blue-600 text-white px-11 rounded button-shadow h-10 flex items-center text-3sm"
            >
              +新規作成
            </Link>
          </div>
        </div>

        <div class="flex flex-col-reverse md:grid md:grid-cols-2 pb-3.5">
          <div class="flex sm:mb-2 md:gap-0 sm:gap-2.5">
            <div class="md:mr-5 sm:mr-0 md:w-96 sm:w-72 md:mb-0 sm:mb-2 md:h-10">
              <el-input
                v-model="seachGroup"
                placeholder="検索キーワードを入力してください"
                size="large"
                class="text-2sm"
                clearable
                @keyup.enter="getGroupList"
              />
            </div>
            <el-button class="el-button__primary md:mb-0 md:!h-10 md:w-20 sm:mb-2" size="large" @click="getGroupList">
              <span class="text-white">検索</span>
            </el-button>
          </div>
          <div class="button-des pb-3 md:pb-0 md:ml-auto sm:ml-0">
            <Link
              :href="route('admin.group-list.create')"
              as="button"
              type="button"
              class="bg-blue-600 text-white px-11 rounded button-shadow h-10 flex items-center text-3sm"
            >
              +新規作成
            </Link>
          </div>
        </div>

        <section class="mx-auto mt-2.5">
          <div class="w-full overflow-hidden rounded-lg shadow-lg">
            <div class="w-full overflow-x-auto">
              <table class="table-fixed w-full table-group-list">
                <thead>
                  <tr class="text-2sm text-left bg-gray-100 border-b border-gray-600">
                    <th
                      class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2"
                      v-for="(item, index) in label"
                      :key="index"
                      :style="{
                        width: item.width + 'px',
                        minWidth: (item?.minWidth ? item.minWidth : 150) + 'px',
                      }"
                    >
                      {{ item.text }}
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-body_table" v-if="totalData > 0">
                  <tr class="text-2sm border-b hover:bg-gray-200" v-for="(item, index) in dataGroup" :key="index">
                    <td class="py-6 pl-4 text-center word">
                      {{ item.group_name }}
                    </td>
                    <td class="py-6 text-center flex flex-wrap md:justify-start sm:justify-center min-h-[85px]">
                      <div
                        class="user-tags mx-5 bg-graylight pl-4 pr-3 my-1 text-xs"
                        v-for="user in item.account_name"
                        :key="user.id"
                      >
                        @{{ user.username }}
                      </div>
                    </td>
                    <td class="py-6 text-center">
                      <Link
                        :href="'/group-list/' + item.id"
                        as="button"
                        type="button"
                        class="px-9 py-2 bg-blue-600 rounded button-shadow mr-2 text-white"
                        :disabled="item.has_posts"
                      >
                        編集
                      </Link>
                      <button
                        @click="openDeletePopup(item)"
                        class="px-9 py-2 bg-button_table rounded button-shadow text-white"
                        :disabled="item.has_posts"
                      >
                        削除
                      </button>

                      <Link
                        :href="'/group-analysis/' + item.id"
                        type="button"
                        class="px-6 ml-2 py-2 bg-blue-600 rounded button-shadow mr-2 text-white"
                      >
                        分析
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <el-empty description="データはありません" v-if="!totalData > 0" />
        <div class="pt-4 pb-9 flex justify-center">
          <el-pagination
            :page-size="Number(10)"
            :pager-count="5"
            layout="prev, pager, next"
            :total="Number(totalData)"
            :current-page="currentPage || 1"
            @current-change="handleCurrentChange"
            class="is-background"
          />
        </div>

        <Popup :active="deleteSelect" @updateStatusPopup="deleteSelect = $event" @deletePlan="deleteGroup" />
      </div>
    </div>
  </DrashBoard>
  <Popup
    :textWarning="'このグループを削除しても宜しいですか。'"
    :active="deleteSelect"
    @updateStatusPopup="deleteSelect = $event"
    @deletePlan="deleteGroup"
  />
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import Popup from '@/Components/PopUp.vue'
import Pagination from '@/Components/Pagination.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import * as groupApi from '@/Api/Group'
export default {
  components: {
    Pagination,
    DrashBoard,
    BreadCrumb,
    UserNav,
    Popup,
    Link,
  },
  data() {
    return {
      totalData: 1,
      currentPage: 1,
      totalPage: 1,
      deleteSelect: false,
      label: [
        { text: 'グループ名', width: 150 },
        { text: '配下アカウント', width: 400 },
        { text: '操作', width: 300 },
      ],
      dataGroup: [],
      idItemDelete: '',
      seachGroup: '',
      isShowData: true,
    }
  },
  async created() {
    this.getGroupList()
  },
  methods: {
    handleCurrentChange(value) {
      this.currentPage = value
    },
    openDeletePopup(item) {
      this.idItemDelete = item
      this.deleteSelect = true
    },
    editGroup: function (item) {},
    async getGroupList() {
      const res = await groupApi.getGroupList(this.currentPage, this.seachGroup)
      this.totalData = res.data.meta.total
      this.dataGroup = res.data.data
      this.currentPage = res.data.meta.current_page
    },
    deleteGroup: async function () {
      try {
        await groupApi.deleteGroup(this.idItemDelete)
        this.$toast.success(this.$t('delete group successfully'))
        this.getGroupList()
        this.deleteSelect = false
      } catch (error) {
        this.$toast.error(this.$t('something went wrong'))
      }
    },
  },
  watch: {
    seachGroup: function () {
      this.getGroupList()
    },
    currentPage: function () {
      this.getGroupList()
    },
  },
}
</script>

<style lang="scss" scoped>
.button-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .button-mobile {
    display: inline-block;
  }
  .button-des {
    display: none;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}
.user-tags {
  border-radius: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.search_icon {
  padding: 10px 16px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #d02d92;
}
.select__status {
  background: #ffffff;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
}
.el-button__primary {
  background-color: #5264cd;
  border: 1px solid #5264cd;
}

.el-button__primary span {
  color: white !important;
}
</style>
