<template>
  <div>
    <Bar
      class="mx-auto p-4"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :plugins="plugins"
      style="position: relative; height: 150vh;"
    />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'

export default {
  name: 'AllBarChart',
  components: { Bar },
  props: {
    chartId: { type: String, default: 'bar-chart' },
    plugins: { type: Array, default: () => [] },
    dataCityForChart: { type: Object, default: () => {} },
  },
  data() {
    return {
      listCountry: [],
      showModal: false,
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'フォロワー',
            backgroundColor: '#0097D3',
            data: [],
          },
        ],
      },
      chartOptions: {
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          tooltip: {
            xAlign: {
              label: function (context) {
                context.dataset.label.xAlign = 'left'
                return context.dataset.label.xAlign
              },
            },
          },
          legend: {
            position: 'top',
            align: 'start',
            title: {
              display: true,
            },
          },
          datalabels: {
            display: false,
          },
        },
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        scales: {
          x: {
            grid: {
              borderColor: 'transparent',
            },
          },
          y: {
            grid: {
              display: false,
              borderColor: 'transparent',
            },
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
          },
        },
      },
    }
  },
  created() {
    this.getData()
  },
  watch: {
    dataCityForChart: function () {
      this.getData()
    },
  },
  methods: {
    async getData() {
      if (this.dataCityForChart && Object.keys(this.dataCityForChart)?.length) {
        this.prepareDataForChart()
        this.showData = true
      } else {
        this.showData = false
      }
    },
    prepareDataForChart() {
      let totalDataCountry = {}
      const audience_city = this.dataCityForChart?.audience_city

      const isJson = this.IsJsonString(audience_city)
      if (!isJson) return

      for (const [index, item] of Object.entries(JSON.parse(audience_city))) {
        if (totalDataCountry.hasOwnProperty(index)) {
          totalDataCountry[index] += item
        } else {
          totalDataCountry[index] = item
        }
      }

      for (const [key, item] of Object.entries(totalDataCountry)) {
        if (!this.chartData.labels.includes(key)) {
          this.chartData.labels.push(key)
        }
      }

      for (const city of this.chartData.labels) {
        this.chartData.datasets[0].data = [...this.chartData.datasets[0]?.data, totalDataCountry[city]]
      }

      this.chartData.labels = this.chartData.labels.map((label) => {
        return this.$t(label)
      })
    },
    IsJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
  },
}
</script>

<style></style>
