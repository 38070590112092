<template>
  <h3 class="text-option font-semibold pl-2 pt-4 text-nomal">フォロワー男女比</h3>
  <Pie
    v-if="showData"
    class="mx-auto p-4"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :plugins="plugins"
  />
  <NoData v-else />
</template>

<script>
import { Pie } from 'vue-chartjs'
import NoData from './NoData.vue'
export default {
  name: 'PieChart',
  components: { Pie, NoData },
  props: {
    range: { type: Object, default: 0 },
    analyticType: { type: String, default: 'month' },
    chartId: { type: String, default: 'pie-chart' },
    datasetIdKey: { type: String, default: 'label' },
    width: { type: Number, default: 400 },
    height: { type: Number, default: 400 },
    styles: { type: Object, default: () => {} },
    plugins: { type: Object, default: () => [] },
    dataGenderForChart: { type: Array, default: [] },
    totalMemberGroup: { type: Number, default: 0 },
  },
  data() {
    return {
      accountId: '',
      listGender: [],
      showData: true,
      chartData: {
        labels: ['女性', '男性', 'その他'],
        datasets: [
          {
            backgroundColor: ['#D20058', '#0097D3', '#DEE2E5'],
            data: [],
            datalabels: {
              formatter: (value) => {
                return value + '%'
              },
            },
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { position: 'top', align: 'start', title: { display: true, position: 'start', align: 'start' } },
          datalabels: { display: false },
        },
      },
      listDataGender: [],
    }
  },
  watch: {
    dataGenderForChart: function () {
      this.getData()
    },
  },
  methods: {
    async getData() {
      if (this.dataGenderForChart.length > 0 && this.totalMemberGroup > 0) {
        await this.prepareDataForChart()
      } else {
        this.showData = false
      }
    },
    prepareDataForChart() {
      this.listDataGender = []
      this.dataGenderForChart.forEach((account_insight) => {
        if (account_insight.audience_gender_age) {
          this.listDataGender.push(account_insight.audience_gender_age)
        }
      })

      this.showData = this.listDataGender.length > 0

      let dataWomen = 0
      let dataMen = 0
      let dataOther = 0
      this.listDataGender.forEach((listData) => {
        const isJson = this.IsJsonString(listData)
        if (!isJson) return

        for (const [index, item] of Object.entries(JSON.parse(listData))) {
          if (index.includes('F.')) {
            dataWomen += item
          }
          if (index.includes('M.')) {
            dataMen += item
          }
          if (index.includes('U.')) {
            dataOther += item
          }
        }
      })

      // Calculate the average number of members in the group
      dataWomen = dataWomen / this.totalMemberGroup
      dataMen = dataMen / this.totalMemberGroup
      dataOther = dataOther / this.totalMemberGroup

      const totalDataGender = dataWomen + dataMen + dataOther
      let dataGenderForChart = [
        ((dataWomen / totalDataGender) * 100).toFixed(2),
        ((dataMen / totalDataGender) * 100).toFixed(2),
        ((dataOther / totalDataGender) * 100).toFixed(2),
      ]

      this.chartData.datasets[0].data = dataGenderForChart
    },
    IsJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
  },
}
</script>

<style></style>
