<template>
  <h3 class="text-option font-semibold pl-4 pt-4 text-nomal">フォロワー年齢層</h3>
  <Bar
    v-if="showData"
    class="mx-auto p-4"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :plugins="plugins"
  />
  <NoData v-else></NoData>
</template>

<script>
import { Bar } from 'vue-chartjs'
import NoData from './NoData.vue'
export default {
  name: 'BarChart',
  components: {
    Bar,
    NoData,
  },
  props: {
    range: { type: Object, default: 0 },
    chartId: { type: String, default: 'bar-chart' },
    width: { type: Number, default: 400 },
    height: { type: Number, default: 400 },
    plugins: { type: Array, default: () => [] },
    dataGenderForChart: { type: Object, default: () => {} },
  },
  data() {
    return {
      accountId: '',
      showData: true,
      listAges: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: '女性',
            backgroundColor: '#D20058',
            data: [],
          },
          {
            label: '男性',
            backgroundColor: '#0097D3',
            data: [],
          },
          {
            label: 'その他',
            backgroundColor: '#DEE2E5',
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          tooltip: {
            xAlign: {
              label: function (context) {
                context.dataset.label.xAlign = 'left'
                return context.dataset.label.xAlign
              },
            },
          },
          legend: {
            position: 'top',
            align: 'start',
            title: {
              display: true,
              position: 'start',
              align: 'start',
            },
          },
          datalabels: {
            display: false,
          },
        },

        elements: {
          bar: {
            borderWidth: 1,
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              borderColor: 'transparent',
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
              borderColor: 'transparent',
            },
          },
        },
      },
    }
  },
  created() {
    this.getData()
  },
  watch: {
    dataGenderForChart: function () {
      this.getData()
    },
  },
  methods: {
    async getData() {
      if (this.dataGenderForChart && Object.keys(this.dataGenderForChart)?.length) {
        await this.prepareDataForChart()
        this.showData = true
      } else {
        this.showData = false
      }
    },
    prepareDataForChart() {
      let dataLabel = []
      let totalDataAge = {}
      const audience_gender_age = this.dataGenderForChart.audience_gender_age

      const isJson = this.IsJsonString(audience_gender_age)
      if (!isJson) return

      for (const [index, item] of Object.entries(JSON.parse(audience_gender_age))) {
        //add labels
        const key = index.split('.')[1]
        if (!dataLabel.includes(key)) {
          dataLabel.push(key) || 0
        }

        //Calculation total
        if (totalDataAge.hasOwnProperty(index)) {
          totalDataAge[index] += item
        } else {
          totalDataAge[index] = item
        }
      }

      this.showData = this.listAges.length > 0
      dataLabel = dataLabel.sort()
      //Set data gender by age default = 0
      let dataInit = []
      for (let val of dataLabel) {
        dataInit.push(0)
      }

      let dataWomen = [...dataInit],
        dataMen = [...dataInit],
        dataOther = [...dataInit]

      for (const [index, item] of Object.entries(totalDataAge)) {
        const indexOfLabel = dataLabel.indexOf(index.split('.')[1])
        if (index.includes('F.')) {
          dataWomen[indexOfLabel] = item
        }
        if (index.includes('M.')) {
          dataMen[indexOfLabel] = item
        }
        if (index.includes('U.')) {
          dataOther[indexOfLabel] = item
        }
      }

      this.chartData.labels = dataLabel
      this.chartData.datasets[0].data = dataWomen
      this.chartData.datasets[1].data = dataMen
      this.chartData.datasets[2].data = dataOther
      this.showData = true
    },
    IsJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
  },
}
</script>

<style></style>
