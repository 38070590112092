<template>
  <dashboard>
    <UserInformation />
    <!-- <div
            class="fb-login-button"
            data-width=""
            data-size="large"
            data-button-type="continue_with"
            data-layout="default"
            data-auto-logout-link="false"
            data-use-continue-as="false"
            @click="handleLoginInsta()"
            :scope="scope"
        ></div> -->
    <button @click="handleLoginInsta">
      <img class="custom-ins" src="/images/ins.png" />
    </button>
    <div class="mt-12 ml-10 tabs">
      <ul class="flex">
        <li class="px-6 relative pb-5 active font-sans">アカウント管理</li>
        <li class="px-6 relative pb-5 font-sans">決済方法・プラン管理</li>
        <li class="px-6 relative pb-5 font-sans">ご利用明細</li>
        <li class="px-6 relative pb-5 font-sans">代理ログイン</li>
      </ul>
    </div>
    <form class="px-10 bg-background">
      <div class="grid grid-cols-1 gap-4 py-8 border-b border-[#D5D2D3]">
        <div class="form-group align-items items-center grid grid-cols-payment">
          <label class="text-black" for="">親ユーザ会社名</label>
          <input
            type="text"
            class="h-14 border border-solid border-input rounded disable-color"
            v-model="userSelected.company"
            disabled
          />
        </div>

        <div class="form-group align-items items-center grid grid-cols-payment">
          <label class="text-black col-span-1" for="">親アカウントプラン</label>
          <input
            type="text"
            class="h-14 border border-solid border-input rounded disable-color"
            v-model="userSelected.plan"
            disabled
          />
        </div>
        <div class="form-group align-items items-center grid grid-cols-payment">
          <label class="text-black col-span-1" for="">親アカウント権限</label>
          <input
            type="text"
            class="h-14 border border-solid border-input rounded disable-color"
            v-model="userSelected.plan"
            disabled
          />
        </div>
      </div>

      <div class="grid grid-cols-2 gap-20 py-8">
        <div class="form-left">
          <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
            <label class="text-black" for="">企業名</label>
            <input
              type="text"
              class="col-span-2 h-14 border border-solid border-input rounded"
              v-model="userSelected.company"
            />
          </div>

          <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
            <div class="label">
              <label class="grow text-black block" for="">企業ロゴ</label>
            </div>
            <button class="bg-button_profile text-white w-52 py-2 rounded-sm">アップロード</button>
          </div>

          <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
            <div class="label">
              <label class="grow text-black block" for="">パスワード</label>
              <span class="text-black">英数字8文字以上</span>
            </div>
            <input
              class="col-span-2 h-14 border border-solid border-input pl-3 rounded disable-color"
              type="password"
              value="password"
              disabled
            />
          </div>

          <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
            <label class="text-black col-span-1" for="">利用プラン</label>
            <input
              type="text"
              class="col-span-2 h-14 border border-solid border-input rounded disable-color"
              v-model="userSelected.plan"
              disabled
            />
          </div>

          <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
            <label class="text-black col-span-1" for="">月額金額</label>
            <input
              type="text"
              class="col-span-2 h-14 border border-solid border-input rounded disable-color"
              value="thiếu trường-Số tiền hàng tháng"
              disabled
            />
          </div>

          <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
            <label class="text-black col-span-1" for="">契約終了日</label>
            <input
              type="text"
              class="col-span-2 h-14 border border-solid border-input rounded disable-color"
              :value="formatDate(userSelected.contact_expriation_date)"
              disabled
            />
          </div>
        </div>
        <div class="form-right">
          <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
            <label class="text-black col-span-1" for="">E-mail</label>
            <input
              type="text"
              class="col-span-2 h-14 border border-solid border-input rounded"
              v-model="userSelected.email"
            />
          </div>
          <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
            <label class="text-black col-span-1" for="">ID</label>
            <input
              type="text"
              class="col-span-2 h-14 border border-solid border-input rounded disable-color"
              v-model="userSelected.id"
              disabled
            />
          </div>
          <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
            <label class="text-black col-span-1" for="">権限</label>
            <input
              type="text"
              class="col-span-2 h-14 border border-solid border-input rounded disable-color"
              v-model="userSelected.roles"
              disabled
            />
          </div>
          <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
            <label class="text-black col-span-1" for="">決済タイプ</label>
            <input
              type="text"
              class="col-span-2 h-14 border border-solid border-input rounded disable-color"
              v-model="userSelected.plan"
              disabled
            />
          </div>
          <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
            <label class="text-black col-span-1" for="">利用開始日</label>
            <input
              type="text"
              class="col-span-2 h-14 border border-solid border-input rounded disable-color"
              :value="formatDate(userSelected.created_at)"
              disabled
            />
          </div>
          <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
            <label class="text-black col-span-1" for="">自動更新回数</label>
            <input
              type="text"
              class="col-span-2 h-14 border border-solid border-input rounded disable-color"
              value="thiếu trường-Số lượng cập nhật tự động"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center w-full mt-5 pb-4">
        <button class="bg-pink-400 text-white px-20 py-6 mx-4 rounded-sm">決済方法を更新する</button>
        <button class="bg-button_profile text-white px-20 py-6 mx-4 rounded-sm">決済方法を更新する</button>
      </div>
      <vue-final-modal v-model="isShow">
        <div class="modal-body p-[10px] md:w-6/10 w-8/10">
          <form>
            <div v-for="(item, i) in businessAccount" :key="i">
              <input type="radio" v-model="account" :value="item" />
              {{ item.name }}
            </div>
            <button type="button" @click="submit">保存</button>
          </form>
        </div>
      </vue-final-modal>
    </form>
  </dashboard>
</template>

<script>
import Dashboard from '../../Dashboard.vue'
import UserInformation from '@/Components/UserInformation.vue'
import * as accounts from '@/Api/Users'

export default {
  props: ['userId'],
  components: {
    Dashboard,
    UserInformation,
  },
  data() {
    return {
      userSelected: {},
      isShow: false,
      businessAccount: [],
      account: null,
    }
  },
  created() {
    this.getInfoDetail()
  },
  computed: {
    scope() {
      return [
        'public_profile',
        'email',
        'pages_show_list',
        'pages_show_list',
        'pages_manage_metadata',
        'pages_read_engagement',
        'pages_read_user_content',
        'instagram_basic',
        'instagram_content_publish',
        'instagram_manage_insights',
        'instagram_manage_comments',
        'business_management',
      ].join(',')
    },
  },
  methods: {
    handleLoginInsta() {
      FB.login(
        (response) => {
          if (!response.authResponse) {
            return
          }

          const access_token = response.authResponse.accessToken
          axios
            .get(
              'https://graph.facebook.com/me/accounts?fields=instagram_business_account{name,profile_picture_url},access_token&&access_token=' +
                access_token
            )
            .then((res) => {
              const data = res.data.data
                .filter((item) => item.instagram_business_account)
                .map((v) => ({
                  access_token: v.access_token,
                  ...v.instagram_business_account,
                }))
              this.businessAccount = data
              this.isShow = true
            })
            .catch((error) => console.log(error))
        },
        {
          scope: this.scope,
        }
      )
    },
    submit() {
      accounts.insAccountLink(this.account, this.userId)
      this.isShow = false
      return alert('done')
    },
    async getInfoDetail() {
      const res = await accounts.getUserDetail(this.userId)
      this.userSelected = res.data.data
    },
    formatDate(oldDate) {
      if (oldDate) {
        const date = new Date(oldDate)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        return `${year}年${month}月${day}日`
      }
    },
  },
}
</script>

<style scoped>
.custom-ins {
  width: 150px;
  height: auto;
  display: inline;
}
.disable-color {
  color: #81858b;
}

.modal-body {
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 9px;
}
</style>
