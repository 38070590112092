<template>
    <dashboard>
        <div class="h-full flex overflow-hidden">
            <div class="relative flex-1 bg-gray-100">
                <Loading v-if="isLoading"/>
                <div class="my-12 px-5 w-full lg:w-11/12 2xl:w-3/4 mx-auto">
                    <div class="plan">
                        <div class="title text-center py-8">
                            <div class="logo">
                                <!--Logo-->
                                <Link class="py-2">
                                    <img src="/img/favicon-upscale.png" alt="Favicon Upscale" width="152" height="28">
                                </Link>
                            </div>
                            <div class="text-2xl py-2">有料プランへのアップグレード</div>
                        </div>
                        <div class="grid grid-cols-2 lg:gap-40 md:gap-10 sm:gap-1 py-8">
                            <div class="form-left">
                                <div class="form-group align-items mb-8 grid grid-cols-4 items-center">
                                    <div class="block text-button_pink sm:whitespace-nowrap">ご請求先</div>
                                </div>
                                <div class="form-group align-items mb-8 grid grid-cols-4 items-center">
                                    <div class="block text-button_pink sm:whitespace-nowrap">プラン名</div>
                                </div>
                                <div class="form-group align-items mb-8 grid grid-cols-4 items-center">
                                    <div class="block text-button_pink sm:whitespace-nowrap">契約期間</div>
                                </div>
                                <div class="form-group align-items mb-8 grid grid-cols-1 items-center">
                                    <div class="block text-button_pink sm:whitespace-nowrap">ご請求金額
                                        ({{ formatDate(plan.started_at) }} 〜 {{ formatDate(plan.one_month) }})
                                    </div>
                                </div>

                            </div>
                            <div class="form-right">
                                <div class="form-group align-items mb-8 grid grid-cols-1 text-right">
                                    <div class="text-header_table sm:whitespace-nowrap">
                                        {{ $page.props.auth.user.company }}
                                    </div>
                                </div>
                                <div class="form-group align-items mb-8 grid grid-cols-1 text-right">
                                    <div class="text-header_table sm:whitespace-nowrap">{{ plan.name }}</div>
                                </div>
                                <div class="form-group align-items mb-8 grid grid-cols-1 text-right">
                                    <div class="text-header_table sm:whitespace-nowrap">
                                        {{ formatDate(plan.started_at) }} 〜 {{ formatDate(plan.ended_at) }}
                                    </div>
                                </div>
                                <div class="form-group align-items mb-8 grid grid-cols-1 text-right">
                                    <div class="text-header_table sm:whitespace-nowrap">
                                        {{ (plan.amount_after_tax ?? 0 ).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })}} （税込）
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                   <div class="py-5">
                        <h1 class="text-pink text-xl font-semibold">決済方法</h1>
                        <a class="flex items-center my-8">
                            <img class="w-48" title="Credit Card Logos" src="@shared/uploads/card_payment.png"
                                    width="413" height="59" border="0"/>
                            <h1 class="mx-3">がご利用いただけます</h1>
                        </a>
                        <div v-show="!showNewCard" class="list-card">
                            <div v-for="card in cards" :key="card.id" class="item-card mb-2">
                                <label>
                                    <input type="radio" v-model="chooseCard" :value="card.payjp_card_id"/>
                                    **** **** **** {{ card.last4 }}
                                </label>
                            </div>
                            <a href="javascript:" @click="showNewCard = true" class="mt-2 text-indigo-600 underline">
                                {{ $t('add_new_card') }}
                            </a>
                            <div class="flex w-full justify-center my-6">
                                <button
                                    class="w-1/2 md:w-1/3 xl:w-1/4 max-w-xs bg-button_pink py-5 text-white button-shadow rounded"
                                    @click="callbackUpdatePlan">
                                    {{ $t('turn_back') }}
                                </button>
                                <div class="w-10"></div>
                                <button
                                    class="w-1/2 md:w-1/3 xl:w-1/4 max-w-xs bg-indigo-500 py-5 text-white button-shadow rounded"
                                    @click="paymentPlan()">
                                    {{ $t('send_payment_card') }}
                                </button>
                            </div>
                        </div>
                        <section v-show="showNewCard" class="mdc my-10" id="v2-mdc">
                            <form class="flex flex-wrap">
                                <div class="w-full error" role="alert">
                                    <span class="message"></span>
                                </div>
                                <div class="my-6 w-full lg:w-1/2 pr-0 lg:pr-5 flex justify-between items-center">
                                    <p class="">決済タイプ</p>
                                    <div class="w-5/6 lg:w-3/4">
                                        <input
                                            class="field text-field"
                                            disabled
                                            type="text"
                                            placeholder="クレジットカード"/>
                                    </div>
                                </div>
                                <div class="my-6 w-full lg:w-1/2 pl-0 lg:pl-5 flex justify-between items-center">
                                    <p class="">カード番号</p>
                                    <div class="w-5/6 lg:w-3/4">
                                        <label class="field text-field" for="v2-mdc-card-number">
                                            <div id="v2-mdc-card-number" class="text-field__input"></div>
                                            <span class="text-field__ripple"></span>
                                            <span class="floating-label"></span>
                                            <span class="line-ripple"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="my-6 w-full lg:w-1/2 pr-0 lg:pr-5 flex justify-between items-center">
                                    <p class="w-1/6 lg:w-1/4">カード有効期限</p>
                                    <div class="w-5/6 lg:w-3/4">
                                        <label class="field text-field" for="v2-mdc-card-expiry">
                                            <div id="v2-mdc-card-expiry" class="text-field__input"></div>
                                            <span class="text-field__ripple"></span>
                                            <span class="floating-label"></span>
                                            <span class="line-ripple"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="my-6 w-full lg:w-1/2 pl-0 lg:pl-5 flex justify-between items-center">
                                    <p class="">CVC</p>
                                    <div class="w-5/6 lg:w-3/4">
                                        <label class="field text-field" for="v2-mdc-card-cvc">
                                            <div id="v2-mdc-card-cvc" class="text-field__input"></div>
                                            <span class="text-field__ripple"></span>
                                            <span class="floating-label"></span>
                                            <span class="line-ripple"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="my-6 w-full lg:w-1/2 pr-0 lg:pr-5 flex justify-between items-center">
                                    <p class="">カード名義</p>
                                    <div class="w-5/6 lg:w-3/4">
                                        <label
                                            class="field text-field mdc-text-field mdc-text-field--filled">
                                            <span class="mdc-text-field__ripple"></span>
                                            <span class="mdc-floating-label"></span>
                                            <input id="v2-mdc-name"
                                                    class="mdc-text-field__input"
                                                    autocomplete="cc-name"
                                                    type="text"
                                                    placeholder="例、TARO TANAKA"
                                            >
                                            <span class="mdc-line-ripple"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="flex w-full justify-center my-6">
                                    <button
                                        class="w-1/2 md:w-1/3 xl:w-1/4 max-w-xs bg-button_pink py-5 text-white button-shadow rounded"
                                        @click="callbackUpdatePlan">
                                        {{ $t('turn_back') }}
                                    </button>
                                    <div class="w-10"></div>
                                    <button
                                        class="mdc-button w-1/2 md:w-1/3 xl:w-1/4 custom max-w-xs py-5 text-white button-shadow rounded">
                                        {{ $t('send_payment_card') }}
                                    </button>
                                    <span class="mdc-button__ripple"></span>
                                    <span class="mdc-button__label"></span>
                                </div>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </dashboard>
</template>
<script>
import {Link} from '@inertiajs/inertia-vue3'
import Loading from '@/Components/Loadding'
import * as mdc from "@/Store/mdc";
import * as planAPI from "@/Api/Plan.js";
import Dashboard from "@/Layouts/Authenticated.vue";

export default {
    props: ['plan_craw','customer','subscriptions'],
    components: {
        Loading,
        Dashboard,
        Link
    },
    data() {
        return {
            data: "",
            value: "",
            isActive: false,
            nameCard: "",
            elements: "",
            message: "",
            payjp: null,
            isLoading: false,
            plan: {},
            cards: [],
            chooseCard: '',
            showNewCard: true,
            subscriptionData: "",
        };
    },

    async mounted() {
        await this.fetchDataUser();
        // Get list card
        this.getListCard()

        const me = this;
        var user = this.$page.props.auth.user;
        var buttonElm = mdc.setUpMDC();
        if (Payjp && buttonElm !== null) {
            var payjp = Payjp(process.env.MIX_PAYJP_PK);
            var elements = payjp.elements();
            var elementStyle = {
                base: {
                    fontFamily: '\'Noto Sans Japanese\', sans-serif',
                    '::placeholder': {
                        color: 'rgba(0, 0, 0, 0.54)',
                    },
                    caretColor: '#198FCC',
                    lineHeight: '28px',
                },
                invalid: {
                    //color: 'rgba(0, 0, 0, 0.87)',
                },
            }
            // 入力フォームを分解して管理・配置できます
            var numberElement = elements.create('cardNumber', {
                style: elementStyle,
                placeholder: '4242 4242 4242 4242'
            })
            var expiryElement = elements.create('cardExpiry', {
                style: elementStyle,
            })
            var cvcElement = elements.create('cardCvc', {
                style: elementStyle,
            })
            numberElement.mount('#v2-mdc-card-number')
            expiryElement.mount('#v2-mdc-card-expiry')
            cvcElement.mount('#v2-mdc-card-cvc')

            var payjpElements = [numberElement, expiryElement, cvcElement]
            var nameInput = document.querySelector('#v2-mdc-name')
            var section = document.querySelector('section#v2-mdc')
            var form = section.querySelector('form')
            var errorElm = section.querySelector('.error')
            var changes = {}

            function displayErrorMsg(msg) {
                errorElm.classList.add('visible')
                errorElm.querySelector('.message').innerText = msg
            }

            payjpElements.forEach(function (element, idx) {
                changes[idx] = null
                element.on('change', function (event) {
                    changes[idx] = event
                    buttonElm.setAttribute('disabled', 'true')
                    if (event.error) {
                        displayErrorMsg(event.error.message)
                    } else {
                        var errorMsg = Object.keys(changes).sort().reduce(function (msg, i) {
                            if (msg) {
                                return msg
                            } else if (changes[i] && changes[i].error) {
                                return changes[i].error.message
                            }
                            return null
                        }, null)

                        if (errorMsg) {
                            displayErrorMsg(errorMsg)
                        } else {
                            errorElm.classList.remove('visible')
                            buttonElm.removeAttribute('disabled')
                        }
                    }
                })
            })

            form.addEventListener('submit', function (e) {
                e.preventDefault()
                if (!Object.keys(changes).sort().reduce(function (prev, i) {
                    return prev && changes[i] && changes[i].complete
                }, true)) {
                    return displayErrorMsg('入力が完了していません。')
                }

                var options = {
                    card: {
                        name: nameInput.value || undefined
                    }
                }

                buttonElm.setAttribute('disabled', 'true')
                form.classList.add('submitting')
                me.isLoading = true;

                payjp.createToken(payjpElements[0], options).then(async function (result) {
                    form.classList.remove('submitting')
                    buttonElm.removeAttribute('disabled')

                    if (result.id) {
                        form.classList.add('submitted')
                        // call api card
                        const token = result.id;
                        // Set card Default when add card
                        if (result.card.cvc_check === 'passed' || result.card.cvc_check === "unavailable") {
                            //Create customersss
                            const customer = await planAPI.cardSetDefault(me.subscriptionData.payjp_customer_id, token, me.plan.note);
                            // udpate Subscription
                            if (customer.data.httpStatus === 401){
                                me.isLoading = false;
                                window.location.href = route('login.user')
                            }else if (customer.data.httpStatus === 400 || customer.data.httpStatus === 402) {

                                me.isLoading = false;
                                displayErrorMsg(me.$t(customer.data.jsonBody.error.code))
                            } else {
                                const article = {
                                    payjp_customer_id: me.subscriptionData.payjp_customer_id,
                                    payjp_plan_id: me.subscriptionData.payjp_plan_id,
                                    payjp_subscription_id: me.subscriptionData.payjp_subscription_id,
                                };
                                await axios.post(route('api.subscription_recurring'), article)
                                    .then((response) => {
                                            me.isLoading = false;
                                            me.$toast.success(me.$t('payment success'));
                                            let param = {
                                                'update': response.data.update ?? 0,
                                                'plan_id': me.plan.plan_id,
                                            }
                                            window.location.href = route('admin.payment.success', param)
                                        }
                                    )
                                    .catch((err) => {
                                        me.isLoading = false;
                                        me.$toast.error(me.$t(err.response.data.msg));
                                    })
                            }
                        } else if (result.card.cvc_check === "failed") {
                            me.isLoading = false;
                            displayErrorMsg(me.$t("cvc_check=failed"))
                        }
                        // else if (result.card.cvc_check === "unavailable") {
                        //     me.isLoading = false;
                        //     displayErrorMsg(me.$t("cvc_check=unavailable"))
                        // }

                    } else {
                        me.isLoading = false;
                        displayErrorMsg(result.error.message)
                    }
                })
            })
        }
    },
    created() {
    },

    methods: {
        callbackUpdatePlan() {
            return this.$inertia.visit(route('admin.page_update_plan'))
        },

        async fetchDataUser() {
            //get id user login
            this.userId = this.$page.props.auth.user.id;
            // call api get plans
            let plan_crawl = await planAPI.getPlanCrawl(this.plan_craw);
            this.plan = plan_crawl.data.data;
            this.subscriptionData = this.subscriptions;
        },

        formatDate(oldDate) {
            if (oldDate) {
                const date = new Date(oldDate);
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();
                return `${year}年${month}月${day}日`;
            }
        },

        async getListCard() {
            let result = await planAPI.getListCard(this.$page.props.auth.user.id)
            this.cards = result.data
            result.data.forEach(item => {
                if (item.default) {
                    this.chooseCard = item.payjp_card_id
                }
            })
        },

        async paymentPlan() {
            this.isLoading = true;
            const article = {
                payjp_customer_id: this.subscriptionData.payjp_customer_id,
                payjp_plan_id: this.subscriptionData.payjp_plan_id,
                payjp_subscription_id: this.subscriptionData.payjp_subscription_id,
                card_id: this.chooseCard,
                is_checkout: false
            };

            await axios.post(route('api.subscription_recurring'), article)
                .then((response) => {

                        this.isLoading = false;
                        this.$toast.success(this.$t('Recurring billing has resumed'));
                        let param = {
                            'update': response.data.update ?? 0,
                            'plan_id': this.plan.plan_id,
                        }
                        window.location.href = route('admin.payment.success', param)
                    }
                )
                .catch((err) => {

                    this.isLoading = false;
                    this.$toast.error(this.$t(err.response.data.msg));
                })
        }
    },

    watch: {
        cards: function () {
            if (this.cards.length) {
                this.showNewCard = false
            }
        }
    }
};
</script>

<style scoped>
@import '~@/Pages/Users/assets/css/payment.css';
.custom
{
    font-size: 16px !important;
    color: white;
    height: 4rem;
    padding: 0 !important;
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}
</style>
