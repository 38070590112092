<template>
    <BreezeGuestLayout bg_form_login="bg-blue-200">
        <Head title="Log in" />

        <form @submit.prevent="submit">
            <div class="flex items-start flex-col justify-center flex-sm">
                <BreezeLabel for="email" class="w-32 font-bold text-nomal">
                    メールアドレス
                    <span class="text-red-600">*</span>
                </BreezeLabel>
                <BreezeInput
                    id="email"
                    type="email"
                    class="block md:w-96 sm:w-full rounded-sm md:p-3 text-nomal"
                    v-model="form.email"
                    autofocus
                    autocomplete="email"
                    placeholder="メールアドレスを入力"
                    :class="form.errors.email ? 'border-red-600' : ''"
                />
                <validate-authentication :isShow="false" v-if="form.errors.email">{{ form.errors.email }}</validate-authentication>
            </div>
            <div class="pt-4 flex items-start flex-col justify-center flex-sm">
                <BreezeLabel for="password" class="w-32 font-bold text-nomal">
                    パスワード
                    <span class="text-red-600">*</span>
                </BreezeLabel>
                <BreezeInput
                    id="password"
                    type="password"
                    class="block md:w-96 sm:w-full rounded-sm md:p-3 text-nomal"
                    v-model="form.password"
                    autocomplete="current-password"
                    placeholder="パスワードを入力"
                    :class="form.errors.password ? 'border-red-600' : ''"
                />
            </div>
            <validate-authentication :isShow="false" v-if="form.errors.password">{{ form.errors.password }}</validate-authentication>
            <validate-authentication :isShow="false" v-if="form.errors.auth_failed">
                 <p class="text-red-600" v-html="form.errors.auth_failed"></p>
            </validate-authentication>
            <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                {{ status }}
            </div>



            <div class="flex items-center justify-center mt-7">
                <BreezeButton
                    class="bg-indigo-500 hover:bg-indigo-700 md:pt-5 md:pb-5 sm:pt-3 pl-16 pr-16 rounded-sm text-btn-submit"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                >
                    ログイン
                </BreezeButton>
            </div>

            <div class="flex items-center justify-center mt-12">
                <Link
                    v-if="canResetPassword"
                    :href="route('user.password.request', {
                        page_login : (role == 'oem'
                            ? 'om'
                            : role == 'agency'
                                ? 'ag'
                                : 'ep'
                        )
                    })"
                    class="text-indigo-600 hover:text-indigo-400 text-2sm"
                >
                    パスワードをお忘れの方はこちら
                </Link>
            </div>
        </form>
    </BreezeGuestLayout>
</template>

<script>
import BreezeButton from "@/Components/Button.vue";
import BreezeGuestLayout from "@/Layouts/Guest.vue";
import BreezeInput from "@/Components/Input.vue";
import BreezeLabel from "@/Components/Label.vue";
import BreezeValidationErrors from "@/Components/ValidationErrors.vue";
import { Head, Link, useForm } from "@inertiajs/inertia-vue3";
import ValidateAuthentication from "@/Components/ValidateAuth.vue"

export default {
    components: {
        BreezeButton,
        BreezeGuestLayout,
        BreezeInput,
        BreezeLabel,
        BreezeValidationErrors,
        ValidateAuthentication,
        Head,
        Link,
        useForm,
    },

    props: {
        status: {
            type: String,
            required: false,
        },
        role: {
            type: String,
            required: false,
        },
        canResetPassword: {
            type: Boolean,
            required: false,
        },
        errors: {
            type: Boolean
        }
    },

    data() {
        return {
            form: useForm({
                email: "",
                password: "",
                remember: false,
            }),
        };
    },

    methods: {
        redirectRoute() {
            if (this.role === "oem") {
                return route("login.om");
            } else if (this.role === "agency") {
                return route("login.ag");
            } else if (this.role === "enterprise") {
                return route("login.ep");
            }
        },

        submit() {
            this.form.post(this.redirectRoute(), {
                onFinish: () => {
                    this.form.reset("password");
                },
            });
        },
    },
    watch: {
        'form.email': function(){
            this.form.errors.email = false
        },
        'form.password': function(){
            this.form.errors.password = false
        }
    }
};
</script>
