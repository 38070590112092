<template>
  <DrashBoard>
    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-2 relative pb-5 active">投稿</li>
          <li class="px-2 relative pb-5">投稿済一覧</li>
        </ul>
      </div>
    </template>
    <div class="bg-background pb-20">
      <UserNav />
      <div class="statistic px-10">
        <h2 class="text-xl text-button_pink font-semibold py-5">投稿一覧</h2>
        <div class="menu flex items-center mb-6">
          <div class="menu__search flex relative rounded">
            <input class="flex-1 mr-5 rounded input-cm" placeholder="検索" type="text"  />
            <div class="bg-blue-600 text-white px-7 py-4.5 w-max">
              <button>コメント分析</button>
            </div>
          </div>
        </div>
        <Table :label="tableLabel" :data="tableData">
          <template v-slot:first-column>
            <td class="flex justify-center items-center pt-4.5 pb-3">
              <img
                class="rounded-full border-circle-img"
                src="/images/unsplash_AIAx1HC3nDc.png  "
                alt=""
              />
            </td>
          </template>
          <template v-slot:end-column>
            <carousel :items-to-show="2">
              <slide v-for="slide in 10" :key="slide">
                <img
                  class=""
                  src="/images/unsplash_n6sSLQ_gIVQ.png"
                  alt=""
                />
              </slide>
              <template #addons>
                <navigation />
              </template>
            </carousel>
          </template>
        </Table>
        <PaginationTable  :totalPage = "7" :currentPage = "1" />
      </div>
    </div>
  </DrashBoard>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import Table from "@/Components/Table.vue";
import PaginationTable from "@/Components/Pagination.vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import DrashBoard from "@/Layouts/Authenticated.vue";
import UserNav from "@/Components/UserNav.vue";
export default {
  components: {
    DrashBoard,
    UserNav,
    Table,
    PaginationTable,
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      tableLabel: [
        "プロフィール写真",
        "アカウント名/フォロワー数",
        "いいね数",
        "コメント数",
        "リーチ数",
        "保存数",
        "反応したコンテンツ",
      ],
      tableData: [
        {
          title: "Satoshi01フォロワー数：1,000",
          payment: "100",
          amount: "3",
          Data: "2",
          startDate: "50",
        },
        {
          title: "Satoshi01フォロワー数：1,000",
          payment: "100",
          amount: "3",
          Data: "2",
          startDate: "50",
        },
        {
          title: "Satoshi01フォロワー数：1,000",
          payment: "100",
          amount: "3",
          Data: "2",
          startDate: "50",
        },
        {
          title: "Satoshi01フォロワー数：1,000",
          payment: "100",
          amount: "3",
          Data: "2",
          startDate: "50",
        },
        {
          title: "Satoshi01フォロワー数：1,000",
          payment: "100",
          amount: "3",
          Data: "2",
          startDate: "10",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  max-width: 220px;
  margin: 0 auto;
  img {
    width: 90%;
  }
}
</style>
