import { useProps } from "@/Composables/prop";


export default {
    methods: {
        __getUser() {
            return useProps().value?.auth?.user;
        },
        hasPermission(permission) {

            const permissions = this.__getUser()?.permissions;
            return permissions.find((item) => item.name === permission);
        },
        hasRole(roleName) {
            const role = this.__getUser()?.roles?.first();
            return role?.name === roleName;
        },
    },
};
