<template>
  <div class="fixed top-0 left-0 w-full z-index__max">
    <Transition name="bounce">
      <div v-if="active" class="fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white close-popup">
        <div class="flex items-center justify-between">
          <p class="text-close">警告</p>
          <svg @click="closePopup" class="icon-close" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="currentColor"
              d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"
            ></path>
          </svg>
        </div>
        <h3 class="flex items-center justify-start warning-icon gap-4.5 pt-5 pb-3">
          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="currentColor"
              d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 192a58.432 58.432 0 0 0-58.24 63.744l23.36 256.384a35.072 35.072 0 0 0 69.76 0l23.296-256.384A58.432 58.432 0 0 0 512 256zm0 512a51.2 51.2 0 1 0 0-102.4 51.2 51.2 0 0 0 0 102.4z"
            ></path>
          </svg>
          <div class="el-message-box__message">
            <p class="warning-text">{{ textWarning }}</p>
          </div>
        </h3>
        <div class="flex justify-end">
          <button @click="closePopup" class="btn-close bg-white border text-white py-2 rounded mx-1 whitespace-nowrap">
            キャンセル
          </button>
          <button @click="deletePlan" class="btn-confirm text-white py-2 rounded mx-1 whitespace-nowrap">同意</button>
        </div>
      </div>
    </Transition>
    <Transition @click="closePopup()" name="fade">
      <div v-if="active" class="h-screen overlay w-full absolute z-index__max bg-black opacity-50 top-0 left-0"></div>
    </Transition>
  </div>
</template>

<script>
export default {
  props: ['type', 'active', 'textWarning'],
  methods: {
    closePopup() {
      this.$emit('updateStatusPopup', false)
    },
    deletePlan() {
      this.$emit('deletePlan')
    },
  },
  mounted() {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode == 27) {
        this.$emit('updateStatusPopup', false)
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.bounce-leave-active {
  animation: bounce-in 0.1s reverse;
}
@keyframes bounce-in {
  0% {
    top: 45%;
    opacity: 0;
  }
  100% {
    top: 50%;
    opacity: 1;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.btn-close {
  color: #414141;
  vertical-align: middle;
  padding: 8px 15px;
  white-space: nowrap;
  border: solid 1px #dcdfe6;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 500;
  &:hover {
    background-color: #ecf5ff;
  }
}
.close-popup {
  border: solid 1px #ebeef5;
  text-align: left;
  border-radius: 5px;
  padding: 15px 15px 10px 15px;
  width: 420px;
  z-index: 9999;
  .text-close {
    color: #414141;
  }
  svg.icon-close {
    color: #909399;
    height: 1em;
    width: 1em;
    line-height: 1em;
    &:hover {
      cursor: pointer;
    }
  }
}
.warning-icon {
  color: #e6a23c;
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
  .warning-text {
    font-size: 0.85rem;
  }
}
.btn-confirm {
  background-color: #5264cd !important;
  border: 1px solid #5264cd !important;
  padding: 8px 15px;
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 1;
}
@media (max-width: 640px) {
  .close-popup {
    width: 90%;
  }
}
</style>
