<template>
    <DrashBoard>
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li class="mr-4">
                    <Link :href="route('admin.users.index')" class="font-normal breakcumb-active">ユーザー管理</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:dashboard>
            <div class="flex">
                <h2>ダッシュボード</h2>
                <span>></span>
                <h3>ユーザー管理</h3>
            </div>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li class="px-6 relative pb-5 active">ユーザー管理</li>
                </ul>
            </div>
        </template>

        <div class="md:px-12 sm:px-5 bg-background py-10">
            <div class="flex justify-between flex-col">
                <div class="flex justify-between items-center">
                    <p class="text-3sm text-button_pink font-bold md:pb-5 sm:pb-0">ユーザー管理</p>
                    <div v-if="$page.props.auth.user.roles[0].name == 'admin_agency' ? false : true">
                        <Link :href="route('admin.users.create')"
                            class="bg-bluelight text-white inline-block px-5 py-2.5 w-max rounded button-shadow cursor-pointer text-3sm">
                        ユーザーを作成
                        </Link>
                    </div>
                </div>

                <div class="flex flex-wrap items-end w-full mb-5 justify-start">
                    <div class="flex flex-wrap md:mr-5 sm:mr-0 justify-start">
                        <div class="sm:mr-0 md:mr-5 my-2" v-if="hasRole('master')">
                            <p class="pb-2 text-2sm">権限</p>
                            <el-select v-model="filter.filter_role" placeholder="権限" size="large"
                                :clearable="clearable_role" @change="onSearch()">
                                <el-option label="指定なし" :value="0" class="text-2sm" />
                                <el-option v-for="item in roles" :key="item.value" :label="item.name"
                                    :value="item.label" class="text-2sm" />
                            </el-select>
                        </div>
                        <div class="sm:mr-0 md:mr-5 my-2">
                            <p class="pb-2 text-2sm">利用プラン</p>
                            <el-select v-model="filter.filter_plan" placeholder="利用プラン" size="large"
                                :clearable="clearable_plan" @change="onSearch()">
                                <el-option label="指定なし" :value="0" class="text-2sm" />
                                <el-option v-for="item in plans" :key="item.id" :label="item.name" :value="item.id"
                                    class="text-2sm" />
                            </el-select>
                        </div>
                        <div class="sm:mr-0 md:mr-5 my-2">
                            <p class="pb-2 text-2sm">契約状況</p>
                            <el-select v-model="filter.filter_status" placeholder="指定なし" size="large"
                                :clearable="clearable_status" @change="onSearch()">
                                <el-option label="指定なし" :value="0" class="text-2sm" />
                                <el-option label="無料プラン利用中" value="1" class="text-2sm" />
                                <el-option label="解約中" value="2" class="text-2sm" />
                                <el-option label="解約済" value="3" class="text-2sm" />
                                <el-option label="契約終了" value="4" class="text-2sm" />
                                <el-option label="未決済" value="5" class="text-2sm" />
                                <el-option label="契約中" value="6" class="text-2sm" />
                                <el-option label="パスワード未設定" value="7" class="text-2sm" />
                                <el-option label="未契約" value="8" class="text-2sm" />
                            </el-select>
                        </div>
                        <div class="my-2">
                            <p class="pb-2 text-2sm">ジャンル</p>
                            <el-select v-model="filter.filter_category" placeholder="ジャンル" size="large"
                                :clearable="clearable_category" @change="onSearch()">
                                <el-option label="指定なし" :value="0" class="text-2sm" />
                                <el-option label="美容" value="美容" class="text-2sm" />
                                <el-option label="ショッピング" value="ショッピング" class="text-2sm" />
                                <el-option label="スタイル" value="スタイル" class="text-2sm" />
                                <el-option label="食べ物/グルメ" value="食べ物/グルメ" class="text-2sm" />
                                <el-option label="動物" value="動物" class="text-2sm" />
                                <el-option label="インテリアデザイン" value="インテリアデザイン" class="text-2sm" />
                                <el-option label="DIY" value="DIY" class="text-2sm" />
                                <el-option label="建築物" value="建築物" class="text-2sm" />
                                <el-option label="アート" value="アート" class="text-2sm" />
                                <el-option label="コミック" value="コミック" class="text-2sm" />
                                <el-option label="自然" value="自然" class="text-2sm" />
                                <el-option label="テレビ/映画" value="テレビ/映画" class="text-2sm" />
                                <el-option label="旅行" value="旅行" class="text-2sm" />
                                <el-option label="雑貨" value="雑貨" class="text-2sm" />
                                <el-option label="ファッション" value="ファッション" class="text-2sm" />
                                <el-option label="フィットネス" value="フィットネス" class="text-2sm" />
                                <el-option label="ガジェット" value="ガジェット" class="text-2sm" />
                            </el-select>
                        </div>
                    </div>
                    <div class="flex sm:flex-col md:flex-row items-center">
                        <div class="sm:mr-0 md:mr-5 my-2 el_daterange">
                            <p class="pb-2 text-2sm">利用開始日</p>
                            <el-date-picker v-model="filter.filter_started_at" type="daterange"
                                start-placeholder="日付を指定" end-placeholder="日付を指定" @change="onSearch()" class="text-2sm"
                                popper-class="customDatePickerFirst" />
                        </div>
                        <div class="my-2 el_daterange">
                            <p class="pb-2 text-2sm">契約満了日</p>
                            <el-date-picker v-model="filter.filter_ended_at" type="daterange" start-placeholder="日付を指定"
                                end-placeholder="日付を指定" @change="onSearch()" class="text-2sm"
                                popper-class="customDatePickerSecond" />
                        </div>
                    </div>
                </div>
                <div class="flex flex-col md:flex-row mb-5">
                    <el-input type="text" v-model="filter.search" placeholder="検索キーワードを入力してください"
                        class="sm:mr-0 md:mr-5 md:w-96 rounded-sm my-auto md:h-10 text-2sm" size="large" clearable
                        @change="onSearch" @keyup.enter="onSearch" />
                    <button @click.prevent="onSearch"
                        class="bg-bluelight text-white px-7 w-max rounded button-shadow cursor-pointer md:w-20 h-10 flex items-center text-2sm justify-center mt-3 md:mt-0">
                        検索
                    </button>
                </div>
            </div>

            <!-- datatable lis hash tag -->
            <div>
                <data-table :fields="fields" :items="users.data" :paginate="users.meta" :current-page="filter.page || 1"
                    disable-table-info footer-center paginate-background @page="handleCurrentPage"
                    class="el-table-header__black">
                    <template #login_proxy="{ row }">
                        <button :disabled="
                            !(
                                !(!hasRole('master') && !hasRole('admin_oem')) &&
                                !['master'].includes(row?.role_name) &&
                                !row?.token_register &&
                                !row?.account_lock
                            ) || row?.retry_time
                        " @click="impersonate(row?.id)"
                            class="bg-bluelight text-white px-3.5 py-2 block m-auto button-shadow rounded text-2sm">
                            ログイン
                        </button>
                    </template>

                    <template #instagram="{ row }">
                        <div v-if="row?.token_register == null" class="flex justify-center"
                            :class="{ instaColor: row?.account }">
                            <template v-if="hasRole('master') && !['master'].includes(row?.role_name)">
                                <i @click="handleLoginInsta(row?.id)"
                                    class="fab fa-instagram text-3xl cursor-pointer"></i>
                            </template>
                            <template v-else>
                                <i class="fab fa-instagram text-3xl"></i>
                            </template>
                        </div>
                    </template>

                    <template #started_at="{ row }">
                        <p v-if="['未決済', 'パスワード未設定'].includes(row?.status)"></p>
                        <p v-else>
                            {{ formatDate(row?.started_at) }}
                        </p>
                    </template>

                    <template #ended_at="{ row }">
                        <p v-if="['未決済', 'パスワード未設定'].includes(row?.status)"></p>
                        <p v-else>{{ formatDate(row?.ended_at) }}</p>
                    </template>

                    <template #status="{ row }">
                        <p v-if="row?.status" class="flex items-center justify-center">
                            <template v-if="row.status == 'パスワード未設定'">
                                <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-pink"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                        fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                    <path
                                        d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                        fill="#BE3D8F" />
                                </svg>

                                <span class="text-pink ml-2">{{ row.status }}</span>
                            </template>
                            <template v-if="row.status == '契約中'">
                                <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-blue-600"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                        fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                    <path
                                        d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                        fill="#5264CD" />
                                </svg>

                                <span class="text-blue-600 ml-2">{{ row.status }}</span>
                            </template>
                            <template v-if="row.status == '契約終了'">
                                <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-pink"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                        fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                    <path
                                        d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                        fill="#BE3D8F" />
                                </svg>
                                <span class="text-pink ml-2">{{ row.status }}</span>
                            </template>
                            <template v-if="row.status == '無料プラン利用中'">
                                <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-blue-600"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                        fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                    <path
                                        d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                        fill="#5264CD" />
                                </svg>
                                <span class="text-blue-600 ml-2">{{ row.status }}</span>
                            </template>
                            <template v-if="row.status == '解約中'">
                                <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-pink"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                        fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                    <path
                                        d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                        fill="#BE3D8F" />
                                </svg>
                                <span class="text-pink ml-2">{{ row.status }}</span>
                            </template>
                            <template v-if="row.status == '未決済'">
                                <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-pink"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                        fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                    <path
                                        d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                        fill="#BE3D8F" />
                                </svg>
                                <span class="text-pink ml-2">{{ row.status }}</span>
                            </template>
                            <template v-if="row.status == '解約済'">
                                <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-pink"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                        fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                    <path
                                        d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                        fill="#BE3D8F" />
                                </svg>
                                <span class="text-pink ml-2">{{ row.status }}</span>
                            </template>
                            <template v-if="row.status == '未契約'">
                                <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-pink"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                        fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                    <path
                                        d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                        fill="#BE3D8F" />
                                </svg>
                                <span class="text-pink ml-2">未契約</span>
                            </template>
                        </p>
                    </template>

                    <template #detail="{ row }">
                        <div class="flex justify-center items-center">
                            <Link v-if="row?.id && row.role !== null"
                                class="bg-bluelight text-white px-5 py-2 block m-auto button-shadow rounded cursor-pointer"
                                :href="route('admin.users_detail', row.id)" :data="{ activeTab: 2 }">
                            <p class="text-white whitespace-nowrap">プラン変更</p>
                            </Link>
                            <button v-else class="bg-bluelight text-white px-5 py-2 block m-auto button-shadow rounded"
                                disabled>
                                <p class="text-white whitespace-nowrap">プラン変更</p>
                            </button>
                        </div>
                    </template>

                    <template #actions="{ row }">
                        <div class="flex justify-center items-center">
                            <template v-if="hasRole('master') && !['master'].includes(row?.role_name)">
                                <button
                                    class="min-w-[60px] py-2 text-[16px] bg-red-400 text-white px-3.5 block button-shadow rounded cursor-pointer m-auto ml-2"
                                    @click="lockUnlockAccount(row)"
                                    :class="{ 'unlock-button': row?.account_lock === 0 }">
                                    <i class="fas fa-unlock" v-if="row?.account_lock === 0"></i>
                                    <i class="fas fa-lock" v-else></i>
                                </button>
                                <button
                                    class="min-w-[60px] h-10 bg-red-400 text-white block m-auto button-shadow rounded cursor-pointer ml-2"
                                    @click="unlockBlockAccount(row)" style="padding: 10px 16px" v-if="row?.isBlocked">
                                    <img class="w-full h-full" src="/images/icon-lock-user.png" alt="" />
                                </button>
                            </template>
                        </div>
                    </template>

                    <template #delete="{ row }">
                        <div class="flex justify-center items-center">
                            <button class="bg-button_table text-white py-2 rounded px-4 button-shadow mx-1"
                                @click="deleteAccount(row)" :disabled="row?.isBlockDelete">
                                {{ $t('delete') }}
                            </button>
                        </div>
                    </template>
                </data-table>
            </div>
        </div>

        <PopupIns :data="businessAccount" v-model="isShow" :user-id="selectId" @onSubmitSuccess="onSubmitSuccess" />
    </DrashBoard>
</template>

<script>
import DrashBoard from '@/Layouts/Authenticated.vue'
import DataTable from '@/Components/UI/DataTable.vue'
import { Link } from '@inertiajs/inertia-vue3'
import PopupIns from '@/Components/PopupIns.vue'
import Ins from '@/Services/Ins'
import { ElMessageBox } from 'element-plus'
import { Unlock as UnlockIcon, Lock as LockIcon, InfoFilled as InfoFilledIcon } from '@element-plus/icons-vue'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { getListRole } from '@/Api/Role'
import dayjs from 'dayjs'

export default {
    props: {
        set_old_params: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        DrashBoard,
        DataTable,
        Link,
        Ins,
        PopupIns,
        UnlockIcon,
        LockIcon,
        InfoFilledIcon,
        BreadCrumb,
    },

    data() {
        let fieldTable = [
            { key: 'login_proxy', label: '代理ログイン', width: 150, fixed: 'left', align: 'center' },
            { key: 'instagram', label: 'Instagram', width: 150, align: 'center' },
            { key: 'company', label: '会社名', width: screen.width / 12 >= 150 ? screen.width / 12 : 180, align: 'center' },
            {
                key: 'name',
                label: 'アカウント名',
                width: screen.width / 12 >= 150 ? screen.width / 12 : 180,
                align: 'center',
            },
            {
                key: 'category',
                label: 'ジャンル',
                width: screen.width / 12 >= 150 ? screen.width / 12 : 180,
                align: 'center',
            },
            { key: 'role', label: '権限', width: screen.width / 12 >= 320 ? screen.width / 12 : 330, align: 'center' },
            {
                key: 'payment',
                label: '決済タイプ',
                width: screen.width / 12 >= 320 ? screen.width / 12 : 340,
                align: 'center',
            },
            { key: 'started_at', label: '利用開始日', width: 150, align: 'center' },
            { key: 'ended_at', label: '契約満了日', width: 150, align: 'center' },
            { key: 'plan_name', label: '利用プラン', width: 150, align: 'center' },
            { key: 'status', label: '契約状況', width: 200, align: 'center' },
            { key: 'detail', label: '詳細', width: 150, align: 'center' },
            { key: 'actions', label: this.$t('actions'), width: 150, align: 'center' },
            { key: 'delete', label: this.$t('delete'), width: 100, align: 'center' },
        ]

        return {
            users: {},
            filter: {
                page: 1,
                search: '',
                limit: 10,
                filter_role: 0,
                filter_plan: 0,
                filter_ended_at: '',
                filter_started_at: '',
                filter_status: 0,
                filter_category: 0,
            },
            keyword: null,
            fields: this.hasRole('master')
                ? fieldTable
                : this.hasRole('admin_oem')
                    ? fieldTable.filter((field) => {
                        return this.$page.props.auth.impersonate == true
                            ? field.key !== 'actions' && field.key !== 'login_proxy'
                            : field.key !== 'actions'
                    })
                    : this.hasRole('admin_enterprise')
                        ? fieldTable.filter((field) => {
                            return field.key !== 'actions' && field.key !== 'login_proxy'
                        })
                        : fieldTable.filter((field) => {
                            return field.key !== 'actions' && field.key !== 'login_proxy' && field.key !== 'delete'
                        }),
            isShow: false,
            businessAccount: [],
            account: null,
            selectId: null,
            loading: false,
            roles: [],
            plans: [],
            clearable_plan: false,
            clearable_role: false,
            clearable_status: false,
            clearable_category: false,
        }
    },

    created() {
        this.setParamsSearch()
        this.fetchDataUser()
        this.getListRole()
        this.fetchDataPlan()
    },

    mounted() {
        this.customDatePickerFirstText()
        this.customDatePickerSecondText()
    },
    methods: {
        // fetch data
        async fetchDataUser() {
            this.loading = true
            this.formatDateSearch()
            this.saveParamsSearch()

            await axios
                .get(route('api.users.index', { ...this.filter }))
                .then((res) => {
                    this.users = res?.data
                    this.loading = false
                })
                .catch((err) => {
                    console.error(err)
                    this.loading = false
                })
        },

        async getListRole() {
            await getListRole().then((res) => {
                this.roles = res.data
            })
        },

        async fetchDataPlan(page = null) {
            this.isLoading = true
            await axios
                .get('/api/plans?nolimit')
                .then((res) => {
                    this.plans = res.data.data
                    this.isLoading = false
                })
                .catch((err) => {
                    console.error(err)
                    this.isLoading = false
                })
        },

        formatDate(data) {
            if (data) {
                const date = new Date(data)
                let day = date.getDate()
                let month = date.getMonth() + 1
                let year = date.getFullYear()
                day = day < 10 ? '0' + day : day
                month = month < 10 ? '0' + month : month
                return `${year}年${month}月${day}日`
            }
        },

        onSearch() {
            this.loading = true
            this.filter.page = 1
            this.fetchDataUser()
            this.loading = false
            this.checkClearable()
        },

        handleCurrentPage(value) {
            this.loading = true
            this.filter.page = value
            this.fetchDataUser()
        },

        impersonate(user_id) {
            this.$inertia.visit(route('admin.users.impersonate', user_id))

            this.$toast.success(this.$t('Login proxy success'))
        },

        handleLoginInsta(user_id) {
            FB.login(
                async (response) => {
                    if (!response.authResponse) {
                        return
                    }
                    const {
                        data: { data },
                    } = await Ins.businessAccount(response.authResponse.accessToken)
                    const checkAllAccountIns = await Ins.checkAllAccountIns(Ins.filterBusinessAccount(data))
                    this.businessAccount = checkAllAccountIns
                    if (this.businessAccount.length == 0) {
                        this.checkPermissionLinkAccountIns()
                    } else {
                        this.isShow = true
                    }
                    this.selectId = user_id
                },
                { scope: Ins.addPermission() }
            )
        },

        checkPermissionLinkAccountIns() {
            return this.$toast.error(this.$t('please_add_enough_permissions_for_the_linked_account'))
        },

        onSubmitSuccess() {
            this.fetchDataUser()
        },

        submit() {
            Ins.insAccountLink(this.account, this.selectId)
            this.isShow = false
            this.$toast.success('Success! You did it!')
            this.fetchDataUser()
        },

        unlockBlockAccount(item) {
            let msg = item.isBlocked
                ? this.$t('do you want to unlock this account')
                : this.$t('do you want to block this account')

            ElMessageBox.confirm(msg, '警告', {
                confirmButtonText: 'はい',
                cancelButtonText: 'いいえ',
                type: 'warning',
            }).then(async () => {
                axios
                    .post(route('api.block-unlock-account'), {
                        userId: item.id,
                        isBlocked: item.isBlocked,
                    })
                    .then(({ data: { status, msg, isBlocked } }) => {
                        if (status) {
                            this.$toast.success(msg)
                            this.fetchDataUser()
                        } else {
                            this.$toast.error(msg)
                        }
                    })
                    .catch((_) => {
                        this.$toast.error(this.$t('something went wrong'))
                    })
            })
        },

        lockUnlockAccount(item) {
            let msg =
                item.account_lock === 0
                    ? this.$t('do you want to lock this account')
                    : this.$t('do you want to unlock this account')

            ElMessageBox.confirm(msg, '警告', {
                confirmButtonText: 'はい',
                cancelButtonText: 'いいえ',
                type: 'warning',
            }).then(async () => {
                axios
                    .post(route('api.lock-unlock-account'), {
                        userId: item.id,
                        isAccountLock: item.account_lock === 1 ? true : false,
                    })
                    .then(({ data: { status, msg, isAccountLock } }) => {
                        if (status) {
                            this.$toast.success(msg)
                            this.fetchDataUser()
                        } else {
                            his.$toast.error(msg)
                        }
                    })
                    .catch((_) => {
                        this.$toast.error(this.$t('something went wrong'))
                    })
            })
        },
        // call Api Delete user
        deleteAccount(item) {
            let msg = this.$t('Do you really want to delete users ?')
            ElMessageBox.confirm(msg, '警告', {
                confirmButtonText: '同意',
                cancelButtonText: 'キャンセル',
                type: 'warning',
            }).then(async () => {
                axios
                    .delete(route('api.users.destroy', item.id))
                    .then(({ data: { status, msg } }) => {
                        if (status) {
                            this.$toast.success(this.$t(msg))
                            this.fetchDataUser()
                        } else {
                            this.$toast.error(this.$t(msg))
                        }
                    })
                    .catch((_) => {
                        this.$toast.error(this.$t('something went wrong'))
                    })
            })
        },

        checkClearable() {
            if (this.filter.filter_role != 0) {
                this.clearable_role = true
            } else {
                this.filter.filter_role = 0
                this.clearable_role = false
            }

            if (this.filter.filter_status != 0) {
                this.clearable_status = true
            } else {
                this.filter.filter_status = 0
                this.clearable_status = false
            }

            if (this.filter.filter_plan != 0) {
                this.clearable_plan = true
            } else {
                this.clearable_plan = false
                this.filter.filter_plan = 0
            }

            if (this.filter.filter_category != 0) {
                this.clearable_category = true
            } else {
                this.filter.filter_category = 0
                this.clearable_category = false
            }
        },
        formatDateSearch() {
            if (this.filter.filter_started_at) {
                this.filter.filter_started_at = [
                    dayjs(this.filter.filter_started_at[0]).format('YYYY-M-D'),
                    dayjs(this.filter.filter_started_at[1]).format('YYYY-M-D'),
                ]
            }

            if (this.filter.filter_ended_at) {
                this.filter.filter_ended_at = [
                    dayjs(this.filter.filter_ended_at[0]).format('YYYY-M-D'),
                    dayjs(this.filter.filter_ended_at[1]).format('YYYY-M-D'),
                ]
            }
        },
        saveParamsSearch() {
            const key = 'search_users_params' + this.$page?.props?.auth?.user?.id
            const params = this.filter
            sessionStorage.setItem(key, JSON.stringify(params))
        },
        setParamsSearch() {
            if (!this.set_old_params) return
            const key = 'search_users_params' + this.$page?.props?.auth?.user?.id

            const params = JSON.parse(sessionStorage.getItem(key))
            params ? (this.filter = params) : ''
        },
        customDatePickerFirstText() {
            let datePicker = document.getElementsByClassName('customDatePickerFirst')

            let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[0]
            const preYearNode = document.createTextNode('<<前年')
            btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0])
            btnPreYear.style.cssText += 'margin: 0 5px 0 0'

            let btnPreMonth =
                datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[1]
            const preMonthNode = document.createTextNode('<前月')
            btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0])
            btnPreMonth.style.cssText += 'margin: 0'

            let btnNextYear =
                datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[2]
            const nextYearNode = document.createTextNode('翌年>>')
            btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0])
            btnNextYear.style.cssText += 'margin: 0 0 0 5px'

            let btnNextMonth =
                datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[3]
            const nextMonthNode = document.createTextNode('翌月>')
            btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0])
            btnNextMonth.style.cssText += 'margin: 0'
        },
        customDatePickerSecondText() {
            let datePicker = document.getElementsByClassName('customDatePickerSecond')

            let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[0]
            const preYearNode = document.createTextNode('<<前年')
            btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0])
            btnPreYear.style.cssText += 'margin: 0 5px 0 0'

            let btnPreMonth =
                datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[1]
            const preMonthNode = document.createTextNode('<前月')
            btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0])
            btnPreMonth.style.cssText += 'margin: 0'

            let btnNextYear =
                datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[2]
            const nextYearNode = document.createTextNode('翌年>>')
            btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0])
            btnNextYear.style.cssText += 'margin: 0 0 0 5px'

            let btnNextMonth =
                datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[3]
            const nextMonthNode = document.createTextNode('翌月>')
            btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0])
            btnNextMonth.style.cssText += 'margin: 0'
        },
    },
}
</script>
<style lang="scss">
.el-button--primary {
    background-color: #5264cd !important;
    border: 1px solid #5264cd !important;
}

.el-button--primary span {
    color: white !important;
}
</style>
<style lang="scss" scoped>
.unlock-button {
    background: #f8717100;
    border: 2px solid #80808042;
    color: #000000b0;
    height: 40px;
}

.instaColor {
    color: #be3d8f;
}

.el_daterange .el-range-editor.el-input__inner {
    display: inline-flex;
    align-items: center;
    padding: 0px 10px;
    height: 40px;
}

form button {
    padding-top: 9px;
    padding-bottom: 9px;
}
</style>

<style>
.el_daterange .el-date-editor--daterange.el-input,
.el_daterange .el-date-editor--daterange.el-input__inner,
.el_daterange .el-date-editor--timerange.el-input,
.el_daterange .el-date-editor--timerange.el-input__inner {
    width: 256px !important;
}

.el_daterange .el-range-editor.el-input__inner {
    display: inline-flex;
    align-items: center;
    padding: 3px 10px;
    height: 40px;
}

.el_daterange .el-range-editor .el-range-input {
    caret-color: transparent;
}
</style>
