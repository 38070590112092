<template>
    <DrashBoard>
        <LinkInstagram v-if="!showLinkInstagram" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li>
                    <Link :href="route('admin.hashtag-analysis.index')" class="font-normal">指定ハッシュタグ一覧</Link>
                </li>
                <span class="font-normal text-notice_date mx-2">></span>
                <li>
                    <Link :href="route('admin.hashtag-analysis.detail', igHashtagId)"
                        class="font-normal breakcumb-active">
                    指定ハッシュタグ分析
                    </Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5 active">指定ハッシュタグ分析</li>
                </ul>
            </div>
        </template>
        <template v-if="!isExport" v-slot:manual>
            <UserManual :isShow="isShowManual" :content="arrContent" @closeManualPopup="toogleManual" />
        </template>
        <div class="bg-background" :class="{ 'pb-10': isExport, 'pb-20': !isExport }" v-loading="loading">
            <UserNav v-if="!isExport" />
            <div class="statistic" :class="{ 'md:px-10 sm:px-5': !isExport, 'px-5 md:px-20': isExport }">
                <div class="flex items-center md:relative">
                    <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                        <p class="text-button_pink font-semibold text-3sm">指定ハッシュタグ分析</p>
                        <div class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer" @click="toogleManual">
                            <img :src="suggestIcon" />
                        </div>
                    </div>
                    <!-- <UserManual :isShow="isShowManual" :content="arrContent" /> -->
                </div>
                <div class="section-filter flex justify-between md:flex-row sm:flex-col gap-5">
                    <div class="w-1/2 text-right flex items-center justify-between md:my-0 sm:my-2">
                        <p class="text-button_pink text-title">サマリー</p>
                        <a :href="`${URL_INSTAGRAM}/${hashtagName?.replace('#', '')}`"
                            class="text-button_pink font-medium cursor-pointer text-3sm mt-2" target="_blank">
                            {{ hashtagName }}
                        </a>
                    </div>
                    <div class="flex md:flex-row sm:flex-col md:gap-0 sm:gap-2">
                        <el-checkbox @change="fetchData" v-model="filter.isCheckOfAccount" size="large" :false-label="0"
                            :true-label="1" class="checkbox-comment">
                            <span class="text-2sm">自アカウントの投稿を含む</span>
                        </el-checkbox>
                        <div class="md:ml-6">
                            <el-date-picker v-model="dateRange" :type="'daterange'" size="large" range-separator="-"
                                start-placeholder="Start date" end-placeholder="End date" :disabled-date="disabledDate"
                                :clearable="false" style="width: 100%" popper-class="customDatePickerDetailHashtag" />
                        </div>
                    </div>
                </div>
                <!-- section analytic info -->
                <div class="section-analysis-info mt-7">
                    <div class="grid md:grid-cols-3 sm:grid-cols-1 gap-8">
                        <div class="bg-white p-5 border border-gray-200 rounded">
                            <div class="title-header text-button_pink flex justify-between">
                                <p class="text-3sm text-button_pink mt-2 font-semibold">投稿数</p>
                                <el-image src="/image/camera.svg" lazy />
                            </div>
                            <div class="body flex justify-between mt-7">
                                <p class="text-2sm text-gray-500">{{ viewDateRange }}</p>
                                <p class="text-base text-gray-600 font-bold">
                                    {{ summary['posts_count'] ? formatToInteger(summary['posts_count']) : 0 }}
                                </p>
                            </div>
                        </div>
                        <div class="bg-white p-5 border border-gray-200 rounded">
                            <div class="title-header text-button_pink flex justify-between">
                                <p class="text-3sm text-button_pink mt-2 font-semibold">いいね数</p>
                                <el-image src="/image/heart.svg" lazy />
                            </div>
                            <div class="body flex justify-between mt-7">
                                <p class="text-2sm text-gray-500">{{ viewDateRange }}</p>
                                <p class="text-base text-gray-600 font-bold">
                                    {{ summary['likes_count'] ? formatToInteger(summary['likes_count']) : 0 }}
                                </p>
                            </div>
                        </div>
                        <div class="bg-white p-5 border border-gray-200 rounded">
                            <div class="title-header text-button_pink flex justify-between">
                                <p class="text-3sm text-button_pink mt-2 font-semibold">コメント数</p>
                                <el-image src="/image/comment.svg" lazy />
                            </div>
                            <div class="body flex justify-between mt-7">
                                <p class="text-2sm text-gray-500">{{ viewDateRange }}</p>
                                <p class="text-base text-gray-600 font-bold">
                                    {{ summary['comments_count'] ? formatToInteger(summary['comments_count']) : 0 }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- section analysis chart -->
                <div class="section-analysis-chart bg-white p-5 mt-7">
                    <BarLineChart :height="heigthChart" :width="500" :dateRange="dateRange" :chartData="chartData"
                        :chartOptions="chartOptions" :canvasID="hashtagDetailAnalyticChartID" />
                </div>

                <!-- Table -->
                <div v-if="isExport" class="section-analysis-info mt-7 bg-white p-5">
                    <p class="text-button_pink font-bold mb-3 text-3sm">同時使用ハッシュタグランキング</p>
                    <data-table :fields="fields" :items="dataHashtags.data" disable-table-info footer-center
                        paginate-background class="el-table-header__black mt-5 rou">
                        <template #hashtag="{ row }">
                            <a :href="`${URL_INSTAGRAM}/${row?.key?.replace('#', '')}`"
                                class="text-button_pink underline font-medium text-[13px] cursor-pointer"
                                target="_blank">
                                {{ row?.key }}
                            </a>
                        </template>
                        <template #posts_count="{ row }">
                            <p>{{ formatToInteger(row?.doc_count) }}</p>
                        </template>
                        <template #likes_count="{ row }">
                            <p>{{ formatToInteger(row?.likes_count?.value) }}</p>
                        </template>
                        <template #comments_count="{ row }">
                            <p>{{ formatToInteger(row?.comments_count?.value) }}</p>
                        </template>
                    </data-table>
                </div>

                <!--section rankings -->
                <div class="section-worst bg-white mt-7 p-5">
                    <div class="flex">
                        <div class="w-1/2">
                            <p class="text-button_pink font-semibold text-3sm">ベストワースト</p>
                        </div>
                        <div class="w-1/2 justify-end flex">
                            <el-select v-model="filter.sort_field" @change="fetchData">
                                <el-option v-for="item in listSelect" :key="item.value" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                            <div class="cursor-pointer" @click="showDetailPost = !showDetailPost">
                                <el-image v-if="showDetailPost" src="/image/arrow_up.svg" class="!w-5 !h-5 mt-1 ml-2"
                                    fit="fill" />
                                <el-image v-else src="/image/arrow_down.svg" class="!w-5 !h-5 mt-1 ml-2" fit="fill" />
                            </div>
                        </div>
                    </div>
                    <!-- Best 5 -->
                    <p class="text-gray-500 text-title my-3">ベスト5</p>

                    <div class="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div class="flex flex-col" v-for="(post, index) in hashtagTops" :key="index">
                            <div class="relative hover-wrap">
                                <video class="h-64 w-100 bg-gray-300" v-if="isVideo(post.thumbnail_url)"
                                    :src="post.thumbnail_url + '#t=2'"></video>
                                <el-image v-else :src="post.thumbnail_url" class="h-64 w-100" lazy />
                                <div class="hover-item">
                                    <p><i @click="redirectPostInsta(post.sns_link)"
                                            class="fab fa-instagram text-3xl"></i></p>
                                </div>
                            </div>
                            <div class="mt-auto" v-if="showDetailPost">
                                <div class="pt-3 pb-1 text-gray-500 text-2sm border-b-2 flex justify-between">
                                    <p class="text-left">投稿日時</p>
                                    <p class="text-right">{{ formatTime(post.post_created) }}</p>
                                </div>
                                <div class="pt-3 pb-1 text-gray-500 text-2sm border-b-2 flex justify-between">
                                    <p class="text-left">いいね数</p>
                                    <p class="text-right">{{ formatToInteger(post.likes) }}</p>
                                </div>
                                <div class="pt-3 pb-1 text-gray-500 text-2sm border-b-2 flex justify-between">
                                    <p class="text-left">コメント数</p>
                                    <p class="text-right">{{ formatToInteger(post.comments) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Worst 5 -->
                    <div class="flex">
                        <div class="w-1/2">
                            <p class="text-gray-500 text-title mt-5 mb-3">ワースト5</p>
                        </div>
                    </div>

                    <div class="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div class="flex flex-col" v-for="(post, index) in hashtagWorst" :key="index">
                            <div class="relative hover-wrap">
                                <video class="h-64 w-100 bg-gray-300" v-if="isVideo(post.thumbnail_url)"
                                    :src="post.thumbnail_url + '#t=2'"></video>
                                <el-image v-else :src="post.thumbnail_url" class="h-64 w-100" lazy />
                                <div class="hover-item">
                                    <p><i @click="redirectPostInsta(post.sns_link)"
                                            class="fab fa-instagram text-3xl"></i></p>
                                </div>
                            </div>
                            <div class="mt-auto" v-if="showDetailPost">
                                <div class="pt-3 pb-1 text-gray-500 text-2sm border-b-2 flex justify-between">
                                    <p class="text-left">投稿日時</p>
                                    <p class="text-right">{{ formatTime(post.post_created) }}</p>
                                </div>
                                <div class="pt-3 pb-1 text-gray-500 text-2sm border-b-2 flex justify-between">
                                    <p class="text-left">いいね数</p>
                                    <p class="text-right">{{ formatToInteger(post.likes) }}</p>
                                </div>
                                <div class="pt-3 pb-1 text-gray-500 text-2sm border-b-2 flex justify-between">
                                    <p class="text-left">コメント数</p>
                                    <p class="text-right">{{ formatToInteger(post.comments) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 flex justify-between">
                        <p class="text-2sm text-gray-600">{{ viewDateRange }} の期間に投稿されたフィード投稿</p>
                    </div>
                </div>
                <!-- Recent posts -->
                <div class="section-recent-posts bg-white mt-7 md:p-5 sm:p-2">
                    <div class="flex">
                        <div class="w-1/2">
                            <p class="text-button_pink font-bold text-3sm mb-3">最近の投稿</p>
                        </div>
                        <div class="w-1/2 justify-end flex">
                            <div class="cursor-pointer" @click="showDetailRecent = !showDetailRecent">
                                <el-image v-if="showDetailRecent" src="/image/arrow_up.svg" class="!w-5 !h-5 mt-1 ml-2"
                                    fit="fill" />
                                <el-image v-else src="/image/arrow_down.svg" class="!w-5 !h-5 mt-1 ml-2" fit="fill" />
                            </div>
                        </div>
                    </div>

                    <div class="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div class="flex flex-col" v-for="(post, index) in hashtagRecent" :key="index">
                            <div class="relative hover-wrap">
                                <video class="h-64 w-100 bg-gray-300" v-if="isVideo(post.thumbnail_url)"
                                    :src="post.thumbnail_url + '#t=2'"></video>
                                <el-image v-else :src="post.thumbnail_url" class="h-64 w-100" lazy />
                                <div class="hover-item">
                                    <p><i @click="redirectPostInsta(post.sns_link)"
                                            class="fab fa-instagram text-3xl"></i></p>
                                </div>
                            </div>
                            <div class="mt-auto" v-if="showDetailRecent">
                                <div class="pt-3 pb-1 text-gray-500 text-2sm border-b-2 flex justify-between">
                                    <p class="text-left">投稿日時</p>
                                    <p class="text-right">{{ formatTime(post.post_created) }}</p>
                                </div>
                                <div class="pt-3 pb-1 text-gray-500 text-2sm border-b-2 flex justify-between">
                                    <p class="text-left">いいね数</p>
                                    <p class="text-right">{{ formatToInteger(post.likes) }}</p>
                                </div>
                                <div class="pt-3 pb-1 text-gray-500 text-2sm border-b-2 flex justify-between">
                                    <p class="text-left">コメント数</p>
                                    <p class="text-right">{{ formatToInteger(post.comments) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="hashtagRecent.length > 0" class="text-2sm mt-2 text-right cursor-pointer">
                        <p class="text-button_pink" v-if="filter.limitPostRecent == 5"
                            @click="filter.limitPostRecent = 10">
                            もっと見る
                        </p>
                        <p class="text-button_pink" v-else @click="filter.limitPostRecent = 5">隠れる</p>
                    </div>
                </div>

                <!-- Popular 5 -->
                <div class="section-recent-posts bg-white mt-7 md:p-5 sm:p-2">
                    <div class="mb-3">
                        <div class="flex w-full md:flex-row sm:flex-col md:items-center">
                            <div class="2xl:w-1/3 w-full">
                                <span class="text-button_pink text-title font-bold">人気投稿</span>
                                <span class="md:ml-7 sm:ml-2 text-2sm text-gray-500">
                                    平均いいね数: {{ avgHashtagPopular.likeAvg ? formatToFloat(avgHashtagPopular.likeAvg) : 0
                                    }}
                                </span>
                                <span class="md:ml-7 sm:ml-2 text-2sm text-gray-500">
                                    平均コメント数: {{ avgHashtagPopular.commentAvg ?
                                            formatToFloat(avgHashtagPopular.commentAvg) : 0
                                    }}
                                </span>
                                <span class="md:ml-7 sm:ml-2 text-2sm text-gray-500">
                                    投稿数: {{ avgHashtagPopular.countPost ? formatToFloat(avgHashtagPopular.countPost) : 0
                                    }}
                                </span>
                            </div>
                            <div class="w-full 2xl:w-2/3 justify-between md:justify-end flex md:mt-0 sm:mt-4">
                                <el-date-picker :clearable="false" v-model="selectedDate" type="date"
                                    :disabled-date="disabledDate" popper-class="cusDatePickerPopular" />
                                <div class="cursor-pointer" @click="showDetailPopular = !showDetailPopular">
                                    <el-image v-if="showDetailPopular" src="/image/arrow_up.svg"
                                        class="!w-5 !h-5 mt-1 ml-2" fit="fill" />
                                    <el-image v-else src="/image/arrow_down.svg" class="!w-5 !h-5 mt-1 ml-2"
                                        fit="fill" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-5">
                        <div class="flex flex-col" v-for="(post, index) in hashtagPopular" :key="index">
                            <div class="relative hover-wrap">
                                <video class="h-64 w-100 bg-gray-300" v-if="isVideo(post.thumbnail_url)"
                                    :src="post.thumbnail_url + '#t=2'"></video>
                                <el-image v-else :src="post.thumbnail_url" class="h-64 w-100" lazy />
                                <div class="hover-item">
                                    <p><i @click="redirectPostInsta(post.sns_link)"
                                            class="fab fa-instagram text-3xl"></i></p>
                                </div>
                            </div>
                            <div class="mt-auto" v-if="showDetailPopular">
                                <div class="pt-3 pb-1 text-gray-500 text-2sm border-b-2 flex justify-between">
                                    <p class="text-left">投稿日時</p>
                                    <p class="text-right">{{ formatTime(post.post_created) }}</p>
                                </div>
                                <div class="pt-3 pb-1 text-gray-500 text-2sm border-b-2 flex justify-between">
                                    <p class="text-left">いいね数</p>
                                    <p class="text-right">{{ formatToInteger(post.likes) }}</p>
                                </div>
                                <div class="pt-3 pb-1 text-gray-500 text-2sm border-b-2 flex justify-between">
                                    <p class="text-left">コメント数</p>
                                    <p class="text-right">{{ formatToInteger(post.comments) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="hashtagPopular.length > 0" class="text-2sm mt-2 text-right cursor-pointer">
                        <p class="text-button_pink" v-if="filter.limitPostPopular == 5"
                            @click="filter.limitPostPopular = 10">
                            もっと見る
                        </p>
                        <p class="text-button_pink" v-else @click="filter.limitPostPopular = 5">隠れる</p>
                    </div>
                </div>

                <!-- Table -->
                <div v-if="!isExport" class="section-analysis-info mt-7 bg-white p-5">
                    <p class="text-button_pink font-bold mb-3 text-3sm">同時使用ハッシュタグランキング</p>
                    <data-table :fields="fields" :items="dataHashtags.data" disable-table-info footer-center
                        paginate-background class="el-table-header__black mt-5 rou">
                        <template #hashtag="{ row }">
                            <a :href="`${URL_INSTAGRAM}/${row?.key?.replace('#', '')}`"
                                class="text-button_pink underline font-medium text-[13px] cursor-pointer"
                                target="_blank">
                                {{ row?.key }}
                            </a>
                        </template>
                        <template #posts_count="{ row }">
                            <p>{{ formatToInteger(row?.doc_count) }}</p>
                        </template>
                        <template #likes_count="{ row }">
                            <p>{{ formatToInteger(row?.likes_count?.value) }}</p>
                        </template>
                        <template #comments_count="{ row }">
                            <p>{{ formatToInteger(row?.comments_count?.value) }}</p>
                        </template>
                    </data-table>
                    <div v-if="!isExport" class="text-2sm mt-2 text-button_pink text-right cursor-pointer"
                        @click="listHashtagRankings">
                        もっと見る
                    </div>
                </div>

                <div v-if="!isExport" class="flex items-center justify-center w-full mt-10">
                    <button type="button"
                        class="bg-pink-400 text-white w-72 lg:px-10 sm:px-5 py-4 rounded-sm button-shadow text-btn-submit"
                        @click="$inertia.visit(route('admin.hashtag-analysis.index'))">
                        戻る
                    </button>
                </div>
            </div>
        </div>
    </DrashBoard>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import DataTable from '@/Components/UI/DataTable.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import BarLineChart from './Chart/BarLineChart.vue'
import dayjs from 'dayjs'
import { Inertia } from '@inertiajs/inertia'
import UserManual from '@/Components/UserManual.vue'

export default {
    name: 'HashtagAnalysisDetail',
    components: { DrashBoard, UserNav, DataTable, Link, BreadCrumb, LinkInstagram, BarLineChart, UserManual },
    emits: ['getHashtagTableData', 'getDataElacticHashtag', 'getDetailHashtagRange', 'getDetailHashtagLabel'],
    props: {
        hashtagDetailAnalyticChartID: { type: String },
        hashtagItem: { type: Object, required: true },
        igHashtagId: { type: String },
    },
    data() {
        const since = new Date(new Date().setDate(new Date().getDate() - 30))
        const until = new Date(new Date().setDate(new Date().getDate() - 1))
        return {
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowManual: false,
            arrContent: ['指定したハッシュタグで期間内にエンゲージメントが高かった投稿・低かった投稿を確認することができます。', 'ハッシュタグで求められるコンテンツを分析することで、投稿企画の参考にすることができます。', 'また、指定ハッシュタグと同時に使用されているハッシュタグを確認することができ、関連しているハッシュタグ選定を行うこともできます。'],
            loading: false,
            showLinkInstagram: true,
            URL_INSTAGRAM: 'https://www.instagram.com/explore/tags',
            dateRange: [since, until],
            summary: [],
            filter: {
                sort_field: 'likes',
                isCheckOfAccount: 1,
                limitPostPopular: 5,
                limitPostRecent: 5,
            },
            // chartdata
            chartData: {
                labels: [],
                datasets: [
                    {
                        index: 2,
                        type: 'bar',
                        label: '投稿数',
                        data: [],
                        backgroundColor: '#C4C4C4',
                        borderColor: '#C4C4C4',
                        yAxisID: 'A',
                    },
                    {
                        index: 3,
                        type: 'bar',
                        label: 'いいね数',
                        data: [],
                        backgroundColor: '#00BDD7',
                        borderColor: '#00BDD7',
                        yAxisID: 'B',
                    },
                    {
                        index: 4,
                        type: 'bar',
                        label: 'コメント数',
                        data: [],
                        backgroundColor: '#3600A8',
                        borderColor: '#3600A8',
                        yAxisID: 'B',
                    },
                ],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: { display: true, align: 'center', labels: { padding: 20 } },
                    datalabels: { display: false },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                    A: {
                        type: 'linear',
                        position: 'left',
                        grid: {
                            display: false,
                        },
                    },
                    B: {
                        type: 'linear',
                        position: 'right',
                    },
                },
            },
            // top post
            hashtagTops: [],
            hashtagWorst: [],
            hashtagRecent: [],
            hashtagPopular: [],
            avgHashtagPopular: [],
            // hashtag data
            dataHashtags: {
                data: [],
            },
            fields: [
                { key: 'hashtag', label: 'ハッシュタグ', align: 'center', minWidth: 150 },
                { key: 'posts_count', label: '使用投稿数', align: 'center', width: 300 },
                { key: 'likes_count', label: '平均いいね数', align: 'center', width: 300 },
                { key: 'comments_count', label: '平均コメント数', align: 'center', width: 300 },
            ],

            listSelect: [
                { value: 'likes', label: 'いいね数' },
                { value: 'comments', label: 'コメント数' },
            ],

            hashtagName: '',

            selectedSelect: '',
            selectedDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            showDetailPost: false,
            showDetailRecent: false,
            showDetailPopular: false,
        }
    },
    watch: {
        'filter.limitPostPopular'() {
            this.fetchData()
        },
        'filter.limitPostRecent'() {
            this.fetchData()
        },
        selectedDate() {
            this.fetchData()
        },
        dateRange() {
            this.selectedDate = this.dateRange[1]
            this.fetchData()
        },
        async hashtagItem() {
            if (!this.hashtagItem?.ig_hashtag_id) return
            await this.fetchData()
        },
    },
    computed: {
        heigthChart() {
            let widthScreen = screen.width
            if (widthScreen > 640) {
                return 150
            } else {
                return 1050
            }
        },
        viewDateRange() {
            const startDate = dayjs(this.dateRange[0]).format('YYYY/MM/DD')
            const endDate = dayjs(this.dateRange[1]).format('YYYY/MM/DD')
            return startDate + ' - ' + endDate
        },
        isExport() {
            return Inertia?.page?.url === '/export/report-preview'
        },
    },
    created() {
        this.fetchData()
    },
    mounted() {
        this.customDatePickerText()
        this.cusDatePickerText()
    },
    methods: {
        toogleManual() {
            this.isShowManual = !this.isShowManual
        },
        async fetchData() {
            const startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
            const endDate = dayjs(this.dateRange[1]).format('YYYY-MM-DD')
            this.$emit('getDetailHashtagRange', this.dateRange)
            this.$emit('getDetailHashtagLabel', this.filter.sort_field)
            this.$emit('getDetailHashtagPopularDate', this.selectedDate)
            const selected_date = dayjs(this.selectedDate).format('YYYY-MM-DD')
            const ig_hashtag_id =
                Inertia?.page?.url === '/export/report-preview'
                    ? this.hashtagItem.ig_hashtag_id
                    : Inertia?.page?.url.replace('/hashtag-analysis/', '')
            if (!ig_hashtag_id) {
                return
            }
            this.loading = true
            await axios
                .get(route('api.analysis-hashtag.show', ig_hashtag_id), {
                    params: {
                        start: startDate,
                        end: endDate,
                        sort_field: this.filter.sort_field,
                        selected_date: selected_date,
                        isCheckOfAccount: this.filter.isCheckOfAccount,
                        limitPostPopular: this.filter.limitPostPopular,
                        limitPostRecent: this.filter.limitPostRecent,
                        limit: Inertia?.page?.url === '/export/report-preview' ? 17 : 5,
                    },
                })
                .then((res) => {
                    if (res.data != null) {
                        this.summary = res?.data?.summary
                        // chart data
                        let labels = []
                        const day = dayjs(this.dateRange[1]).diff(dayjs(this.dateRange[0]), 'day')
                        for (let i = 0; i <= day; i++) {
                            const day = dayjs(this.dateRange[0]).add(i, 'day').format('YYYY/M/D')
                            labels.push(day)
                        }
                        this.chartData.labels = labels
                        this.chartData.datasets[0].data = res?.data?.chartData?.posts_count
                        this.chartData.datasets[1].data = res?.data?.chartData?.likes_count
                        this.chartData.datasets[2].data = res?.data?.chartData?.comments_count

                        // hashtag top
                        this.hashtagTops = res?.data?.hashtagBest
                        this.hashtagWorst = res?.data?.hashtagWorst
                        this.hashtagRecent = res?.data?.hashtagRecent
                        this.hashtagPopular = res?.data?.hashtagPopular
                        this.avgHashtagPopular = res?.data?.avgHashtagPopular
                        this.$emit('getDataElacticHashtag', res.data)

                        // name hashtag
                        this.hashtagName = res?.data?.hashtagName
                        this.dataHashtags.data = res?.data?.hashtagList
                        this.$emit('getHashtagTableData', this.dataHashtags.data)

                        // set Y-axis graph max
                        const postCountMax = Number(Math.max(...res?.data?.chartData?.posts_count))
                        let yMaxA = getMaxValue(postCountMax, 50)
                        yMaxA = Math.round(yMaxA / 10 + 1) * 10

                        const likeCountMax = Number(Math.max(...res?.data?.chartData?.likes_count))
                        const commentCountMax = Number(Math.max(...res?.data?.chartData?.comments_count))
                        let yMaxB =
                            likeCountMax > commentCountMax ? getMaxValue(likeCountMax, 500) : getMaxValue(commentCountMax, 500)
                        yMaxB = Math.round(yMaxB / 10 + 1) * 10

                        this.setMaxYAxisGraph(yMaxA, yMaxB)
                    }

                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                })
        },
        getMaxValue(number, unit) {
            let data = number
            if (data / unit === 0) {
                return data
            }
            data++
            this.loopNumber(data, unit)
        },
        // check instagram success
        onInstaSubmitSuccess() {
            this.showLinkInstagram = true
        },
        // link instagram
        async checkLinkInstagram() {
            const check = await accountAPI.checkInsAccount()
            if (check.data) {
                this.showLinkInstagram = true
            } else {
                this.showLinkInstagram = false
            }
        },

        disabledDate(date) {
            let d = new Date()
            return date > d.setDate(d.getDate() - 1)
        },

        listHashtagRankings() {
            const startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
            const endDate = dayjs(this.dateRange[1]).format('YYYY-MM-DD')
            let hashtag_id =
                Inertia?.page?.url === '/export/report-preview' ? this.hashtagItem.ig_hashtag_id : this.igHashtagId

            window.location.href = '/hashtag-analysis-ranking/' + hashtag_id + `?start=${startDate}&end=${endDate}`
        },

        formatTime(date) {
            if (!date) {
                return
            }
            let listDate = date.slice(0, 10).split('-')
            let listTime = date.slice(11, 16)
            let dateFormat = listDate[0] + '年' + listDate[1] + '月' + listDate[2] + '日' + ' ' + listTime
            return dateFormat
        },

        redirectPostInsta(link) {
            window.open(link)
        },

        setMaxYAxisGraph(yMaxA, yMaxB) {
            this.chartOptions.scales.A.max = yMaxA
            this.chartOptions.scales.A.ticks.stepSize = yMaxA / 10
            this.chartOptions.scales.B.max = yMaxB
            this.chartOptions.scales.B.ticks.stepSize = yMaxB / 10
        },

        isVideo(linkS3) {
            if (linkS3 == null) {
                return false
            }
            let arr = linkS3.split('.')
            const typeVieo = ['mp4', 'm4v', 'avi', 'mov', 'mpg', 'mpeg', 'wmv', 'avi', 'mpeg-2']
            return typeVieo.includes(arr[arr.length - 1])
        },
        customDatePickerText() {
            let datePicker = document.getElementsByClassName('customDatePickerDetailHashtag')

            let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[0]
            const preYearNode = document.createTextNode("<<前年");
            btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0]);
            btnPreYear.style.cssText += 'margin: 0 5px 0 0';

            let btnPreMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[1]
            const preMonthNode = document.createTextNode("<前月");
            btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0]);
            btnPreMonth.style.cssText += 'margin: 0';

            let btnNextYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[2]
            const nextYearNode = document.createTextNode("翌年>>");
            btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0]);
            btnNextYear.style.cssText += 'margin: 0 0 0 5px';

            let btnNextMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[3]
            const nextMonthNode = document.createTextNode("翌月>");
            btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0]);
            btnNextMonth.style.cssText += 'margin: 0';
        },
        cusDatePickerText() {
            let datePicker = document.getElementsByClassName('cusDatePickerPopular')

            let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0]
            const preYearNode = document.createTextNode("<<前年");
            btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0]);
            btnPreYear.style.cssText += 'margin: 0 5px 0 0';

            let btnPreMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[1]
            const preMonthNode = document.createTextNode("<前月");
            btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0]);
            btnPreMonth.style.cssText += 'margin: 0';

            let btnNextYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[4]
            const nextYearNode = document.createTextNode("翌年>>");
            btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0]);
            btnNextYear.style.cssText += 'margin: 0 0 0 5px';

            let btnNextMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[5]
            const nextMonthNode = document.createTextNode("翌月>");
            btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0]);
            btnNextMonth.style.cssText += 'margin: 0';
        }
    },
}
</script>

<style lang="scss" scoped>
.el-button__primary {
    background-color: #5264cd;
    border: 1px solid #5264cd;
}

.el-button__primary span {
    color: white !important;
}

.text-pink {

    &:hover,
    p {
        color: #be3d8f !important;
        text-decoration: underline;
    }
}

.el-link.is-underline:hover:after {
    border-bottom: none !important;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    border-bottom: 0 !important;
}

.hover-item {
    opacity: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16rem;

    i {
        color: #be3d8f;
    }
}

.hover-wrap:hover .hover-item {
    opacity: 1;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
}
</style>

<style>
.checkbox-comment .el-checkbox__input.is-checked .el-checkbox__inner {
    background-image: url(/images/tick.svg?01fd452…);
    background-color: #be3d8f;
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
}

.checkbox-comment .el-checkbox__inner::after {
    border: 0 !important;
}

.checkbox-comment .el-radio__inner {
    border-radius: 0;
    padding: 9px;
}

.checkbox-comment .input-pl .el-input__inner {
    padding-left: 24px !important;
}

.checkbox-comment .el-radio__input.is-checked .el-radio__inner {
    background-image: url(/images/tick.svg?01fd452…);
    background-color: #be3d8f;
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
}

.checkbox-comment .el-radio__input.is-checked .el-radio__inner::after {
    display: none;
}

.breakcumb-container {
    width: 100%;
    overflow: auto;
}
</style>
