import GetThumbnailUrl from '../../../Mixins/thumbnail.js'

export default {
  mixins: [GetThumbnailUrl],
  methods: {
    async genStoryAnalyticPage(doc, listImgTableStoryData) {
      doc.setFontSize(14) // set font size
      doc.setTextColor(190, 61, 143)
      doc.text('ストーリーズ分析', 13, 30)
      doc.text('ストーリーズ分析', 13, 30)
      doc.text('ストーリーズ分析', 13, 30)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(10)

      const storyCanvas = document.createElement('canvas')
      storyCanvas.width = 250
      storyCanvas.height = 120

      let storyCanvasCtx = storyCanvas.getContext('2d')
      let x = 0
      let y = 0
      let widthStory = 220
      let heightStory = 95
      let radius = 10
      radius = { tl: radius, tr: radius, br: radius, bl: radius }
      storyCanvasCtx.beginPath()
      storyCanvasCtx.moveTo(x + radius.tl, y)
      storyCanvasCtx.lineTo(x + widthStory - radius.tr, y)
      storyCanvasCtx.quadraticCurveTo(x + widthStory, y, x + widthStory, y + radius.tr)
      storyCanvasCtx.lineTo(x + widthStory, y + heightStory - radius.br)
      storyCanvasCtx.quadraticCurveTo(x + widthStory, y + heightStory, x + widthStory - radius.br, y + heightStory)
      storyCanvasCtx.lineTo(x + radius.bl, y + heightStory)
      storyCanvasCtx.quadraticCurveTo(x, y + heightStory, x, y + heightStory - radius.bl)
      storyCanvasCtx.lineTo(x, y + radius.tl)
      storyCanvasCtx.quadraticCurveTo(x, y, x + radius.tl, y)
      storyCanvasCtx.closePath()
      storyCanvasCtx.fillStyle = '#f5f5f5'
      storyCanvasCtx.fill()

      doc.addImage(storyCanvas, 'PNG', 13, 40)
      doc.addImage(storyCanvas, 'PNG', 76, 40)
      doc.addImage(storyCanvas, 'PNG', 139, 40)
      doc.text('ストーリーズ投稿数', 15, 45)
      doc.text('ストーリーズ投稿数', 15, 45)
      doc.setFontSize(10)
      doc.text('合計', 15, 60)
      doc.text(String(this.storiesAnalysis.storiesTotal), 53, 60)
      doc.text('インプレッション数', 78, 45)
      doc.text('インプレッション数', 78, 45)
      doc.setFontSize(10)
      doc.text('合計', 78, 55)
      doc.text(String(this.storiesAnalysis.impressionsTotal), 115, 55)
      doc.text('投稿平均', 78, 60)
      doc.text(String(this.storiesAnalysis.impressionsAvg), 115, 60)
      doc.text('リーチ数', 141, 45)
      doc.text('リーチ数', 141, 45)
      doc.setFontSize(10)
      doc.text('合計', 141, 55)
      doc.text(String(this.storiesAnalysis.reachTotal), 179, 55)
      doc.text('投稿平均', 141, 60)
      doc.text(String(this.storiesAnalysis.reachAvg), 179, 60)
      let listStoryImg = []
      for (var imgStoryIndex = 0; imgStoryIndex < listImgTableStoryData.length; imgStoryIndex++) {
        let itemBase64Img = listImgTableStoryData[imgStoryIndex] + '?time=' + Math.floor(Date.now() / 1000)
        listStoryImg.push(itemBase64Img)
      }
      const tableStoryDataMap = JSON.parse(JSON.stringify(this.tableStoryData))
      if (this.tableStoryData.length > 9) {
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 26 },
          },
          didDrawCell: function (data) {
            if (data.column.index === 0 && data.cell.section === 'body') {
              var textPos = data.cell
              let base64Img = listStoryImg[data.row.index]
              doc.addImage(base64Img, 'jpeg', textPos.x + 1, textPos.y + 1, 20, 20)
            }
          },
          bodyStyles: { minCellHeight: 22 },
          startY: 70,
          head: [['サムネイル', '投稿内容', '投稿日時', 'インプ', 'リーチ', '戻る・次へ', '閲覧完了率']],
          body: tableStoryDataMap.slice(0, 9),
        })
        doc.addPage()
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 26 },
            1: { cellWidth: 70 },
          },
          didDrawCell: function (data) {
            if (data.column.index === 0 && data.cell.section === 'body') {
              var textPos = data.cell
              let base64Img = listStoryImg[data.row.index]
              doc.addImage(base64Img, 'jpeg', textPos.x + 1, textPos.y + 1, 20, 20)
            }
          },
          bodyStyles: { minCellHeight: 22 },
          startY: 45,
          head: [['サムネイル', '投稿内容', '投稿日時', 'インプ', 'リーチ', '戻る・次へ', '閲覧完了率']],
          body: tableStoryDataMap.splice(9),
        })
      } else if (this.tableStoryData.length <= 9) {
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 26 },
            1: { cellWidth: 70 },
          },
          didDrawCell: function (data) {
            if (data.column.index === 0 && data.cell.section === 'body') {
              var textPos = data.cell
              let base64Img = listStoryImg[data.row.index]
              doc.addImage(base64Img, 'jpeg', textPos.x + 1, textPos.y + 1, 20, 20)
            }
          },
          bodyStyles: { minCellHeight: 22 },
          startY: 70,
          head: [['サムネイル', '投稿内容', '投稿日時', 'インプ', 'リーチ', '戻る・次へ', '閲覧完了率']],
          body: tableStoryDataMap,
        })
      }
    },
  },
}
