<template>
    <div class="flex h-screen overflow-hidden">
        <AppSidebar/>
        <div class="wrapper relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            <AppHeader #header/>
            <div class="body flex-grow-1 px-3">
                <div class="statistic px-6 bg-gray-600">
                    <div class="menu flex pb-2 items-center mb-6">
                        <div class="menu__search flex relative mt-8">
                            <input class="flex-1 mr-5 rounded input-cm" placeholder="検索キーワードを入力してください" type="text"/>
                            <div class="bg-blue-600 text-white px-7 py-4.5 w-max rounded">
                                <button>検索</button>
                            </div>
                        </div>
                    </div>
                    <Table :label="tableLabel" :data="tableData">
                        <template v-slot:first-column>
                            <td class="flex justify-center items-center pb-3.5 pt-3">
                                <button class="rounded button-shadow py-2 px-4.5 bg-blue-600 text-white">
                                    ログイン
                                </button>
                            </td>
                        </template>
                        <template v-slot:end-column>
                            <td class="flex justify-center items-center pb-3.5 pt-3">
                                <span
                                    class="mutil-border border-indigo-400 w-2.5 h-2.5 bg-indigo-400 block mr-3 rounded-full"></span>
                                <p class="text-indigo-600">契約中</p>
                            </td>
                        </template>
                    </Table>
                    <Pagination/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Table from "@/Components/Table.vue";
import Pagination from "@/Components/Pagination.vue";
import AppSidebar from "@/Layouts/AppSidebar";
import AppHeader from "@/Layouts/AppHeader";

export default {
    components: {
        Table,
        Pagination,
        AppSidebar,
        AppHeader
    },
    data() {
        return {
            tableLabel: [
                "代理ログイン",
                "会社名",
                "アカウント名",
                "権限",
                "利用プラン",
                "決済タイプ",
                "利用開始日",
                "契約満了日",
                "契約状況",
            ],
            tableData: [
                {
                    title: "株式会社トライハッチ",
                    tag: "2022043100001",
                    Authority: "代理店ユーザー",
                    plan: "スタンダードプラン",
                    payment: "クレジットカード",
                    amount: "2022/12/12",
                    startDate: "2022/12/12",
                },
                {
                    title: "株式会社トライハッチ",
                    tag: "2022043100001",
                    Authority: "代理店ユーザー",
                    plan: "スタンダードプラン",
                    payment: "クレジットカード",
                    amount: "2022/12/12",
                    startDate: "2022/12/12",
                },
                {
                    title: "株式会社トライハッチ",
                    tag: "2022043100001",
                    Authority: "代理店ユーザー",
                    plan: "スタンダードプラン",
                    payment: "クレジットカード",
                    amount: "2022/12/12",
                    startDate: "2022/12/12",
                },
                {
                    title: "株式会社トライハッチ",
                    tag: "2022043100001",
                    Authority: "代理店ユーザー",
                    plan: "スタンダードプラン",
                    payment: "クレジットカード",
                    amount: "2022/12/12",
                    startDate: "2022/12/12",
                },
                {
                    title: "株式会社トライハッチ",
                    tag: "2022043100001",
                    Authority: "代理店ユーザー",
                    plan: "スタンダードプラン",
                    payment: "クレジットカード",
                    amount: "2022/12/12",
                    startDate: "2022/12/12",
                },
                {
                    title: "株式会社トライハッチ",
                    tag: "2022043100001",
                    Authority: "代理店ユーザー",
                    plan: "スタンダードプラン",
                    payment: "クレジットカード",
                    amount: "2022/12/12",
                    startDate: "2022/12/12",
                },
                {
                    title: "株式会社トライハッチ",
                    tag: "2022043100001",
                    Authority: "代理店ユーザー",
                    plan: "スタンダードプラン",
                    payment: "クレジットカード",
                    amount: "2022/12/12",
                    startDate: "2022/12/12",
                },
                {
                    title: "株式会社トライハッチ",
                    tag: "2022043100001",
                    Authority: "代理店ユーザー",
                    plan: "スタンダードプラン",
                    payment: "クレジットカード",
                    amount: "2022/12/12",
                    startDate: "2022/12/12",
                },
            ],
        };
    },
};
</script>

<style scoped>
.select__status {
    background: #ffffff;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
}

.status {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #09ab36;
    margin: 0 22px 0 8px;
}

.mutil-border {
    box-shadow: 0 0 0 3px #ffffff, 0 0 0 5px #dbdbdb;
}
</style>
