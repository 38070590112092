import { EMPTY_IMG } from '../Mixins/media.js'

export default {
  methods: {
    genSlide18Enterprise(pptx, postInsight, text) {
      let slide18 = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slide18.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      slide18.addText('アカウント別フィード投稿エンゲージメント', {
        x: 2.45,
        y: 0.25,
        w: 7.05,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 22,
      })

      let opts = {
        x: 0.5,
        y: 1.31,
        w: 10.02,
        h: 4.58,
        barDir: 'col',
        barGrouping: 'clustered',
        catAxisLabelColor: '787878',
        catAxisLabelFontSize: 12,
        catAxisOrientation: 'minMax',
        showTitle: true,
        title: 'アカウント別エンゲージメントの割合',
        titleColor: '787878',
        titleFontSize: 18.6,
        // valAxisMaxVal: 100,
        // valAxisMajorUnit: 10,
        showLegend: true,
        legendPos: 'b',
        legendFontSize: 12,
        legendColor: '787878',
        chartColors: ['E7289E', '3E63A3', '2E4A7A'],
      }
      if (
        postInsight.checkLike &&
        postInsight.checkComment &&
        postInsight.checkSaved &&
        postInsight.checkEngagement &&
        postInsight.checkImpressions
      ) {
        slide18.addImage({ path: EMPTY_IMG, x: 3.75, y: 1.84, w: 3.56, h: 3.56 })
      } else {
        let labels = postInsight?.accountNameIns
        let chartTypes = [
          {
            type: pptx.charts.BAR,
            data: [
              {
                name: 'いいね数',
                labels: labels,
                values: postInsight?.likes,
              },
              {
                name: 'コメント数',
                labels: labels,
                values: postInsight?.comments,
              },
              {
                name: '保存数',
                labels: labels,
                values: postInsight?.saved,
              },
            ],

            options: {
              barGrouping: 'stacked',
            },
          },
        ]
        slide18.addChart(chartTypes, opts)
      }

      slide18.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.29,
        w: 10.14,
        h: 1.56,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slide18
    },
  },
}
