export default {
  methods: {
    async genTopAndWorstPostPage(doc) {
      doc.setFontSize(14)
      doc.setTextColor(190, 61, 143)
      doc.text('クリエイティブ分析', 13, 30)
      doc.text('クリエイティブ分析', 13, 30)
      doc.text('クリエイティブ分析', 13, 30)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(10)
      doc.text(this.topWorstPostCategory == 'normal' ? '通常投稿' : 'リール投稿', 13, 40)

      doc.text('TOP 10', 13, 45)
      for (let topBestPostsIndex = 0; topBestPostsIndex < this.topBestPosts.length; topBestPostsIndex++) {
        let item = this.topBestPosts[topBestPostsIndex]
        if (topBestPostsIndex <= 4) {
          doc.addImage(
            String(item.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)),
            'jpeg',
            topBestPostsIndex * 37 + 13,
            50,
            33,
            28
          )
          doc.text('カラー：' + item.hexCode, topBestPostsIndex * 37 + 13, 85)
          doc.addImage(this.genColorBlock(item.hexCode), topBestPostsIndex * 37 + 13, 87, 33, 16)
        } else if (topBestPostsIndex > 4) {
          doc.addImage(
            String(item.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)),
            'jpeg',
            (topBestPostsIndex - 5) * 37 + 13,
            106,
            33,
            28
          )
          doc.text('カラー：' + item.hexCode, (topBestPostsIndex - 5) * 37 + 13, 141)
          doc.addImage(this.genColorBlock(item.hexCode), (topBestPostsIndex - 5) * 37 + 13, 143, 33, 16)
        }
      }
      doc.text('WORST 10', 13, 167)
      for (let topWorstPostsIndex = 0; topWorstPostsIndex < this.topWorstPosts.length; topWorstPostsIndex++) {
        let item = this.topWorstPosts[topWorstPostsIndex]
        if (topWorstPostsIndex <= 4) {
          doc.addImage(
            String(item.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)),
            'jpeg',
            topWorstPostsIndex * 37 + 13,
            172,
            33,
            28
          )
          doc.text('カラー：' + item.hexCode, topWorstPostsIndex * 37 + 13, 207)
          doc.addImage(this.genColorBlock(item.hexCode), topWorstPostsIndex * 37 + 13, 209, 33, 16)
        } else if (topWorstPostsIndex > 4) {
          doc.addImage(
            String(item.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)),
            'jpeg',
            (topWorstPostsIndex - 5) * 37 + 13,
            228,
            33,
            28
          )
          doc.text('カラー：' + item.hexCode, (topWorstPostsIndex - 5) * 37 + 13, 263)
          doc.addImage(this.genColorBlock(item.hexCode), (topWorstPostsIndex - 5) * 37 + 13, 265, 33, 16)
        }
      }
    },
    genColorBlock(color) {
      const blockColor = document.createElement('canvas')
      blockColor.width = 100
      blockColor.height = 100

      let blockColorCtx = blockColor.getContext('2d')
      let x = 0
      let y = 0
      let widthStory = blockColor.width
      let heightStory = blockColor.height
      let radius = 10
      radius = { tl: radius, tr: radius, br: radius, bl: radius }
      blockColorCtx.beginPath()
      blockColorCtx.moveTo(x, y)
      blockColorCtx.lineTo(x + widthStory, y)
      blockColorCtx.quadraticCurveTo(x + widthStory, y, x + widthStory, y)
      blockColorCtx.lineTo(x + widthStory, y + heightStory)
      blockColorCtx.quadraticCurveTo(x + widthStory, y + heightStory, x + widthStory, y + heightStory)
      blockColorCtx.lineTo(x, y + heightStory)
      blockColorCtx.quadraticCurveTo(x, y + heightStory, x, y + heightStory)
      blockColorCtx.lineTo(x, y)
      blockColorCtx.quadraticCurveTo(x, y, x, y)
      blockColorCtx.closePath()
      blockColorCtx.fillStyle = color
      blockColorCtx.fill()

      return blockColor
    },
  },
}
