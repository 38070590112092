<template>
  <div class="sm:px-0 md:px-10 mt-5">
    <div class="bg-background">
      <div class="sm:px-5 lg:px-10 h-full">
        <div class="flex justify-between items-center">
          <h2 class="text-button_pink !text-[14px] font-semibold py-5">コメント分析</h2>
          <button
            v-if="isRole == 'master'"
            class="bg-black py-3 px-7 text-white !text-[14px] button-shadow rounded"
            @click="exportComment"
            :disabled="(numberExportCommnent <= 0 && isRole !== 'master') || !filter?.postId"
          >
            CSV出力
          </button>

          <el-tooltip
            v-else
            class="tooltip-customer"
            effect="dark"
            :content="'コメント分析画面今月後 ' + numberExportCommnent + ' 回CSV出力出来ます。'"
            placement="top"
          >
            <button
              class="bg-black py-3 px-7 text-white !text-[14px] button-shadow rounded"
              @click="exportComment"
              :disabled="(numberExportCommnent <= 0 && isRole !== 'master') || !filter?.postId"
            >
              CSV出力
            </button>
          </el-tooltip>
        </div>
        <div class="md:grid md:grid-cols-7 sm:flex sm:flex-col">
          <div class="col-span-4 flex justify-between sm:mb-2 md:mb-0 sm:flex-wrap md:flex-nowrap">
            <div class="md:mr-4 sm:mr-0 w-100 md:mb-0 sm:mb-2">
              <el-input
                v-model="filter.name"
                placeholder="検索"
                size="large"
                class="!text-2sm"
                clearable
                @keyup.enter="onSearch"
              />
            </div>
            <el-button class="el-button__primary md:mb-0 sm:mb-2" size="large" @click="onSearch">
              <span class="text-white !text-[14px]">検索</span>
            </el-button>
            <el-select
              v-model="filter.postId"
              placeholder="投稿を切り替える"
              size="large"
              class="w-96 sm:ml-0 md:ml-4 !text-2sm"
              filterable
            >
              <el-option v-for="(item, index) in listPosts" :key="index" :value="item.id" :label="item.content">
                {{ item.content ? item.content : '投稿内容がありません。' }}
              </el-option>
            </el-select>
          </div>
          <div class="col-span-3 flex flex-1 sm:justify-center md:justify-end items-center">
            <span class="text-gray-600 !text-2sm">友好的コメント数：</span>
            <span class="font-bold ml-2 mr-4 text-link_pink text-md">{{ sumPositive }}</span>
            <span class="text-gray-600 !text-2sm">批判的コメント数：</span>
            <span class="font-bold ml-2 text-link_pink text-md">{{ sumNegative }}</span>
          </div>
        </div>
        <!-- table detail post -->
        <el-table
          :data="post_selected"
          border
          style="width: 100%"
          class="mt-5 el-table__comment el-table-header__black"
        >
          <el-table-column
            prop="date"
            label="投稿画像"
            width="150"
            fixed="left"
            label-class-name="text-center"
            align="center"
          >
            <template #default="{ row }">
              <video
                v-if="checkTypeFile(row?.files?.length > 0 ? row.files[0] : row.file)"
                controls
                width="112"
                style="height: 64px"
              >
                <source :src="row?.files?.length > 0 ? row.files[0] : row.file" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <img v-else class="w-28 h-16" :src="row?.files?.length > 0 ? row.files[0] : row.file" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="content" label="投稿内容" label-class-name="text-center">
            <template #default="{ row }">
              <span class="spn-post-content">
                {{ row?.content }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="hashtag" label="ハッシュタグ" width="250" label-class-name="text-center">
            <template #default="{ row }">
              <p>{{ row?.hashtag ? convertContent(getHashtagData(row)) : '' }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="投稿日時" width="160" label-class-name="text-center">
            <template #default="{ row }">
              {{ formatDateTime(row?.updated_at) }}
            </template>
          </el-table-column>
          <el-table-column prop="likes" label="いいね数" width="150" align="center" label-class-name="text-center">
            <template #default="{ row }">{{ row?.like }}</template>
          </el-table-column>
          <el-table-column prop="comments" label="コメント数" width="150" align="center" label-class-name="text-center">
            <template #default="{ row }">{{ row?.comment }}</template>
          </el-table-column>
          <el-table-column prop="reach" label="リーチ数" width="150" align="center" label-class-name="text-center">
            <template #default="{ row }">{{ row?.reach }}</template>
          </el-table-column>
          <el-table-column
            prop="engagement"
            label="エンゲージメント率"
            width="150"
            align="center"
            label-class-name="text-center"
          >
            <template #default="{ row }">{{ getSumEngagement(row) }}</template>
          </el-table-column>
          <template #empty>
            <el-empty description="データはありません" />
          </template>
        </el-table>
        <!-- table list comment of post -->
        <data-table
          :fields="fields"
          :items="comments.data"
          :paginate="comments.meta"
          :current-page="filter.page || 1"
          disable-table-info
          footer-center
          paginate-background
          @page="handleCurrentPage"
          class="el-table-header__black mt-7"
        >
          <template #timestamp="{ row }">
            {{ row?.timestamp ? convertTime(row?.timestamp) : '' }}
          </template>
          <template #feel="{ row }">
            <div v-if="row?.emotions?.length">
              <i
                v-for="emotion in row?.emotions"
                :key="emotion"
                :class="emotion"
                class="text-2xl text-yellow-500 w-7"
              />
            </div>
            <i class="fa-solid fa-face-meh text-2xl text-yellow-400 w-7" v-else />
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>

<script>
import DrashBoard from '@/Layouts/Authenticated.vue'
import DataTable from '@/Components/UI/DataTable.vue'
import { Link } from '@inertiajs/inertia-vue3'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import UserNav from '@/Components/UserNav.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import GetBase64Url from '../../Mixins/imgToBase64Mixin.js'
import { fetchNumberExport, addNumberExport } from '../../Mixins/limitExport.js'

export default {
  components: { DrashBoard, DataTable, Link, BreadCrumb, UserNav, LinkInstagram },
  emits: ['getPostSelected', 'getPostSelectedImg', 'getPostSelectedComment'],
  props: {
    userId: { type: Number },
    plan: { type: Object, require: true },
  },
  mixins: [GetBase64Url],

  computed: {
    isRole() {
      return this.$page.props?.auth?.user?.roles[0]?.name
    },
  },

  data() {
    return {
      numberExportCommnent: 0,
      loading: false,
      showLinkInstagram: true,
      filter: {
        name: '',
        postId: null,
        page: 1,
        limit: 10,
      },
      listPosts: [],
      post_selected: [],
      comments: [],
      fields: [
        {
          key: 'username',
          label: 'ユーザー名',
          width: 150,
          fixed: 'left',
          align: 'left',
          lableClassName: 'text-center',
        },
        { key: 'feel', label: 'ネガポジ', width: 170, align: 'center', lableClassName: 'text-center' },
        { key: 'timestamp', label: '投稿日時', width: 170, lableClassName: 'text-center' },
        {
          key: 'text',
          label: 'コメント内容',
          width: screen.width / 3 > 200 ? 'full' : 200,
          lableClassName: 'text-center',
        },
      ],
      sumNegative: 0,
      sumPositive: 0,
    }
  },
  created() {
    this.getListPosts()
    this.fetchNumberExportMonth()
  },
  watch: {
    'filter.postId': {
      async handler() {
        if (!this.filter.postId) {
          this.post_selected = Array(this.listPosts[0])
          this.filter.postId = this.listPosts[0] ? this.listPosts[0]?.id : null
        } else {
          this.post_selected = this.listPosts?.filter((v) => this.filter.postId === v.id)
        }
        let postSelectedBaseImg = await this.getBase64UrlExport(this.post_selected[0])
        let postSelectedImg = 'data:image/jpeg;base64,' + postSelectedBaseImg.data
        let postSelected = this.post_selected.map((item, index) => {
          return [
            index,
            item.content.length > 25 ? item.content.slice(0, 25) + '...' : item.content,
            item.hashtag ? this.getHashtagDataExport(item) : '',
            this.formatDateTime(item?.updated_at),
            item.like,
            item.comment,
            item.reach,
            Math.round((item.engagement + Number.EPSILON) * 100) / 100 + '%',
          ]
        })
        this.$emit('getPostSelected', postSelected)
        this.$emit('getPostSelectedImg', postSelectedImg)
        this.getListComments()
        this.getListCommentsExport()
        this.getSumEmotionComments()
      },
      deep: true,
    },
    'filter.name': {
      handler() {
        if (!this.filter.name) {
          this.getListComments()
          this.getListCommentsExport()
          this.getSumEmotionComments()
        }
      },
      deep: true,
    },
  },
  methods: {
    checkTypeFile(filePath) {
      const arr = filePath.split('.')
      if (arr[arr.length - 1] == 'mp4') {
        return true
      }
      return false
    },
    async getListPosts() {
      await axios
        .get(route('api.comment.getPostsUser'))
        .then((res) => {
          if (res?.data) {
            this.listPosts = res?.data?.data?.map((v) => {
              if (!v.content) {
                return {
                  ...v,
                  content: '投稿内容がありません。',
                }
              }
              return v
            })
            this.post_selected = Object.keys(res?.data?.data[0])?.length ? Array(res?.data?.data[0]) : []
            this.filter.postId = res?.data.data[0]?.id
          }
        })
        .catch((err) => {})
    },
    // get list comment insights
    async getListComments() {
      await axios
        .get(
          route('api.comment.getComments', {
            filter: { post_id: this.filter.postId, name: this.filter.name },
            limit: this.filter?.limit,
            page: this.filter?.page,
          })
        )
        .then((res) => {
          this.comments = res?.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getListCommentsExport() {
      await axios
        .get(
          route('api.comment.getCommentsExport', {
            filter: { post_id: this.filter.postId, name: this.filter.name },
          })
        )
        .then((res) => {
          this.$emit(
            'getPostSelectedComment',
            res?.data.data.map((item) => {
              return [
                item.username,
                this.getEmotions(item.emotions),
                this.convertTime(item.timestamp),
                item.text.length > 30 ? item.text.slice(0, 35) + '...' : item.text,
              ]
            })
          )
        })
        .catch((err) => {
          console.log(err)
        })
    },
    convertTime(date) {
      const cmtDay = new Date(date * 1000)
      let month = cmtDay.getMonth() + 1
      let hour = cmtDay.getHours() >= 10 ? cmtDay.getHours() : '0' + cmtDay.getHours()
      return cmtDay.getFullYear() + '年' + month + '月' + cmtDay.getDate() + ' ' + hour + ':' + cmtDay.getMinutes()
    },
    // get sum emotion comments of this post
    async getSumEmotionComments() {
      await axios
        .get(
          route('api.comment.getSumEmotions', {
            filter: { post_id: this.filter.postId, name: this.filter.name },
            limit: this.filter?.limit,
            page: this.filter?.page,
          })
        )
        .then((res) => {
          this.sumPositive = res?.data?.sumPositive ?? 0
          this.sumNegative = res?.data?.sumNegative ?? 0
        })
    },
    // on search by name
    onSearch() {
      this.filter.page = 1
      this.getListComments()
      this.getListCommentsExport()
      this.getSumEmotionComments()
    },
    handleCurrentPage(value) {
      this.filter.page = value
      this.getListComments()
      this.getListCommentsExport()
      this.getSumEmotionComments()
    },
    // get sum likes
    getSumLikes(row) {
      return row?.like
    },
    // get sum comment
    getSumComments(row) {
      return row?.comment
    },
    // get sum reachs
    getSumReachs(row) {
      return row?.reach
    },
    // get some engagement
    getSumEngagement(row) {
      const engagement = row?.engagement
      return Number.isInteger(engagement) ? engagement + '%' : engagement.toFixed(2) + '%'
    },
    getHashtagData(row) {
      if (typeof row?.hashtag === 'object') {
        return row?.hashtag?.toString()?.replaceAll(',', ' ')
      }
      return row?.hashtag?.replaceAll(',', ' ')
    },
    convertContent(item) {
      if(item.length > 50) {
        return item.slice(0, 50) + '...'
      }
      return item
    },
    getHashtagDataExport(item) {
      return item?.hashtag?.replaceAll(',', ' ').slice(0, 25) + '...'
    },
    formatDateTime(datetime) {
      const date = new Date(datetime)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()
      let hours = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()
      let minutes = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()
      return `${year}年${month}月${day}日 ${hours}:${minutes}`
    },
    checkIsImage(row) {
      if (!row) return
      const imageType = ['jpeg', 'jpg', 'png', 'jfif', 'pjpeg', 'pjp', 'heic', 'heif']
      const fileName = row?.file?.split('.')
      const fileType = fileName[fileName.length - 1]
      return imageType.includes(fileType) ? true : false
    },
    exportComment() {
      if (this.numberExportCommnent === 0) {
        return
      }

      const userId = this.userId ? this.userId : this.$page?.props?.auth?.user?.id
      const url = route('api.export.comment.csv') + '?user_id=' + userId + '&post_id=' + this.filter.postId
      window.location.href = url

      if (this.isRole !== 'master') {
        addNumberExport('csv', 'csv_comment')
        this.fetchNumberExportMonth()
      }
    },
    getEmotions(item) {
      if (item.length == 0) {
        return '-'
      } else if (item.length != 0) {
        if (item[0].substring(17) === 'frown') {
          return 'ネガティブ'
        } else if (item[0].substring(17) === 'laugh') {
          return 'ポジティブ'
        }
      }
    },

    async fetchNumberExportMonth() {
      const { data } = await fetchNumberExport('csv', 'csv_comment')
      this.numberExportCommnent = this.plan?.number_report_csv - data
    },
  },
}
</script>

<style lang="scss" scoped>
.el-button__primary {
  background-color: #5264cd;
  border: 1px solid #5264cd;
}

.el-button__primary span {
  color: white !important;
}
.el-select-dropdown__item {
  width: 300px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.spn-post-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.el-select-dropdown__item {
  font-size: 13px !important;
}
</style>
