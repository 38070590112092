<template>
    <BreezeGuestLayout>
        <Head title="Forgot Password" />

        <div class="mb-7 text-center text-gray-600 font-medium">
            <p v-if="!status" class="mt-3 mb-10 text-nomal">
                パスワードをお忘れの方は下記より <br />
                メールを入力してください。
            </p>
            <p v-else class="mt-10 mb-9 text-nomal">
                ご登録メールにパスワードリセットをお送りしてい
                <br>
                ますのでご確認ください
            </p>
        </div>

        <form @submit.prevent="submit" v-if="!status" class="mx-auto flex flex-col justify-center">
            <div class="forgot-email flex items-end">
                <BreezeLabel
                    for="email"
                    value="メールアドレス "
                    class="font-bold mr-1 text-nomal"
                ></BreezeLabel>
                <span class="text-red-600">*</span>
            </div>
            <BreezeInput
                v-model="form.email"
                class="block rounded-sm p-3 w-full md:w-96"
                :class="form.errors.email ? 'border-red-600' : ''"
                @change="form.errors.email = ''"
                placeholder="メールアドレスを入力してください"
                id="email"
                type="email"
            />
            <div class="flex justify-end">
                <div
                    class="text-left sm:text-2m test-base text-red-600 mt-2 sm:w-72 md:w-3/4 whitespace-nowrap lg:pl-5 sm:pl-0"
                    v-if="form.errors.email"
                >
                    {{ form.errors.email }}
                </div>
            </div>

            <div class="mt-5 text-center">
                <BreezeButton
                    class="bg-indigo-500 hover:bg-indigo-700 sm:px-10 sm:py-2 md:py-3 md:px-13 mt-2 rounded-sm text-btn-submit"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                >
                    リセットする
                </BreezeButton>
            </div>
            <div class="flex items-center justify-center mt-12">
                <Link
                    :href="page_login === null
                        ? route('formLogin.user')
                        : route(('formLogin.' + page_login))
                    "
                    class="text-indigo-600 hover:text-indigo-400 text-nomal"
                >
                    ログイン画面に戻る
                </Link>
            </div>
        </form>

        <div class="mt-7 flex justify-center" v-else>
            <Link
                class="bg-indigo-500 hover:bg-indigo-700 py-5 rounded-sm px-20"
                :href="page_login === null
                    ? route('formLogin.user')
                    : route(('formLogin.' + page_login))
                "
                :class="{ 'opacity-25': form.processing }"
                :disabled="form.processing"
            >
                <span class="text-white">ログイン画面に戻る</span>
            </Link>
        </div>
    </BreezeGuestLayout>
</template>

<script>
import BreezeButton from "@/Components/Button.vue";
import BreezeGuestLayout from "@/Layouts/Guest.vue";
import BreezeInput from "@/Components/Input.vue";
import BreezeValidationErrors from "@/Components/ValidationErrors.vue";
import { Head, useForm, Link } from "@inertiajs/inertia-vue3";
import BreezeLabel from "@/Components/Label.vue";

export default {
    components: {
        BreezeButton,
        BreezeGuestLayout,
        BreezeInput,
        BreezeValidationErrors,
        Head,
        useForm,
        BreezeLabel,
        Link,
    },

    props: {
        status: {
            type: String,
            required: false,
        },
        page_login: {
            type: String,
            required: false,
        }
    },

    data() {
        return {
            form: useForm({
                email: "",
            }),
        };
    },

    methods: {
        submit() {
            this.form.post(route("user.password.email"));
        },
    },
};
</script>

<style></style>
