<template>
    <div class="notification my-3 mx-6">
        <span class="text-xs text-slate-500"> {{ time }} </span>
        <span class="text-xs ml-6">{{ content }}</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            time: "2022年４月30日",
            content:
                "2022年5月1日24:00よりメンテナンスをおこないます。詳しくはこちらをご覧ください。",
        };
    },
};
</script>

<style scoped>
.notification {
    padding: 17px 15px;
    background: #edf1f8;
}
</style>
