<template>
    <BreezeGuestLayout>
        <Head title="Reset Password" />

        <div class="mb-10 text-center text-gray-600 text-nomal">
            新しいパスワードを入力してください
        </div>

        <form @submit.prevent="submit" class="mx-auto sm:flex md:block sm:flex-col sm:justify-center">
            <div class="flex items-end">
                <BreezeLabel
                    for="email"
                    value="メールアドレス "
                    class="font-bold mr-1 text-nomal"
                />
                <span class="text-red-600">*</span>
            </div>
            <BreezeInput
                id="email"
                disabled="disabled"
                type="email"
                class="block rounded-sm p-3 w-full md:w-96 text-nomal"
                v-model="form.email"
                :class="form.errors.email ? 'border-red-600' : ''"
                @change="form.errors.email = ''"
            />

            <div v-if="form.errors.email" class="flex sm:justify-start">
                <div
                    class="text-left text-nomal text-red-600 mt-2 sm:w-72 md:w-auto"
                    v-if="form.errors.email"
                >
                    {{ form.errors.email }}
                </div>
            </div>

            <div class="mt-5">
                <div class="flex items-end">
                    <BreezeLabel for="password" value="パスワード" class="font-bold mr-1 text-nomal" />
                    <span class="text-red-600">*</span>
                </div>

                <BreezeInput
                    id="password"
                    type="password"
                    class="block  w-full md:w-96 rounded-sm p-3 text-nomal"
                    v-model="form.password"
                    :class="form.errors.password ? 'border-red-600' : ''"
                    @change="form.errors.password = ''"
                />
            </div>
            <div v-if="form.errors.password" class="flex sm:justify-start">
                <div
                    class="text-left text-nomal text-red-600 mt-2 sm:w-72 md:w-auto"
                    v-if="form.errors.password"
                >
                    {{ form.errors.password }}
                </div>
            </div>

            <div class="mt-5">
                <div class="flex items-end">
                    <BreezeLabel
                        for="password_confirmation"
                        value="パスワード（確認)"
                        class="font-bold mr-1 text-nomal"
                    />
                    <span class="text-red-600">*</span>
                </div>

                <BreezeInput
                    id="password_confirmation"
                    type="password"
                    class="block rounded-sm p-3  w-full md:w-96 text-nomal"
                    v-model="form.password_confirmation"
                    :class="form.errors.password_confirmation ? 'border-red-600' : ''"
                    @change="form.errors.password_confirmation = ''"
                />
            </div>
            <div v-if="form.errors.password_confirmation" class="flex sm:justify-start">
                <div
                    class="text-left text-nomal text-red-600 mt-2 sm:w-72 md:w-auto"
                    v-if="form.errors.password_confirmation"
                >
                    {{ form.errors.password_confirmation }}
                </div>
            </div>
            <div class="mt-6 flex items-center justify-center mx-auto">
                <BreezeButton
                    class="bg-indigo-500 hover:bg-indigo-700 px-10 py-3 md:py-4 md:px-13 rounded-sm text-btn-submit"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                >
                    リセットする
                </BreezeButton>
            </div>

            <!-- <div class="flex items-center justify-center mt-12 sm:text-sm text-base sm:text-center text-center px-5">
                パスワードをお忘れの場合はコチラより再発行お願いします。
            </div> -->
        </form>
    </BreezeGuestLayout>
</template>
6
<script>
import BreezeButton from "@/Components/Button.vue";
import BreezeGuestLayout from "@/Layouts/Guest.vue";
import BreezeInput from "@/Components/Input.vue";
import BreezeLabel from "@/Components/Label.vue";
import BreezeValidationErrors from "@/Components/ValidationErrors.vue";
import { Link, Head, useForm } from "@inertiajs/inertia-vue3";

export default {
    components: {
        BreezeButton,
        BreezeGuestLayout,
        BreezeInput,
        BreezeLabel,
        BreezeValidationErrors,
        Head,
        Link,
        useForm,
    },

    props: {
        email: {
            type: String,
            required: true,
        },
        token: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            form: useForm({
                token: this.token,
                email: this.email,
                password: "",
                password_confirmation: "",
            }),
        };
    },

    watch: {
        "form.password"(val) {
            var password = document.getElementById("password");
            password.onkeyup = function (e) {
                if (e.key == " " || e.code == "Space" || e.keyCode == 32) {
                    this.form.password.value = val.replace(/ +/g, "");
                }
            };
        },
        "form.password_confirmation"(val) {
            var password_confirm = document.getElementById("password_confirmation");
            password_confirm.onkeyup = function (e) {
                if (e.key == " " || e.code == "Space" || e.keyCode == 32) {
                    this.form.password_confirmation.value = val.replace(/ +/g, "");
                }
            };
        },
    },

    methods: {
        submit() {
            const messeg = this.$t('change_password_success')
            this.form.post(route("user.password.update"), {
                onFinish: () => {
                    if (!this.form.hasErrors) {
                        this.$toast.success(messeg);
                        return
                    }
                },
            });
        },
    },
};
</script>
