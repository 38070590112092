import { EMPTY_IMG } from '../Mixins/media.js'
export default {
  methods: {
    genSlideInteractiveChange(pptx, dataEngagement, text, title) {
      let slideInteractiveChange = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideInteractiveChange.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      slideInteractiveChange.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 6.05,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 22,
      })

      let opts = {
        x: 0.44,
        y: 1.17,
        w: 10.04,
        h: 4.83,
        barDir: 'col',
        barGrouping: 'clustered',
        catAxisLabelColor: '787878',
        catAxisLabelFontSize: 12,
        catAxisOrientation: 'minMax',
        showTitle: true,
        title: title,
        titleColor: '787878',
        titleFontSize: 18.6,
        showLegend: true,
        legendPos: 'b',
        legendFontSize: 12,
        legendColor: '787878',
        valAxisMinVal: 0,
        chartColors: ['3E63A3', 'E7299E', '337CDF'],
        catAxisLabelFontSize: 9,
        catAxisLabelRotate: -50,
      }
      if (
        dataEngagement.dataSlide18.checkArrLike &&
        dataEngagement.dataSlide18.checkArrComment &&
        dataEngagement.dataSlide18.checkArrSaved
      ) {
        slideInteractiveChange.addImage({ path: EMPTY_IMG, x: 3.75, y: 1.84, w: 3.56, h: 3.56 })
      } else {
        let labels = dataEngagement.dataLabels
        let chartTypes = [
          {
            type: pptx.charts.BAR,
            data: [
              {
                name: 'いいね',
                labels: labels,
                values: dataEngagement.dataSlide18.dataLike,
              },
              {
                name: 'コメント',
                labels: labels,
                values: dataEngagement.dataSlide18.dataComment,
              },
              {
                name: '保存数',
                labels: labels,
                values: dataEngagement.dataSlide18.dataSaved,
              },
            ],
          },
        ]
        slideInteractiveChange.addChart(chartTypes, opts)
      }

      slideInteractiveChange.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.29,
        w: 10.14,
        h: 1.56,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slideInteractiveChange
    },
  },
}
