import axios from 'axios'
export async function getHashTag(page, keyword) {
  return await axios.get(route('api.hashtags.index'), {
    params: {
      page: page,
      search: keyword,
    },
  })
}
export async function postHashTag(name, since, until) {
  await axios.post(route('api.hashtags.store'), {
    name: name,
    start_date: since,
    end_date: until,
  })
}
