<template>
    <Datepicker v-model="date" :clearable="false" />

</template>

<script>
import { ref } from 'vue';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'
export default {
    components:{
        Datepicker
    },
    data() {
        const date = ref(new Date());
        return {
            date
        };
    },
}
</script>

<style >
.dp__icon.dp__input_icon{
    display: none;
}
.dp__input_icon_pad{
    padding-left: 15px;
}
</style>
