import axios from 'axios'

export async function getDetailAccountTime(type, since, until, accountId) {
  return await axios.get(route('api.get.account.time'), {
    params: {
      type: type,
      since: since,
      until: until,
      account_id: accountId,
    },
  })
}
export async function getMultipleAccountData(since, until, analyticType) {
  return await axios.get(route('api.get.summary.account'), {
    params: {
      since: since,
      until: until,
      analyticType: analyticType,
    },
  })
}
export async function getInstaAccountLink() {
  return await axios.get(route('admin.show.accounts_chart'), {})
}
export async function getHashtagAccount(since, until) {
  return await axios.get(route('api.detail.hashtag'), {
    params: {
      since: since,
      until: until,
    },
  })
}

export async function checkInsAccount() {
  return await axios.get(route('api.check.account'), {})
}

// competitor account
export async function filterCompetitorAccount(text) {
  return await axios.get(route('api.filter-acount-competitor'), {
    params: {
      keyword: text,
    },
  })
}

export async function saveCompetitorAccount(params) {
  return await axios.post(route('api.save-account-competitor'), params)
}

export async function deleteCompetitorAccount(params) {
  return await axios.post(route('api.delete-account-competitor', params))
}

export async function getDataAccountCompetitor(id, sinceTime, untilTime) {
  return await axios.post(route('api.get-account-competitor', id), {
    since: sinceTime,
    until: untilTime,
  })
}

export async function getAllAccountCompetitor(page) {
  return await axios.get(route('api.get-all-account-competitor'), {
    params: {
      page: page,
    },
  })
}
export async function getFollowSummary(since, until, analyticType) {
  return await axios.get(route('api.get.follow.summary'), {
    params: {
      since: since,
      until: until,
      analyticType: analyticType,
    },
  })
}
export async function getPdfSetting() {
  return await axios.get(route('api.get.pdf.setting'))
}

export async function getDataAnalysis(sinceTime, untilTime) {
  return await axios.get(route('api.analysis-competitor'), {
    params: {
      since: sinceTime,
      until: untilTime,
    },
  })
}
