<template>
  <dashboard>
    <!-- Instagram -->
    <div class="sm:py-0 sm:px-0 md:py-12 md:px-12 bg-background min-h-screen">
      <div class="max-w-full mx-auto sm:px-6 lg:px-8">
        <h1 class="capitalize text-button_pink font-bold text-title">アカウント管理</h1>
        <div class="flex flex-wrap -mb-4 mt-4 gap-x-3">
          <!--  v-if="$page.props.auth.permissions.includes('manage_plan')" -->
          <Link
            v-if="
              hasRole('master') ||
              hasRole('admin_enterprise') ||
              hasRole('admin_oem') ||
              hasRole('admin_agency')
            "
            :href="route('admin.plans.index')"
            class="flex items-center text-[13px] w-1/3 justify-between cursor-pointer mb-4 h-20 link__item drop-shadow-md bg-white transform hover:text-white px-3 hover:bg-button_pink transition duration-450 ease-in-out font-sans"
          >
            <h1>プラン管理</h1>
            <i class="fa-solid fa-plus text-xl"></i>
          </Link>
          <Link
            v-if="$page.props.auth.permissions.includes('manage_notification')"
            :href="route('admin.notifications.index')"
            class="flex items-center text-[13px] w-1/3 justify-between cursor-pointer mb-4 h-20 link__item drop-shadow-md bg-white transform hover:text-white px-3 hover:bg-button_pink transition duration-450 ease-in-out font-sans"
          >
            <h1>お知らせ管理</h1>
            <i class="fa-solid fa-plus text-xl"></i>
          </Link>
          <Link
            v-if="$page.props.auth.permissions.includes('show_user')"
            :href="route('admin.users.index')"
            class="flex items-center text-[13px] w-1/3 justify-between cursor-pointer mb-4 h-20 link__item drop-shadow-md bg-white transform hover:text-white px-3 hover:bg-button_pink transition duration-450 ease-in-out font-sans"
          >
            <h1>ユーザー管理</h1>
            <i class="fa-solid fa-plus text-xl"></i>
          </Link>
        </div>
      </div>
      <!-- Some Magical Thing -->
      <div
        class="max-w-full mx-auto sm:px-6 lg:px-8 mt-12"
        v-if="instagramMenu.filter((item) => item.isAllow).length > 0"
      >
        <h1 class="capitalize text-button_pink font-bold font-sans text-title">分析</h1>
        <div class="flex flex-wrap -mb-4 mt-4 gap-x-3">
          <Link
            v-for="(menu, index) in instagramMenu"
            :href="menu.link"
            :key="index"
            v-show="menu.isAllow"
            class="flex items-center text-[13px] w-1/3 justify-between cursor-pointer mb-4 h-20 link__item drop-shadow-md bg-white transform hover:text-white px-3 hover:bg-button_pink transition duration-450 ease-in-out font-sans"
          >
            <h1>{{ menu.name }}</h1>
            <i class="fa-solid fa-plus text-xl"></i>
          </Link>
        </div>
      </div>
      <!-- Some Magical Thing -->
      <div v-if="!(reportMenu[0]?.isAllow  == false && reportMenu[1]?.isAllow == false)" class="max-w-full mx-auto sm:px-6 lg:px-8 mt-12">
        <h1 class="capitalize text-button_pink font-bold font-sans text-title">レポーティング</h1>
        <div class="flex flex-wrap -mb-4 mt-4 gap-x-3">
            <Link
                v-for="(menu, index) in reportMenu"
                :href="menu.link"
                :key="index"
                v-show="menu.isAllow"
                class="flex items-center text-[13px] w-1/3 justify-between cursor-pointer mb-4 h-20 link__item drop-shadow-md bg-white transform hover:text-white px-3 hover:bg-button_pink transition duration-450 ease-in-out font-sans break-all">
              <h1>{{ menu.name }}</h1>
              <i class="fa-solid fa-plus text-xl"></i>
            </Link>
        <!-- <div class="flex items-center text-[15px] w-1/3 justify-between cursor-pointer mb-4 h-20 link__item drop-shadow-md bg-white transform hover:text-white px-3 hover:bg-button_pink transition duration-450 ease-in-out font-sanst">
            <h1>Google Date Studio</h1>
            <i class="fa-solid fa-plus text-xl"></i>
        </div> -->
      </div>
      </div>
      <!-- Some Magical Thing -->
      <div class="max-w-full mx-auto sm:px-6 lg:px-8 mt-12">
        <h1 class="capitalize text-button_pink font-bold font-sans text-title">設定</h1>
        <div class="flex flex-wrap -mb-4 mt-4 gap-x-3">
          <Link
              v-for="(menu, index) in settingMenu"
              :href="menu.link"
              :key="index"
              v-show="menu.isAllow"
              class="flex items-center text-[13px] w-1/3 justify-between cursor-pointer mb-4 h-20 link__item drop-shadow-md bg-white transform hover:text-white px-3 hover:bg-button_pink transition duration-450 ease-in-out font-sans">
            <h1>{{ menu.name }}</h1>
            <i class="fa-solid fa-plus text-xl"></i>
          </Link>
        </div>
      </div>
    </div>
  </dashboard>
</template>

<script setup>
import Dashboard from '@/Layouts/Authenticated.vue'
import { Link } from '@inertiajs/inertia-vue3'
import { useInstagramMenu,useReportMenu,useSettingMenu } from '@/Composables/menu'

const instagramMenu = useInstagramMenu()
const reportMenu = useReportMenu()
const settingMenu = useSettingMenu()
</script>

<style lang="scss" scoped>
.link__item {
  width: calc(33.3333% - 12px);
}
</style>
