export const replaceUrl = string => {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return string.replace(urlRegex, function (url) {
        var hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
            hyperlink = 'http://' + hyperlink;
        }
        return `<a href="${hyperlink}" class="highlight-url" target="_blank" rel="noopener noreferrer">${url}</a>`
    });
}
