<template>
  <div class="bg-white py-4">
    <h3 v-if="!isShowChart">対象期間のデータがありません。</h3>
    <Line :id="canvasId" :chart-options="chartOptions" :chart-data="chartData" :plugins="plugins" v-if="isShowChart" />
  </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import * as accountAPI from '@/Api/Account'
import dayjs from 'dayjs'

export default {
  name: 'LineChart',
  components: { Line },
  props: {
    range: { type: Object, default: 0 },
    year: { type: Object, default: 0 },
    analyticType: { type: String, default: 'month' },
    showLine: { type: String, default: 'all' },
    chartId: { type: String, default: 'line-chart' },
    datasetIdKey: { type: String, default: 'label' },
    width: { type: Number, default: 100 },
    height: { type: Number, default: 250 },
    plugins: { type: Object, default: () => {} },
    canvasId: { type: String},
  },
  data() {
    return {
      isShowChart: true,
      isShowDate: true,
      accountId: '',
      dataFollower: {
        data: [],
        label: 'フォロワー推移',
        borderColor: '#3e95cd',
        fill: false,
        lineTension: 0.5,
      },
      dataInteractive: {
        data: [],
        label: 'エンゲージメント率推移',
        borderColor: '#BE3D8F',
        fill: true,
        lineTension: 0.5,
      },
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            label: 'followers',
            borderColor: '#3e95cd',
            fill: false,
            lineTension: 0.5,
          },
          {
            data: [],
            label: 'following',
            borderColor: '#BE3D8F',
            fill: false,
            lineTension: 0.5,
          },
        ],
      },
      chartOptions: {
        responsive: true ,
        maintainAspectRatio: false,
        layout: {
          padding: { left: 20, right: 10, top: 20, bottom: 20 },
        },
        plugins: {
          legend: {
            display: true,
            labels: { boxHeight: 1, position: 'top', align: 'start' },
            font: { size: 32 }
          },
          datalabels: {
            display: false,
          },
        },
        tooltips: {
          intersect: true,
        },
        scales: {
          x: { grid: { display: false } },
          y: { grid: { display: false } },
        },
      },
    }
  },
  async created() {
    if (this.range.length > 0) {
      this.getData()
    }
  },
  watch: {
    range: function () {
      this.getData()
    },
    year: function () {
      this.getData()
    },
    showLine() {
      this.checkLineForChart()
    },
  },
  methods: {
    async getData() {
      let sinceTime = this.range[0] ? this.range[0] : this.range
      let untilTime = this.range[1] ? this.range[1] : ''

      if (this.analyticType == 'year') {
        sinceTime = this.year ? dayjs(this.year).year() : dayjs().year()
      } else {
        sinceTime = dayjs(sinceTime).format('YYYY-M-D')
        untilTime = dayjs(untilTime).format('YYYY-M-D')
      }

      const response = await accountAPI.getDetailAccountTime(this.analyticType, sinceTime, untilTime, this.accountId)

      if (response.data.type == 'year') {
        this.chartData.labels = response.data.months
        this.dataFollower.data = response.data.followers
        this.dataInteractive.data = response.data.interactives
      }

      if (response.data.type == 'day') {
        this.chartData.labels = response.data.dataLabels

        this.dataFollower.data = response.data.dataFollowers

        this.dataInteractive.data = response.data.dataInteractive
      }

      this.checkLineForChart()
    },
    checkLineForChart() {
      this.chartData.datasets = []
      // this.chartData.datasets[1] = [];

      if (this.showLine == 'followers') {
        setTimeout(() => {
          this.chartData.datasets[0] = this.dataFollower
        }, 200)
      }

      if (this.showLine == 'interactives') {
        setTimeout(() => {
          this.chartData.datasets[0] = this.dataInteractive
        }, 200)
      }

      if (this.showLine == 'all') {
        setTimeout(() => {
          this.chartData.datasets[0] = this.dataFollower
          this.chartData.datasets[1] = this.dataInteractive
        }, 200)
      }
    },
  },
}
</script>

<style></style>
