import { computed } from "vue";
import { useProps } from "./prop";
const useInstagramMenu = (_) => {
    const props = useProps();
    return computed((_) => props.value?.instagramMenu || []);
};

const useReportMenu = (_) => {
    const props = useProps();
    return computed((_) => props.value?.reportMenu || []);
};

const useOtherMenu = (_) => {
    const props = useProps();
    return computed((_) => props.value?.otherMenu || []);
};

const useDashboardMenu = (_) => {
    const props = useProps();
    return computed((_) => props.value?.dashboardMenu[0].link || []);
}

const useAccountMenu = (_) => {
    const props = useProps();
    return computed((_) => props.value?.accountMenu || []);
}
const useSettingMenu = (_) => {
    const props = useProps();
    return computed((_) => props.value?.settingMenu || []);
}
export { useInstagramMenu, useReportMenu, useOtherMenu, useDashboardMenu, useAccountMenu , useSettingMenu };
