<template>

  <div class="flex">
    <div class="basis-2/12">
      <SideBar />
    </div>
    <div class="basis-10/12">
      <Header />
      <Notification />
      <PartnerStatistic />
    </div>
  </div>
</template>

<script>
import Header from "@/Layouts/AppHeader.vue";
import Footer from "@/Layouts/AppFooter.vue";
import SideBar from "@/Layouts/AppSidebar.vue";
import PartnerStatistic from "./Partners/Index.vue";
import Notification from "@/Components/Notifications.vue";
import NotificationDelivery from "./Notifications/Index.vue";
export default {
  components: {
    Header,
    Footer,
    SideBar,
    PartnerStatistic,
    Notification,
    NotificationDelivery,
  },
};
</script>

<style scoped scss>
.detail button {
    box-shadow: 0 0 6px rgba(81, 5, 52, 0.25);
}
</style>
