<template>
  <div class="statistic">
    <h2 class="text-xl text-button_pink font-semibold py-5">投稿一覧</h2>
    <div class="menu flex items-center mb-6">
      <div class="menu__search flex relative">
        <input class="flex-1 mr-5 input-cm" placeholder="検索" type="text" />
        <div class="bg-blue-600 text-white px-7 py-4.5 w-max">
          <button>検索</button>
        </div>
      </div>
    </div>
    <Table :label="tableLabel" :data="tableData">
      <template v-slot:first-column>
        <td class="flex justify-center items-center py-4">
          <img src="/images/unsplash_yRELK_NOAGM.png" alt="" />
        </td>
      </template>
      <template v-slot:end-column>
        <td>
          <button
            class="rounded button-shadow py-2 px-5.5 bg-blue-600 text-white"
          >
            確認する
          </button>
        </td>
      </template>
    </Table>
    <Pagination />
  </div>
</template>

<script>
import Table from "@/Components/Table.vue";
import Pagination from "@/Components/Pagination.vue";
export default {
  components: {
    Table,
    Pagination,
  },
  data() {
    return {
      tableLabel: [
        "投稿画像",
        "タイプ",
        "投稿内容",
        "ハッシュタグ",
        "投稿日時",
        "いいね数",
        "コメント数",
        "リーチ数",
        " エンゲージメ",
        "初速分析",
      ],
      tableData: [
        {
          title: "通常",
          tag: "esttesttetstetstetstetsttetstetsttetstettsttettettat",
          Authority: "#test #test",
          plan: "2022/1/1 10:00",
          payment: "100",
          amount: "3",
          startDate: "1",
          endDate: "5.26%",
        },
        {
          title: "通常",
          tag: "esttesttetstetstetstetsttetstetsttetstettsttettettat",
          Authority: "#test #test",
          plan: "2022/1/1 10:00",
          payment: "100",
          amount: "3",
          startDate: "1",
          endDate: "5.26%",
        },
        {
          title: "通常",
          tag: "esttesttetstetstetstetsttetstetsttetstettsttettettat",
          Authority: "#test #test",
          plan: "2022/1/1 10:00",
          payment: "100",
          amount: "3",
          startDate: "1",
          endDate: "5.26%",
        },
        {
          title: "通常",
          tag: "esttesttetstetstetstetsttetstetsttetstettsttettettat",
          Authority: "#test #test",
          plan: "2022/1/1 10:00",
          payment: "100",
          amount: "3",
          startDate: "1",
          endDate: "5.26%",
        },
        {
          title: "通常",
          tag: "esttesttetstetstetstetsttetstetsttetstettsttettettat",
          Authority: "#test #test",
          plan: "2022/1/1 10:00",
          payment: "100",
          amount: "3",
          startDate: "1",
          endDate: "5.26%",
        },
        {
          title: "通常",
          tag: "esttesttetstetstetstetsttetstetsttetstettsttettettat",
          Authority: "#test #test",
          plan: "2022/1/1 10:00",
          payment: "100",
          amount: "3",
          startDate: "1",
          endDate: "5.26%",
        },
        {
          title: "通常",
          tag: "esttesttetstetstetstetsttetstetsttetstettsttettettat",
          Authority: "#test #test",
          plan: "2022/1/1 10:00",
          payment: "100",
          amount: "3",
          startDate: "1",
          endDate: "5.26%",
        },
        {
          title: "通常",
          tag: "esttesttetstetstetstetsttetstetsttetstettsttettettat",
          Authority: "#test #test",
          plan: "2022/1/1 10:00",
          payment: "100",
          amount: "3",
          startDate: "1",
          endDate: "5.26%",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
