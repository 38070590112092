<template>
  <div class="linechart-contain chart-scroll__pdf" v-if="dataChart.length != 0">
    <Line
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :id="canvasID"
    />
  </div>
  <div v-else>
    <el-empty description="データはありません" />
  </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import { CategoryScale } from 'chart.js'
import { Inertia } from '@inertiajs/inertia'
import { chartOptions } from './untils/initData'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement
)

export default {
  name: 'BarChart',
  components: { Line },
  props: {
    chartId: { type: String, default: 'bar-chart' },
    datasetIdKey: { type: String, default: 'label' },
    type: { type: String, default: 'follow' },
    width: { type: Number, default: 400 },
    height: { type: Number, default: 400 },
    cssClasses: { default: '', type: String },
    styles: { type: Object, default: () => {} },
    plugins: { type: Object, default: () => {} },
    dataLabel: { type: Object, default: () => {} },
    dataChart: { type: Object },
    canvasID: { type: String },
  },
  computed: {
    isExport() {
      return Inertia?.page?.url === '/export/report-preview'
    },
  },
  data() {
    return {
      chartOptions,
      colors: [
        '#b55fbe',
        '#FF1616',
        '#D02D92',
        '#00B05C',
        '#b17325',
        '#1E88E5',
        '#039BE5',
        '#00ACC1',
        '#00897B',
        '#43A047',
        '#7CB342',
        '#C0CA33',
        '#FDD835',
        '#FFB300',
        '#FB8C00',
        '#F4511E',
        '#6D4C41',
        '#757575',
        '#546E7A',
      ],
      chartData: {
        datasets: [],
        labels: this.dataLabel,
      },
    }
  },

  created() {
    this.mapDataChart()
  },

  watch: {
    dataChart: {
      handler() {
        this.mapDataChart()
      },
    },
  },

  methods: {
    mapDataChart() {
      this.chartData.labels = []
      this.chartData.datasets = []
      this.chartData.labels = this.dataLabel

      let indexSearchNull = []
      this.dataChart.forEach((element) => {
        indexSearchNull.push(this.getIndexNull(element.dataFollow))
      })

      const indexNullMin = Math.min(...indexSearchNull)

      let key = 0
      this.dataChart.forEach((element) => {
        this.chartData.datasets.push({
          label: element.username,
          data: this.mapTypeChart(this.type, element, indexNullMin),
          type: 'line',
          borderColor: key > this.colors.length ? this.colors[0] : this.colors[key],
          backgroundColor: key > this.colors.length ? this.colors[0] : this.colors[key],
          lineTension: 0,
          borderWidth: 3,
          pointRadius: 3,
          yAxisID: 'A',
        })
        key += 1
      })
    },

    mapTypeChart(type, element, indexNullMin) {
      let result
      switch (type) {
        case 'follow':
          result = this.removeNullData(indexNullMin, element.dataFollow)
          break

        case 'follow_change':
          result = this.removeNullData(indexNullMin, element.dataFollowChange)
          break

        case 'post':
          result = this.removeNullData(indexNullMin, element.dataMedias)
          break

        case 'change_post':
          result = this.removeNullData(indexNullMin, element.dataMediasChange)
          break

        default:
          result = this.removeNullData(indexNullMin, element.dataFollow)
          break
      }

      return result
    },
    removeNullData(index, array) {
      let result = [...array]
      let labels = [...this.dataLabel]

      if (index) {
        result = result.splice(index - result.length)
        this.chartData.labels = labels.splice(index - this.dataLabel.length)
      }

      result = result.map(function (item) {
        if (item) {
          return item
        }
        return 0
      })

      return result
    },
    getIndexNull(array) {
      const search = (element) => element !== null
      const indexSearch = array.findIndex(search)

      return indexSearch
    },
  },
}
</script>
<style lang="scss" scoped>
.linechart-contain {
  #bar-chart {
    height: 600px !important;
  }
}
.chart-scroll__pdf {
  padding: 1rem 0 !important;
  width: 100%;
  overflow-x: auto;
  & > div {
    width: 1510px;
    height: 400px;
  }
}
@media (max-width: 640px) {
  .linechart-contain {
    #bar-chart {
      height: 350px !important;
    }
  }
  .chart--scroll {
    padding: 1rem 0;
    width: 100%;
    overflow: scroll;
    & > div {
      width: 800px;
      height: 400px;
    }
  }
}
</style>
