<template>
<div class="linechart-contain chart--scroll">
    <Line
        :chart-data="chartData"
        :chart-options="chartOptions"
    />
</div>
</template>

<script>
import { Line } from "vue-chartjs";
import { CategoryScale } from "chart.js";
import {
  Chart,
  Title,
  Tooltip,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";

Chart.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement
);
export default {
    name: "LineChart",
    props: {
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 400,
        },
        chartData: {
            label: [],
            datasets: [],
        },
    },
    components: {Line},
    data() {
        return {
            chartOptions: {
                type: "line",
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        labels: {
                            boxHeight: 1
                        },
                    },
                    tooltip: {
                        backgroundColor: "rgba(0,0,0,0.4)",
                        boxWidth: 20,
                        boxHeight: 1
                    },
                    datalabels: {
                        display: false,
                    },
                },
                yAxes: [
                    {
                        gridLines: {
                            drawBorder: false,
                        },
                    },
                ],
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            font: {
                                size: 14,
                                color: "#414141",
                            },
                        },
                    },
                    y: {
                        grid: {
                            drawBorder: false,
                        },
                        ticks: {
                            font: {
                                size: 14,
                                color: "#414141",
                            },
                        },
                    },
                },
                elements: {

                },
            },
        };
    },
};
</script>

<style lang="scss">
.linechart-contain{
    #line-chart{
        height: 400px !important;
    }
}
@media (max-width: 640px) {
.linechart-contain{
    #line-chart{
        height: 400px !important;
        }
    }
  .chart--scroll {
    padding: 1rem 0 !important;
    width: 100%;
    overflow: scroll;
    & > div {
      width: 800px;
      height: 400px;
    }
  }
}
</style>
