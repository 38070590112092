<template>
<div class="bg-background">
  <dashboard>
    <template v-slot:breakcumb>
      <BreadCrumb class="pl-10 bg-white ml-0 py-10">
        <li>
          <Link :href="route('admin.group-list.index')" class="font-normal">グルーピング</Link>
        </li>
        <span class="font-normal text-notice_date mx-2">></span>
        <li>
          <Link :href="'/group-analysis/' + group.id" class="font-normal breakcumb-active">グループアカウント分析</Link>
        </li>
      </BreadCrumb>
    </template>
    <div v-if="!isShowChart" class="px-6">
      <link-instagram></link-instagram>
    </div>
    <template v-slot:tabs>
      <div class="tabs">
        <ul class="flex md:pl-10 sm:pl-5 bg-white">
          <li class="pr-4 relative pb-5" :class="{ active: current_tab == 'tab-1' }" @click="demossss">
            グループアカウント分析
          </li>
          <li class="px-6 relative pb-5" :class="{ active: current_tab == 'tab-2' }" @click="current_tab = 'tab-2'">
            横断比較
          </li>
        </ul>
        <!-- start tab 1  -->
      </div>
    </template>
     <div class="mt-10" v-if="current_tab == 'tab-1'">
          <MemberAnalysis :group="group"></MemberAnalysis>
        </div>
        <!-- start tab 2  -->
        <div class="mt-10" v-if="current_tab == 'tab-2'">
          <MultiMemberAnalysis :group="group"></MultiMemberAnalysis>
        </div>
  </dashboard>
  </div>
</template>
<script>
import MemberAnalysis from './MemberAnalysis.vue'
import MultiMemberAnalysis from './MultiMemberAnalysis.vue'
import Dashboard from '@/Layouts/Authenticated.vue'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
export default {
  name: 'GroupAnalysis',
  components: {
    Dashboard,
    BreadCrumb,
    Link,
    MultiMemberAnalysis,
    MemberAnalysis,
    LinkInstagram,
  },

  props: {
    group: {
      type: Object,
      require: true,
    },
  },

  data() {
    return {
      range: [],
      current_tab: 'tab-1',
      lineTab: 'all',
      isShowChart: true,
    }
  },
  methods: {
    demossss() {
      this.current_tab = 'tab-1'
    }
  }
}
</script>
<style>
.tabs {
  margin-top: 0 !important;
}
</style>
