import axios from "axios";
import { forEach } from "lodash";

export default {
    // businessAccount(params) {
    //     return axios.get(
    //         "https://graph.facebook.com/me/accounts?fields=instagram_business_account{username,name,profile_picture_url},access_token&&access_token=" +
    //             params
    //     );
    // },

    async businessAccount(params) {
        let token = btoa(params);

        return await axios.post(route('api.account.instagram.business', token))
    },

    // adsAccount(params) {
    //     return axios.get(
    //         "https://graph.facebook.com/v13.0/me/adaccounts?fields=id,account_id,name&&access_token=" + params
    //     );
    // },

    async adsAccount(params) {
        let token = btoa(params);

        return await axios.post(route('api.account.handle.adaccounts', token))
    },

    filterBusinessAccount(data) {
        return data
            .filter((item) => item.instagram_business_account)
            .map((v) => ({
                access_token: v.access_token,
                ...v.instagram_business_account,
                pageId: v.id,
            }));
    },

    async filterTokenAccountIns(item) {
        const { data } = await axios.get(route("api.account.business_id"));

        return item.filter((item) => item.instagram_business_account?.id == data)
    },

    async insAccountLink(data, userID) {
        const params = {
            access_token: atob(data.access_token),
            business_id: data.id,
            user_id: userID,
            page_id: data.pageId,
        };

        return await axios.post(route("api.accounts.store", params));
    },

    async addAdsAccount(data) {
        const params = {
            user_id : data.userId,
            ads_id: data.id,
            name: data.name,
            access_token: btoa(data.access_token)
        };

        return await axios.post(route("api.ads_account.store", params));
    },

    async createAds(param) {
        const formData = new FormData();

        // b1
        formData.append('campaign_name', param.campaign_name)
        formData.append('campaign_objective', param.campaign_objective)
        formData.append('destination_type', param.destination_type)
        formData.append('campaign_special_ad_categories', '[]')

        //b2
        formData.append('ads_set_name', param.setName)
        formData.append('ads_set_optimization_goal', param.optimizationGoal)
        formData.append('ads_set_billing_event', 'IMPRESSIONS')
        formData.append('ads_set_bid_amount', param.bidAmount)
        formData.append('ads_set_daily_budget', param.dailyBudget) // > 1$
        formData.append('ads_set_start_time', param.start_date)
        formData.append('ads_set_end_time', param.end_date)

        formData.append('ads_set_targeting', param.chooseTarget)
        formData.append('ads_set_gender', param.gender)
        formData.append('ads_set_max_age', param.maxAge)
        formData.append('ads_set_min_age', param.minAge)
        formData.append('ads_set_locale', param.addLocale)

        // b3
        formData.append('ad_creative_name', 'creative name')
        if(param.type == 'image'){
            formData.append('ad_creative_file', param.file)
        }

        if(param.type == 'video'){
            formData.append('ad_creative_file[file]', param.file.file)
            formData.append('ad_creative_file[thumnail]', param.file.thumnail)
            formData.append('link_website', param.linksShow)
        }

        if(param.type == 'carousel'){
            param.file.forEach((item, index) => {
                formData.append('ad_creative_file[' + index +']' + '[file]', item.file)
                formData.append('ad_creative_file[' + index +']' + '[thumnail]', item.thumnail)
                formData.append('ad_creative_file[' + index +']' + '[description]', item.description)
                formData.append('ad_creative_file[' + index +']' + '[type]', item.type)
                formData.append('ad_creative_file[' + index +']' + '[name]', item.name)
                formData.append('ad_creative_file[' + index +']' + '[link]', item.link)
            })

            formData.append('link_website', param.linksShow)
            formData.append('phone_number', '+81'+param.phoneCall)
        }

        formData.append('type', param.type)
        if (param.phoneCall) {
            formData.append('phone_number', param.phoneCall)
        }

        if (param.linksShow) {
            formData.append('link_website', param.linksShow)
        }

        // b4
        formData.append('name_ads', param.nameAds)
        formData.append('message_ads', param.messageAds)

        return await axios.post(route('api.create.ads'), formData)
    },

    addPermission() {
        return [
            "public_profile",
            "email",
            "pages_show_list",
            "pages_manage_metadata",
            "pages_read_engagement",
            // "pages_read_user_content",
            "instagram_basic",
            "instagram_content_publish",
            "instagram_manage_insights",
            "instagram_manage_comments",
            "business_management",
        ].join(",");
    },

    addPermissionAds() {
        return [
            "ads_read",
            "ads_management"
        ].join(",");
    },

    async  checkAllAccountIns(data) {
        const promises = [];
        const vm = this;
        data.map(async (orderGroup) => {
            const value = vm.mapDataAccountIns(orderGroup);
            promises.push(value);
        });
        return Promise.all(promises);
    },

    async mapDataAccountIns(orderGroup) {
        const res =  await this.checkAccountPost(orderGroup.username);
        var orderFull = {
            ...orderGroup,
            status : res?.status == false ? true : false
        };

        return orderFull;
    },

    async checkAccountPost(accountIns) {
        const { data: data } = await axios.get(
          route("api.account.post", accountIns)
        );

        return data;
    },
};
