<template>
  <Dashboard>
    <div class="flex justify-center lg:pt-10 md:pt-5">
      <BreezeApplicationLogo class="w-64 h-20 fill-current text-gray-500" />
    </div>

    <div class="flex items-center justify-between lg:py-5 lg:px-20 md:px-10 md:py-5 sm:px-4 sm:py-4">
      <p class="text-button_pink font-medium text-title">現在のプラン</p>
      <!-- check xem nó có sử dụng plan trial k -->
      <p class="">
        {{ user.plan?.name }}
        <!-- （2022年12月1日 23:59まで） -->
      </p>
    </div>

    <div class="flex lg:py-5 lg:px-20 md:px-10 md:py-5 sm:px-4 sm:py-4 bg-background">
      <p class="text-button_pink w-1/4 font-medium lg:pt-5 md:pt-3 sm:pt-3 lh-2 text-title">プラン選択</p>
      <div class="w-3/4 overflow-y-scroll" style="max-height: 650px">
        <template v-for="(plan, index) in plans" :key="index">
          <template v-if="user.plan !== null && user?.plan?.id == plan.id">
            <div
              class="text-white font-bold lg:p-5 md:p-3 mb-5 flex justify-between md:items-center sm:items-start bg-gray-400"
            >
              <div>
                <p class="text-line-clamp-3 lh-2 text-nomal">
                  {{ plan.name }}
                </p>
                <p class="text-line-clamp-3 lh-2 text-nomal">
                  {{ plan.note ? plan.note : '' }}
                </p>
              </div>
              <p class="text-nomal">
                {{ plan.amount.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' }) }}
              </p>
            </div>
          </template>
          <div
            v-else
            @click="plan_choose = plan"
            class="bg-white md:p-5 md:mb-5 sm:p-3 sm:mb-3 flex justify-between md:items-center sm:items-start cursor-pointer border hover:border-button_pink"
            :class="plan_choose.id == plan.id ? 'border-button_pink font-bold' : 'border-white'"
          >
            <div>
              <p class="text-line-clamp-3 lh-2 text-3sm">
                {{ plan.name }}
              </p>
              <p class="text-line-clamp-3 lh-2 text-nomal">
                {{ plan.note ? plan.note : '' }}
              </p>
            </div>
            <p class="text-nomal">
              {{ plan.amount.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' }) }}
            </p>
          </div>
        </template>
      </div>
    </div>

    <div class="flex items-center justify-center w-full bg-background py-10">
      <button
        @click="callbackProfile"
        class="md:py-5.5 sm:py-2.5 bg-button_pink md:px-29 sm:px-10 text-white button-shadow rounded text-btn-submit"
      >
        戻る
      </button>
      <button
        @click="updatePlanUser()"
        as="button"
        type="submit"
        class="md:py-5.5 bg-button_profile md:ml-6 md:px-29 sm:px-10 sm:py-2.5 sm:ml-3 text-white button-shadow rounded text-btn-submit"
      >
        決済画面へ
      </button>
    </div>
  </Dashboard>
</template>

<script>
import Dashboard from '@/Pages/Dashboard.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeLabel from '@/Components/Label.vue'
import BreezeValidationErrors from '@/Components/ValidationErrors.vue'
import { Link } from '@inertiajs/inertia-vue3'
import BreezeApplicationLogo from '@/Components/ApplicationLogo.vue'

export default {
  props: {
    user_id: {
      type: Number,
      required: true,
    },
  },

  components: {
    Dashboard,
    BreezeValidationErrors,
    BreezeInput,
    BreezeLabel,
    Link,
    BreezeApplicationLogo,
  },

  data() {
    return {
      plans: [],
      plan_choose: {},
      user: {},
    }
  },

  created() {
    this.fetchDataPlan()
    this.fetchDataUser()
  },

  methods: {
    callbackProfile() {
      return this.$inertia.visit(route('admin.users_infor'))
    },

    choosePlanActive(plan_id) {
      this.plans = this.plans.map((plan) => ({
        id: plan.id,
        name: plan.name,
        amount: plan.amount,
        note: plan.note,
        free: plan.free,
        checkbox: plan.id == plan_id ? true : false,
      }))
    },

    async fetchDataPlan() {
      await axios
        .get('/api/get_plan_user/' + this.user_id)
        .then((res) => {
          this.plans = res.data.data
            .filter((plan) => {
              return plan.trial == 0 && plan.check_ended_at == true
            })
            .map((plan) => ({
              id: plan.id,
              name: plan.name,
              amount: plan.amount,
              free: plan.free,
              note: plan.note,
              checkbox: plan.checkbox,
            }))
        })
        .catch((err) => {
          console.error(err)
        })
    },

    async fetchDataUser() {
      await axios
        .get(route('api.users.show', this.user_id))
        .then((res) => {
          this.user = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },

    updatePlanUser() {
      if (Object.keys(this.plan_choose).length) {
        window.location.href = route('admin.users-payment-plan', this.plan_choose.id)
      } else {
        this.$toast.error(this.$t('Please select the plan you want to upgrade'))
      }
    },
  },
}
</script>
<style scoped>
.lh-2 {
  line-height: 1.5rem;
}
</style>
