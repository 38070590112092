<script setup>
import BreezeButton from "@/Components/Button.vue";
import BreezeGuestLayout from "@/Layouts/GuestRegister.vue";
import BreezeInput from "@/Components/Input.vue";
import BreezeLabel from "@/Components/Label.vue";
import {Head, useForm} from "@inertiajs/inertia-vue3";

const form = useForm({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    terms: false,
});
const submit = () => {
    form.post(route("register"), {
        onFinish: () => form.reset("password", "password_confirmation"),
    });
};
</script>

<template>
    <BreezeGuestLayout>
        <Head title="Register"/>

        <!-- <BreezeValidationErrors class="mb-4 " /> -->

        <!-- Form -->
        <form @submit.prevent="submit">

            <!-- Title -->
            <div class="flex justify-center mb-9">
                <h1 class="text-pink-400 font-bold text-2xl">
                    フリートライアルに申し込む
                </h1>
            </div>

            <!-- ID -->
            <div class="flex justify-center items-center">
                <BreezeLabel class="text-xs w-32">ID</BreezeLabel>
                <BreezeInput
                    id="lastName"
                    v-model="form.id"
                    autocomplete="name"
                    autofocus
                    class="block rounded-sm p-3 w-full"
                    placeholder="テキストテキスト"
                    required
                    type="text"
                />
            </div>

            <!-- First Name -->
            <div class="flex justify-center items-center mt-4">
                <BreezeLabel class="text-xs w-32">名前（名）</BreezeLabel>
                <BreezeInput
                    id="firstName"
                    v-model="form.firstName"
                    autocomplete="name"
                    class="block rounded-sm p-3 w-full"
                    placeholder="テキストテキスト"
                    required
                    type="text"
                />
            </div>

            <!-- Password -->
            <div class="flex justify-center items-center mt-4">
                <BreezeLabel class="text-xs w-32">パスワード</BreezeLabel>
                <BreezeInput
                    id="password"
                    v-model="form.password"
                    autocomplete="new-password" class="block rounded-sm p-3 w-full"
                    placeholder="テキストテキスト"
                    required
                    style="border: 0"
                    type="password"
                />
            </div>

            <!-- Password Confirmation -->
            <div class="flex justify-center items-center mt-4">
                <BreezeLabel class="text-xs w-32">パスワード（確認）</BreezeLabel>
                <BreezeInput id="password_confirmation" v-model="form.password_confirmation" autocomplete="new-password"
                             class="block rounded-sm p-3 w-full"
                             placeholder="テキストテキスト" required style="border: 0"
                             type="password"/>
            </div>

            <div class="flex items-center justify-center mt-7">
                <BreezeButton
                    :class="{ 'opacity-25': form.processing }" :disabled="form.processing"
                    class="bg-indigo-500 hover:bg-indigo-700 pt-5 pb-5 rounded-sm"
                    style="padding-right: 245px; padding-left: 245px;"
                >
                    送信
                </BreezeButton>
            </div>
        </form>
    </BreezeGuestLayout>
</template>
