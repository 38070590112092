<template>
  <BreezeGuestLayout my_form_login="my-20" v-if="!status">
    <Head title="Register" />
    <!-- Title -->
<!--    <div class="mb-10">-->
<!--      <div class="flex justify-center">-->
<!--        <p class="font-bold text-xl">フリープランのお申し込み</p>-->
<!--      </div>-->
<!--    </div>-->
    <!-- Form -->
    <form @submit.prevent="submit" class="container mx-auto px-5 pb-5">
      <!-- Title -->
      <h1 class="text-button_pink font-bold mb-5 text-3sm">お客様情報</h1>

      <div class="flex-sm flex items-center w-full lg:justify-between">
        <!-- First Name -->
        <BreezeLabel class="w-32 font-bold text-2sm">
          氏名（姓）
          <span class="text-red-600">*</span>
        </BreezeLabel>
        <BreezeInput
          maxlength="60"
          v-model="form.first_name"
          class="block sm:w-72 md:w-96 rounded-sm p-3 text-2sm"
          :class="form.errors.first_name ? 'border-red-600' : ''"
          @change="form.errors.first_name = ''"
          placeholder="氏名を入力"
          type="text"
        />
        <!-- Last Name -->
        <div class="w-20 sm:hidden lg:flex"></div>
        <BreezeLabel class="w-32 sm:hidden lg:flex font-bold text-2sm">
          名前（名）
          <span class="text-red-600">*</span>
        </BreezeLabel>
        <BreezeInput
          maxlength="60"
          v-model="form.last_name"
          class="block sm:w-72 md:w-96 rounded-sm p-3 sm:hidden lg:flex text-2sm"
          :class="form.errors.last_name ? 'border-red-600' : ''"
          @change="form.errors.last_name = ''"
          placeholder="名前を入力"
          type="text"
        />
      </div>
      <!-- Custom show input last name for mobile  -->
      <div class="sm:flex lg:hidden flex items-center w-full">
        <div class="sm:w-0 lg:w-32"></div>
        <div class="text-left text-red-600 mt-2 sm:text-2sm text-base sm:w-72 md:w-96" v-if="form.errors.first_name">
          {{ form.errors.first_name }}
        </div>
      </div>

      <div class="flex items-center w-full mt-4 flex-sm sm:flex lg:hidden">
        <!-- Last Name -->
        <BreezeLabel class="w-32 font-bold text-2sm">
          名前（名）
          <span class="text-red-600">*</span>
        </BreezeLabel>
        <BreezeInput
          maxlength="60"
          v-model="form.last_name"
          class="block sm:w-72 md:w-96 rounded-sm p-3 text-2sm"
          :class="form.errors.last_name ? 'border-red-600' : ''"
          @change="form.errors.last_name = ''"
          placeholder="名前を入力"
          type="text"
        />
      </div>
      <div class="sm:flex lg:hidden flex items-center w-full">
        <div class="sm:w-0 lg:w-32"></div>
        <div class="text-left text-red-600 sm:text-2sm mt-2 text-base sm:w-72 md:w-96" v-if="form.errors.last_name">
          {{ form.errors.last_name }}
        </div>
      </div>
      <!-- Custom show input last name for mobile  -->

      <div class="sm:hidden lg:flex justify-between items-center w-full mt-2">
        <div class="w-32"></div>
        <div class="text-left text-red-600 sm:text-2sm text-base sm:w-72 md:w-96" v-if="form.errors.first_name">
          {{ form.errors.first_name }}
        </div>
        <div class="w-20"></div>
        <div class="w-32"></div>
        <div class="text-left text-red-600 sm:text-2sm text-base sm:w-72 md:w-96" v-if="form.errors.last_name">
          {{ form.errors.last_name }}
        </div>
      </div>

      <!-- Email -->
      <div class="flex items-center w-full mt-4 flex-sm">
        <BreezeLabel class="w-32 font-bold text-2sm">
          メールアドレス
          <span class="text-red-600">*</span>
        </BreezeLabel>
        <BreezeInput
          maxlength="100"
          v-model="form.email"
          class="block sm:w-72 md:w-96 rounded-sm p-3 text-2sm"
          :class="form.errors.email ? 'border-red-600' : ''"
          @change="form.errors.email = ''"
          placeholder="メールアドレスを入力"
          type="email"
        />
      </div>
      <div class="flex items-center w-full">
        <div class="sm:w-0 lg:w-32"></div>
        <div class="text-left text-red-600 sm:text-2sm mt-2 text-base sm:w-72 md:w-96" v-if="form.errors.email">
          {{ form.errors.email }}
        </div>
      </div>

      <!-- Category -->
        <div class="flex items-center w-full mt-4 flex-sm">
            <BreezeLabel class="w-32 font-bold text-2sm">
                ジャンル
                <span class="text-red-600">*</span>
            </BreezeLabel>
            <el-select
             class="sm:w-72 md:w-96 rounded-sm category-select text-2sm"
             :class="form.errors.category ? 'border border-red-600 rounded' : ''"
             v-model="form.category" placeholder="ジャンルを入力"
             size="large">
              <el-option
                v-for="item in categories"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
        </div>
        <div class="flex items-center w-full">
            <div class="sm:w-0 lg:w-32"></div>
            <div class="text-left text-red-600 sm:text-2sm mt-2 text-base sm:w-72 md:w-96" v-if="form.errors.category">
            {{ form.errors.category }}
            </div>
        </div>

      <!-- Company Name -->
      <div class="flex items-center w-full mt-4 flex-sm">
        <BreezeLabel class="w-32 font-bold text-2sm">
          会社名
          <span class="text-red-600">*</span>
        </BreezeLabel>

        <BreezeInput
          :class="form.errors.company ? 'border-red-600' : ''"
          maxlength="60"
          v-model="form.company"
          @change="form.errors.company = ''"
          class="block sm:w-72 md:w-96 rounded-sm p-3 text-2sm"
          placeholder="会社名を入力"
          type="text"
        />
      </div>
      <div class="flex items-center w-full">
        <div class="sm:w-0 lg:w-32"></div>
        <div class="text-left text-red-600 sm:text-2sm mt-2 text-base sm:w-72 md:w-96" v-if="form.errors.company">
          {{ form.errors.company }}
        </div>
      </div>

      <!-- zipcode -->
      <div class="flex items-center w-full mt-4 flex-sm">
        <BreezeLabel class="w-32 font-bold text-2sm">郵便番号</BreezeLabel>
        <BreezeInput
          v-model="form.zip_code"
          class="block sm:w-72 md:w-96 rounded-sm p-3 text-2sm"
          placeholder="郵便番号を入力"
          type="text"
          maxlength="7"
          @change="checkZipCode(form.zip_code)"
        />
        <BreezeLabel class="sm:ml-0 md:ml-5 text-2sm font-bold">（ハイフンなし）</BreezeLabel>
      </div>

      <!-- address1 -->
      <div class="flex items-center w-full mt-4 flex-sm">
        <BreezeLabel class="w-32 font-bold text-2sm">都道府県</BreezeLabel>
        <BreezeInput
          v-model="form.address_1"
          class="block sm:w-72 md:w-96 rounded-sm p-3 text-2sm"
          placeholder="都道府県を入力"
          type="text"
        />
      </div>

      <!-- address_2 -->
      <div class="flex items-center w-full mt-4 flex-sm">
        <BreezeLabel class="w-32 font-bold text-2sm">市区町村</BreezeLabel>
        <BreezeInput
          v-model="form.address_2"
          class="block sm:w-72 md:w-96 rounded-sm p-3 text-2sm"
          placeholder="市区町村を入力"
          type="text"
        />
      </div>

        <!-- address -->
        <div class="flex items-center w-full mt-4 flex-sm">
            <BreezeLabel class="w-32 font-bold text-2sm">住所</BreezeLabel>
            <BreezeInput
                v-model="form.address"
                class="block rounded-sm p-3 sm:w-72 md:w-96 lg:w-10/12 text-2sm custom-w-input"
                placeholder="住所を入力"
                type="text"
            />
        </div>

      <!-- Phone Number -->
      <div class="flex items-center w-full mt-4 flex-sm">
        <BreezeLabel class="w-32 font-bold text-2sm">
          電話番号
          <span class="text-red-600">*</span>
        </BreezeLabel>
        <BreezeInput
          maxlength="60"
          v-model="form.phone"
          class="block sm:w-72 md:w-96 rounded-sm p-3 text-2sm"
          placeholder="電話番号を入力"
          :class="form.errors.phone ? 'border-red-600' : ''"
          @change="form.errors.phone = ''"
          type="text"
        />
      </div>
      <div class="flex items-center w-full">
        <div class="sm:w-0 lg:w-32"></div>
        <div class="text-left text-red-600 sm:text-2sm mt-2 text-base sm:w-72 md:w-96" v-if="form.errors.phone">
          {{ form.errors.phone }}
        </div>
      </div>

      <!-- Term of Service -->
      <div class="flex justify-center items-center mt-10 mb-10">
        <div>
          <div class="flex items-center w-full">
            <input
              id="flexCheckChecked"
              class="form-check-input appearance-none h-4 w-4border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
              v-model="form.checkbox"
              @change="form.errors.checkbox = ''"
            />
            <label class="form-check-label inline-block text-gray-800 sm:text-sm md:text-3sm" for="flexCheckChecked">
              <a
                href="/term" target="_blank"
              >
                <span class="text-button_pink hover:cursor-pointer text-2sm">利用規約・</span>
              </a>
              <a
                href="/privacy" target="_blank"
              >
                <span class="text-button_pink hover:cursor-pointer text-2sm">プライバシーポリシ</span>
              </a>
              <span class="text-2sm">ーに同意する</span>
            </label>
          </div>
          <div class="sm:text-center md:text-left mt-2 text-baseo w-full" style="height:16px">
            <span v-if="form.errors.checkbox" class="sm:text-2sm text-base text-red-600">{{ form.errors.checkbox }}</span>
          </div>
        </div>
      </div>

            <div class="flex items-center justify-center mt-5">
                <BreezeButton
                    class="bg-indigo-500 hover:bg-indigo-700 pt-5 pb-5 sm:px-10 md:px-20 rounded-sm text-3sm"
                >
                    フリープランに申し込む
                </BreezeButton>
            </div>
            <div class="text-center pt-8">
                <Link
                    :href="route('formLogin.user')"
                    class="text-indigo-600 hover:text-indigo-400 text-3sm"
                >
                    ログイン画面に戻る
                </Link>
            </div>
        </form>
    </BreezeGuestLayout>

  <BreezeGuestLayout my_form_login="my-40 py-20" border_form_login="border border-gray-300" v-else>
    <Head title="Register" />
    <div class="font-medium text-3sm text-center px-20">
      （サービス名）○○プランへのお申し込みありがとうございます。
      <br />
      ご登録のメールアドレスにお申し込み情報の詳細をお送りしていますのでご確認ください
    </div>
  </BreezeGuestLayout>
</template>

<script>
import BreezeButton from '@/Components/Button.vue'
import BreezeGuestLayout from '@/Layouts/Guest.vue'
import BreezeInput from '@/Components/Input.vue'
import BreezeLabel from '@/Components/Label.vue'
import BreezeValidationErrors from '@/Components/ValidationErrors.vue'
import { Head, useForm, Link } from '@inertiajs/inertia-vue3'
import { getDataZipCode } from '@/Api/Users'
import Multiselect from '@suadelabs/vue3-multiselect'

export default {
  components: {
    BreezeButton,
    Multiselect,
    BreezeGuestLayout,
    BreezeValidationErrors,
    BreezeInput,
    BreezeLabel,
    Head,
    useForm,
    Link,
  },

  props: {
    status: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      form: useForm({
        last_name: '',
        first_name: '',
        company: '',
        email: '',
        phone: null,
        checkbox: false,
        address: '',
        address_1: '',
        address_2: '',
        zip_code: '',
        category: '',
      }),
      categories: [
        '美容',
        'ショッピング',
        'スタイル',
        '食べ物/グルメ',
        '動物',
        'インテリアデザイン',
        'DIY',
        '建築物',
        'アート',
        'コミック',
        '自然',
        'テレビ/映画',
        '旅行',
        '雑貨',
        'ファッション',
        'フィットネス',
        'ガジェット',
      ]
    }
  },

  methods: {
    submit() {
      const me = this.$t('sign_up_success_please_check_your_email')
      this.form.post(route('user.register.mail'), {
        onFinish: () => {
          if (!this.form.hasErrors) {
               this.$toast.success(me)
          }
        },
      })
    },

    async checkZipCode(zip_code) {
      await getDataZipCode(zip_code)
        .then(async (res) => {
          if (res.data.results !== null) {
            let data = res.data.results[0]
            this.form.address = data.address3
            this.form.address_1 = data.address1
            this.form.address_2 = data.address2
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },

  watch: {
        'form.category'(){
            this.form.errors.category = false
        }
    }
}
</script>

<style>
.category-select .el-input__inner {
  height: 50px;
}
</style>

<style scoped>
@media (min-width: 1024px) {
  .custom-w-input {
    width: calc(100% - 8rem) !important;
  }
}
</style>
<style src="@suadelabs/vue3-multiselect/dist/vue3-multiselect.css"></style>
