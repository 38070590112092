import { CAMERA_IMG, HEART_IMG, COMMENT_IMG } from '../Mixins/media'
import { formatToInteger } from '../../../Plugins/numberFormat'
export default {
  methods: {
    genSlideHashtagDetail(pptx, labels, hashtag, text, title) {
      let slideHashtagDetail = pptx.addSlide({ masterName: 'MASTER_SLIDE' })

      slideHashtagDetail.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })

      slideHashtagDetail.addText(title, {
        x: 2.3,
        y: 0.25,
        w: 7,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 20,
      })

      slideHashtagDetail.addShape(pptx.shapes.RECTANGLE, {
        x: 0.3,
        y: 1.5,
        w: 3.2,
        h: 0.8,
        line: 'e7289e',
        color: 'white',
        lineSize: 0.5,
      })

      slideHashtagDetail.addShape(pptx.shapes.RECTANGLE, {
        x: 3.9,
        y: 1.5,
        w: 3.2,
        h: 0.8,
        line: 'e7289e',
        color: 'white',
        lineSize: 0.5,
      })

      slideHashtagDetail.addShape(pptx.shapes.RECTANGLE, {
        x: 7.5,
        y: 1.5,
        w: 3.2,
        h: 0.8,
        line: 'e7289e',
        color: 'white',
        lineSize: 0.5,
      })

      slideHashtagDetail.addText('投稿数', {
        x: 0.4,
        y: 1.5,
        w: 2,
        h: 0.4,
        align: 'left',
        color: '000000',
        fontSize: 9,
      })

      slideHashtagDetail.addText('いいね数', {
        x: 4,
        y: 1.5,
        w: 2,
        h: 0.4,
        align: 'left',
        color: '000000',
        fontSize: 9,
      })

      slideHashtagDetail.addText('コメント数', {
        x: 7.6,
        y: 1.5,
        w: 2,
        h: 0.4,
        align: 'left',
        color: '000000',
        fontSize: 9,
      })

      slideHashtagDetail.addImage({
        x: 3.15,
        y: 1.56,
        w: 0.25,
        h: 0.24,
        path: CAMERA_IMG,
      })

      slideHashtagDetail.addImage({
        x: 6.75,
        y: 1.56,
        w: 0.25,
        h: 0.24,
        path: HEART_IMG,
      })

      slideHashtagDetail.addImage({
        x: 10.3,
        y: 1.56,
        w: 0.25,
        h: 0.24,
        path: COMMENT_IMG,
      })

      slideHashtagDetail.addText(
        hashtag?.summary['posts_count'] ? formatToInteger(hashtag?.summary['posts_count']) : '0',
        {
          x: 2.7,
          y: 1.9,
          w: 0.8,
          h: 0.4,
          align: 'right',
          bold: true,
          color: '000000',
          fontSize: 9,
        }
      )

      slideHashtagDetail.addText(
        hashtag?.summary['likes_count'] ? formatToInteger(hashtag?.summary['likes_count']) : '0',
        {
          x: 6.3,
          y: 1.9,
          w: 0.8,
          h: 0.4,
          align: 'right',
          bold: true,
          color: '000000',
          fontSize: 9,
        }
      )

      slideHashtagDetail.addText(
        hashtag?.summary['comments_count'] ? formatToInteger(hashtag?.summary['comments_count']) : '0',
        {
          x: 9.9,
          y: 1.9,
          w: 0.8,
          h: 0.4,
          align: 'right',
          bold: true,
          color: '000000',
          fontSize: 9,
        }
      )

      let opts = {
        x: 0.3,
        y: 3,
        w: 10.3,
        h: 3,
        barDir: 'col',
        barGrouping: 'clustered',
        catAxisLabelColor: '787878',
        catAxisLabelFontSize: 12,
        catAxisOrientation: 'minMax',
        // showTitle: true,
        title: title,
        titleColor: '787878',
        titleFontSize: 18.6,
        showLabel: true,
        showLegend: true,
        legendPos: 'b',
        legendFontSize: 12,
        legendColor: '787878',
        chartColors: ['C4C4C4', '00BDD7', '3600A8'],
        catAxisLabelFontSize: 9,
        catAxisLabelRotate: -45,
      }

      let chartTypes = [
        {
          type: pptx.charts.BAR,
          data: [
            {
              name: '投稿数',
              labels: labels,
              values: hashtag?.chartData?.posts_count,
            },
            {
              name: 'いいね数',
              labels: labels,
              values: hashtag?.chartData?.likes_count,
            },
            {
              name: 'コメント数',
              labels: labels,
              values: hashtag?.chartData?.comments_count,
            },
          ],
        },
      ]
      slideHashtagDetail.addChart(chartTypes, opts)

      slideHashtagDetail.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.1,
        w: 10.14,
        h: 1.74,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slideHashtagDetail
    },
  },
}
