<template>
    <DrashBoard>
        <LinkInstagram v-if="!showLinkInstagram" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li class="mr-4">
                    <Link :href="route('admin.post-hashtag.index')" class="font-normal breakcumb-active">ストーリーズ分析</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5 active ml-2">ストーリーズ分析</li>
                </ul>
            </div>
        </template>

        <UserNav />
        <template v-slot:manual>
            <UserManual :isShow="isShowManual" :content="arrContent" @closeManualPopup="toogleManual" />
        </template>
        <div class="statistic sm:px-5 md:px-14.5 bg-background min-h-screen max-h-full pb-7">
            <div class="flex items-center md:relative">
                <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                    <p class="text-button_pink font-semibold text-3sm">ストーリーズ分析</p>
                    <div class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer" @click="toogleManual">
                        <img :src="suggestIcon" />
                    </div>
                </div>
            </div>
            <div class="dv-statistical grid md:grid-cols-3 grid-cols-1 sm:gap-1 md:gap-7 mb-[30px]">
                <div class="bg-white rounded h-40 p-5 border border-gray-200 flex flex-col justify-between">
                    <div class="text-link_pink text-base font-bold text-3sm">ストーリーズ投稿数</div>
                    <div class="flex justify-between bottom-5 items-center">
                        <p class="text-2sm">合計</p>
                        <p class="font-bold text-xl text-gray-600">{{ formatToInteger(storiesAnalysis.storiesTotal) }}
                        </p>
                    </div>
                </div>
                <div class="bg-white rounded h-40 p-5 border border-gray-200 flex flex-col justify-between">
                    <div class="text-link_pink text-base font-bold text-3sm">インプレッション数</div>
                    <div class="flex justify-between bottom-14 items-center">
                        <p class="text-2sm">合計</p>
                        <p class="font-bold text-xl text-gray-600">{{ formatToInteger(storiesAnalysis.impressionsTotal)
                        }}</p>
                    </div>
                    <div class="flex justify-between bottom-5 items-center">
                        <p class="text-2sm">投稿平均</p>
                        <p class="font-bold text-xl text-gray-600">{{ formatToFloat(storiesAnalysis.impressionsAvg) }}
                        </p>
                    </div>
                </div>
                <div class="bg-white rounded h-40 p-5 border border-gray-200 flex flex-col justify-between">
                    <div class="text-link_pink text-base font-bold text-3sm">リーチ数</div>
                    <div class="flex justify-between bottom-14 items-center">
                        <p class="text-2sm">合計</p>
                        <p class="font-bold text-xl text-gray-600">{{ formatToInteger(storiesAnalysis.reachTotal) }}</p>
                    </div>
                    <div class="flex justify-between bottom-5 items-center">
                        <p class="text-2sm">投稿平均</p>
                        <p class="font-bold text-xl text-gray-600">{{ formatToFloat(storiesAnalysis.reachAvg) }}</p>
                    </div>
                </div>
            </div>
            <!-- Stories post list -->
            <div class="bg-white p-5 mb-7">
                <h3 class="text-link_pink text-base font-bold text-3sm mb-2">ストーリーズ投稿一覧</h3>
                <div>
                    <div class="flex flex-col md:flex-row">
                        <div class="flex">
                            <el-input type="text" v-model="filter.caption" placeholder="検索キーワードを入力してください"
                                class="w-full md:!w-96" size="large" clearable @change="getStoriesList"
                                @keyup.enter="getStoriesList" />
                            <el-button @click.prevent="getStoriesList" class="el-button__primary w-20 ml-4"
                                size="large">
                                <span>検索</span>
                            </el-button>
                        </div>
                        <div class="sm:pl-0 md:pl-5 sm:mt-2 md:mt-0 md:w-1/2 flex items-center">
                            <span class="text-gray-600 text-2sm">表示件数: {{ stories?.meta?.total || 0 }}件</span>
                        </div>
                    </div>
                </div>
                <data-table :fields="fields" :items="stories.data" :paginate="stories.meta"
                    :current-page="filter.page || 1" :tableRowClassName="'h-[340px]'" disable-table-info footer-center
                    paginate-background @page="handleCurrentPage" class="el-table-header__black mt-5 rou">
                    <!-- chart -->
                    <template #chart="{ row }">
                        <i @click.prevent="getStoryInsights(row.id)"
                            :class="[storyIdSelect === row?.id ? 'text-pink-500 bg-gray-300 rounded-full py-1 px-2.5' : '']"
                            class="fa-solid fa-chart-simple text-xl" />
                    </template>

                    <template #thumbnail_url="{ row }">
                        <el-image :src="row?.thumbnail_url" lazy style="max-height: '150px'" />
                    </template>

                    <template #impressions="{ row }">{{ formatToInteger(row?.impressions) }}</template>
                    <template #reach="{ row }">{{ formatToInteger(row?.reach) }}</template>
                    <template #back_next="{ row }">{{ formatToInteger(row?.back_next) }}</template>

                    <!-- created_time -->
                    <template #created_time="{ row }">
                        <span>{{ formatTime(row?.created_time) }}</span>
                    </template>
                </data-table>
            </div>
            <!-- Stories index transition -->
            <div class="relative bg-white px-5 pb-7 pt-5" id="chart-wrapper">
                <div>
                    <p class="text-link_pink text-base font-bold text-3sm">ストーリーズ指標推移</p>
                    <Chart :chartData="chartData" :chartOptions="chartOptions" />
                </div>
            </div>
        </div>
    </DrashBoard>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import DrashBoard from '@/Layouts/Authenticated.vue'
import DataTable from '@/Components/UI/DataTable.vue'
import { chartOptions, chartData } from './untils/initData'
import UserNav from '@/Components/UserNav.vue'
import Chart from './Chart.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import dayjs from 'dayjs'
import UserManual from '@/Components/UserManual.vue'

export default {
    components: { DrashBoard, DataTable, Link, BreadCrumb, Chart, UserNav, LinkInstagram, UserManual },
    data() {
        return {
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowManual: false,
            arrContent: ['ストーリーズのインプレッション数・リーチなどを確認することができます。', 'ストーリーズやインスタライブは、「ストーリーズ推移表」のグラフ上でリーチ数が多く表示されている時間帯に投稿すると閲覧されやすい傾向にあります。', 'また、ジャンルや業界によってベストタイミングは異なるため、複数の曜日や時間で投稿をテスト的におこなっていくことも良いでしょう。'],
            loading: false,
            showLinkInstagram: true,
            filter: {
                caption: '',
                page: 1,
                limit: 6,
            },
            stories: [],
            fields: [
                { key: 'chart', label: 'Chart', width: 100, align: 'center' },
                { key: 'thumbnail_url', label: 'サムネイル', width: 200, align: 'center' },
                { key: 'caption', label: '投稿内容', width: 250, align: 'center' },
                { key: 'created_time', label: '投稿日時', width: 170, align: 'center' },
                { key: 'impressions', label: 'インプ', width: 170, align: 'center' },
                { key: 'reach', label: 'リーチ', width: 170, align: 'center' },
                { key: 'back_next', label: '戻る・次へ', width: 170, align: 'center' },
                {
                    key: 'browsing_completion_rate',
                    label: '閲覧完了率',
                    width: screen.width / 7 > 200 ? 'full' : 170,
                    align: 'center',
                },
            ],
            isShowChart: false,
            chartData: chartData,
            chartOptions: chartOptions,
            storiesAnalysis: {
                impressionsTotal: 0,
                impressionsAvg: 0,
                reachAvg: 0,
                reachTotal: 0,
                storiesTotal: 0,
            },
            storyIdSelect: '',
            isGetStories: true,
        }
    },
    async created() {
        await this.checkLinkInstagram()
        await this.fetchData()
    },
    methods: {
        toogleManual() {
            this.isShowManual = !this.isShowManual
        },
        handleCurrentPage(value) {
            this.filter.page = value
            this.isGetStories = true
            this.getStoriesList()
        },
        async fetchData() {
            await this.getStoriesAnalysis()
            await this.getStoriesList()
        },
        async getStoriesAnalysis() {
            await axios
                .get(route('api.story-analysis'))
                .then((response) => {
                    this.storiesAnalysis = response?.status == 200 ? { ...response.data } : this.storiesAnalysis
                })
                .catch((e) => { })
        },
        async getStoriesList() {
            await axios
                .get(
                    route('api.story-list', { page: this.filter?.page, caption: this.filter?.caption, limit: this.filter?.limit })
                )
                .then((response) => {
                    this.stories = response?.status == 200 ? response.data : []

                    if (this.stories?.data.length > 0) {
                        this.storyIdSelect = this.stories.data[0].id
                        this.getStoryInsights(this.storyIdSelect)
                    } else {
                        this.storyIdSelect = ''
                        this.chartData.labels = []
                        this.chartData.datasets[0].data = []
                        this.chartData.datasets[1].data = []
                        this.chartData.datasets[2].data = []
                        this.chartData.datasets[3].data = []
                    }
                })
                .catch((e) => { })
        },
        async getStoryInsights(storyId) {
            if (!storyId) return
            this.storyIdSelect = storyId

            await axios
                .get(route('api.story-insights', { story_id: storyId }))
                .then((response) => {
                    let created_time = response.data.map((story) => story.created_time)
                    created_time = created_time.map((item) => item.split('-'))

                    const reach = response.data.map((story) => story.reach)
                    const diff_reach = response.data.map((story) => story.diff_reach)
                    const impressions = response.data.map((story) => story.impressions)
                    const diff_imp = response.data.map((story) => story.diff_imp)

                    // set Y-axis graph max
                    const impressionMax = Number(Math.max(...impressions))
                    const reachMax = Number(Math.max(...reach))
                    let yMaxA = impressionMax > reachMax ? impressionMax : reachMax
                    yMaxA = Math.round(yMaxA / 10 + 1) * 10

                    const diffReachMax = Number(Math.max(...diff_reach))
                    const diffImpMax = Number(Math.max(...diff_imp))
                    let yMaxB = diffReachMax > diffImpMax ? diffReachMax : diffImpMax
                    yMaxB = Math.round(yMaxB / 10 + 1) * 10

                    this.setMaxYAxisGraph(yMaxA, yMaxB)

                    // set data for chart
                    this.chartData.labels = created_time
                    this.chartData.datasets[0].data = reach
                    this.chartData.datasets[1].data = impressions
                    this.chartData.datasets[2].data = diff_reach
                    this.chartData.datasets[3].data = diff_imp

                    this.isShowChart = true

                    if (!this.isGetStories) {
                        this.scrollToChart()
                    }
                    this.isGetStories = false
                })
                .catch((e) => {
                    this.isShowChart = false
                })
        },
        formatTime(date) {
            if (!date) {
                return
            }
            const created_time = new Date(date * 1000)

            let day = created_time.getDate()
            let month = created_time.getMonth() + 1
            let year = created_time.getFullYear()
            let hour = created_time.getHours()
            let minute = (created_time.getMinutes() < 10 ? '0' : '') + created_time.getMinutes()

            return `${year}年${month}月${day}日 ${hour}:${minute}`
        },
        // link instagram
        async checkLinkInstagram() {
            const check = await accountAPI.checkInsAccount()
            if (check.data) {
                this.showLinkInstagram = true
            } else {
                this.showLinkInstagram = false
            }
        },
        scrollToChart() {
            document.getElementById('chart-wrapper').scrollIntoView({
                behavior: 'smooth',
            })
        },
        setMaxYAxisGraph(yMaxA, yMaxB) {
            this.chartOptions.scales.A.max = yMaxA
            this.chartOptions.scales.A.ticks.stepSize = yMaxA / 10
            this.chartOptions.scales.B.max = yMaxB
            this.chartOptions.scales.B.ticks.stepSize = yMaxB / 10
        },
    },
}
</script>

<style lang="scss" scoped>
.el-button__primary {
    background-color: #5264cd;
    border: 1px solid #5264cd;
}

.el-button__primary span {
    color: white !important;
}
</style>
