<template>
  <div class="flex flex-col pt-10 h-full">
    <h2 class="text-title text-button_pink font-semibold pb-12.5 text-center">{{ this.$i18n.t('Preview') }}</h2>
    <div class="bg-ip bg-contain flex mx-auto bg-no-repeat pt-9 px-4 pb-5 flex-col justify-between">
      <div class="header-screen flex items-center justify-between border-b">
        <div class="basis-2/6">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Instagram_logo.svg/1200px-Instagram_logo.svg.png"
            alt=""
          />
        </div>
        <div>
          <i class="fas fa-plus-square"></i>
          <i class="fa-brands fa-facebook-messenger ml-3.5"></i>
        </div>
      </div>
      <div class="post-main py-3 flex-1">
        <div class="user-info flex items-center justify-between">
          <div class="flex items-center">
            <div class="rounded-full inline-flex justify-center items-center avatar w-11 h-11 header__avatar header__avatar--border border-2 border-solid mr-2">
              <span v-if="!profilePictureUrl" class="text-black text-xl">{{ username?.slice(0, 1).toUpperCase() }}</span>
              <img class="w-full h-full rounded-full" v-else :src="profilePictureUrl"/>

              <!-- avatar  default -->
            </div>
            <p class="text-black font-bold ml-2 text-xs">{{ username }}</p>
          </div>
          <i class="fa-solid fa-ellipsis"></i>
        </div>
        <div class="mt-2 w-full">
          <div v-if="(!validate && !isCarousel) || (isCarousel && !arrImage.length)" class="custom-bg-ins">
            <p class="text-black">画像または動画をアップロードしてください</p>
          </div>
          <div class="relative ">
            <img
              ref="imagePreview"
              v-show="fileType != 'video/mp4' && file != '' && validate == true && !isCarousel"
              :src="file.slice(-1)[0]"
              class="mr-2 w-full"
              style="max-height:150px"
            />
            <div class="block carousel-preview" v-if="isCarousel">
              <el-carousel height="200px" class="w-full" v-if="arrImage.length > 0">
                <el-carousel-item v-for="item in arrImage" :key="item">
                  <div v-if="item.type != 'video/mp4'" class="rounded-md border h-40 w-full flex items-center justify-center">
                    <img :src="item.url" alt="" class="w-full h-full"/>
                  </div>
                  <div v-if="item.type == 'video/mp4'" class="flex items-center justify-center">
                    <video
                      id="foo"
                      width="260"
                      class="mr-2"
                      :src="item.url"
                      controls
                      autoplay
                    >
                    </video>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <!-- <span
              :style="{ top: (item.y * 100 ) + '%', left: (item.x * 100) + '%' }"
              v-for="(item, index) in tag"
              :key="index"
              class="
                bg-gray-300
                rounded-xl
                py-2
                leading-none
                px-4
                absolute
                flex
                items-center
                tag-flag
              "
            >
              <span class="mr-4 text-xs whitespace-nowrap">{{ item.username }}</span>
              <svg
                @click="removeHashtag(index)"
                class="inline-block"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.44662 1.17242L8.50661 0.232422L4.77995 3.95909L1.05328 0.232422L0.113281 1.17242L3.83995 4.89909L0.113281 8.62576L1.05328 9.56576L4.77995 5.83909L8.50661 9.56576L9.44662 8.62576L5.71995 4.89909L9.44662 1.17242Z"
                  fill="#5264CD"
                />
              </svg>
            </span> -->
          </div>
          <video
            v-if="fileType == 'video/mp4' && validate == true && !isCarousel"
            width="320"
            height="150"
            :src="file.slice(-1)[0]"
            controls
            autoplay
            style="max-height:150px"
          ></video>
        </div>
        <div class="post-image-option flex justify-between mt-3">
          <div class="flex gap-4 items-center">
            <i class="far fa-heart"></i>
            <i class="far fa-comment-alt"></i>
            <i class="far fa-paper-plane"></i>
          </div>
          <div class="bookmark"><i class="far fa-bookmark"></i></div>
        </div>
        <div>
          <div>
            <span class="text-black font-bold text-xs">{{ username }}</span>
            <span class="text-xs ml-1">{{ content }}</span>
          </div>
          <p class="flex flex-wrap">
            <span v-for="itemHashtag in hashtag" :key="itemHashtag" class="text-xs hashtag px-1">
              {{ itemHashtag }}
            </span>
          </p>
        </div>
      </div>
      <div class="bottom-foot-container">
        <i class="fas fa-home"></i>
        <i class="fas fa-search"></i>
        <i class="fas fa-plus-square"></i>
        <i class="far fa-heart"></i>
        <i class="fas fa-user-circle"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    username: {
      type: String,
    },
    content: {
      type: String,
      default: 'Content is empty',
    },
    tag: {
      default: '@tag',
    },
    hashtag: {
      default: '',
    },
    file: {
      default: null,
    },
    fileType: {
      default: null,
    },
    validate: {
      default: null,
    },
    profilePictureUrl: {
      type: String,
    },
    arrImage: {
      type: Array,
      default:[]
    },
    isCarousel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isVideo: false,
    }
  },
  watch: {
    file: function () {
      if (this.fileType == 'video/mp4') {
        this.isVideo = true
      } else {
        this.isVideo = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-bg-ins {
  display: flex;
  height: 200px;
  background-color: gray;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
}
.bg-ip {
  width: 283px;
  height: 571px;
  background-image: url('@shared/uploads/iPhone-screen.svg');
  filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.25));
}
.bottom-foot-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  border-top: #d8d8d8 1px solid;
  color: #d8d8d8;
  font-size: 3vmin;
  text-align: center;
  user-select: none;
  padding-top: 4px;
  i {
    font-size: 1rem;
    padding: 10px 0;
    color: black;
  }
}
.text-break {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  visibility: visible;
  word-wrap: break-word;
}

.hashtag {
  color: rgba(var(--fe0, 0, 55, 107), 1);
}
.tag-flag {
  &::before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-bottom: 10px solid #d1d5db;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: -5px;
    left: 10px;
    margin: 0 auto;
  }
}
.header__avatar.header__avatar--border {
    border-color: #BF3D8F;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(182, 173, 173); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
</style>
<style>
.post-main
{
  height: 100%;
  overflow-y: scroll ;
   overflow-x: hidden ;
  padding-right: 5px;
}
.carousel-preview .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
}
.carousel-preview .el-carousel__container .el-carousel__arrow
{
  transform: translateY(-30px);
}
.carousel-preview .el-carousel__container img
{
  height: 190px;
}

</style>
