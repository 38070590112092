<template>
 <div class="chart--scroll linechart-contain">
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
  />
 </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Line } from 'vue-chartjs'
import { CategoryScale } from 'chart.js'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement
)

export default {
  name: 'BarChart',
  components: { Bar, Line },
  props: {
    chartId: { type: String, default: 'bar-chart' },
    datasetIdKey: { type: String, default: 'label' },
    width: { type: Number, default: 400 },
    height: { type: Number, default: 400 },
    cssClasses: { default: '', type: String },
    styles: { type: Object, default: () => {} },
    plugins: { type: Object, default: () => {} },
    chartData: { label: [], datasets: [] },
    chartOptions: { type: Object, default: () => {} },
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.linechart-contain #line-chart {
    height: 400px !important;
  }
@media (max-width: 640px) {
  .linechart-contain {
    #line-chart {
      height: 350px !important;
    }
  }
  .chart--scroll {
    padding: 1rem 0 !important;
    width: 100%;
    overflow: scroll;
    & > div {
      width: 800px;
      height: 400px;
    }
  }
}
</style>