import { formatToFloat } from '../../../Plugins/numberFormat'

export default {
  methods: {
    getAverage(total, countPost) {
      if (total && countPost && countPost != 0) {
        const average = total / countPost
        return formatToFloat(average)
      }
      return 0
    },
    genSlideEngagementAnalysis(pptx, postInfo, text, title) {
      let dataInfo = [
        {
          title: 'インプレッション数',
          total: postInfo?.sumImpressions ?? 0,
          avg: this.getAverage(postInfo?.sumImpressions, postInfo?.sumPost),
        },
        {
          title: 'リーチ数',
          total: postInfo?.sumReachs ?? 0,
          avg: this.getAverage(postInfo?.sumReachs, postInfo?.sumPost),
        },
        {
          title: '保存数',
          total: postInfo?.sumSaved ?? 0,
          avg: this.getAverage(postInfo?.sumSaved, postInfo?.sumPost),
        },
        {
          title: '動画再生数',
          total: postInfo?.sumVideoViews ?? 0,
          avg: this.getAverage(postInfo?.sumVideoViews, postInfo?.sumPost),
        },
        {
          title: 'いいね数',
          total: postInfo?.sumLikes ?? 0,
          avg: this.getAverage(postInfo?.sumLikes, postInfo?.sumPost),
        },
        {
          title: 'コメント数',
          total: postInfo?.sumComments ?? 0,
          avg: this.getAverage(postInfo?.sumComments, postInfo?.sumPost),
        },
        {
          title: 'エンゲージメント率',
          sub_title: '投稿平均',
          total: postInfo?.sumEngagementRate ?? 0,
          avg: '',
        },
        {
          title: '投稿数',
          total: postInfo?.sumPost ?? 0,
          avg: '',
        },
      ]

      let slideEngagementAnalysis = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideEngagementAnalysis.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })

      slideEngagementAnalysis.addText(title, {
        x: 2.3,
        y: 0.25,
        w: 8,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 22,
      })

      dataInfo.forEach((item, index) => {
        slideEngagementAnalysis.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
          x: index >= 4 ? (index - 4) * 2.5 + 0.7 : index * 2.5 + 0.7,
          y: index >= 4 ? 3.7 : 1.5,
          w: 2.2,
          h: 1.9,
          rectRadius: 0.05,
          line: '000000',
          color: 'white',
          lineSize: 0.1,
        })

        slideEngagementAnalysis.addText(item.title, {
          x: index >= 4 ? (index - 4) * 2.5 + 0.8 : index * 2.5 + 0.8,
          y: index >= 4 ? 3.7 : 1.5,
          w: 2.2,
          h: 0.7,
          align: 'left',
          color: '000000',
          fontSize: 14,
        })

        slideEngagementAnalysis.addText(item.sub_title ?? '合計', {
          x: index >= 4 ? (index - 4) * 2.5 + 0.8 : index * 2.5 + 0.8,
          y: index >= 4 ? 4.3 : 2.1,
          w: 1,
          h: 0.5,
          align: 'left',
          color: '000000',
          fontSize: 12,
        })

        slideEngagementAnalysis.addText(String(item.total), {
          x: index >= 4 ? (index - 4) * 2.5 + 1.9 : index * 2.5 + 1.9,
          y: index >= 4 ? 4.3 : 2.1,
          w: 1,
          h: 0.5,
          align: 'right',
          bold: true,
          color: '000000',
          fontSize: 12,
        })

        slideEngagementAnalysis.addShape(pptx.shapes.LINE, {
          x: index >= 4 ? (index - 4) * 2.5 + 0.9 : index * 2.5 + 0.9,
          y: index >= 4 ? 4.9 : 2.6,
          w: 1.9,
          h: 0,
          line: { width: 0.5, color: '000000' },
        })

        if (index < 6) {
          slideEngagementAnalysis.addText('投稿平均', {
            x: index >= 4 ? (index - 4) * 2.5 + 0.8 : index * 2.5 + 0.8,
            y: index >= 4 ? 5 : 2.7,
            w: 1,
            h: 0.5,
            align: 'left',
            color: '000000',
            fontSize: 12,
          })

          slideEngagementAnalysis.addText(String(item.avg), {
            x: index >= 4 ? (index - 4) * 2.5 + 1.9 : index * 2.5 + 1.9,
            y: index >= 4 ? 5 : 2.7,
            w: 1,
            h: 0.5,
            align: 'right',
            bold: true,
            color: '000000',
            fontSize: 12,
          })
        }
      })

      slideEngagementAnalysis.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.1,
        w: 10.14,
        h: 1.74,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slideEngagementAnalysis
    },
  },
}
