export default {
  methods: {
    followerChange(item) {
      let lastDaySelected = item?.lastDaySelected?.followers ?? ''
      let lastDayPrevious = item?.lastDayPrevious?.followers ?? ''
      let followers = lastDaySelected - lastDayPrevious
      return followers > 0 ? '+' + followers : followers
    },
    followerChangeAverage(item) {
      let lastDaySelected = item.lastDaySelected?.followers ?? ''
      let lastDayPrevious = item.lastDayPrevious?.followers ?? ''
      let followers = lastDaySelected - lastDayPrevious
      let average = followers / item.interval
      let result = Number.isInteger(average) ? average : average.toFixed(2)
      return result === 0 ? result : result > 0 ? '+' + result : result
    },
    followerIncrease(item) {
      let followers = this.getSubtractFollower(item)
      return followers === 0 ? followers : followers > 0 ? '+' + followers : ''
    },
    followerIncreaseLevel(item) {
      let followers = this.getSubtractFollower(item)
      let average = followers / item.interval
      let result = Number.isInteger(average) ? average : average.toFixed(2)
      return result === 0 ? result : result > 0 ? '+' + result : ''
    },
    followerIncreaseRatio(item) {
      let lastDaySelected = item.lastDaySelected?.followers ?? ''
      let lastDayPrevious = item.lastDayPrevious?.followers ?? ''
      let followers = lastDaySelected - lastDayPrevious
      if (followers < 0 || followers === 0) {
        return followers < 0 ? '' : 0
      } else {
        return followers * 100
      }
    },
    followerFell(item) {
      let followers = this.getSubtractFollower(item)
      return followers === 0 ? followers : followers < 0 ? followers : ''
    },
    followerFellAverage(item) {
      let followers = this.getSubtractFollower(item)
      let average = followers / item.interval
      let result = Number.isInteger(average) ? average : average.toFixed(2)
      return result === 0 ? result : result < 0 ? result : ''
    },
    followerFellRatio(item) {
      let lastDaySelected = item.lastDaySelected?.followers ?? ''
      let lastDayPrevious = item.lastDayPrevious?.followers ?? ''
      let followers = lastDaySelected - lastDayPrevious
      if (followers > 0 || followers === 0) {
        return followers > 0 ? '' : 0
      }
      return followers * 100
    },
    getSubtractFollower(item) {
      let previousDaySelected = item.previousDaySelected?.followers ?? ''
      let lastDaySelected = item.lastDaySelected?.followers ?? ''
      let followers = lastDaySelected - previousDaySelected
      return followers
    },
    formatDataPlus(temp) {
      return temp > 0 ? (Number.isInteger(temp) === true ? '+' + temp + '%' : '+' + temp.toFixed(2) + '%') : ''
    },
    formatDataMinus(temp) {
      return temp < 0 ? (Number.isInteger(temp) === true ? temp + '%' : temp.toFixed(2) + '%') : ''
    },
    profileImpressionsAverage(item) {
      let average = item.calculation?.totalProfileViews / item.interval
      return Number.isInteger(average) ? average : average.toFixed(2)
    },
    detailFollowerFellRatio(item) {
      let average = item.calculation?.totalProfileViews / item.interval
      if (average) {
        let result = (average / item.lastDaySelected?.followers) * 100
        return Number.isInteger(result) ? result + '%' : result.toFixed(2) + '%'
      }
      return 0 + '%'
    },
    clickNumberAverage(item) {
      let average = item.calculation?.totalWebsiteClicks / item.interval
      return Number.isInteger(average) ? average : average.toFixed(2)
    },
    clickNumberRatio(item) {
      let clickNumberAverage = item.calculation?.totalWebsiteClicks / item.interval
      let profileImpressionsAverage = item.calculation?.totalProfileViews / item.interval
      if (clickNumberAverage > 0 && profileImpressionsAverage > 0) {
        let result = (clickNumberAverage / profileImpressionsAverage) * 100
        return Number.isInteger(result) ? result + '%' : result.toFixed(2) + '%'
      }
      return 0 + '%'
    },
  },
}
