<template>
  <DrashBoard>
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li class="mr-4">
          <Link :href="route('admin.plans.index')" class="font-normal breakcumb-active">プラン管理</Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="mt-12 ml-8 tabs">
        <ul class="flex">
          <li class="px-2 relative pb-5 active">プラン管理</li>
        </ul>
      </div>
    </template>
    <Loading v-if="isLoading" />
    <div class="md:px-8 sm:px-4 pt-13 bg-background min-h-screen h-full">
      <div class="flex justify-between items-center">
        <p class="text-3sm text-button_pink font-semibold py-5">プラン管理</p>
        <Link
          v-if="check_role_master"
          :href="route('admin.plans.create')"
          as="button"
          class="bg-button_profile text-white py-2 px-4 rounded button-shadow mx-1 text-3sm"
        >
          プランを作成
        </Link>
      </div>

      <!-- table -->
      <div class="bg-white p-2">
        <data-table :fields="fields" :items="tableData" disable-table-info footer-center size = "small" class="el-table-header__black">
            <template #name="{ row }">
              <div class="px-5.5 text-center whitespace-nowrap overflow-hidden text-ellipsis">
                    {{ row?.name }}
              </div>
            </template>
            <template #amount="{ row }">
              <div class="py-1 text-center">
                    {{ row?.amount == 0 ? '無料' : row?.amount.toLocaleString() + '円' }}
              </div>
            </template>
            <template #number_accounts="{ row }">
              <div class="py-1 text-center">
                {{ row?.number_accounts.toLocaleString() }}
              </div>
            </template>
            <template #storage_time="{ row }">
              <div class="py-1 text-center" v-if="row?.storage_time == 0">無制限</div>
              <div class="py-1 text-center" v-if="row?.storage_time != 0">{{ row?.storage_time }}日</div>
            </template>
            <template #value_cycle_months="{ row }">
              <div v-if="row?.cycle_months == 0" class="py-1 text-center">無制限</div>
              <div v-else class="py-1 text-center">
                {{ row?.value_cycle_months }}月
              </div>
            </template>
            <template #value_renewal_cycle_months="{ row }">
              <div v-if="row?.cycle_months == 0" class="py-1 text-center">0月</div>
              <div v-else class="py-1 text-center">
                {{ row?.value_renewal_cycle_months }}月
              </div>
            </template>
            <template #account_analysis="{ row }">
                <el-image v-if="row?.account_analysis == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
            </template>
            <template #post_analysis="{ row }">
                <el-image v-if="row?.post_analysis == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
            </template>
            <template #co_opetition_analysis="{ row }">
              <div class="py-1 flex items-center justify-center">
                <el-image v-if="row?.co_opetition_analysis == 1" src="/images/check-yes.svg"></el-image>
                  <el-image v-else src="/images/check-no.svg"></el-image>
                  <span v-if="row?.number_account_competition > 0" class="ml-2">
                    競合 {{ row?.number_account_competition.toLocaleString() }}アカウントまで
                  </span>
              </div>
            </template>
            <template #report_pptx="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.report_pptx == 1" src="/images/check-yes.svg"></el-image>
                  <span v-if="row?.number_report_pptx > 0 && row?.report_pptx == 1" class="ml-2">
                    毎月 {{ row?.number_report_pptx.toLocaleString() }} 回まで出力出来ます。
                  </span>
                  <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #advertising_analysis="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.advertising_analysis == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #report_csv="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.report_csv == 1" src="/images/check-yes.svg"></el-image>
                  <span v-if="row?.number_report_csv > 0 && row?.report_csv == 1" class="ml-2">
                    毎月 {{ row?.number_report_csv.toLocaleString() }} 回まで出力出来ます。
                  </span>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #report_pdf="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.report_pdf == 1" src="/images/check-yes.svg"></el-image>
                  <span v-if="row?.number_report_pdf > 0 && row?.report_pdf == 1" class="ml-2">
                    毎月 {{ row?.number_report_pdf.toLocaleString() }} 回まで出力出来ます。
                  </span>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #user_analysis="{ row }">
              <div class="py-1 text-center">
                <el-image v-if="row?.user_analysis == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #report_analysis="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.report_analysis == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #business_analysis="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.business_analysis == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #story_analysis="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.story_analysis == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #hashtag_analysis="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.hashtag_analysis == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #creative_analysis="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.creative_analysis == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #reserved_post_function="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.reserved_post_function == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #comment_analysis="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.comment_analysis == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #reel_analysis="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.reel_analysis == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #initial_velocity_analysis="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.initial_velocity_analysis == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #post_hashtag="{ row }">
              <div class="py-1 flex justify-center">
                <el-image v-if="row?.post_hashtag == 1" src="/images/check-yes.svg"></el-image>
                <el-image v-else src="/images/check-no.svg"></el-image>
              </div>
            </template>
            <template #actions="{ row }">
              <div class="flex justify-center py-1" v-if="check_role_master">
                  <Link
                    @click="isLoading = false"
                    :href="'/plans/'+row?.id+'/edit'"
                    as="button"
                    class="bg-button_profile text-white py-2 px-4 rounded button-shadow mx-1"
                  >
                    更新
                  </Link>
                  <button
                    :disabled="row?.trial == 1 || row?.check_user_join_plan > 0 ? 'disabled' : false"
                    @click="showPopupDeletePlan(row?.id)"
                    class="bg-button_table text-white py-2 rounded px-4 button-shadow mx-1"
                    :class="row?.trial == 1 ? 'opacity-60' : 'opacity-100'"
                  >
                    削除
                  </button>
                </div>
            </template>
          </data-table>

      </div>
      <div class="pt-4 pb-9 flex justify-center">
        <el-pagination
          :page-size="Number(paginate.per_page)"
          :pager-count="5"
          layout="prev, pager, next"
          :total="Number(paginate.total)"
          :current-page="currentPage || 1"
          @current-change="handleCurrentChange"
          class="is-background"
        />
      </div>
    </div>
  </DrashBoard>
  <PopUp
    :textWarning="'このプランを削除しても宜しいですか。'"
    :active="show_popup_delete_plan"
    @updateStatusPopup="show_popup_delete_plan = $event"
    @deletePlan="deletePlan"
    class="transition-all"
  />
</template>

<script>
import DataTable from '@/Components/UI/DataTable.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import PopUp from '@/Components/PopUp.vue'
import Panigation from '@/Components/Pagination.vue'
import { Link } from '@inertiajs/inertia-vue3'
import Table from '@/Components/Table.vue'
import Loading from '@/Components/Loadding'
import axios from 'axios'
import BreadCrumb from '@/Components/Breadcrumb.vue'
export default {
  components: {
    Table,
    Link,
    PopUp,
    Panigation,
    DataTable,
    DrashBoard,
    Loading,
    BreadCrumb,
  },
  data() {
    return {
      fields : [
        { width: 146, key: 'name',  label: 'プラン名' , fixed: 'left',  align: 'center' },
        { width: 85, key: 'amount', label: '料金' , align: 'center' },
        { width: 160, key: 'number_accounts', label: '連携可能アカウント数' ,   align: 'center'},
        { width: 137, key: 'storage_time', label: 'データ保有期間' ,  align: 'center' },
        { width: 150, key: 'value_cycle_months', label: this.$t('cycle_months') ,align: 'center' },
        { width: 150, key: 'value_renewal_cycle_months', label: this.$t('renewal_cycle_months') ,align: 'center' },
        { width: 150, key: 'account_analysis', label: 'アカウント分析' ,  align: 'center' },
        { width: 79, key: 'post_analysis', label: '投稿分析 ' , align: 'center' },
        { width: 250, key: 'co_opetition_analysis', label: '競合分析' , align: 'center' },
        { width: 250, key: 'report_pptx', label: 'レポート作成 (PowerPoint)' ,align: 'center' },
        { width: 250, key: 'report_csv', label: 'レポート作成 (CSV)' ,align: 'center'},
        { width: 250, key: 'report_pdf', label: 'レポート作成 (PDF)' ,align: 'center'},
        { width: 100, key: 'advertising_analysis', label: '広告分析' ,align: 'center' },
        { width: 168, key: 'user_analysis', label: '時間/曜日別分析' ,align: 'center' },
        { width: 168, key: 'business_analysis', label: 'エンタープライズ機能' ,align: 'center' },
        { width: 200, key: 'story_analysis', label: 'ストーリーズ分析' ,align: 'center'},
        { width: 200, key: 'hashtag_analysis', label: '指定ハッシュタグ分析' ,align: 'center' },
        { width: 150, key: 'creative_analysis', label: this.$t('creative_analysis') ,align: 'center' },
        { width: 150, key: 'reserved_post_function', label: this.$t('reserved_post_function') ,align: 'center' },
        { width: 150, key: 'comment_analysis', label: this.$t('comment_analysis') ,align: 'center' },
        { width: 150, key: 'reel_analysis', label: this.$t('reel_analysis') ,align: 'center' },
        { width: 150, key: 'initial_velocity_analysis', label: this.$t('initial_velocity_analysis') ,align: 'center' },
        { width: 150, key: 'post_hashtag', label: this.$t('post_hashtag') ,align: 'center' },
      ],
      paginate: {},
      show_popup_delete_plan: false,
      currentPage: 1,
      plan_delete_id: null,
      isLoading: false,
      check_role_master: this.hasRole('master'),
    }
  },

  created() {
    this.check_role_master
        ? this.fields = [...this.fields, { width: 216, key: 'actions', label: '操作' ,align: 'center' }]
        : ''
    this.fetchDataPlan()
  },

  methods: {
    async fetchDataPlan(page = null) {
      this.isLoading = true
      await axios
        .get('/api/plans?page=' + (page ? page : 1))
        .then((res) => {
          this.tableData = res.data.data;
          this.paginate = res?.data?.meta
          this.isLoading = false
        })
        .catch((err) => {
          console.error(err)
          this.isLoading = false
        })
    },
    handleCurrentChange(value) {
      this.currentPage = value
    },
    showPopupDeletePlan(id) {
      this.isLoading = true
      this.show_popup_delete_plan = true
      this.plan_delete_id = id
      this.isLoading = false
    },

    closePopupDeletePlan() {
      this.show_popup_delete_plan = false
      this.plan_delete_id = null
    },

    async deletePlan() {
      this.isLoading = true
      axios
        .delete('/api/plans/' + this.plan_delete_id)
        .then((res) => {
          this.closePopupDeletePlan()
          this.fetchDataPlan(this.currentPage)
          this.$toast.success(this.$t('Delete plan successfully'))
          this.isLoading = false
          this.$inertia.visit(route('admin.plans.index'))
        })
        .catch((err) => {
          this.$toast.error(this.$t('Delete plan error : Has may be used to be used to current plan to'))
          this.closePopupDeletePlan()
          this.isLoading = false
        })
    },
  },

  watch: {
    currentPage() {
      this.fetchDataPlan(this.currentPage)
    },
  },
}
</script>
<style lang="scss" scoped>
.el-table th.el-table__cell>.cell
{
  text-align: center;
}
</style>
<style lang="scss">
.scroll-bar__customize {
  background: #fff;
  border-bottom: solid 1px #ebeef5;
  .bg-body_table tr td {
    border: 0;
    border-top: solid 1px #ebeef5;
    border-left: solid 1px #ebeef5;
  }
  background: #fff;
  ::-webkit-scrollbar {
    height: 6px;
    right: 2px;
    bottom: 2px;
    z-index: 1;
    cursor: pointer;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e0e0e4;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b6b6be;
  }
}
</style>
