function VideoFrame(videoId) {
    return new Promise((resolve) => {
        try {
            let video = document.getElementById(videoId);
            var lastSeenTime = -1;
            var durationCumul = 0;
            var frameList = [];
            var indexKey = 0;
            var guessCount = 0;

            const calculatorFrameRate = (_) => {
                var currentVideoTime = 1000 * video.currentTime;
                var dt = currentVideoTime - lastSeenTime;
                dt = Math.ceil(dt * 10) / 10;
                lastSeenTime = currentVideoTime;
                if (dt == 0) return;
                if (guessCount++ == 0) return;

                durationCumul += dt;
                frameList.push(durationCumul / guessCount);
            };

            const interval = setInterval((_) => {
                if (indexKey++ === 60) {
                    clearInterval(interval);
                    resolve(
                        (
                            frameList.reduce(
                                (total, val) => total + val || 0,
                                0
                            ) / frameList.length
                        ).toFixed(2)
                    );
                }
                calculatorFrameRate();
            }, 10);
        } catch (error) {
            console.log(error);
            resolve(0);
        }
    });
}

const getVideoInfo = (file, videoId = "foo") => {
    return new Promise((resolve) => {
        try {
            var video = document.createElement("video");
            video.src = URL.createObjectURL(file);
            video.preload = "metadata";

            //filename: file.name
            video.onloadedmetadata = async function () {
                window.URL.revokeObjectURL(video.src);

                resolve({
                    duration: video.duration,
                    size: (file.size / (1024 * 1024)).toFixed(2),
                    frameRate: await VideoFrame(videoId),
                });
            };
        } catch (error) {
            resolve(null);
        }
    });
};

export { getVideoInfo };

// Instagram
// • •
// Video codec: H264
// • •
// Playback time: Within 3 to 60 seconds
// • •
// File size: 100MB or less
// • •
// Screen size: [1920 * 1080px] or [1080 * 1920px] or less
// • •
// Extension: mp4
// • •
// Frame rate: 23-60fps or less
