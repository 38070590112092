<template>
    <dashboard>
        <Loading v-if="isLoading" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li v-if="check_role_admin">
                    <Link :href="route('admin.users.index', { set_old_params: true })" class="font-normal">管理画面</Link>
                </li>
                <span v-if="check_role_admin" class="font-normal text-notice_date mx-2">></span>
                <li>
                    <Link :href="route('admin.users_infor')" class="font-normal breakcumb-active">アカウント情報</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li @click="toggleTabs(1)" class="sm:px-3 md:px-6 relative pb-5 active">アカウント管理</li>
                    <li @click="toggleTabs(2)" class="sm:px-3 md:px-6 relative pb-5">決済方法・プラン管理</li>
                    <li v-if="checkHidden()" @click="toggleTabs(3)" class="sm:px-3 md:px-6 relative pb-5">ご利用明細</li>
                </ul>
            </div>
        </template>
        <div class="py-5">
            <UserInformation :userSelected="user" />
        </div>
        <form class="lg:px-20 md:px-10 sm:px-5 mt-8 bg-background" v-if="user.parent_user">
            <div class="grid grid-cols-1 gap-4 pb-8 border-b border-[#D5D2D3]">
                <div class="form-group align-items items-center grid grid-cols-payment">
                    <label class="">親ユーザ会社名</label>
                    <input type="text" class="py-3 border border-solid border-input rounded disable-color"
                        :value="user.parent_user.company" disabled />
                </div>
                <div class="form-group align-items items-center grid grid-cols-payment">
                    <label class="">親アカウントプラン</label>
                    <input type="text" class="py-3 border border-solid border-input rounded disable-color"
                        :value="user.parent_user.plan" disabled />
                </div>
                <div class="form-group align-items items-center grid grid-cols-payment">
                    <label class="">親アカウント権限</label>
                    <input type="text" class="py-3 border border-solid border-input rounded disable-color"
                        v-model="user.parent_user.role" disabled />
                </div>
            </div>
        </form>

        <form @submit.prevent="confirmUpdate()" class="lg:px-20 sm:px-5 md:mt-8 sm:mt-0 bg-background">
            <!-- desktop -->
            <div
                class="show--desktop lg:grid form-user--info sm:hidden grid-cols-2 lg:gap-40 md:gap-5 pt-8 pb-4 lg:px-0 md:px-5">
                <div class="form-left">
                    <!-- upload avatar -->
                    <div class="form-group align-items h-24 mb-4 grid grid-cols-4 items-center">
                        <div>
                            <p class="md:text-2sm sm:text-sm text-label col-span-1 break-all text-black">プロフィール画像</p>
                            <p class="md:text-2sm sm:text-sm text-label col-span-1 break-all text-black">アップロード</p>
                        </div>

                        <div v-show="urlAvatar == null" class="col-span-3">
                            <button @click="fileUploadAvatar()" type="button"
                                class="text-white bg-button_profile px-8 py-2 text-xs rounded text-btn-submit">
                                アップロード
                            </button>
                            <input type="file" hidden ref="fileUploadAvatar" @input="onSelectFileAvatar"
                                accept="image/jpeg, image/png, image/jpg, image/gif, image/svg" />
                            <div v-if="form.errors.avatar_url" class="text-left text-red-600 mt-2 w-96">
                                {{ form.errors.avatar_url }}
                            </div>
                        </div>
                        <div v-show="urlAvatar != null" class="flex items-center col-span-3">
                            <img :src="urlAvatar" alt="Logo"
                                class="object-cover w-24 h-24 rounded-full border-solid border-2 border-pink" />
                            <div class="flex-1">
                                <p class="px-5 custom-file">{{ nameFileAvatar }}</p>
                                <p class="px-5 text-button_pink underline cursor-pointer whitespace-nowrap"
                                    @click="fileUploadAvatar()">
                                    画像を変更する
                                </p>
                                <div v-if="form.errors.avatar_url" class="text-left text-red-600 mt-2 w-96">
                                    {{ form.errors.avatar_url }}
                                </div>
                            </div>
                        </div>


                    </div>
                    <!-- end upload avatar -->
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label>
                            企業名
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="col-span-3">
                            <input type="text" class="py-3 border border-solid border-input rounded w-full"
                                v-model="form.company" />
                            <div v-if="form.errors.company" class="text-left text-red-600 mt-2 w-96">
                                {{ form.errors.company }}
                            </div>
                        </div>
                    </div>

                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="text-label">
                            メールアドレス
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="col-span-3">
                            <input type="text" class="py-3 border border-solid border-input rounded w-full"
                                v-model="form.email" />
                            <div v-if="form.errors.email" class="text-left text-red-600 mt-2 w-80">
                                {{ form.errors.email }}
                            </div>
                        </div>
                    </div>

                    <!-- zipcode -->
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <div class="zip_code">
                            <label class="text-black col-span-1 block">郵便番号</label>
                            <span class="text-xs block mt-3 custom-current-size-des">(ハイフンなし)</span>
                        </div>
                        <input type="text" class="col-span-3 py-3 border border-solid border-input rounded"
                            v-model="form.zip_code" maxlength="7" @change="checkZipCode(form.zip_code)"
                            placeholder="郵便番号を入力" />
                    </div>

                    <!-- address 2-->
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">市区町村</label>
                        <input type="text" class="col-span-3 py-3 border border-solid border-input rounded"
                            v-model="form.address_2" placeholder="市区町村を入力" />
                    </div>

                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">ID</label>
                        <input type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="user.id" disabled />
                    </div>

                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">利用プラン</label>
                        <input type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="user.plan?.name" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">利用開始日</label>
                        <input v-if="['未決済', 'パスワード未設定'].includes(user?.status)"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color" disabled />
                        <input v-else type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="formatDate(user.started_at)" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center"
                        v-if="user.contract_url !== null">
                        <label class="">契約書</label>
                        <a :href="user.contract_url" target="_blank" class="col-span-3 py-3 underline text-blue-500">
                            契約書の詳細を表示する
                        </a>
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center"
                        v-if="['admin_agency', 'admin_oem'].includes(this.user.role_name)">
                        <label class="text-2sm">
                            通知設定
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="col-span-3 flex flex-wrap items-center">
                            <div class="flex md:w-1/2 sm:w-full items-center my-2">
                                <input v-model="form.type_register" type="radio" name="typeRegister" value="0"
                                    class="mr-2 border-input text-pink-400" />
                                <p class="px-3 w-full overflow-x-hidden text-2sm">配下のユーザーに通知する</p>
                            </div>
                            <div class="flex md:w-1/2 sm:w-full items-center my-2">
                                <input v-model="form.type_register" type="radio" name="typeRegister" value="1"
                                    class="mr-2 border-input text-pink-400" />
                                <p class="px-3 w-full overflow-x-hidden text-2sm">配下のユーザーに通知しない</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-right">

                    <!-- upload logo -->
                    <div v-if="userLogin === 'master' && user?.role_name?.split('_')[0] !== 'user'"
                        class="form-group align-items h-24 mb-4 grid grid-cols-4 items-center">
                        <label class="md:text-2sm sm:text-sm text-label col-span-1">ロゴアップロード</label>
                        <div class="w-full col-span-3">
                            <div class="pt-2 w-full">
                                <button v-if="urlLogo == null" @click="fileUploadLogo()" type="button"
                                    class="text-white bg-button_profile px-8 py-2 text-xs rounded text-btn-submit">
                                    アップロード
                                </button>
                                <div v-else class="flex items-center">
                                    <img :src="urlLogo" alt="Logo" class="w-44 h-auto" />
                                    <div class="flex-1">
                                        <p class="px-5 custom-file">{{ nameFileLogo }}</p>
                                        <p class="px-5 text-button_pink underline cursor-pointer whitespace-nowrap"
                                            @click="fileUploadLogo()">
                                            ロゴを変更する
                                        </p>
                                        <div v-if="form.errors.logo_url" class="text-left text-red-600 mt-2 w-96">
                                            {{ form.errors.logo_url }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-left text-red-600 text-sm mt-2">{{ form.errors.logo_url ?? null }}
                            </div>
                            <UploadImage field="logo_url" @crop-success="cropLogoSuccess"
                                @crop-upload-success="cropUploadSuccess" @crop-upload-fail="cropUploadFail"
                                v-model="showCropLogo" :width="195" :height="40" langType="ja" img-format="png">
                            </UploadImage>
                        </div>
                    </div>
                    <div v-else class="h-24 mb-4">
                    </div>
                    <!-- end upload logo -->

                    <div class="h-[66px]">

                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <div class="label">
                            <label class="grow text-black block">パスワード</label>
                        </div>
                        <div class="col-span-3">
                            <input class="col-span-3 py-3 border border-solid border-input rounded disable-color w-full"
                                v-model="form.password" :type="typePassword" />
                            <div v-if="form.errors.password" class="text-left text-red-600 mt-2 w-auto">
                                {{ form.errors.password }}
                            </div>
                        </div>
                    </div>

                    <!-- address_1 -->
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">都道府県</label>
                        <input type="text" class="col-span-3 py-3 border border-solid border-input rounded"
                            v-model="form.address_1" placeholder="都道府県を入力" />
                    </div>

                    <!-- address -->
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">住所</label>
                        <input type="text" class="col-span-3 py-3 border border-solid border-input rounded"
                            v-model="form.address" placeholder="住所を入力" />
                    </div>

                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">権限</label>
                        <el-select v-if="this.$page.props.auth.user.roles[0].name == 'master' && user.role === '直ユーザー'"
                            v-model="form.role" class="col-span-3 role-select" @change="fetchDataParentUser">
                            <el-option v-for=" item in roles" :key="item.value" :label="item.name" :value="item.label"
                                class="text-2sm" />
                        </el-select>
                        <input v-else type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="user.role" disabled />
                    </div>

                    <div v-if="(form.role === 'user_oem' || form.role === 'user_agency' || form.role === 'user_enterprise') && user.role === '直ユーザー'"
                        class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="text-black col-span-1 text-2sm">
                            親アカウント
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="col-span-3">
                            <el-select class="w-full rounded account-select"
                                :class="form.errors.parent_user_id ? 'border border-red-600' : ''"
                                v-model="form.parent_user.id" size="large">
                                <el-option v-for="item in parent_users" :key="item.id" :label="item.text"
                                    :value="item.id" @change="form.errors.parent_user_id = ''" />
                            </el-select>
                            <div class="text-left text-red-600 text-nomal" v-if="form.errors.parent_user_id">
                                {{ form.errors.parent_user_id }}
                            </div>
                        </div>
                    </div>

                    <div v-if="!checkHidden" class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">決済タイプ</label>
                        <input type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="user.payment" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">月額金額（税込）</label>
                        <input type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color" :value="
                                user?.amount_plan_crawl
                                    ? getAmountPlanCrawl(user.amount_plan_crawl).toLocaleString('ja-JP', {
                                        style: 'currency',
                                        currency: 'JPY',
                                    })
                                    : user.plan?.amount_after_tax
                                        ? user.plan.amount_after_tax.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
                                        : '¥0'
                            " disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">契約終了日</label>
                        <input v-if="['未決済', 'パスワード未設定'].includes(user?.status)"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color" disabled />
                        <input v-else type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="formatDate(user.ended_at)" disabled />
                    </div>

                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">自動更新回数</label>
                        <input type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="user.count_update" disabled />
                    </div>
                </div>
            </div>
            <!-- mobile -->
            <div
                class="mobile--show form-user--info lg:hidden sm:grid grid-cols-2 lg:gap-40 md:gap-5 py-8 lg:px-0 md:px-5">
                <div class="form-left form-mobile">
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label>
                            企業名
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="col-span-3">
                            <input type="text" class="py-3 border border-solid border-input rounded w-full"
                                v-model="form.company" />
                            <div v-if="form.errors.company" class="text-left text-red-600 mt-2 w-96">
                                {{ form.errors.company }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group align-items h-24 mb-4 grid grid-cols-4 items-center">
                        <label class="md:text-2sm sm:text-sm text-label">プロフィール画像アップロード</label>

                        <div v-show="urlAvatar == null" class="col-span-3">
                            <button @click="fileUploadAvatar()" type="button"
                                class="text-white bg-button_profile px-8 py-2 text-xs rounded">
                                アップロード
                            </button>
                            <input type="file" hidden ref="fileUploadAvatar" @input="onSelectFileAvatar"
                                accept="image/jpeg, image/png, image/jpg, image/gif, image/svg" />
                            <div v-if="form.errors.avatar_url" class="text-left text-red-600 mt-2 w-96">
                                {{ form.errors.avatar_url }}
                            </div>
                        </div>
                        <div v-show="urlAvatar != null" class="flex items-center">
                            <img :src="urlAvatar" alt="Logo"
                                class="object-cover w-24 h-24 rounded-full border-solid border-2 border-pink" />
                            <div class="flex-1">
                                <p class="px-5 custom-file">{{ nameFileAvatar }}</p>
                                <p class="px-5 text-button_pink underline cursor-pointer whitespace-nowrap"
                                    @click="fileUploadAvatar()">
                                    ロゴを変更する
                                </p>
                                <div v-if="form.errors.avatar_url" class="text-left text-red-600 mt-2 w-96">
                                    {{ form.errors.avatar_url }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- upload logo -->
                    <div v-if="userLogin === 'master' && user?.role_name?.split('_')[0] !== 'user'"
                        class="form-group align-items h-24 mb-4 grid grid-cols-4 items-center">
                        <label class="md:text-2sm sm:text-sm text-label col-span-1">ロゴアップロード</label>
                        <div class="w-full col-span-3">
                            <div class="pt-2 w-full">
                                <button v-if="urlLogo == null" @click="fileUploadLogo()" type="button"
                                    class="text-white bg-button_profile px-8 py-2 text-xs rounded text-btn-submit">
                                    アップロード
                                </button>
                                <div v-else class="flex items-center">
                                    <img :src="urlLogo" alt="Logo" class="w-44 h-auto" />
                                    <div class="flex-1">
                                        <p class="px-5 custom-file">{{ nameFileLogo }}</p>
                                        <p class="px-5 text-button_pink underline cursor-pointer whitespace-nowrap"
                                            @click="fileUploadLogo()">
                                            ロゴを変更する
                                        </p>
                                        <div v-if="form.errors.logo_url" class="text-left text-red-600 mt-2 w-96">
                                            {{ form.errors.logo_url }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-left text-red-600 text-sm mt-2">{{ form.errors.logo_url ?? null }}
                            </div>
                            <UploadImage field="logo_url" @crop-success="cropLogoSuccess"
                                @crop-upload-success="cropUploadSuccess" @crop-upload-fail="cropUploadFail"
                                v-model="showCropLogo" :width="195" :height="40" langType="ja" img-format="png">
                            </UploadImage>
                        </div>
                    </div>
                    <!-- end upload logo -->
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="text-label">
                            メールアドレス
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="col-span-3">
                            <input type="text" class="py-3 border border-solid border-input rounded w-full"
                                v-model="form.email" />
                            <div v-if="form.errors.email" class="text-left text-red-600 mt-2 w-80">
                                {{ form.errors.email }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <div class="label">
                            <label class="text-black block">パスワード</label>
                            <!--              <span class="text-black text-sm">英数字8文字以上</span>-->
                        </div>
                        <div class="col-span-3">
                            <input class="col-span-3 py-3 border border-solid border-input rounded disable-color w-full"
                                v-model="form.password" :type="typePassword" />
                            <div v-if="form.errors.password" class="text-left text-red-600 mt-2 w-auto">
                                {{ form.errors.password }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <div class="zip_code">
                            <label class="text-black col-span-1 block">郵便番号</label>
                            <span class="text-xs block mt-1">(ハイフンなし)</span>
                        </div>
                        <input type="text" class="col-span-3 py-3 border border-solid border-input rounded"
                            v-model="form.zip_code" maxlength="7" @change="checkZipCode(form.zip_code)"
                            placeholder="郵便番号を入力" />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">都道府県</label>
                        <input type="text" class="col-span-3 py-3 border border-solid border-input rounded"
                            v-model="form.address_1" placeholder="都道府県を入力" />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">市区町村</label>
                        <input type="text" class="col-span-3 py-3 border border-solid border-input rounded"
                            v-model="form.address_2" placeholder="市区町村を入力" />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">住所</label>
                        <input type="text" class="col-span-3 py-3 border border-solid border-input rounded"
                            v-model="form.address" placeholder="住所を入力" />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">ID</label>
                        <input type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="user.id" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">権限</label>
                        <input type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="user.role" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">利用プラン</label>
                        <input type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="user.plan?.name" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">決済タイプ</label>
                        <input type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="user.payment" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">月額金額（税込）</label>
                        <input type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color" :value="
                                user?.amount_plan_crawl
                                    ? getAmountPlanCrawl(user.amount_plan_crawl).toLocaleString('ja-JP', {
                                        style: 'currency',
                                        currency: 'JPY',
                                    })
                                    : user.plan?.amount_after_tax
                                        ? user.plan.amount_after_tax.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
                                        : '¥0'
                            " disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">利用開始日</label>
                        <input type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="formatDate(user.started_at)" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">契約終了日</label>
                        <input type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="formatDate(user.ended_at)" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center"
                        v-if="user.contract_url !== null">
                        <label class="">契約書</label>
                        <a :href="user.contract_url" target="_blank" class="col-span-3 py-3 underline text-blue-500">
                            契約書の詳細を表示する
                        </a>
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                        <label class="">自動更新回数</label>
                        <input type="text"
                            class="col-span-3 py-3 border border-solid border-input rounded disable-color"
                            :value="user.count_update" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-4 items-center"
                        v-if="['admin_agency', 'admin_oem'].includes(this.user.role_name)">
                        <label class="text-2sm">
                            通知設定
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="col-span-3 flex flex-wrap items-center">
                            <div class="flex md:w-1/2 sm:w-full items-center my-2">
                                <input v-model="form.type_register" type="radio" name="typeRegister-mobile" value="0"
                                    class="mr-2 border-input text-pink-400 !w-5" />
                                <p class="px-3 w-full overflow-x-hidden text-2sm">配下のユーザーに通知する</p>
                            </div>
                            <div class="flex md:w-1/2 sm:w-full items-center my-2">
                                <input v-model="form.type_register" type="radio" name="typeRegister-mobile" value="1"
                                    class="mr-2 border-input text-pink-400 !w-5" />
                                <p class="px-3 w-full overflow-x-hidden text-2sm">配下のユーザーに通知しない</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex items-center md:justify-center sm:justify-between w-full mt-5 pb-14 lg:gap-8 md:gap-4 sm:gap-2">
                <button type="button"
                    class="bg-pink-400 text-white w-72 lg:px-10 md:px-5 sm:px-5 py-4 rounded-sm text-base button-shadow whitespace-nowrap"
                    @click="goBack()">
                    戻る
                </button>
                <button :disabled="isDisabled" type="submit"
                    class="bg-button_profile text-white w-72 lg:px-10 md:px-5 sm:px-5 py-4 rounded-sm text-base button-shadow whitespace-nowrap">
                    アカウント情報を更新する
                </button>
            </div>
        </form>

        <PopUp :active="show_popup_delete_user" @updateStatusPopup="show_popup_delete_user = $event"
            @handleUserDelete="handleUserDelete" class="transition-all" />

        <PopupIns :data="businessAccount" v-model="isShow" :user-id="user.id"
            @onSubmitSuccess="$emit('onInstaSubmitSuccess')" />
    </dashboard>
</template>

<script>
import Dashboard from '../../Dashboard.vue'
import PopUp from '@/Components/PopUpUser.vue'
import UserInformation from '@/Components/UserInformation.vue'
import Notifications from '@/Components/Notifications.vue'
import Ins from '@/Services/Ins'
import { Link } from '@inertiajs/inertia-vue3'
import PopupIns from '@/Components/PopupIns.vue'
import Loading from '@/Components/Loadding'
import { getDataZipCode } from '@/Api/Users'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import UploadImage from 'vue-image-crop-upload'
import { getListRole } from '@/Api/Role'
import { ElMessageBox } from 'element-plus'


export default {
    props: {
        user: {
            type: Object,
            required: true,
        },

        toggleTabs: {
            type: Function,
            required: true,
        },

        fetchDataUser: {
            type: Function,
            require: true,
        },
    },
    components: {
        Dashboard,
        UserInformation,
        Link,
        Notifications,
        PopUp,
        PopupIns,
        BreadCrumb,
        Loading,
        UploadImage
    },
    data() {
        return {
            urlAvatar: null,
            userLogin: null,
            nameFileAvatar: null,
            show_popup_delete_user: false,
            showCropLogo: false,
            isShow: false,
            businessAccount: [],
            account: {},
            form: this.$inertia.form({
                company: this.user.company,
                avatar_url: this.user.avatar_image,
                logo_url: this.user.image_logo,
                password: '',
                role: '',
                email: this.user.email,
                zip_code: this.user.zip_code,
                address: this.user.address,
                address_1: this.user.address_1,
                address_2: this.user.address_2,
                parent_user: {
                    id: null,
                    text: null,
                },
                parent_user_id: null,
                type_register: this.user.is_send_mail,
                _method: 'PUT',
            }),
            parent_users: [],
            isLoading: false,
            checkNewValue: true,
            typePassword: 'text',
            check_role_admin: false,
            oldEmail: '',
            hiddenPopupConfirm: true,
            roles: [
                {
                    "name": "代理店管理者",
                    "label": "admin_agency"
                },
                {
                    "name": "OEM管理",
                    "label": "admin_oem"
                },
                {
                    "name": "エンタープライズ管理",
                    "label": "admin_enterprise"
                },
                {
                    "name": "代理店ユーザー",
                    "label": "user_agency"
                },
                {
                    "name": "OEMユーザー",
                    "label": "user_oem"
                },
                {
                    "name": "エンタープライズユーザー",
                    "label": "user_enterprise"
                },
                {
                    "name": "直ユーザー",
                    "label": "user_normal"
                }
            ],
            isDisabled: true
        }
    },
    watch: {
        'form.company'(newValue) {
            if (newValue) {
                this.checkNewValue = false
            }
            if (newValue == this.user.company) {
                this.checkNewValue = true
            }
        },

        'form.email'(newValue) {
            if (newValue) {
                this.checkNewValue = false
            }
            if (newValue == this.user.email) {
                this.checkNewValue = true
            }
        },

        'form.avatar_url'(newValue) {
            if (newValue) {
                this.checkNewValue = false
            }
        },
        'form.logo_url'(newValue) {
            if (newValue) {
                this.checkNewValue = false
            }
        },

        'form.password'(newValue) {
            if (newValue) {
                this.checkNewValue = false
                this.typePassword = 'password'
            }
        },
        'form.zip_code'(newValue) {
            if (newValue) {
                this.checkNewValue = false
            }
        },
        'form.address'(newValue) {
            if (newValue) {
                this.checkNewValue = false
            }
        },
        'form.address_1'(newValue) {
            if (newValue) {
                this.checkNewValue = false
            }
            if (newValue == this.user.address_1) {
                this.checkNewValue = true
            }
        },
        'form.address_2'(newValue) {
            if (newValue) {
                this.checkNewValue = false
            }
            if (newValue == this.user.address_2) {
                this.checkNewValue = true
            }
        },
        'form.type_register'(newValue) {
            if (newValue == this.user.is_send_mail) {
                this.checkNewValue = true
            } else {
                this.checkNewValue = false
            }
        },
        'form.parent_user.id'(val) {
            if (val != null) {
                this.form.errors.parent_user_id = ''
            }
        },
        'form.role'(newValue) {
            if (newValue == this.user.role_name) {
                this.checkNewValue = true
            } else {
                this.checkNewValue = false
            }
            this.form.errors.parent_user_id = ''
            this.hiddenPopupConfirm = ['admin_agency', 'admin_oem', 'user_agency', 'user_oem'].includes(this.form.role) ? true : false
        },
        'checkNewValue'(val) {
            this.handleDisable(val)
        },
        user(val) {
            this.form.company = val.company
            this.form.avatar_url = val.avatar_image
            this.form.logo_url = val.image_logo
            this.form.email = val.email
            this.form.type_register = val.is_send_mail
            this.form.zip_code = val.zip_code
            this.form.address = val.address
            this.form.address_1 = val.address_1
            this.form.address_2 = val.address_2
            this.form.role = val.role_name
        },
    },
    created() {
        this.$page.props.auth.user.roles.filter((role) => {
            this.userLogin = role.name
        })
        this.oldEmail = this.form.email
        this.urlAvatar = this.user.avatar_image
        this.urlLogo = this.user.image_logo
        this.form.role = this.user.role_name
        this.form.type_register = this.user.is_send_mail
        this.checkRoleAdmin()
    },
    computed: {
        userelected() {
            return this.user
        },
    },
    methods: {
        async fetchDataParentUser() {
            this.form.parent_user = {
                id: null,
                text: null,
            }
            if (['user_agency', 'user_oem', 'user_enterprise'].includes(this.form.role)) {
                this.isLoading = true
                await axios
                    .get('/api/parent_user?role=' + this.form.role)
                    .then((res) => {

                        this.parent_users = res.data.data
                            .filter((user) => {
                                return (
                                    user.status != '解約済' &&
                                    user.status != '契約終了' &&
                                    user.status != '未決済' &&
                                    user.status != '解約中'
                                )
                            })
                            .map((user) => ({
                                text: user.first_name + ' ' + user.last_name,
                                id: user.id,
                            }))
                        // this.form.parent_user = this.parent_users[0]
                        this.isLoading = false
                    })
                    .catch((err) => {
                        console.error(err)
                        this.isLoading = false
                    })
            }
        },
        checkHidden() {
            let checkHidden = true
            let adRole = ['admin_agency', 'admin_oem', 'user_agency', 'user_oem']
            let currPage = window.location.pathname.split('/')[1]
            if (currPage === 'users-detail') {
                checkHidden = adRole.includes(this.user.role_name) ? false : true
            } else {
                checkHidden = adRole.includes(this.$page.props.auth.user.roles[0].name) ? false : true
            }

            return checkHidden
        },
        cropLogoSuccess(imgDataUrl) {
            this.urlLogo = imgDataUrl
            this.form.logo_url = this.dataURLtoFile(imgDataUrl, 'img_logo')
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n)

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }

            return new File([u8arr], filename, { type: mime })
        },
        cropUploadSuccess(jsonData, field) {
            console.log('-------- upload success --------')
            if (field == 'img_logo') {
                console.log(jsonData, field)
            } else {
                console.log(jsonData, field)
            }
        },

        cropUploadFail(status, field) {
            console.log('-------- upload fail --------')
            if (field == 'img_logo') {
                console.log(status, field)
            } else {
                console.log(status, field)
            }
        },
        getAmountPlanCrawl(amount) {
            const tax = 10
            let result = amount + (amount * tax) / 100
            return Number(result)
        },

        goBack() {
            if (this.$page.component === 'Users/Detail') {
                return this.$inertia.visit(route('admin.users.index', { set_old_params: true }))
            } else {
                return this.$inertia.visit(route('admin.dashboard.index'))
            }
        },
        fileUploadAvatar() {
            this.form.avatar_url = null
            this.$refs.fileUploadAvatar.click()
        },
        fileUploadLogo() {
            this.showCropLogo = !this.showCropLogo
            this.form.logo_url = null
            // this.$refs.fileUploadLogo.click()
        },
        checkRoleAdmin() {
            this.$page.props.auth.user.roles.filter((role) => {
                if (
                    role.name == 'user_agency' ||
                    role.name == 'user_oem' ||
                    role.name == 'user_enterprise' ||
                    role.name == 'user_normal'
                ) {
                    this.check_role_admin = false
                } else {
                    this.check_role_admin = true
                }
            })
        },
        onSelectFileAvatar(e) {
            let files = e.target.files || e.dataTransfer.files
            let array_img_type = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg']
            if (array_img_type.includes(files[0]['type'])) {
                this.urlAvatar = URL.createObjectURL(files[0])
                this.nameFileAvatar = files[0].name
                this.form.avatar_url = files[0]
                this.form.errors.avatar_url = ''
            } else {
                this.form.errors.avatar_url = 'jpeg, jpg, png, gif, svg ファイルを使用してください。'
            }
        },
        onSelectFileLogo(e) {
            let logoFiles = e.target.files || e.dataTransfer.files
            let array_img_type = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg']
            if (array_img_type.includes(logoFiles[0]['type'])) {
                this.urlLogo = URL.createObjectURL(logoFiles[0])
                this.nameFileLogo = logoFiles[0].name
                this.form.logo_url = logoFiles[0]
                this.form.errors.logo_url = ''
            } else {
                this.form.errors.logo_url = 'jpeg, jpg, png, gif, svg ファイルを使用してください。'
            }
        },
        async updateProfile() {
            this.isLoading = true
            await this.form.post(route('api.users.update', this.user.id), {
                onFinish: () => {
                    if (!this.form.hasErrors) {
                        this.isLoading = false
                        if (this.user.email === this.form.email) {
                            this.$toast.success(this.$t('update account information successfully'))
                        } else {
                            this.$toast.success(this.$t('update account information successfully (change mail)'))
                        }
                    } else {
                        this.isLoading = false
                        // this.$toast.error(this.$t("an error update account"));
                    }
                    // this.$inertia.visit(route("admin.users-infor"));
                    if (!this.form.errors.parent_user_id) {
                        this.fetchDataUser()
                    }
                },
            })
        },
        confirmUpdate() {
            if (['user_agency', 'user_oem', 'user_enterprise'].includes(this.form.role) && this.form.parent_user.id == null) {
                this.form.errors.parent_user_id = 'この項目は必須です。'
            }
            let hiddenPopup = this.user.status === 'パスワード未設定' ? this.user.plan.amount > 0 ? true : false : this.user.role_name !== this.form.role && this.hiddenPopupConfirm
            if (hiddenPopup) {
                let msg = this.$t('権限を変更したら、契約状態は契約中になりますが、よろしいでしょうか？')
                ElMessageBox.confirm(msg, '警告', {
                    confirmButtonText: '同意',
                    cancelButtonText: 'キャンセル',
                    type: 'warning',
                }).then(async () => {
                    this.updateProfile()
                })
            } else {
                this.updateProfile()
            }
        },
        handleLoginInsta() {
            FB.login(
                async (response) => {
                    if (!response.authResponse) {
                        return
                    }
                    const {
                        data: { data },
                    } = await Ins.businessAccount(response.authResponse.accessToken)
                    this.businessAccount = Ins.filterBusinessAccount(data)
                    this.isShow = true
                    // FB.logout();
                },
                { scope: Ins.addPermission() }
            )
        },

        submit() {
            Ins.insAccountLink(this.account, this.user.id)
            this.isShow = false
            this.$toast.success('Success! You did it!')
        },

        showPopupDeleteUser(id) {
            this.show_popup_delete_user = true
            this.user.id = id
        },

        closePopupDeleteUser() {
            this.show_popup_delete_user = false
            this.user.id = null
        },

        async handleUserDelete() {
            await axios
                .delete(route('api.users.destroy', this.userSelected.id))
                .then(() => {
                    this.closePopupDeleteUser()
                    this.$toast.success(this.$t('delete user successfully'))
                    this.$inertia.visit(route('admin.users.index'))
                })
                .catch(() => {
                    this.$toast.error(this.$t('something went wrong'))
                })
        },

        formatDate(oldDate) {
            if (oldDate) {
                const date = new Date(oldDate)
                let day = date.getDate()
                let month = date.getMonth() + 1
                let year = date.getFullYear()
                return `${year}年${month}月${day}日`
            }
        },

        async checkZipCode(zip_code) {
            await getDataZipCode(zip_code)
                .then(async (res) => {
                    if (res.data.results !== null) {
                        let data = res.data.results[0]
                        this.form.address = data.address3
                        this.form.address_1 = data.address1
                        this.form.address_2 = data.address2
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        handleDisable(val) {
            let isImpersonating = this.$page.props.auth.impersonate
            let adRole = ['admin_agency', 'admin_oem', 'admin_enterprise']
            let msRole = ['master']
            let currPage = window.location.pathname.split('/')[1]
            if (!isImpersonating && currPage === 'users-detail') {
                this.isDisabled = msRole.includes(this.userLogin) ? false : true
            } else if (currPage === 'users-detail') {
                this.isDisabled = adRole.includes(this.userLogin) ? true : false
            }
            val == true ? this.isDisabled = true : this.isDisabled = false
        },
    },
}
</script>

<style scoped>
* {
    font-size: 13px !important;
}

.custom-current-size-des {
    font-size: 12px !important;
}

button {
    font-size: 12px !important;
}

.disable-color {
    color: #81858b;
}

.modal-body {
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 9px;
    width: 35%;
}

.custom-file {
    white-space: nowrap;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .custom-file:hover {
  overflow: visible;
} */
@media (min-width: 1200px) and (max-width: 1360px) {
    .text-label {
        margin-left: -30px;
    }
}
</style>
<style lang="scss">
.vicp-operate a:first-child {
    background: #be3d8f;
    color: white !important;

    &:hover {
        background: #be3d8f !important;
    }
}

.vicp-operate-btn {
    background: #5264cd !important;
    border-radius: 4px !important;
    color: white !important;

    &:hover {
        background: #5264cd !important;
    }
}

@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .vicp-wrap {
        width: 85% !important;
    }

    .vicp-crop-left {
        float: unset !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .vicp-crop-right {
        float: unset !important;
        display: none !important;
    }
}

.role-select .el-input__inner,
.account-select .el-input__inner {
    height: 50px !important;
}
</style>

