<template>
    <dashboard>
        <div class="flex min-h-screen h-full md:overflow-hidden">
            <div class="relative flex-1 bg-gray-100">
                <Loading v-if="isLoading" />
                <div class="my-12 px-5 w-full lg:w-11/12 2xl:w-3/4 mx-auto">
                    <div class="plan">
                        <h1 class="text-pink text-title font-semibold mb-5">決済方法</h1>
                        <table class="min-w-full bg-white">
                            <tbody>
                                <tr class="bg-white border">
                                    <td class="py-4 px-6 w-52 text-2sm  text-gray-900 border font-bold">
                                        利用開始日
                                    </td>
                                    <td class="py-4 px-8 text-2sm text-gray-500">
                                        {{ formatDate(plan.started_at) }}
                                    </td>
                                </tr>

                                <tr class="bg-white border">
                                    <td class="py-4 px-6 w-52 text-2sm text-gray-900 border font-bold">
                                        契約終了日
                                    </td>
                                    <td class="py-4 px-8 text-2sm text-gray-500">
                                        {{ formatDate(plan.ended_at) }}
                                    </td>
                                </tr>

                                <tr class="bg-white border">
                                    <td class="py-4 px-6 w-52 text-2sm text-gray-900 border font-bold">
                                        月額金額
                                    </td>
                                    <td class="py-4 px-8 text-2sm text-gray-500">
                                        {{ (plan.amount_after_tax ?? 0).toLocaleString('ja-JP', {
    style: 'currency',
    currency: 'JPY'
})
                                        }} （税込）
                                    </td>
                                </tr>

                                <tr class="bg-white border">
                                    <td class="py-4 px-6 w-52 text-2sm text-gray-900 border font-bold">
                                        契約変更
                                    </td>
                                    <td class="py-4 px-8 text-2sm text-gray-500">
                                        {{ plan.name }} - {{ plan.note }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-show="showFormPayment" class="py-5">
                        <a class="flex items-center my-8">
                            <img class="w-48" title="Credit Card Logos" src="@shared/uploads/card_payment.png"
                                width="413" height="59" border="0" />
                            <h1 class="mx-3 whitespace-nowrap text-nomal">がご利用いただけます</h1>
                        </a>
                        <div v-show="!showNewCard" class="list-card">
                            <div v-for="card in cards" :key="card.id" class="item-card mb-2">
                                <label>
                                    <input type="radio" v-model="chooseCard" :value="card.payjp_card_id" />
                                    **** **** **** {{ card.last4 }}
                                </label>
                            </div>
                            <a href="javascript:" @click="showNewCard = true" class="mt-2 text-indigo-600 underline">
                                {{ $t('add_new_card') }}
                            </a>
                            <div class="flex w-full justify-center my-6">
                                <button
                                    class="w-1/2 md:w-1/3 xl:w-1/4 max-w-xs bg-button_pink py-5 text-white button-shadow rounded"
                                    @click="callbackUserInfo">
                                    {{ $t('turn_back') }}
                                </button>
                                <div class="w-10"></div>
                                <button
                                    class="w-1/2 md:w-1/3 xl:w-1/4 max-w-xs bg-indigo-500 py-5 text-white button-shadow rounded"
                                    @click="paymentPlan()">
                                    {{ $t('send_payment_card') }}
                                </button>
                            </div>
                        </div>
                        <section v-show="showNewCard" class="mdc my-10" id="v2-mdc">
                            <form class="flex flex-wrap">
                                <div class="w-full error" role="alert">
                                    <span class="message"></span>
                                </div>
                                <div class="my-6 w-full lg:w-1/2 pr-0 lg:pr-5 flex justify-between items-center">
                                    <p class="text-nomal">決済タイプ</p>
                                    <div class="sm:w-4/6 md:w-5/6 lg:w-3/4">
                                        <input class="field text-field" disabled type="text" placeholder="クレジットカード" />
                                    </div>
                                </div>
                                <div class="my-6 w-full lg:w-1/2 pl-0 lg:pl-5 flex justify-between items-center">
                                    <p class="text-nomal">カード番号</p>
                                    <div class="sm:w-4/6 md:w-5/6 lg:w-3/4">
                                        <label class="field text-field" for="v2-mdc-card-number">
                                            <div id="v2-mdc-card-number" class="text-field__input"></div>
                                            <span class="text-field__ripple"></span>
                                            <span class="floating-label"></span>
                                            <span class="line-ripple"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="my-6 w-full lg:w-1/2 pr-0 lg:pr-5 flex justify-between items-center">
                                    <p class="w-1/6 lg:w-1/4 text-nomal">カード有効期限</p>
                                    <div class="sm:w-4/6 md:w-5/6 lg:w-3/4">
                                        <label class="field text-field" for="v2-mdc-card-expiry">
                                            <div id="v2-mdc-card-expiry" class="text-field__input"></div>
                                            <span class="text-field__ripple"></span>
                                            <span class="floating-label"></span>
                                            <span class="line-ripple"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="my-6 w-full lg:w-1/2 pl-0 lg:pl-5 flex justify-between items-center">
                                    <p class="text-nomal">CVC</p>
                                    <div class="sm:w-4/6 md:w-5/6 lg:w-3/4">
                                        <label class="field text-field" for="v2-mdc-card-cvc">
                                            <div id="v2-mdc-card-cvc" class="text-field__input"></div>
                                            <span class="text-field__ripple"></span>
                                            <span class="floating-label"></span>
                                            <span class="line-ripple"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="my-6 w-full lg:w-1/2 pr-0 lg:pr-5 flex justify-between items-center">
                                    <p class="text-nomal">カード名義</p>
                                    <div class="sm:w-4/6 md:w-5/6 lg:w-3/4">
                                        <label class="field text-field mdc-text-field mdc-text-field--filled">
                                            <span class="mdc-text-field__ripple"></span>
                                            <span class="mdc-floating-label"></span>
                                            <input id="v2-mdc-name" class="mdc-text-field__input" autocomplete="cc-name"
                                                type="text" placeholder="例、TARO TANAKA">
                                            <span class="mdc-line-ripple"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="flex w-full justify-center my-6 btn-height">
                                    <button
                                        class="w-1/2 md:w-1/3 xl:w-1/4 max-w-xs bg-button_pink text-btn-submit text-white button-shadow rounded"
                                        @click="callbackUserInfo">
                                        {{ $t('turn_back') }}
                                    </button>
                                    <div class="md:w-10 sm:w-5"></div>
                                    <button
                                        class="mdc-button w-1/2 md:w-1/3 xl:w-1/4 custom max-w-xs py-5 text-btn-submit text-white button-shadow rounded">
                                        {{ $t('send_payment_card') }}
                                    </button>
                                    <span class="mdc-button__ripple"></span>
                                    <span class="mdc-button__label"></span>
                                </div>
                            </form>
                        </section>
                    </div>
                    <div v-show="!showFormPayment" class="flex w-full justify-center my-6">
                        <button
                            class="w-1/2 md:w-1/3 xl:w-1/4 max-w-xs bg-button_pink py-5 text-white button-shadow rounded"
                            @click="callbackUserInfo">
                            {{ $t('turn_back') }}
                        </button>
                        <div class="w-10"></div>
                        <button
                            class="w-1/2 md:w-1/3 xl:w-1/4 max-w-xs bg-indigo-500 py-5 text-white button-shadow rounded"
                            @click="paymentPlan()">
                            {{ $t('payment_plan_update') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </dashboard>
</template>

<script>
import Loading from '@/Components/Loadding'
import * as mdc from "@/Store/mdc";
import * as planAPI from "@/Api/Plan.js";
import Dashboard from "@/Layouts/Authenticated.vue";

export default {
    props: ['plan_id', 'plan_crawl_id', 'amount_use', 'amount_update'],
    components: {
        Loading, Dashboard
    },
    data() {
        return {
            data: "",
            value: "",
            isActive: false,
            nameCard: "",
            elements: "",
            message: "",
            payjp: null,
            isLoading: false,
            plan: {},
            cards: [],
            chooseCard: '',
            showNewCard: true,
            showFormPayment: true
        };
    },

    async mounted() {
        await this.fetchDataUser();
        // Get list card
        this.getListCard()

        const me = this;
        var user = this.$page.props.auth.user;
        var buttonElm = mdc.setUpMDC();
        if (Payjp && buttonElm !== null) {
            var payjp = Payjp(process.env.MIX_PAYJP_PK);
            var elements = payjp.elements();
            var elementStyle = {
                base: {
                    fontFamily: '\'Noto Sans Japanese\', sans-serif',
                    '::placeholder': {
                        color: 'rgba(0, 0, 0, 0.54)',
                    },
                    caretColor: '#198FCC',
                    lineHeight: '28px',
                },
                invalid: {
                    //color: 'rgba(0, 0, 0, 0.87)',
                },
            }
            // 入力フォームを分解して管理・配置できます
            var numberElement = elements.create('cardNumber', {
                style: elementStyle,
                placeholder: '4242 4242 4242 4242'
            })
            var expiryElement = elements.create('cardExpiry', {
                style: elementStyle,
            })
            var cvcElement = elements.create('cardCvc', {
                style: elementStyle,
            })
            numberElement.mount('#v2-mdc-card-number')
            expiryElement.mount('#v2-mdc-card-expiry')
            cvcElement.mount('#v2-mdc-card-cvc')

            var payjpElements = [numberElement, expiryElement, cvcElement]
            var nameInput = document.querySelector('#v2-mdc-name')
            var section = document.querySelector('section#v2-mdc')
            var form = section.querySelector('form')
            var errorElm = section.querySelector('.error')
            var changes = {}

            function displayErrorMsg(msg) {
                errorElm.classList.add('visible')
                errorElm.querySelector('.message').innerText = msg
            }

            payjpElements.forEach(function (element, idx) {
                changes[idx] = null
                element.on('change', function (event) {
                    changes[idx] = event
                    buttonElm.setAttribute('disabled', 'true')
                    if (event.error) {
                        displayErrorMsg(event.error.message)
                    } else {
                        var errorMsg = Object.keys(changes).sort().reduce(function (msg, i) {
                            if (msg) {
                                return msg
                            } else if (changes[i] && changes[i].error) {
                                return changes[i].error.message
                            }
                            return null
                        }, null)

                        if (errorMsg) {
                            displayErrorMsg(errorMsg)
                        } else {
                            errorElm.classList.remove('visible')
                            buttonElm.removeAttribute('disabled')
                        }
                    }
                })
            })

            form.addEventListener('submit', function (e) {
                e.preventDefault()
                if (!Object.keys(changes).sort().reduce(function (prev, i) {
                    return prev && changes[i] && changes[i].complete
                }, true)) {
                    return displayErrorMsg('入力が完了していません。')
                }

                var options = {
                    card: {
                        name: nameInput.value || undefined
                    }
                }

                buttonElm.setAttribute('disabled', 'true')
                form.classList.add('submitting')
                me.isLoading = true;

                payjp.createToken(payjpElements[0], options).then(async function (result) {
                    form.classList.remove('submitting')
                    buttonElm.removeAttribute('disabled')
                    console.log(result);
                    if (result.id) {
                        form.classList.add('submitted')
                        // call api card
                        const token = result.id;
                        // Set card Default when add card
                        if (result.card.cvc_check === 'passed' || result.card.cvc_check === "unavailable") {
                            //Create customersss
                            let customer_by_user = null;
                            await axios.get(route('api.customer.by.user'))
                                .then((response) => {
                                    customer_by_user = response.data !== '' ? response.data.payjp_customer_id : null
                                }
                                )
                                .catch((error) => {
                                    me.isLoading = false;
                                    if (error.response.status === 401) {
                                        window.location.href = route('login.user')
                                    } else {
                                        me.$toast.success(error.message);
                                    }
                                }
                                )
                            const customer = await planAPI.cardSetDefault(customer_by_user ?? null, token, me.plan.note);

                            if (customer.data.httpStatus === 400 || customer.data.httpStatus === 402) {

                                me.isLoading = false;
                                displayErrorMsg(me.$t(customer.data.jsonBody.error.code))
                            } else {
                                // create Subscription
                                const article = {
                                    payjp_customer_id: customer.data.payjp_customer_id,
                                    payjp_plan_id: me.plan.payjp_plan_id,
                                    plan_id: me.plan.plan_id,
                                    card_id: '',
                                    is_checkout: true
                                };
                                await axios.post(route('api.subscriptions.store'), article)
                                    .then((response) => {
                                        me.isLoading = false;
                                        me.$toast.success(me.$t('payment success'));
                                        errorElm.querySelector('.message').remove();
                                        let param = {
                                            'update': response.data.update ?? 0,
                                            'plan_id': me.plan.plan_id,
                                        }
                                        window.location.href = route('admin.payment.success', param)
                                    }
                                    )
                                    .catch((err) => {
                                        me.isLoading = false;
                                        me.$toast.error(me.$t(err.response.data.msg));
                                    })
                            }
                        } else if (result.card.cvc_check === "failed") {
                            me.isLoading = false;
                            displayErrorMsg(me.$t("cvc_check=failed"))
                        }
                    } else {
                        me.isLoading = false;
                        displayErrorMsg(result.error.message)
                    }
                })
            })
        }
    },
    created() {
    },

    methods: {
        callbackUserInfo() {
            return this.$inertia.visit(route('admin.users_infor'))
        },

        async fetchDataUser() {
            //get id user login
            this.userId = this.$page.props.auth.user.id;
            // call api get plans
            let plan_crawl = await planAPI.getPlanCrawl(this.plan_crawl_id);
            this.plan = plan_crawl.data.data;
        },

        formatDate(oldDate) {
            if (oldDate) {
                const date = new Date(oldDate);
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();
                return `${year}年${month}月${day}日`;
            }
        },

        async getListCard() {
            let result = await planAPI.getListCard(this.$page.props.auth.user.id)
            this.cards = result.data
            result.data.forEach(item => {
                if (item.default) {
                    this.chooseCard = item.payjp_card_id
                }
            })
        },

        async paymentPlan() {
            this.isLoading = true;

            let customer_by_user_id = '';
            await axios.get(route('api.customer.by.user'))
                .then((response) => {
                    customer_by_user_id = response.data.payjp_customer_id
                }
                )
            const article = {
                payjp_customer_id: customer_by_user_id ?? null,
                payjp_plan_id: this.plan.payjp_plan_id,
                plan_id: this.plan.plan_id,
                card_id: this.chooseCard,
                is_checkout: true
            };

            await axios.post(route('api.subscriptions.store'), article)
                .then((response) => {

                    this.isLoading = false;
                    this.$toast.success(this.$t('payment success'));
                    let param = {
                        'update': response.data.update ?? 0,
                        'plan_id': this.plan.plan_id,
                    }
                    window.location.href = route('admin.payment.success', param)
                }
                )
                .catch((err) => {

                    this.isLoading = false;
                    this.$toast.error(this.$t(err.response.data.msg));
                }
                );
        }
    },

    watch: {
        plan: function () {
            const amount = this.amount_use - this.amount_update;
            if (amount >= 0) {
                this.showFormPayment = false
            }
        },
        cards: function () {
            if (this.cards.length) {
                this.showNewCard = false
            }
        }
    }
};
</script>

<style scoped>
@import '~@/Pages/Users/assets/css/payment.css';

.custom {
    font-size: 14px !important;
    color: white;
    height: 4rem;
    padding: 0 !important;
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

@media (max-width: 640px) {
    p {
        white-space: nowrap;
    }

    .btn-height button {
        font-size: 12px !important;
        white-space: nowrap;
        height: 3rem;
    }
}
</style>
