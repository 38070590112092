<template>
  <data-table
    :fields="fields"
    :items="base_infos"
    disable-table-info
    footer-center
    paginate-background
    class="el-table-header__black mt-7"
    :class="{ datatable__hidden: checkEmpty }"
  >
    <template v-if="!checkEmpty" #followers="{ row }">
      <p v-if="row" class="font-bold">{{ dataFollow(row?.followers) }}</p>
    </template>
    <template v-if="!checkEmpty" #follower_change>
      <p class="font-bold" :class="{ plus_active: followerChange() > 0, minus_active: followerChange() < 0 }">
        {{ formatFollowerChange(followerChange()) }}
      </p>
    </template>
    <template v-if="!checkEmpty" #profile_views>
      <p class="font-bold">{{ getProfileView() }}</p>
    </template>
    <template v-if="!checkEmpty" #website_clicks>
      <p class="font-bold">{{ getWebsiteClicks() }}</p>
    </template>
    <template v-if="!checkEmpty" #reach>
      <p class="font-bold">{{ getReach() }}</p>
    </template>
    <template v-if="!checkEmpty" #media_count="{ row }">
      <p class="font-bold">{{ getMediaCount(row?.media_count) }}</p>
    </template>
  </data-table>
  <el-empty v-if="checkEmpty" description="お知らせはありません。" class="mt-2"></el-empty>
</template>

<script>
import DataTable from '@/Components/UI/DataTable.vue'
import { formatToFloat } from '@/Plugins/numberFormat'

export default {
  components: { DataTable },
  props: {
    range: { type: Object, default: 0 },
    groupTable: { type: Object, default: {} },
  },
  data() {
    return {
      base_infos: [],
      calculation: {},
      checkEmpty: false,
      fields: [
        { key: 'followers', label: 'フォロワー数', width: 150, align: 'center' },
        {
          key: 'follower_change',
          label: 'フォロワー純増減数',
          width: screen.width / 5 >= 240 ? screen.width / 5 : 200,
          align: 'center',
        },
        {
          key: 'profile_views',
          label: 'プロフィールアクセス数',
          width: screen.width / 5 >= 240 ? screen.width / 5 : 200,
          align: 'center',
        },
        {
          key: 'website_clicks',
          label: 'ウェブサイトクリック数',
          width: screen.width / 5 >= 240 ? screen.width / 5 : 200,
          align: 'center',
        },
        {
          key: 'reach',
          label: 'アカウントリーチ数',
          width: screen.width / 5 >= 240 ? screen.width / 5 : 200,
          align: 'center',
        },
        {
          key: 'media_count',
          label: 'フィード投稿数',
          width: screen.width / 5 >= 240 ? screen.width / 5 : 200,
          align: 'center',
        },
      ],
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    range: function () {
      this.fetchData()
    },
    groupTable: function () {
      this.fetchData()
    },
  },
  methods: {
    // fetch data
    async fetchData() {
      if (!this.range.length) return
      const datatable = JSON.parse(JSON.stringify(this.groupTable))
      this.base_infos = datatable?.lastDaySelected ? Array(datatable?.lastDaySelected) : []
      this.calculation = datatable?.calculation
      this.checkData(datatable?.calculation)
    },
    checkData(data) {
      if (data) {
        this.checkEmpty = Object.values(data).every((x) => x == 0)
      }
    },
    getProfileView() {
      let result = Number.isInteger(this.calculation?.totalProfileViews)
        ? this.calculation?.totalProfileViews
        : this.calculation?.totalProfileViews.toFixed(2)
      return result ? this.formatToInteger(result) : '-'
    },
    getWebsiteClicks() {
      let result = Number.isInteger(this.calculation?.totalWebsiteClicks)
        ? this.calculation?.totalWebsiteClicks
        : this.calculation?.totalWebsiteClicks.toFixed(2)
      return result ? this.formatToInteger(result) : '-'
    },
    getReach() {
      let result = Number.isInteger(this.calculation?.totalReach)
        ? this.calculation?.totalReach
        : this.calculation?.totalReach.toFixed(2)
      return result ? this.formatToInteger(result) : '-'
    },
    getMediaCount(value) {
      return value ? this.formatToFloat(value) : '-'
    },
    dataFollow(value) {
      return value ? this.formatToFloat(value) : '-'
    },
    //follower change
    followerChange() {
      const lastDaySelected = this.groupTable?.lastDaySelected?.followers ?? 0
      const lastDayPrevious = this.groupTable?.lastDayPrevious?.followers ?? 0
      let followers = Number.isInteger(lastDaySelected - lastDayPrevious)
        ? lastDaySelected - lastDayPrevious
        : (lastDaySelected - lastDayPrevious).toFixed(2)
      return followers
    },
    formatFollowerChange(followers) {
      return followers > 0 ? '+' + this.formatToInteger(followers) : this.formatToInteger(followers)
    },
  },
}
</script>
