<template>
    <div
        class="flex justify-between lg:items-center md:px-8 sm:px-2 pb-5 border-b border-[#D5D2D3] lg:flex-row sm:flex-col md:items-start lg:gap-0 md:gap-5">
        <div class="flex items-center gap-3 relative">
            <img v-if="userSelected?.avatar_image"
                class="object-cover w-24 h-24 rounded-full border-solid border-2 border-pink"
                :src="userSelected?.avatar_image" />
            <p v-if="!userSelected?.avatar_image"
                class="flex justify-center items-center object-cover w-24 h-24 rounded-full border-solid border-2 border-pink text-2xl">
                {{ userSelected.first_name?.[0] }}
            </p>
            <!-- <div class="w-32 h-32 absolute rounded-full">
        <label class="formLabel absolute right-[50px] bottom-[50px] z-20" for="file">
        <img class="object-cover cursor-pointer" :src="'..//images/レイヤー 14.svg'" alt=""><input
            style="display: none" type="file" id="file" class="imagesAvatar"
            name="inputAvatar">
        </label>
        <div class="rounded-full absolute w-full top-0 z-10 opacity-0 hover:visible hover:bg-[#BF3D8F] hover:opacity-[.58] h-full"></div>
      </div> -->
            <div>
                <div class="flex items-start justify-start">
                    <div class="mr-13">
                        <div class="flex items-center">
                            <h3 v-if="userSelected.company"
                                class="uppercase font-semibold text-[13px] text-[#020202] md:mr-13 sm:mr-10">
                                {{ userSelected?.company }}
                            </h3>
                            <div class="sm:mx-0">
                                <p v-if="userSelected.status" class="flex items-center justify-center text-[13px]">
                                    <template v-if="userSelected.status == 'パスワード未設定'">
                                        <!-- <input type="radio" class="mr-2 text-blue-600" checked /> -->
                                        <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-pink"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                                fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                            <path
                                                d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                                fill="#BE3D8F" />
                                        </svg>
                                        <span class="text-pink md:text-base sm:text-xs whitespace-nowrap">{{
                                            userSelected.status
                                        }}</span>
                                    </template>

                                    <template v-if="userSelected.status == '契約中'">
                                        <!-- <input type="radio" class="mr-2 text-blue-600" checked /> -->
                                        <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-blue-600"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                                fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                            <path
                                                d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                                fill="#5264CD" />
                                        </svg>
                                        <span class="text-blue-600 whitespace-nowrap">{{ userSelected.status }}</span>
                                    </template>
                                    <template v-if="userSelected.status == '契約終了'">
                                        <!-- <input type="radio" class="mr-2 text-pink " checked /> -->
                                        <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-pink"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                                fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                            <path
                                                d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                                fill="#BE3D8F" />
                                        </svg>
                                        <span class="text-pink whitespace-nowrap">{{ userSelected.status }}</span>
                                    </template>
                                    <template v-if="userSelected.status == '無料プラン利用中'">
                                        <!-- <input type="radio" class="mr-2 text-blue-600" checked /> -->
                                        <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-blue-600"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                                fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                            <path
                                                d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                                fill="#5264CD" />
                                        </svg>
                                        <span class="text-blue-600 whitespace-nowrap">{{ userSelected.status }}</span>
                                    </template>
                                    <template v-if="userSelected.status == '解約中'">
                                        <!-- <input type="radio" class="mr-2 text-pink " checked /> -->
                                        <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-pink"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                                fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                            <path
                                                d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                                fill="#BE3D8F" />
                                        </svg>
                                        <span class="text-pink whitespace-nowrap">{{ userSelected.status }}</span>
                                        <el-tooltip class="box-item" effect="light"
                                            :content="getMessage(userSelected.dateExprie)" placement="top" raw-content>
                                            <el-icon class="ml-4" :size="18">
                                                <QuestionFilled />
                                            </el-icon>
                                        </el-tooltip>
                                    </template>
                                    <template v-if="userSelected.status == '未決済'">
                                        <!-- <input type="radio" class="mr-2 text-pink " checked /> -->
                                        <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-pink"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                                fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                            <path
                                                d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                                fill="#BE3D8F" />
                                        </svg>
                                        <span class="text-pink whitespace-nowrap">{{ userSelected.status }}</span>
                                    </template>
                                    <template v-if="userSelected.status == '解約済'">
                                        <!-- <input type="radio" class="mr-2 text-pink " checked /> -->
                                        <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-pink"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                                fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                            <path
                                                d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                                fill="#BE3D8F" />
                                        </svg>
                                        <span class="text-pink whitespace-nowrap">{{ userSelected.status }}</span>
                                    </template>
                                    <template v-if="userSelected.status == '未契約'">
                                        <!-- <input type="radio" class="mr-2 text-pink " checked /> -->
                                        <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-pink"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                                fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                            <path
                                                d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                                fill="#BE3D8F" />
                                        </svg>
                                        <span class="text-pink whitespace-nowrap">{{ userSelected.status }}</span>
                                    </template>
                                </p>
                                <!-- <p v-else class="flex items-center justify-center">
                                    <svg viewBox="0 0 18 18" fill="currentColor" class="h-5 w-5 text-pink"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9 17.2822C13.4183 17.2822 17 13.7005 17 9.28223C17 4.86395 13.4183 1.28223 9 1.28223C4.58172 1.28223 1 4.86395 1 9.28223C1 13.7005 4.58172 17.2822 9 17.2822Z"
                                            fill="white" stroke="#DBDBDB" stroke-linejoin="round" />
                                        <path
                                            d="M8.80017 14.3752C11.5008 14.3752 13.6901 12.1859 13.6901 9.48523C13.6901 6.78456 11.5008 4.59521 8.80017 4.59521C6.0995 4.59521 3.91016 6.78456 3.91016 9.48523C3.91016 12.1859 6.0995 14.3752 8.80017 14.3752Z"
                                            fill="#BE3D8F" />
                                    </svg>
                                    <span class="text-pink text-[13px] whitespace-nowrap">未契約</span>
                                </p> -->
                            </div>
                        </div>
                        <div class="flex items-center md:mt-0 sm:mt-1">
                            <div class="pr-3">
                                <p v-if="accountLinkIns" class="text-[13px] text-[#81858C]">アカウント名 : {{
                                    accountLinkIns
                                }}</p>
                            </div>
                            <div class="md:px-3 text-[13px]" v-if="accountLinkIns">
                                <button @click="updateTokenAccountFB">
                                    <span class="text-blue-600 whitespace-nowrap">
                                        <i class="fa-solid fa-arrows-rotate"></i>
                                        アクセストークン更新
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-start flex-col">
                    <div v-if="userSelected?.token_register == null" @click="handleLoginInsta">
                        <img class="w-24 cursor-pointer my-1" src="@shared/uploads/ins-icon.png" />
                        <div class="flex items-center"
                            v-if="userSelected.role_name != 'master' && userSelected.plan !== null">
                            <div class="ml-0 mt-1 text-3sm">
                                連携可能のInstaアカウント数は最大
                                <span class="font-bold" style="color: #f05161">{{
                                    userSelected.plan?.number_accounts
                                }}</span>
                                です。
                            </div>
                            <el-tooltip class="box-item" effect="light" :content="$t('tooltip_user_info_insta')"
                                placement="top" raw-content>
                                <el-icon class="ml-1" :size="18">
                                    <QuestionFilled />
                                </el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="checkHidden()" class="flex items-center md:mt-0 sm:mt-1">
            <h3 class="md:text-xl sm:text-sm mx-3">アカウント情報を</h3>
            <button :disabled="userSelected.number_invoice == 0" @click="exportPdf(userSelected.id)"
                class="bg-black text-white md:py-3 sm:py-1 md:px-9 sm:px-3 rounded mr-1 text-xs">
                PDF出力
            </button>
            <button :disabled="userSelected.number_invoice == 0" @click="exportCsv(userSelected.id)"
                class="bg-black text-white md:py-3 sm:py-1 md:px-9 sm:px-3 rounded ml-1 text-xs">
                CSV出力
            </button>
        </div>
    </div>
    <PopupIns :data="businessAccount" v-model="isShow" :user-id="userSelected.id"
        @onSubmitSuccess="handleSubmitSuccess" />
</template>

<script>
import Ins from '@/Services/Ins'
import PopupIns from '@/Components/PopupIns.vue'
import { QuestionFilled } from '@element-plus/icons-vue'

export default {
    props: ['userSelected'],
    components: {
        PopupIns,
        QuestionFilled,
    },

    data() {
        return {
            isShow: false,
            businessAccount: [],
            account: {},
            accountLinkIns: '',
            tokenFB: '',
        }
    },
    mounted() {
        this.accountLinkIns = this.userSelected?.account_name
    },
    methods: {
        checkHidden() {
            let checkHidden = true
            let adRole = ['admin_agency', 'admin_oem', 'user_agency', 'user_oem']
            let currPage = window.location.pathname.split('/')[1]
            if (currPage === 'users-detail') {
                checkHidden = adRole.includes(this.userSelected.role_name) ? false : true
            } else {
                checkHidden = adRole.includes(this.$page.props.auth.user.roles[0].name) ? false : true
            }

            return checkHidden
        },
        async updateTokenAccountFB() {
            try {
                if (this.tokenFB) {
                    const {
                        data: { data },
                    } = await Ins.businessAccount(this.tokenFB)

                    const updateTokenAccount = await Ins.filterTokenAccountIns(data)

                    if (updateTokenAccount.length == 0) {
                        await this.checkPermissionUpdateToken()
                    } else {
                        const res = await axios.post(route('api.account.update_token', updateTokenAccount[0].access_token))
                        if (!res.data.status) {
                            return this.$toast.error(res.data.msg)
                        }

                        this.$toast.success('アクセストークンを更新しました。')
                        window.location.reload()
                    }
                } else {
                    FB.login(
                        async (response) => {
                            if (!response.authResponse) {
                                return
                            }

                            const {
                                data: { data },
                            } = await Ins.businessAccount(response.authResponse.accessToken)

                            const updateTokenAccount = await Ins.filterTokenAccountIns(data)

                            if (updateTokenAccount.length == 0) {
                                await this.checkPermissionUpdateToken()
                            } else {
                                const res = await axios.post(route('api.account.update_token', updateTokenAccount[0].access_token))
                                if (!res.data.status) {
                                    return this.$toast.error(res.data.msg)
                                }

                                this.$toast.success('アクセストークンを更新しました。')
                                window.location.reload()
                            }
                            // FB.logout()
                        },
                        { scope: Ins.addPermission() }
                    )
                }
            } catch (error) {
                console.log(error)
            }
        },

        checkPermissionUpdateToken() {
            return this.$toast.error(
                this.$t('アクセストークンを更新できませんでした。Facebookページの管理権限をご確認ください。')
            )
        },

        handleSubmitSuccess(userNameIns) {
            return (this.accountLinkIns = userNameIns)
        },

        handleLoginInsta() {
            FB.login(
                async (response) => {
                    if (!response.authResponse) {
                        return
                    }
                    this.tokenFB = response.authResponse.accessToken
                    const {
                        data: { data },
                    } = await Ins.businessAccount(response.authResponse.accessToken)
                    const checkAllAccountIns = await Ins.checkAllAccountIns(Ins.filterBusinessAccount(data))
                    this.businessAccount = checkAllAccountIns
                    if (this.businessAccount.length == 0) {
                        this.checkPermissionLinkAccountIns()
                    } else {
                        this.isShow = true
                    }
                    // FB.logout();
                },
                { scope: Ins.addPermission() }
            )
        },

        checkPermissionLinkAccountIns() {
            return this.$toast.error(this.$t('please_add_enough_permissions_for_the_linked_account'))
        },

        exportPdf(user_id) {
            window.location.href = route('admin.export-pdf', user_id)
        },

        exportCsv(user_id) {
            axios.get(route('admin.export-csv', user_id)).then(function (response) {
                window.location.href = response.data
            })
        },

        getMessage(dateExprie) {
            if (dateExprie == null) {
                return ''
            }
            const date = new Date(dateExprie)
            let day = date.getDate()
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            return '現在なプランを' + `${year}年${month}月${day}日` + 'まで利用出来ます。'
        },
    },
}
</script>
