<template>
  <dashboard>
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li v-if="check_role">
          <Link :href="route('admin.notifications.index')" class="font-normal">お知らせ一覧</Link>
        </li>
        <span v-if="check_role" class="font-normal text-notice_date mx-2">></span>
        <li>
          <Link :href="route('admin.notifications.show', deliveryNotice.id)" class="font-normal breakcumb-active">
            お知らせ
          </Link>
        </li>
      </BreadCrumb>
    </template>
    <div class="body flex-grow-1 p-6 bg-background">
      <table class="min-w-full bg-white">
        <tbody>
          <tr class="bg-white border">
            <td class="py-5 md:px-6 sm:px-2 sm:w-5 md:w-24 text-2sm bg-gray-100 text-gray-900 border col-span-1">公開日時</td>
            <td class="py-5 md:px-6 sm:px-2 sm:w-5 md:w-32 text-gray-900 text-2sm">
              {{ deliveryNotice.deliveredTimeFormat }}
            </td>
          </tr>

          <tr class="bg-white border">
            <td class="py-5  md:px-6 sm:px-2 sm:w-5 md:w-24 text-2sm bg-gray-100 text-gray-900 border">ラベル</td>
            <td class="py-5  md:px-6 sm:px-2 sm:w-5 md:w-52 text-2sm text-gray-900">
              {{ deliveryNotice.label }}
            </td>
          </tr>

          <tr class="bg-white border">
            <td class="py-5  md:px-6 sm:px-2 sm:w-5 md:w-24 text-2sm bg-gray-100 text-gray-900 border">タイトル</td>
            <td class="py-5  md:px-6 sm:px-2 sm:w-5 md:w-52 text-2sm text-gray-900 break-text" v-html="parseString(deliveryNotice.title)"></td>
          </tr>

          <tr class="bg-white border">
            <td class="py-5  md:px-6 sm:px-2 sm:w-5 md:w-24 text-2sm bg-gray-100 text-gray-900 border">本文</td>
            <td class="py-5  md:px-6 sm:px-2 sm:w-5 md:w-52 text-2sm text-gray-900 break-text overflow-auto">
              <p class="h-[200px]" v-html="parseString(deliveryNotice.content)"></p>
            </td>
          </tr>
          <!-- What Is This Form Use For ??? -->
          <tr class="bg-white border">
            <td class="py-5  md:px-6 sm:px-2 sm:w-5 md:w-24 text-2sm bg-gray-100 text-gray-900 border">公開範囲</td>
            <td class="py-5  md:px-6 sm:px-2 sm:w-5 md:w-52 text-2sm text-gray-900">
              {{ generateReceiver }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex items-center justify-center w-full mt-10">
        <Link href="/dashboard" class="bg-indigo-500 text-white px-24 py-5 rounded-sm text-3sm">戻る</Link>
      </div>
    </div>
  </dashboard>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import Dashboard from '../Dashboard.vue'
import { getListRole } from '@/Api/Role'
import _ from 'lodash'
import { parseTagA } from '@/Helpers/xss'
import BreadCrumb from '@/Components/Breadcrumb.vue'
export default {
  name: 'Detail',
  data() {
    return {
      roles: [],
      check_role: false,
    }
  },
  props: {
    deliveryNotice: {
      type: Object,
      required: true,
    },
    prevRoute: {
      type: String,
      required: true,
    },
  },
  components: {
    Dashboard,
    Link,
    BreadCrumb,
  },
  async created() {
    const { data } = await getListRole()
    this.roles = data
    this.$page.props.auth.user.roles.filter((role) => {
      if (role.name == 'master') {
        this.check_role = true
      } else {
        this.check_role = false
      }
    })
  },
  computed: {
    generateReceiver() {
      const noticeRoles = this.deliveryNotice?.notice_roles || []
      if (_.isEqual(noticeRoles.map((item) => item.role_id).sort(), this.roles.map((item) => item.id).sort())) {
        return '全て'
      }

      return noticeRoles
        .map((item) => {
          const role = (this.roles || []).find((i) => i.id === item.role_id)
          return role?.name || ''
        })
        .join('、')
    },
  },
  methods: {
    parseString(str) {
      return parseTagA(str)
    },
  },
}
</script>

<style scoped>
.break-text {
  word-break: break-all;
  overflow-wrap: anywhere;
}
</style>
