import {
  purposeCampaigns,
  traffics,
  advertisingSettings,
  otherCampaignsInfo,
  optimizationGoal,
  optimizationGoalForConvertsions,
  optimizationGoalForReach,
  optimizationGoalForLeadGeneration,
  optimizationGoalForAppInstalls,
  optimizationGoalForPageLikes,
  optimizationGoalForPostEngagement,
  optimizationGoalForBrandAwareness,
} from '@/Constants/options'
import Ins from '@/Services/Ins'
export default {
  data() {
    let validateLinkShow = (rule, value, callback) => {
      if (value === '' || !this.isValidURL(value)) {
        callback(new Error(`${this.$i18n.t('Vui lòng nhập linksShow')}`))
      } else {
        callback()
      }
    }
    let validateRequired = (message) => (rule, value, callback) => {
      if (value === '' || (value && value.trim() === '')) {
        callback(new Error(`${message}`))
      } else {
        callback()
      }
    }
    return {
      optionsAgeMin: [],
      optionsAgeMax: [],
      form: {
        campaign_objective: '',
        destination_type: '',
        budget: 0,
        dailyBudget: 24000,
        reach: '',
        minAge: 0,
        end_date: new Date(),
        start_date: new Date(),
        maxAge: 100,
        gender: 0,
        campainsCurrent: null,
        searchTarget: null,
        chooseTarget: null,
        setting: '',
        raiseBrand: '',
        reachTraffic: '',
        engagement: '',
        installApp: '',
        increaseVideo: '',
        leadAcquisition: '',
        message: '',
        conversion: '',
        catalogSales: '',
        increaseShop: '',
        modelFile: 1,
        file: '',
        setName: '',
        optimizationGoal: '',
        billingEvent: '',
        bidAmount: '',
        creativeName: '',
        nameAds: '',
        linksShow: '',
        phoneCall: '',
        messageAds: '',
        addLocale: [],
      },
      rules: {
        campaign_name: [
          { validator: validateRequired(`${this.$i18n.t('Vui lòng nhập tên campaign')}`), trigger: 'change' },
          { required: true, message: `${this.$i18n.t('Vui lòng nhập tên campaign')}`, trigger: 'change' },
        ],
        campaign_objective: [
          { required: true, message: `${this.$i18n.t('Vui lòng nhập tên objective')}`, trigger: 'change' },
        ],
        setName: [
          { validator: validateRequired(`${this.$i18n.t('Vui lòng nhập setName')}`), trigger: 'change' },
          { required: true, message: `${this.$i18n.t('Vui lòng nhập setName')}`, trigger: 'change' },
        ],
        searchTarget: [
          { validator: validateRequired(`${this.$i18n.t('Vui lòng Search Taget')}`), trigger: 'change' },
          { required: true, message: `${this.$i18n.t('Vui lòng Search Taget')}`, trigger: 'change' },
        ],
        optimizationGoal: [
          { required: true, message: `${this.$i18n.t('Vui lòng nhập Optimization Goal')}`, trigger: 'change' },
        ],
        // bidAmount: [{ required: true, message: `${this.$i18n.t('Vui lòng nhập bidAmount')}`, trigger: 'change' }],
        dailyBudget: [{ required: true, message: `${this.$i18n.t('Vui lòng nhập dailyBudget')}`, trigger: 'change' }],
        nameAds: [
          { validator: validateRequired(`${this.$i18n.t('Vui lòng nhập nameAds')}`), trigger: 'change' },
          { required: true, message: `${this.$i18n.t('Vui lòng nhập nameAds')}`, trigger: 'change' },
        ],
        linksShow: [
          { validator: validateLinkShow, trigger: 'change' },
          { required: true, message: `${this.$i18n.t('Vui lòng nhập linksShow')}`, trigger: 'change' },
        ],
        phoneCall: [{ required: true, message: `${this.$i18n.t('Vui lòng nhập phoneCall')}`, trigger: 'change' }],
        chooseTarget: [{ required: true, message: `${this.$i18n.t('Vui lòng nhập chooseTarget')}`, trigger: 'change' }],
        start_date: [{ required: true, message: `${this.$i18n.t('Vui lòng nhập start_date')}`, trigger: 'change' }],
        destination_type: [
          { required: true, message: `${this.$i18n.t('Vui lòng nhập destination_type')}`, trigger: 'change' },
        ],
        end_date: [{ required: true, message: `${this.$i18n.t('Vui lòng nhập end_date')}`, trigger: 'change' }],
      },
      arrFileCarosel: [],
      checkedCities: [],
      fileUpload: [],
      fileType: '',
      since: new Date(),
      until: new Date().setDate(new Date().getDate() + 2),
      untilDate: new Date(),
      adsAccount: [],
      isShow: false,
      accountAds: null,
      options: [],
      chooseCampains: '1',
      dataTarget: [],
      openSelectTarget: false,
      userIdCurrent: this.$page.props.auth.user.id,
      isOpenPreview: false,
      dialogVisible: false,
      isLoading: false,
      minAngle: 18,
      maxAngle: 65,
      myThumbnail: {},
      myImage: {},
      myCarousel: {},
      type: '',
      infoCarousel: [],
      convertsions: false,
      reach: false,
      checkOptimizationGoal: false,
      validateFileUpload: true,
      searchLocal: [],
      leadGeneration: false,
      appInstalls: false,
      videoViews: false,
      pageLikes: false,
      postEngagement: false,
      brandAwareness: false,
      oldFile: '',
      oldCarousel: '',
      oldArrCarousel: [],
      oldFileUpload: [],
      oldFilesUpload: [],
    }
  },
  computed: {
    account() {
      if (this.$page.props.auth?.account !== null) {
        return this.$page.props.auth?.account
      }
      return null
    },
    listOptimizationGoal() {
      return optimizationGoal
    },
    listCampain() {
      return purposeCampaigns
    },
    listTraffic() {
      return traffics
    },
    listAdvertisingSettings() {
      return advertisingSettings
    },
    listOtherCampain() {
      return otherCampaignsInfo
    },
    ListConvertsions() {
      return optimizationGoalForConvertsions
    },
    ListReach() {
      return optimizationGoalForReach
    },
    ListLeadGeneration() {
      return optimizationGoalForLeadGeneration
    },
    ListAppInstalls() {
      return optimizationGoalForAppInstalls
    },
    ListPageLikes() {
      return optimizationGoalForPageLikes
    },
    ListPostEngagement() {
      return optimizationGoalForPostEngagement
    },
    ListBrandAwareness() {
      return optimizationGoalForBrandAwareness
    },
    ageMin: {
      get: function () {
        var val = parseInt(this.minAngle)
        return val
      },
      set: function (val) {
        val = parseInt(val)
        if (val > this.maxAngle) {
          this.maxAngle = val
        }
        this.minAngle = val
      },
    },
    ageMax: {
      get: function () {
        var val = parseInt(this.maxAngle)
        return val
      },
      set: function (val) {
        val = parseInt(val)
        if (val < this.minAngle) {
          this.minAngle = val
        }
        this.maxAngle = val
      },
    },
  },
  created() {
    this.getAccountAds()
    for (let i = 13; i < 66; i++) {
      this.optionsAgeMin.push({
        value: i,
        label: i,
      })
      this.optionsAgeMax.push({
        value: i,
        label: i,
      })
    }
    this.optionsAgeMax[this.optionsAgeMax.length - 1].label = '65+'
  },
  methods: {
    async handleSelectLocaleAds(query) {
      if (query !== '') {
        const {
          data: { data },
        } = await axios.get(route('api.ads.local.ads', query))

        this.searchLocal = data
      } else {
        this.searchLocal = []
      }
    },
    goBack() {
      return this.$inertia.visit(route('admin.advertise.list'))
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    isValidURL(text) {
      let elm
      if (text.trim() !== '') {
        if (!elm) {
          elm = document.createElement('input')
          elm.setAttribute('type', 'url')
        }
        elm.value = text
        return elm.validity.valid
      } else {
        return false
      }
    },
    generateVideoThumbnail(file) {
      return new Promise((resolve) => {
        const canvas = document.createElement('canvas')
        const video = document.createElement('video')

        video.autoplay = true
        video.muted = true
        video.src = URL.createObjectURL(file)

        video.onloadeddata = () => {
          let ctx = canvas.getContext('2d')

          canvas.width = video.videoWidth
          canvas.height = video.videoHeight

          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
          video.pause()
          return resolve(canvas.toDataURL('image/png'))
        }
      })
    },
    convertTimeStamp(dateTime) {
      let time = new Date(dateTime)
      if (time) {
        return Math.floor(time.getTime() / 1000)
      }
    },
    formartFormData(form) {
      let formData = new FormData()
      for (var key of Object.keys(form)) {
        formData.append(key, Object.keys(form))
      }
      return formData
    },
    formartDate() {
      this.untilDate =
        this.untilDate.toISOString().split('T')[0].replaceAll('-', '/') +
        ' ' +
        this.untilDate.getHours() +
        ':' +
        this.untilDate.getMinutes()
    },
    disabledEndDate(time) {
      let d = new Date(this.since)
      return time.getTime() < d.setDate(d.getDate() + 1)
    },
    disabledStartDate(time) {
      let d = new Date()
      return time.getTime() < d.setDate(d.getDate() - 1)
    },
    cusDatePickerStartText() {
      let datePicker = document.getElementsByClassName('cusDatePickerAdStartTime')

      let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0]
      const preYearNode = document.createTextNode('<<前年')
      btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0])
      btnPreYear.style.cssText += 'margin: 0 5px 0 0'

      let btnPreMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[1]
      const preMonthNode = document.createTextNode('<前月')
      btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0])
      btnPreMonth.style.cssText += 'margin: 0'

      let btnNextYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[4]
      const nextYearNode = document.createTextNode('翌年>>')
      btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0])
      btnNextYear.style.cssText += 'margin: 0 0 0 5px'

      let btnNextMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[5]
      const nextMonthNode = document.createTextNode('翌月>')
      btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0])
      btnNextMonth.style.cssText += 'margin: 0'
    },
    cusDatePickerEndText() {
      let datePicker = document.getElementsByClassName('cusDatePickerAdEndTime')

      let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0]
      const preYearNode = document.createTextNode('<<前年')
      btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0])
      btnPreYear.style.cssText += 'margin: 0 5px 0 0'

      let btnPreMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[1]
      const preMonthNode = document.createTextNode('<前月')
      btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0])
      btnPreMonth.style.cssText += 'margin: 0'

      let btnNextYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[4]
      const nextYearNode = document.createTextNode('翌年>>')
      btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0])
      btnNextYear.style.cssText += 'margin: 0 0 0 5px'

      let btnNextMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[5]
      const nextMonthNode = document.createTextNode('翌月>')
      btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0])
      btnNextMonth.style.cssText += 'margin: 0'
    },

    openPreview() {
      this.isOpenPreview = true
    },
    handleFileUpload() {
      this.form.file = this.$refs.file.files[0]
    },
    uploadFile: function () {
      this.$refs.inputFile.value = ''
      this.$refs.inputFile.click()
    },
    uploadNewFile: function () {
      this.$refs.inputFile.value = ''
      this.$refs.inputFile.click()
    },
    async getAccountAds() {
      const { data } = await axios.get(route('api.ads.account', this.userIdCurrent))
      if (data) {
        this.accountAds = data
        this.dialogVisible = false
      } else {
        this.dialogVisible = true
      }
    },

    async getCampainsAccount() {
      if (!this.accountAds) {
        return
      }
      const {
        data: { data },
      } = await axios.get(route('api.ads.campains.account'))
      return (this.options = data)
    },

    async handleSearchTarget() {
      if (!this.form.searchTarget || !this.accountAds) {
        return
      }

      const {
        data: { data },
      } = await axios.get(route('api.ads.target.account', this.form.searchTarget))
      this.dataTarget = data
      this.openSelectTarget = true
    },

    handleLoginInsta() {
      FB.login(
        async (response) => {
          if (!response.authResponse) {
            return
          }
          const {
            data: { data },
          } = await Ins.adsAccount(response.authResponse.accessToken)

          if (data.length != 0) {
            this.adsAccount = data.map((item) => ({
              userId: this.userIdCurrent,
              account_id: item.account_id,
              id: item.id,
              name: item.name,
              access_token: response.authResponse.accessToken,
            }))
            this.isShow = true
            this.dialogVisible = false
          } else {
            this.checkAccountAdsIns()
          }
          // FB.logout();
        },
        { scope: Ins.addPermissionAds() }
      )
    },
    checkAccountAdsIns() {
      return this.$toast.error(this.$t('bạn chưa có tài khoản ads và hãy thêm đủ quyền liên kết fb'))
    },
    checkValidateFileUpload() {
      if (this.fileUpload != '') {
        this.validateFileUpload = true
        return true
      } else {
        this.validateFileUpload = false
        return false
      }
    },
    handleSubmitSuccess() {
      this.getAccountAds()
      this.getCampainsAccount()
    },
    checkValidateCarousel() {
      if (this.form.modelFile == 2) {
        if (this.infoCarousel.length > 0) {
          let indexItemErr = this.infoCarousel.findIndex((item) => item.des == '' || item.link == '' || item.name == '')
          if (indexItemErr !== -1) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      } else {
        return true
      }
    },
    async dataUrlToFile(file, fileName) {
      let dataUrl = await this.generateVideoThumbnail(file)
      const res = await fetch(dataUrl)
      const blob = await res.blob()
      return new File([blob], fileName, { type: 'image/jpeg' })
    },
  },
}
