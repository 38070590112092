<template>
  <Loading v-if="isLoading" />
  <div class="pb-10 bg-background">
    <div class="flex justify-between items-center">
      <h2 class="text-title text-button_pink font-semibold py-5">PPTX出力設定画面</h2>
    </div>

    <div class="mx-auto">
      <div class="grid grid-cols-1 lg:grid-cols-12 gap-5 xl:gap-16">
        <!-- Perview -->
        <div class="py-3 border rounded-md shadow-gray-700 col-span-1 lg:col-span-6 mb-20 bg-white">
          <div class="relative">
            <div class="absolute right-2 lg:top-4 sm:top-1 flex justify-end items-center">
              <div class="flex items-center">
                <template v-if="urlCopyright == null">
                  <img :src="'/img/favicon-upscale.png'" alt="" class="w-20 h-auto" />
                </template>
                <template v-else>
                  <img :src="urlCopyright" alt="Logo" class="w-20 h-auto" />
                </template>
              </div>
            </div>
            <img :src="'/images/pptx-demo.png'" alt="" class="w-full h-auto" />
            <div class="md:absolute sm:relative bottom-0 left-1/2 translate-x-[-50%]">
              <template v-if="form.text_copyright == []">
                <p class="text-pink font-bold text-nomal text-center pt-2">2.Enter text copyright</p>
              </template>
              <template v-else>
                <p class="text-nomal text-center pt-2">{{ form.text_copyright }}</p>
              </template>
            </div>
          </div>
        </div>

        <!-- Form -->
        <div class="col-span-1 lg:col-span-6">
          <form @submit.prevent="submitForm" class="md:px-5 pb-5 flex flex-col flex-wrap">
            <div class="w-full pb-5">
              <p class="font-bold text-title text-red-500">※変更したいものは以下にご設定ください。</p>
            </div>
            <div class="pt-5 w-full">
              <div class="pb-4">
                <p class="text-nomal">1.Company Logo (copyright)</p>
              </div>
              <div class="pt-2 w-full">
                <button
                  v-if="urlCopyright == null"
                  @click="fileUploadCopyright()"
                  type="button"
                  class="text-white bg-button_profile px-8 py-2 text-xs rounded text-btn-submit"
                >
                  アップロード
                </button>
                <div v-else class="flex items-center">
                  <p class="pr">{{ nameFileCopyright }}</p>
                  <p class="px-5 text-button_pink underline cursor-pointer text-nomal" @click="fileUploadCopyright()">
                    ロゴを変更する
                  </p>
                  <div class="text-left text-red-600 text-sm mt-2">{{ form.errors.img_copyright ?? null }}</div>
                </div>
                <img v-if="urlCopyright" class="mt-3" :src="urlCopyright" style="width: 270px; height: 70px" />
              </div>
              <div class="text-left text-red-600 text-sm mt-2">{{ form.errors.img_copyright ?? null }}</div>
              <UploadImage
                field="img_copyright"
                @crop-success="cropSuccess"
                @crop-upload-success="cropUploadSuccess"
                @crop-upload-fail="cropUploadFail"
                v-model="showCropCopuright"
                :width="180"
                :height="50"
                langType="ja"
                img-format="png"
              ></UploadImage>
            </div>
            <div class="pt-5 w-full">
              <div class="pb-4">
                <p class="text-nomal">2.Text Copyright</p>
              </div>
              <input
                v-model="form.text_copyright"
                class="py-4 w-full border-gray-200 rounded-md"
                type="text"
                placeholder="2022 Insta Cheki"
              />
            </div>
            <!-- button submit -->
            <div class="flex justify-around md:gap-5 sm:gap-4 mt-10">
              <button
                :disabled="disapbleReset"
                style="background: #be3d8f"
                @click="resetData()"
                class="md:py-3 md:w-52 sm:w-1/2 text-white button-shadow rounded text-btn-submit"
                type="button"
              >
                リセット
              </button>
              <button
                class="md:py-3 bg-blue-600 md:w-52 sm:w-1/2 text-white button-shadow rounded text-btn-submit"
                type="submit"
              >
                変更
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DrashBoard from '@/Layouts/Authenticated.vue'
import { Link, useForm } from '@inertiajs/inertia-vue3'
import { ElMessageBox } from 'element-plus'
import Loading from '@/Components/Loadding'
import UploadImage from 'vue-image-crop-upload'

export default {
  components: {
    DrashBoard,
    Link,
    Loading,
    useForm,
    UploadImage,
  },
  data() {
    return {
      form: useForm({
        img_copyright: null,
        text_copyright: '',
      }),
      urlCopyright: null,
      nameFileCopyright: null,
      isLoading: false,
      showCropCopuright: false,
      disapbleReset: true,
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    async resetData() {
      ElMessageBox.confirm('設定したものを取り消してもよろしいでしょうか？', '警告', {
        confirmButtonText: '同意',
        cancelButtonText: 'キャンセル',
        type: 'warning',
      }).then(() => {
        axios.get(route('api.reset.pptx.setting')).then((res) => {
          if (res.data) {
            this.fetchData()
            this.form = useForm({
              text_copyright: '',
              img_copyright: null,
            })
            this.urlCopyright = null
            this.$toast.success(this.$t('Reset form pptx success'))
            this.disapbleReset = true
          }
        })
      })
    },

    async fetchData() {
      await axios
        .get(route('api.get-pptx-page-by-admin'))
        .then((res) => {
          if (Object.keys(res.data.data).length !== 0) {
            this.disapbleReset = false
            this.form = useForm(JSON.parse(JSON.stringify(res.data.data)))
            this.urlCopyright = this.form.img_copyright
            this.form.img_copyright = null
          }
        })
        .catch((err) => {})
    },

    fileUploadCopyright() {
      this.showCropCopuright = !this.showCropCopuright
      this.form.img_copyright = null
    },

    submitForm() {
      this.isLoading = true

      if (this.form.id) {
        const formData = new FormData()
        formData.append('user_id', this.form.user_id)
        formData.append('text_copyright', this.form.text_copyright)
        if (this.form.img_copyright !== null) {
          formData.append('img_copyright', this.form.img_copyright)
        }
        formData.append('_method', 'PUT')

        axios
          .post(route('api.api-pptx-page.update', this.form.id), formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((res) => {
            this.$toast.success('update form pptx successfully')
            this.fetchData()
            this.isLoading = false
          })
          .catch((err) => {
            console.log(JSON.parse(JSON.stringify(err.errors)))
            this.isLoading = false
          })
      } else {
        this.form.post(route('api.api-pptx-page.store'), {
          onFinish: () => {
            if (!this.form.hasErrors) {
              this.$toast.success(this.$t('create form pptx successfully'))
              this.fetchData()
              this.disapbleReset = false
              this.isLoading = false
            } else {
              this.isLoading = false
            }
          },
        })
      }
    },

    cropSuccess(imgDataUrl) {
      this.urlCopyright = imgDataUrl
      this.form.img_copyright = this.dataURLtoFile(imgDataUrl, 'img_copyright')
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },

    cropUploadSuccess(jsonData, field) {
      console.log('-------- upload success --------')
      if (field == 'img_logo') {
        console.log(jsonData, field)
      } else {
        console.log(jsonData, field)
      }
    },

    cropUploadFail(status, field) {
      console.log('-------- upload fail --------')
      if (field == 'img_logo') {
        console.log(status, field)
      } else {
        console.log(status, field)
      }
    },
  },
}
</script>

<style lang="scss">
.vicp-operate a:first-child {
  background: #be3d8f;
  color: white !important;
  &:hover {
    background: #be3d8f !important;
  }
}
.vicp-operate-btn {
  background: #5264cd !important;
  border-radius: 4px !important;
  color: white !important;
  &:hover {
    background: #5264cd !important;
  }
}
.block-title {
  display: flex;
  align-items: center;
  padding: 7px 37px;
  background: #e7289e;
  font-weight: bold;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  span {
    color: white;
  }
}
.el-button--primary {
  background-color: #5264cd !important;
  border: 1px solid #5264cd !important;
}

.el-button--primary span {
  color: white !important;
  @media (max-width: 640px) {
    .block-title {
      padding: 7px;
    }
    .vue-image-crop-upload .vicp-wrap {
      width: 90%;
    }
  }
  @media (max-width: 1340px) {
    .block-title {
      padding: 7px 20px;
    }
  }
}
</style>
