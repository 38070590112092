// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../uploads/iPhone-screen.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.bg-ip[data-v-a632a288] {\n  width: 283px;\n  height: 571px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.25));\n}\n", "",{"version":3,"sources":["webpack://./resources/js/Components/IphoneScreen.vue"],"names":[],"mappings":";AAwBA;EACE,YAAY;EACZ,aAAa;EACb,yDAA0D;EAC1D,qDAAqD;AACvD","sourcesContent":["<template>\n  <div :style=\"{paddingLeft : pl+'px'}\" class=\"flex flex-col pt-10 border-l-2 h-full\">\n    <button class=\"bg-blue-600 button-shadow rounded text-white py-3 mb-10\">\n      投稿のプレビュー\n    </button>\n    <div class=\"bg-ip bg-contain block mx-auto bg-no-repeat pt-9 px-4 pb-5\">\n      <img\n        class=\"w-full h-full object-cover rounded-4xl\"\n        src=\"/images/girl.png\"\n        alt=\"\"\n      />\n    </div>\n  </div>\n</template>\n\n<script>\nexport default {\n  props: [\"pl\"],\n  mounted() {\n  },\n};\n</script>\n\n<style scoped>\n.bg-ip {\n  width: 283px;\n  height: 571px;\n  background-image: url(\"@shared/uploads/iPhone-screen.svg\");\n  filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.25));\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
