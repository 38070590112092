<template>
  <dashboard>
    <div class="hash-card p-3 font-bold grid grid-row-4 gap-4 grid-rows-[50%]">
      <div class="box">
        <div class="text-lg text-pink">ハッシュタグサーチ</div>
        <div class="form-group mt-3 inline-flex flex-col">
          <form action="" class="flex">
            <input
              type="text"
              v-model="value"
              class="
                border border-solid border-select
                rounded-l
                box-border
                text-select
              "
              placeholder="候補キーワードを入力"
              @click="showSelect"
            />
            <div
              class="
                search
                py-1.5
                px-3.5
                flex
                items-center
                justify-center
                bg-pink
                rounded-r
              "
            >
              <img :src="'/images/search-line.svg'" alt="" />
            </div>
            <p>{{ value }}</p>
          </form>
          <div class="select shadow-select px-5 py-3 rounded" v-if="options">
            <ul v-for="product in products" :key="product.id">
              <li class="flex p-2 border-b border-solid justify-between">
                <div class="content text-item_link text-xs">
                  {{ product.name }}
                </div>
                <div class="price text-option text-xs">
                  {{ product.price }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="text-lg text-pink">おすすめタグ</div>
        <div class="propose grid gap 2 grid-cols-request mt-3">
          <div
            class="propose_request flex items-center pr-4"
            v-for="propose in proposes"
            :key="propose.id"
          >
            <div class="text-request font-medium mr-2">
              {{ propose.content }}
            </div>
            <div
              class="
                bg-white
                border border-solid border-select
                box-border
                inline-block
                rounded-full
                w-10
                h-10
                text-black
                flex
                items-center
                justify-center
              "
            >
              {{ propose.select }}
            </div>
          </div>
        </div>
      </div>
      <div class="box row-span-2 mt-4">
        <div class="text-lg text-pink">ハッシュタグ追加</div>
        <div class="form-group mt-4 inline-block bg-button rounded">
          <input
            type="text"
            class="rounded-l border border-solid border-select"
            ref="input"
            required
          />
          <button
            type="button"
            class="text-sm text-white font-semibold px-2"
            @click="addHashtag($refs.input)"
          >
            +Add hashtag
          </button>
        </div>
        <div class="mt-4 text-request font-medium relative">
          <span class="mr-4">#TEST</span>
          <span
            class="mr-4 cursor-pointer hover:text-red-500 hover:underline"
            data-tooltip-target="tooltip-default"
            v-for="(item, index) in hashTag"
            :key="index"
            @click="removeHashTag(item.id)"
          >
            #{{ item }}
            <div
              id="tooltip-default"
              role="tooltip"
              class="
                inline-block
                absolute
                invisible
                z-10
                py-2
                px-3
                -bottom-5
                text-sm
                font-bold
                text-white
                bg-pink
                rounded-lg
                shadow-sm
                opacity-0
                transition-opacity
                duration-300
                tooltip
                dark:bg-gray-700
              "
            >
              Xóa
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
          </span>
        </div>
      </div>
      <div class="box row-span-2">
        <div class="text-lg text-pink my-4">ハッシュタグジャンル設定</div>
        <div class="form-group inline-block mr-4">
          <label for="the_type" class="text-option text-sm font-normal mr-2"
            >ジャンル選択</label
          >
          <input
            type="text"
            id="the_type"
            class="border border-solid border-select"
          />
        </div>
        <div class="form-group inline-block mx-4 font-normal">
          <label for="specific" class="text-option text-sm mr-2"
            >ハッシュタグ決定</label
          >
          <input
            type="text"
            id="specific"
            class="border border-solid border-select"
          />
        </div>
        <button
          type="button"
          class="py-2.5 px-4 ml-4 bg-button text-white shadow-button"
        >
          よく使うハッシュタグに追加
        </button>
      </div>
    </div>
  </dashboard>
</template>

<script>
import Dashboard from "../Dashboard";
export default {
  name: "HashCard.vue",
  components: {
    Dashboard,

  },
  data() {
    return {
      value: "",
      hashTag: [],
      selected: "",
      options: false,
      suggestions: [],
      products: [
        {
          name: "#洋服",
          price: "4000",
        },
        {
          name: "#冬物収納. ",
          price: "5000",
        },
        {
          name: "#秋物新作.  ",
          price: "6000",
        },
        {
          name: "#シャツ.",
          price: "6000",
        },
      ],
      proposes: [
        {
          content: "#TEST",
          select: "A",
        },
        {
          content: "#アパレル",
          select: "B",
        },
        {
          content: "#シャツ",
          select: "C",
        },
        {
          content: "#プチプラ服",
          select: "D",
        },
        {
          content: "＃秋物新作",
          select: "E",
        },
      ],
    };
  },
  methods: {
    addHashtag(e) {
      this.hashTag.push(e.value);
      return e.value;
    },
    removeHashTag: function (index) {
      this.hashTag.splice(index, 1);
    },
    showSelect() {
      this.options = true;
      this.suggestions = [];

    },
  },

};
</script>

<style scoped>
span.mr-4.cursor-pointer.hover\:text-red-500.hover\:underline:hover
  div#tooltip-default {
  opacity: 1;
  visibility: visible;
}
</style>
