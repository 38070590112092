<template>
    <DrashBoard>
        <LinkInstagram v-if="!showLinkInstagram && !isExport" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li class="mr-4">
                    <Link :href="route('admin.hashtag-analysis.index')" class="font-normal breakcumb-active">
                    指定ハッシュタグ一覧
                    </Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5 active">指定ハッシュタグ一覧</li>
                </ul>
            </div>
        </template>
        <template v-if="!isExport" v-slot:manual>
            <UserManual :isShow="isShowManual" :content="arrContent" @closeManualPopup="toogleManual" />
        </template>
        <div class="bg-background" :class="{ 'pb-10': isExport, 'pb-20': !isExport }" v-loading="loading">
            <UserNav v-if="!isExport"></UserNav>
            <div class="statistic" :class="{ 'px-5 xl:px-10': !isExport, 'px-5 md:px-20': isExport }">
                <div v-if="!isExport" class="flex justify-between">
                    <div class="flex items-center md:relative">
                        <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                            <p class="text-button_pink font-semibold text-3sm">指定ハッシュタグ一覧</p>
                            <div class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer" @click="toogleManual">
                                <img :src="suggestIcon" />
                            </div>
                        </div>
                    </div>
                    <p v-if="!isExport" class="text-button_pink text-2sm justify-end hidden md:flex md:items-center">
                        {{ getNumberHashtagAvailable() }}
                    </p>
                </div>
                <div class="flex justify-start pb-3 items-center md:hidden">
                    <button v-if="!isExport" type="button" :disabled="disabledAdd()" @click.prevent="onOpenModalHashtag"
                        class="bg-blue-600 h-10 px-3 xl:px-5 rounded text-white ml-0 lg:ml-6 text-btn-submit whitespace-nowrap">
                        ＋新規作成
                    </button>
                    <p v-if="!isExport" class="text-button_pink ml-3 text-2sm justify-end flex items-center">
                        {{ getNumberHashtagAvailable() }}
                    </p>
                </div>
                <!-- Search -->
                <div class="hashtag-search-box block lg:flex flex-wrap justify-between gap-y-2 xl:gap-y-4 text-2sm">
                    <div v-if="!isExport"
                        class="flex lg:justify-start md:justify-between sm:justify-between pb-3 md:pb-0">
                        <el-input @keyup.enter="fetchData()" @change="fetchData()" placeholder="検索キーワードを入力してください"
                            type="text" v-model="filter.name" class="lg:!w-72 sm:!w-9/12" size="large" clearable />
                        <button type="button" @click.prevent="fetchData"
                            class="bg-blue-600 h-10 px-5 xl:px-7 rounded text-white ml-5 whitespace-nowrap text-btn-submit">
                            検索
                        </button>
                    </div>

                    <div
                        :class="{ 'block lg:flex': !isExport, 'w-full flex flex-col md:flex-row md:justify-between': isExport }">
                        <div v-if="isExport">
                            <div class="flex items-center md:relative">
                                <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                                    <p class="text-button_pink font-semibold text-3sm">指定ハッシュタグ一覧</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="isExport" class="flex flex-col md:flex-row">
                            <el-checkbox v-model="filter.isCheckOfAccount" @change="fetchData" size="large"
                                :false-label="0" :true-label="1" class="checkbox-comment">
                                <span class="text-2sm">自アカウントの投稿を含む</span>
                            </el-checkbox>
                            <div class="ml-0 lg:ml-5 mb-3 lg:mb-0">
                                <el-date-picker v-model="dateRange" :type="'daterange'" size="large" range-separator="-"
                                    start-placeholder="Start date" end-placeholder="End date"
                                    :disabled-date="disabledDate" :clearable="true"
                                    popper-class="customDatePickerHashtagElactics" />
                            </div>
                        </div>
                        <div v-else class="flex flex-col md:flex-row">
                            <div>
                                <el-checkbox v-model="filter.isCheckOfAccount" @change="fetchData" size="large"
                                    :false-label="0" :true-label="1" class="checkbox-comment">
                                    <span class="text-2sm">自アカウントの投稿を含む</span>
                                </el-checkbox>
                            </div>
                            <div class="ml-0 lg:ml-5 mb-3 lg:mb-0">
                                <el-date-picker v-model="dateRange" :type="'daterange'" size="large" range-separator="-"
                                    start-placeholder="Start date" end-placeholder="End date"
                                    :disabled-date="disabledDate" :clearable="false"
                                    popper-class="customDatePickerHashtagElactics" />
                            </div>
                        </div>

                        <button v-if="!isExport" type="button" :disabled="disabledAdd()"
                            @click.prevent="onOpenModalHashtag"
                            class="bg-blue-600 h-10 px-3 xl:px-5 rounded text-white ml-0 lg:ml-6 text-btn-submit whitespace-nowrap hidden md:block">
                            ＋新規作成
                        </button>
                    </div>
                </div>

                <!-- Table -->
                <div class="section-analysis-info mt-7">
                    <data-table :fields="fields" :items="dataHashtags?.data" disable-table-info footer-center
                        class="el-table-header__black mt-5">
                        <template #name="{ row }">
                            <a :href="`${URL_INSTAGRAM}/${row?.name?.replace('#', '')}`"
                                class="text-button_pink underline font-medium text-[13px] cursor-pointer"
                                target="_blank">
                                {{ row?.name }}
                            </a>
                        </template>

                        <template #posts_count="{ row }">
                            <p>{{ formatToInteger(sumArray(row?.analysis?.posts_count)) }}</p>
                        </template>

                        <template #likes_count="{ row }">
                            <p>{{ formatToInteger(sumArray(row?.analysis?.likes_count)) }}</p>
                        </template>

                        <template #comments_count="{ row }">
                            <p>{{ formatToInteger(sumArray(row?.analysis?.comments_count)) }}</p>
                        </template>

                        <template #top_media_count="{ row }">
                            <p>
                                {{ filter.isCheckOfAccount && !loading ? sumArray(row?.analysis?.top_media_count) : 0 }}
                            </p>
                        </template>

                        <!-- chart -->
                        <template #posts_change="{ row }">
                            <div class="flex py-3">
                                <Line :chart-options="chartOptions" :chart-data="getChartData(row?.analysis)"
                                    :width="400" :height="150" class="hashtag-analytic-chart" />

                                <div v-if="!isExport" class="w-1/5 flex flex-wrap items-center justify-center">
                                    <p class="text-white text-xs">
                                        <button type="button"
                                            @click="$inertia.visit(route('admin.hashtag-analysis.detail', row?.ig_hashtag_id))"
                                            class="btn bg-blue-600 px-4 py-2 rounded mb-3">
                                            閲覧
                                        </button>
                                        <button type="button" class="btn bg-button_pink px-4 py-2 rounded"
                                            @click.prevent="deleteHashtag(row?.id)">
                                            削除
                                        </button>
                                    </p>
                                </div>
                                <div v-else class="w-1/5 flex flex-wrap items-center justify-center">
                                    <p class="text-white text-xs">
                                        <button type="button" @click="goToElement(row)"
                                            class="btn bg-blue-600 px-4 py-2 rounded mb-3">
                                            閲覧
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </template>
                    </data-table>
                </div>

                <!-- dialog hashtag -->
                <el-dialog v-model="showModalHashtag" custom-class="dialog-hashtag p-4" width="40%"
                    :key="showModalHashtag" append-to-body>
                    <el-form ref="ruleFormRef" :model="form" label-position="left" label-width="96px"
                        @submit.prevent="">
                        <el-form-item label="名前" prop="hashtag_name"
                            :rules="[{ required: true, message: 'この項目は必須項目です' }]">
                            <el-input v-model="form.hashtag_name" placeholder="ハッシュタグを入力してください。" size="large" />
                            <div class="w-full mt-6 text-black font-medium text-3sm">
                                <p>１度に登録出来るハッシュタグの上限は１０つです。</p>
                                <p>ハッシュタグ登録後画像の収集まで最大４時間程掛かる場合はあります。</p>
                            </div>
                        </el-form-item>
                    </el-form>
                    <template #footer>
                        <div class="flex justify-center items-center text-white text-xl mt-3">
                            <button type="button"
                                class="button-shadow bg-blue-600 py-3 w-32 cursor-pointer rounded mr-3"
                                @click.prevent="showModalHashtag = false">
                                キャンセル
                            </button>
                            <button type="button" class="button-shadow bg-button_pink py-3 w-32 cursor-pointer rounded"
                                @click.prevent="addHashTag">
                                登録
                            </button>
                        </div>
                    </template>
                </el-dialog>
            </div>
        </div>
    </DrashBoard>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import DataTable from '@/Components/UI/DataTable.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import dayjs from 'dayjs'
import { ElMessageBox } from 'element-plus'

import { Line } from 'vue-chartjs'
import { CategoryScale } from 'chart.js'
import { Inertia } from '@inertiajs/inertia'
import UserManual from '@/Components/UserManual.vue'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LineController,
    LineElement,
    PointElement
)

export default {
    name: 'HashtagAnalysis',
    components: { DrashBoard, UserNav, DataTable, Link, BreadCrumb, LinkInstagram, Line, UserManual },
    emits: ['getDataHashtagsAnalysis', 'getDetailHashtag'],

    data() {
        const since = new Date(new Date().setDate(new Date().getDate() - 30))
        const until = new Date(new Date().setDate(new Date().getDate() - 1))

        return {
            loading: false,
            showLinkInstagram: true,
            URL_INSTAGRAM: 'https://www.instagram.com/explore/tags',
            filter: {
                page: 1,
                name: null,
                limit: 10,
                isCheckOfAccount: 1,
            },
            dateRange: [since, until],
            showModalHashtag: false,
            button_loading: true,
            form: {
                hashtag_name: null,
            },
            fields: [
                { key: 'name', label: 'ハッシュタグ', align: 'center', minWidth: 200 },
                { key: 'posts_count', label: '投稿数', align: 'center', width: 200 },
                { key: 'likes_count', label: '総いいね数', align: 'center', width: 200 },
                { key: 'comments_count', label: '総コメント数', align: 'center', width: 200 },
                {
                    key: 'top_media_count',
                    label: '人気投稿に掲載された自分の投稿',
                    align: 'center',
                    width: 200,
                    lableClassName: '!w-36',
                },
                { key: 'posts_change', label: '投稿数推移', align: 'center', width: 500 },
            ],
            dataHashtags: {},
            chartOptions: {
                responsive: true,
                scales: {
                    x: {
                        ticks: {
                            display: false, //this will remove only the label
                        },
                    },
                    y: {
                        grid: { display: true, borderDash: [2], borderDashOffset: 2 },
                    },
                },
                plugins: {
                    legend: false,
                    title: false,
                    datalabels: {
                        display: false,
                    },
                },
                layout: {
                    padding: {
                        left: 2,
                        right: 2,
                    },
                },
            },
            chartData: {
                datasets: [
                    {
                        data: [],
                        borderColor: '#5264CD',
                        backgroundColor: '#5264CD',
                        borderWidth: 3,
                        pointRadius: 3,
                        label: '投稿数',
                    },
                ],
                labels: [],
            },
            arrContent: ['あらかじめ設定したハッシュタグがInstagram上でどの程度「投稿数」「いいね数」「コメント数」があるかを確認することができます。', 'エンゲージメントを得やすいハッシュタグを分析し、ハッシュタグ選定にご利用いただけます。', 'また、アカウントが人気投稿に表示されたかを確認することもできるため、定点観測することが可能です。', '閲覧をクリックすることでさらに詳細な分析を行うことが可能です。'],
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowManual: false
        }
    },
    computed: {
        isExport() {
            return Inertia?.page?.url === '/export/report-preview'
        },
    },
    watch: {
        dateRange() {
            this.fetchData()
        },
    },
    created() {
        this.fetchData()
        this.checkLinkInstagram()
    },
    mounted() {
        this.customDatePickerText()
    },
    methods: {
        toogleManual() {
            this.isShowManual = !this.isShowManual
        },
        onOpenModalHashtag() {
            this.form.hashtag_name = ''
            this.showModalHashtag = true
        },
        onInstaSubmitSuccess() {
            this.showLinkInstagram = true
        },
        // fetch data
        async fetchData() {
            this.loading = true
            const startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
            const endDate = dayjs(this.dateRange[1]).format('YYYY-MM-DD')

            await axios
                .get(route('api.analysis-hashtag.index', { ...this.filter, startDate, endDate }))
                .then(({ status, data }) => {
                    this.dataHashtags = status === 200 ? data : {}
                    this.loading = false
                    this.viewDetail(this.dataHashtags.data[0])
                    this.$emit('getDataHashtagsAnalysis', this.dataHashtags.data)
                })
                .catch((e) => {
                    this.loading = false
                })
        },

        // link instagram
        async checkLinkInstagram() {
            const check = await accountAPI.checkInsAccount()
            if (check.data) {
                this.showLinkInstagram = true
            } else {
                this.showLinkInstagram = false
            }
        },
        disabledDate(date) {
            let d = new Date()
            return date > d.setDate(d.getDate() - 1)
        },
        handleCurrentPage(value) {
            this.filter.page = value
            this.fetchData()
        },
        addHashTag() {
            this.$refs.ruleFormRef.validate(async (valid) => {
                if (valid) {
                    this.loading = true
                    await axios
                        .post(route('api.analysis-hashtag.store'), this.form)
                        .then((response) => {
                            const status = response?.data.status

                            if (status === 200) {
                                this.$toast.success(response.data.message)
                            } else {
                                this.$toast.error(response.data.message)
                            }

                            this.showModalHashtag = false
                            this.loading = false
                            this.fetchData()
                        })
                        .catch(() => {
                            this.loading = false
                        })
                } else {
                    this.loading = false
                    return false
                }
            })
        },
        deleteHashtag(id) {
            if (!id) return

            ElMessageBox.confirm(this.$t('指定ハッシュタグを削除でよろしいでしょうか？'), '警告', {
                confirmButtonText: '同意',
                cancelButtonText: 'キャンセル',
                type: 'warning',
            }).then(async () => {
                await axios
                    .delete(route('api.hashtags.destroy', id))
                    .then((res) => {
                        this.$toast.success(this.$t('ハッシュタグの削除が完了しました。'))
                        this.fetchData()
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('something went wrong'))
                    })
            })
        },
        getChartData(analysis) {
            let chartData = this.chartData

            if (analysis && analysis?.posts_count?.length) {
                chartData = {
                    datasets: [
                        {
                            data: analysis?.posts_count,
                            borderColor: '#5264CD',
                            backgroundColor: '#5264CD',
                            borderWidth: 3,
                            pointRadius: 3,
                            label: '投稿数',
                        },
                    ],
                    labels: analysis?.date,
                }
            }

            return chartData
        },
        sumArray(arr) {
            if (!arr) return 0

            return arr.reduce((a, b) => a + b, 0)
        },
        getNumberHashtagAvailable() {
            const max = 10
            const hashtagTotal = this.dataHashtags?.data?.length ?? 0
            const result = max - hashtagTotal

            return '後' + result + '件登録できます'
        },
        disabledAdd() {
            const max = 10
            const hashtagTotal = this.dataHashtags?.data?.length ?? 0
            const result = max - hashtagTotal

            return result == 0 ? true : false
        },
        async goToElement(item) {
            document.getElementById('hashtag-detail-element').scrollIntoView()
            await this.viewDetail(item)
        },
        viewDetail(item) {
            this.$emit('getDetailHashtag', item)
        },
        customDatePickerText() {
            let datePicker = document.getElementsByClassName('customDatePickerHashtagElactics')

            let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[0]
            const preYearNode = document.createTextNode('<<前年')
            btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0])
            btnPreYear.style.cssText += 'margin: 0 5px 0 0'

            let btnPreMonth =
                datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[1]
            const preMonthNode = document.createTextNode('<前月')
            btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0])
            btnPreMonth.style.cssText += 'margin: 0'

            let btnNextYear =
                datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[2]
            const nextYearNode = document.createTextNode('翌年>>')
            btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0])
            btnNextYear.style.cssText += 'margin: 0 0 0 5px'

            let btnNextMonth =
                datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[3]
            const nextMonthNode = document.createTextNode('翌月>')
            btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0])
            btnNextMonth.style.cssText += 'margin: 0'
        },
    },
}
</script>
<style lang="scss">
.el-button--primary {
    background-color: #5264cd !important;
    border: 1px solid #5264cd !important;
}

.el-button--primary span {
    color: white !important;
}
</style>
<style lang="scss" scoped>
.text-pink {

    &:hover,
    p {
        color: #be3d8f !important;
        text-decoration: underline;
    }
}

.el-link.is-underline:hover:after {
    border-bottom: none !important;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    border-bottom: 0 !important;
}
</style>

<style>
.dialog-hashtag .el-dialog__header {
    padding: 0 !important;
    border-bottom: none !important;
    background: white !important;
}

.hashtag-search-box .el-date-editor {
    --el-date-editor-daterange-width: 250px;
    --el-date-editor-monthrange-width: 200px;
}

.checkbox-comment .el-checkbox__input.is-checked .el-checkbox__inner {
    background-image: url(/images/tick.svg?01fd452…);
    background-color: #be3d8f;
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
}

.checkbox-comment .el-checkbox__inner::after {
    border: 0 !important;
}

.checkbox-comment .el-radio__inner {
    border-radius: 0;
    padding: 9px;
}

.checkbox-comment .input-pl .el-input__inner {
    padding-left: 24px !important;
}

.checkbox-comment .el-radio__input.is-checked .el-radio__inner {
    background-image: url(/images/tick.svg?01fd452…);
    background-color: #be3d8f;
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
}

.checkbox-comment .el-radio__input.is-checked .el-radio__inner::after {
    display: none;
}

@media (max-width: 640px) {
    .dialog-hashtag .el-form-item__label {
        width: unset !important;
    }

    .el-message-box {
        max-width: 100% !important;
    }
}
</style>
