export const TERM_CONTENT_1 = [
  [
    'ユーザーネーム',
    'いいね！',
    'フォロー',
    'フォロワー',
    'フォロバ、フォローバック',
    'シェア',
    'ポスト(post)',
    'キャプション',
    'タグ付け',
    'ダイレクトメッセージ',
    'タイムライン、フィード',
    'インスタグラマー',
    'ストーリー、ストーリーズ(Stories)',
    'ハイライト、ストーリーズハイライト',
    'リール',
    'インスタライブ',
    'IGTV',
    'コレクション',
    'インサイト',
    'プロアカウント',
    'ショッピング機能',
    'ハッシュタグ',
    '場所を追加',
    '発見欄(虫眼鏡マーク)',
  ],
  [
    'ログイン時やアカウント検索時、URLなどに使用される名前のことです。',
    'ユーザーが”好意”や”共感”を感じた時に使う機能。♡のボタン”をタップすることで『いいね！』を付けることができます。',
    '他ユーザーの投稿を、自分のタイムラインで見れるようにする機能。',
    '自分をフォローしているユーザーのこと。フォロワー数が多いとより多くのユーザーに閲覧されます。',
    'フォローしてくれたユーザーに対し、フォローし返す行為のこと',
    '気に入った投稿を、他のユーザーに共有・拡散すること',
    'Instagramにおける投稿のこと',
    '投稿につける説明文（投稿文）のこと',
    '投稿した写真や動画、人物、物などに『ユーザーリンク』を付与する機能',
    '特定のユーザーに写真や動画、文章などのメッセージを送れる機能。DMと書くこともある',
    '自分の投稿”や”フォローしているアカウントの投稿””広告”が表示される場所',
    '多くのフォロワーを持つなど、他のユーザーに対する影響力を持った人',
    '24時間経つと自動で消える”、”フルスクリーンで投稿できる”という特徴を持つ一つの投稿形式',
    '24時間で消えてしまうストーリーを、プロフィール画面に表示し続けておける機能',
    'Instagramで縦長動画をUPする機能',
    'Instagramのライブ配信機能',
    '最大60分までの動画を投稿できるアプリで、『インスタ版YouTube』とも呼ばれる',
    '他のユーザーが投稿した写真や動画を”ブックマーク”して整理できる保存機能',
    '”フォロワー”の年齢層・性別などのデータや、プロフィールへのリーチ数など、アカウントの状態を把握できる機能',
    'ビジネスに利用したい時や、自分自身の作品を宣伝したい時に使うアカウントのこと',
    '投稿した画像に”商品情報をタグ付け”することで、ECサイト（ネットショップ）の商品ページへ直接誘導できる機能',
    'SNSなどで使われる”タグ”のこと。投稿をカテゴライズ（分類）して、検索に引っかかりやすくする効果がある',
    'Instagramの投稿に地点情報を付与できる機能',
    'アカウントごとにレコメンドされた投稿が表示される場所',
  ],
]
export const TERM_CONTENT_2 = [
  [
    '投稿のインプレッション数',
    '投稿のリーチ数',
    'いいね数',
    'コメント数',
    '保存数',
    'エンゲージメント数',
    'フィード投稿リーチ率',
    'エンゲージメント率',
    'いいね率',
    'コメント率',
    '保存率',
    'アカウントインプレッション数',
    'アカウントリーチ数',
    'プロフィールアクセス数',
    'ウェブサイトURLクリック数',
    'フォロワー増加数',
    'フォロワー減少数',
    'フォロワー純増減数',
    'プロフィールアクセス率',
    'ウェブサイトURLクリック率',
    'フォロー率',
    'フォロー解除率',
  ],
  [
    '投稿がユーザーの画面に表示された回数',
    '投稿を見た人の数',
    '投稿に寄せられたいいねの数',
    '投稿に寄せられたコメントの数',
    '投稿がInstagramのコレクション機能により保存された数',
    'いいね数＋コメント数＋保存数',
    'フィード投稿のリーチ数÷フォロワー数',
    '(いいね数＋コメント数＋保存数)÷インプレッション数',
    '投稿のいいね数÷インプレッション数',
    '投稿のコメント数÷インプレッション数',
    '投稿の保存数÷インプレッション数',
    'アカウントがユーザーの画面に表示された回数',
    'プロフィールを見たユーザーの数',
    'プロフィールへのアクセス数',
    'プロフィールに設定しているウェブサイトURLがクリックされた回数',
    'データ抽出期間初日から最終日までに増加したフォロワーの数',
    'データ抽出期間初日から最終日までに減少したフォロワーの数',
    'データ抽出期間初日から最終日までのフォロワー増減数',
    'プロフィールアクセス数÷リーチ数',
    'プロフィールURLクリック数÷プロフィールアクセス数',
    'フォロー増加数÷アカウントリーチ数',
    'フォロワー減少数÷フォロワー数',
  ],
]
export const TERMS_TITLE = [
  ['用語', '解説'],
  ['用語', '計算式・指標'],
]
export const SUB_MENU_TITLE = [
  'Instagramアカウントを分析する上で知っておくべき指標名や用語の説明をしています。',
  'レポート内で多く利用していますのでチェックしておきましょう。',
]
export const TITLE_SLIDE_2 = [
  'Instagramアカウントを分析する上で知っておくべき指標名や用語の説明をしています。',
  'レポート内で多く利用していますのでチェックしておきましょう。',
]
export const TITLE_SLIDE_5 = [
  '全体のサマリーデータを通してアカウント状況を確認しましょう。',
  '多くのユーザーに見られた投稿やハッシュタグ・コメントの傾向を確認しましょう。',
]
export const TITLE_SLIDE_13 = [
  'フィード投稿を通して、どのぐらいのユーザーに投稿が届いたのか',
  'エンゲージメントの高い投稿・低い投稿などを分析しましょう。',
]
export const TITLE_SLIDE_21 = [
  'ストーリーズ投稿を通して、どのぐらいのユーザーに投稿が届いたのか',
  'エンゲージメントの高い投稿・低い投稿などを分析しましょう。',
]
export const TITLE_SLIDE_25 = [
  'どのぐらいのユーザーにプロフィールを閲覧されたのか',
  'フォロワーの増減数を分析しましょう。',
]
export const TITLE_SLIDE_28 = [
  '定期ウォッチしているハッシュタグの投稿数・人気投稿や人気のない投稿などを分析しましょう。',
  '',
]
export const TITLE_SLIDE_33 = [
  '時間・曜日別のエンゲージメントを分析し、アカウントや投稿のエンゲージメントを獲得しやすい曜日・時間を分析しましょう。',
  '',
]

export const TABLE_SLIDE9_1 = [
  [{ options: { fill: 'E789C7', color: 'ffffff', align: 'center', valign: 'middle', fontSize: 10 } }],
  [{ options: { fill: 'E789C7', color: 'ffffff', align: 'center', valign: 'middle', fontSize: 10 } }],
  [{ options: { fill: 'E789C7', color: 'ffffff', align: 'center', valign: 'middle', fontSize: 10 } }],
  [{ options: { fill: 'E789C7', color: 'ffffff', align: 'center', valign: 'middle', fontSize: 10 } }],
]

export const DATA_CHART_SLIDE_10 = [
  {
    name: 'グループ内コメント評価の割合',
    labels: ['ポジティブ', 'ネガティブ', 'ベター'],
    values: [33.3, 33.3, 33.3],
  },
]
export const OPTION_CHART_SLIDE_10_COLOR = ['3e63a3', '002060', 'e7289e']
export const OPTION_CHART_SLIDE_10 = {
  x: 6.8,
  y: 2.8,
  w: 3.28,
  h: 3.28,
  chartArea: { fill: { color: '404040' } },
  chartColors: OPTION_CHART_SLIDE_10_COLOR,
  dataBorder: { pt: '1', color: 'F1F1F1' },
  showPercent: false,
}
export const TABLE_SLIDE_10_1 = [
  {
    text: 'アカウント名',
    options: { fill: 'dfdfdf', bold: true, color: '303030', align: 'center', valign: 'middle', fontSize: 12 },
  },
  {
    text: 'ユーザー名',
    options: { fill: 'dfdfdf', bold: true, color: '303030', align: 'center', valign: 'middle', fontSize: 12 },
  },
  {
    text: 'コメント日時',
    options: { fill: 'dfdfdf', bold: true, color: '303030', align: 'center', valign: 'middle', fontSize: 12 },
  },
  {
    text: 'ネガポジ',
    options: { fill: 'dfdfdf', bold: true, color: '303030', align: 'center', valign: 'middle', fontSize: 12 },
  },
]
export const TABLE_SLIDE_10_2 = [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ']

export const DATA_TEST_TABLE_CHART = [
  {
    number: {
      text1: 'text1text1text1text1text1',
      text2: 'text2text2text2text2text2text2text2text2',
      text3: 'text3',
      text4: 'text4',
      text5: 'text5',
    },
    chart: {
      labels: ['label1', 'label2', 'label3', 'label4', 'label5', 'label1', 'label2', 'label3', 'label4', 'label5'],
      values: [91, 62, 36, 45, 55, 91, 62, 36, 45, 55],
    },
  },
  {
    number: { text1: 'text1', text2: 'text2', text3: 'text3', text4: 'text4', text5: 'text5' },
    chart: {
      labels: ['label1', 'label2', 'label3', 'label4', 'label5', 'label1', 'label2', 'label3', 'label4', 'label5'],
      values: [91, 62, 36, 45, 55, 91, 62, 36, 45, 55],
    },
  },
  {
    number: { text1: 'text1', text2: 'text2', text3: 'text3', text4: 'text4', text5: 'text5' },
    chart: {
      labels: ['label1', 'label2', 'label3', 'label4', 'label5', 'label1', 'label2', 'label3', 'label4', 'label5'],
      values: [91, 62, 36, 45, 55, 91, 62, 36, 45, 55],
    },
  },
  {
    number: { text1: 'text1', text2: 'text2', text3: 'text3', text4: 'text4', text5: 'text5' },
    chart: {
      labels: ['label1', 'label2', 'label3', 'label4', 'label5', 'label1', 'label2', 'label3', 'label4', 'label5'],
      values: [91, 62, 36, 45, 55, 91, 62, 36, 45, 55],
    },
  },
  {
    number: { text1: 'text1', text2: 'text2', text3: 'text3', text4: 'text4', text5: 'text5' },
    chart: {
      labels: ['label1', 'label2', 'label3', 'label4', 'label5', 'label1', 'label2', 'label3', 'label4', 'label5'],
      values: [91, 62, 36, 45, 55, 91, 62, 36, 45, 55],
    },
  },
  {
    number: { text1: 'text1', text2: 'text2', text3: 'text3', text4: 'text4', text5: 'text5' },
    chart: {
      labels: [
        'label1',
        'label2',
        'label3',
        'label4',
        'label5',
        'label1',
        'label2',
        'label3',
        'label4',
        'label5',
        'label1',
        'label2',
        'label3',
        'label4',
        'label5',
        'label1',
        'label2',
        'label3',
        'label4',
        'label5',
        'label1',
        'label2',
        'label3',
        'label4',
        'label5',
        'label1',
        'label2',
        'label3',
        'label4',
        'label5',
      ],
      values: [
        91, 62, 36, 45, 55, 91, 62, 36, 45, 55, 91, 62, 36, 45, 55, 91, 62, 36, 45, 55, 91, 62, 36, 45, 55, 91, 62, 36,
        45, 55,
      ],
    },
  },
  // {
  //   number: {text1: 'text1', text1: 'text1', text1: 'text1', text1: 'text1', text1: 'text1'  },
  //   chartData: {labels:[1,2,3,4,5,6,7], data:[1,2,3,4,5,6,7], optsChartScat4 : { x: 7.0, y: 4.0, w: "45%", h: 3 }}
  // },
  // {
  //   number: {text1: 'text1', text1: 'text1', text1: 'text1', text1: 'text1', text1: 'text1'  },
  //   chartData: {labels:[1,2,3,4,5,6,7], data:[1,2,3,4,5,6,7], optsChartScat4 : { x: 7.0, y: 4.0, w: "45%", h: 3 }}
  // },
]
