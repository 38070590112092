import { formatToInteger } from '@/Plugins/numberFormat'
export default {
  methods: {
    genEngagementAnalysisPage(doc) {
      doc.setFontSize(14) // set font size
      doc.setTextColor(190, 61, 143)
      doc.text('時間/曜日別分析', 13, 30)
      doc.text('時間/曜日別分析', 13, 30)
      doc.text('時間/曜日別分析', 13, 30)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(10)
      if (this.analysisHourCurrTab === 'normal') {
        doc.text('通常投稿', 13, 40)
      } else {
        doc.text('リール投稿', 13, 40)
      }
      const margin = {
        left: 15,
        right: 15,
        top: 20,
        bottom: 20,
      }
      const printWidht = doc.internal.pageSize.width - (margin.left + margin.right)
      const storyCanvas = document.createElement('canvas')
      let sectionWidth = (printWidht - 15) / 4
      storyCanvas.width = sectionWidth * 3.779527559
      storyCanvas.height = 120

      let storyCanvasCtx = storyCanvas.getContext('2d')
      let x = 0
      let y = 0
      let widthStory = storyCanvas.width
      let heightStory = storyCanvas.height
      let radius = 10
      radius = { tl: radius, tr: radius, br: radius, bl: radius }
      storyCanvasCtx.beginPath()
      storyCanvasCtx.moveTo(x + radius.tl, y)
      storyCanvasCtx.lineTo(x + widthStory - radius.tr, y)
      storyCanvasCtx.quadraticCurveTo(x + widthStory, y, x + widthStory, y + radius.tr)
      storyCanvasCtx.lineTo(x + widthStory, y + heightStory - radius.br)
      storyCanvasCtx.quadraticCurveTo(x + widthStory, y + heightStory, x + widthStory - radius.br, y + heightStory)
      storyCanvasCtx.lineTo(x + radius.bl, y + heightStory)
      storyCanvasCtx.quadraticCurveTo(x, y + heightStory, x, y + heightStory - radius.bl)
      storyCanvasCtx.lineTo(x, y + radius.tl)
      storyCanvasCtx.quadraticCurveTo(x, y, x + radius.tl, y)
      storyCanvasCtx.closePath()
      storyCanvasCtx.fillStyle = '#f5f5f5'
      storyCanvasCtx.fill()
      let arrTitleFeed = [
        'インプレッション数',
        'リーチ数',
        '保存数',
        '動画再生数',
        'いいね数',
        'コメント数',
        'エンゲージメント率',
        '通常投稿数',
      ]
      let arrTitleReel = [
        'リーチ数',
        '保存数',
        '動画再生数',
        'いいね数',
        'コメント数',
        'エンゲージメント数',
        'リール投稿数',
      ]
      if (this.postCategory === 'normal') {
        let dataTotalMap = []
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumImpressions)))
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumReachs)))
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumSaved)))
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumVideoViews)))
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumLikes)))
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumComments)))
        dataTotalMap.push(String(this.postInformation?.sumEngagementRate))
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumPost)))
        let dataAvgMap = []
        dataAvgMap.push(String((this.postInformation?.sumImpressions / this.postInformation?.sumPost).toFixed(2)))
        dataAvgMap.push(String((this.postInformation?.sumReachs / this.postInformation?.sumPost).toFixed(2)))
        dataAvgMap.push(String((this.postInformation?.sumSaved / this.postInformation?.sumPost).toFixed(2)))
        dataAvgMap.push(String((this.postInformation?.sumVideoViews / this.postInformation?.sumPost).toFixed(2)))
        dataAvgMap.push(String((this.postInformation?.sumLikes / this.postInformation?.sumPost).toFixed(2)))
        dataAvgMap.push(String((this.postInformation?.sumComments / this.postInformation?.sumPost).toFixed(2)))
        arrTitleFeed.forEach((item, index) => {
          let textWidth = doc.getTextWidth(dataTotalMap[index])
          if (index < 4) {
            let textAvgWidth = doc.getTextWidth(dataAvgMap[index])
            doc.addImage(storyCanvas, 'PNG', index * (sectionWidth + 5) + 13, 45)
            doc.text(item, index * (sectionWidth + 5) + 15, 50)
            doc.text('合計', index * (sectionWidth + 5) + 15, 60)
            doc.text(dataTotalMap[index], index * (sectionWidth + 5) + (sectionWidth - (textWidth + 5)) + 15, 60)
            doc.text('投稿平均', index * (sectionWidth + 5) + 15, 74)
            doc.text(dataAvgMap[index], index * (sectionWidth + 5) + (sectionWidth - (textAvgWidth + 5)) + 15, 74)
          } else if (index < 6) {
            let textAvgWidth = doc.getTextWidth(dataAvgMap[index])
            doc.addImage(storyCanvas, 'PNG', (index - 4) * (sectionWidth + 5) + 13, 85)
            doc.text(item, (index - 4) * (sectionWidth + 5) + 15, 90)
            doc.text('合計', (index - 4) * (sectionWidth + 5) + 15, 100)
            doc.text(dataTotalMap[index], (index - 4) * (sectionWidth + 5) + (sectionWidth - (textWidth + 5)) + 15, 100)
            doc.text('投稿平均', (index - 4) * (sectionWidth + 5) + 15, 114)
            doc.text(
              dataAvgMap[index],
              (index - 4) * (sectionWidth + 5) + (sectionWidth - (textAvgWidth + 5)) + 15,
              114
            )
          } else if (index == 6) {
            doc.addImage(storyCanvas, 'PNG', (index - 4) * (sectionWidth + 5) + 13, 85)
            doc.text(item, (index - 4) * (sectionWidth + 5) + 15, 90)
            doc.text('投稿平均', (index - 4) * (sectionWidth + 5) + 15, 100)
            doc.text(dataTotalMap[index], (index - 4) * (sectionWidth + 5) + (sectionWidth - (textWidth + 5)) + 15, 100)
          } else if (index == 7) {
            doc.addImage(storyCanvas, 'PNG', (index - 4) * (sectionWidth + 5) + 13, 85)
            doc.text(item, (index - 4) * (sectionWidth + 5) + 15, 90)
            doc.text('合計', (index - 4) * (sectionWidth + 5) + 15, 100)
            doc.text(dataTotalMap[index], (index - 4) * (sectionWidth + 5) + (sectionWidth - (textWidth + 5)) + 15, 100)
          }
        })
      } else {
        let dataTotalMap = []
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumReachs)))
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumSaved)))
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumVideoViews)))
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumLikes)))
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumComments)))
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumEngagement)))
        dataTotalMap.push(String(formatToInteger(this.postInformation?.sumPost)))
        let dataAvgMap = []
        dataAvgMap.push(
          String(formatToInteger((this.postInformation?.sumReachs / this.postInformation?.sumPost).toFixed(2)))
        )
        dataAvgMap.push(
          String(formatToInteger((this.postInformation?.sumSaved / this.postInformation?.sumPost).toFixed(2)))
        )
        dataAvgMap.push(
          String(formatToInteger((this.postInformation?.sumVideoViews / this.postInformation?.sumPost).toFixed(2)))
        )
        dataAvgMap.push(
          String(formatToInteger((this.postInformation?.sumLikes / this.postInformation?.sumPost).toFixed(2)))
        )
        dataAvgMap.push(
          String(formatToInteger((this.postInformation?.sumComments / this.postInformation?.sumPost).toFixed(2)))
        )
        dataAvgMap.push(
          String(formatToInteger((this.postInformation?.sumEngagement / this.postInformation?.sumPost).toFixed(2)))
        )
        arrTitleReel.forEach((item, index) => {
          let textWidth = doc.getTextWidth(dataTotalMap[index])
          if (index < 4) {
            let textAvgWidth = doc.getTextWidth(dataAvgMap[index])
            doc.addImage(storyCanvas, 'PNG', index * (sectionWidth + 5) + 13, 45)
            doc.text(item, index * (sectionWidth + 5) + 15, 50)
            doc.text('合計', index * (sectionWidth + 5) + 15, 60)
            doc.text(dataTotalMap[index], index * (sectionWidth + 5) + (sectionWidth - (textWidth + 5)) + 15, 60)
            doc.text('投稿平均', index * (sectionWidth + 5) + 15, 74)
            doc.text(dataAvgMap[index], index * (sectionWidth + 5) + (sectionWidth - (textAvgWidth + 5)) + 15, 74)
          } else if (index < 6) {
            let textAvgWidth = doc.getTextWidth(dataAvgMap[index])
            doc.addImage(storyCanvas, 'PNG', (index - 4) * (sectionWidth + 5) + 13, 85)
            doc.text(item, (index - 4) * (sectionWidth + 5) + 15, 90)
            doc.text('合計', (index - 4) * (sectionWidth + 5) + 15, 100)
            doc.text(dataTotalMap[index], (index - 4) * (sectionWidth + 5) + (sectionWidth - (textWidth + 5)) + 15, 100)
            doc.text('投稿平均', (index - 4) * (sectionWidth + 5) + 15, 114)
            doc.text(
              dataAvgMap[index],
              (index - 4) * (sectionWidth + 5) + (sectionWidth - (textAvgWidth + 5)) + 15,
              114
            )
          } else if (index == 6) {
            doc.addImage(storyCanvas, 'PNG', (index - 4) * (sectionWidth + 5) + 13, 85)
            doc.text(item, (index - 4) * (sectionWidth + 5) + 15, 90)
            doc.text('投稿平均', (index - 4) * (sectionWidth + 5) + 15, 100)
            doc.text(dataTotalMap[index], (index - 4) * (sectionWidth + 5) + (sectionWidth - (textWidth + 5)) + 15, 100)
          }
        })
      }

      //---------------------------draw-chart---------------------------
      const pageWidth = doc.internal.pageSize.getWidth()
      const pageHeight = doc.internal.pageSize.getHeight()

      //---------------------------bar-chart---------------------------
      const hourlyBarChart = document.getElementById('engagement-analytic-chart-v1')
      const dailyBarChart = document.getElementById('engagement-analytic-chart-v2')
      const hourlyBarChartCanvas = hourlyBarChart ? hourlyBarChart.childNodes[0] : null
      const dailyBarChartCanvas = dailyBarChart ? dailyBarChart.childNodes[0] : null

      //2 acc chart
      doc.text('時間/曜日別エンゲージメント' + this.mapLabelTabTime(this.analysisCurrTabTime), 13, 127)
      let newCanvas = document.createElement('canvas')
      let width = 1536
      let height = 300

      newCanvas.width = width
      newCanvas.height = height
      let ctx = newCanvas.getContext('2d')
      doc.setFontSize(9) // set font size
      doc.text('時間別', 13, 135)
      doc.text('曜日別', 110, 135)
      doc.setFontSize(10) // set font size
      if (hourlyBarChartCanvas) {
        ctx.drawImage(hourlyBarChartCanvas, 0, 0, 750, 375)
      } else {
        doc.addImage(this.getEmptyImg(), 'PNG', 8, 127)
      }
      if (dailyBarChartCanvas) {
        ctx.drawImage(dailyBarChartCanvas, 786, 0, 750, 375)
      } else {
        doc.addImage(this.getEmptyImg(), 'PNG', 77, 127)
      }

      const newCanvasWidthRatio = (pageWidth / width) * 0.9
      const newCanvasHeightRatio = (pageHeight / height) * 0.9
      const newCanvasRatio = newCanvasWidthRatio > newCanvasHeightRatio ? newCanvasHeightRatio : newCanvasWidthRatio

      const newCanvasWidth = width * newCanvasRatio
      const newCanvasHeight = height * newCanvasRatio
      doc.addImage(newCanvas, 'PNG', 13, 140, newCanvasWidth, newCanvasHeight)

      // doc.addImage(this.drawTimeFrame(), 'PNG', 135, 0)

      // let accRangeStart = dayjs(this.accRange[0]).format('YYYY-MM-DD')
      // let accRangeStartFormat =
      //   accRangeStart.split('-')[0] + '年' + accRangeStart.split('-')[1] + '月' + accRangeStart.split('-')[2] + '日'
      // let accRangeEnd = dayjs(this.accRange[1]).format('YYYY-MM-DD')
      // let accRangeEndFormat =
      //   accRangeEnd.split('-')[0] + '年' + accRangeEnd.split('-')[1] + '月' + accRangeEnd.split('-')[2] + '日'
      // let textRangeWidth = doc.getTextWidth(accRangeStartFormat + '  /  ' + accRangeEndFormat)
      // doc.text(accRangeStartFormat + '  /  ' + accRangeEndFormat, 197 - textRangeWidth, 8, { baseline: 'center' })

      //---------------------------bubble-chart---------------------------
      const bubbleChart = document.getElementById('engagement-analytic-chart-v3')
      const bubbleChartCanvas = bubbleChart ? bubbleChart.childNodes[0] : null
      const bubbleChartCanvasWRatio = (pageWidth / 1536) * 0.9
      const bubbleChartCanvasHRatio = (pageHeight / 600) * 0.9
      const accRatio =
        bubbleChartCanvasWRatio > bubbleChartCanvasHRatio ? bubbleChartCanvasHRatio : bubbleChartCanvasWRatio
      const bubbleChartCanvasWidth = 1536 * accRatio
      const bubbleChartCanvasHeight = 600 * accRatio

      if (bubbleChartCanvas) {
        doc.text('時間/曜日別ヒートマップ' + this.mapLabelTabTime(this.analysisCurrTabHour), 13, newCanvasHeight + 150)
        doc.addImage(
          bubbleChartCanvas,
          'PNG',
          10,
          newCanvasHeight + 155,
          bubbleChartCanvasWidth,
          bubbleChartCanvasHeight
        )
      }
    },
    mapLabelTabTime(item) {
      switch (item) {
        case 'impressions':
          return '「インプレッション」'
          break
        case 'reach':
          return '「リーチ」'
          break
        case 'saved':
          return '「保存数」'
          break
        case 'video_views':
          return '「動画再生数」'
          break
        case 'like':
          return '「いいね」'
          break
        case 'comment':
          return '「コメント」'
          break
        case 'engagement_rate':
          return '「エンゲージメント率」'
          break
        default:
          return '「エンゲージメント数」'
      }
    },
  },
}
