<template>
  <dashboard>
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li class="mr-4">
          <Link :href="route('admin.notifications.index')" class="font-normal">お知らせ管理</Link>
        </li>
        <span class="font-normal text-notice_date mx-2">></span>
        <li class="mr-4">
          <Link :href="route('admin.notifications.create')" class="font-normal breakcumb-active">お知らせを作成</Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="mt-12 ml-8 tabs">
        <ul class="flex">
          <li class="px-2 relative pb-5 active text-13">お知らせを作成</li>
        </ul>
      </div>
    </template>
    <div class="body flex-grow-1 py-6 md:px-10 sm:px-2 bg-background min-h-screen max-h-full">
      <h2 class="text-14 text-button_pink font-semibold pt-5">お知らせを作成</h2>
      <form v-on:submit.prevent="submitForm">
        <div class="grid grid-cols-1 gap-4 py-8 notification-form">
          <div class="flex items-center justify-between w-full">
            <h1 class="md:w-60 sm:w-32 text-nomal">
              タイトル
              <span class="text-red-500">*</span>
            </h1>

            <div class="w-full" :class="{ 'failed-validate': errors.title?.first() }">
              <el-input v-model="form.title" class="w-full text-13" size="large" placeholder="タイトルを入力" />
              <span class="msg-error-validate text-nomal mt-1">{{ errors.title?.first() }}</span>
            </div>
          </div>

          <div class="flex items-center justify-between w-full">
            <h1 class="md:w-60 sm:w-32 text-nomal">
              ラベル
              <span class="text-red-500">*</span>
            </h1>

            <div class="w-full" :class="{ 'failed-validate': errors.label?.first() }">
              <el-select v-model="form.label" class="w-full text-13" placeholder="ラベルを選択" size="large">
                <el-option v-for="item in labels" :key="item" :label="item" :value="item" />
              </el-select>
              <span class="msg-error-validate text-nomal mt-1">{{ errors.label?.first() }}</span>
            </div>
          </div>

          <div class="flex justify-between w-full">
            <h1 class="md:w-60 sm:w-32 text-nomal">
              本文
              <span class="text-red-500">*</span>
            </h1>

            <div class="w-full" :class="{ 'failed-validate': errors.content?.first() }">
              <el-input
                v-model="form.content"
                :rows="6"
                type="textarea"
                placeholder="本文を入力"
                class="w-full text-13"
              />
              <span class="msg-error-validate text-nomal mt-1">{{ errors.content?.first() }}</span>
            </div>
          </div>

          <div class="flex items-center w-full md:flex-nowrap sm:flex-wrap md:gap-0 sm:gap-4 custom-datepicker">
            <h1 class="md:w-60 sm:w-full text-nomal">
              配信スケジュール
              <span class="text-red-500">*</span>
            </h1>

            <div class="flex w-full justify-start md:flex-nowrap sm:flex-wrap md:gap-0 sm:gap-4">
              <div class="flex items-center mr-8">
                <input
                  type="radio"
                  id="now"
                  v-model="form.type"
                  :value="0"
                  class="text-pink-400 mr-2 border-gray-200"
                />
                <label for="now" class="whitespace-nowrap text-13">即時配信</label>
              </div>

              <div class="flex items-center mr-8">
                <input
                  type="radio"
                  id="schedule"
                  :value="1"
                  v-model="form.type"
                  class="text-pink-400 mr-2 border-gray-200"
                />

                <label for="schedule" class="whitespace-nowrap text-13">予約配信</label>
              </div>

              <div v-show="form.type === 1" :class="{ 'failed-validate': errors.date?.first() }">
                <el-date-picker
                  v-model="form.date"
                  type="datetime"
                  size="large"
                  format="YYYY-MM-DD HH:mm"
                  :disabled-date="disabledDate"
                  placeholder="日時入力"
                  popper-class="cusDatePicker"
                  :clearable="false"
                />
                <div class="text-left text-[12px] text-red-600 mt-2" v-if="validateSchedule">
                  {{ messageSchedule }}
                </div>
              </div>
            </div>
          </div>

          <div class="flex md:flex-row sm:flex-col md:items-center w-full mt-5">
            <h1 class="text-nomal w-60">
              配信権限
              <span class="text-red-500">*</span>
            </h1>
            <div class="w-full">
              <div
                class="md:flex sm:grid grid-cols-2 justify-between md:gap-y-2 sm:gap-4 md:mt-2 sm:mt-4 flex flex-wrap"
              >
                <div class="flex items-center float-left lg:w-1/5 md:w-4/12 sm:w-1/2 roles-item">
                  <input
                    type="checkbox"
                    id="role0"
                    v-model="form.role"
                    :value="0"
                    class="rounded-sm text-pink-400 mr-2 border-gray-200"
                    @click="handleCheckAllRole"
                  />
                  <label for="role0" class="text-13">全て</label>
                </div>
                <div
                  v-for="role in roles"
                  :key="role.id"
                  class="roles-item flex items-center float-left lg:w-1/5 md:w-4/12 sm:w-1/2"
                >
                  <input
                    type="checkbox"
                    :id="'role' + role.id"
                    v-model="form.role"
                    :value="role.id"
                    class="rounded-sm text-pink-400 mr-2 border-gray-200"
                    @change="handleCheckRole"
                  />
                  <label class="text-13" :for="'role' + role.id">{{ role.name }}</label>
                </div>
              </div>
              <div class="block" :class="{ 'failed-validate': errors.role?.first() }">
                <span class="msg-error-validate text-nomal mt-1">{{ errors.role?.first() }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-center my-12 w-full gap-6">
          <button
            class="text-14 md:py-5.5 sm:py-3 bg-button_pink w-72 px-10 text-white button-shadow rounded"
            @click.prevent="goBack"
          >
            取り消し
          </button>
          <button class="text-14 md:py-5.5 sm:py-3 bg-button_profile text-white w-72 px-10 rounded-sm button-shadow">
            配信する
          </button>
        </div>
      </form>
    </div>
  </dashboard>
</template>

<script>
import Dashboard from '@/Layouts/Authenticated.vue'
import { getListRole } from '@/Api/Role'
import _ from 'lodash'
import { Back } from '@element-plus/icons-vue'
import { Link } from '@inertiajs/inertia-vue3'
import BreadCrumb from '@/Components/Breadcrumb.vue'
export default {
  name: 'Create',
  components: {
    Dashboard,
    Back,
    BreadCrumb,
    Link,
  },

  created() {
    this.getListRole()
    this.getHourCurrent()
  },
  mounted() {
    this.cusDatePickerText()
  },
  data() {
    return {
      isAllowSubmit: true,
      roles: [],
      normalDate: '',
      form: {
        role: [],
        type: 0,
        label: '',
        date: new Date(),
        title: '',
        content: '',
      },
      dateRaw: new Date(),
      errors: [],
      labels: ['お知らせ', '不具合', 'アップデート', '最新情報', 'アンケート'],
      validHours: null,
      validateSchedule: false,
    }
  },
  computed: {
    rolesId() {
      return this.roles.map((item) => item.id)
    },
  },
  methods: {
    goBack() {
      return this.$inertia.visit(route('admin.notifications.index'))
    },
    getHourCurrent() {
      const data = new Date()
      return (this.validHours = data.getHours())
    },
    disabledDate(time) {
      let d = new Date()
      return time.getTime() < d.setDate(d.getDate() - 1)
    },
    getListRole() {
      getListRole().then((res) => {
        this.roles = res.data
      })
    },
    submitForm() {
      if (this.form.type != 1) {
        this.form.date = null
      } else {
        const dateNow = new Date()
        dateNow.setMinutes(dateNow.getMinutes() + 29)
        this.dateRaw = this.form.date
        let formatDateNow = new Date(dateNow).getTime()
        let timeSchedule = new Date(this.date).getTime()
        if (timeSchedule < formatDateNow) {
          this.validateSchedule = true
          return this.errorSchedule(dateNow)
        }

        if (this.validateSchedule == true) {
          return
        }
        this.form.date = this.convertTime(this.form.date)
      }
      if (!this.isAllowSubmit) return
      this.isAllowSubmit = false
      axios
        .post(route('api.delivery_notices.store'), this.form)
        .then((res) => {
          if (res?.data?.status === false) {
            this.isAllowSubmit = true
            this.$toast.error(this.$t('something went wrong'))
          } else {
            this.$toast.success(this.$t('create notification successfully'))
            this.$inertia.visit(route('admin.notifications.index'))
          }
        })
        .catch((error) => {
          this.isAllowSubmit = true
          const { data, status } = error.response

          if (status === 422) {
            this.errors = data
            this.form.date = this.dateRaw
          }
        })
    },

    convertTime(data) {
      const moth = data.getMonth() + 1
      let date = ''
      if (moth < 10) {
        date = data.getFullYear() + '-' + '0' + moth + '-' + data.getDate()
      } else {
        date = data.getFullYear() + '-' + moth + '-' + data.getDate()
      }
      const time = data.getHours() + ':' + data.getMinutes()
      const dateTime = date + ' ' + time
      return dateTime
    },

    errorSchedule(date) {
      const dateFormat = this.formatDateJA(date)
      this.messageSchedule = dateFormat + 'より後の日付を指定してください。'
    },
    formatDateJA(date) {
      if (date) {
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hour = date.getHours()
        let minute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()

        return `${year}年${month}月${day}日 ${hour}:${minute}`
      }
    },

    handleCheckAllRole(e) {
      if (e.target.checked) {
        this.form.role = [0, ...this.rolesId]
      } else {
        this.form.role = []
      }
    },
    handleCheckRole(e) {
      if (!e.target.checked) {
        this.form.role = this.form.role.filter((item) => item !== 0)
      } else if (_.isEqual(this.form.role.sort(), this.rolesId.sort())) {
        this.form.role = [0, ...this.rolesId]
      }
    },
    cusDatePickerText() {
      let datePicker = document.getElementsByClassName('cusDatePicker')

      let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0]
      const preYearNode = document.createTextNode('<<前年')
      btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0])
      btnPreYear.style.cssText += 'margin: 0 5px 0 0'

      let btnPreMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[1]
      const preMonthNode = document.createTextNode('<前月')
      btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0])
      btnPreMonth.style.cssText += 'margin: 0'

      let btnNextYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[4]
      const nextYearNode = document.createTextNode('翌年>>')
      btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0])
      btnNextYear.style.cssText += 'margin: 0 0 0 5px'

      let btnNextMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[5]
      const nextMonthNode = document.createTextNode('翌月>')
      btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0])
      btnNextMonth.style.cssText += 'margin: 0'
    },
  },
  watch: {
    'form.type'(value) {
      if (value == 1) {
        const date = new Date()
        date.setMinutes(date.getMinutes() + 30)
        return (this.form.date = date)
      }
    },
    'form.title'(value) {
      if (value.length != 0) {
        this.errors.title = []
      }
    },
    'form.label'(value) {
      if (value.length != 0) {
        this.errors.label = []
      }
    },
    'form.content'(value) {
      if (value.length != 0) {
        this.errors.content = []
      }
    },
    'form.role'(value) {
      if (value.length != 0) {
        this.errors.role = []
      }
    },
    'form.date'(value) {
      const date = new Date()
      date.setMinutes(date.getMinutes() + 30)
      if (value < date) {
        this.validateSchedule = true
        this.errorSchedule(date)
      } else {
        this.validateSchedule = false
      }
    },
  },
}
</script>
<style lang="scss">
.notification-form {
  .el-input__inner {
    height: 48px;
    color: #000;
    font-size: 16px;
  }
  .el-textarea__inner {
    color: #000;
    padding-top: 20px;
    font-size: 16px;
  }
}
.custom-datepicker {
  .el-input__inner {
    padding-left: 35px !important;
  }
}

.text-13 {
  font-size: 13px !important;
}
.text-14 {
  font-size: 14px !important;
}
@media (min-width: 1440px) {
  .roles-item {
    width: unset !important;
  }
}
</style>
