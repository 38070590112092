import { EMPTY_IMG } from '../Mixins/media.js'

export default {
  methods: {
    genSlide27Enterprise(pptx, dataFollower, text, title) {
      let slide27 = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slide27.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      const initialValue = 0
      let selectedMonthFollower = dataFollower.sumFollower.selectedMonth.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        initialValue
      )
      let lastMonthFollower = dataFollower.sumFollower.lastMonth.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        initialValue
      )

      let followersUp =
        selectedMonthFollower > lastMonthFollower ? String(selectedMonthFollower - lastMonthFollower) : '0'
      let followersDown =
        selectedMonthFollower < lastMonthFollower ? '-' + String(lastMonthFollower - selectedMonthFollower) : '0'
      let followersChange =
        selectedMonthFollower > lastMonthFollower
          ? '+' + String(selectedMonthFollower - lastMonthFollower)
          : '-' + String(lastMonthFollower - selectedMonthFollower)

      slide27.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 6.05,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 22,
      })

      slide27.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.45,
        y: 1.42,
        w: 2.71,
        h: 4.37,
        rectRadius: 0.1,
        fill: { color: 'f8f8f8' },
      })

      slide27.addText('フォロワー増減情報', {
        x: 0.5,
        y: 1.6,
        w: 1.61,
        h: 0.29,
        align: 'left',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 10,
      })

      slide27.addText('合計増加フォロワー数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 0.77,
        y: 2.21,
        w: 1.98,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 10,
      })

      slide27.addText(selectedMonthFollower, {
        x: 0.27,
        y: 2.58,
        w: 3.0,
        h: 0.41,
        align: 'center',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 19,
      })

      slide27.addText(followersUp === '0' ? '(0)' : '( +' + followersUp + ' )', {
        x: 0.8,
        y: 2.9,
        w: 1.96,
        h: 0.26,
        align: 'center',
        valign: 'top',
        color: '1f79f2',
        fontSize: 10,
      })

      slide27.addText('合計フォロー解除数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 0.77,
        y: 3.26,
        w: 1.98,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 10,
      })

      slide27.addText(selectedMonthFollower, {
        x: 0.27,
        y: 3.69,
        w: 3.0,
        h: 0.41,
        align: 'center',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 19,
      })

      slide27.addText(followersUp === '0' ? '(0)' : '( ' + followersDown + ')', {
        x: 0.8,
        y: 4.01,
        w: 1.96,
        h: 0.26,
        align: 'center',
        valign: 'top',
        color: 'DC3545',
        fontSize: 10,
      })

      slide27.addText('合計行動数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 0.77,
        y: 4.39,
        w: 1.98,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 10,
      })

      slide27.addText(selectedMonthFollower, {
        x: 0.27,
        y: 4.9,
        w: 3.0,
        h: 0.41,
        align: 'center',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 19,
      })

      slide27.addText(followersChange === '+0' || followersChange === '-0' ? '' : '( ' + followersChange + ')', {
        x: 0.8,
        y: 5.22,
        w: 1.96,
        h: 0.26,
        align: 'center',
        valign: 'top',
        color: followersChange >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 10,
      })

      // chart
      let opts = {
        x: 3.44,
        y: 1.25,
        w: 7.02,
        h: 4.58,
        barDir: 'col',
        barGrouping: 'clustered',
        catAxisLabelColor: '787878',
        catAxisLabelFontSize: 12,
        catAxisOrientation: 'minMax',
        showTitle: true,
        title: 'フォロワーの増減数',
        titleColor: '787878',
        titleFontSize: 18.6,
        showLegend: true,
        legendPos: 'b',
        chartColors: ['e7299e', '1F3151'],
        valAxes: [
          {
            showValAxisTitle: false,
            valAxisTitle: 'Primary Value Axis',
          },
          {
            showValAxisTitle: false,
            valAxisTitle: 'Secondary Value Axis',
            catAxisOrientation: 'maxMin',
            valGridLine: { style: 'none' },
          },
        ],
        catAxes: [
          {
            catAxisTitle: 'Primary Category Axis',
          },
          {
            catAxisHidden: true,
          },
        ],
        // valAxisMaxVal: 16000,
        // valAxisMajorUnit: 2000,
      }
      if (
        dataFollower.followerSelectedMonth.checkFollowerStartDate &&
        dataFollower.followerSelectedMonth.checkFollowerEndDate &&
        dataFollower.followerSelectedMonth.checkFollowerChange
      ) {
        slide27.addImage({ path: EMPTY_IMG, x: 5.14, y: 1.8, w: 3.56, h: 3.56 })
      } else {
        let labels = dataFollower.accountNameIns
        let followerStartDate = dataFollower.followerSelectedMonth.followerStartDate
        let followerEndDate = dataFollower.followerSelectedMonth.followerEndDate
        let followerChange = dataFollower.followerSelectedMonth.followerChange

        let chartTypes = [
          {
            type: pptx.charts.BAR,
            data: [
              {
                name: '月初のフォロワー数',
                labels: labels,
                values: followerStartDate,
              },
              {
                name: '最終取得日のフォロワー数',
                labels: labels,
                values: followerEndDate,
              },
            ],
          },
          {
            type: pptx.charts.LINE,
            data: [
              {
                name: 'フォロワーの増減数',
                labels: labels,
                values: followerChange,
              },
            ],
            options: {
              chartColors: ['C2C2C2'],
              lineDataSymbol: 'none',
              secondaryValAxis: !!opts.valAxes,
              secondaryCatAxis: !!opts.catAxes,
            },
          },
        ]
        slide27.addChart(chartTypes, opts)
        // end chart
      }

      slide27.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.29,
        w: 10.27,
        h: 1.56,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slide27
    },
  },
}
