export default {
  methods: {
    async dataUrlToFile(file, fileName) {
      let dataUrl = await this.generateVideoThumbnail(file)
      const res = await fetch(dataUrl)
      const blob = await res.blob()
      return new File([blob], fileName, { type: 'image/jpeg' })
    },
    async createFile(link) {
      let dataUrl = ''
      let is_video = this.checkLink(link)
      if (is_video) {
        let response = await fetch(link)
        let data = await response.blob()
        let metadata = {
          type: 'image/jpeg',
        }
        let file = new File([data], 'test.jpeg', metadata)
        dataUrl = await this.generateVideoThumbnailExport(file)
        const res = await fetch(dataUrl)
        return res.url
      } else {
        return link
      }
    },
    async getBaseCodeImg(link) {
      let dataUrl = await this.imgToDataUrl(link)
      const res = await fetch(dataUrl)
      return res.url
    },
    checkLink(linkS3) {
      if (linkS3 == null) {
        return false
      }
      let arr = linkS3.split('.')
      const typeVieo = ['mp4', 'm4v', 'avi', 'mov', 'mpg', 'mpeg', 'wmv', 'avi', 'mpeg-2']
      return typeVieo.includes(arr[arr.length - 1].split('?')[0])
    },
    generateVideoThumbnail(file) {
      return new Promise((resolve) => {
        const canvas = document.createElement('canvas')
        const video = document.createElement('video')

        video.autoplay = true
        video.muted = true
        video.src = URL.createObjectURL(file)

        video.onloadeddata = () => {
          let ctx = canvas.getContext('2d')

          canvas.width = video.videoWidth
          canvas.height = video.videoHeight

          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
          video.pause()
          return resolve(canvas.toDataURL('image/png'))
        }
      })
    },
    generateVideoThumbnailExport(file) {
      return new Promise((resolve) => {
        const canvas = document.createElement('canvas')
        const video = document.createElement('video')

        video.autoplay = true
        video.currentTime = 2
        video.muted = true
        video.src = URL.createObjectURL(file)

        video.onloadeddata = () => {
          let ctx = canvas.getContext('2d')

          canvas.width = video.videoWidth
          canvas.height = video.videoHeight

          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
          video.pause()
          return resolve(canvas.toDataURL('image/jpeg'))
        }
      })
    },
    imgToDataUrl(url) {
      return new Promise(async (resolve) => {
        const data = await fetch(url)
        const blob = await data.blob()
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
      })
    },
    getHexCode(src) {
      return new Promise((resolve) => {
        let imgEl = new Image()
        imgEl.onload = function () {
          let blockSize = 5, // only visit every 5 pixels
            defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
            canvas = document.createElement('canvas'),
            context = canvas.getContext('2d'),
            data,
            width,
            height,
            i = -4,
            length,
            rgb = { r: 0, g: 0, b: 0 },
            count = 0
          if (!context) {
            return defaultRGB
          }
          height = canvas.height = this.naturalHeight || this.offsetHeight || this.height
          width = canvas.width = this.naturalWidth || this.offsetWidth || this.width

          context.drawImage(this, 0, 0)

          try {
            data = context.getImageData(0, 0, width, height)
          } catch (e) {
            /* security error, img on diff domain */
            console.log(e)

            return defaultRGB
          }

          length = data.data.length

          while ((i += blockSize * 4) < length) {
            ++count
            rgb.r += data.data[i]
            rgb.g += data.data[i + 1]
            rgb.b += data.data[i + 2]
          }

          // ~~ used to floor values
          rgb.r = ~~(rgb.r / count)
          rgb.g = ~~(rgb.g / count)
          rgb.b = ~~(rgb.b / count)
          // ~~ convert RGB to Hex
          const rgbToHex = (r, g, b) =>
            '#' +
            [r, g, b]
              .map((x) => {
                const hex = x.toString(16)
                return hex.length === 1 ? '0' + hex : hex
              })
              .join('')

          return resolve(rgbToHex(rgb.r, rgb.g, rgb.b))
        }
        imgEl.src = src
      })
    },
  },
}
