<template>
  <DrashBoard>
    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-6 relative pb-5 active">投稿</li>
          <li class="px-6 relative pb-5">投稿済一覧</li>
          <li class="px-6 relative pb-5">予約投稿一覧</li>
          <li class="px-6 relative pb-5">下書き保存一覧</li>
        </ul>
      </div>
    </template>
    <div class="px-14.5 pb-20 bg-background">
      <div class="post-form">
        <h2 class="title-md pt-14 pb-12.5">投稿</h2>
        <div class="flex items-center pb-9 pt-7 border-gray-200">
          <label class="basis-1-10" for="投稿タイプ">グループ</label>
          <input class="flex-1 leading-loose" type="text" name="通常投稿" />
        </div>
        <div class="flex items-center border-b pb-9 border-gray-200">
          <label class="basis-1-10" for="投稿タイプ">投稿タイプ</label>
          <form class="flex items-center flex-1">
            <input type="checkbox" name="通常投稿" />
            <label class="mr-14" for="通常投稿">即時投稿</label>
            <input type="checkbox" name="通常投稿" />
            <label class="mr-16" for="通常投稿">ストーリーズ投稿</label>
            <input type="checkbox" name="通常投稿" />
            <label for="通常投稿">リール投稿</label>
          </form>
        </div>
        <div
          class="
            flex
            items-center
            border-b
            pb-16.5
            pt-7
            border-gray-200
            relative
          "
        >
          <label class="basis-1-10" for="投稿タイプ">投稿ステータス</label>
          <form class="flex items-center">
            <input type="checkbox" name="通常投稿" />
            <label class="mr-14" for="通常投稿">通常投稿</label>
            <input type="checkbox" name="通常投稿" />
            <label class="mr-6" for="通常投稿">予約投稿</label>
            <div class="absolute bottom-8.5">
              <input type="checkbox" name="通常投稿" />
              <label class="mr-14" for="通常投稿">下書き保存</label>
            </div>
            <div class="post-time">
              <input class="w-24.5" type="text" name="年" placeholder="2022" />
              <label for="年">年</label>
              <input class="w-16.5" type="text" name="年" placeholder="12" />
              <label for="年">月</label>
              <input class="w-16.5" type="text" name="年" placeholder="31" />
              <label class="mr-6" for="年">日</label>
              <input
                class="w-16.5 ml-6"
                type="text"
                name="年"
                placeholder="22"
              />
              <label for="年">時</label>
              <input class="w-16.5" type="text" name="年" placeholder="10" />
              <label for="年">分</label>
            </div>
          </form>
        </div>
        <div class="flex items-center border-b pb-9 pt-7 border-gray-200">
          <label class="basis-1-10" for="投稿タイプ">投稿ステータス</label>
          <div class="flex items-center">
            <img
              :class="[!is_selected ? ['w-81', 'mr-7'] : '']"
              :src="imgSelected"
              alt=""
            />
            <input
              ref="inputImageUpload"
              @change="onFileChange"
              class="opacity-0 absolute -z-10"
              type="file"
              name=""
            />
            <button
              v-if="is_selected"
              class="
                text-white text-xs
                button-shadow
                bg-blue-600
                py-2
                w-44 text-center
                hover:cursor-pointer
                rounded
              "
              @click="this.$refs.inputImageUpload.click()"
            >
              アップロード
            </button>
            <span
              v-if="!is_selected"
              @click="this.$refs.inputImageUpload.click()"
              class="underline"
              >クリエイティブを変更する</span
            >
          </div>
        </div>
        <div class="flex items-start border-b pb-9 pt-7 border-gray-200">
          <label class="basis-1-10 mt-6" for="投稿タイプ">本文</label>
          <input class="flex-1 leading-max" type="text" name="通常投稿" />
        </div>
        <div class="flex items-center border-b pb-9 pt-7 border-gray-200">
          <label class="basis-1-10" for="投稿タイプ">ハッシュタグサーチ</label>
          <input class="flex-1 leading-loose" type="text" name="通常投稿" />
        </div>
        <div class="flex items-center border-b pb-9 pt-7 border-gray-200">
          <label class="mr-10" for="投稿タイプ"
            >アカウント毎にハッシュタグを変更する</label
          >
          <input type="checkbox" name="通常投稿" />
          <label class="ml-3.5 mr-13" for="通常投稿">Yes</label>
          <input type="checkbox" name="通常投稿" />
          <label class="ml-3.5" for="通常投稿">No</label>
        </div>
        <div class="flex items-center border-b pb-9 pt-7 border-gray-200">
          <label class="basis-1-10" for="投稿タイプ">タグ付け</label>
          <input class="flex-1 leading-loose" type="text" name="通常投稿" />
        </div>
        <div class="flex items-center border-b pb-9 pt-7 border-gray-200">
          <label class="basis-1-10" for="投稿タイプ">場所を追加</label>
          <input class="flex-1 leading-loose" type="text" name="通常投稿" />
        </div>
        <div class="flex items-start border-b pb-9 pt-7 border-gray-200">
          <label class="basis-1-10 mt-6" for="投稿タイプ">プレビュー</label>
          <div>
            <img src="/images/mobile-frame.png" alt="" />
            <button
              class="
                py-2
                w-44 text-center
                bg-blue-600
                button-shadow
                text-white text-xs
                block
                mx-auto
                mt-4
                rounded
              "
            >
              アップロード
            </button>
          </div>
        </div>
      </div>
       <Link href="/post/create/schedule" as="button" type="button" class="py-5.5 px-29 rounded bg-blue-600 button-shadow text-white my-28 block mx-auto">
          実行する
        </Link>
    </div>
  </DrashBoard>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";
import DrashBoard from "@/Layouts/Authenticated.vue";
import UserNav from "@/Components/UserNav.vue";
import IphoneScreen from "@/Components/IphoneScreen.vue";
export default {
  components: {
    IphoneScreen,
    DrashBoard,
    UserNav,
    Link
  },
  data() {
    return {
      imgSelected: "",
      is_selected: true,
    };
  },
  methods: {
    onFileChange(event) {
      this.imgSelected = URL.createObjectURL(event.target.files[0]);
      this.is_selected = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.post-form {
  input::placeholder {
    opacity: 0.8;
  }
  .basis-1-10 {
    margin-right: 1rem;
  }
  form {
    label {
      margin-left: 14px;
    }
    textarea {
      border: 1px solid #d0d0d0;
      box-sizing: border-box;
    }
  }
  .post-time {
    input[type="text"] {
      margin-left: 8px;
    }
  }
  .leading-loose {
    line-height: 2;
  }
}
</style>
