const chartData = {
  labels: [],
  datasets: [
    {
      label: 'リーチ',
      data: [],
      type: 'line',
      borderColor: '#D02D92',
      backgroundColor: '#D02D92',
      lineTension: 0,
      borderWidth: 3,
      pointRadius: 3,
      yAxisID: 'A',
    },
    {
      label: 'インプレッション',
      data: [],
      type: 'line',
      borderColor: '#3600A8',
      backgroundColor: '#3600A8',
      lineTension: 0,
      borderWidth: 3,
      pointRadius: 3,
    },
    {
      backgroundColor: '#C4C4C4',
      borderColor: '#C4C4C4',
      borderWidth: 3,
      data: [],
      label: 'リーチ増減',
      pointRadius: 3,
      type: 'bar',
      yAxisID: 'B',
    },
    {
      backgroundColor: '#00BDD7',
      borderColor: '#00BDD7',
      borderWidth: 3,
      data: [],
      label: 'インプレッション増減',
      pointRadius: 3,
      type: 'bar',
      yAxisID: 'B',
    },
  ],
}
const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    A: {
      type: 'linear',
      position: 'left',
    },
    B: {
      type: 'linear',
      position: 'right',
      ticks: {
        max: 1,
        min: 0,
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        title: (tooltipItems) => {
          const value = tooltipItems[0].label
          return value.split(',').reverse().join(' ')
        },
      },
    },
    datalabels: {
      display: false,
    },
  },
}

export { chartOptions, chartData }
