<template>
  <DrashBoard>
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li>
          <Link :href="route('admin.group-list.index')" class="font-normal" as="a">グルーピング</Link>
        </li>
        <span class="font-normal text-notice_date mx-2">></span>
        <li v-if="!id">
          <Link :href="route('admin.group-list.create')" class="font-normal breakcumb-active" as="a">新規作成</Link>
        </li>
        <li v-if="id">
          <Link :href="'/group-list/' + id" class="font-normal breakcumb-active" as="a">更新画面</Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-10 relative pb-5 active">{{ id ? '更新画面' : '新規作成' }}</li>
        </ul>
      </div>
    </template>
    <div class="bg-background pb-20 pt-5">
      <div class="md:px-14.5 sm:px-5 h-screen">
        <p class="py-5 text-3sm text-button_pink font-bold">{{ id ? '更新画面' : '新規作成' }}</p>
        <div class="grid form-wrapper_2col items-center gap-x-6">
          <label class="text-2sm">グールプ名</label>
          <div class="py-5">
            <el-input v-model="nameGroup" class="w-full rounded relative text-2sm" size="large" placeholder="グールプ名を入力"/>
            <div v-if="errors.name" class="w-full flex text-red-600 mt-1 absolute text-nomal">
              {{ errors.name[0] }}
            </div>
          </div>

          <label class="text-2sm sm:mt-5 md:mt-0">アカウント追加</label>

          <div class="relative select-h-auto py-5">
            <el-select v-model="searchAccount"
                multiple
                filterable
                default-first-option
                :reserve-keyword="false"
                class="w-full rounded relative text-2sm" size="large">
              <el-option
                v-for="item in accountSearchList"
                :key="item.id"
                :label="item.username"
                :value="item.id"
              />
            </el-select>
            <div v-if="errors.account" class="w-full flex text-red-600 mt-1 absolute text-nomal">
               {{ errors.account[0] }}
            </div>
          </div>
        </div>

        <div class="mt-8.5 flex btn-submit justify-center md:gap-0 sm:gap-4">
          <button
            @click="$inertia.visit(route('admin.group-list.index'))"
            class="bg-button_pink text-white md:py-5.5 md:px-24 md:mr-6 sm:py-3 rounded text-3sm"
          >
            取り消し
          </button>

          <button @click="addGroup" v-if="!id" class="bg-blue-600 text-white md:py-5.5 sm:py-3 md:px-24 block rounded text-3sm">登録</button>
          <button @click="updateGroup" v-if="id" class="bg-blue-600 text-white md:py-5.5 sm:py-3 md:px-24 block rounded text-3sm">更新</button>
        </div>
      </div>
    </div>
  </DrashBoard>
</template>

<script>
import Pagination from '@/Components/Pagination.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import { Link } from '@inertiajs/inertia-vue3'
import UserNav from '@/Components/UserNav.vue'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import * as groupApi from '@/Api/Group'
import { Back } from '@element-plus/icons-vue'
export default {
  components: {
    Pagination,
    DrashBoard,
    Link,
    UserNav,
    BreadCrumb,
    Back,
  },
  props: ['id'],
  async created() {
    if (this.id) {
      const groupInfo = await groupApi.getGroupDetail(this.id)
      this.accountList = groupInfo.data.data.account_name.map((element) => element.id)
      this.searchAccount = this.accountList
      this.nameGroup = groupInfo.data.data.group_name
    }

    const res = await groupApi.getAccountListByAdmin()
    this.accountGet = res.data.data
    this.accountSearchList = this.accountAvailable
  },
  data() {
    return {
      searchAccount: [],
      accountSearchList: [],
      accountList: [],
      nameGroup: '',
      accountGet: [],
      isSearchAccount: false,
      errors: {},
    }
  },
  computed: {
    accountAvailable() {
      const results = this.accountGet.filter(({ id: id1 }) => !this.accountList.some(({ id: id2 }) => id2 == id1))
      return results
    },
  },
  methods: {
    async addGroup() {
    //   const account = this.accountList.map((element) => element.id)
      try {
        await groupApi
          .addGroup(this.nameGroup, this.searchAccount)
          .then(() => {
            this.$toast.success(this.$t('add group successfully'))
            this.$inertia.visit('/group-list')
          })
          .catch(
            function (error) {
              this.errors = error.response.data
            }.bind(this)
          )
      } catch (error) {
        this.$toast.error(this.$t('something went wrong'))
      }
    },
    async updateGroup() {
    //   const accountId = this.accountList.map((element) => element.id)
      try {
        await groupApi
          .editGroup(this.id, this.nameGroup, this.searchAccount)
          .then(() => {
            this.$toast.success(this.$t('update group successfully'))
            this.$inertia.visit('/group-list')
          })
          .catch(
            function (error) {
              this.errors = error.response.data
              // this.$toast.error(this.$t('something went wrong'))
            }.bind(this)
          )
      } catch (error) {
        this.$toast.error(this.$t('something went wrong'))
      }
    },
    removeAccount(account) {
      const index = this.accountList.indexOf(account)
      if (index > -1) {
        this.accountList.splice(index, 1) // 2nd parameter means remove one item only
      }
    },
    resetError() {
      if (this.accountList.length > 1) {
        this.errors.account[0] = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  box-shadow: 0px 0px 6px rgba(81, 5, 52, 0.25);
}
.form-wrapper_2col {
  grid-template-columns: max-content auto;
}

.translate-y-center {
  transform: translateY(-50%);
}
.list-user-search {
  li {
    &:hover {
      cursor: pointer;
      background: #ebebeb;
    }
  }
}
@media (max-width: 640px) {
  .form-wrapper_2col {
    grid-template-columns: 100%;
  }
  .btn-submit button{
    width: 50%;
    white-space: nowrap;
  }
}
</style>
