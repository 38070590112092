<template>
  <data-table
    :fields="fields"
    :items="base_infos"
    disable-table-info
    footer-center
    paginate-background
    class="el-table-header__black mt-7"
  >
    <template #followers="{ row }">
      <p v-if="row" class="font-bold">{{ formatToInteger(row.followers) }}</p>
    </template>
    <template #follower_change>
      <p class="font-bold" :class="{ plus_active: followerChange() > 0, minus_active: followerChange() < 0 }">
        {{ formatValue(followerChange()) }}
      </p>
    </template>
    <template #profile_views>
      <p class="font-bold">{{ getProfileView() ?? '-' }}</p>
    </template>
    <template #website_clicks>
      <p class="font-bold">{{ getWebsiteClicks() ?? '-' }}</p>
    </template>
    <template #reach>
      <p class="font-bold">{{ getReach() ?? '-' }}</p>
    </template>
    <template #media_count="{ row }">
      <p class="font-bold">{{ formatToInteger(row?.media_count) ?? '-' }}</p>
    </template>
  </data-table>
</template>

<script>
import DataTable from '@/Components/UI/DataTable.vue'

export default {
  components: { DataTable },
  props: {
    range: { type: Object, default: 0 },
    accountTables: { type: Object, default: {} },
  },
  data() {
    return {
      base_infos: [],
      calculation: {},
      fields: [
        { key: 'followers', label: 'フォロワー数', width: 150, align: 'center' },
        {
          key: 'follower_change',
          label: 'フォロワー純増減数',
          width: screen.width / 5 >= 240 ? screen.width / 5 : 200,
          align: 'center',
        },
        {
          key: 'profile_views',
          label: 'プロフィールアクセス数',
          width: screen.width / 5 >= 240 ? screen.width / 5 : 200,
          align: 'center',
        },
        {
          key: 'website_clicks',
          label: 'ウェブサイトクリック数',
          width: screen.width / 5 >= 240 ? screen.width / 5 : 200,
          align: 'center',
        },
        {
          key: 'reach',
          label: 'アカウントリーチ数',
          width: screen.width / 5 >= 240 ? screen.width / 5 : 200,
          align: 'center',
        },
        {
          key: 'media_count',
          label: 'フィード投稿数',
          width: screen.width / 5 >= 240 ? screen.width / 5 : 200,
          align: 'center',
        },
      ],
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    range: function () {
      this.fetchData()
    },
    accountTables: function () {
      this.fetchData()
    },
  },
  methods: {
    // fetch data
    async fetchData() {
      if (!this.range.length) return
      const datatable = JSON.parse(JSON.stringify(this.accountTables))
      this.base_infos = datatable?.lastDaySelected ? Array(datatable?.lastDaySelected) : []
      this.calculation = datatable?.calculation
    },
    getProfileView() {
      return this.formatToInteger(this.calculation?.totalProfileViews)
    },
    getWebsiteClicks() {
      return this.formatToInteger(this.calculation?.totalWebsiteClicks)
    },
    getReach() {
      return this.formatToInteger(this.calculation?.totalReach)
    },
    //follower change
    followerChange() {
      const lastDaySelected = this.accountTables?.lastDaySelected?.followers ?? 0
      const lastDayPrevious = this.accountTables?.lastDayPrevious?.followers ?? 0
      const followers = lastDaySelected - lastDayPrevious
      return followers;
    },
    formatValue(followers) {
        return followers > 0 ? '+' + this.formatToInteger(followers) : this.formatToInteger(followers)
    },
  },
}
</script>
<style>
.plus_active {
  color: #4a80d3;
}
.minus_active {
  color: #dc3545;
}
</style>
