export default {
  methods: {
    genSlideCover(pptx, curentDate, endDateSelectedMonth, startDateSelectedMonth) {
      let slideCover = pptx.addSlide()
      slideCover.addShape(pptx.ShapeType.rect, { x: 0, y: 0, w: 11.02, h: 4.74, fill: { color: 'e7299e' } })
      slideCover.addText('Instagram Monthly Report', {
        x: 0.7,
        y: 2.76,
        w: 5.12,
        h: 0.43,
        align: 'left',
        bold: true,
        color: 'ffffff',
        fontSize: 20,
      })
      slideCover.addText('Instagramレポート', {
        x: 0.7,
        y: 3.32,
        w: 10.53,
        h: 0.7,
        align: 'left',
        bold: true,
        color: 'ffffff',
        fontSize: 36,
      })
      slideCover.addText('株式会社●●  御中', {
        x: 0.7,
        y: 5.08,
        w: 4.83,
        h: 0.54,
        align: 'left',
        color: 'e7299e',
        fontSize: 18,
      })
      slideCover.addText('●データの集計期間', {
        x: 0.7,
        y: 5.98,
        w: 4.83,
        h: 0.54,
        align: 'left',
        color: '808080',
        fontSize: 20,
      })
      slideCover.addText(startDateSelectedMonth + ' – ' + endDateSelectedMonth, {
        x: 4.88,
        y: 5.98,
        w: 4.41,
        h: 0.39,
        align: 'left',
        color: '808080',
        fontSize: 18,
      })
      slideCover.addText('●作成日', { x: 0.7, y: 6.47, w: 4.83, h: 0.54, align: 'left', color: '808080', fontSize: 20 })
      slideCover.addText(curentDate, {
        x: 4.88,
        y: 6.47,
        w: 4.41,
        h: 0.39,
        align: 'left',
        color: '808080',
        fontSize: 18,
      })
      return slideCover
    },
  },
}
