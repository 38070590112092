import axios from "axios";

export async function getPlanIdByUser(userId,planId) {
    const article = {
        user_id:userId,
        plan_id:planId
    };
    return await axios.get(route('api.plans.by_user',article));
}
export async function getPlanSuggestByUser(userId,planSuggestId) {
    const article = {
        user_id:userId,
        plan_suggest_id:planSuggestId
    };
    return await axios.get(route('api.plans.suggest',article));
}


export async function getDetailPlan(planId) {
    return await axios.get(route('api.plans.show', planId));
}

export async function getPlanCrawl(planCrawlId) {
    return await axios.get(route('api.plans.get_plan_crawl', planCrawlId));
}

export async function createCustomer(userId,email,description) {
    const article = {
        user_id:userId,
        email: email ,
        description: description
    };
    return await axios.post(route('api.customers.store'), article);
}

export async function createCard(customer_id,token) {
    const article = {
        customer_id: customer_id,
        token: token
    };
    return await axios.post(route('api.credit_cards.store'), article);
}

export async function cardSetDefault(customerPayId,token,planNote) {
    const article = {
        customer_pay_id: customerPayId,
        token: token,
        plan_note:planNote
    };
    return await axios.post(route('api.card.set_default'), article);
}

export async function createSubscription(payjp_customer_id,payjp_plan_id,plan_id,card_id = '',is_checkout = false) {
    const article = {
        payjp_customer_id: payjp_customer_id,
        payjp_plan_id: payjp_plan_id,
        plan_id: plan_id,
        card_id: card_id,
        is_checkout: is_checkout
    };
    return await axios.post(route('api.subscriptions.store'), article);
}

export async function getListCard(user_id) {
    return await axios.get(route('api.list-card-user', user_id));
}

export async function pausePlan(userId, planId) {
    const article = {
        user_id:userId,
        plan_id:planId
    };
    return await axios.post(route('api.plans.pause'), article);
}