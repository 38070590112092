import { EMPTY_IMG } from '../Mixins/media.js'
export default {
  methods: {
    genSlideInteractive(pptx, dataEngagement, text, title) {
      let slideInteractive = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideInteractive.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      slideInteractive.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 6.05,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 22,
      })

      let opts = {
        x: 0.5,
        y: 1.31,
        w: 10.02,
        h: 4.58,
        barDir: 'col',
        barGrouping: 'clustered',
        catAxisLabelColor: '787878',
        catAxisLabelFontSize: 12,
        catAxisOrientation: 'minMax',
        showTitle: true,
        title: 'フィード投稿エンゲージメント',
        titleColor: '787878',
        titleFontSize: 18.6,
        showLegend: true,
        legendPos: 'b',
        legendFontSize: 12,
        legendColor: '787878',
        valAxes: [
          {
            showValAxisTitle: false,
            valAxisTitle: 'Primary Value Axis',
          },
          {
            showValAxisTitle: false,
            valAxisTitle: 'Secondary Value Axis',
            catAxisOrientation: 'maxMin',
            valAxisMinVal: 0,
            valGridLine: { style: 'none' },
          },
        ],
        catAxes: [
          {
            catAxisTitle: 'Primary Category Axis',
          },
          {
            catAxisHidden: true,
          },
        ],
        valAxisMinVal: 0,
      }

      let labels = dataEngagement.dataLabels

      if (dataEngagement.dataSlide18.checkEngagement) {
        slideInteractive.addImage({ path: EMPTY_IMG, x: 3.75, y: 1.84, w: 3.56, h: 3.56 })
      } else {
        let chartTypes = [
          {
            type: pptx.charts.BAR,
            data: [
              {
                name: 'エンゲージメント数',
                labels: labels,
                values: dataEngagement.dataSlide18.dataEngagement,
              },
            ],
            options: {
              chartColors: ['e7299e'],
            },
          },
          {
            type: pptx.charts.LINE,
            data: [
              {
                name: 'エンゲージメント率( %)',
                labels: labels,
                values: dataEngagement.dataSlide18.dataEngagementRate,
              },
            ],
            options: {
              chartColors: ['C2C2C2'],
              lineDataSymbol: 'none',
              secondaryValAxis: !!opts.valAxes,
              secondaryCatAxis: !!opts.catAxes,
            },
          },
        ]
        slideInteractive.addChart(chartTypes, opts)
      }

      slideInteractive.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.29,
        w: 10.14,
        h: 1.56,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slideInteractive
    },
  },
}
