<script setup>
import BreezeButton from "@/Components/Button.vue";
import BreezeGuestLayout from "@/Layouts/GuestRegister.vue";
import BreezeInput from "@/Components/Input.vue";
import BreezeLabel from "@/Components/Label.vue";
import { Head, useForm } from "@inertiajs/inertia-vue3";

const form = useForm({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    terms: false,
});
const submit = () => {
    form.post(route("register"), {
        onFinish: () => form.reset("password", "password_confirmation"),
    });
};
</script>

<template>
    <BreezeGuestLayout>
        <Head title="Register" />

        <!-- <BreezeValidationErrors class="mb-4 " /> -->

        <!-- Form -->
        <div>
            <div class="flex items-center justify-center">
                <div style="width: 493px" class="pb-8 text-center text-base">
                    （サービス名）○○プランへのお申し込みありがとうございます。ご登録のメールアドレスにお申し込み情報の詳細をお送りしていますのでご確認ください
                </div>
            </div>

            <div class="flex items-center justify-center mt-7">
                <BreezeButton
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                    class="bg-indigo-500 hover:bg-indigo-700 pt-5 pb-5 rounded-sm px-24 mx-2"
                >
                    管理画面に戻る
                </BreezeButton>

                <BreezeButton
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                    class="bg-pink-500 hover:bg-pink-700 pt-5 pb-5 rounded-sm px-20 mx-2"
                >
                    ログアウトして閉じる
                </BreezeButton>
            </div>
        </div>
    </BreezeGuestLayout>
</template>
