export default {
  methods: {
    genSlideEngagementChart(pptx, dataAnalysisPost, text, title) {
      if (dataAnalysisPost.hours.length == 0 || dataAnalysisPost.days.length == 0) {
        return
      }

      let dataChart = [
        {
          data: [...dataAnalysisPost?.hours?.impressions],
          type: 'hours',
        },
        {
          data: [...dataAnalysisPost?.days.impressions],
          type: 'day',
        },
        {
          data: [...dataAnalysisPost?.hours?.reach],
          type: 'hours',
        },
        {
          data: [...dataAnalysisPost?.days.reach],
          type: 'day',
        },
        {
          data: [...dataAnalysisPost?.hours?.saved],
          type: 'hours',
        },
        {
          data: [...dataAnalysisPost?.days.saved],
          type: 'day',
        },
        {
          data: [...dataAnalysisPost?.hours?.video_views],
          type: 'hours',
        },
        {
          data: [...dataAnalysisPost?.days.video_views],
          type: 'day',
        },
        {
          data: [...dataAnalysisPost?.hours?.like],
          type: 'hours',
        },
        {
          data: [...dataAnalysisPost?.days.like],
          type: 'day',
        },
        {
          data: [...dataAnalysisPost?.hours?.comment],
          type: 'hours',
        },
        {
          data: [...dataAnalysisPost?.days.comment],
          type: 'day',
        },
        {
          data: [...dataAnalysisPost?.hours?.engagement],
          type: 'hours',
        },
        {
          data: [...dataAnalysisPost?.days.engagement],
          type: 'day',
        },
      ]
      this.genSlideEngagement(pptx, dataChart.slice(0, 8), text, title)
      this.genSlideEngagement(pptx, dataChart.slice(8, 14), text, 'いいね/コメント/エンゲージメント率', 'slideSecond')
    },

    genSlideEngagement(pptx, dataChart, text, title, options = null) {
      let slideEngagementChart = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideEngagementChart.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })

      slideEngagementChart.addText(title, {
        x: 2.3,
        y: 0.25,
        w: 8,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 22,
      })

      slideEngagementChart.addText(options === 'slideSecond' ? 'いいね' : 'インプレッション', {
        x: 0.06,
        y: 1,
        w: 2,
        h: 0.3,
        align: 'left',
        color: '000000',
        fontSize: 10,
      })

      slideEngagementChart.addText(options === 'slideSecond' ? 'コメント' : 'リーチ', {
        x: 5.3,
        y: 1,
        w: 2,
        h: 0.3,
        align: 'left',
        color: '000000',
        fontSize: 10,
      })

      slideEngagementChart.addText(options === 'slideSecond' ? 'エンゲージメント率' : '保存数', {
        x: 0.06,
        y: 3.6,
        w: 2,
        h: 0.3,
        align: 'left',
        color: '000000',
        fontSize: 10,
      })

      if (options !== 'slideSecond') {
        slideEngagementChart.addText('動画再生数', {
          x: 5.32,
          y: 3.6,
          w: 2,
          h: 0.3,
          align: 'left',
          color: '000000',
          fontSize: 10,
        })
      }

      let labelDay = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日']
      let labelHours = ['0-3時', '3-6時', '6-9時', '9-12時', '12-15時', '15-18時', '18-21時', '21-0時']

      dataChart.forEach((item, i) => {
        let optsChartBar2 = {
          x: i >= 4 ? (i - 4) * 2.6 + 0.2 : i * 2.6 + 0.2,
          y: i >= 4 ? 3.9 : 1.35,
          w: 2.6,
          h: 2.15,
          barDir: 'bar',
          chartColors: ['C81A88'],

          catAxisLabelColor: '787878',
          catAxisLabelFontSize: 8,
          valGridLine: { color: '9b9b9b', style: 'solid', size: 0.5 },

          dataLabelColor: '787878',

          showValue: true,
          dataLabelFontSize: 8,
          valAxisLabelFontSize: 8,
          dataLabelFormatCode: '#,##0.00',

          showTitle: true,
          title: item?.type == 'day' ? '曜日別' : '時間別',
          titleFontSize: 9,
          titleColor: '787878',
          titleAlign: 'left',
          titlePos: { x: 0.01, y: -0.1 },
          catAxisLabelPos: 'low',
        }
        slideEngagementChart.addChart(
          pptx.charts.BAR,
          [{ labels: item.type == 'day' ? labelDay : labelHours, values: item.data }],
          optsChartBar2
        )
      })

      slideEngagementChart.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.1,
        w: 10.14,
        h: 1.74,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slideEngagementChart
    },
  },
}
