<template>
  <div class="sm:px-0 md:px-10 pb-20 mt-5">
    <div class="statistic sm:px-5 md:px-10 rounded">
      <div class="flex justify-between items-center my-2">
        <h2 class="text-button_pink !text-[14px] font-semibold">クリエイティブ分析</h2>
        <button
          v-if="isRole == 'master'"
          :disabled="numberExportCreative <= 0 && isRole !== 'master'"
          @click="exportCreativeCSV"
          class="bg-black ml-3 py-3 px-7 text-white !text-[14px] button-shadow rounded"
        >
          CSV出力
        </button>

        <el-tooltip
          v-else
          class="tooltip-customer"
          effect="dark"
          :content="'クリエイティブ分析画面今月後 ' + numberExportCreative + ' 回CSV出力出来ます。'"
          placement="top"
        >
          <button
            :disabled="numberExportCreative <= 0 && isRole !== 'master'"
            @click="exportCreativeCSV"
            class="bg-black ml-3 py-3 px-7 text-white !text-[14px] button-shadow rounded"
          >
            CSV出力
          </button>
        </el-tooltip>
      </div>
      <div class="bg-white p-3">
        <div class="md:px-10 mt-12">
          <div>
            <p class="uppercase text-button_pink font-semibold mb-3 pl-3.2 text-3sm">top10</p>
            <!-- list post best -->
            <ul
              class="flex mt-2.5 mb-3 xl:flex-nowrap sm:flex-wrap xl:justify-start md:justify-center md:gap-0 sm:gap-2.5"
            >
              <li
                v-for="(post, index) in topBestPosts"
                :key="index"
                class="md:h-28 md:w-28 sm:h-24 sm:w-24 xl:h-auto xl:w-1/10 pl-3.2 top-list-item image__error"
              >
                <el-image :src="post?.thumbnail_url" :alt="post?.content" class="rounded w-100" lazy>
                  <template #error>
                    <div class="image-slot">
                      <i class="fa-regular fa-image text-xl text-gray-600" />
                    </div>
                  </template>
                </el-image>
              </li>
            </ul>
            <div class="text-option text-base font-normal pl-3.2 text-2sm">おすすめカラー：#eeeee</div>
          </div>
          <!-- list post worst -->
          <div class="sm:pb-5 md:pb-0 mt-8">
            <p class="uppercase text-button_pink font-semibold mb-3 pl-3.2 text-3sm">Worst10</p>
            <div
              class="flex mt-2.5 mb-3 xl:flex-nowrap sm:flex-wrap xl:justify-start md:justify-center md:gap-0 sm:gap-2.5"
            >
              <div
                v-for="(post, index) in topWorstPosts"
                :key="index"
                class="md:h-28 md:w-28 sm:h-24 sm:w-24 xl:h-auto xl:w-1/10 pl-3.2 top-list-item image__error"
              >
                <el-image :src="post?.thumbnail_url" :alt="post?.content" class="rounded w-100" lazy>
                  <template #error>
                    <div class="image-slot">
                      <i class="fa-regular fa-image text-xl text-gray-600" />
                    </div>
                  </template>
                </el-image>
              </div>
            </div>
            <div class="text-option text-base font-normal pl-3.2 text-2sm">おすすめカラー：#aaaaaa</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import { fetchNumberExport, addNumberExport } from './Mixins/limitExport.js'

export default {
  name: 'PostCreative',
  components: { DrashBoard, UserNav, BreadCrumb, Link, LinkInstagram },
  emits: ['getTopBestPosts', 'getTopWorstPosts'],
  props: {
    userId: { type: Number },
    plan: { type: Object, require: true },
  },
  data() {
    return {
      loading: false,
      numberExportCreative: 0,
      showLinkInstagram: true,
      topBestPosts: [],
      topWorstPosts: [],
    }
  },
  computed: {
    isRole() {
      return this.$page.props?.auth?.user?.roles[0]?.name
    },
  },

  created() {
    this.fetchData()
    this.fetchNumberExportMonth()
  },
  methods: {
    // fetch data
    async fetchData() {
      await axios
        .get(route('api.top.post'), {
          params: {
            type: 'export',
          },
        })
        .then((res) => {
          const data = Object.keys(res?.data).map((k) => res?.data[k])
          if (data?.length) {
            const top_posts = Object.keys(data[0]).map((k) => data[0][k])
            const worst_posts = Object.keys(data[1]).map((k) => data[1][k])
            this.topBestPosts = top_posts?.sort((a, b) => b.engagement_rate - a.engagement_rate)
            this.topWorstPosts = worst_posts.sort((a, b) => a.engagement_rate - b.engagement_rate)
            this.$emit('getTopBestPosts', this.topBestPosts)
            this.$emit('getTopWorstPosts', this.topWorstPosts)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    // expory creative CSV
    exportCreativeCSV() {
      if (this.numberExportCreative === 0) {
        return
      }

      const userId = this.userId ? this.userId : this.$page?.props?.auth?.user?.id
      const url = route('api.export.creative.csv') + '?user_id=' + userId
      window.location.href = url

      if (this.isRole !== 'master') {
        addNumberExport('csv', 'csv_creative')
        this.fetchNumberExportMonth()
      }
    },

    async fetchNumberExportMonth() {
      const { data } = await fetchNumberExport('csv', 'csv_creative')
      this.numberExportCreative = this.plan?.number_report_csv - data
    },
  },
}
</script>

<style lang="scss" scoped>
.top-list-item .el-image {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  min-height: 50px;
}

.image__error .el-image {
  max-width: 300px;
  max-height: 200px;
  width: 100%;
  height: 96px;
}

.image__error .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 96px;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}
.image__error .image-slot .el-icon {
  font-size: 30px;
}
</style>
