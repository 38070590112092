<template>
    <div class="px-6">
        <h2 class="text-xl text-button_pink font-semibold py-5">
            お知らせ|testtesttesttesttest
        </h2>
        <div class="flex">
            <form class="pr-5 basis-5/12 flex flex-col justify-between">
                <div class="form__wrapper">
                    <label for="">親アカウント名</label>
                    <input type="text" />
                    <label for="">会社名</label>
                    <input type="text" />
                    <label for="">アカウント名</label>
                    <input type="text" />
                    <label for="">連携アカウント名</label>
                    <input type="text" />
                    <label for="">氏名(姓)</label>
                    <input type="text" />
                </div>
                <div class="form__wrapper--2col">
                    <label for="">会社名</label>
                    <input class="grow" type="text" />
                    <label class="ml-7 mr-3.5" for="">名前(名)</label>
                    <input class="grow" type="text" />
                </div>
                <div class="form__wrapper mt-5">
                    <label for="">メールアドレス</label>
                    <input class="grow" type="text" />
                </div>
            </form>
            <div class="pl-6">
                <h2 class="text-xl text-button_pink font-semibold pb-5">
                    プラン選択
                </h2>
                <div
                    class="grid form__radio"
                    v-for="item in permisionList"
                    :key="item"
                >
                    <div v-for="(value, label) in item" :key="label">
                        <input
                            type="radio"
                            id="html"
                            name="fav_language"
                            :value="{ value }"
                        />
                        <label class="ml-2 mr-8" for="html">{{ label }}</label>
                    </div>
                </div>
                <h2 class="text-xl text-button_pink font-semibold pb-5 mt-10">
                    プラン選択
                </h2>
                <div
                    class="grid form__radio--3col"
                    v-for="item in planList"
                    :key="item"
                >
                    <div v-for="(value, label) in item" :key="label">
                        <input
                            type="radio"
                            id="html"
                            name="fav_language"
                            :value="{ value }"
                        />
                        <label class="ml-2 mr-8" for="html">{{ label }}</label>
                    </div>
                </div>
                <div class="flex items-center pr-16 mt-10">
                    <label class="basis-5/12 text-black" for=""
                        >契約書アップロード</label
                    >
                    <input
                        placeholder="ファイルを選択する"
                        disabled
                        class="grow"
                        type="text"
                    />
                </div>
                <div class="flex items-center mt-4 pr-16">
                    <label class="basis-5/12 text-black" for=""
                        >ロゴアップロード</label
                    >
                    <input
                        placeholder="ファイルを選択する"
                        disabled
                        class="grow"
                        type="text"
                    />
                </div>
            </div>
        </div>
        <button class="py-2 px-5.5 mt-8 bg-blue-600 text-white font-semibold">
            登録する
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            permisionList: [
                {
                    "○代理店管理者": 1,
                    "○OEM管理者": 1,
                    エンタープライズ管理者: 1,
                    "○直販ユーザー": 1,
                    "○代理店ユーザー": 1,
                    OEMユーザー: 1,
                    エンタープライズユーザー: 1,
                },
            ],
            planList: [
                {
                    ライトプラン: 1,
                    スタンダードプラン: 1,
                    プロプラン: 1,
                },
            ],
        };
    },
};
</script>

<style scoped>
.form__wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: 10% auto;
    grid-gap: 20px 0px;
}
.form__wrapper label,
.form__wrapper--2col label {
    color: black;
}
.form__wrapper--2col {
    align-items: center;
    display: grid;
    margin-top: 20px;
    grid-template-columns: 10% auto max-content auto;
    grid-gap: 16px 0px;
}
.form__wrapper label {
    text-align: left;
}
.form__radio {
    grid-template-columns: max-content auto;
    grid-gap: 20px 0px;
}
.form__radio--3col {
    grid-template-columns: max-content max-content auto;
    grid-gap: 45px;
}
</style>
