<template>
  <DrashBoard>
    <div class="bg-background pb-20">
      <UserNav />
      <div class="px-14.5">
        <h2 class="title-md py-5">ハッシュタグを追加</h2>
        <div class="grid form-wrapper_2col items-center gap-y-7.5 gap-x-6">
          <label> Tên hashtag </label>
          <input
            v-model="nameHashtag"
            class="w-full rounded relative py-4"
            type="text"
          />
          <label> Ngày bắt đầu thống kê: </label>
          <div class="flex items-center">
            <v-date-picker
              class="relative w-max"
              v-model="since"
              mode="dateTime"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="text-item_link"
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                />
                <img
                  class="absolute right-3 top-1/2 -translate-y-1/2"
                  src="/images/calendar.svg"
                  alt=""
                />
              </template>
            </v-date-picker>
            <label class="mx-6"> Ngày kết thúc thống kê: </label>
            <v-date-picker
              class="relative w-max"
              v-model="until"
              mode="dateTime"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="text-item_link"
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                />
                <img
                  class="absolute right-3 top-1/2 -translate-y-1/2"
                  src="/images/calendar.svg"
                  alt=""
                />
              </template>
            </v-date-picker>
          </div>
          <label for="">Hashtag vừa thêm</label>
          <ul class="flex gap-3 border p-5 rounded">
            <li
              v-for="hashtag in hashtagLast"
              :key="hashtag"
              class="
                border
                rounded-2xl
                flex
                items-center
                gap-x-6
                px-4
                py-1
                bg-white
                text-base text-option
              "
            >
              <span> {{ hashtag }}</span>
            </li>
          </ul>
          <p v-if="validate">{{checkValidate}}</p>
          <div class="mt-8.5 flex">
            <button
              @click="createHashtag"
              class="bg-blue-600 text-white py-5.5 px-16 button-shadow rounded"
            >
              もっと
            </button>
          </div>
        </div>
      </div>
    </div>
  </DrashBoard>
</template>

<script>
import Pagination from "@/Components/Pagination.vue";
import DrashBoard from "@/Layouts/Authenticated.vue";
import UserNav from "@/Components/UserNav.vue";
import * as hashTagApi from "@/Api/Hashtag";
export default {
  components: {
    Pagination,
    DrashBoard,
    UserNav,
  },
  async mounted() {
    const dataHastag = await hashTagApi.getHashTag();
    this.hashtagLast = dataHastag.data.data.map((hastag) => hastag.name).reverse();
  },
  methods: {
    formartDate(date) {
      if (date) {
       return date.toISOString().split("T")[0];
      }
    },
    async createHashtag() {
        this.validate = true;
        try {
            await hashTagApi.postHashTag(this.nameHashtag, this.formartDate(this.since), this.formartDate(this.until));
            this.checkValidate = this.$t('add_hashtag_successfully');
        } catch (error) {
            this.checkValidate = this.$t('invalid_hashtag_information');
        }
    },
  },
  data() {
    return {
      nameHashtag: "",
      since: new Date(),
      until: null,
      hashtagLast: [],
      checkValidate: "",
      validate : false
    };
  },
};
</script>

<style scoped>
.form-wrapper_2col {
  grid-template-columns: max-content max-content;
}

.translate-y-center {
  transform: translateY(-50%);
}
</style>
