<template>
  <el-col :md="6" class="px-3 my-3">
    <div class="p-3 bg-white border border-gray-300 rounded">
      <div class="mt-2 mb-6 text-left text-gray-600 text-3sm font-semibold">{{ title }}</div>
      <div class="flex justify-between border-b border-gray-200 pb-2">
        <div class="text-gray-500 text-2sm">{{ name === 'sum_engagement_rate' ? '投稿平均' : '合計' }}</div>
        <div class="text-gray-600 font-semibold text-lg number">{{ total ? formatToFloat(total) : 0 }}</div>
      </div>
      <div class="flex justify-between pt-2 h-6">
        <div class="text-gray-500 text-2sm">{{ !name ? '投稿平均' : ' ' }}</div>
        <div class="text-gray-600 font-semibold text-2sm">{{ !name ? getAverage() : ' ' }}</div>
      </div>
    </div>
  </el-col>
</template>

<script>
export default {
  name: 'Info',
  props: ['title', 'total', 'countPost', 'name'],
  methods: {
    getAverage() {
      if (this.total && this.countPost) {
        const average = this.total / this.countPost
        return this.formatToFloat(average)
      }
      return 0
    },
  },
}
</script>

<style></style>
