<template>
    <div id="chart-manual"
        class="hidden absolute  w-11/12 md:w-[350px] top-[25px] left-1/2 -translate-x-1/2 md:translate-none md:left-[380px] z-50">
        <div class="header-manual p-2 text-center text-white bg-[#d3007f] rounded-t-[10px] relative">
            <p class="text-[15px] text-white font-bold">画面の見方/ヒント</p>
            <div @click="$emit('closeManualPopupChart')"
                class="absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer">
                &#10006;</div>
        </div>
        <div class="body-manual bg-white rounded-b-[10px] md:h-[400px]">
            <div class="body-manual__title text-center py-2 font-bold text-[14px]">画面の見方</div>
            <div class="body-manual__content px-2">
                <div v-for="item in content" :key="item">
                    <p v-html="item" class="text-[13px]"></p>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: Array,
            default: []
        },
        isShow: {
            type: Boolean,
            default: false
        },
    },
    emits: ['closeManualPopupChart'],
    data() {
        return {
            isShowPopup: false
        }
    },
}
</script>

<style scoped>
.overlay-loading {
    background-color: rgba(0, 0, 0, 0.4);
}
</style>


