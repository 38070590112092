<template>
  <h3 class="text-option font-semibold text-nomal pl-4 pt-4">フォロワーの地域</h3>
  <Bar
    v-if="showData"
    class="mx-auto p-4"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :plugins="plugins"
  />
  <NoData v-else></NoData>
  <div v-if="showData" class="loadmore p-2 flex items-center justify-end">
    <p class="text-button_pink text-2sm font-bold cursor-pointer" @click="toggleModal()">もっとみる</p>
  </div>
  <!-- modal -->
  <div
    v-if="showModal"
    @click="showModal = false"
    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-auto mx-auto max-w-6xl model-chart">
      <!--content-->
      <div
        class="border-0 shadow-lg rounded-lg relative flex flex-col bg-white outline-none focus:outline-none w-[300px] md:w-[420px] lg:w-[600px] xl:w-[765px] 2xl:w-[1000px]"
        style="top: 10px"
      >
        <!--header-->
        <div class="flex items-start justify-between p-5 border-b border-solid border-slate-200">
          <h3 class="text-title text-option font-bold">フォロワーの地域</h3>
          <button v-on:click="toggleModal()">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <!--body-->
        <div class="overflow-y-auto" style="height: 55vh">
          <horizon-bar-chat-all :dataCityForChart="dataCityForChart"></horizon-bar-chat-all>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  <!-- modal -->
</template>

<script>
import { Bar } from 'vue-chartjs'
import HorizonBarChatAll from './AllData.vue'
import NoData from '../NoData'

export default {
  name: 'ChartByAddress',
  components: { Bar, HorizonBarChatAll, NoData },
  props: {
    range: { type: Object, default: 0 },
    chartId: { type: String, default: 'bar-chart' },
    plugins: { type: Array, default: () => [] },
    dataCityForChart: { type: Object, default: () => {} },
  },
  data() {
    return {
      listCountry: [],
      showModal: false,
      showData: true,
      chartData: {
        labels: [],
        datasets: [{ label: 'フォロワー', backgroundColor: '#0097D3', data: [] }],
      },
      chartOptions: {
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          tooltip: {
            xAlign: {
              label: function (context) {
                context.dataset.label.xAlign = 'left'

                return context.dataset.label.xAlign
              },
            },
          },
          legend: {
            position: 'top',
            align: 'start',
            title: { display: true },
          },
          datalabels: { display: false },
        },
        elements: {
          bar: { borderWidth: 2 },
        },
        scales: {
          x: {
            grid: { borderColor: 'transparent' },
          },
          y: {
            min: 0,
            max: 6,
            grid: { display: false, borderColor: 'transparent' },
          },
        },
      },
      chartOptionsAll: {
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          tooltip: {
            xAlign: {
              label: function (context) {
                context.dataset.label.xAlign = 'left'

                return context.dataset.label.xAlign
              },
            },
          },
          legend: {
            position: 'top',
            align: 'start',
            title: { display: true },
          },
          datalabels: { display: false },
        },
        elements: {
          bar: { borderWidth: 2 },
        },
        scales: {
          x: {
            grid: { borderColor: 'transparent' },
          },
          y: {
            grid: { display: false, borderColor: 'transparent' },
            ticks: { beginAtZero: true, precision: 0 },
          },
        },
      },
    }
  },
  created() {
    this.getData()
  },
  watch: {
    dataCityForChart: function () {
      this.getData()
    },
  },
  methods: {
    async getData() {
      if (this.dataCityForChart && Object.keys(this.dataCityForChart)?.length) {
        this.prepareDataForChart()
        this.showData = true
      } else {
        this.showData = false
        this.chartData.labels = []
        this.chartData.datasets[0].data = []
      }
    },
    prepareDataForChart() {
      let totalDataCountry = {}
      const audience_city = this.dataCityForChart?.audience_city

      const isJson = this.IsJsonString(audience_city)
      if (!isJson) return

      for (const [index, item] of Object.entries(JSON.parse(audience_city))) {
        if (totalDataCountry.hasOwnProperty(index)) {
          totalDataCountry[index] += item
        } else {
          totalDataCountry[index] = item
        }
      }

      for (const [key, item] of Object.entries(totalDataCountry)) {
        if (!this.chartData.labels.includes(key)) {
          this.chartData.labels.push(key)
        }
      }

      for (const city of this.chartData.labels) {
        this.chartData.datasets[0].data = [...this.chartData.datasets[0]?.data, totalDataCountry[city]]
      }

      this.chartData.labels = this.chartData.labels.map((label) => {
        return this.$t(label)
      })
    },
    IsJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    toggleModal: function () {
      this.showModal = !this.showModal
      this.chartOptions.scales.y.max = 89
    },
  },
}
</script>

<style scoped>
.model-chart {
  top: 15%;
}
</style>
