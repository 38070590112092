<template>
  <el-dialog v-model="value" @close="onClose" title="アカウント追加" width="30%" custom-class="custom-dialog">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules">
      <el-form-item width="50%" prop="account">
        <div v-for="(item, i) in data" :key="i">
          <div class="flex items-center my-4 mr-5 pt-[10px]">
            <el-radio-group v-model="ruleForm.account">
              <el-radio :disabled="item.status" :label="item.id" size="large">
                <div class="flex items-center">
                  <el-image
                    v-if="item.profile_picture_url"
                    style="width: 50px; height: 50px"
                    :src="item.profile_picture_url"
                  />
                  <el-image v-else style="width: 50px; height: 50px" :src="'/images/user_profile_picture.jpeg'" />
                  <div class="pl-2">{{ item.name }}</div>
                </div>
              </el-radio>
            </el-radio-group>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="onClose">{{ $t('Cancel') }}</el-button>
        <el-button type="primary" @click="handleSubmit()">
          {{ $t('Submit') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import Ins from '@/Services/Ins'
import axios from 'axios'

export default {
  props: ['data', 'value', 'userId'],

  data() {
    return {
      ruleForm: {
        account: null,
      },
      rules: {
        account: [
          {
            required: true,
            message: this.$i18n.t('Please input select account'),
            trigger: 'change',
          },
        ],
      },
    }
  },

  emits: ['update:modelValue', 'onSubmitSuccess'],

  methods: {
    onClose() {
      this.$emit('update:modelValue', false)
    },
    handleSubmit() {
      const userId = this.userId
      // const account = JSON.parse(this.ruleForm.account);

      const filterAccount = this.data.filter((item) => item.id == this.ruleForm.account)
      const account = filterAccount[0]

      this.$refs.ruleFormRef.validate(async (valid, fields) => {
        if (valid) {
          const response = await this.checkLimitLinkInstagram(account)
          if (response?.status === false) {
            this.onClose()
            return this.$toast.error(response?.error)
          }

          const res = await this.checkAccountPost(account.username)
          if (res?.status === false) {
            this.onClose()
            return this.$toast.error(this.$t('Account already has posts'))
          }

          Ins.insAccountLink(account, userId)
            .then((res) => {
              if (res.data.data == null) {
                this.onClose()
                return this.$toast.error(this.$t('Account link failed'))
              }
              this.onClose()
              this.$toast.success(this.$t('Account link successful'))
              this.$emit('onSubmitSuccess', res.data.data.username)
              this.ruleForm.account = null

              this.runBatch(res.data.data.business_id)
              this.reloadPage()
            })
            .catch((error) => {
              this.onClose()
              this.$toast.error(this.$t('Account link failed'))
              console.log(error)
            })
        }
      })
    },

    async checkAccountPost(accountIns) {
      const { data: data } = await axios.get(route('api.account.post', accountIns))

      return data
    },

    async runBatch(business_id) {
      await axios.get(route('api.account.run-batch', business_id))
    },

    async checkLimitLinkInstagram(accountIns) {
      const { data: data } = await axios.get(route('api.account.check-limit-account', accountIns))

      return data
    },

    reloadPage() {
      window.location.reload()
    },
  },
}
</script>

<style>
.custom-dialog {
  top: 50%;
  transform: translateY(-50%);
  left: 8%;
}
.custom-dialog .el-form-item__content {
  display: block !important;
}
.custom-dialog .el-dialog__body {
  padding: 10px;
}
</style>
