<template>
    <div class="px-6 pt-5">
        <button class="mb-5 flex gap-2">
            <svg
                class="self-center"
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.97803 9.86426L1.00003 5.00201L5.97803 0.834373"
                    stroke="#838383"
                />
            </svg>
            <span>Back</span>
        </button>
        <InfoTable :data="infoTable" />
    </div>
</template>

<script>
import InfoTable from "@/Components/InfoTable.vue";
export default {
    components: {
        InfoTable,
    },
    data() {
        return {
            infoTable: {
                公開日時: "2022/1/1 13:00",
                ラベル: "お知らせ",
                タイトル: "タイトル",
                本文: "testtesttesttesttesttesttesttesttesttesttesttesttesttesttesttesttesttesttestte",
                公開範囲: "全て",
            },
        };
    },
};
</script>

<style scoped></style>
