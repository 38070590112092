<template>
  <h3 class="text-option font-semibold pl-4 pt-4 text-nomal">フォロワー年齢層</h3>
  <Bar
    v-if="showData"
    class="mx-auto p-4"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :plugins="plugins"
    :id="canvasId"
  />
  <NoData v-else></NoData>
</template>

<script>
import { Bar } from 'vue-chartjs'
import NoData from './NoData.vue'
export default {
  name: 'BarChart',
  components: {
    Bar,
    NoData,
  },
  props: {
    range: { type: Object, default: 0 },
    chartId: { type: String, default: 'bar-chart' },
    width: { type: Number, default: 400 },
    height: { type: Number, default: 400 },
    plugins: { type: Array, default: () => [] },
    accountInsights: { type: Array, default: [] },
    canvasId: { type: String},
  },
  data() {
    return {
      accountId: '',
      showData: true,
      listAges: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: '女性',
            backgroundColor: '#D20058',
            data: [],
          },
          {
            label: '男性',
            backgroundColor: '#0097D3',
            data: [],
          },
          {
            label: 'その他',
            backgroundColor: '#DEE2E5',
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          tooltip: {
            xAlign: {
              label: function (context) {
                context.dataset.label.xAlign = 'left'
                return context.dataset.label.xAlign
              },
            },
          },
          legend: {
            position: 'top',
            align: 'start',
            title: {
              display: true,
              position: 'start',
              align: 'start',
            },
          },
          datalabels: {
            display: false,
          },
        },

        elements: {
          bar: {
            borderWidth: 1,
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              borderColor: 'transparent',
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
              borderColor: 'transparent',
            },
          },
        },
      },
    }
  },
  watch: {
    accountInsights: function () {
      this.getData()
    },
  },
  methods: {
    async getData() {
      if (this.accountInsights.length > 0) {
        await this.prepareDataForChart()
      }
    },
    prepareDataForChart() {
      this.listAges = []
      this.accountInsights.forEach((accountInsight) => {
        if (accountInsight.audience_gender_age) {
          this.listAges.push(accountInsight.audience_gender_age)
        }
      })

      const dataLabel = []
      let totalDataAge = {}

      this.listAges.forEach((listAge) => {
        const dataAge = JSON.parse(listAge)

        for (const [index, item] of Object.entries(dataAge)) {
          //add labels
          const key = index.split('.')[1]
          if (!dataLabel.includes(key)) {
            dataLabel.push(key) || 0
          }

          //Calculation total
          if (totalDataAge.hasOwnProperty(index)) {
            totalDataAge[index] += item
          } else {
            totalDataAge[index] = item
          }
        }
      })

      let dataWomen = []
      let dataMen = []
      let dataOther = []
      for (const [index, item] of Object.entries(totalDataAge)) {
        if (index.includes('F.')) {
          dataWomen.push(item)
        }
        if (index.includes('M.')) {
          dataMen.push(item)
        }
        if (index.includes('U.')) {
          dataOther.push(item)
        }
      }

      this.chartData.labels = dataLabel
      this.chartData.datasets[0].data = dataWomen
      this.chartData.datasets[1].data = dataMen
      this.chartData.datasets[2].data = dataOther
    },
  },
}
</script>

<style></style>
