<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Line } from 'vue-chartjs'
import { CategoryScale } from 'chart.js'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement
)

export default {
  name: 'BarChart',
  components: { Bar, Line },
  props: {
    chartId: { type: String, default: 'bar-chart' },
    datasetIdKey: { type: String, default: 'label' },
    width: { type: Number, default: 400 },
    height: { type: Number, default: 400 },
    cssClasses: { default: '', type: String },
    styles: { type: Object, default: () => {} },
    plugins: { type: Object, default: () => {} },
    chartData: { label: [], datasets: [] },
    chartOptions: { type: Object, default: () => {} },
  },
  data() {
    return {}
  },
}
</script>
