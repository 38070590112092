<script setup>
import AppSidebar from '@/Layouts/AppSidebar'
import AppHeader from '@/Layouts/AppHeader'
function hiddenMenu() {
  let sidebarMenu = document.getElementById('sidebar-menu')
  sidebarMenu.classList.remove('sidebar-mobile__active')
  let mainPage = document.getElementById('main-page')
  mainPage.classList.remove('main-page__active')
}
</script>


<template>
  <div class="flex h-screen overflow-hidden">
    <AppSidebar />
    <div class="wrapper relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
      <div @click="hiddenMenu" id="main-page"></div>
      <AppHeader />
      <div class="nav-breakcumb">
        <slot name="breakcumb"></slot>
        <slot name="tabs"></slot>
      </div>
      <div class="bg-background w-full">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

