<template>
    <!--Side bar-->
    <div id="sidebar-menu"
        class="flex flex-col sidebar-mobile h-screen relative z-50 left-0 top-0 lg:relative lg:overflow-y-auto bg-sidebar lg:sidebar-expanded:!w-64 shrink-0 p-4 transition-all scrollbar:w-1.5 scrollbar:h-1.5 pt-0">
        <!-- Sidebar -->
        <div class="flex justify-between px-4 py-4">
            <!--Logo-->
            <Link class="py-2" :href="(menus || []).first()?.link">
            <img :src="$page.props.auth.user.image_logo ? $page.props.auth.user.image_logo : defaultLogo"
                alt="Favicon Upscale" class="h-[40px]" />
            </Link>
        </div>
        <!-- End Sidebar-->
        <!-- Link-->
        <div class="space-y-8">
            <!-- Pages group -->
            <nav>
                <ul class="mt-4 u-list">
                    <li v-for="menu in menus" :key="menu.id" class="px-4 pt-3 mb-0.5">
                        <Link :href="menu.link"
                            :class="['single-link', parseSubmenu([menu.link] || []).includes($page.url) ? 'is-active' : '']"
                            v-if="!menu.child">
                        <div class="flex items-center last:text-item_link relative" :style="titleColor">
                            <icon :icon="menu.icon" />
                            <span class="text-md leading-4 font-normal ml-3 hover:text-option font-sans">{{ menu.name
                            }}</span>
                        </div>
                        </Link>
                        <div class="py-2 mb-0.5" v-if="menu.child">
                            <p class="text-xs text-item">{{ menu.name }}</p>
                        </div>
                        <ul v-if="menu.child">
                            <li class="items-center pl-6 py-2 mb-0.5 text-item_link hover:text-option font-sans"
                                v-for="submenu in menu.child" :key="submenu.id">
                                <Link :href="submenu.link"
                                    :class="{ 'is-active': checkCaseMenuActive(submenu.link, $page.url) }"
                                    class="text-md">
                                    <div class="flex items-center">
                                        <div>
                                            {{ submenu.name }}
                                        </div>
                                        <div v-if="submenu.id === 'post_analysis' && submenu.name === '投稿分析' && this.$page?.props?.auth?.errorPost > 0">
                                            <div class="w-6 h-6 bg-[#ff0000] text-white text-[13px] ml-1 flex justify-center items-center rounded-[50%]">{{ this.$page?.props?.auth?.errorPost }}</div>
                                        </div>
                                    </div>
                                </Link>
                                <!-- <div v-if="submenu.id === 'business_analysis' && submenu.name === '投稿分析'">
                                    <div class="w-6 h-6 bg-[#ff0000] text-white text-[13px] ml-1 flex justify-center items-center rounded-[50%]">{{ this.groupCount }}</div>
                                </div> -->
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
        <!-- End link-->
    </div>
    <!--End side bar-->
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Components/Icon.vue'
import {
    useInstagramMenu,
    useReportMenu,
    useOtherMenu,
    useDashboardMenu,
    useAccountMenu,
    useSettingMenu,
} from '@/Composables/menu'

export default {
    name: 'AppSidebar',
    data() {
        return {
            defaultLogo: window.location.origin + '/img/favicon-upscale.png',
            titleColor: {
                color: '#414141',
            },
            locationOrigin: '',
            prefixMenu: '',
            // normalCount: this.$page?.props?.auth?.errorPost,
            // groupCount: 0,
        }
    },
    components: {
        Link,
        Icon,
    },
    created() {
        this.prefixMenu = window.location.pathname
        let logoCompany = this.$page.props.auth.user.image_logo
        this.logoUrl = logoCompany ? logoCompany : window.location.origin + '/img/favicon-upscale.png'
    },
    computed: {
        user() {
            return this.$page?.props?.auth?.user
        },
        menus() {
            const type = this.$page?.admin?.type

            let menus = [
                {
                    icon: 'large',
                    name: 'ダッシュボード',
                    line: true,
                    link: useDashboardMenu().value,
                },
            ]
            const instagramMenu = this.filterMenuAllow(useInstagramMenu().value)
            if (instagramMenu.length > 0) {
                menus = [
                    ...menus,
                    {
                        name: 'Instagram 分析',
                        link: '/post',
                        child: instagramMenu,
                    },
                ]
            }

            const reportMenu = this.filterMenuAllow(useReportMenu().value)
            if (reportMenu.length > 0) {
                menus = [
                    ...menus,
                    {
                        name: 'レポーティング',
                        child: reportMenu,
                    },
                ]
            }

            const otherMenu = this.filterMenuAllow(useOtherMenu().value)
            if (otherMenu.length > 0) {
                menus = [
                    ...menus,
                    {
                        name: 'エンタープライズ向け',
                        child: otherMenu,
                    },
                ]
            }

            const accountMenu = this.filterMenuAllow(useAccountMenu().value)
            if (accountMenu.length > 0) {
                menus = [
                    ...menus,
                    {
                        name: 'アカウント管理',
                        child: accountMenu,
                    },
                ]
            }
            const settingMenu = this.filterMenuAllow(useSettingMenu().value)
            if (settingMenu.length > 0) {
                menus = [
                    ...menus,
                    {
                        name: '設定',
                        child: settingMenu,
                    },
                ]
            }
            return menus
        },
    },
    methods: {
        // getErrorPost(){
        //     axios
        //     .get(route('api.get-total-error-post'))
        //     .then((res) => {
        //             this.normalCount = res.data
        //             // this.groupCount = res.data.groupCount
        //         })
        // },
        hasPermission(permission) {
            const permissions = this.user?.permissions
            return permissions.find((item) => item.name === permission)
        },
        hasRole(roleName) {
            const role = this.user?.roles?.first()
            return role?.name === roleName
        },
        filterMenuAllow(menus, key = 'isAllow') {
            return menus.filter((item) => item[key])
        },
        parseSubmenu(subMenus) {
            return (subMenus || []).map((item) => {
                try {
                    return new URL(item).pathname
                } catch (error) {
                    return item
                }
            })
        },
        checkActiveMenuByPrefix(menu) {
            return menu.link.includes(this.prefixMenu)
        },
        checkCaseMenuActive(submenuLink, page) {
            const pageUrl = page?.split('?')[0]
            if (
                submenuLink == window.location.origin + pageUrl ||
                (submenuLink == window.location.origin + '/post' && pageUrl.includes('/post/analytic')) ||
                (submenuLink == window.location.origin + '/post' && pageUrl.includes('/post/edit')) ||
                (submenuLink == window.location.origin + '/group-post-manage' && pageUrl.includes('/group-post-manage')) ||
                (submenuLink == window.location.origin + '/group-list' && pageUrl.includes('/group-analysis')) ||
                (submenuLink == window.location.origin + '/group-post-manage' && pageUrl.includes('/post-group/analytic')) ||
                (submenuLink == window.location.origin + '/group-post-manage' && pageUrl.includes('/post-group/edit')) ||
                (submenuLink == window.location.origin + '/fb-advertises' && pageUrl.includes('/advertise-analysis/detail')) ||
                (submenuLink == window.location.origin + '/fb-advertises' && pageUrl.includes('/post-advertisment')) ||
                (submenuLink == window.location.origin + '/group-list' && pageUrl.includes('/group-list/')) ||
                (submenuLink == window.location.origin + '/hashtag-analysis' && pageUrl.includes('/hashtag-analysis')) ||
                (submenuLink == window.location.origin + '/users/analytic/compete' &&
                    pageUrl.includes('/users/analytic/compete')) ||
                (submenuLink == window.location.origin + '/plans' && pageUrl.includes('/plans/')) ||
                (submenuLink == window.location.origin + '/notifications' && pageUrl.includes('/notifications/')) ||
                (submenuLink == window.location.origin + '/users' && pageUrl.includes('/users-detail/')) ||
                (submenuLink == window.location.origin + '/users' && pageUrl.includes('/users/create'))
            ) {
                return true
            } else {
                return false
            }
        },
    },
}
</script>

<style scoped lang="scss">
.text-md {
    font-size: 13px;
}

#sidebar-menu::-webkit-scrollbar {
    display: none;
}

#sidebar-menu {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.u-list :last-child a span {
    color: #8d8e92;
}

.is-active {
    position: relative;
    display: inline-block;
    width: 100%;
    color: #414141;

    &:after {
        content: '';
        position: absolute;
        right: -32px;
        top: 0;
        width: 5px;
        height: 100%;
        background-color: #be3d8f;
    }
}
</style>
