<template>
  <Dashboard>
    <template v-slot:breakcumb>
      <Loading v-if="fullscreenLoading" />
      <BreadCrumb class="z-10">
        <li class="mr-4">
          <Link :href="route('admin.export.report.pptx')" class="font-normal breakcumb-active">
            レポート作成 (CSVとPDF)
          </Link>
        </li>
      </BreadCrumb>
    </template>

    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs z-10">
        <ul class="flex">
          <li class="px-2 relative pb-5 active">レポート作成 (CSVとPDF)</li>
        </ul>
      </div>
      <div class="bg-background relative">
        <LinkInstagram v-if="!showLinkInstagram" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
        <UserNav />
        <div v-if="isRole !== 'master'">
          <h2 class="text-center mt-10 mb-4 font-bold text-3sm">
            {{ plan?.name }} では毎月 {{ plan?.number_report_pdf }} 回までPDFレポートを作成出来ます。
          </h2>
          <p class="text-center text-2sm">
            ＠{{ currentUser }}では今月後 {{ numberExport < 0 ? 0 : numberExport }} 回PDFレポートを作成出来ます。
          </p>
        </div>
        <div
          v-if="isRole === 'master' || planPermission?.plan?.account_analysis == 1"
          class="sm:px-0 md:px-10 pb-10"
          style="z-index: 5 !important"
        >
          <div class="flex-grow-1 relative">
            <AccountAnalytic
              :accountLineChartID="accountLineChart"
              :accountPieChartID="accountPieChart"
              :accountStackBarChartID="accountStackBarChart"
              :accountHorizontalChartID="accountHorizontalChart"
              :accountRangeDateTimeID="accountRangeDateTimeID"
              :userId="userIdSelected"
              :numberExport="numberExport"
              :plan="plan"
              @onChangeRangeAccount="onChangeRangeAccount"
              @getAccountTableData="getAccountTableData"
              @mapAccountHashtagTableData="mapAccountHashtagTableData"
              @exportPdf="exportPdf"
            />
          </div>
        </div>
        <PostHashtag
          v-if="isRole === 'master' || planPermission?.plan?.post_hashtag == 1"
          :plan="plan"
          @getPostHashtagTableData="getPostHashtagTableData"
          @getReelHashtagTableData="getReelHashtagTableData"
          @getPostRankingTableData="getPostRankingTableData"
          @getReelRankingTableData="getReelRankingTableData"
          @getHashtagCurrTab="getHashtagCurrTab"
        />
        <ListPostAnalytic
          v-if="isRole === 'master' || planPermission?.plan?.post_analysis == 1"
          :plan="plan"
          :userId="userIdSelected"
          @getPostTableData="getPostTableData"
          @getDetailPost="getDetailPost"
          @getListImgPostTableData="getListImgPostTableData"
        />
        <PostAnalytic
          v-if="
            isRole === 'master' ||
            (this.planPermission?.plan?.initial_velocity_analysis == 1 && this.planPermission?.plan?.post_analysis == 1)
          "
          id="post-detail-element"
          :plan="plan"
          :userId="userIdSelected"
          :postAnalyticChart="postAnalyticChart"
          :postItem="postDetail"
          @getDetailPostTableData="getDetailPostTableData"
          @getDetailPostTableDataImg="getDetailPostTableDataImg"
          @getDetailPostTableDataCategory="getDetailPostTableDataCategory"
          @getTimePostTableData="getTimePostTableData"
        />
        <AnalyticPost
          v-if="isRole === 'master' || planPermission?.plan?.post_analysis == 1"
          :plan="plan"
          :userId="userIdSelected"
          :canvasId="analyticPostChart"
          @isData="isData"
          @getAnalyticPostTableData="getAnalyticPostTableData"
          @getAnalyticPostRange="getAnalyticPostRange"
          @getAnalyticPostCategory="getAnalyticPostCategory"
        />

        <StoryAnalytic
          v-if="isRole === 'master' || planPermission?.plan?.story_analysis == 1"
          :plan="plan"
          :userId="userIdSelected"
          @getTableStoryData="getTableStoryData"
          @getlistImgTableStoryData="getlistImgTableStoryData"
          @getStoriesAnalysis="getStoriesAnalysis"
        />
        <Creative
          v-if="isRole === 'master' || planPermission?.plan?.creative_analysis == 1"
          :plan="plan"
          :userId="userIdSelected"
          @getTopWorstPosts="getTopWorstPosts"
          @getTopBestPosts="getTopBestPosts"
          @getTopWorstPostCategory="getTopWorstPostCategory"
        />
        <PostCommentAnalytic
          v-if="isRole === 'master' || planPermission?.plan?.comment_analysis == 1"
          :plan="plan"
          :userId="userIdSelected"
          @getPostSelected="getPostSelected"
          @getPostSelectedImg="getPostSelectedImg"
          @getPostSelectedComment="getPostSelectedComment"
          @getCmtPostCategory="getCmtPostCategory"
        />
        <CompeteAccount
          v-if="isRole === 'master' || planPermission?.plan?.co_opetition_analysis == 1"
          :competeAnalysisChartID="competeAnalysisChart"
          @mapDataCompeteFollower="mapDataCompeteFollower"
          @mapDataCompeteAccount="mapDataCompeteAccount"
          @mapDataFirstDayAcc="mapDataFirstDayAcc"
          @getAccountTransitionTab="getAccountTransitionTab"
          @getAccCompete="getAccCompete"
        />
        <EngagementAnalysis
          v-if="isRole === 'master' || planPermission?.plan?.user_analysis == 1"
          @getPostCategory="getPostCategory"
          @getPostInfo="getPostInfo"
          @getAnalysisHourCurrTab="getAnalysisHourCurrTab"
          :engagementAnalysisChartID="engagementAnalysisChart"
          @getAnalysisHourCurrTabHour="getAnalysisHourCurrTabHour"
          @getAnalysisHourCurrTabTime="getAnalysisHourCurrTabTime"
        />
        <div v-if="isRole === 'master' || planPermission?.plan?.hashtag_analysis == 1">
          <HashtagAnalytic @getDataHashtagsAnalysis="getDataHashtagsAnalysis" @getDetailHashtag="getDetailHashtag" />
          <HashtagDetail
            id="hashtag-detail-element"
            :hashtagDetailAnalyticChartID="hashtagDetailAnalyticChart"
            :hashtagItem="hashtagDetail"
            @getHashtagTableData="getHashtagTableData"
            @getDataElacticHashtag="getDataElacticHashtag"
            @getDetailHashtagRange="getDetailHashtagRange"
            @getDetailHashtagLabel="getDetailHashtagLabel"
            @getDetailHashtagPopularDate="getDetailHashtagPopularDate"
          />
        </div>
        <div class="flex items-center justify-center py-20">
          <button
            :disabled="numberExport <= 0 && isRole !== 'master'"
            class="bg-black py-4 px-8.5 text-white !text-[14px] mr-2 ml-5 button-shadow rounded"
            style="background-color: #5264cd"
            @click="handleClick()"
          >
            PDF出力
          </button>
        </div>
      </div>
    </template>
  </Dashboard>
</template>

<script>
import Dashboard from './ExportDashboard.vue'
import AccountAnalytic from './Account/Charts/Index.vue'
import PostHashtag from './PostHashtag.vue'
import ListPostAnalytic from './Posts/Statistic/Index.vue'
import AnalyticPost from '../Users/Analysis/Post/Index.vue'
import PostAnalytic from './Posts/Statistic/Analytic.vue'
import StoryAnalytic from './Story/Index.vue'
import Creative from '../Posts/Creative/Index.vue'
import PostCommentAnalytic from '../Posts/Comment/Index.vue'
import CompeteAccount from '../Users/Analysis/Compete/Index.vue'
import HashtagAnalytic from '../Hashtag/Analysis/Index.vue'
import HashtagDetail from '../Hashtag/Analysis/Detail.vue'
import EngagementAnalysis from '../Posts/AnalysisHours/Index.vue'
import { Link } from '@inertiajs/inertia-vue3'
import AccAnalyticMixin from './Mixins/accountAnalyticMixin'
import ImgToBase64Mixin from './Mixins/imgToBase64Mixin'
import * as accountAPI from '@/Api/Account'
import dayjs from 'dayjs'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import InputFilterAccount from './InputFilterAccount.vue'
import UserNav from '@/Components/UserNav.vue'
import LinkInstagram from '@/Components/LinkInstagram.vue'

import genHashtagHourAnalyticPage from './PdfPage/genHashtagHourAnalyticPage.js'
import genHashtagHourAnalyticIMGPage from './PdfPage/genHashtagHourAnalyticIMGPage.js'
import genHashtagDetailAnalyticPage from './PdfPage/genHashtagDetailAnalyticPage.js'
import genEngagementAnalysisPage from './PdfPage/genEngagementAnalysisPage.js'
import genCompeteAccountPage from './PdfPage/genCompeteAccountPage.js'
import genHeaderFooterPage from './PdfPage/genHeaderFooterPage.js'
import genCommentAnalyticPage from './PdfPage/genCommentAnalyticPage.js'
import genTopAndWorstPostPage from './PdfPage/genTopAndWorstPostPage.js'
import genStoryAnalyticPage from './PdfPage/genStoryAnalyticPage.js'
import genChangePostAnalyticPage from './PdfPage/genChangePostAnalyticPage.js'
import genPostSelectedPage from './PdfPage/genPostSelectedPage.js'
import genListPostPage from './PdfPage/genListPostPage.js'
import genHashtagAccountPage from './PdfPage/genHashtagAccountPage.js'
import genAccountAnalyticPage from './PdfPage/genAccountAnalyticPage.js'
import PDF from './Mixins/pdf.js'
import { fetchNumberExport, addNumberExport } from './Mixins/limitExport.js'
import Loading from '@/Components/Loadding'

export default {
  name: 'Index.vue',
  components: {
    Dashboard,
    Loading,
    AccountAnalytic,
    PostHashtag,
    ListPostAnalytic,
    PostAnalytic,
    AnalyticPost,
    StoryAnalytic,
    Creative,
    PostCommentAnalytic,
    CompeteAccount,
    HashtagAnalytic,
    HashtagDetail,
    EngagementAnalysis,
    Link,
    BreadCrumb,
    InputFilterAccount,
    UserNav,
    LinkInstagram,
  },
  props: {
    plan: { type: Object, default: null },
    planPermission: { type: Object, default: null },
  },
  computed: {
    currentUser() {
      return this.$page.props?.auth?.account?.username
    },
  },
  mixins: [
    AccAnalyticMixin,
    ImgToBase64Mixin,
    PDF,
    genHashtagHourAnalyticPage,
    genHashtagHourAnalyticIMGPage,
    genHashtagDetailAnalyticPage,
    genEngagementAnalysisPage,
    genCompeteAccountPage,
    genHeaderFooterPage,
    genCommentAnalyticPage,
    genTopAndWorstPostPage,
    genStoryAnalyticPage,
    genChangePostAnalyticPage,
    genPostSelectedPage,
    genListPostPage,
    genHashtagAccountPage,
    genAccountAnalyticPage,
  ],
  data() {
    return {
      accountRangeDateTimeID: 'account-range-date-time',
      accountLineChart: 'account-line-chart',
      accountPieChart: 'account-pie-chart',
      accountStackBarChart: 'account-stack-bar-chart',
      accountHorizontalChart: 'account-horizontal-chart',
      analyticPostChart: 'analytic-post-chart',
      postAnalyticChart: 'post-analytic-chart',
      competeAnalysisChart: 'compete-analytic-chart',
      hashtagAnalyticChart: 'hashtag-analytic-chart',
      hashtagDetailAnalyticChart: 'hashtag-detail-analytic-chart',
      engagementAnalysisChart: 'engagement-analytic-chart',
      arrXHR: 0,
      numberXHR: false,
    }
  },
  mounted() {
    this.fetchNumberExportMonth()
  },
  async created() {
    await this.checkLinkInstagram()
    this.calculatorTime()
  },
  methods: {
    onInstaSubmitSuccess: function () {
      this.showLinkInstagram = true
    },
    // link instagram
    checkLinkInstagram: async function () {
      const check = await accountAPI.checkInsAccount()
      if (check.data) {
        this.showLinkInstagram = true
      } else {
        this.showLinkInstagram = false
      }
    },
    changeUserSelected: function (user) {
      this.userSelected = user
    },
    exportPdf: function () {
      this.handleClick()
    },
    async handleClick() {
      this.fullscreenLoading = true
      if (this.numberExport === 0) {
        return
      }
      let checkSuccess = false
      setTimeout(async () => {
        try {
          checkSuccess = true
          this.exportPDFFile()
          if (checkSuccess && this.isRole !== 'master') {
            await axios.post(route('api.user.export'), {
              type: 'pdf',
              btn_id: 'pdf',
            })
            this.fetchNumberExportMonth()
          }
        } catch (error) {
          console.log(error)
        }
      }, 100)
    },
    async exportPDFFile() {
      // declare doc jsPDF
      const doc = new jsPDF()
      doc.setFontSize(10)
      // add font
      doc.addFont('/fonts/GenShinGothic.ttf', 'GenShinGothic', 'normal')
      doc.setFont('GenShinGothic', 'normal')

      // declare variable for add img to pdf file
      let pdfSetting = this.pdfSetting
      let imgPostTableData = this.listImgPostTableData
      let postSelectedImg = this.postSelectedImg
      let detailPostImg = this.detailPostTableDataImg
      let detailPostCategory = this.detailPostTableDataCategory
      let listImgTableStoryData = this.listImgTableStoryData

      // account analytic
      if (this.isRole === 'master' || this.planPermission?.plan?.account_analysis == 1) {
        await this.genAccountAnalyticPage(doc)
        doc.addPage()
      }

      // phan tich hashtag acc
      if (
        (this.isRole === 'master' || this.planPermission?.plan?.post_hashtag == 1) &&
        (this.tablePostHashtagData.length != 0 || this.tableReelHashtagData.length != 0)
      ) {
        await this.genHashtagAccountPage(doc)
        doc.addPage()
      }

      // list post
      if ((this.isRole === 'master' || this.planPermission?.plan?.post_analysis == 1) && imgPostTableData.length != 0) {
        await this.genListPostPage(doc, imgPostTableData)
        doc.addPage()
      }

      // phan tich bai post selected
      if (
        (this.isRole === 'master' ||
          (this.planPermission?.plan?.initial_velocity_analysis == 1 &&
            this.planPermission?.plan?.post_analysis == 1)) &&
        detailPostImg.length != 0
      ) {
        await this.genPostSelectedPage(doc, detailPostImg, detailPostCategory)
        doc.addPage()
      }

      // phan tich bien dong post
      if ((this.isRole === 'master' || this.planPermission?.plan?.post_analysis == 1) && this.isData) {
        await this.genChangePostAnalyticPage(doc)
        doc.addPage()
      }

      // phan tich story
      if (
        (this.isRole === 'master' || this.planPermission?.plan?.story_analysis == 1) &&
        listImgTableStoryData.length != 0
      ) {
        await this.genStoryAnalyticPage(doc, listImgTableStoryData)
        doc.addPage()
      }

      // phan tich top post
      if (
        (this.isRole === 'master' || this.planPermission?.plan?.creative_analysis == 1) &&
        this.topBestPosts.length != 0
      ) {
        await this.genTopAndWorstPostPage(doc)
        doc.addPage()
      }

      // phan tich comment
      if (
        (this.isRole === 'master' || this.planPermission?.plan?.comment_analysis == 1) &&
        postSelectedImg.length != 0
      ) {
        await this.genCommentAnalyticPage(doc, postSelectedImg)
        doc.addPage()
      }

      // phan tich canh tranh
      if (
        (this.isRole === 'master' || this.planPermission?.plan?.co_opetition_analysis == 1) &&
        this.competeAccData.length != 0
      ) {
        this.genCompeteAccountPage(doc)
        doc.addPage()
      }

      // phan tich engagement
      if (
        (this.isRole === 'master' || this.planPermission?.plan?.user_analysis == 1) &&
        this.postInformation.sumPost != 0
      ) {
        await this.genEngagementAnalysisPage(doc)
        doc.addPage()
      }

      // demo hashtag pdf
      if ((this.isRole === 'master' || this.planPermission?.plan?.hashtag_analysis == 1) && this.hashtagDetail) {
        this.genHashtagHourAnalyticPage(doc)
        this.genHashtagDetailAnalyticPage(doc)
        await this.genHashtagHourAnalyticIMGPage(doc)
      }

      // create header-footer
      await this.genHeaderFooterPage(doc, pdfSetting)

      this.fullscreenLoading = false

      doc.save('Instagramレポート.pdf')
    },
    // get number export per month
    async fetchNumberExportMonth() {
      const { data } = await fetchNumberExport('pdf', 'pdf')
      this.numberExport = this.plan?.number_report_pdf - data
    },
    calculatorTime() {
      let myInterval = setInterval(() => {
        this.arrXHR = window.performance.getEntries().filter((item) => {
          return item.initiatorType === 'xmlhttprequest'
        })
      }, 5000)
      setInterval(() => {
        if (this.numberXHR) {
          clearInterval(myInterval)
        }
      }, 5000)
    },
  },
  watch: {
    arrXHR(newVal, oldVal) {
      if (Number(newVal.length) - Number(oldVal.length) == 0) {
        this.fullscreenLoading = false
        this.numberXHR = true
      }
    },
  },
}
</script>
<style scoped>
table {
  border-collapse: collapse;
}

.user-tags {
  border-radius: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.search_icon {
  padding: 10px 16px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #d02d92;
}

.select__status {
  background: #ffffff;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
}

.el-popper.is-pure.is-light.el-select__popper {
  z-index: 50 !important;
}
</style>
<style>
.bg-background.relative .popup-instagram .el-overlay {
  position: absolute;
}
</style>
