/**
 * Format a value (string, number, etc) to integer with type ja-JP
 *
 * @param {number|string} value
 * @returns {number|NaN} Returns NaN if the value cannot be converted
 */
export function formatToInteger(value) {
  return value ? Number(value).toLocaleString('ja-JP', { maximumFractionDigits: 0 }) : 0
}

/**
 * Format a value (string, number, etc) to string representation with 'precision' digits after the decimal
 *
 * @param {number|string} value
 * @param {number|string} precision
 * @returns {number|NaN} Returns NaN if the value cannot be converted
 */
export function formatToFloat(value, precision = 2) {
  if (value) {
    if (typeof value === 'string') {
      const checkHasPercent = value?.includes('%')
      const number = checkHasPercent ? Number(value?.replace('%', '')) : Number(value)
      const result =
        number >= 1000
          ? number.toLocaleString('ja-JP', { maximumFractionDigits: 0 })
          : number.toLocaleString('ja-JP', { maximumFractionDigits: precision })
      return checkHasPercent ? result + '%' : result
    } else {
      return value >= 1000
        ? value.toLocaleString('ja-JP', { maximumFractionDigits: 0 })
        : value.toLocaleString('ja-JP', { maximumFractionDigits: precision })
    }
  }
  return 0
}
