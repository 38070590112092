export default {
  methods: {
    genSlideTerms(pptx, title, content, text) {
      let slideTerms = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideTerms.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      let arrRowsSlideTerms = []
      arrRowsSlideTerms.push([
        {
          text: title[0],
          options: { fill: 'e7299e', color: 'ffffff', align: 'center', valign: 'middle', fontSize: 10 },
        },
        {
          text: title[1],
          options: { fill: 'e7299e', color: 'ffffff', align: 'center', valign: 'middle', fontSize: 10 },
        },
      ])
      content[0].forEach((name, index) => {
        let strText = content[1][index]
        arrRowsSlideTerms.push([
          { text: name, options: { color: '000000', align: 'left', valign: 'middle', bold: true, fontSize: 10 } },
          { text: strText, options: { color: '000000', align: 'left', valign: 'middle', fontSize: 9 } },
        ])
      })
      slideTerms.addTable(arrRowsSlideTerms, { x: 0.39, y: 1.11, colW: [2.7, 7.6], border: { color: '000000' } })
      return slideTerms
    },
  },
}
