<template>
    <div>
        <div v-if="openTab === 1">
            <Information :user="user" :toggleTabs="toggleTabs" :fetchDataUser="fetchDataUser" />
        </div>
        <div v-if="openTab === 2">
            <Plan :user="user" :toggleTabs="toggleTabs" :fetchDataUser="fetchDataUser" />
        </div>
        <div v-if="openTab === 3">
            <Invoice :user="user" :toggleTabs="toggleTabs" />
        </div>
    </div>
</template>

<script>
import Information from './Tabs/Information.vue'
import Invoice from './Tabs/Invoice.vue'
import Plan from './Tabs/Plan.vue'

export default {
    props: {
        user_id: { type: Number, required: true },
        activeTab: { type: String },
    },

    components: {
        Information,
        Invoice,
        Plan,
    },

    data() {
        return {
            openTab: 1,
            user: {},
            check_role: false,
        }
    },

    created() {
        this.fetchDataUser()
    },

    methods: {
        toggleTabs(tab) {
            this.openTab = tab
            this.$inertia.get(route('admin.users_detail', this.user_id), { activeTab: tab }, { replace: true, preserveState: true })
        },

        async fetchDataUser() {
            await axios
                .get(route('api.users.show', this.user_id))
                .then((res) => {
                    this.user = res.data.data
                    this.openTab = this.activeTab ? Number(this.activeTab) : 1
                })
                .catch((err) => {
                    console.error(err)
                })
        },
    },
}
</script>
