<template>
  <div v-loading="loading">
    <data-table
      :fields="fields"
      :items="hashtags.data"
      :paginate="hashtags.meta"
      :current-page="filter.page || 1"
      disable-table-info
      footer-center
      paginate-background
      @page="handleCurrentPage"
      class="el-table-header__black"
    >
      <template #hashtag_name="{ row }">
        <el-link :href="`${URL_INSTAGRAM}/${row?.hashtag_name?.replace('#', '')}`" class="text-pink" target="_blank">
          <p>{{ row?.hashtag_name }}</p>
        </el-link>
      </template>
      <template #hashtag_using="{ row }">
        <p>{{ formatToInteger(row?.totalCount) }}</p>
      </template>
      <template #hashtag_likes="{ row }">
        <p>{{ formatToInteger(row?.hashtag_likes) }}</p>
      </template>
      <template #hashtag_reachs="{ row }">
        <p>{{ formatToInteger(row?.hashtag_reachs) }}</p>
      </template>
      <template #hashtag_comments="{ row }">
        <p>{{ formatToInteger(row?.hashtag_comments) }}</p>
      </template>
      <template #hashtag_saves="{ row }">
        <p>{{ formatToInteger(row?.hashtag_saves) }}</p>
      </template>
      <template #hashtag_impressions="{ row }">
        <p>{{ row?.hashtag_impressions ? formatToInteger(row?.hashtag_impressions) : '0' }}</p>
      </template>
      <template #engagement_rate="{ row }">
        <p>{{ formatToFloat(row?.engagement_rate + '%') }}</p>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/Components/UI/DataTable.vue'

export default {
  components: { DataTable },
  props: {
    range: { type: Object, default: 0 },
    year: { type: Object, default: 0 },
    analyticType: { type: String, default: undefined },
  },
  data() {
    return {
      loading: false,
      hashtags: {},
      filter: {
        page: 1,
        name: null,
        limit: 10,
      },
      fields: [
        {
          key: 'hashtag_name',
          label: 'ハッシュタグ',
          minWidth: 300,
          fixed: window.innerWidth > 600 ? 'left' : false,
          align: 'center',
        },
        { key: 'hashtag_using', label: '使用回数', width: 170, align: 'center' },
        { key: 'hashtag_likes', label: 'いいね数', width: 170, align: 'center' },
        { key: 'hashtag_reachs', label: 'リーチ数', width: 170, align: 'center' },
        { key: 'hashtag_comments', label: 'コメント数', width: 170, align: 'center' },
        { key: 'hashtag_saves', label: '保存数', width: 170, align: 'center' },
        { key: 'hashtag_impressions', label: 'インプレッション数', width: 170, align: 'center' },
        { key: 'engagement_rate', label: 'エンゲージメント率', width: 170, align: 'center' },
      ],
      URL_INSTAGRAM: 'https://www.instagram.com/explore/tags',
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    range: function () {
      this.fetchData()
    },
    year: function () {
      this.fetchData()
    },
  },
  methods: {
    // fetch data
    async fetchData() {
      this.loading = true
      await axios
        .get(
          route('api.account.get-hashtag.all', {
            filter: { name: this.filter?.name },
            limit: this.filter?.limit,
            page: this.filter?.page,
            checkHashtagAll: true,
          })
        )
        .then((res) => {
          this.hashtags = res?.data
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    handleCurrentPage(value) {
      this.filter.page = value
      this.fetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.text-pink {
  &:hover,
  p {
    color: #be3d8f !important;
    text-decoration: underline;
  }
}

.el-link.is-underline:hover:after {
  border-bottom: none !important;
}
</style>
