<template>
  <div class="w-full">
    <el-row>
      <el-col
        :xs="11"
        :sm="7"
        :md="7"
        :lg="4"
        :xl="4"
        v-for="(file, index) in listFilePreview"
        :key="index"
        class="mb-[10px] mr-1"
      >
        <div :class="classImage" class="mr-2 relative inline-block group">
          <el-image
            v-if="file.type?.split('/')[0] == 'image'"
            :src="file.url"
            class="rounded w-full h-full object-cover"
            alt
            fit="cover"
          >
            <template #error>
              <div class="image-slot-error">
                <el-icon size="25"><icon-picture /></el-icon>
              </div>
            </template>
          </el-image>

          <video
            v-else
            width="300"
            :src="file.url"
            controls
            autoplay
            class="rounded w-full h-full object-cover"
          ></video>

          <!-- icon delete -->
          <span
            class="hidden absolute top-0 w-full h-full rounded cursor-pointer justify-center items-center"
            :class="{ 'group-hover:flex': !disabledDelete }"
            style="background-color: rgba(0, 0, 0, 0.7)"
          >
            <i @click.prevent="removeFile(index, file)" class="fa-solid fa-trash-can text-white"></i>
          </span>
        </div>
      </el-col>

      <el-col v-if="listFilePreview.length < 10" :xs="11" :sm="7" :md="7" :lg="4" :xl="4" class="mb-[10px] mr-1">
        <div
          :class="[
            disabledAdd ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer',
            { 'w-36 h-36': !listFilePreview.length },
            classImage,
          ]"
          class="relative inline-block align-top items-center rounded border border-dashed border-black"
          @click="$refs.inputUpload.click()"
        >
          <div class="absolute h-max top-0 bottom-0 right-0 left-0 m-auto text-center text-sm leading-5">
            <img class="inline-block w-5 h-5" src="/images/plus.svg" alt="" />
          </div>
          <input
            class="hidden"
            ref="inputUpload"
            type="file"
            name="file"
            @change="handleFileChange($event)"
            :accept="accept"
            :multiple="isMultiple"
            :disabled="disabledAdd"
          />
        </div>
        <div v-if="!listFilePreview.length" class="text-left text-red-600 text-[12px] mt-2 whitespace-nowrap">
          {{ 'ファイルを選択してください' }}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Picture as IconPicture, Delete, Plus } from '@element-plus/icons-vue'
import { getVideoInfo } from '@/Helpers/video'
export default {
  name: 'UploadFileList',
  components: {
    IconPicture,
    Delete,
    Plus,
  },
  props: {
    accept: {
      type: String,
      default: 'image/png, image/gif, image/jpeg',
    },
    classImage: {
      type: String,
      default: 'w-full h-32',
    },
    disabledAdd: {
      default: false,
    },
    disabledDelete: {
      default: false,
    },
    isMultiple: {
      default: false,
    },
    listFiles: {
      default: {},
    },
    oldFiles: {
      type: Array,
    },
    arrFiles: {
      type: Array,
    },
  },
  data() {
    return {
      listFilePreview: [],
      validateFile: true,
    }
  },
  created() {
    this.listFilePreview = this.oldFiles ? this.listFilePreview.concat(this.oldFiles) : []
    this.listFiles.arrImage = this.listFilePreview
  },
  watch: {
    listFilePreview() {
      this.listFiles.arrImage = this.listFilePreview
    },
  },
  methods: {
    removeFile(index, file) {
      this.listFilePreview.splice(index, 1)
      this.$emit('removeFile', index)
      if (this.oldFiles) {
        let list_old_files = []
        this.oldFiles.forEach((f, i) => {
          list_old_files.push(f.url)
        })
        if (list_old_files.includes(file.url)) {
          this.$emit('removeOldFile', file.url.split('/')[4])
        }
      }
    },
    async handleFileChange(e) {
      this.validateFile = true
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) return

      //   Check if the file meets the conditions
      for (const file of files) {
        if (this.listFilePreview.length >= 10) return

        await this.checkFile(file)
        if (!this.validateFile) return

        let url = URL.createObjectURL(file)
        let type = file.type

        this.listFilePreview.push({ url: url, type: type })
        this.listFiles.arrImage = this.listFilePreview
        this.$emit('uploadFile', file)
      }

      e.target.value = null
    },
    async checkFile(file) {
      const maxImageSize = 8192
      const maxVideoSize = 102400
      const fileType = file.type.split('/')[0]

      if (fileType == 'image') {
        this.checkFileSize(file.size, maxImageSize)

        await this.checkImageUpload(file)
      } else if (fileType == 'video') {
        this.checkFileSize(file.size, maxVideoSize)

        await this.checkVideoUpload(file)
      } else {
        this.setError()
      }
    },
    checkFileSize(size, sizeMax) {
      if (size / 1000 >= sizeMax) {
        return this.setError()
      }
    },
    async checkImageUpload(file) {
      return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL
        let self = this

        let img = new Image()
        img.onload = function () {
          const ratio = this.width / this.height
          const ratioCheck = ratio >= 0.8 && ratio <= 1.91
          const widthCheck = this.width > 5000 || this.width < 320
          const heightCheck = this.height > 10000

          if (!ratioCheck || widthCheck || heightCheck) {
            resolve(self.setError())
          }
          resolve(true)
        }

        img.onerror = function () {
          self.setError()
        }
        img.src = _URL.createObjectURL(file)
      })
    },
    async checkVideoUpload(file) {
      const url = URL.createObjectURL(file)
      const video = document.createElement('video')
      video.src = url
      video.addEventListener('loadedmetadata', function () {
        if (this.videoWidth > 1920 || this.videoHeight > 1080) {
          return this.setError()
        }
      })

      const info = await getVideoInfo(file)
      const checkDuration = info.duration < 3 || info.duration > 60
      const checkFrameRate = info.frameRate < 23 && info.frameRate > 60

      if (checkDuration || checkFrameRate) {
        this.setError()
      }
    },
    setError() {
      this.$toast.error('メディアファイルをアップ失敗しました。も一回選択してください')
      this.validateFile = false
    },
  },
}
</script>
<style>
.image-slot-error {
  height: 100%;
  width: 100%;
  background: #f5f7fa;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
}
</style>
