<script setup>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue'
import AppSidebar from '@/Layouts/AppSidebar'
import AppHeader from '@/Layouts/AppHeader'
import Notification from '@/Components/Notifications.vue'
import { Head } from '@inertiajs/inertia-vue3'

// import PopupNotification from '@/Components/PopupNotification';

const props = defineProps({
  notification: {
    type: Boolean,
    default: true,
  },
})
function hiddenMenu() {
  let sidebarMenu = document.getElementById('sidebar-menu')
  sidebarMenu.classList.remove('sidebar-mobile__active')
  let mainPage = document.getElementById('main-page')
  mainPage.classList.remove('main-page__active')
}
</script>

<template>
  <div class="flex h-screen overflow-hidden">
    <!-- <PopupNotification /> -->
    <AppSidebar />
    <div class="wrapper relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
      <AppHeader />
      <div @click="hiddenMenu" id="main-page"></div>
      <div class="nav-breakcumb">
        <slot name="breakcumb"></slot>
        <slot name="tabs"></slot>
      </div>
      <div class="body flex-grow-1 relative z-10">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
