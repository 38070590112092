<template>
  <div class="text-xl bg-white px-4 pb-10">
    <h2 class="text-button_pink font-semibold pt-5 text-14">アカウント比較</h2>
    <data-table
      :fields="fields"
      :items="dataAllAccount"
      :current-page="filter.page || 1"
      disable-table-info
      footer-center
      paginate-background
      @page="handleCurrentPage"
      class="el-table-header__black mt-5 rou"
    >
      <!-- chart -->
      <template #thumbnail_url="{ row }">
        <div class="flex items-center ml-4">
          <div v-if="row?.image_url" class="w-20 h-20 flex items-center">
            <el-image class="rounded-full" :src="row?.image_url" lazy />
          </div>
          <span
            v-else
            class="inline-flex justify-center items-center avatar w-[5rem] h-[3.5rem] rounded-full header__avatar header__avatar--border border-2 border-solid mr-2"
          >
            {{ row?.fullname.charAt(0) }}
          </span>
          <div class="ml-6 w-full text-left">
            <p>{{ row?.fullname }}</p>
            <a
              target="__blank"
              :href="'https://www.instagram.com/' + row?.username"
              class="cursor-pointer"
              style="color: #be3d8f"
            >
              <i class="fab fa-instagram text-xs"></i>
              Instagramで確認
            </a>
          </div>
        </div>
      </template>
      <template #followers="{ row }">
        <span>{{ formatNumber(row?.followers) }}</span>
      </template>
      <template #follower_change="{ row }">
        <span>{{ calculator(row) ? formatNumber(calculator(row)) : 0 }}</span>
        <span
          :class="{ plus_active: calculatorPercen(row) > 0, minus_active: calculatorPercen(row) < 0 }"
          class="float-right"
        >
          ( {{ calculatorPercen(row) +  '%' }} )
        </span>
      </template>
      <template #followers_change_chart="{ row }">
        <Line
          :chart-options="options"
          :chart-data="getChartData(row?.data_labels, row?.post_last_week)"
          :width="120"
          :height="20"
          :class="canvasID + '-v3'"
          v-if="row?.post_last_week"
        />
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/Components/UI/DataTable.vue'
import { dataAccounts } from './untils/initData'
import { Line } from 'vue-chartjs'
import { CategoryScale } from 'chart.js'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement
)

export default {
  components: { DataTable, Line },
  props: {
    dataAllAccount: { type: Array },
    canvasID: { type: String },
  },

  watch: {
    dataAllAccount: {
      handler() {
        this.getChartData()
      },
    },
  },
  data() {
    return {
      loading: false,
      filter: {
        page: 1,
        limit: 6,
      },
      fields: [
        { key: 'thumbnail_url', label: 'サムネイル', width: 300, align: 'center' },
        { key: 'followers', label: 'フォロワー数', width: 200, align: 'center' },
        { key: 'follower_change', label: 'フォロワー増減', width: 250, align: 'center' },
        { key: 'number_post_last_week', label: '直近7日間のフィード投稿数', width: 250, align: 'center' },
        { key: 'followers_change_chart', label: 'フォロワー数推移', align: 'center', width: 550 },
      ],
      isShowChart: false,
      dataAccounts,
      options: {
        responsive: true,
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
        plugins: {
          legend: false,
          title: false,
          datalabels: {
            display: false,
          },
        },
        layout: {
          padding: {
            left: 2,
            right: 2,
          },
        },
      },
    }
  },
  methods: {
    calculatorPercen(row) {
      if (row) {
        let result = (this.calculator(row) / row?.followers) * 100
        result = result.toFixed(2)
        if (result == '-0.00') {
          return result = '0.00'
        }

        result = result > 0 ? '+' + result : result
        return result
      }
    },

    calculator(row) {
      let temp = row?.old_followers ? row.old_followers : 0
      return row?.followers - temp
    },
    getChartData(labels, data) {
      if (!labels || !data) return

      const chartData = {
        datasets: [
          {
            data: data,
            borderColor: '#BE3D8F',
            backgroundColor: '#BE3D8F',
            borderWidth: 3,
            pointRadius: 3,
          },
        ],
        labels: labels,
      }
      return chartData
    },
    formatNumber(number) {
      if(!number) return

      const str = number.toString().split('.')
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return str.join('.')
    },

    handleCurrentPage(value) {
      this.filter.page = value
      this.fetchData()
    },
  },
}
</script>
<style scoped>
.header__avatar.header__avatar--border {
  border-color: #bf3d8f;
}
</style>