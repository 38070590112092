import dayjs from 'dayjs'

export default {
  methods: {
    genAccountAnalyticPage(doc) {
      const accountLineChart = document.getElementById('account-line-chart')
      const accountPieChart = document.getElementById('account-pie-chart')
      const accountStackBarChart = document.getElementById('account-stack-bar-chart')
      const accountHorizontalChart = document.getElementById('account-horizontal-chart')

      const accountLineChartCanvas = accountLineChart ? accountLineChart.childNodes[0] : null
      const accountPieChartCanvas = accountPieChart ? accountPieChart.childNodes[0] : null
      const accountStackBarChartCanvas = accountStackBarChart ? accountStackBarChart.childNodes[0] : null
      const accountHorizontalChartCanvas = accountHorizontalChart ? accountHorizontalChart.childNodes[0] : null

      // ------------- account analytic -------------
      doc.setFontSize(14)
      doc.setTextColor(190, 61, 143)
      doc.text('アカウント分析', 13, 30)
      doc.text('アカウント分析', 13, 30)
      doc.text('アカウント分析', 13, 30)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(10)

      doc.addImage(this.drawTimeFrame(), 'PNG', 135, 0)

      let accRangeStart = dayjs(this.accRange[0]).format('YYYY-MM-DD')
      let accRangeStartFormat =
        accRangeStart.split('-')[0] + '年' + accRangeStart.split('-')[1] + '月' + accRangeStart.split('-')[2] + '日'
      let accRangeEnd = dayjs(this.accRange[1]).format('YYYY-MM-DD')
      let accRangeEndFormat =
        accRangeEnd.split('-')[0] + '年' + accRangeEnd.split('-')[1] + '月' + accRangeEnd.split('-')[2] + '日'
      let textRangeWidth = doc.getTextWidth(accRangeStartFormat + '  /  ' + accRangeEndFormat)
      doc.text(accRangeStartFormat + '  /  ' + accRangeEndFormat, 197 - textRangeWidth, 8, { baseline: 'center' })

      // account table 1
      doc.text('基本サマリー', 13, 40)
      doc.autoTable({
        styles: {
          font: 'GenShinGothic',
          fontSize: 8,
          halign: 'center',
          valign: 'middle',
        },
        startY: 45,
        head: [
          [
            'フォロワー数',
            'フォロワー純増減数',
            'プロフィールアクセス数',
            'ウェブサイトクリック数',
            'アカウントリーチ数',
            'フィード投稿数',
          ],
        ],
        body: [this.accBasicTableData],
      })
      // account table 2
      doc.text('フォロワーサマリー', 13, 65)
      doc.autoTable({
        styles: {
          font: 'GenShinGothic',
          fontSize: 8,
          halign: 'center',
          valign: 'middle',
        },
        startY: 70,
        head: [
          [
            'フォロワー数',
            'フォロワー純増減数',
            '平均フォロワー純増減数',
            'フォロワー増加数',
            '平均フォロワー増加数',
            'フォロワー増加率',
            'フォロワー減少数',
            '平均フォロワー減少数',
            'フォロワー減少率',
          ],
        ],
        body: [this.accFollowerTableData],
      })
      // account table 3
      doc.text('インサイトサマリー', 13, 92)
      doc.autoTable({
        styles: {
          font: 'GenShinGothic',
          fontSize: 8,
          halign: 'center',
          valign: 'middle',
        },
        startY: 97,
        head: [
          [
            'プロフィールアクセス数',
            '平均プロフィールアクセス数',
            'プロフィールアクセス率',
            'ウェブサイトクリック数',
            '平均ウェブサイトクリック数',
            'ウェブサイトクリック率',
          ],
        ],
        body: [this.accDetailTableData],
      })
      const pageWidth = doc.internal.pageSize.getWidth()
      const pageHeight = doc.internal.pageSize.getHeight()

      // declare ratio for chart
      const accLineChartCanvasWRatio = (pageWidth / 1536) * 0.9
      const accLineChartCanvasHRatio = (pageHeight / 400) * 0.9
      const accRatio =
        accLineChartCanvasWRatio > accLineChartCanvasHRatio ? accLineChartCanvasHRatio : accLineChartCanvasWRatio
      const accountLineChartCanvasWidth = 1536 * accRatio
      const accountLineChartCanvasHeight = 400 * accRatio

      if (accountLineChartCanvas) {
        doc.text('推移', 13, 122)
        doc.addImage(accountLineChartCanvas, 'PNG', 10, 122, accountLineChartCanvasWidth, accountLineChartCanvasHeight)
      }

      //3 acc chart
      doc.text('フォロワー属性', 13, accountLineChartCanvasHeight + 135)
      let newCanvas = document.createElement('canvas')
      newCanvas.id = 'canvas-multiple-chart'
      let width = 1536
      let height = 415

      newCanvas.width = width
      newCanvas.height = height
      let ctx = newCanvas.getContext('2d')
      doc.setTextColor(190, 61, 143)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(9) // set font size
      doc.text('フォロワー男女比', 13, accountLineChartCanvasHeight + 145)
      doc.text('フォロワー年齢層', 74, accountLineChartCanvasHeight + 145)
      doc.text('フォロワーの地域', 140, accountLineChartCanvasHeight + 145)
      doc.setFontSize(10) // set font size
      if (accountPieChartCanvas) {
        ctx.drawImage(accountPieChartCanvas, 10, 15, 482, 400)
      } else {
        doc.addImage(this.getEmptyImg(), 'PNG', 8, accountLineChartCanvasHeight + 150)
      }
      if (accountStackBarChartCanvas) {
        ctx.drawImage(accountStackBarChartCanvas, 492, 15, 482, 400)
      } else {
        doc.addImage(this.getEmptyImg(), 'PNG', 77, accountLineChartCanvasHeight + 150)
      }
      if (accountHorizontalChartCanvas) {
        ctx.drawImage(accountHorizontalChartCanvas, 1020, 15, 482, 400)
      } else {
        doc.addImage(this.getEmptyImg(), 'PNG', 147, accountLineChartCanvasHeight + 150)
      }

      const newCanvasWidthRatio = (pageWidth / width) * 0.9
      const newCanvasHeightRatio = (pageHeight / height) * 0.9
      const newCanvasRatio = newCanvasWidthRatio > newCanvasHeightRatio ? newCanvasHeightRatio : newCanvasWidthRatio

      const newCanvasWidth = width * newCanvasRatio
      const newCanvasHeight = height * newCanvasRatio
      doc.addImage(newCanvas, 'PNG', 13, accountLineChartCanvasHeight + 150, newCanvasWidth, newCanvasHeight)

      doc.addPage()
      doc.setFontSize(14)
      doc.setTextColor(190, 61, 143)
      doc.text('アカウント分析', 13, 30)
      doc.text('アカウント分析', 13, 30)
      doc.text('アカウント分析', 13, 30)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(10)
      doc.text('ハッシュタグ分析', 13, 40)
      doc.text('ハッシュタグ分析', 13, 40)
      doc.autoTable({
        styles: {
          font: 'GenShinGothic',
          fontSize: 8,
          halign: 'center',
          valign: 'middle',
        },
        startY: 45,
        head: [
          [
            'ハッシュタグ',
            '使用回数',
            'いいね数',
            'リーチ数',
            'コメント数',
            '保存数',
            'インプレッション数',
            'エンゲージメント率',
          ],
        ],
        body: this.accHashtagTableData,
      })
    },
  },
}
