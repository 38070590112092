<template>
    <div v-if="isShow"
        class="fixed flex justify-center items-center w-screen md:w-calc md:ml-[258px] md:justify-start h-full overlay-loading z-40 top-0 left-0">
        <div class="fixed w-11/12 md:w-[350px] md:top-[130px] md:left-[475px] z-40">
            <div class="header-manual p-2 text-center text-white bg-[#d3007f] rounded-t-[10px] relative">
                <p class="text-[15px] text-white font-bold">画面の見方/ヒント</p>
                <div @click="$emit('closeManualPopup')"
                    class="absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer">
                    &#10006;</div>
            </div>
            <div class="body-manual bg-white rounded-b-[10px] md:h-[400px]">
                <div class="relative body-manual__title text-center py-2 font-bold text-[14px]">
                    <p>画面の見方</p>
                    <!-- <img :src="suggestIcon" class="absolute w-6 h-6 top-1/2 -translate-y-1/2 left-24" /> -->
                </div>
                <div class="body-manual__content px-2">
                    <div v-for="item in content" :key="item">
                        <p v-html="item" class="text-[13px]"></p>
                        <br>
                    </div>
                </div>
            </div>
        </div>
        <div @click="$emit('closeManualPopup')" class="absolute top-0 left-0 z-30 w-screen h-full"></div>
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: Array,
            default: []
        },
        isShow: {
            type: Boolean,
            default: false
        },
    },
    emits: ['closeManualPopup'],
    data() {
        return {
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowPopup: false
        }
    },
}
</script>

<style scoped>
.overlay-loading {
    background-color: rgba(0, 0, 0, 0.4);
}
</style>


