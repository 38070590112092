<template>
    <svg v-if="icon==='large'" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.9199 0.282227H10.9199C10.3676 0.282227 9.91992 0.729942 9.91992 1.28223V6.28223C9.91992 6.83451 10.3676 7.28223 10.9199 7.28223H15.9199C16.4722 7.28223 16.9199 6.83451 16.9199 6.28223V1.28223C16.9199 0.729942 16.4722 0.282227 15.9199 0.282227Z" fill="#BE3D8F"/>
        <path d="M6.91992 0.282227H1.91992C1.36764 0.282227 0.919922 0.729942 0.919922 1.28223V6.28223C0.919922 6.83451 1.36764 7.28223 1.91992 7.28223H6.91992C7.47221 7.28223 7.91992 6.83451 7.91992 6.28223V1.28223C7.91992 0.729942 7.47221 0.282227 6.91992 0.282227Z" fill="#BE3D8F"/>
        <path d="M15.9199 9.28223H10.9199C10.3676 9.28223 9.91992 9.72994 9.91992 10.2822V15.2822C9.91992 15.8345 10.3676 16.2822 10.9199 16.2822H15.9199C16.4722 16.2822 16.9199 15.8345 16.9199 15.2822V10.2822C16.9199 9.72994 16.4722 9.28223 15.9199 9.28223Z" fill="#BE3D8F"/>
        <path d="M6.91992 9.28223H1.91992C1.36764 9.28223 0.919922 9.72994 0.919922 10.2822V15.2822C0.919922 15.8345 1.36764 16.2822 1.91992 16.2822H6.91992C7.47221 16.2822 7.91992 15.8345 7.91992 15.2822V10.2822C7.91992 9.72994 7.47221 9.28223 6.91992 9.28223Z" fill="#BE3D8F"/>
    </svg>

    <img v-else-if="icon==='account'" :src="'/images/ベクトルスマートオブジェクト.svg'" alt="">

    <i v-else-if="icon==='down'" class="fa-solid fa-angle-down"></i>
</template>

<script>
export default {
    props: ['icon'],
}
</script>

<style>

</style>
