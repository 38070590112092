<template>
  <data-table
    :fields="fields"
    :items="details"
    disable-table-info
    footer-center
    paginate-background
    class="el-table-header__black mt-7"
  >
    <template #profile_views>
      <p class="font-bold">{{ getTotalProfileViews() }}</p>
    </template>
    <template #profile_impressions_average>
      <p class="font-bold">{{ profileImpressionsAverage() }}</p>
    </template>
    <template #follower_fell_ratio="{ row }">
      <p class="font-bold">{{ row?.followers ? followerFellRatio(row) : '-' }}</p>
    </template>
    <template #website_clicks>
      <p class="font-bold">{{ getTotalSumWebsiteClicks() }}</p>
    </template>
    <template #click_number_average>
      <p class="font-bold">{{ clickNumberAverage() }}</p>
    </template>
    <template #click_number_ratio>
      <p class="font-bold">{{ clickNumberRatio() }}</p>
    </template>
  </data-table>
</template>

<script>
import DataTable from '@/Components/UI/DataTable.vue'

export default {
  components: { DataTable },
  props: {
    range: { type: Object, default: 0 },
    accountTables: { type: Object, default: {} },
  },
  data() {
    return {
      details: [],
      calculation: {},
      fields: [
        {
          key: 'profile_views',
          label: 'プロフィールアクセス数',
          width: screen.width / 6 >= 240 ? screen.width / 6 : 200,
          align: 'center',
        },
        {
          key: 'profile_impressions_average',
          label: '平均プロフィールアクセス数',
          width: screen.width / 6 >= 240 ? screen.width / 6 : 200,
          align: 'center',
        },
        {
          key: 'follower_fell_ratio',
          label: 'プロフィールアクセス率',
          width: screen.width / 6 >= 240 ? screen.width / 6 : 200,
          align: 'center',
        },
        {
          key: 'website_clicks',
          label: 'ウェブサイトクリック数',
          width: screen.width / 6 >= 240 ? screen.width / 6 : 200,
          align: 'center',
        },
        {
          key: 'click_number_average',
          label: '平均ウェブサイトクリック数',
          width: screen.width / 6 >= 240 ? screen.width / 6 : 200,
          align: 'center',
        },
        {
          key: 'click_number_ratio',
          label: 'ウェブサイトクリック率',
          width: screen.width / 6 >= 240 ? screen.width / 6 : 200,
          align: 'center',
        },
      ],
      interval: 0,
    }
  },
  async created() {
    await this.fetchData()
  },
  watch: {
    accountTables: function () {
      this.fetchData()
    },
  },
  methods: {
    async fetchData() {
      const datatable = JSON.parse(JSON.stringify(this.accountTables))
      this.details = datatable?.lastDaySelected ? Array(datatable?.lastDaySelected) : []
      this.interval = datatable?.interval
      this.calculation = datatable?.calculation
    },
    getTotalProfileViews() {
      return this.formatToInteger(this.calculation?.totalProfileViews)
    },
    profileImpressionsAverage() {
      const average = this.calculation?.totalProfileViews / this.interval
      return this.formatToFloat(average)
    },
    followerFellRatio(row) {
      const average = this.calculation?.totalProfileViews / this.interval
      if (average) {
        const result = (average / row?.followers) * 100
        return this.formatToFloat(result + '%')
      }
      return 0 + '%'
    },
    getTotalSumWebsiteClicks() {
      return this.formatToInteger(this.calculation?.totalWebsiteClicks)
    },
    clickNumberAverage() {
      const average = this.calculation?.totalWebsiteClicks / this.interval
      return this.formatToFloat(average)
    },
    clickNumberRatio() {
      const clickNumberAverage = this.calculation?.totalWebsiteClicks / this.interval
      const profileImpressionsAverage = this.calculation?.totalProfileViews / this.interval
      if (clickNumberAverage > 0 && profileImpressionsAverage > 0) {
        const result = (clickNumberAverage / profileImpressionsAverage) * 100
        return this.formatToFloat(result + '%')
      }
      return 0 + '%'
    },
  },
}
</script>
<style></style>
