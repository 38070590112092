import { EMPTY_IMG } from '../Mixins/media.js'
export default {
  methods: {
    genSlideGroupPostAnalysis(pptx, postGroupAnalysis, type, text) {
      let title = type === 'slide14' ? 'アカウント別 フィード投稿 リーチ数' : 'グループ内フィード投稿エンゲージメント'
      let nameChart = type === 'slide14' ? 'アカウント別 フィード投稿 リーチ数' : 'グループ内　エンゲージメント'
      let slide14 = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slide14.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      slide14.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 6.05,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 22,
      })

      let opts = {
        x: 0.5,
        y: 1.31,
        w: 10.02,
        h: 4.58,
        barDir: 'col',
        barGrouping: 'clustered',
        catAxisLabelColor: '787878',
        catAxisLabelFontSize: 12,
        catAxisOrientation: 'minMax',
        showTitle: true,
        title: nameChart,
        titleColor: '787878',
        titleFontSize: 18.6,
        showLegend: true,
        legendPos: 'b',
        chartColors: type === 'slide14' ? ['e7299e', '3D63A3'] : ['e7299e'],
        valAxes: [
          {
            showValAxisTitle: false,
            valAxisTitle: 'Primary Value Axis',
          },
          {
            showValAxisTitle: false,
            valAxisTitle: 'Secondary Value Axis',
            catAxisOrientation: 'maxMin',
            // valAxisMajorUnit: 5,
            // valAxisMaxVal: 25,
            valAxisMinVal: 0,
            valGridLine: { style: 'none' },
          },
        ],
        catAxes: [
          {
            catAxisTitle: 'Primary Category Axis',
          },
          {
            catAxisHidden: true,
          },
        ],
        // valAxisMaxVal: 16000,
        // valAxisMajorUnit: 2000,
      }
      let labels = postGroupAnalysis.nameAccount
      let dataForChart = []
      if (postGroupAnalysis.checkSumReach && postGroupAnalysis.checkSumImpression && postGroupAnalysis.checkSumPost) {
        slide14.addImage({ path: EMPTY_IMG, x: 3.75, y: 1.84, w: 3.56, h: 3.56 })
      } else {
        if (type === 'slide14') {
          dataForChart = [
            {
              name: 'インプレッション数',
              labels: labels,
              values: postGroupAnalysis.sumReach,
            },
            {
              name: 'リーチ数',
              labels: labels,
              values: postGroupAnalysis.sumImpression,
            },
          ]
        } else {
          dataForChart = [
            {
              name: 'リーチ数',
              labels: labels,
              values: postGroupAnalysis.sumReach,
            },
          ]
        }

        let chartTypes = [
          {
            type: pptx.charts.BAR,
            data: dataForChart,
          },
          {
            type: pptx.charts.LINE,
            data: [
              {
                name: '投稿数',
                labels: labels,
                values: postGroupAnalysis.sumPost,
              },
            ],
            options: {
              chartColors: ['C2C2C2'],
              lineDataSymbol: 'none',
              secondaryValAxis: !!opts.valAxes,
              secondaryCatAxis: !!opts.catAxes,
            },
          },
        ]
        slide14.addChart(chartTypes, opts)
      }
      slide14.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.29,
        w: 10.14,
        h: 1.56,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slide14
    },
  },
}
