<template>
    <div>
        <!-- Topbar -->
        <header v-if="handleHeaderPage()" class="sticky block z-30 bg-white px-3">
            <div class="flex px-5 items-center sm:justify-center md:justify-between py-2">
                <!-- Header: Left side -->
                <div v-if="handleClientPage()" class="flex sm:hidden md:flex h-14">
                </div>
                <div v-else class="flex sm:hidden md:flex">
                    <Link href="/"><img :src="'/img/favicon-upscale.png'" class=" w-48" /></Link>
                </div>
                <!-- Header: Right side -->
                <ul class="flex items-center justify-center sm:flex-wrap md:flex">
                    <li class="inline-flex items-center mx-2 relative">
                        <button v-if="route().current() == 'formLogin.user' || route().current() == 'register'"
                            class="inline-flex justify-center items-center sm:mx-0">
                            <Link :href="route('register')"
                                class="flex items-center border-r-2 px-4 sm:text-3sm text-base"
                                :class="route().current() == 'register' ? 'disabled opacity-60 pointer-events-none' : false">
                            サインアップ
                            </Link>
                            <Link
                                :class="route().current() == 'formLogin.user' ? 'disabled opacity-60 pointer-events-none' : false"
                                :href="route('formLogin.user')"
                                class="flex items-center sm:border-0 border-r-2 px-4 sm:text-3sm text-base">
                            サインイン
                            </Link>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="shadow-inner h-1"></div>
        </header>

        <div class="flex flex-col justify-start items-center sm:pt-0 fixed h-screen overflow-auto w-full"
            :class="bg_form_login">
            <!-- Content -->
            <div class="flex flex-col justify-center items-center h-full md:px-0 sm:px-5"
                :class="border_form_login + ' ' + my_form_login">
                <div v-if="!handleClientPage()" class="flex justify-center">
                    <Link href="#">
                    <BreezeApplicationLogo class="md:w-64 sm:w-full md:h-20 fill-current text-gray-500" />
                    </Link>
                </div>
                <div class="py-4 bg-transparent sm:rounded-lg">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreezeApplicationLogo from '@/Components/ApplicationLogo.vue';
import {
    Link
} from '@inertiajs/inertia-vue3';

export default {
    components: {
        BreezeApplicationLogo,
        Link
    },

    props: {
        bg_form_login: {
            type: String,
            default: 'bg-slate-100',
            required: false,
        },

        border_form_login: {
            type: String,
            required: false,
        },

        my_form_login: {
            type: String,
            default: 'my-48',
            required: false,
        }
    },
    created() {
        this.handleClientPage()
        this.handleHeaderPage()
    },
    methods: {
        handleClientPage() {
            let currPage = window.location.pathname.split('/')[1]
            return ['master', 'om', 'ag', 'ep'].includes(currPage) ? false : true
        },
        handleHeaderPage() {
            let currPage = window.location.pathname.split('/')[1]
            let headerHidden = ['login', 'register'].includes(window.location.pathname.split('/')[2])
            return ['master', 'om', 'ag', 'ep', 'client'].includes(currPage) || headerHidden ? true : false
        }
    }
}
</script>

