<template>
  <div class="w-full text-center text-white font-bold text-14 bg-red-400">
    <div class="w-full show-alert flex justify-center p-1">
      <p class="text-14 mt-1 text-white">{{ content }}</p>

      <p
        style="color: #2563eb"
        class="text-xs mt-1 md:w-44 sm:w-24 text-center hover:underline cursor-pointer text-14"
        @click="this.$inertia.visit(route('admin.users_infor'))"
      >
        トークン更新はこちらへ
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: { type: String, require: true },
  },
}
</script>

<style lang="css">
.text-14 {
  font-size: 14px;
}
</style>
