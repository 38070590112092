<template>
    <div class="bg-white py-4 chart--scroll linechart-contain" v-loading="loading">
        <Line :chart-options="chartOptions" :chart-data="chartData" :plugins="plugins" v-if="isShowChart" />
        <el-empty description="データはありません" v-else />
    </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import * as accountAPI from '@/Api/Account'
import dayjs from 'dayjs'

export default {
    name: 'LineChart',
    components: { Line },
    props: {
        range: { type: Object, default: 0 },
        year: { type: Object, default: 0 },
        analyticType: { type: String, default: 'month' },
        showLine: { type: String, default: 'all' },
        chartId: { type: String, default: 'line-chart' },
        datasetIdKey: { type: String, default: 'label' },
        width: { type: Number, default: 100 },
        height: { type: Number, default: 250 },
        plugins: { type: Object, default: () => { } },
    },
    data() {
        return {
            isShowChart: true,
            isShowDate: true,
            accountId: '',
            dataFollower: {
                data: [],
                label: 'フォロワー推移',
                borderColor: '#3e95cd',
                fill: false,
                lineTension: 0.5,
                yAxisID: 'B',
            },
            dataInteractive: {
                data: [],
                label: 'エンゲージメント率推移',
                borderColor: '#BE3D8F',
                fill: true,
                lineTension: 0.5,
                yAxisID: 'A',
            },
            dataFollowerSingle: {
                data: [],
                label: 'フォロワー推移',
                borderColor: '#3e95cd',
                fill: false,
                lineTension: 0.5,
            },
            dataInteractiveSingle: {
                data: [],
                label: 'エンゲージメント率推移',
                borderColor: '#BE3D8F',
                fill: true,
                lineTension: 0.5,
            },
            chartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        label: 'エンゲージメント率推移',
                        borderColor: '#BE3D8F',
                        fill: false,
                        lineTension: 0.5,
                    },
                    {
                        data: [],
                        label: 'フォロワー推移',
                        borderColor: '#3e95cd',
                        fill: false,
                        lineTension: 0.5,
                    },
                ],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: { left: 20, right: 10, top: 20, bottom: 20 },
                },
                plugins: {
                    legend: {
                        display: true,
                        labels: { boxHeight: 1, position: 'top', align: 'start' },
                    },
                    datalabels: {
                        display: false,
                    },

                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || ''
                                if (label == 'エンゲージメント率推移') {
                                    label += ': ' + context.parsed.y + ' %'
                                } else {
                                    label += ': ' + context.parsed.y
                                }
                                return label
                            },
                        },
                    },
                },
                scales: {
                    x: { grid: { display: false } },
                    y: { grid: { display: false } },
                },
            },
            loading: false,
        }
    },
    async created() {
        if (this.range.length > 0) {
            this.getData()
        }
    },
    watch: {
        range: function () {
            this.getData()
        },
        year: function () {
            this.getData()
        },
        showLine() {
            this.checkLineForChart()
        },
    },
    methods: {
        async getData() {
            this.loading = true
            let sinceTime = this.range[0] ? this.range[0] : this.range
            let untilTime = this.range[1] ? this.range[1] : ''

            if (this.analyticType == 'year') {
                sinceTime = this.year ? dayjs(this.year).year() : dayjs().year()
            } else {
                sinceTime = dayjs(sinceTime).format('YYYY-M-D')
                untilTime = dayjs(untilTime).format('YYYY-M-D')
            }

            const response = await accountAPI.getDetailAccountTime(this.analyticType, sinceTime, untilTime, this.accountId)

            if (response.data.type == 'year') {
                this.chartData.labels = response.data.months
                this.dataFollower.data = response.data.followers
                this.dataInteractive.data = response.data.interactives
                this.dataFollowerSingle.data = response.data.followers
                this.dataInteractiveSingle.data = response.data.interactives
            }

            if (response.data.type == 'day') {
                this.chartData.labels = response.data.dataLabels
                this.dataFollower.data = response.data.dataFollowers
                this.dataInteractive.data = response.data.dataInteractive
                this.dataFollowerSingle.data = response.data.dataFollowers
                this.dataInteractiveSingle.data = response.data.dataInteractive
            }
            this.checkShowLineChart()
            this.checkLineForChart()
            this.loading = false
        },
        checkShowLineChart() {
            if (!this.dataFollower?.data?.length && !this.dataInteractive?.data?.length && !this.dataFollowerSingle?.data?.length && !this.dataInteractiveSingle?.data?.length) {
                this.isShowChart = false
            } else {
                this.isShowChart = false
                this.dataFollower?.data?.forEach((element) => {
                    if (element) {
                        this.isShowChart = true
                    }
                })
                this.dataFollowerSingle?.data?.forEach((element) => {
                    if (element) {
                        this.isShowChart = true
                    }
                })
                if (this.isShowChart === false) {
                    this.dataInteractive?.data?.forEach((element) => {
                        if (element) {
                            this.isShowChart = true
                        }
                    })
                }
                if (this.isShowChart === false) {
                    this.dataInteractiveSingle?.data?.forEach((element) => {
                        if (element) {
                            this.isShowChart = true
                        }
                    })
                }
            }
        },
        checkLineForChart() {
            this.chartData.datasets = []
            // this.chartData.datasets[1] = [];

            if (this.showLine == 'followers') {
                setTimeout(() => {
                    this.chartData.datasets[0] = this.dataFollowerSingle
                    this.chartOptions.scales = {
                        y: { grid: { display: false } },
                    }
                }, 200)
            }

            if (this.showLine == 'interactives') {
                setTimeout(() => {
                    this.chartData.datasets[0] = this.dataInteractiveSingle
                    this.chartOptions.scales = {
                        y: { grid: { display: false } },
                    }
                }, 200)
            }

            if (this.showLine == 'all') {
                setTimeout(() => {
                    this.chartData.datasets[0] = this.dataInteractive
                    this.chartData.datasets[1] = this.dataFollower
                    this.chartOptions.scales = {
                        A: {
                            type: 'linear',
                            position: 'left',
                            grid: {
                                display: false,
                            },
                        },
                        B: {
                            type: 'linear',
                            position: 'right',
                            grid: {
                                display: false,
                            },
                        },
                    }
                }, 200)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.linechart-contain #line-chart {
    height: 400px !important;
}

.chart--scroll {
    padding: 1rem 0 !important;
    width: 100%;
    overflow: scroll;

    &>div {
        width: 1510px;
        height: 400px;
    }
}

@media (max-width: 640px) {
    .linechart-contain {
        #line-chart {
            height: 350px !important;
        }
    }
}
</style>
