<template>
    <dashboard>
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li v-if="check_role_admin">
                    <Link :href="route('admin.users.index')" class="font-normal">管理画面</Link>
                </li>
                <span v-if="check_role_admin" class="font-normal text-notice_date mx-2">></span>
                <li>
                    <Link :href="route('admin.users_infor')" class="font-normal breakcumb-active">アカウント情報</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li @click="toggleTabs(1)" class="sm:px-3 md:px-6 relative pb-5">アカウント管理</li>
                    <li @click="toggleTabs(2)" class="sm:px-3 md:px-6 relative pb-5 active">決済方法・プラン管理</li>
                    <li v-if="!['admin_agency', 'admin_oem', 'user_agency', 'user_oem'].includes($page.props.auth.user.roles[0].name)"
                        @click="toggleTabs(3)" class="sm:px-3 md:px-6 relative pb-5">ご利用明細</li>
                </ul>
            </div>
        </template>
        <div class="py-5">
            <UserInformation :userSelected="user" />
        </div>

        <div class="sm:px-5 md:p-12 bg-background mb-[50px]">
            <div class="text-pink-400 font-semibold text-2xl mb-5 custom-current-size-title">プラン管理</div>

            <div class="sm:fled:x sm:flex-col md:grid md:grid-cols-2 lg:gap-x-40 md:gap-x-10" v-if="user.plan !== null">
                <div class="form-left">
                    <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                        <label class="text-black col-span-1">現在の契約</label>
                        <input type="text" class="col-span-2 h-14 border border-solid border-input rounded"
                            :value="user.plan?.name" disabled />
                    </div>
                </div>
                <div v-if="user.plan?.amount > 0 && !['admin_agency', 'admin_oem', 'user_agency', 'user_oem'].includes($page.props.auth.user.roles[0].name)"
                    class="
                    form-right">
                    <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                        <label class="text-black col-span-1">決済状態</label>
                        <input type="text" class="col-span-2 h-14 border border-solid border-input rounded"
                            :value="user.apply == 1 ? '決済済み' : '未決済'" disabled />
                    </div>
                </div>
            </div>

            <div class="sm:fled:x sm:flex-col md:grid md:grid-cols-2 lg:gap-x-40 md:gap-x-10" v-if="user.plan !== null">
                <div class="form-left flex sm:flex-col-reverse md:flex-col">
                    <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                        <label class="text-black col-span-1">利用開始日</label>
                        <input v-if="['未決済', 'パスワード未設定'].includes(user?.status)"
                            class="col-span-2 h-14 border border-solid border-input rounded" disabled />
                        <input v-else type="text" class="col-span-2 h-14 border border-solid border-input rounded"
                            :value="formatDate(user.started_at)" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                        <label class="text-black col-span-1">月額金額（税込）</label>
                        <input type="text" class="col-span-2 h-14 border border-solid border-input rounded" :value="
                            user?.amount_plan_crawl
                                ? getAmountPlanCrawl(user.amount_plan_crawl).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
                                : user.plan?.amount_after_tax
                                    ? user.plan.amount_after_tax.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
                                    : '¥0'
                        " disabled />
                    </div>
                </div>
                <div class="form-right">
                    <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                        <label class="text-black col-span-1">契約終了日</label>
                        <input v-if="['未決済', 'パスワード未設定'].includes(user?.status)"
                            class="col-span-2 h-14 border border-solid border-input rounded" disabled />
                        <input v-else type="text" class="col-span-2 h-14 border border-solid border-input rounded"
                            :value="formatDate(user.ended_at)" disabled />
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-3 items-center">
                        <label class="text-black col-span-1">自動更新回数</label>
                        <input type="text" class="col-span-2 h-14 border border-solid border-input rounded"
                            :value="user.count_update" disabled />
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap py-5 md:justify-center sm:justify-between relative button-user-payment"
                v-if="!$page.props.auth.impersonate">
                <button v-if="user.suggest_update && user.suggest_amount > 0" @click="redirectToPayment" type="button"
                    class="text-white bg-button_profile py-4 text-xs rounded-sm mb-2 mx-2 md:w-48 sm:w-1/2">
                    新しいプランを決済する
                </button>
                <button v-if="isShowCanceled(user)" @click="confirmPausePlan = true" type="button"
                    class="text-white bg-red-500 py-4 text-xs rounded-sm mb-2 mx-2 md:w-48 sm:w-1/2">
                    解約する
                </button>

                <button v-if="check_recurring_time_end == true" type="button" @click="paymentRecurring"
                    class="text-white bg-green-500 px-3 py-4 text-xs rounded-sm mb-2 mx-2 md:w-48 sm:w-1/2">
                    再契約する
                </button>

                <button v-if="isShowReopen(user)" @click="confirmUsePlanOld = true" type="button"
                    class="text-white bg-green-500 py-4 text-xs rounded-sm mb-2 mx-2 md:w-48 sm:w-1/2">
                    再契約する
                </button>

                <button v-if="isShowUpdate(user)" type="button" @click="redirectPageUpdatePlan"
                    class="bg-button_profile text-white py-4 mb-2 mx-2 text-xs rounded-sm md:w-48 sm:w-1/2 text-center">
                    プランを変更する
                </button>
            </div>
        </div>

        <el-dialog v-model="dialogVisible" :title="$t('Request plan update')" width="30%" center append-to-body>
            <span>{{ $t('Admin has set a plan for you, please pay to use the new plan') }}</span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">{{ $t('Close') }}</el-button>
                    <el-button type="primary" @click="redirectToPayment()">{{ $t('Payment') }}</el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog v-model="confirmExtensionPlan" title="契約延長" width="30%" center append-to-body>
            <span>プラン延長でよろしいでしょうか？</span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="confirmExtensionPlan = false">キャンセル</el-button>
                    <el-button type="primary" @click="extensionPlan">同意</el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog v-model="confirmPausePlan" title="利用しているプランをキャンセル" width="30%" center append-to-body>
            <span>
                利用しているプランをキャンセルしてよろしいでしょうか？
                <br />
                利用しているプランをキャンセルしたら、今期間の終了まで利用可能となります
            </span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="confirmPausePlan = false">キャンセル</el-button>
                    <el-button type="primary" @click="pausePlan">同意</el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog v-model="confirmUsePlanOld" title="現在のプランを引き続き利用した" width="30%" center append-to-body>
            <p>{{ user.plan.name }}プランを再度登録してよろしいでしょうか？</p>
            <p v-if="user.role !== '直ユーザー'">他のプランを登録したい場合、管理者にへご連絡お願いします。</p>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="confirmUsePlanOld = false">キャンセル</el-button>
                    <el-button type="primary" @click="usePlanOld()">同意</el-button>
                </span>
            </template>
        </el-dialog>
    </dashboard>
</template>

<script>
import Dashboard from '../../Dashboard.vue'
import { Link } from '@inertiajs/inertia-vue3'
import UserInformation from '@/Components/UserInformation'
import axios from 'axios'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { ElMessageBox } from 'element-plus'
export default {
    props: {
        user: {
            type: Object,
            required: true,
        },

        toggleTabs: {
            type: Function,
            required: true,
        },

        fetchDataUser: {
            type: Function,
            require: true,
        },
    },

    components: {
        Dashboard,
        Link,
        UserInformation,
        BreadCrumb,
    },

    data: function () {
        return {
            dialogVisible:
                this.user.suggest_update &&
                !this.$page.props.auth.impersonate &&
                this.user.suggest_amount > 0,
            confirmExtensionPlan: false,
            confirmPausePlan: false,
            confirmUsePlanOld: false,
            check_date_pause_plan: true,
            check_role_admin: false,
            subscription_user: null,
            check_cancel_date: false,
            check_recurring_time_end: false,
        }
    },

    created() {
        this.checkSubscriptionUser()
        this.checkEndDate()
        this.checkRoleAdmin()
        this.checkRecurring()
    },

    methods: {
        getAmountPlanCrawl(amount) {
            const tax = 10;
            let result = amount + (amount * tax / 100);
            return Number(result)
        },

        checkEndDate() {
            if (this.user.dateExprie || this.user.apply == 0) {
                this.check_date_pause_plan = false
            } else {
                this.check_date_pause_plan = true
            }
        },

        //Check button payemnt plan
        checkRecurring() {
            if (this.user.recurring_time_end == 1 && this.user.apply == 0) {
                this.check_recurring_time_end = true
            }
        },

        paymentRecurring() {
            let msg = this.$t('Do you really want to continue paying for this plan package?')
            ElMessageBox.confirm(msg, '警告', {
                confirmButtonText: 'はい',
                cancelButtonText: 'いいえ',
                type: 'warning',
            }).then(async () => {
                this.$inertia.get(route('admin.payment_recurring_plan'))
            })
        },

        checkRoleAdmin() {
            this.$page.props.auth.user.roles.filter((role) => {
                if (
                    role.name == 'user_agency' ||
                    role.name == 'user_oem' ||
                    role.name == 'user_enterprise' ||
                    role.name == 'user_normal'
                ) {
                    this.check_role_admin = false
                } else {
                    this.check_role_admin = true
                }
            })
        },

        formatDate(oldDate) {
            if (oldDate) {
                const date = new Date(oldDate)
                let day = date.getDate()
                let month = date.getMonth() + 1
                let year = date.getFullYear()
                return `${year}年${month}月${day}日`
            }
        },

        async checkSubscriptionUser() {
            await axios
                .get(route('api.user.subscriptions', this.user.id))
                .then((res) => {
                    if (res.data.status) {
                        this.subscription_user = res.data.status
                        this.check_cancel_date = this.subscription_user == 'active' || this.subscription_user == 'canceled'
                        //this.check_cancel_date = (this.subscription_user == 'active' || (this.subscription_user == 'canceled' && this.user.check_cancel_date))
                    } else {
                        this.check_cancel_date = true
                    }
                })
                .catch((err) => { })
        },

        redirectToPayment() {
            return (window.location.href = route('admin.checkout-plan'))
        },

        redirectPageUpdatePlan() {
            return (window.location.href = route('admin.page_update_plan'))
        },

        async pausePlan() {
            const article = {
                user_id: this.user.id,
                plan_id: this.user.plan.id,
            }
            await axios
                .post(route('api.plans.pause', article))
                .then((res) => {
                    this.$toast.success(this.$t('Pause plan success'))
                    this.fetchDataUser(2)
                    this.checkSubscriptionUser()
                    this.confirmPausePlan = false
                })
                .catch((err) => {
                    this.$toast.error(this.$t('Pause plan error'))
                    this.confirmPausePlan = false
                })
            // location.reload()
        },

        async usePlanOld() {
            if (['admin_agency', 'admin_oem', 'user_agency', 'user_oem'].includes(this.user.role_name)) {
                await axios
                    .get(route('api.users.payment_plan_user', this.user))
                    .then((res) => {
                        this.$toast.success(this.$t('Resume plan success'))
                        this.fetchDataUser(2)
                        this.confirmUsePlanOld = false
                        window.location.reload()
                    })
            } else {
                return (window.location.href = route('admin.plans.payment_plan_old', this.user.plan.id))
            }
        },

        async extensionPlan() {
            await axios
                .post(route('api.users.user_extension_plan', this.user.id))
                .then((res) => {
                    this.$toast.success(this.$t('Extension plan success'))
                    this.fetchDataUser(2)
                    this.checkSubscriptionUser()
                    this.confirmExtensionPlan = false
                })
                .catch((err) => {
                    this.$toast.error(this.$t('Extension plan error'))
                    this.confirmExtensionPlan = false
                })
        },

        isShowUpdate(user) {
            return (
                user.role_name == 'user_normal' &&
                this.check_cancel_date &&
                !this.$page.props.auth.impersonate &&
                user.status != '解約中'
            )
        },

        isShowReopen(user) {
            let checkUserRole = ['admin_agency', 'admin_oem', 'user_agency', 'user_oem'].includes(user.role_name)
            let checkTime = user.dateExprieTimestamp * 1000 > Date.now()

            return (checkUserRole ? !checkTime : this.subscription_user == 'canceled') && user.status == '契約終了' && user.plan.amount > 0
        },

        isShowCanceled(user) {
            let checkUserRole = ['admin_agency', 'admin_oem', 'user_agency', 'user_oem'].includes(user.role_name)
            return (checkUserRole ? true : this.subscription_user == 'active') && this.user.apply == 1 && !user.isHiddenCancel && user.dateExprie == null && user.status !== '無料プラン利用中'
        },
    },
}
</script>
<style lang="scss" scoped>
* {
    font-size: 13px !important;
}

.custom-current-size-title {
    font-size: 14px !important;
}

button {
    font-size: 14px !important;
}

@media (max-width: 640px) {
    .button-user-payment {
        gap: 10px 0px;

        button {
            margin: 5px -10px;
            padding: 1rem 0;
        }
    }
}
</style>
<style lang="scss">
.el-button--primary {
    background-color: #5264cd !important;
    border: 1px solid #5264cd !important;
}

.el-button--primary span {
    color: white !important;
}
</style>
