<script setup>
import BreezeButton from '@/Components/Button.vue';
import BreezeGuestLayout from '@/Layouts/Guest.vue';
import BreezeInput from '@/Components/Input.vue';
import BreezeLabel from '@/Components/Label.vue';
import BreezeValidationErrors from '@/Components/ValidationErrors.vue';
import { Head, useForm } from '@inertiajs/inertia-vue3';

const props = defineProps({
    email: String,
    token: String,
});

const form = useForm({
    token: props.token,
    email: props.email,
    password: '',
    password_confirmation: '',
});

const submit = () => {
    form.post(route('password.update'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};
</script>

<template>
    <BreezeGuestLayout>
        <Head title="Reset Password" />

        <form @submit.prevent="submit">

            <div class="mt-5 flex items-center justify-center">
                <BreezeInput id="password" type="password" class="block w-96 rounded-sm p-3" v-model="form.password" required autocomplete="new-password" placeholder="パスワード"/>
            </div>

            <div class="mt-5 flex items-center justify-center">
                <BreezeInput id="password_confirmation" type="password" class="block w-96 rounded-sm p-3" v-model="form.password_confirmation" required autocomplete="new-password" placeholder="パスワードを認証する"/>
            </div>

            <BreezeValidationErrors class="mb-5"/>

            <div class="flex items-center justify-center mt-7">
                <BreezeButton class="bg-indigo-500 hover:bg-indigo-700 pt-5 pb-5 pl-16 pr-16 rounded-sm"
                              :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    ログイン
                </BreezeButton>
            </div>
        </form>
    </BreezeGuestLayout>
</template>
