<template>
  <AdminRegister />
</template>

<script>
import AdminRegister from './AdminRegister.vue'
export default {
    components:{
        AdminRegister
    }
}
</script>

<style>

</style>