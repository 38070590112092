export const purposeCampaigns = [
  {
    label: 'トラフィック',
    value: 'LINK_CLICKS',
  },
  {
    label: 'リード獲得',
    value: 'LEAD_GENERATION',
  },
  {
    label: 'コンバージョン',
    value: 'CONVERSIONS',
  },
  {
    label: '動画の再生数アップ',
    value: 'VIDEO_VIEWS',
  },
  {
    label: 'リーチ',
    value: 'REACH',
  },
  {
    label: 'ページへの「いいね！」',
    value: 'PAGE_LIKES',
  },
  {
    label: 'エンゲージメント',
    value: 'POST_ENGAGEMENT',
  },
  {
    label: 'ブランドの認知度アップ',
    value: 'BRAND_AWARENESS',
  },
]

export const optimizationGoalForConvertsions = [
  {
    label: 'バリュー',
    value: 'VALUE',
  },
  {
    label: 'コンバージョン',
    value: 'CONVERSATIONS', //pass to local current
  },
  {
    label: 'ランディングページビュー',
    value: 'LANDING_PAGE_VIEWS',
  },
  {
    label: 'リンクのクリック',
    value: 'LINK_CLICKS',
  },
  {
    label: 'デイリーユニークリーチ',
    value: 'REACH',
  },
  {
    label: 'インプレッション',
    value: 'IMPRESSIONS',
  },
]

export const optimizationGoalForReach = [
  {
    label: 'リーチ',
    value: 'REACH',
  },
  {
    label: 'インプレッション',
    value: 'IMPRESSIONS',
  },
]

export const optimizationGoal = [
  {
    label: 'デイリーユニークリーチ',
    value: 'REACH',
  },
  {
    label: 'リンクのクリック',
    value: 'LINK_CLICKS',
  },
  {
    label: 'インプレッション',
    value: 'IMPRESSIONS',
  },
  {
    label: 'ランディングページビュー',
    value: 'LANDING_PAGE_VIEWS',
  },
]

export const optimizationGoalForLeadGeneration = [
  {
    label: 'リンクのクリック',
    value: 'LINK_CLICKS',
  },
  {
    label: 'リード',
    value: 'LEAD_GENERATION',
  },
  {
    label: 'コンバージョンリード',
    value: 'QUALITY_LEAD',
  },
  {
    label: '広告想起リフト',
    value: 'QUALITY_CALL',
  },
]

export const optimizationGoalForAppInstalls = [
  {
    label: 'OFFSITE_CONVERSIONS',
    value: 'OFFSITE_CONVERSIONS',
  },
  {
    label: 'LINK_CLICKS',
    value: 'LINK_CLICKS',
  },
  {
    label: 'REACH',
    value: 'REACH',
  },
  {
    label: 'VALUE',
    value: 'VALUE',
  },
]

export const optimizationGoalForPageLikes = [
  {
    label: 'ページへの「いいね！」',
    value: 'PAGE_LIKES',
  },
  {
    label: 'インプレッション',
    value: 'IMPRESSIONS',
  },
  {
    label: '投稿のエンゲージメント',
    value: 'POST_ENGAGEMENT',
  },
  {
    label: 'デイリーユニークリーチ',
    value: 'REACH',
  },
]

export const optimizationGoalForPostEngagement = [
  {
    label: '投稿のエンゲージメント',
    value: 'POST_ENGAGEMENT',
  },
  {
    label: 'インプレッション',
    value: 'IMPRESSIONS',
  },
  {
    label: 'リンクのクリック',
    value: 'LINK_CLICKS',
  },
  {
    label: 'デイリーユニークリーチ',
    value: 'REACH',
  },
]
export const optimizationGoalForBrandAwareness = [
    {
      label: '広告想起リフト',
      value: 'AD_RECALL_LIFT',
    },
  ]

export const otherCampaignsInfo = [
  {
    label: 'ブランドの認知アップ',
    key: 'raiseBrand',
  },
  {
    label: 'リーチ　○トラフィック',
    key: 'reachTraffic',
  },
  {
    label: 'エンゲージメント',
    key: 'engagement',
  },
  {
    label: 'アプリのインストール',
    key: 'installApp',
  },
  {
    label: '動画の再生数アップ',
    key: 'increaseVideo',
  },
  {
    label: 'リード獲得',
    key: 'leadAcquisition',
  },
  {
    label: 'メッセージ',
    key: 'message',
  },
  {
    label: 'コンバージョン',
    key: 'conversion',
  },
  {
    label: 'カタログ販売',
    key: 'catalogSales',
  },
  {
    label: '来店数の増加',
    key: 'increaseShop',
  },
]

export const traffics = [
  {
    label: 'Webサイト',
    value: 'WEBSITE',
  },
  //   {
  //     label: 'アプリ',
  //     value: 'APP',
  //   },
  {
    label: 'Messenger',
    value: 'MESSENGER',
  },
  //   {
  //     label: 'WhatsApp',
  //     value: 'WHATSAPP',
  //   },
  {
    label: '通話',
    value: 'PHONE_CALL',
  },
]

export const advertisingSettings = [
  {
    label: 'シングル画像または動画',
    value: 'シングル画像または動画',
  },
  {
    label: 'カルーセル',
    value: 'カルーセル',
  },
  {
    label: 'コレクション',
    value: 'コレクション',
  },
]
