<template>
  <div>
    <div class="flex justify-between items-center">
      <h2 class="text-xl text-button_pink font-semibold py-5">お知らせ一覧</h2>
      <Link
        :href="route('admin.notifications.create')"
        as="button"
        class="bg-button_profile text-white py-2 px-4 rounded button-shadow mx-1"
      >
        お知らせを作成
      </Link>
    </div>
    <Table :label="label">
      <template v-slot:first-header>
        <th
          class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2 min-w-[170px]"
        >
          配信日時
        </th>
      </template>
      <template v-slot:end-header>
        <th
          class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2"
          style="width: 120px"
        >
          編集
        </th>
      </template>
      <template v-slot:custom-tbody>
        <tbody class="bg-body_table">
          <tr class="text-2sm border-b hover:bg-gray-200" v-for="(notify, index) in dataTable" :key="index">
            <td class="py-2">
              <div class="px-3">
                <h1 class="whitespace-nowrap">
                  {{ notify.deliveredTime }}
                </h1>
                <div v-if="notify.status == 1" class="text-center">
                  <input
                    type="radio"
                    class="text-pink-400"
                    :name="notify.id"
                    id="radio1"
                    @change="handlechange(e)"
                    checked
                  />
                  <label for="radio1" class="ml-2 text-pink-400">配信済</label>
                </div>
                <div v-else class="text-center">
                  <input type="radio" class="text-indigo-400" :name="notify.id" id="radio1" checked />
                  <label class="ml-2 text-indigo-400">配信予定</label>
                </div>
              </div>
            </td>

            <td class="py-2">
              <p class="whitespace-nowrap px-2 text-center">{{ notify.label }}</p>
            </td>

            <td class="py-2">
              <p class="text-center wrap-text w-[200px]" v-html="parseString(notify.title)"></p>
            </td>

            <td class="py-2">
              <p class="text-center wrap-text" v-html="parseString(notify.content)"></p>
            </td>

            <td class="py-2">
              <div class="p-5 flex justify-center">
                <Link
                  :href="route('admin.notifications.edit', notify.id)"
                  class="bg-indigo-500 text-white py-2 px-5 rounded-sm whitespace-nowrap"
                >
                  編集
                </Link>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </Table>
    <div class="pt-4 pb-9 flex justify-center">
      <el-pagination
              :page-size="Number(6)"
              :pager-count="5"
              layout="prev, pager, next"
              :total="Number(totalPage*6)"
              :current-page="currentPage || 1"
              @current-change="handleCurrentChange"
              class="is-background "
      />
    </div>
  </div>
</template>

<script>
import Dashboard from '@/Layouts/Authenticated.vue'
import Table from '@/Components/Table'
import Pagination from '@/Components/Pagination'
import { Link } from '@inertiajs/inertia-vue3'
import axios from 'axios'
import { parseTagA } from '@/Helpers/xss'
export default {
  name: 'History',

  components: {
    Dashboard,
    Table,
    Pagination,
    Link,
  },
  data() {
    return {
      currentTab: 'notification_post',
      dataTable: [],
      currentPage: 1,
      totalPage: 1,
      meta: {},
      label: [
        {
          text: 'ラベル',
          key: 'label',
        },

        {
          text: 'タイトル',
          key: 'title',
        },

        {
          text: '本文',
          key: 'content',
          minWidth: 150,
        },
      ],
    }
  },

  created() {
    this.getListNotification()
  },

  methods: {
    parseString(str) {
      return parseTagA(str)
    },
    handleCurrentChange(value)
    {
     this.currentPage = value
    },
    getListNotification(page = null) {
      axios
        .get('/api/delivery_notices?page=' + (page ? page : 1))
        .then((response) => {
          this.currentPage = response.data.meta.current_page
          this.totalPage = response.data.meta.last_page
          this.dataTable = response.data.data
          this.meta = response.data.meta
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },

  watch: {
    currentPage() {
      this.getListNotification(this.currentPage)
    },
  },
}
</script>

<style></style>
