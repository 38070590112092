export default {
  methods: {
    genListPostPage(doc, imgPostTableData) {
      doc.setFontSize(14) // set font size
      doc.setTextColor(190, 61, 143)
      doc.text('投稿分析', 13, 30)
      doc.text('投稿分析', 13, 30)
      doc.text('投稿分析', 13, 30)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(10)
      const postTableDataMap = JSON.parse(JSON.stringify(this.postTableData))

      if (this.postTableData.length > 10) {
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 26 },
            1: { cellWidth: 15 },
            2: { cellWidth: 20 },
            4: { cellWidth: 25 },
          },
          didDrawCell: function (data) {
            if (data.column.index === 0 && data.cell.section === 'body') {
              var textPos = data.cell
              let base64Img = imgPostTableData[data.cell.raw]
              doc.addImage(base64Img, 'jpeg', textPos.x + 1, textPos.y + 1, 20, 20)
            }
          },
          bodyStyles: { minCellHeight: 22 },
          startY: 45,
          head: [
            [
              '投稿画像',
              'タイプ',
              '投稿内容',
              'ハッシュタグ',
              '投稿日時',
              'いいね数',
              'コメント数',
              '保存数',
              'リーチ数',
              'エンゲージメント率',
            ],
          ],
          body: postTableDataMap.slice(0, 10),
        })
        doc.addPage()
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 26 },
            1: { cellWidth: 15 },
            2: { cellWidth: 20 },
            4: { cellWidth: 25 },
          },
          didDrawCell: function (data) {
            if (data.column.index === 0 && data.cell.section === 'body') {
              var textPos = data.cell
              let base64Img = imgPostTableData[data.cell.raw]
              doc.addImage(base64Img, 'jpeg', textPos.x + 1, textPos.y + 1, 20, 20)
            }
          },
          bodyStyles: { minCellHeight: 22 },
          startY: 45,
          head: [
            [
              '投稿画像',
              'タイプ',
              '投稿内容',
              'ハッシュタグ',
              '投稿日時',
              'いいね数',
              'コメント数',
              '保存数',
              'リーチ数',
              'エンゲージメント率',
            ],
          ],
          body: postTableDataMap.splice(10),
        })
      } else if (this.postTableData.length <= 10) {
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 26 },
            1: { cellWidth: 15 },
            2: { cellWidth: 20 },
            4: { cellWidth: 25 },
          },
          didDrawCell: function (data) {
            if (data.column.index === 0 && data.cell.section === 'body') {
              var textPos = data.cell
              let base64Img = imgPostTableData[data.cell.raw]
              doc.addImage(base64Img, 'jpeg', textPos.x + 1, textPos.y + 1, 20, 20)
            }
          },
          bodyStyles: { minCellHeight: 22 },
          startY: 45,
          head: [
            [
              '投稿画像',
              'タイプ',
              '投稿内容',
              'ハッシュタグ',
              '投稿日時',
              'いいね数',
              'コメント数',
              '保存数',
              'リーチ数',
              'エンゲージメント率',
            ],
          ],
          body: this.postTableData,
        })
      }
    },
  },
}
