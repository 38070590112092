<template>
    <DrashBoard>
        <div class="bg-background pl-11.5 pr-13 pt-13">
            <h2 class="text-xl text-button_pink font-semibold py-5">
                プラン新規登録
            </h2>
            <form>
                <div class="flex flex-wrap items-center mb-5">
                    <div class="grid grid-cols-3 gap-y-6 gap-x-3 w-1/2 items-center">
                        <label for="" class="relative">
                            プラン名
                            <span class="text-red-500">*</span>
                            <span class="text-red-500 absolute top-5 left-0 text-sm" v-if="errors.name"> {{ errors.name[0] }} </span>
                        </label>
                        <input v-model="form.name" disabled class="col-span-2 py-4 border-gray-300" type="text" @change="errors.name = []"/>
                        <label for="relative">連携可能アカウント数</label>
                        <input v-model="form.number_accounts" disabled class="col-span-2 py-4 border-gray-300" type="number" />
                    </div>
                    <div class="w-1/2 pl-10">
                        <div class="flex py-4 mb-6 items-center">
                            <label for="" class="relative w-1/3">
                                料金
                                <span class="text-red-500 absolute top-5 left-0 text-sm" v-if="errors.amount"> {{ errors.amount[0] }} </span>
                            </label>
                            <div class="flex items-center">
                                <input v-model="form.type_amount" disabled class="mr-3" type="radio" :value='0'/>
                                <label class="mr-10" for="">無料</label>
                            </div>
                            <div class="flex items-center pr-8">
                                <input v-model="form.type_amount" disabled class="mr-3" type="radio" :value='1'/>
                                <label for="">料金</label>
                            </div>
                            <div class="flex-1" v-if="form.type_amount == 1">
                                <input v-model="form.amount" disabled class="py-2 border-gray-300" placeholder="円" type="number" />
                            </div>
                        </div>
                        <div class="flex py-4 items-center">
                            <label for="" class="relative w-1/3">
                                データ保有期間
                                <span class="text-red-500 absolute top-5 left-0 text-sm" v-if="errors.storage_time"> {{ errors.storage_time[0] }} </span>
                            </label>
                            <div class="flex items-center">
                                <input v-model="form.type_storage_time" disabled class="mr-3" type="radio" :value='0'/>
                                <label class="mr-10" for="">無料</label>
                            </div>
                            <div class="flex items-center pr-8">
                                <input v-model="form.type_storage_time" disabled class="mr-3" type="radio" :value='1'/>
                                <label for="">日数</label>
                            </div>
                            <div class="flex-1" v-if="form.type_storage_time == 1">
                                <input v-model="form.storage_time" disabled class="py-2 border-gray-300" placeholder="円" type="number"/>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- analysis -->
                <div class="flex flex-wrap items-center">
                    <div class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 w-1/2">
                        <label for="">アカウント分析</label>
                        <div>
                            <input v-model="form.account_analysis" disabled class="mr-4" type="radio" :value='1'/>
                            <label for="">Yes</label>
                        </div>
                        <div>
                            <input v-model="form.account_analysis" disabled class="mr-4" type="radio" :value='0'/>
                            <label for="">No</label>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 w-1/2 pl-10">
                        <label for="">投稿分析</label>
                        <div>
                            <input v-model="form.post_analysis" disabled class="mr-4" type="radio" :value='1'/>
                            <label for="">Yes</label>
                        </div>
                        <div>
                            <input v-model="form.post_analysis" disabled class="mr-4" type="radio" :value='0'/>
                            <label for="">No</label>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 w-1/2">
                        <label for="">競合分析</label>
                        <div>
                            <input v-model="form.co_opetition_analysis" disabled class="mr-4" type="radio" :value='1'/>
                            <label for="">Yes</label>
                        </div>
                        <div>
                            <input v-model="form.co_opetition_analysis" disabled class="mr-4" type="radio" :value='0'/>
                            <label for="">No</label>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 w-1/2 pl-10">
                        <label for="">広告分析</label>
                        <div>
                            <input v-model="form.advertising_analysis" disabled class="mr-4" type="radio" :value='1'/>
                            <label for="">Yes</label>
                        </div>
                        <div>
                            <input v-model="form.advertising_analysis" disabled class="mr-4" type="radio" :value='0'/>
                            <label for="">No</label>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 w-1/2">
                        <label for="">時間/曜日別分析</label>
                        <div>
                            <input v-model="form.user_analysis" class="mr-4" disabled type="radio" :value='1'/>
                            <label for="">Yes</label>
                        </div>
                        <div>
                            <input v-model="form.user_analysis" class="mr-4" disabled type="radio" :value='0'/>
                            <label for="">No</label>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 w-1/2 pl-10">
                        <label for="">レポーティング</label>
                        <div>
                            <input v-model="form.report_analysis" class="mr-4" disabled type="radio" :value='1'/>
                            <label for="">Yes</label>
                        </div>
                        <div>
                            <input v-model="form.report_analysis" class="mr-4" disabled type="radio" :value='0'/>
                            <label for="">No</label>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 w-1/2">
                        <label for="">エンタープライズ機能</label>
                        <div>
                            <input v-model="form.business_analysis" class="mr-4" disabled type="radio" :value='1'/>
                            <label for="">Yes</label>
                        </div>
                        <div>
                            <input v-model="form.business_analysis" class="mr-4" disabled type="radio" :value='0'/>
                            <label for="">No</label>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </DrashBoard>
</template>

<script>
import DrashBoard from "@/Layouts/Authenticated.vue";
import Notification from "@/Components/Notifications.vue";
import {Link} from '@inertiajs/inertia-vue3';

export default {
    props: {
        plan_id: {
            type: Number,
            required: true,
        }
    },

    data() {
        return {
            form: {
                name: '',
                number_accounts: 0,
                amount: 0,
                storage_time: 0,
                type_amount: 0,
                type_storage_time: 0,
                account_analysis: 0,
                post_analysis: 0,
                co_opetition_analysis: 0,
                advertising_analysis: 0,
                user_analysis: 0,
                report_analysis: 0,
                business_analysis: 0,
                trial_days: 0, //default
                currency: 'jpy', //default
                interval: "month", //default
            },
            errors: [],
        };
    },

    components: {
        DrashBoard,
        Notification,
        Link,
    },

    created() {
       this.fetchDataPlan();
    },

    methods: {
        fetchDataPlan() {
            axios.get(route('api.plans.show', this.plan_id))
            .then(res => {
                this.form = JSON.parse(JSON.stringify(res.data));
                if (this.form.amount == 0) {
                    this.form.type_amount = 0;
                } else {
                    this.form.type_amount = 1;
                }

                if (this.form.storage_time == 0) {
                    this.form.type_storage_time = 0;
                } else {
                    this.form.type_storage_time = 1;
                }
            })
            .catch(err => {
                console.error(err);
            })
        },
    }
};
</script>
