import { EMPTY_IMG } from '../Mixins/media.js'

export default {
  methods: {
    genSlideTopCreative(pptx, items, text, title) {
      const slideTopCreative = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideTopCreative.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      if (items.length === 0) {
        slideTopCreative.addImage({ path: EMPTY_IMG, x: 3.72, y: 1.39, w: 3.56, h: 3.56 })
      } else {
        items.forEach((v, index) => {
          let post = []
          for (const [key, value] of Object.entries(v)) {
            post.push((post[`${key}`] = value))
          }
          slideTopCreative.addText('BEST' + (index + 1), {
            x: 0.36 + index * 2.09,
            y: 1.6 + index * 0.075,
            w: 1.5,
            h: 0.2,
            align: 'left',
            color: '3465a4',
            fontSize: 11,
          })
          slideTopCreative.addImage({
            x: 0.47 + index * 2.09,
            y: 2.13,
            w: 1.73,
            h: 2.76,
            path: post['thumbnail_url'] + '?time=' + Math.floor(Date.now() / 1000),
            altText: 'top ' + index + ' creative',
          })
          slideTopCreative.addText('カラー：' + post['hexCode'], {
            x: 0.47 + index * 2.09,
            y: 5.08,
            w: 1.73,
            h: 0.26,
            align: 'center',
            fontSize: 12,
          })
          slideTopCreative.addShape(pptx.shapes.RECTANGLE, {
            x: 0.47 + index * 2.09,
            y: 5.38,
            w: 1.73,
            h: 0.52,
            fill: { color: post['hexCode'].substring(1) },
          })
        })
      }

      slideTopCreative.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 8.01,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 22,
      })
      slideTopCreative.addText('■クリエイティブトップ5', {
        x: 0.36,
        y: 1.03,
        w: 7.4,
        h: 0.3,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 12,
      })

      slideTopCreative.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.26,
        y: 6,
        w: 10.44,
        h: 1.84,
        rectRadius: 0.2,
        line: '09c3bb',
        lineSize: 1,
      })

      return slideTopCreative
    },
  },
}
