<template>
 <!--content-->

<div class="popup-instagram">
   <el-dialog
      width="20%"
      v-model="dialogVisible"
      :show-close="false"
      :before-close="handleClose"
      >
      <h2 class="text-option text-center font-semibold">インスタグラムアカウントを連携してください</h2>
      <button class="mt-4 w-full">
         <div @click="handleLoginInsta" class="w-32 cursor-pointer mx-auto">
            <img src="@shared/uploads/ins-icon.png" />
         </div>
      </button>
   </el-dialog>
</div>
    <PopupIns :data="businessAccount" v-model="isShow"  :user-id="userId" @onSubmitSuccess="$emit('onInstaSubmitSuccess')"/>

</template>

<script>
import Ins from "@/Services/Ins";
import PopupIns from "@/Components/PopupIns.vue";


export default {
    components: {
        PopupIns
    },
    data() {
        return {
            isShow: false,
            dialogVisible : true,
            businessAccount: [],
            userId: ""
        }
    },
    mounted() {
        this.dialogVisible =  true;
        this.getUserId();
    },
    methods: {
    handleLoginInsta() {
      FB.login(
        async (response) => {
          if (!response.authResponse) {
            return;
          }
          const {
            data: { data },
          } = await Ins.businessAccount(response.authResponse.accessToken);
          this.businessAccount = Ins.filterBusinessAccount(data);
          this.dialogVisible =  false;
          this.isShow = true;
          // FB.logout();
        },
        { scope: Ins.addPermission() }
      );
    },
    getUserId(){
        const userId = this.$page.props.auth.user.id;
        this.userId = userId;
      }
    },
}
</script>

<style>
.dialog-footer button:first-child {
  margin-right: 10px;
}
.popup-instagram .el-dialog__header{
    display: none;
}
.popup-instagram .el-overlay-dialog{
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup-instagram .el-dialog{
    border-radius: 16px;
    margin-top:auto;
    margin-bottom:auto;
}
</style>
