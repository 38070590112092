<template>
    <DrashBoard>
        <LinkInstagram v-if="!showLinkInstagram" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li class="mr-4">
                    <Link :href="route('admin.compete.index')" class="font-normal breakcumb-active">競合アカウント分析</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li class="pr-4 relative pb-5" :class="{ active: current_tab == 'tab-1' }"
                        @click="current_tab = 'tab-1'">
                        競合アカウント分析
                    </li>
                    <li class="px-6 relative pb-5" :class="{ active: current_tab == 'tab-2' }"
                        @click="current_tab = 'tab-2'">
                        競合アカウント管理
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:manual>
            <div @keydown.esc="deleteSelect = false" class="bg-background min-h-screen"
                :class="{ 'sm:px-5 md:px-10 pb-5': !isExport, 'px-5 md:px-20': isExport }">
                <!-- start tab 1  -->
                <div class="" v-if="current_tab == 'tab-1'">
                    <AccountAnalysis :allAccount="allAccount" :IDChart="competeAnalysisChartID"
                        :suggestIcon="suggestIcon" :isShowManual="isShowManual"
                        @getDataCompeteFollower="getDataCompeteFollower" :arrContent="arrContentTab1"
                        @getDataCompeteAccount="getDataCompeteAccount" @getDataFirstDayAcc="getDataFirstDayAcc">
                    </AccountAnalysis>
                </div>
                <!-- start tab 2  -->
                <div class="" v-if="current_tab == 'tab-2'">
                    <ManageAccount :numberAccountCompetitor="numberAccountCompetitor" :suggestIcon="suggestIcon"
                        :isShowManualCompete="isShowManualCompete" @getDataCompeteFollower="getDataCompeteFollower"
                        :arrContentCompete="arrContentTab2" v-on:getAllAccountCompetitor="getAllAccountCompetitor"
                        :tableData="allAccount"></ManageAccount>
                </div>
            </div>
        </template>
    </DrashBoard>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import AccountAnalysis from './AccountAnalysis.vue'
import ManageAccount from './ManageAccount.vue'
import { getAllAccountCompetitor } from '@/Api/Account'
import { Link } from '@inertiajs/inertia-vue3'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import * as accountAPI from '@/Api/Account'
import { Inertia } from "@inertiajs/inertia";

export default {
    components: { DrashBoard, ManageAccount, AccountAnalysis, Link, BreadCrumb, LinkInstagram },
    emits: ['mapDataCompeteFollower'],
    data() {
        return {
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowManual: false,
            isShowManualCompete: false,
            arrContentTab1: ['競合アカウントの推移(フォロワー数、フィード投稿)を日時毎に確認することができます。', '競合のデータと比較することで、自社のアカウント運用だけでは発見できない、ノウハウや知見を得ることが可能です。', 'より効果的なアカウントの運用に繋がります。'],
            arrContentTab2: ['競合アカウントを登録することができる画面です。', '画面右上の「競合アカウントの追加」からアカウント情報を追加することができます。', '追加したアカウントは競合アカウント分析で確認することができます。'],
            allAccount: [],
            accountCompetitor: null,
            showLinkInstagram: true,
            current_tab: 'tab-1',
        }
    },
    async created() {
        this.checkLinkInstagram()
        this.getAllAccountCompetitor()
    },

    props: {
        numberAccountCompetitor: {
            type: Number,
            require: true,
        },
        competeAnalysisChartID: {
            type: String,
        },
    },
    computed: {
        isExport() {
            return Inertia?.page?.url === '/export/report-preview'
        }
    },
    methods: {

        getDataCompeteFollower: function (data) {
            this.$emit('mapDataCompeteFollower', data)
        },
        getDataCompeteAccount: function (data) {
            this.$emit('mapDataCompeteAccount', data)
        },
        getDataFirstDayAcc: function (data) {
            this.$emit('mapDataFirstDayAcc', data)
        },
        onInstaSubmitSuccess() {
            this.showLinkInstagram = true
        },
        async getAllAccountCompetitor() {
            await getAllAccountCompetitor(this.currentPage).then(async (res) => {
                this.loading = false
                this.allAccount = res.data.data
                this.currentPage = res.data.current_page
                this.totalPage = Math.ceil(res.data.total / res.data.per_page)
            })
        },
        // link instagram
        async checkLinkInstagram() {
            const check = await accountAPI.checkInsAccount()
            if (check.data) {
                this.showLinkInstagram = true
            } else {
                this.showLinkInstagram = false
            }
        },
    },
}
</script>
