import pptxgen from 'pptxgenjs'
import { CHEKI_LOGO } from '../Mixins/media.js'

export default {
  methods: {
    genMasterSlide(text_copyright, logo) {
      let textCopyright = text_copyright === null ? '2022 © Copyright Tryhatch Inc. All Reseved.' : text_copyright

      const pptx = new pptxgen()
      pptx.defineLayout({ name: 'cus', width: 11.02, height: 8.27 })
      pptx.layout = 'cus'

      let objImg = {
        path: logo,
        x: 9.2,
        y: 0.4,
        w: 1.5,
        h: 0.4,
      }

      // Define Slice Master
      pptx.defineSlideMaster({
        title: 'MASTER_SLIDE',
        background: { color: 'FFFFFF' },
        objects: [
          {
            image: logo === null ? { x: 9.2, y: 0.4, w: 1.5, h: 0.4, data: CHEKI_LOGO } : objImg,
          },
          {
            text: {
              text: textCopyright,
              options: {
                x: 0,
                y: 7.8,
                w: 11.02,
                h: 0.5,
                align: 'center',
                valign: 'bottom',
                color: '8f8f8f',
                fontSize: 11,
              },
            },
          },
        ],
        slideNumber: {
          x: 8.46,
          y: 7.8,
          w: 2.48,
          h: 0.44,
          align: 'right',
          valign: 'middle',
          color: '8f8f8f',
          fontSize: 12,
        },
      })

      return pptx
    },
  },
}
