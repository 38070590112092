<template>
    <DrashBoard v-loading="loading">
        <LinkInstagram v-if="!showLinkInstagram" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li class="mr-4">
                    <Link :href="route('admin.comment')" class="font-normal breakcumb-active">コメント分析</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5" :class="{ active: currentTabPostCategory == 'normal' }"
                        @click="currentTabPostCategory = 'normal'">
                        通常投稿
                    </li>
                    <li v-if="isShowReel" class="px-2 relative pb-5 ml-3"
                        :class="{ active: currentTabPostCategory == 'reel' }" @click="currentTabPostCategory = 'reel'">
                        リール投稿
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:exportTabs>
            <div class="ml-20 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5" :class="{ active: currentTabPostCategory == 'normal' }"
                        @click="currentTabPostCategory = 'normal'">
                        通常投稿
                    </li>
                    <li v-if="isShowReel" class="px-2 relative pb-5 ml-3"
                        :class="{ active: currentTabPostCategory == 'reel' }" @click="currentTabPostCategory = 'reel'">
                        リール投稿
                    </li>
                </ul>
            </div>
        </template>
        <template v-if="!isExport" v-slot:manual>
            <UserManual :isShow="isShowManual" :content="arrContent" @closeManualPopup="toogleManual" />
        </template>
        <UserNav v-if="!isExport" />
        <div class="bg-background" :class="{ 'pb-10': isExport, 'pb-20': !isExport }">
            <div class="h-full" :class="{ 'px-5 md:px-20': isExport, 'sm:px-5 lg:px-10': !isExport }">
                <div v-if="!isExport" class="flex items-center md:relative">
                    <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                        <p class="text-button_pink font-semibold text-3sm">
                            コメント分析
                        </p>
                        <div class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer" @click="toogleManual">
                            <img :src="suggestIcon" />
                        </div>
                    </div>
                </div>

                <div v-else class="flex justify-between items-center">
                    <div class="flex items-center md:relative">
                        <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                            <h2 class="text-button_pink !text-[14px] font-semibold py-5">コメント分析</h2>
                        </div>
                    </div>
                    <button v-if="isRole == 'master'"
                        class="bg-black py-3 px-7 text-white !text-[14px] button-shadow rounded" @click="exportComment"
                        :disabled="(numberExportCommnent <= 0 && isRole !== 'master') || !filter?.postId">
                        CSV出力
                    </button>

                    <el-tooltip v-else class="tooltip-customer" effect="dark"
                        :content="'コメント分析画面今月後 ' + numberExportCommnent + ' 回CSV出力出来ます。'" placement="top">
                        <button class="bg-black py-3 px-7 text-white !text-[14px] button-shadow rounded"
                            @click="exportComment"
                            :disabled="(numberExportCommnent <= 0 && isRole !== 'master') || !filter?.postId">
                            CSV出力
                        </button>
                    </el-tooltip>
                </div>
                <div class="md:grid md:grid-cols-7 sm:flex sm:flex-col flex-column">
                    <div class="md:col-span-5 flex-1 flex sm:mb-2 md:mb-0 gap-4 md:flex-row sm:flex-col">
                        <div class="flex search-input">
                            <div class="sm:mr-4 md:w-96 md:mb-0 sm:mb-2 md:h-10">
                                <el-input v-model="filter.name" placeholder="検索キーワードを入力してください" size="large"
                                    class="text-2sm" clearable @keyup.enter="onSearch" />
                            </div>
                            <el-button class="el-button__primary md:mb-0 md:!h-10 md:w-20 sm:mb-2" size="large"
                                @click="onSearch">
                                <span class="text-white">検索</span>
                            </el-button>
                        </div>
                        <el-select v-model="filter.postId" placeholder="投稿を切り替える" size="large"
                            class="md:w-1/3 sm:w-full text-2sm" filterable>
                            <el-option v-for="(item, index) in listPosts" :key="index" :value="item.id"
                                :label="item.content">
                                {{ item.content ? item.content : '投稿内容がありません。' }}
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-span-2 flex sm:justify-center md:justify-end items-center grid-start">
                        <span class="text-gray-600 text-2sm">友好的コメント数：</span>
                        <span class="font-bold ml-2 mr-4 text-link_pink text-2sm">{{ sumPositive }}</span>
                        <span class="text-gray-600 text-2sm">批判的コメント数：</span>
                        <span class="font-bold ml-2 text-link_pink text-2sm">{{ sumNegative }}</span>
                    </div>
                </div>
                <!-- table detail post -->
                <el-table :data="post_selected" border style="width: 100%"
                    class="mt-5 el-table__comment el-table-header__black">
                    <el-table-column prop="date" label="投稿画像" width="150" fixed="left" label-class-name="text-center"
                        align="center">
                        <template #default="{ row }">
                            <el-image :src="row?.is_carousel ? row?.files[0] : row?.file" lazy class="w-28 h-auto"
                                v-if="checkIsImage(row)">
                                <template #error>
                                    <div class="image-slot">
                                        <i class="fa-regular fa-image text-xl text-gray-600" />
                                    </div>
                                </template>
                            </el-image>
                            <video v-else controls width="112" style="height: 64px">
                                <source :src="row?.is_carousel ? row?.files[0] : row?.file" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </template>
                    </el-table-column>
                    <el-table-column prop="post_category" label="タイプ" label-class-name="text-center">
                        <template #default="{ row }">
                            <span class="spn-post-content">
                                {{ row?.post_category == 1 ? 'リール' : '通常' }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="content" label="投稿内容" width="250" label-class-name="text-center">
                        <template #default="{ row }">
                            <span class="spn-post-content">
                                {{ row?.content }}
                            </span>
                            <p @click="viewDetailContent(row)"
                                class="text-xs text-button_profile font-semibold text-right block mt-2 hover:cursor-pointer">
                                全文を見る
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="hashtag" label="ハッシュタグ" width="170" label-class-name="text-center">
                        <template #default="{ row }">
                            <p class="paragraph-overflow">{{ row?.hashtag? getHashtagData(row): '' }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="投稿日時" width="170" label-class-name="text-center">
                        <template #default="{ row }">
                            {{ formatDateTimeFromTimestamp(row?.updated_at_timestamp) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="likes" label="いいね数" width="150" align="center"
                        label-class-name="text-center">
                        <template #default="{ row }">{{ formatToInteger(row?.like) }}</template>
                    </el-table-column>
                    <el-table-column prop="comments" label="コメント数" width="150" align="center"
                        label-class-name="text-center">
                        <template #default="{ row }">{{ formatToInteger(row?.comment) }}</template>
                    </el-table-column>
                    <el-table-column prop="comments" label="保存数" width="150" align="center"
                        label-class-name="text-center">
                        <template #default="{ row }">{{ formatToInteger(row?.saved) }}</template>
                    </el-table-column>
                    <el-table-column prop="reach" label="リーチ数" width="150" align="center"
                        label-class-name="text-center">
                        <template #default="{ row }">{{ formatToInteger(row?.reach) }}</template>
                    </el-table-column>
                    <el-table-column prop="engagement"
                        :label="currentTabPostCategory == 'normal' ? 'エンゲージメント率' : 'エンゲージメント数'" width="150"
                        align="center" label-class-name="text-center">
                        <template #default="{ row }" v-if="currentTabPostCategory == 'normal'">
                            {{ getSumEngagement(row) }}
                        </template>
                        <template #default="{ row }" v-else>
                            {{ formatToInteger(Number(row?.like) + Number(row?.comment) + Number(row?.saved)) }}
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty description="データはありません" />
                    </template>
                </el-table>
                <!-- table list comment of post -->
                <data-table :fields="fields" :items="comments.data" :paginate="comments.meta"
                    :current-page="filter.page || 1" disable-table-info footer-center paginate-background
                    @page="handleCurrentPage" class="el-table-header__black mt-7">
                    <template #timestamp="{ row }">
                        {{ row?.timestamp? formatDateTimeFromTimestamp(row?.timestamp): '' }}
                    </template>
                    <template #feel="{ row }">
                        <div v-if="row?.emotions?.length">
                            <i v-for="emotion in row?.emotions" :key="emotion" :class="emotion"
                                class="text-2xl text-yellow-400 w-7" />
                        </div>
                        <i class="fa-solid fa-face-meh text-2xl text-yellow-400 w-7" v-else />
                    </template>
                </data-table>
                <el-dialog v-model="dialogOpen" width="50%" title="投稿内容" append-to-body>
                    <PopupReviewPost :content="dataContent.content"
                        :file="dataContent?.is_carousel ? dataContent.files[0] : dataContent.file"
                        :fileType="getTypeFile(dataContent?.is_carousel ? dataContent.files[0] : dataContent.file)"
                        :carouselItems="dataContent?.is_carousel ? dataContent.files : []"
                        :hashtag="dataContent.hashtag" :tag="dataContent.tag" :username="accountName" />
                </el-dialog>
            </div>
        </div>
    </DrashBoard>
</template>

<script>
import DrashBoard from '@/Layouts/Authenticated.vue'
import DataTable from '@/Components/UI/DataTable.vue'
import { Link } from '@inertiajs/inertia-vue3'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import UserNav from '@/Components/UserNav.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import PopupReviewPost from '@/Components/PopupReviewPost.vue'
import { Inertia } from '@inertiajs/inertia'
import GetBase64Url from '../../Report/Mixins/imgToBase64Mixin.js'
import { fetchNumberExport, addNumberExport } from '../../Report/Mixins/limitExport.js'
import UserManual from '@/Components/UserManual.vue'

export default {
    components: { DrashBoard, DataTable, Link, BreadCrumb, UserNav, LinkInstagram, PopupReviewPost, UserManual },
    emits: ['getPostSelected', 'getPostSelectedImg', 'getPostSelectedComment', 'getCmtPostCategory'],
    props: {
        userId: { type: Number },
        plan: { type: Object, require: true },
    },
    mixins: [GetBase64Url],
    computed: {
        isShowReel() {
            if (this.$page.props?.auth?.user?.roles[0]?.name === 'master') {
                return true
            }
            return this?.$page?.props?.auth?.plan?.reel_analysis == 1
        },
        isRole() {
            return this.$page.props?.auth?.user?.roles[0]?.name
        },
        isExport() {
            return Inertia?.page?.url === '/export/report-preview'
        },
    },

    data() {
        return {
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowManual: false,
            arrContent: ['フィード投稿に寄せられたコメントの投稿日時や詳細情報の確認とネガポジ判定を行うことができます。', 'ネガティブな投稿に対して素早く対処することで、炎上リスクの軽減や早期沈静化につながります。'],

            loading: false,
            numberExportCommnent: 0,
            showLinkInstagram: true,
            dialogOpen: false,
            accountName: '',
            dataContent: '',
            filter: {
                name: '',
                postId: null,
                page: 1,
                limit: 10,
            },
            listPosts: [],
            post_selected: [],
            comments: [],
            fields: [
                {
                    key: 'username',
                    label: 'ユーザー名',
                    width: 150,
                    fixed: 'left',
                    align: 'left',
                    lableClassName: 'text-center',
                },
                { key: 'feel', label: 'ネガポジ', width: 170, align: 'center', lableClassName: 'text-center' },
                { key: 'timestamp', label: '投稿日時', width: 170, lableClassName: 'text-center' },
                {
                    key: 'text',
                    label: 'コメント内容',
                    width: screen.width / 3 > 200 ? 'full' : 200,
                    lableClassName: 'text-center',
                },
            ],
            sumNegative: 0,
            sumPositive: 0,
            currentTabPostCategory: 'normal',
        }
    },
    mounted() {
        this.getListPosts()
        this.fetchNumberExportMonth()
        if (!this.isExport) {
            this.checkLinkInstagram()
        }
    },
    watch: {
        'filter.postId': {
            handler() {
                if (!this.filter.postId) {
                    this.post_selected = this.listPosts[0] ? this.listPosts[0] : []
                    this.filter.postId = this.listPosts[0] ? this.listPosts[0]?.id : null
                } else {
                    this.post_selected = this.listPosts?.filter((v) => this.filter.postId === v.id)
                }
                let postSelectedImg = this.post_selected[0]?.thumbnail
                let postSelected = this.post_selected.map((item, index) => {
                    return [
                        index,
                        item.post_category == 1 ? 'リール' : '通常',
                        item.content.length > 25 ? item.content.slice(0, 25) + '...' : item.content,
                        item.hashtag ? this.getHashtagDataExport(item) : '',
                        this.formatDateTime(item?.updated_at),
                        item.like,
                        item.comment,
                        item.saved,
                        item.reach,
                        item.post_category != 1
                            ? Math.round((item.engagement + Number.EPSILON) * 100) / 100 + '%'
                            : this.formatToInteger(Number(item.like) + Number(item.comment) + Number(item.saved)),
                    ]
                })
                this.$emit('getPostSelected', postSelected)
                this.$emit('getPostSelectedImg', postSelectedImg)
                this.getListComments()
                this.getListCommentsExport()
                this.getSumEmotionComments()
            },
            deep: true,
        },
        'filter.name': {
            handler() {
                if (!this.filter.name) {
                    this.getListComments()
                    this.getListCommentsExport()
                    this.getSumEmotionComments()
                }
            },
            deep: true,
        },
        currentTabPostCategory: {
            handler() {
                if (this.currentTabPostCategory) {
                    this.filter.name = ''
                }
                this.arrContent = this.currentTabPostCategory === 'reel' ? ['リール投稿に寄せられたコメントの投稿日時や詳細情報の確認とネガポジ判定を行うことができます。', '<p>ネガティブな投稿に対して素早く対処することで、</p>' + '<p>炎上リスクの軽減や早期沈静化につながります。</p>'] : ['フィード投稿に寄せられたコメントの投稿日時や詳細情報の確認とネガポジ判定を行うことができます。', 'ネガティブな投稿に対して素早く対処することで、炎上リスクの軽減や早期沈静化につながります。']
                this.isShowManual = false
                this.getListPosts()
                this.getListComments()
                this.getListCommentsExport
                this.getSumEmotionComments()
            },
        },
    },
    methods: {
        toogleManual() {
            this.isShowManual = !this.isShowManual
        },
        async getListPosts() {
            const postCategory = this.currentTabPostCategory
            this.$emit('getCmtPostCategory', postCategory)
            this.listPosts = this.post_selected = []
            this.filter.postId = null
            this.loading = true
            this.accountName = this.$page.props.auth.account
            await axios
                .get(route('api.comment.getPostsUser', { post_category: postCategory }))
                .then((res) => {
                    if (res?.data) {
                        this.listPosts = res?.data?.data?.map((v) => {
                            if (!v.content) {
                                return {
                                    ...v,
                                    content: '投稿内容がありません。',
                                }
                            }
                            return v
                        })
                        this.post_selected = Object.keys(res?.data?.data[0])?.length ? Array(res?.data?.data[0]) : []
                        this.filter.postId = res?.data.data[0]?.id
                    }
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                })
        },
        // get list comment insights
        viewDetailContent(item) {
            this.dataContent = item
            this.dialogOpen = true
        },
        getHashtagDataExport(item) {
            return item?.hashtag?.replaceAll(',', ' ').slice(0, 25) + '...'
        },
        getTypeFile(fileName) {
            return fileName.split('.').pop()
        },
        async getListComments() {
            const postCategory = this.currentTabPostCategory
            this.loading = true
            await axios
                .get(
                    route('api.comment.getComments', {
                        filter: { post_id: this.filter.postId, name: this.filter.name },
                        limit: this.filter?.limit,
                        page: this.filter?.page,
                        post_category: postCategory,
                    })
                )
                .then((res) => {
                    this.comments = res?.data ?? []
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                })
        },
        async getListCommentsExport() {
            await axios
                .get(
                    route('api.comment.getCommentsExport', {
                        filter: { post_id: this.filter.postId, name: this.filter.name },
                    })
                )
                .then((res) => {
                    this.$emit(
                        'getPostSelectedComment',
                        res?.data.data.map((item) => {
                            return [
                                item.username,
                                this.getEmotions(item.emotions),
                                this.convertTime(item.timestamp),
                                item.text.length > 30 ? item.text.slice(0, 35) + '...' : item.text,
                            ]
                        })
                    )
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // get sum emotion comments of this post
        async getSumEmotionComments() {
            const postCategory = this.currentTabPostCategory
            this.loading = true
            await axios
                .get(
                    route('api.comment.getSumEmotions', {
                        filter: { post_id: this.filter.postId, name: this.filter.name },
                        limit: this.filter?.limit,
                        page: this.filter?.page,
                        post_category: postCategory,
                    })
                )
                .then((res) => {
                    this.sumPositive = res?.data?.sumPositive
                    this.sumNegative = res?.data?.sumNegative
                    this.loading = false
                })
        },
        // on search by name
        onSearch() {
            this.filter.page = 1
            this.getListComments()
            this.getListCommentsExport()
            this.getSumEmotionComments()
        },
        handleCurrentPage(value) {
            this.filter.page = value
            this.getListComments()
            this.getListCommentsExport()
            this.getSumEmotionComments()
        },
        // get some engagement
        getSumEngagement(row) {
            return isNaN(row.engagement) ? '0%' : (row.engagement).toFixed(2) + '%'
        },
        getHashtagData(row) {
            return row?.hashtag?.replaceAll(',', ' ')
        },
        formatDateTime(datetime) {
            const date = new Date(datetime)
            let day = date.getDate()
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            let hours = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()
            let minutes = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()
            return `${year}年${month}月${day}日 ${hours}:${minutes}`
        },
        formatDateTimeFromTimestamp(date) {
            if (!date) {
                return
            }
            const created_time = new Date(date * 1000)

            let day = created_time.getDate()
            let month = created_time.getMonth() + 1
            let year = created_time.getFullYear()
            let hour = (created_time.getHours() < 10 ? '0' : '') + created_time.getHours()
            let minute = (created_time.getMinutes() < 10 ? '0' : '') + created_time.getMinutes()

            return `${year}年${month}月${day}日 ${hour}:${minute}`
        },
        checkIsImage(row) {
            if (!row) return
            const imageType = ['jpeg', 'jpg', 'png', 'jfif', 'pjpeg', 'pjp', 'heic', 'heif']
            let fileName = row?.is_carousel ? row?.files[0]?.split('.') : row?.file?.split('.')
            const fileType = fileName[fileName.length - 1]
            return imageType.includes(fileType) ? true : false
        },
        // link instagram
        async checkLinkInstagram() {
            const check = await accountAPI.checkInsAccount()
            if (check.data) {
                this.showLinkInstagram = true
            } else {
                this.showLinkInstagram = false
            }
        },
        exportComment() {
            if (this.numberExportCommnent === 0) {
                return
            }

            const userId = this.userId ? this.userId : this.$page?.props?.auth?.user?.id
            const url = route('api.export.comment.csv') + '?user_id=' + userId + '&post_id=' + this.filter.postId
            window.location.href = url

            if (this.isRole !== 'master') {
                addNumberExport('csv', 'csv_comment')
                this.fetchNumberExportMonth()
            }
        },
        getEmotions(item) {
            if (item.length == 0) {
                return '-'
            } else if (item.length != 0) {
                if (item[0].substring(17) === 'frown') {
                    return 'ネガティブ'
                } else if (item[0].substring(17) === 'laugh') {
                    return 'ポジティブ'
                }
            }
        },
        convertTime(date) {
            const cmtDay = new Date(date * 1000)
            let month = cmtDay.getMonth() + 1
            let hour = cmtDay.getHours() >= 10 ? cmtDay.getHours() : '0' + cmtDay.getHours()
            return cmtDay.getFullYear() + '年' + month + '月' + cmtDay.getDate() + ' ' + hour + ':' + cmtDay.getMinutes()
        },

        async fetchNumberExportMonth() {
            const { data } = await fetchNumberExport('csv', 'csv_comment')
            this.numberExportCommnent = this.plan?.number_report_csv - data
        },
    },
}
</script>

<style lang="scss" scoped>
.paragraph-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.el-button__primary {
    background-color: #5264cd;
    border: 1px solid #5264cd;
}

.el-button__primary span {
    color: white !important;
}

.el-select-dropdown__item {
    width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.spn-post-content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

i.fa-face-frown {
    color: #483d8b;
}

@media (max-width: 1340px) {
    .flex-column {
        grid-template-columns: 100%;
        gap: 10px 0;

        .search-input {
            &>div {
                width: 100% !important;
            }

            width: 50% !important;
        }

        .grid-start {
            justify-content: start;
        }
    }
}

@media (max-width: 640px) {
    .flex-column {
        .search-input {
            &>div {
                width: 100% !important;
            }

            width: 100% !important;
        }
    }
}
</style>
