<template>
    <DrashBoard>
        <Loading v-if="loading" />
        <LinkInstagram v-if="!showLinkInstagram" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li class="mr-4">
                    <Link :href="route('admin.creative')" class="font-normal breakcumb-active">クリエイティブ分析</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5" :class="{ active: currentTabPostCategory == 'normal' }"
                        @click="currentTabPostCategory = 'normal'">
                        通常投稿
                    </li>
                    <li v-if="isShowReel" class="px-2 relative pb-5 ml-3"
                        :class="{ active: currentTabPostCategory == 'reel' }" @click="currentTabPostCategory = 'reel'">
                        リール投稿
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:exportTabs>
            <div class="!mt-0 ml-20 tabs">
                <ul class="flex">
                    <li class="pr-2 relative pb-5" :class="{ active: currentTabPostCategory == 'normal' }"
                        @click="currentTabPostCategory = 'normal'">
                        通常投稿
                    </li>
                    <li v-if="isShowReel" class="px-2 relative pb-5 ml-3"
                        :class="{ active: currentTabPostCategory == 'reel' }" @click="currentTabPostCategory = 'reel'">
                        リール投稿
                    </li>
                </ul>
            </div>
        </template>
        <template v-if="!isExport" v-slot:manual>
            <UserManual :isShow="isShowManual" :content="arrContent" @closeManualPopup="toogleManual" />
        </template>
        <div class="bg-background" :class="{ 'sm:h-min-screen md:h-screen': !isExport }">
            <UserNav v-if="!isExport" />
            <div v-if="!isExport" class="flex items-center md:relative">
                <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                    <p class="text-button_pink font-semibold text-3sm"
                        :class="{ 'ml-5 md:ml-20': isExport, 'ml-5 md:ml-12': !isExport }">
                        クリエイティブ分析
                    </p>
                    <div class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer" @click="toogleManual">
                        <img :src="suggestIcon" />
                    </div>
                </div>
            </div>
            <div v-if="isExport" class="flex justify-between items-center my-2"
                :class="{ 'mx-5 md:mx-20': isExport, 'ml-12': !isExport }">
                <div class="flex items-center md:relative">
                    <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                        <h2 class="text-button_pink !text-[14px] font-semibold">クリエイティブ分析</h2>
                    </div>
                </div>
                <button v-if="isRole == 'master'" :disabled="numberExportCreative <= 0 && isRole !== 'master'"
                    @click="exportCreativeCSV"
                    class="bg-black ml-3 py-3 px-7 text-white !text-[14px] button-shadow rounded">
                    CSV出力
                </button>

                <el-tooltip v-else class="tooltip-customer" effect="dark"
                    :content="'クリエイティブ分析画面今月後 ' + numberExportCreative + ' 回CSV出力出来ます。'" placement="top">
                    <button :disabled="numberExportCreative <= 0 && isRole !== 'master'" @click="exportCreativeCSV"
                        class="bg-black ml-3 py-3 px-7 text-white !text-[14px] button-shadow rounded">
                        CSV出力
                    </button>
                </el-tooltip>
            </div>
            <div :class="{ 'px-5 md:px-20': isExport, 'bg-background md:px-10 sm:px-5': !isExport }">
                <div :class="{ 'bg-white': isExport }">
                    <div>
                        <p class="uppercase text-button_pink font-semibold mb-3 pl-[6.5px] md:pl-3.2 text-3sm">top10</p>
                        <!-- list post best -->
                        <ul v-if="topBestPosts.length"
                            class="flex mt-2.5 mb-3 xl:flex-nowrap sm:flex-wrap xl:justify-start md:justify-center md:gap-y-[160px] sm:gap-y-[140px]"
                            :class="{ 'pr-[13px]': isExport }">
                            <li v-for="(post, index) in topBestPosts" :key="index"
                                class="md:h-28 md:w-28 sm:h-24 sm:w-1/3 xl:h-auto xl:w-1/10 px-[6.5px] top-list-item image__error">
                                <el-image :src="post?.thumbnail_url" :alt="post?.content" class="rounded w-100" lazy>
                                    <template #error>
                                        <div class="image-slot">
                                            <i class="fa-regular fa-image text-xl text-gray-600" />
                                        </div>
                                    </template>
                                </el-image>
                                <p class="text-center md:pb-3 md:pt-6 !text-[10px] lg:!text-[12px]">カラー：{{ post?.hexCode
                                }}</p>
                                <div class="h-[96px] w-100 px-[6.5px] rounded"
                                    :style="{ 'background-color': post?.hexCode }"></div>
                            </li>
                        </ul>
                        <el-empty v-else description="データはありません" />
                    </div>
                    <!-- list post worst -->
                    <div class="sm:pb-[7rem] md:pb-0 mt-[10rem] xl:mt-8" :class="{ 'mb-10': isExport }">
                        <p class="uppercase text-button_pink font-semibold mb-3 pl-[6.5px] md:pl-3.2 text-3sm">Worst10
                        </p>
                        <div v-if="topWorstPosts.length"
                            class="flex mt-2.5 mb-3 xl:flex-nowrap sm:flex-wrap xl:justify-start md:justify-center md:gap-y-[160px] sm:gap-y-[140px]"
                            :class="{ 'pr-[13px]': isExport }">
                            <div v-for="(post, index) in topWorstPosts" :key="index"
                                class="md:h-28 md:w-28 sm:h-24 sm:w-1/3 xl:h-auto xl:w-1/10 px-[6.5px] top-list-item image__error">
                                <el-image :src="post?.thumbnail_url" :alt="post?.content" class="rounded w-100" lazy>
                                    <template #error>
                                        <div class="image-slot">
                                            <i class="fa-regular fa-image text-xl text-gray-600" />
                                        </div>
                                    </template>
                                </el-image>
                                <p class="text-center md:pb-3 md:pt-6 !text-[10px] lg:!text-[12px]">カラー：{{ post?.hexCode
                                }}</p>
                                <div class="h-[96px] w-100 px-[6.5px] rounded"
                                    :style="{ 'background-color': post?.hexCode }"></div>
                            </div>
                        </div>
                        <el-empty v-else description="データはありません" />
                    </div>
                </div>
            </div>
        </div>
    </DrashBoard>
</template>

<script>
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import { Inertia } from '@inertiajs/inertia'
import { fetchNumberExport, addNumberExport } from '../../Report/Mixins/limitExport.js'
import GetThumbnailUrl from '../../../Mixins/thumbnail.js'
import Loading from '@/Components/Loadding'
import UserManual from '@/Components/UserManual.vue'

export default {
    name: 'PostCreative',
    components: { DrashBoard, Loading, UserNav, BreadCrumb, Link, LinkInstagram, UserManual },
    emits: ['getTopBestPosts', 'getTopWorstPosts', 'getTopWorstPostCategory'],
    mixins: [GetThumbnailUrl],
    props: {
        userId: { type: Number },
        plan: { type: Object, require: true },
    },
    computed: {
        isShowReel() {
            if (this.$page.props?.auth?.user?.roles[0]?.name === 'master') {
                return true
            }
            return this?.$page?.props?.auth?.plan?.reel_analysis == 1
        },
        isExport() {
            return Inertia?.page?.url === '/export/report-preview'
        },
        isRole() {
            return this.$page.props?.auth?.user?.roles[0]?.name
        },
    },

    data() {
        return {
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowManual: false,
            arrContent: ['フィード投稿をエンゲージメントの高いクリエイティブと低いクリエイティブに分類することが出来ます。', 'エンゲージメントが高い傾向にあるクリエイティブを「おすすめカラー」「アスペクト比」を基準として判定することができます。', '類似させたクリエイティブを作成することで、デザイン検討において抽象的な仮説ではなく定量的な分析が可能になります。'],
            loading: false,
            numberExportCreative: 0,
            showLinkInstagram: true,
            topBestPosts: [],
            topWorstPosts: [],
            currentTabPostCategory: 'normal',
        }
    },
    created() {
        this.fetchData()
        if (!this.isExport) {
            this.checkLinkInstagram()
        }
        this.fetchNumberExportMonth()
    },
    watch: {
        currentTabPostCategory: {
            handler() {
                this.fetchData()
                this.arrContent = this.currentTabPostCategory === 'reel' ? ['リール投稿をエンゲージメントの高いクリエイティブと低いクリエイティブに分類することが出来ます。', 'エンゲージメントが高い傾向にあるクリエイティブを「おすすめカラー」「アスペクト比」を基準として判定することができます。', '類似させたクリエイティブを作成することで、デザイン検討において抽象的な仮説ではなく定量的な分析が可能になります。'] : ['フィード投稿をエンゲージメントの高いクリエイティブと低いクリエイティブに分類することが出来ます。', 'エンゲージメントが高い傾向にあるクリエイティブを「おすすめカラー」「アスペクト比」を基準として判定することができます。', '類似させたクリエイティブを作成することで、デザイン検討において抽象的な仮説ではなく定量的な分析が可能になります。']
                this.isShowManual = false
            },
        },
    },
    methods: {
        toogleManual() {
            this.isShowManual = !this.isShowManual
        },
        onInstaSubmitSuccess() {
            this.showLinkInstagram = true
        },
        // fetch data
        async fetchData() {
            this.loading = true
            this.$emit('getTopWorstPostCategory', this.currentTabPostCategory)
            await axios
                .get(route('api.top.post'), {
                    params: {
                        type: 'get',
                        post_category: this.currentTabPostCategory,
                    },
                })
                .then(async (res) => {
                    const data = Object.keys(res?.data).map((k) => res?.data[k])
                    if (data?.length) {
                        const top_posts = Object.keys(data[0]).map((k) => data[0][k])
                        const worst_posts = Object.keys(data[1]).map((k) => data[1][k])
                        for (let v of Object.values(top_posts)) {
                            const code = {
                                hexCode: await this.getHexColor(v.thumbnail_url),
                            }
                            v = Object.assign(v, code)
                        }
                        for (let v of Object.values(worst_posts)) {
                            const code = {
                                hexCode: await this.getHexColor(v.thumbnail_url),
                            }
                            v = Object.assign(v, code)
                        }
                        this.topBestPosts = top_posts
                        this.topWorstPosts = worst_posts
                        this.$emit('getTopBestPosts', this.topBestPosts)
                        this.$emit('getTopWorstPosts', this.topWorstPosts)
                    }
                    this.loading = false
                })
                .catch((err) => {
                    console.log(err)
                    this.loading = false
                })
        },
        // link instagram
        async checkLinkInstagram() {
            const check = await accountAPI.checkInsAccount()
            if (check.data) {
                this.showLinkInstagram = true
            } else {
                this.showLinkInstagram = false
            }
        },
        // expory creative CSV
        exportCreativeCSV() {
            if (this.numberExportCreative === 0) {
                return
            }

            const userId = this.userId ? this.userId : this.$page?.props?.auth?.user?.id
            const url = route('api.export.creative.csv') + '?user_id=' + userId + '&category=' + this.currentTabPostCategory
            window.location.href = url

            if (this.isRole !== 'master') {
                addNumberExport('csv', 'csv_creative')
                this.fetchNumberExportMonth()
            }
        },

        async fetchNumberExportMonth() {
            const { data } = await fetchNumberExport('csv', 'csv_creative')
            this.numberExportCreative = this.plan?.number_report_csv - data
        },
        async getHexColor(imgEl) {
            let base64Src = await this.getBaseCodeImg(imgEl + '?time=' + Math.floor(Date.now() / 1000))
            let hexCode = await this.getHexCode(base64Src)
            return hexCode
        },
    },
}
</script>

<style lang="scss" scoped>
.top-list-item .el-image {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    min-height: 50px;
}

.image__error .el-image {
    max-width: 300px;
    max-height: 200px;
    width: 100%;
    height: 96px;
}

.image__error .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 96px;
    background: var(--el-fill-color-light);
    color: var(--el-text-color-secondary);
    font-size: 30px;
}

.image__error .image-slot .el-icon {
    font-size: 30px;
}
</style>
