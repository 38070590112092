<template>
  <div
    v-if="dialogFormVisible"
    :style="{ left: left - 25 + 'px', top: top + 15 + 'px' }"
    class="absolute select-hashtag w-72 h-64 bg-white py-5 px-2 hover:cursor-text rounded-md"
  >
    <div class="flex items-center justify-center">
      <p class="text-base font-semibold mr-2">Tag:</p>
      <el-input v-model="input" :placeholder="this.$i18n.t('Search account')" />
    </div>
    <div class="flex items-center mt-4 p-1 hover:bg-slate-200 hover:cursor-pointer rounded-md">
      <img class="w-12 h-auto rounded-full mr-4" :src="accountTag.profile_picture_url" alt="" />
      <div @click="pushData(accountTag)">
        <p class="text-base text-black font-bold">{{ accountTag.username }}</p>
        <p class="text-xs text-gray-400">{{ accountTag.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { filterCompetitorAccount } from '@/Api/Account'
export default {
  props: ['dialogFormVisible', 'left', 'top', 'height', 'width'],
  data() {
    return {
      input: '',
      arrTag: [],
      accountTag: {},
    }
  },
  methods: {
    async seachAccount(username) {
      const res = await filterCompetitorAccount(username)
      this.accountTag = res.data
    },
    pushData(item) {
      const tagSelected = {}
      tagSelected.username = item.username
      tagSelected.top = this.top
      tagSelected.left = this.left
      tagSelected.x = this.left / this.width
      tagSelected.y = this.top / this.height
      this.input = ''
      this.$emit('closeSelectTag', tagSelected)
    },
    mounted() {
      this.$refs.selectTagRef.focus()
    },
  },
  watch: {
    input: function () {
      this.seachAccount(this.input)
    },
  },
}
</script>

<style lang="scss">
.select-hashtag {
  z-index: 999;
  position: absolute;
  .el-input {
    width: auto;
  }
  &::before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-bottom: 15px solid #fff;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: -15px;
    left: 15px;
    margin: 0 auto;
  }
}
</style>
