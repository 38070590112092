<template>
  <DrashBoard>
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li>
          <Link :href="route('admin.setting.index')" class="font-normal breakcumb-active">管理設定</Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-5 relative pb-5" :class="{ active: tabActive === 'comment' }" @click="tabActive = 'comment'">
            コメント設定
          </li>
          <li
            v-if="roleAdmin.includes(isRole)"
            class="px-5 relative pb-5"
            :class="{ active: tabActive === 'pdf' }"
            @click="tabActive = 'pdf'"
          >
            PDF出力設定
          </li>
          <li
            v-if="roleAdmin.includes(isRole)"
            class="px-5 relative pb-5"
            :class="{ active: tabActive === 'pptx' }"
            @click="tabActive = 'pptx'"
          >
            PPTX出力設定
          </li>
        </ul>
      </div>
    </template>
    <div class="bg-background pb-20 min-h-screen">
      <div class="statistic md:px-10 sm:px-5">
        <Comment class="p" v-if="tabActive === 'comment'" />
        <Pdf v-if="tabActive === 'pdf'" />
        <Pptx v-if="tabActive === 'pptx'" />
      </div>
    </div>
  </DrashBoard>
</template>

<script>
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import Comment from './Comment/Index.vue'
import Pdf from './PDF/Index.vue'
import Pptx from './PPTX/Index.vue'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
export default {
  name: 'Setting',
  components: { DrashBoard, UserNav, Comment, Pdf, Pptx, BreadCrumb, Link },

  computed: {
    isRole() {
      return this.$page.props?.auth?.user?.roles[0]?.name
    },
  },

  data() {
    return {
      roleAdmin: ['master', 'admin_agency', 'admin_oem', 'admin_enterprise'],
      tabActive: 'comment',
    }
  },
}
</script>
