<template>
    <el-dialog
        v-model="value"
        @close="onClose"
        :title="this.$i18n.t('add ads acccount')"
        width="30%"
        custom-class="custom-dialog"
    >
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules">
            <el-form-item width="50%" prop="account">
                <div v-for="(item, i) in data" :key="i">
                    <div class="flex items-center mt-[10px]">
                        <el-radio-group v-model="ruleForm.account">
                            <el-radio
                                :label="JSON.stringify(item)"
                                size="large"
                            >
                                <div class="flex items-center edit-ads">
                                    <div class="pl-2">{{ item.name }}</div>
                                    <div class="pl-2">{{ $t("ID tài khoản quảng cáo : ")}} {{ item.account_id }}</div>
                                </div>
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="onClose">{{ $t("Cancel") }}</el-button>
                <el-button type="primary" @click="handleSubmit()">
                    {{ $t("Submit") }}
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import Ins from "@/Services/Ins";

export default {
    props: ["data", "value"],

    data() {
        return {
            ruleForm: {
                account: null,
            },
            rules: {
                account: [
                    {
                        required: true,
                        message: this.$i18n.t("Please input select account"),
                        trigger: "change",
                    },
                ],
            },
        };
    },

    emits: ["update:modelValue", "onSubmitSuccess"],

    methods: {
        onClose() {
            this.$emit("update:modelValue", false);
        },
        handleSubmit() {
            const accountAds = JSON.parse(this.ruleForm.account);

            this.$refs.ruleFormRef.validate(async (valid, fields) => {
                if (valid) {
                    Ins.addAdsAccount(accountAds)
                        .then((res) => {
                            this.onClose();
                            this.$toast.success(
                                this.$t("Account link successful")
                            );
                            this.$emit("onSubmitSuccess");
                            this.ruleForm.account = null
                        })
                        .catch((error) => {
                            this.onClose();
                            this.$toast.error(this.$t("Account link failed"));
                            console.log(error);
                        });
                }
            });
        },
    },
};
</script>

<style scoped>
.custom-dialog .el-form-item__content {
    display: block !important;
}
.custom-dialog .el-dialog__body {
    padding: 10px;
}
.edit-ads{
    display: block;
    width: 100%;
    line-height: 1.5;
}
</style>
