<template>
  <Statistic />
</template>

<script>
import Detail from "./Detail.vue";
import Statistic from "./Statistic.vue";
export default {
    components:{
        Statistic ,
        Detail
    }
}
</script>

<style>

</style>