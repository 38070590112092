<template>
    <div v-if="!isExport" class="flex h-screen overflow-hidden">
        <!-- <PopupNotification /> -->
        <AppSidebar />
        <div class="wrapper relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            <AppHeader />
            <div @click="hiddenMenu" id="main-page"></div>
            <div @click="hiddenPopup" id="popup-page"></div>
            <div class="nav-breakcumb">
                <slot name="breakcumb"></slot>
                <slot name="tabs"></slot>
            </div>
            <div class="body flex-grow-1 relative" :class="{ 'z-10': !isEngagementPage || !linkAcc }">
                <slot></slot>
            </div>
            <div class="body flex-grow-1 relative z-90">
                <slot name="manual"></slot>
            </div>
            <div class="body flex-grow-1 relative z-90">
                <slot name="manualChart"></slot>
            </div>
        </div>
    </div>
    <div v-else class="body flex-grow-1 relative z-10">
        <slot name="exportTabs"></slot>
        <slot></slot>
    </div>
</template>

<script setup>
import BreezeAuthenticatedLayout from '@/Layouts/Authenticated.vue'
import AppSidebar from '@/Layouts/AppSidebar'
import AppHeader from '@/Layouts/AppHeader'
import Notification from '@/Components/Notifications.vue'
import { Head } from '@inertiajs/inertia-vue3'
import { Inertia } from '@inertiajs/inertia'
import { computed } from 'vue'

// import PopupNotification from '@/Components/PopupNotification';

const props = defineProps({
    notification: {
        type: Boolean,
        default: true,
    },
})

const isExport = computed(() => Inertia?.page?.url === '/export/report-preview')
const isEngagementPage = computed(() => Inertia?.page?.url === '/engagement-analysis')
const linkAcc = Inertia?.page?.props?.auth?.account

function hiddenMenu() {
    let sidebarMenu = document.getElementById('sidebar-menu')
    sidebarMenu.classList.remove('sidebar-mobile__active')
    let mainPage = document.getElementById('main-page')
    mainPage.classList.remove('main-page__active')
}
function hiddenPopup() {
    let popup = document.getElementById('popup-page')
    popup.classList.remove('popup-page__active')
    let el = document.getElementById('chart-manual')
    el.classList.add('hidden')
}
</script>
