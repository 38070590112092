<template>
  <DrashBoard>
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li>
          <Link :href="route('admin.accounts.index')" class="font-normal">アカウント分析</Link>
        </li>
        <span class="font-normal text-notice_date mx-2">></span>
        <li>
          <Link :href="route('admin.account-register.index')" class="font-normal breakcumb-active">登録一覧</Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-6 relative pb-5 active">登録一覧</li>
        </ul>
      </div>
    </template>
    <div class="px-14.5 bg-background pt-11">
      <div class="flex justify-between">
        <div class="menu flex w-5/12 mb-9">
          <input
            class="flex-1 mr-5 input-cm"
            placeholder="検索キーワードを入力してください"
            @input="handleSearch"
            v-model="searchData"
            type="text"
          />
          <div class="bg-blue-600 text-white px-7 py-4.5 w-max rounded button-shadow cursor-pointer" @click="getData">
            <button>検索</button>
          </div>
        </div>
        <div class="bg-blue-600 text-white px-7 py-4.5 w-max rounded button-shadow cursor-pointer mb-9">
          <button>ユーザーを作成</button>
        </div>
      </div>
      <table class="mt-2.5 overflow-y-scroll">
        <thead>
          <tr>
            <th
              v-for="(item, index) in label"
              :key="index"
              @mouseover="showSelectBox(index)"
              @mouseleave="hiddenSelectBox"
              class="hover:cursor-pointer text-2sm relative py-4 font-normal bg-header_table border-r text-center rounded-md border-2"
            >
              <p class="text-white font-normal flex items-center justify-center">
                {{ item.text }}
                <svg
                  class="ml-11"
                  v-show="item.select"
                  width="9"
                  height="6"
                  viewBox="0 0 9 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1.25293 1.32812L4.77434 4.58862L7.88785 1.32812" stroke="white" />
                </svg>
              </p>
              <div v-show="item.select" ref="selectBox" class="absolute top-full left-1/2 w-full hidden">
                <svg
                  class="left-1/2 -translate-x-1/2"
                  width="22"
                  height="11"
                  viewBox="0 0 22 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.6694 0.303955L21.0995 10.7419H0.229492L10.6694 0.303955Z"
                    fill="#EBEBEB"
                  />
                </svg>
                <ul class="bg-white text-dark w-full left-1/2 py-4 -translate-x-1/2">
                  <li v-for="item in itemSelect" :key="item">
                    {{ item }}
                  </li>
                </ul>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="bg-body_table">
          <tr v-for="(item, index) in staticTableData" :key="index" @dblclick="navigateToDetail(item.id)">
            <td>
              <button class="bg-bluelight text-white px-3.5 py-2 block m-auto button-shadow rounded">ログイン</button>
            </td>
            <td>
              <div>
                <!-- <button @click="handleLoginInsta"> -->
                <button>
                  <img class="custom-ins" src="/images/ins.png" />
                </button>
              </div>
            </td>
            <td class="py-5.5" v-for="(label, index) in labelToBind" :key="index">
              <p class="text-center" :class="label.key">
                {{ (label.isDate && formatDate(item[label.key])) || item[label.key] }}
              </p>
            </td>
            <td class="py-5.5">
              <div v-if="item.status" class="flex items-center justify-center">
                <p class="bg-bluelight w-2.5 h-2.5 mr-2 rounded-full border border-item mutil-border"></p>
                <p class="text-button_profile">契約中</p>
              </div>
              <div v-if="!item.status" class="flex items-center justify-center">
                <p class="bg-pink-190 w-2.5 h-2.5 mr-2 rounded-full border border-item mutil-border"></p>
                <p class="text-button_profile color-pink-190">契約中</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <vue-final-modal v-model="isShow">
        <div class="modal-body p-[10px] md:w-6/10 w-8/10">
          <form>
            <div v-for="(item, i) in businessAccount" :key="i">
              <input type="radio" v-model="account" :value="item" />
              {{ item.name }}
            </div>
            <button type="button" @click="submit">保存</button>
          </form>
        </div>
      </vue-final-modal>
      <Panigation :totalPage="totalPage" :currentPage="currentPage" @updateCurrentPage="updateCurrentPage" />
    </div>
  </DrashBoard>
</template>

<script>
import Panigation from '@/Components/Pagination.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import Table from '@/Components/Table.vue'
import * as account from '@/Api/Users'
import { Link } from "@inertiajs/inertia-vue3";
import { Inertia } from '@inertiajs/inertia'
import BreadCrumb from '@/Components/Breadcrumb.vue'
export default {
  components: {
    Table,
    DrashBoard,
    Panigation,
    BreadCrumb,
    Link
  },
  data() {
    return {
      itemSelect: ['株式会社トライハッチ', '株式会社トライハッチ', '株式会社トライハッチ', 'active'],
      selectFilter: false,
      label: [
        { text: '代理ログイン', select: false },
        { text: 'Social Network', select: false },
        { text: '会社名', select: true, key: 'company' },
        { text: 'アカウント名', select: true, key: 'account_name' },
        { text: '権限', select: true, key: 'role' },
        { text: '利用プラン', select: true, key: 'plans' },
        {
          text: '決済タイプ',
          select: true,
          key: 'payment',
        },
        {
          text: '利用開始日',
          select: true,
          key: 'created_at',
          isDate: true,
        },
        {
          text: '契約満了日',
          select: true,
          key: 'contact_expriation_date',
          isDate: true,
        },
        { text: '契約状況', select: false, key: 'status' },
      ],
      staticTableLabel: ['time', 'post', 'コメント数', 'リーチ数', '保存数'],
      staticTableData: [],
      currentPage: 1,
      searchData: undefined,
      totalPage: null,
      time: {},
      isShow: false,
      businessAccount: [],
      account: null,
    }
  },
  computed: {
    labelToBind: function () {
      return this.label.filter((item) => item.select === true)
    },
  },
  methods: {
    handleLoginInsta() {
      FB.login(
        (response) => {
          if (!response.authResponse) {
            return
          }

          const access_token = response.authResponse.accessToken
          axios
            .get(
              'https://graph.facebook.com/me/accounts?fields=instagram_business_account{name,profile_picture_url},access_token&&access_token=' +
                access_token
            )
            .then((res) => {
              const data = res.data.data
                .filter((item) => item.instagram_business_account)
                .map((v) => ({
                  access_token: v.access_token,
                  ...v.instagram_business_account,
                }))
              this.businessAccount = data
              this.isShow = true
            })
            .catch((error) => console.log(error))
        },
        {
          scope: this.scope,
        }
      )
    },
    submit() {
      account.insAccountLink(this.account)
      this.isShow = false
      return alert('done')
    },
    showSelectBox: function (index) {
      for (let i = 0; i < this.label.length; i++) {
        this.$refs.selectBox[i].classList.remove('active')
      }
      this.$refs.selectBox[index].classList.add('active')
    },
    hiddenSelectBox: function () {
      for (let i = 0; i < this.label.length; i++) {
        this.$refs.selectBox[i].classList.remove('active')
      }
    },
    getData: async function () {
      const res = await account.getUserList(this.searchData, this.currentPage)
      this.staticTableData = res.data.data
      this.totalPage = Math.round(res.data.meta.total / res.data.meta.per_page)
    },
    handleSearch: function () {
      clearTimeout(this.time)
      this.time = setTimeout(() => {
        this.getData()
      }, 200)
    },
    formatDate: function (data) {
      if (data) {
        const date = new Date(data)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        day = day < 10 ? '0' + day : day
        month = month < 10 ? '0' + month : month
        return `${year}/${month}/${day}`
      }
    },
    updateCurrentPage: function (page) {
      this.currentPage = page
      this.getData()
    },
    navigateToDetail: function (id) {
      Inertia.visit('/account-management', {
        data: {
          userId: id,
        },
      })
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="scss" scoped>
.custom-ins {
  width: 100px;
  height: auto;
  display: inline;
}
.modal-body {
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 9px;
}
table {
  // font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  .active {
    display: block;
  }
  tr {
    box-shadow: 0px 1px 0px rgba(169, 169, 169, 0.25);
    border-bottom: solid 1px rgba(169, 169, 169, 0.25);
  }
  th {
    ul {
      padding: 0;
      top: 120%;
      border: 1px solid #ebebeb;
      &:hover {
        cursor: pointer;
      }
      li {
        padding: 0.5rem 0;
        transition: all 0.3s;
        color: #020202;
        &:hover {
          color: #fff;
          background: #5264cd;
          cursor: pointer;
        }
      }
    }
    svg {
      top: 100%;
    }
  }
  tr:last-of-type {
    border-bottom: 0;
  }
  &.light {
    tr {
      background-color: #ffffff;
    }
    thead tr {
      border: 1px solid #e2e2e2;
    }
    th {
      background: #ffffff;
      color: #414141;
      border: 0;
    }
  }
  .mutil-border {
    box-shadow: 0 0 0 3px #ffffff, 0 0 0 5px #dbdbdb;
  }
  .bg-pink-190 {
    background-color: #be3d8f;
  }
  .color-pink-190 {
    color: #be3d8f;
  }
}
</style>
