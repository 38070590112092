import { EMPTY_IMG } from '../Mixins/media.js'
export default {
  methods: {
    genSlideReachOfPostChart(pptx, masterSlide, text, title) {
      const slideReachOfPostChart = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideReachOfPostChart.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      slideReachOfPostChart.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 8.01,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 22,
      })

      slideReachOfPostChart.addText('合計投稿数', {
        shape: pptx.shapes.RECTANGLE,
        x: 0.64,
        y: 1.14,
        w: 2.7,
        h: 0.4,
        bold: true,
        align: 'center',
        valign: 'middle',
        color: '303030',
        fontSize: 12,
        line: { color: '303030' },
      })
      let sumPostAccount = masterSlide.sumData.dataSeletedMonth.postAccountDetail.sumPostAccount
      slideReachOfPostChart.addText(String(sumPostAccount), {
        x: 0.64,
        y: 1.77,
        w: 2.7,
        h: 0.5,
        bold: true,
        align: 'center',
        valign: 'middle',
        color: 'e7289e',
        fontSize: 24,
      })

      slideReachOfPostChart.addText('合計リーチ数', {
        shape: pptx.shapes.RECTANGLE,
        x: 4.16,
        y: 1.14,
        w: 2.7,
        h: 0.4,
        bold: true,
        align: 'center',
        valign: 'middle',
        fontSize: 12,
        line: { color: '303030' },
      })
      let sumReach = masterSlide.sumData.dataSeletedMonth.postAccountDetail.sumReach
      slideReachOfPostChart.addText(String(sumReach), {
        x: 4.16,
        y: 1.77,
        w: 2.7,
        h: 0.5,
        bold: true,
        align: 'center',
        valign: 'middle',
        color: 'e7289e',
        fontSize: 24,
      })

      slideReachOfPostChart.addText('合計インプレッション数', {
        shape: pptx.shapes.RECTANGLE,
        x: 7.68,
        y: 1.14,
        w: 2.7,
        h: 0.4,
        bold: true,
        align: 'center',
        valign: 'middle',
        color: '303030',
        fontSize: 12,
        line: { color: '303030' },
      })
      let sumImpression = masterSlide.sumData.dataSeletedMonth.postAccountDetail.sumImpression

      slideReachOfPostChart.addText(String(sumImpression), {
        x: 7.68,
        y: 1.77,
        w: 2.7,
        h: 0.5,
        bold: true,
        align: 'center',
        valign: 'middle',
        color: 'e7289e',
        fontSize: 24,
      })
      if (sumReach == 0 && sumImpression == 0 && sumPostAccount == 0) {
        slideReachOfPostChart.addImage({ path: EMPTY_IMG, x: 3.9, y: 2.5, w: 3.56, h: 3.56 })
      } else {
        // // chart
        let opts = {
          x: 0.64,
          y: 2.59,
          w: 9.76,
          h: 3.31,
          barDir: 'col',
          barGrouping: 'clustered',
          catAxisLabelColor: '787878',
          catAxisLabelFontSize: 12,
          catAxisOrientation: 'minMax',
          showTitle: true,
          title: 'フィード投稿結果',
          titleColor: '787878',
          titleFontSize: 12,
          showLegend: true,
          legendPos: 'b',
          chartColors: ['e7299e', '1F3151'],
          valAxes: [
            {
              showValAxisTitle: false,
              valAxisTitle: 'Primary Value Axis',
            },
            {
              showValAxisTitle: false,
              valAxisTitle: 'Secondary Value Axis',
              catAxisOrientation: 'maxMin',
              catAxisLabelRotate: -45,
              catAxisLabelFontSize: 9,
              valGridLine: { style: 'none' },
            },
          ],
          catAxes: [
            {
              catAxisTitle: 'Primary Category Axis',
            },
            {
              catAxisHidden: true,
            },
          ],
        }

        let chartTypes = [
          {
            type: pptx.charts.BAR,
            data: [
              {
                name: 'リーチ数',
                labels: masterSlide.dataLabels,
                values: masterSlide.postChartData.dataReachPostsOfDay,
              },
              {
                name: 'インプレッション数',
                labels: masterSlide.dataLabels,
                values: masterSlide.postChartData.dataImpressionsPostOfDay,
              },
            ],
            options: {
              chartColors: ['BCBCBC', '2BB6CE'],
              lineDataSymbol: 'circle',
              secondaryValAxis: !!opts.valAxes,
              secondaryCatAxis: !!opts.catAxes,
            },
          },
          {
            type: pptx.charts.LINE,
            data: [
              {
                name: '投稿数',
                labels: masterSlide.dataLabels,
                values: masterSlide.postChartData.postOfDay,
              },
            ],
          },
        ]
        slideReachOfPostChart.addChart(chartTypes, opts)
        // end chart
      }

      slideReachOfPostChart.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.29,
        w: 10.14,
        h: 1.56,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slideReachOfPostChart
    },
  },
}
