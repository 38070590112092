<template>
    <DrashBoard>
        <LinkInstagram v-if="!showLinkInstagram && !isExport" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li class="mr-4">
                    <Link :href="route('admin.post-hashtag.index')" class="font-normal breakcumb-active">時間/曜日別分析</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5" :class="{ active: currentTabPostCategory == 'normal' }"
                        @click="currentTabPostCategory = 'normal'">
                        通常投稿
                    </li>
                    <li v-if="isShowReel" class="px-2 relative pb-5 ml-3"
                        :class="{ active: currentTabPostCategory == 'reel' }" @click="currentTabPostCategory = 'reel'">
                        リール投稿
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:exportTabs>
            <div class="!mt-0 ml-20 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5" :class="{ active: currentTabPostCategory == 'normal' }"
                        @click="currentTabPostCategory = 'normal'">
                        通常投稿
                    </li>
                    <li v-if="isShowReel" class="px-2 relative pb-5 ml-3"
                        :class="{ active: currentTabPostCategory == 'reel' }" @click="currentTabPostCategory = 'reel'">
                        リール投稿
                    </li>
                </ul>
            </div>
        </template>
        <template v-if="!isExport" v-slot:manual>
            <UserManual :isShow="isShowManual" :content="arrContent" @closeManualPopup="toogleManual" />
        </template>
        <div class="bg-background" :class="{ 'pb-10': isExport, 'pb-20': !isExport }" v-loading="loading">
            <UserNav v-if="!isExport"></UserNav>
            <div class="statistic" :class="{ 'md:px-10': !isExport, 'px-5 md:px-20': isExport }">
                <div class="flex sm:flex-col md:flex-row justify-between md:px-0" :class="{ 'sm:pl-5': !isExport }">
                    <div v-if="isExport">
                        <p class="text-button_pink font-semibold md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5 text-3sm">時間/曜日別分析
                        </p>
                    </div>
                    <div v-else class="flex items-center md:relative">
                        <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                            <p class="text-button_pink font-semibold text-3sm">時間/曜日別分析</p>
                            <div class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer" @click="toogleManual">
                                <img :src="suggestIcon" />
                            </div>
                        </div>
                    </div>
                    <div class="md:pt-10">
                        <el-date-picker v-model="dateRange" :type="'daterange'" size="large" range-separator="-"
                            start-placeholder="Start date" end-placeholder="End date" :disabled-date="disabledDate"
                            :clearable="false" popper-class="customDatePickerEngagement" />
                    </div>
                </div>
                <div class="bg-white md:py-5 md:px-5 sm:py-5 sm:px-2 mb-5 mt-2">
                    <el-row v-if="postInfo">
                        <Info title="インプレッション数" :total="postInfo?.sumImpressions" :countPost="postInfo?.sumPost"
                            v-if="currentTabPostCategory != 'reel'" />
                        <Info title="リーチ数" :total="postInfo?.sumReachs" :countPost="postInfo?.sumPost" />
                        <Info title="保存数" :total="postInfo?.sumSaved" :countPost="postInfo?.sumPost" />
                        <Info title="動画再生数" :total="postInfo?.sumVideoViews" :countPost="postInfo?.sumPost" />
                        <Info title="いいね数" :total="postInfo?.sumLikes" :countPost="postInfo?.sumPost" />
                        <Info title="コメント数" :total="postInfo?.sumComments" :countPost="postInfo?.sumPost" />
                        <Info title="エンゲージメント率" :total="postInfo?.sumEngagementRate" name="sum_engagement_rate"
                            v-if="currentTabPostCategory == 'normal'" />
                        <Info title="エンゲージメント数" :total="postInfo?.sumEngagement" :countPost="postInfo?.sumPost"
                            v-else />
                        <Info :title="currentTabPostCategory == 'reel' ? 'リール投稿数' : '通常投稿数'" :total="postInfo?.sumPost"
                            name="sum_post" />
                    </el-row>
                </div>
                <div class="bg-white md:py-5 md:px-5 sm:py-5 sm:px-2 mb-5 mt-2">
                    <p class="text-button_pink font-semibold pl-2 text-3sm">時間/曜日別エンゲージメント</p>
                    <div class="mt-7 tabs md:py-0 sm:py-1">
                        <ul class="flex">
                            <li class="px-2 relative pb-5" :class="{ active: currentTabTime == 'impressions' }"
                                @click="currentTabTime = 'impressions'" v-if="currentTabPostCategory != 'reel'">
                                インプレッション
                            </li>
                            <li class="px-2 relative pb-5 ml-4" :class="{ active: currentTabTime == 'reach' }"
                                @click="currentTabTime = 'reach'">
                                リーチ
                            </li>
                            <li class="px-2 relative pb-5 ml-4" :class="{ active: currentTabTime == 'saved' }"
                                @click="currentTabTime = 'saved'">
                                保存数
                            </li>
                            <li class="px-2 relative pb-5 ml-4" :class="{ active: currentTabTime == 'video_views' }"
                                @click="currentTabTime = 'video_views'">
                                動画再生数
                            </li>
                            <li class="px-2 relative pb-5 ml-4" :class="{ active: currentTabTime == 'like' }"
                                @click="currentTabTime = 'like'">
                                いいね
                            </li>
                            <li class="px-2 relative pb-5 ml-4" :class="{ active: currentTabTime == 'comment' }"
                                @click="currentTabTime = 'comment'">
                                コメント
                            </li>
                            <li class="px-2 relative pb-5" :class="{ active: currentTabTime == 'engagement_rate' }"
                                @click="currentTabTime = 'engagement_rate'" v-if="currentTabPostCategory == 'normal'">
                                エンゲージメント率
                            </li>
                            <li class="px-2 relative pb-5" :class="{ active: currentTabTime == 'engagement' }"
                                @click="currentTabTime = 'engagement'" v-else>
                                エンゲージメント数
                            </li>
                        </ul>
                    </div>
                    <el-row class="mt-5" :class="{ 'export-chart-row__style': isExport }">
                        <el-col :md="12" class="lg:pr-10 sm:pr-0" :class="{ 'export-chart__style': isExport }">
                            <p class="text-2sm font-semibold">時間別</p>
                            <HorizonBarChart :chartData="chartDataHours" :chartOptions="chartOptionsHours"
                                :canvasID="engagementAnalysisChartID + '-' + 'v1'"
                                v-if="chartDataHours?.datasets[0]?.data?.length" />
                            <el-empty description="データはありません" v-else />
                        </el-col>
                        <el-col :md="12" class="lg:pl-10 sm:pl-0" :class="{ 'export-chart__style': isExport }">
                            <p class="text-2sm font-semibold">曜日別</p>
                            <HorizonBarChart :chartData="chartDataDays" :chartOptions="chartOptionsDays"
                                :canvasID="engagementAnalysisChartID + '-' + 'v2'"
                                v-if="chartDataDays?.datasets[0]?.data?.length" />
                            <el-empty description="データはありません" v-else />
                        </el-col>
                    </el-row>
                </div>
                <div class="bg-white md:px-5 px-2 mb-5">
                    <div v-if="!isExport" class="flex items-center relative pt-2">
                        <div class="flex items-center">
                            <p class="text-button_pink font-semibold pl-2 text-3sm">時間/曜日別ヒートマップ</p>
                            <div class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer" @click="showPopup">
                                <img :src="suggestIcon" class="md:relative" />
                            </div>
                        </div>
                        <UserManualChart :isShow="isShowManualChart" :content="arrContentChart"
                            @closeManualPopupChart="hiddenPopup" />
                    </div>
                    <p v-else class="text-button_pink font-semibold pl-2 text-3sm pt-2">時間/曜日別ヒートマップ</p>
                    <div class="mt-7 tabs">
                        <ul class="flex">
                            <li class="px-2 relative pb-5" :class="{ active: currentTabHoursOfDay == 'impressions' }"
                                @click="currentTabHoursOfDay = 'impressions'" v-if="currentTabPostCategory != 'reel'">
                                インプレッション
                            </li>
                            <li class="px-2 relative pb-5 ml-4" :class="{ active: currentTabHoursOfDay == 'reach' }"
                                @click="currentTabHoursOfDay = 'reach'">
                                リーチ
                            </li>
                            <li class="px-2 relative pb-5 ml-4" :class="{ active: currentTabHoursOfDay == 'saved' }"
                                @click="currentTabHoursOfDay = 'saved'">
                                保存数
                            </li>
                            <li class="px-2 relative pb-5 ml-4"
                                :class="{ active: currentTabHoursOfDay == 'video_views' }"
                                @click="currentTabHoursOfDay = 'video_views'">
                                動画再生数
                            </li>
                            <li class="px-2 relative pb-5 ml-4" :class="{ active: currentTabHoursOfDay == 'like' }"
                                @click="currentTabHoursOfDay = 'like'">
                                いいね
                            </li>
                            <li class="px-2 relative pb-5 ml-4" :class="{ active: currentTabHoursOfDay == 'comment' }"
                                @click="currentTabHoursOfDay = 'comment'">
                                コメント
                            </li>
                            <li class="px-2 relative pb-5"
                                :class="{ active: currentTabHoursOfDay == 'engagement_rate' }"
                                @click="currentTabHoursOfDay = 'engagement_rate'"
                                v-if="currentTabPostCategory == 'normal'">
                                エンゲージメント率
                            </li>
                            <li class="px-2 relative pb-5" :class="{ active: currentTabHoursOfDay == 'engagement' }"
                                @click="currentTabHoursOfDay = 'engagement'" v-else>
                                エンゲージメント数
                            </li>
                        </ul>
                    </div>
                    <div class="mt-5 md:pr-5 sm:pr-0">
                        <BubbleChart :chartData="bubbleChartData" :chartOptions="bubbleChartOptions"
                            :canvasID="engagementAnalysisChartID + '-' + 'v3'" v-if="checkShowBubbleChart()" />
                        <el-empty description="データはありません" v-else />
                    </div>
                </div>
            </div>
        </div>
    </DrashBoard>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import HorizonBarChart from './HorizonBarChart/Basic.vue'
import BubbleChart from './BubbleChart.vue'
import Info from './Base/Info.vue'
import dayjs from 'dayjs'
import * as accountAPI from '@/Api/Account'
import { Inertia } from "@inertiajs/inertia";
import UserManual from '@/Components/UserManual.vue'
import UserManualChart from '@/Components/UserManualChart.vue'

import { Tooltip } from 'chart.js'
Tooltip.positioners.customPosition = function (elements, position) {
    if (!elements.length) {
        return false
    }

    return {
        x: position.x,
        y: position.y,
    }
}

export default {
    components: { DrashBoard, UserNav, Link, BreadCrumb, LinkInstagram, HorizonBarChart, BubbleChart, Info, UserManual, UserManualChart },
    emits: ['getPostCategory', 'getPostInfo', 'getAnalysisHourCurrTab', 'getAnalysisHourCurrTabHour', 'getAnalysisHourCurrTabTime'],
    computed: {
        isShowReel() {
            if (this.$page.props?.auth?.user?.roles[0]?.name === 'master') {
                return true
            }
            return this?.$page?.props?.auth?.plan?.reel_analysis == 1
        },
        isExport() {
            return Inertia?.page?.url === '/export/report-preview'
        }
    },
    props: {
        engagementAnalysisChartID: { type: String },
    },
    data() {
        const since = new Date(new Date().setDate(new Date().getDate() - 30))
        const until = new Date(new Date().setDate(new Date().getDate() - 1))
        return {
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowManual: false,
            isShowManualChart: false,
            arrContent: ['フィード投稿の曜日・時間毎のインプレッション数・リーチ数・保存数などを確認することができます。', 'インプレッションやリーチ数などの各指標のエンゲージメントをグラフで時間・曜日別に確認ができるため、投稿に適したタイミングを把握することができます。', 'また、自アカウントのユーザーがより活発になる時間帯を軸に投稿を行うことで、初速のエンゲージメントを高めることができ、人気投稿になる可能性が向上します。'],
            arrContentChart: ['ヒートマップでは、曜日・時間・投稿数を掛け合わせ、最適な投稿タイミングを確認することができます。', '<p>エンゲージメントが最大になるポイントでは、ピンク色に色付けがされています。</p>' + '<p>そのタイミングに合わせて投稿を行うことでユーザーに閲覧される可能性が向上します。</p>', 'また、Instagramでは毎日投稿を行うことが評価される傾向にあるため、平均して最もエンゲージメントが高い時間で投稿を行うことをおすすめします。'],
            loading: false,
            currentTabTime: 'impressions',
            currentTabHoursOfDay: 'impressions',
            currentTabPostCategory: 'normal',
            postInfo: {},
            postHours: [],
            postDays: [],
            postHoursByDay: [],
            maxDataHours: 100,
            maxDataDays: 100,
            dateRange: [since, until],
            showLinkInstagram: true,
            //chart data hours
            chartDataHours: {
                labels: ['0-3時', '3-6時', '6-9時', '9-12時', '12-15時', '15-18時', '18-21時', '21-0時'],
                datasets: [{ label: '平均インプレッション', backgroundColor: '#BE3D8F', data: [] }],
            },
            chartOptionsHours: {
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                barThickness: 20,
                scales: {
                    y: { grid: { display: false } },
                    x: {
                        grid: { display: true, borderDash: [0, 4, 4, 4, 4, 4, 4] },
                        ticks: {
                            callback: function (value, index, ticks) {
                                const maxValue = Math.max(...ticks.map((v) => v.value))
                                const newLabel =
                                    Math.abs(maxValue) >= 1000000 && value
                                        ? (value / 1000000).toFixed(1) + 'M'
                                        : Math.abs(maxValue) >= 1000 && value
                                            ? (value / 1000).toFixed(1) + 'K'
                                            : value
                                return newLabel
                            },
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        displayColors: false,
                        position: 'customPosition',
                        callbacks: {
                            label: function (context, $this) {
                                const label = context?.dataset?.label
                                let value = context?.raw ? parseFloat(context?.raw) : 0
                                const str = value.toString().split('.')
                                str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                value = str.join('.')
                                if (label === '平均エンゲージメント率') {
                                    return [`${label}${value}%`]
                                } else {
                                    return [`${label}${value}`]
                                }
                            },
                        },
                    },
                    legend: { display: false },
                    datalabels: {
                        color: '#BE3D8F',
                        anchor: 'end',
                        align: 'end',
                        offset: 3,
                        formatter: function (value, context) {
                            value = parseFloat(value)
                            if (context?.dataset?.label === '平均エンゲージメント率') {
                                return value + '%'
                            } else if (context?.dataset?.label === '平均エンゲージメント数') {
                                return value
                            } else {
                                const newLabel =
                                    Math.abs(value) >= 1000000
                                        ? (value / 1000000).toFixed(1) + 'M'
                                        : Math.abs(value) >= 1000
                                            ? (value / 1000).toFixed(1) + 'K'
                                            : value
                                return newLabel
                            }
                        },
                    },
                },
                layout: {
                    padding: {
                        right: 50,
                    },
                },
            },
            //chart data days
            chartDataDays: {
                labels: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
                datasets: [{ label: '平均インプレッション', backgroundColor: '#BE3D8F', data: [] }],
            },
            chartOptionsDays: {
                indexAxis: 'y',
                maintainAspectRatio: false,
                responsive: true,
                barThickness: 20,
                scales: {
                    y: { grid: { display: false } },
                    x: {
                        grid: { display: true, borderDash: [0.0, 4, 4, 4, 4, 4, 4] },
                        ticks: {
                            callback: function (value, index, ticks) {
                                const maxValue = Math.max(...ticks.map((v) => v.value))
                                const newLabel =
                                    Math.abs(maxValue) >= 1000000 && value
                                        ? (value / 1000000).toFixed(1) + 'M'
                                        : Math.abs(maxValue) >= 1000 && value
                                            ? (value / 1000).toFixed(1) + 'K'
                                            : value
                                return newLabel
                            },
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        displayColors: false,
                        position: 'customPosition',
                        callbacks: {
                            label: function (context) {
                                const label = context?.dataset?.label
                                let value = context?.raw ? parseFloat(context?.raw) : 0

                                const str = value.toString().split('.')
                                str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                value = str.join('.')

                                if (label === '平均エンゲージメント率') {
                                    return [`${label}${value}%`]
                                } else {
                                    return [`${label}${value}`]
                                }
                            },
                        },
                    },
                    legend: { display: false },
                    datalabels: {
                        color: '#BE3D8F',
                        anchor: 'end',
                        align: 'end',
                        offset: 3,
                        formatter: function (value, context) {
                            value = parseFloat(value)

                            if (context?.dataset?.label === '平均エンゲージメント率') {
                                return parseFloat(value) + '%'
                            } else if (context?.dataset?.label === '平均エンゲージメント数') {
                                return parseFloat(value)
                            } else {
                                const newLabel =
                                    Math.abs(value) >= 1000000
                                        ? (value / 1000000).toFixed(1) + 'M'
                                        : Math.abs(value) >= 1000
                                            ? (value / 1000).toFixed(1) + 'K'
                                            : value
                                return newLabel
                            }
                        },
                    },
                },
                layout: {
                    padding: {
                        right: 50,
                    },
                },
            },
            bubbleChartData: {
                datasets: [
                    // datasets for nomal data
                    { backgroundColor: '#95b3d0', datalabels: { color: '#FFFF' }, data: [] },
                    { backgroundColor: '#9bc9e7', datalabels: { color: '#FFFF' }, data: [] },
                    { backgroundColor: '#95d3f4', datalabels: { color: '#FFFF' }, data: [] },
                    { backgroundColor: '#7dc9f3', datalabels: { color: '#FFFF' }, data: [] },
                    { backgroundColor: '#58bbf1', datalabels: { color: '#FFFF' }, data: [] },
                    { backgroundColor: '#D02D92', data: [], datalabels: { color: '#FFFF' } }, // data set for max Data
                    // data set for total post
                    { backgroundColor: '#c7c8c9', data: [], datalabels: { color: '#FFFF' } },
                    { backgroundColor: '#8a949e', data: [], datalabels: { color: '#FFFF' } },
                    { backgroundColor: '#73828e', data: [], datalabels: { color: '#FFFF' } },
                    { backgroundColor: '#506475', data: [], datalabels: { color: '#FFFF' } },
                    { backgroundColor: '#3e5569', data: [], datalabels: { color: '#FFFF' } },
                    // data set for total post of hours
                    { backgroundColor: '#c7c8c9', data: [], datalabels: { color: '#FFFF' } },
                    { backgroundColor: '#8a949e', data: [], datalabels: { color: '#FFFF' } },
                    { backgroundColor: '#73828e', data: [], datalabels: { color: '#FFFF' } },
                    { backgroundColor: '#506475', data: [], datalabels: { color: '#FFFF' } },
                    { backgroundColor: '#3e5569', data: [], datalabels: { color: '#FFFF' } },

                    { label: '合計投稿数', backgroundColor: '#3e5569', data: [], datalabels: { color: '#FFFF' } }, // legend bubble chat not has data
                    { label: '最大値', backgroundColor: '#D02D92', data: [], datalabels: { color: '#FFFF' } }, // legend bubble chat not has data
                ],
            },
            bubbleChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            label: function (context) {
                                let title_day = '日曜'
                                switch (context?.raw?.y) {
                                    case 3:
                                        title_day = '月曜'
                                        break
                                    case 4:
                                        title_day = '火曜'
                                        break
                                    case 5:
                                        title_day = '水曜'
                                        break
                                    case 6:
                                        title_day = '木曜'
                                        break
                                    case 7:
                                        title_day = '金曜'
                                        break
                                    case 8:
                                        title_day = '土曜'
                                        break
                                }
                                let title_tooltip = context?.raw?.title_tooltip ? parseFloat(context?.raw?.title_tooltip) : 0

                                const str = title_tooltip.toString().split('.')
                                str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                title_tooltip = str.join('.')

                                if (context.label !== 'post_of_day' && context.label !== 'post_of_hours') {
                                    const labelDayHours = title_day + ' ' + context?.raw?.x + '時'
                                    const value = context.label === '平均エンゲージメント率' ? title_tooltip + '%' : title_tooltip
                                    return [labelDayHours, `${context.label}${value}`]
                                } else if (context.label === 'post_of_hours') {
                                    const labelDayHours = context?.raw?.x + '時'
                                    return [labelDayHours, `合計投稿数${title_tooltip}`]
                                } else {
                                    const labelDayHours = title_day + '日'
                                    return [labelDayHours, `合計投稿数${title_tooltip}`]
                                }
                            },
                        },
                    },
                    legend: {
                        reverse: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            padding: 33,
                            usePointStyle: true,
                            boxWidth: 20,
                            filter: function (item, chart) {
                                return (item.datasetIndex === 16 || item.datasetIndex === 17) && item.text !== undefined
                            },
                        },
                        title: {
                            padding: 10,
                        },
                        onClick: (e) => { },
                    },
                    datalabels: {
                        font: {
                            size: 10,
                        },
                    },
                },
                scales: {
                    x: {
                        min: -1,
                        max: 25,
                        grid: { display: true, borderDash: [4], borderDashOffset: 5 },
                        ticks: {
                            stepSize: 1,
                            callback: function (value, index, ticks) {
                                let label = ''
                                switch (value) {
                                    case -1:
                                        label = ''
                                        break
                                    case 24:
                                        label = ''
                                        break
                                    case 25:
                                        label = ''
                                        break
                                    default:
                                        label = value
                                        break
                                }
                                return label
                            },
                        },
                    },
                    y: {
                        min: 0,
                        max: 9,
                        grid: { display: true, borderDash: [4], borderDashOffset: 5 },
                        reverse: true,
                        ticks: {
                            reverse: true,
                            stepSize: 1,
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                let label = ''
                                switch (value) {
                                    case 0:
                                        label = ''
                                        break
                                    case 1:
                                        label = ''
                                        break
                                    case 2:
                                        label = '日曜'
                                        break
                                    case 3:
                                        label = '月曜'
                                        break
                                    case 4:
                                        label = '火曜'
                                        break
                                    case 5:
                                        label = '水曜'
                                        break
                                    case 6:
                                        label = '木曜'
                                        break
                                    case 7:
                                        label = '金曜'
                                        break
                                    case 8:
                                        label = '土曜'
                                        break
                                    case 9:
                                        label = ''
                                        break
                                }
                                return label
                            },
                        },
                    },
                },
            },
        }
    },
    created() {
        this.fetchData()
        this.checkLinkInstagram()
    },
    mounted() {
        this.customDatePickerText()
    },
    watch: {
        currentTabTime: {
            handler() {
                this.chartDataHours.datasets[0].data = []
                this.chartDataDays.datasets[0].data = []
                this.$emit('getAnalysisHourCurrTabTime', this.currentTabTime)
                if (Object.keys(this.postHours).length) {
                    this.getChartDataHours()
                }
            },
        },
        currentTabHoursOfDay: {
            handler() {
                this.$emit('getAnalysisHourCurrTabHour', this.currentTabHoursOfDay)
                if (Object.keys(this.postHoursByDay).length) {
                    this.getChartDataHoursOfDay()
                }
            },
        },
        dateRange: {
            handler() {
                this.fetchData()
            },
        },
        currentTabPostCategory: {
            handler() {
                this.dateRange = this.getDateOfMonth(30)
                if (this.currentTabPostCategory === 'reel') {
                    this.currentTabTime = 'reach'
                    this.currentTabHoursOfDay = 'reach'
                    this.arrContent = ['リール投稿の曜日・時間毎の再生数・リーチ数・保存数などを確認することができます。', '再生数やリーチ数などの各指標のエンゲージメントをグラフで時間・曜日別に確認ができるため、投稿に適したタイミングを把握することができます。', '自アカウントのユーザーがより活発になる時間帯を軸に投稿を行うことで、初速のエンゲージメントを高めることができ、人気投稿になる可能性が向上します。']
                } else {
                    this.currentTabTime = 'impressions'
                    this.currentTabHoursOfDay = 'impressions'
                    this.arrContent = ['フィード投稿の曜日・時間毎のインプレッション数・リーチ数・保存数などを確認することができます。', 'インプレッションやリーチ数などの各指標のエンゲージメントをグラフで時間・曜日別に確認ができるため、投稿に適したタイミングを把握することができます。', 'また、自アカウントのユーザーがより活発になる時間帯を軸に投稿を行うことで、初速のエンゲージメントを高めることができ、人気投稿になる可能性が向上します。']
                }
                this.isShowManual = false
                this.isShowManualChart = false
            },
        },
    },
    methods: {
        toogleManual() {
            this.isShowManual = !this.isShowManual
        },
        showPopup() {
            let mainPage = document.getElementById('popup-page')
            mainPage.classList.add('popup-page__active')
            let el = document.getElementById('chart-manual')
            el.classList.remove('hidden')
        },
        hiddenPopup() {
            let mainPage = document.getElementById('popup-page')
            mainPage.classList.remove('popup-page__active')
            let el = document.getElementById('chart-manual')
            el.classList.add('hidden')
        },
        async fetchData() {
            this.loading = true
            const sinceTime = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
            const untilTime = dayjs(this.dateRange[1]).format('YYYY-MM-DD')
            const postCategory = this.currentTabPostCategory
            this.$emit('getAnalysisHourCurrTab', postCategory)
            this.$emit('getAnalysisHourCurrTabHour', this.currentTabHoursOfDay)
            this.$emit('getAnalysisHourCurrTabTime', this.currentTabTime)
            if (Inertia?.page?.url === '/export/report-preview') {
                this.$emit('getPostCategory', postCategory)
            }


            await axios
                .get(route('api.post.analysis.index'), {
                    params: { since: sinceTime, until: untilTime, post_category: postCategory },
                })
                .then((res) => {
                    const data = JSON.parse(JSON.stringify(res?.data))
                    this.postInfo = data?.info
                    if (Inertia?.page?.url === '/export/report-preview') {
                        this.$emit('getPostInfo', data?.info)
                    }
                    this.postHours = data?.hours
                    this.postDays = data?.days
                    this.postHoursByDay = data?.hours_of_day
                    // check tab is normal
                    if (this.currentTabPostCategory == 'normal') {
                        if (Object.keys(this.postHours).length) {
                            this.chartOptionsHours.scales.x.max = this.resizeNumber(
                                Math.ceil(Math.max(...Object.values(this.postHours?.impressions)))
                            )
                            this.chartDataHours.datasets[0].data = Object.values(this.postHours?.impressions)
                        } else {
                            this.chartOptionsHours.scales.x.max = 0
                            this.chartDataHours.datasets[0].data = []
                        }
                        if (Object.keys(this.postDays).length) {
                            this.chartOptionsDays.scales.x.max = this.resizeNumber(
                                Math.ceil(Math.max(...Object.values(this.postDays?.impressions)))
                            )
                            this.chartDataDays.datasets[0].data = Object.values(this.postDays?.impressions)
                        } else {
                            this.chartOptionsDays.scales.x.max = 0
                            this.chartDataDays.datasets[0].data = []
                        }
                    } else {
                        // check tab is reel
                        if (Object.keys(this.postHours).length) {
                            this.chartOptionsHours.scales.x.max = this.resizeNumber(
                                Math.ceil(Math.max(...Object.values(this.postHours?.reach)))
                            )
                            this.chartDataHours.datasets[0].data = Object.values(this.postHours?.reach)
                        } else {
                            this.chartOptionsHours.scales.x.max = 0
                            this.chartDataHours.datasets[0].data = []
                        }
                        if (Object.keys(this.postDays).length) {
                            this.chartOptionsDays.scales.x.max = this.resizeNumber(
                                Math.ceil(Math.max(...Object.values(this.postDays?.reach)))
                            )
                            this.chartDataDays.datasets[0].data = Object.values(this.postDays?.reach)
                        } else {
                            this.chartOptionsDays.scales.x.max = 0
                            this.chartDataDays.datasets[0].data = []
                        }
                    }

                    // bubble chart data
                    if (data?.total_post_of_day?.length) {
                        this.getChartTotalPostOfDay(data?.total_post_of_day)
                    } else {
                        for (let i = 6; i <= 10; i++) {
                            this.bubbleChartData.datasets[i].data = []
                        }
                    }

                    if (data?.total_post_of_hours?.length) {
                        this.getChartTotalPostOfHours(data?.total_post_of_hours)
                    } else {
                        for (let i = 11; i <= 15; i++) {
                            this.bubbleChartData.datasets[i].data = []
                        }
                    }
                    if (Object.keys(this.postHoursByDay).length) {
                        this.getChartDataHoursOfDay()
                    } else {
                        for (let i = 0; i <= 5; i++) {
                            this.bubbleChartData.datasets[i].data = []
                        }
                    }
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                })

            this.currentTabTime = 'impressions'
            this.currentTabHoursOfDay = 'impressions'
        },
        getChartDataHours() {
            const currentTab = this.currentTabTime
            let chartDataHours = []
            let chartDataDays = []
            let chartLabel = '平均インプレッション'
            switch (currentTab) {
                case 'impressions':
                    chartLabel = '平均インプレッション'
                    chartDataHours = this.postHours?.impressions
                    chartDataDays = this.postDays?.impressions
                    break
                case 'comment':
                    chartLabel = '平均コメント'
                    chartDataHours = this.postHours?.comment
                    chartDataDays = this.postDays?.comment
                    break
                case 'like':
                    chartLabel = '平均いいね'
                    chartDataHours = this.postHours?.like
                    chartDataDays = this.postDays?.like
                    break
                case 'reach':
                    chartLabel = '平均リーチ'
                    chartDataHours = this.postHours?.reach
                    chartDataDays = this.postDays?.reach
                    break
                case 'engagement_rate':
                    chartLabel = '平均エンゲージメント率'
                    chartDataHours = this.postHours?.engagement
                    chartDataDays = this.postDays?.engagement
                    break
                case 'engagement':
                    chartLabel = '平均エンゲージメント数'
                    chartDataHours = this.postHours?.engagement
                    chartDataDays = this.postDays?.engagement
                    break
                case 'saved':
                    chartLabel = '平均保存数'
                    chartDataHours = this.postHours?.saved
                    chartDataDays = this.postDays?.saved
                    break
                case 'video_views':
                    chartLabel = '平均動画再生数'
                    chartDataHours = this.postHours?.video_views
                    chartDataDays = this.postDays?.video_views
                    break
            }
            this.chartDataHours.datasets[0].label = chartLabel
            this.chartOptionsHours.scales.x.max = this.resizeNumber(Math.ceil(Math.max(...Object.values(chartDataHours))))
            this.chartDataHours.datasets[0].data = Object.values(chartDataHours)

            this.chartDataDays.datasets[0].label = chartLabel
            this.chartOptionsDays.scales.x.max = this.resizeNumber(Math.ceil(Math.max(...Object.values(chartDataDays))))
            this.chartDataDays.datasets[0].data = Object.values(chartDataDays)
        },
        getChartDataHoursOfDay() {
            const currentTab = this.currentTabHoursOfDay
            let bubbleChartData = []
            let chartLabel = '平均インプレッション'
            switch (currentTab) {
                case 'impressions':
                    chartLabel = '平均インプレッション'
                    bubbleChartData = this.postHoursByDay?.impressions
                    break
                case 'comment':
                    chartLabel = '平均コメント'
                    bubbleChartData = this.postHoursByDay?.comment
                    break
                case 'like':
                    chartLabel = '平均いいね'
                    bubbleChartData = this.postHoursByDay?.like
                    break
                case 'reach':
                    chartLabel = '平均リーチ'
                    bubbleChartData = this.postHoursByDay?.reach
                    break
                case 'engagement_rate':
                    chartLabel = '平均エンゲージメント率'
                    bubbleChartData = this.postHoursByDay?.engagement
                    break
                case 'engagement':
                    chartLabel = '平均エンゲージメント数'
                    bubbleChartData = this.postHoursByDay?.engagement
                    break
                case 'saved':
                    chartLabel = '平均保存数'
                    bubbleChartData = this.postHoursByDay?.saved
                    break
                case 'video_views':
                    chartLabel = '平均動画再生数'
                    bubbleChartData = this.postHoursByDay?.video_views
                    break
            }
            for (let i = 0; i <= 5; i++) {
                this.bubbleChartData.datasets[i].data = []
            }
            const maxValueHours = Math.max(...bubbleChartData.map((v) => v.label))
            const mapUnit = maxValueHours / 5
            bubbleChartData.forEach((element) => {
                const label = element?.label
                let newLabel =
                    Math.abs(label) >= 1000000
                        ? (label / 1000000).toFixed(1) + 'M'
                        : Math.abs(label) >= 1000
                            ? (label / 1000).toFixed(1) + 'K'
                            : label
                newLabel = currentTab === 'engagement_rate' ? newLabel + '%' : newLabel
                let node = { ...element, label: newLabel, r: 11, title_tooltip: label }
                if (label <= mapUnit) {
                    this.bubbleChartData.datasets[0].data.push(node)
                    this.bubbleChartData.datasets[0].label = chartLabel
                } else if (label <= mapUnit * 2) {
                    node.r = 12
                    this.bubbleChartData.datasets[1].data.push(node)
                    this.bubbleChartData.datasets[1].label = chartLabel
                } else if (label <= mapUnit * 3) {
                    node.r = 14
                    this.bubbleChartData.datasets[2].data.push(node)
                    this.bubbleChartData.datasets[2].label = chartLabel
                } else if (label <= mapUnit * 4) {
                    node.r = 16
                    this.bubbleChartData.datasets[3].data.push(node)
                    this.bubbleChartData.datasets[3].label = chartLabel
                } else if (label < mapUnit * 5) {
                    node.r = 18
                    this.bubbleChartData.datasets[4].data.push(node)
                    this.bubbleChartData.datasets[4].label = chartLabel
                } else {
                    node.r = 20
                    this.bubbleChartData.datasets[5].data.push(node)
                    this.bubbleChartData.datasets[5].label = chartLabel
                }
            })
        },
        getChartTotalPostOfDay(data) {
            for (let i = 6; i <= 10; i++) {
                this.bubbleChartData.datasets[i].data = []
            }
            const chartLabel = 'post_of_day'
            const maxValuePosts = Math.max(...data.map((v) => v.label))
            const mapUnit = maxValuePosts / 4
            data.forEach((element) => {
                const label = element?.label
                const newLabel =
                    Math.abs(label) >= 1000000
                        ? (label / 1000000).toFixed(1) + 'M'
                        : Math.abs(label) >= 1000
                            ? (label / 1000).toFixed(1) + 'K'
                            : label
                let node = { ...element, label: newLabel, r: 11, title_tooltip: label }
                if (label <= mapUnit) {
                    this.bubbleChartData.datasets[6].data.push(node)
                    this.bubbleChartData.datasets[6].label = chartLabel
                } else if (label <= mapUnit * 2) {
                    node.r = 12
                    this.bubbleChartData.datasets[7].data.push(node)
                    this.bubbleChartData.datasets[7].label = chartLabel
                } else if (label <= mapUnit * 3) {
                    node.r = 14
                    this.bubbleChartData.datasets[8].data.push(node)
                    this.bubbleChartData.datasets[8].label = chartLabel
                } else if (label < mapUnit * 4) {
                    node.r = 16
                    this.bubbleChartData.datasets[9].data.push(node)
                    this.bubbleChartData.datasets[9].label = chartLabel
                } else {
                    node.r = 18
                    this.bubbleChartData.datasets[10].data.push(node)
                    this.bubbleChartData.datasets[10].label = chartLabel
                }
            })
        },
        getChartTotalPostOfHours(data) {
            for (let i = 11; i <= 15; i++) {
                this.bubbleChartData.datasets[i].data = []
            }
            const chartLabel = 'post_of_hours'
            const maxValuePosts = Math.max(...data.map((v) => v.label))
            const mapUnit = maxValuePosts / 4
            data.forEach((element) => {
                const label = element?.label
                const newLabel =
                    Math.abs(label) >= 1000000
                        ? (label / 1000000).toFixed(1) + 'M'
                        : Math.abs(label) >= 1000
                            ? (label / 1000).toFixed(1) + 'K'
                            : label

                let node = { ...element, label: newLabel, r: 11, title_tooltip: label }
                if (label <= mapUnit) {
                    this.bubbleChartData.datasets[11].data.push(node)
                    this.bubbleChartData.datasets[11].label = chartLabel
                } else if (label <= mapUnit * 2) {
                    node.r = 12
                    this.bubbleChartData.datasets[12].data.push(node)
                    this.bubbleChartData.datasets[12].label = chartLabel
                } else if (label <= mapUnit * 3) {
                    node.r = 14
                    this.bubbleChartData.datasets[13].data.push(node)
                    this.bubbleChartData.datasets[13].label = chartLabel
                } else if (label < mapUnit * 4) {
                    node.r = 16
                    this.bubbleChartData.datasets[14].data.push(node)
                    this.bubbleChartData.datasets[14].label = chartLabel
                } else {
                    node.r = 18
                    this.bubbleChartData.datasets[15].data.push(node)
                    this.bubbleChartData.datasets[15].label = chartLabel
                }
            })
        },
        checkShowBubbleChart() {
            if (
                this.bubbleChartData?.datasets[0]?.data?.length ||
                this.bubbleChartData?.datasets[1]?.data?.length ||
                this.bubbleChartData?.datasets[2]?.data?.length ||
                this.bubbleChartData?.datasets[3]?.data?.length ||
                this.bubbleChartData?.datasets[4]?.data?.length ||
                this.bubbleChartData?.datasets[5]?.data?.length ||
                this.bubbleChartData?.datasets[6]?.data?.length ||
                this.bubbleChartData?.datasets[7]?.data?.length ||
                this.bubbleChartData?.datasets[8]?.data?.length ||
                this.bubbleChartData?.datasets[9]?.data?.length ||
                this.bubbleChartData?.datasets[10]?.data?.length
            ) {
                return true
            }
            return false
        },
        resizeNumber(numberValue) {
            if (!numberValue) return 1

            if (Math.abs(numberValue) > 100) {
                return Math.floor(numberValue / 100 + 1) * 100
            }

            if (Math.abs(numberValue) < 10) {
                return Math.floor(numberValue + 1)
            }

            return Math.floor(numberValue / 10 + 1) * 10
        },
        disabledDate(date) {
            let d = new Date()
            return date > d.setDate(d.getDate() - 1)
        },
        formatNumber(number) {
            if (!number) return 0
            number = parseFloat(number)

            const str = number.toString().split('.')
            str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            return str.join('.')
        },
        onInstaSubmitSuccess() {
            this.showLinkInstagram = true
        },
        // link instagram
        async checkLinkInstagram() {
            const check = await accountAPI.checkInsAccount()
            if (check.data) {
                this.range = this.getDateOfMonth(30)
                this.showLinkInstagram = true
            } else {
                this.showLinkInstagram = false
            }
        },
        getDateOfMonth(day) {
            const date = []
            date[0] = new Date(new Date().setDate(new Date().getDate() - day))
            date[1] = new Date(new Date().setDate(new Date().getDate() - 1))
            return date
        },
        customDatePickerText() {
            let datePicker = document.getElementsByClassName('customDatePickerEngagement')

            let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[0]
            const preYearNode = document.createTextNode("<<前年");
            btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0]);
            btnPreYear.style.cssText += 'margin: 0 5px 0 0';

            let btnPreMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[1]
            const preMonthNode = document.createTextNode("<前月");
            btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0]);
            btnPreMonth.style.cssText += 'margin: 0';

            let btnNextYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[2]
            const nextYearNode = document.createTextNode("翌年>>");
            btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0]);
            btnNextYear.style.cssText += 'margin: 0 0 0 5px';

            let btnNextMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[3]
            const nextMonthNode = document.createTextNode("翌月>");
            btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0]);
            btnNextMonth.style.cssText += 'margin: 0';
        }
    },
}
</script>

<style lang="scss" scoped>
.el-button__primary {
    background-color: #5264cd;
    border: 1px solid #5264cd;
}

.el-button__primary span {
    color: white !important;
}

.export-chart__style {
    min-width: 600px !important;
}

.export-chart-row__style {
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
}

@media (max-width: 640px) {
    .tabs {
        max-width: 100%;
        overflow-x: auto;
    }

    .tabs::-webkit-scrollbar {
        height: 5px;
        background: #fff;
    }

    /* Track */
    .tabs::-webkit-scrollbar-track {
        background: #fff;
    }

    /* Handle */
    .tabs::-webkit-scrollbar-thumb {
        background: #fff;
    }

    /* Handle on hover */
    .tabs:hover::-webkit-scrollbar-thumb {
        background: rgb(102, 98, 98, 0.5);
        border-radius: 5px;
    }
}
</style>
