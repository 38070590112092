<template>
    <DrashBoard>
        <LinkInstagram v-if="!showLinkInstagram" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li class="mr-4">
                    <Link :href="route('admin.post.post-analytics')" class="font-normal breakcumb-active">投稿数の推移分析
                    </Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5" :class="{ active: currentTabPostCategory == 'normal' }"
                        @click="currentTabPostCategory = 'normal'">
                        通常投稿
                    </li>
                    <li v-if="isShowReel" class="px-2 relative pb-5 ml-3"
                        :class="{ active: currentTabPostCategory == 'reel' }" @click="currentTabPostCategory = 'reel'">
                        リール投稿
                    </li>
                </ul>
            </div>
        </template>
        <UserNav v-if="!isExport" />
        <template v-slot:exportTabs>
            <div class="!mt-0 ml-20 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5" :class="{ active: currentTabPostCategory == 'normal' }"
                        @click="currentTabPostCategory = 'normal'">
                        通常投稿
                    </li>
                    <li v-if="isShowReel" class="px-2 relative pb-5 ml-3"
                        :class="{ active: currentTabPostCategory == 'reel' }" @click="currentTabPostCategory = 'reel'">
                        リール投稿
                    </li>
                </ul>
            </div>
        </template>
        <template v-if="!isExport" v-slot:manual>
            <UserManual :isShow="isShowManual" :content="arrContent" @closeManualPopup="toogleManual" />
        </template>
        <div class="bg-background" :class="{ 'px-5 md:px-20 mb-10': isExport, 'pb-20 md:px-10 sm:px-5': !isExport }">
            <div class="flex items-center justify-between w-full">
                <div class="flex justify-between items-center" :class="{ 'w-full': isExport }">
                    <div class="flex items-center md:relative">
                        <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                            <h2 class="text-title text-button_pink font-semibold">
                                投稿数の推移分析
                            </h2>
                            <div class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer" @click="toogleManual">
                                <img :src="suggestIcon" />
                            </div>
                        </div>
                    </div>
                    <div v-if="isExport">
                        <button v-if="this.$page.props?.auth?.user?.roles[0]?.name === 'master'"
                            :disabled="numberExportPostAnalysis <= 0 && isRole !== 'master'" @click="exportPostCsv"
                            class="bg-black ml-3 py-3 px-7 text-white !text-[14px] button-shadow rounded">
                            CSV出力
                        </button>

                        <el-tooltip v-else class="tooltip-customer" effect="dark"
                            :content="'投稿数の推移画面今月後 ' + numberExportPostAnalysis + ' 回CSV出力出来ます。'" placement="top">
                            <button :disabled="numberExportPostAnalysis <= 0 && isRole !== 'master'"
                                @click="exportPostCsv"
                                class="bg-black ml-3 py-3 px-7 text-white !text-[14px] button-shadow rounded">
                                CSV出力
                            </button>
                        </el-tooltip>
                    </div>
                </div>
                <div v-if="!isExport" class="md:flex-1 flex md:justify-end relative post-calendar sm:w-2/3">
                    <el-date-picker v-model="dateRange" :type="'daterange'" size="large" range-separator="-"
                        start-placeholder="Start date" end-placeholder="End date" :disabled-date="disabledDate"
                        popper-class="customDatePickerChangePostData" />
                </div>
            </div>

            <div class="relative bg-white z-10 md:px-5 md:py-5 sm:px-3 sm:py-3 lg:mt-0 sm:mt-5">
                <div v-if="isExport" class="md:flex-1 flex md:justify-end relative post-calendar">
                    <el-date-picker v-model="dateRange" :type="'daterange'" size="large" range-separator="-"
                        start-placeholder="Start date" end-placeholder="End date" :disabled-date="disabledDate"
                        popper-class="customDatePickerChangePostData" />
                </div>
                <el-empty v-if="!isData" description="データはありません" />
                <ChartAnalytic v-if="chartData.labels && chartData.labels.length > 0 && !loading && isData" :width="500"
                    :height="heigthChart" :dataChart="chartData" :max_engagement="max_engagement" :max_post="max_post"
                    :canvasId="canvasId" />
                <div class="" v-else v-loading="loading" />
            </div>

            <div class="bg-white md:px-0 md:py-0 sm:px-3 sm:py-3 mt-5 z-0" v-loading="loading" v-if="isData">
                <table class="table-fixed w-full overflow-auto md:inline-table sm:block">
                    <thead>
                        <tr>
                            <th class="text-[13px] py-4 font-normal px-5 bg-header_table text-white border-r text-center border-2 whitespace-nowrap"
                                style="min-width: 150px">
                                日付
                            </th>
                            <th class="text-[13px] py-4 font-normal px-5 bg-header_table text-white border-r text-center border-2 whitespace-nowrap"
                                v-for="(item, index) in label" :key="index" style="min-width: 150px">
                                {{ item.text }}
                            </th>
                            <th
                                class="text-[13px] py-4 font-normal px-5 bg-header_table text-white border-r text-center border-2 whitespace-nowrap">
                                {{ engagementText }}
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-body_table overflow-y-scroll">
                        <tr v-for="(item, index) in tableData" :key="index">
                            <td class="py-3.5">
                                <p class="text-center text-[13px] text-">
                                    {{ formatTime(item.postCreated) }}
                                </p>
                            </td>
                            <td class="py-3.5" v-for="(label, index) in label" :key="index">
                                <p class="text-center text-[13px]">{{ item[label.key] }}</p>
                            </td>
                            <td class="py-3.5">
                                <p class="text-center text-[13px]">
                                    {{ item.Reach }}{{ currentTabPostCategory == 'reel' ? '' : ' %' }}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="isData != 0">
                    <p class="text-right hover:cursor-pointer text-button_profile font-semibold block m-2 text-2sm"
                        v-if="!isSeemore" @click="hiddenData()">
                        隠れる
                    </p>
                    <p class="text-right hover:cursor-pointer text-button_profile font-semibold block m-2 text-2sm"
                        v-if="isSeemore" @click="getFullData()">
                        全文を見る
                    </p>
                </div>
            </div>
        </div>
    </DrashBoard>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import ChartAnalytic from './ChartAnalytic.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import { Link } from '@inertiajs/inertia-vue3'
import * as postAPI from '@/Api/Posts'
import UserNav from '@/Components/UserNav.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import dayjs from 'dayjs'
import { fetchNumberExport, addNumberExport } from '../../../Report/Mixins/limitExport.js'
import { formatToInteger } from '@/Plugins/numberFormat'
import { Inertia } from '@inertiajs/inertia'
import UserManual from '@/Components/UserManual.vue'

export default {
    props: ['account_id'],
    props: {
        canvasId: { type: String },
        userId: { type: Number },
        plan: { type: Object, require: true },
    },
    emits: ['getAnalyticPostTableData', 'isData', 'getAnalyticPostRange', 'getAnalyticPostCategory'],
    components: {
        DrashBoard,
        UserNav,
        ChartAnalytic,
        BreadCrumb,
        Link,
        LinkInstagram,
        UserManual
    },
    data() {
        const date = new Date()
        const since = new Date(date.getFullYear(), date.getMonth(), 1)
        const until = new Date()
        return {
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowManual: false,
            arrContent: ['指定期間内でのフィード投稿数やリーチ数・エンゲージメント率をグラフで確認することが出来ます。', '各投稿の反応の比較を行うことで、エンゲージメントが向上しやすい投稿の傾向を確認し、コンテンツ制作に活かしてください。'],
            numberExportPostAnalysis: 0,
            loading: false,
            valueDate: '',
            isSeemore: true,
            showLinkInstagram: true,
            label: [
                { text: '投稿数', key: 'sumPost' },
                { text: 'いいね数', key: 'sumLike' },
                { text: 'コメント数', key: 'sumComment' },
                { text: '保存数', key: 'sumSaved' },
                { text: 'リーチ数', key: 'sumReach' },
            ],
            tableData: [],
            isData: true,
            dateRange: [since, until],
            chartData: {
                datasets: [
                    {
                        label: 'いいね数',
                        data: [],
                        type: 'line',
                        backgroundColor: '#C71585',
                        borderColor: '#C71585',
                        lineTension: 0.5,
                        yAxisID: 'B',
                    },
                    {
                        label: 'コメント数',
                        data: [],
                        type: 'line',
                        borderColor: '#000D8D',
                        backgroundColor: '#000D8D',
                        lineTension: 0.5,
                        yAxisID: 'B',
                    },
                    {
                        label: '保存数',
                        data: [],
                        type: 'line',
                        borderColor: '#00a7ff',
                        backgroundColor: '#00a7ff',
                        lineTension: 0.5,
                        yAxisID: 'B',
                    },
                    {
                        label: 'エンゲージメント率',
                        data: [],
                        type: 'line',
                        yAxisID: 'B',
                        borderColor: '#7FFFD4',
                        backgroundColor: '#7FFFD4',
                        lineTension: 0.5,
                        yAxisID: 'B',
                    },
                    {
                        label: 'リーチ数',
                        data: [],
                        type: 'line',
                        borderColor: '#6A5ACD',
                        backgroundColor: '#6A5ACD',
                        lineTension: 0.5,
                        yAxisID: 'B',
                    },
                    {
                        label: '投稿数',
                        data: [],
                        type: 'bar',
                        yAxisID: 'A',
                        backgroundColor: '#F0C478',
                        borderColor: '#F0C478',
                        borderWidth: 4,
                        yAxisID: 'A',
                    },
                ],
                labels: [],
            },
            max_engagement: 0,
            max_post: 0,
            currentTabPostCategory: 'normal',
            engagementText: 'エンゲージメント率',
        }
    },
    computed: {
        heigthChart() {
            let widthScreen = screen.width
            if (widthScreen > 640) {
                return 150
            } else {
                return 1050
            }
        },
        isShowReel() {
            if (this.$page.props?.auth?.user?.roles[0]?.name === 'master') {
                return true
            }
            return this?.$page?.props?.auth?.plan?.reel_analysis == 1
        },
        isRole() {
            return this.$page.props?.auth?.user?.roles[0]?.name
        },
        isExport() {
            return Inertia?.page?.url === '/export/report-preview'
        },
    },
    created() {
        this.checkLinkInstagram()
        this.initDateRange()
        if (this.$page?.props?.auth?.account) {
            this.getData(dayjs(this.dateRange[0]).format('YYYY-M-D'), dayjs(this.dateRange[1]).format('YYYY-M-D'))
        }
        this.$emit('getAnalyticPostRange', this.dateRange)
        this.fetchNumberExportMonth()
    },
    mounted() {
        this.customDatePickerText()
    },
    methods: {
        toogleManual() {
            this.isShowManual = !this.isShowManual
        },
        initDateRange() {
            this.dateRange[0] = new Date(new Date().setDate(new Date().getDate() - 30))
            this.dateRange[1] = new Date(new Date().setDate(new Date().getDate() - 1))
        },
        onInstaSubmitSuccess() {
            this.showLinkInstagram = true
        },
        disabledDate(date) {
            let d = new Date()
            return date > d.setDate(d.getDate() - 1)
        },
        async getData(since, until) {
            const postCategory = this.currentTabPostCategory
            this.$emit('getAnalyticPostCategory', postCategory)
            this.loading = true
            const res = await postAPI.analyticsPost(this.$page?.props?.auth?.account?.business_id, since, until, postCategory)
            this.chartData.labels = res?.data?.data?.postAnalytic.map((postAnalytic) =>
                postAnalytic.postCreated.replaceAll('-', '/')
            )
            let unitNormal = this.onGetAnalyticPostCategory === 'normal' ? '%' : ''
            this.$emit(
                'getAnalyticPostTableData',
                res?.data?.data?.postAnalytic.map((postAnalytic) => {
                    return [
                        postAnalytic.postCreated.slice(0, 4) +
                        '年' +
                        postAnalytic.postCreated.slice(5, 7) +
                        '月' +
                        postAnalytic.postCreated.slice(8, 10) +
                        '日',
                        postAnalytic.sumPost,
                        postAnalytic.sumLike,
                        postAnalytic.sumComment,
                        postAnalytic.sumSaved,
                        postAnalytic.sumReach,
                        this.checkReach(postAnalytic.sumLike, postAnalytic.sumComment, postAnalytic.sumSaved, postAnalytic.sumImpressions) + unitNormal,
                    ]
                })
            )
            this.$emit('isData', res?.data?.data?.postAnalytic.reduce((n, { sumPost }) => n + sumPost, 0) > 0 ? true : false)

            const sumLike = res?.data?.data?.postAnalytic.map((postAnalytic) => postAnalytic.sumLike)
            const sumComment = res?.data?.data?.postAnalytic.map((postAnalytic) => postAnalytic.sumComment)
            const sumSaved = res?.data?.data?.postAnalytic.map((postAnalytic) => postAnalytic.sumSaved)
            const sumReach = res?.data?.data?.postAnalytic.map((postAnalytic) => postAnalytic.sumReach)
            const sumPost = res?.data?.data?.postAnalytic.map((postAnalytic) => postAnalytic.sumPost)
            const arrEngagement = res?.data?.data?.postAnalytic.map((postAnalytic) =>
                this.checkReach(postAnalytic.sumLike, postAnalytic.sumComment, postAnalytic.sumSaved, postAnalytic.sumImpressions)
            )

            this.chartData.datasets[0].data = sumLike
            this.chartData.datasets[1].data = sumComment
            this.chartData.datasets[2].data = sumSaved
            this.chartData.datasets[3].data = arrEngagement
            this.chartData.datasets[4].data = sumReach
            this.chartData.datasets[5].data = sumPost

            this.max_engagement = await this.getMaxLineChart([...sumLike, ...sumComment, ...sumSaved, ...sumReach, ...arrEngagement])
            this.max_post = await this.getMaxLineChart(sumPost)

            await this.checkIsDate(res?.data?.data?.postAnalytic)
            this.hiddenData()
            this.loading = false
        },
        async getFullData() {
            const postCategory = this.currentTabPostCategory
            const res = await postAPI.analyticsPost(
                this.$page.props.auth.account.business_id,
                dayjs(this.dateRange[0]).format('YYYY-M-D'),
                dayjs(this.dateRange[1]).format('YYYY-M-D'),
                postCategory
            )
            this.tableData = res.data.data.postAnalytic.map((postAnalytic) => ({
                postCreated: postAnalytic.postCreated,
                sumPost: this.formatToInteger(postAnalytic.sumPost),
                sumLike: this.formatToInteger(postAnalytic.sumLike),
                sumComment: this.formatToInteger(postAnalytic.sumComment),
                sumSaved: this.formatToInteger(postAnalytic.sumSaved),
                sumReach: this.formatToInteger(postAnalytic.sumReach),
                Reach: this.formatToFloat(
                    this.checkReach(postAnalytic.sumLike, postAnalytic.sumComment, postAnalytic.sumSaved, postAnalytic.sumImpressions)
                ),
            }))
            this.isSeemore = false
        },
        async hiddenData() {
            const postCategory = this.currentTabPostCategory
            const res = await postAPI.analyticsPost(
                this.$page.props.auth.account.business_id,
                dayjs(this.dateRange[0]).format('YYYY-M-D'),
                dayjs(this.dateRange[1]).format('YYYY-M-D'),
                postCategory
            )
            this.tableData = res.data.data.postAnalytic
                .map((postAnalytic) => ({
                    postCreated: postAnalytic.postCreated,
                    sumPost: this.formatToInteger(postAnalytic.sumPost),
                    sumLike: this.formatToInteger(postAnalytic.sumLike),
                    sumComment: this.formatToInteger(postAnalytic.sumComment),
                    sumSaved: this.formatToInteger(postAnalytic.sumSaved),
                    sumReach: this.formatToInteger(postAnalytic.sumReach),
                    Reach: this.formatToFloat(
                        this.checkReach(postAnalytic.sumLike, postAnalytic.sumComment, postAnalytic.sumSaved, postAnalytic.sumImpressions)
                    ),
                }))
                .slice(0, 5)
            this.isSeemore = true
        },
        // get max value in array
        async getMaxLineChart(array) {
            const maxArray = Number(Math.max(...array))

            //resize max
            return Math.round(maxArray / 10 + 1) * 10
        },
        checkIsDate(data) {
            if (data.reduce((n, { sumPost }) => n + sumPost, 0) > 0) {
                this.isData = true
                this.chartData.datasets.forEach(async (rating) => {
                    rating.borderWidth = 3
                    rating.pointRadius = 3
                })
            } else {
                this.isData = false
                this.chartData.datasets.forEach(async (rating) => {
                    rating.borderWidth = 0
                    rating.pointRadius = 0
                })
            }
        },

        formatTime(date) {
            if (!date) {
                return
            }
            let listDate = date.slice(0, 10).split('-')
            let listTime = date.slice(11, 19)
            let dateFormat = listDate[0] + '年' + listDate[1] + '月' + listDate[2] + '日' + ' ' + listTime
            return dateFormat
        },
        formatDate(dateTime) {
            return new Date(dateTime).toISOString().split('T')[0]
        },
        checkReach(sumLike, sumComment, sumSaved, sumImpressions) {
            if (this.currentTabPostCategory == 'reel') {
                return parseInt(sumLike) + parseInt(sumComment) + parseInt(sumSaved)
            }

            if (sumImpressions > 0) {
                const dataRevert = (((parseInt(sumLike) + parseInt(sumComment) + parseInt(sumSaved)) / parseInt(sumImpressions)) * 100).toFixed(2)
                if (dataRevert.split('.')[1] > 0) {
                    return dataRevert
                } else {
                    return dataRevert.split('.')[0]
                }
            }
            return 0
        },
        async fetchData() {
            if (this.$page?.props?.auth?.account) {
                await this.getData(dayjs(this.dateRange[0]).format('YYYY-M-D'), dayjs(this.dateRange[1]).format('YYYY-M-D'))
            }
            this.$emit('getAnalyticPostRange', this.dateRange)
        },
        // link instagram
        async checkLinkInstagram() {
            const check = await accountAPI.checkInsAccount()
            if (check.data) {
                this.range = this.getDateOfMonth(30)
                this.showLinkInstagram = true
            } else {
                this.showLinkInstagram = false
            }
        },
        getDateOfMonth(day) {
            const date = []
            date[0] = new Date(new Date().setDate(new Date().getDate() - day))
            date[1] = new Date(new Date().setDate(new Date().getDate() - 1))
            return date
        },
        //export post csv
        exportPostCsv() {
            if (this.numberExportPostAnalysis === 0) {
                return
            }
            const userId = this.userId ? this.userId : this.$page?.props?.auth?.user?.id
            const start_time = dayjs(this.dateRange['0']).format('YYYY-MM-DD')
            const end_time = dayjs(this.dateRange['1']).format('YYYY-MM-DD')

            const url =
                route('api.export.post.csv') +
                '?user_id=' +
                userId +
                '&start_time=' +
                start_time +
                '&end_time=' +
                end_time +
                '&category=' +
                this.currentTabPostCategory
            window.location.href = url

            if (this.isRole !== 'master') {
                addNumberExport('csv', 'csv_post_analysis')
                this.fetchNumberExportMonth()
            }
        },
        async fetchNumberExportMonth() {
            const { data } = await fetchNumberExport('csv', 'csv_post_analysis')
            this.numberExportPostAnalysis = this.plan?.number_report_csv - data
        },
        customDatePickerText() {
            let datePicker = document.getElementsByClassName('customDatePickerChangePostData')

            let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[0]
            const preYearNode = document.createTextNode('<<前年')
            btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0])
            btnPreYear.style.cssText += 'margin: 0 5px 0 0'

            let btnPreMonth =
                datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[1]
            const preMonthNode = document.createTextNode('<前月')
            btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0])
            btnPreMonth.style.cssText += 'margin: 0'

            let btnNextYear =
                datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[2]
            const nextYearNode = document.createTextNode('翌年>>')
            btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0])
            btnNextYear.style.cssText += 'margin: 0 0 0 5px'

            let btnNextMonth =
                datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[3]
            const nextMonthNode = document.createTextNode('翌月>')
            btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0])
            btnNextMonth.style.cssText += 'margin: 0'
        },
    },
    watch: {
        dateRange: function () {
            this.fetchData()
        },
        currentTabPostCategory: {
            handler() {
                this.initDateRange()
                this.engagementText = this.currentTabPostCategory === 'reel' ? 'エンゲージメント数' : 'エンゲージメント率'
                this.arrContent = this.currentTabPostCategory === 'reel' ? ['指定期間内でのリール投稿数やリーチ数・エンゲージメント数をグラフで確認することが出来ます。', '各投稿の反応の比較を行うことで、エンゲージメントが向上しやすい投稿の傾向を確認し、コンテンツ制作に活かしてください。'] : ['指定期間内でのフィード投稿数やリーチ数・エンゲージメント率をグラフで確認することが出来ます。', '各投稿の反応の比較を行うことで、エンゲージメントが向上しやすい投稿の傾向を確認し、コンテンツ制作に活かしてください。']
                this.chartData.datasets[3].label = this.engagementText
                this.isShowManual = false
                this.fetchData()
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.title-no-data {
    position: absolute;
    top: 45%;
    font-size: 24px;
    color: #7a838c;
    z-index: 999;
    text-align: center;
    width: 100%;
    font-style: italic;
}

.bg-body_table {
    background-color: #ffffff;
}

table tr:hover {
    background-color: #f5f7fa;
}

table,
td,
th {
    border: 1px solid #ebeef5;
}
</style>
