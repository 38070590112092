export default {
  methods: {
    checkFileExport(dataType, pptxType) {
      if (dataType === 'single') {
        return true
      }
      return false
    },
    genAccSlideInsights(pptx, dataSlideAccount, sumDay, dataType, type, text, title, pptxType) {
      let unit = type === '合計' ? '件' : ' '
      let acc = pptxType == 1 || pptxType == 2 ? dataSlideAccount.masterDataAccount : dataSlideAccount.masterDataGroup
      let check = this.checkFileExport(dataType, pptxType)

      let dataCurr = acc.sumData.dataSeletedMonth
      let dataLastMonth = acc.sumData.dataLastMonth
      let sumPostCurr = dataCurr.postAccountDetail.sumPostAccount
      let sumPostLast = dataLastMonth.postAccountDetail.sumPostAccount
      let accSlideInsights = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      accSlideInsights.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      accSlideInsights.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 8.01,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 22,
      })
      accSlideInsights.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.45,
        y: 1.05,
        w: 2.75,
        h: 4.85,
        rectRadius: 0.1,
        fill: { color: 'f8f8f8' },
      })
      accSlideInsights.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 3.37,
        y: 1.05,
        w: 1.48,
        h: 4.85,
        rectRadius: 0.1,
        fill: { color: 'f8f8f8' },
      })
      accSlideInsights.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 5.03,
        y: 1.05,
        w: 1.48,
        h: 4.85,
        rectRadius: 0.1,
        fill: { color: 'f8f8f8' },
      })
      accSlideInsights.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 6.69,
        y: 1.05,
        w: 2.23,
        h: 4.85,
        rectRadius: 0.1,
        fill: { color: 'f8f8f8' },
      })
      accSlideInsights.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 9.09,
        y: 1.05,
        w: 1.48,
        h: 4.85,
        rectRadius: 0.1,
        fill: { color: 'f8f8f8' },
      })
      //フィード投稿リーチ数
      accSlideInsights.addText('フィード投稿リーチ数', {
        x: 0.44,
        y: 1.14,
        w: 1.58,
        h: 0.26,
        align: 'left',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 10,
      })
      accSlideInsights.addText('フィード投稿リーチ数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 0.61,
        y: 1.49,
        w: 2.41,
        h: 0.47,
        bold: true,
        align: 'center',
        rectRadius: 0.1,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 14,
      })
      let subReach =
        dataType === 'all'
          ? dataCurr.postAccountDetail.sumReach / sumPostCurr - dataLastMonth.postAccountDetail.sumReach / sumPostLast
          : dataCurr.postAccountDetail.sumReach - dataLastMonth.postAccountDetail.sumReach

      let checkSubReach = Number.isInteger(subReach) == true ? subReach : subReach.toFixed(2)

      let sumReachSeletedMonth =
        dataType === 'all' ? dataCurr.postAccountDetail.sumReach / sumPostCurr : dataCurr.postAccountDetail.sumReach
      let checkSumReachLastMonth =
        Number.isInteger(sumReachSeletedMonth) == true ? sumReachSeletedMonth : sumReachSeletedMonth.toFixed(2)

      accSlideInsights.addText('フィード投稿リーチ数 ' + '\n' + this.checkNaNNumber(checkSumReachLastMonth), {
        x: 0.41,
        y: 2.02,
        w: 1.55,
        h: 0.4,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText('(' + this.checkChangeValue(checkSubReach) + ')', {
        x: 0.41,
        y: 2.39,
        w: 1.55,
        h: 0.4,
        align: 'center',
        valign: 'top',
        color: checkSubReach >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 9,
      })

      //合計エンゲージメント数
      accSlideInsights.addText(type + 'エンゲージメント数', {
        x: 3.41,
        y: 1.14,
        w: 1.44,
        h: 0.41,
        align: 'left',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 10,
      })
      accSlideInsights.addText('いいね', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 3.64,
        y: 1.92,
        w: 1.03,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 10,
      })
      let subLike =
        dataType === 'all'
          ? (
              dataCurr.postAccountDetail.sumLike / sumPostCurr -
              dataLastMonth.postAccountDetail.sumLike / sumPostLast
            ).toFixed(2)
          : dataCurr.postAccountDetail.sumLike - dataLastMonth.postAccountDetail.sumLike
      let sumLike =
        dataType === 'all'
          ? (dataCurr.postAccountDetail.sumLike / sumPostCurr).toFixed(2)
          : dataCurr.postAccountDetail.sumLike
      accSlideInsights.addText('いいね数', {
        x: 3.37,
        y: 2.16,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText(this.checkNaNNumber(sumLike) + unit, {
        x: 3.37,
        y: 2.32,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText('(' + String(this.checkChangeValue(subLike)) + ')', {
        x: 3.37,
        y: 2.44,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: subLike >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 9,
      })
      // tỉ lệ like
      dataType === 'all'
      let like_rate_current = (dataCurr.postAccountDetail.sumLike / sumPostCurr) * 100

      let check_like_rate_current = 0
      if (!isNaN(like_rate_current)) {
        check_like_rate_current =
          Number.isInteger(like_rate_current) == true ? like_rate_current : like_rate_current.toFixed(2)
      }

      let like_rate_last_month = ((dataLastMonth.postAccountDetail.sumLike / sumPostLast) * 100).toFixed(2)

      let subRateLike = check_like_rate_current - like_rate_last_month
      let checkSubRateLike = 0
      if (!isNaN(subRateLike)) {
        checkSubRateLike = Number.isInteger(subRateLike) == true ? subRateLike : subRateLike.toFixed(2)
      }

      accSlideInsights.addText('いいね率', {
        x: 3.37,
        y: 2.61,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText(String(check_like_rate_current) + '％', {
        x: 3.37,
        y: 2.73,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText('(' + String(this.checkChangeValue(checkSubRateLike)) + ')', {
        x: 3.37,
        y: 2.85,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: checkSubRateLike >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 9,
      })
      accSlideInsights.addText('コメント', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 3.64,
        y: 3.1,
        w: 1.03,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.1,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 10,
      })

      let sumComment =
        dataType === 'all'
          ? (dataCurr.postAccountDetail.sumComment / sumPostCurr).toFixed(2)
          : dataCurr.postAccountDetail.sumComment
      let subComment =
        dataType === 'all'
          ? (
              dataCurr.postAccountDetail.sumComment / sumPostCurr -
              dataLastMonth.postAccountDetail.sumComment / sumPostCurr
            ).toFixed(2)
          : dataCurr.postAccountDetail.sumComment - dataLastMonth.postAccountDetail.sumComment
      accSlideInsights.addText('コメント数', {
        x: 3.37,
        y: 3.4,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText(this.checkNaNNumber(sumComment) + unit, {
        x: 3.37,
        y: 3.52,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText('(' + String(this.checkChangeValue(subComment)) + ')', {
        x: 3.37,
        y: 3.68,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: subComment >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 9,
      })

      // tỉ lệ comment
      let comment_rate_current =
        (dataCurr.postAccountDetail.sumComment / dataCurr.postAccountDetail.sumPostAccount) * 100
      let check_comment_rate_current = 0
      if (!isNaN(comment_rate_current)) {
        check_comment_rate_current =
          Number.isInteger(comment_rate_current) == true ? comment_rate_current : comment_rate_current.toFixed(2)
      }

      let comment_rate_last_month = (
        (dataLastMonth.postAccountDetail.sumComment / dataLastMonth.postAccountDetail.sumPostAccount) *
        100
      ).toFixed(2)

      let subRateComent = comment_rate_current - comment_rate_last_month
      let checkSubRateComent = 0
      if (!isNaN(subRateComent)) {
        checkSubRateComent = Number.isInteger(subRateComent) == true ? subRateComent : subRateComent.toFixed(2)
      }

      accSlideInsights.addText('コメント率', {
        x: 3.37,
        y: 3.83,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText(String(check_comment_rate_current) + '％', {
        x: 3.37,
        y: 3.98,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText('(' + String(this.checkChangeValue(checkSubRateComent)) + ')', {
        x: 3.37,
        y: 4.11,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: checkSubRateComent >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 9,
      })
      accSlideInsights.addText('保存', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 3.64,
        y: 4.49,
        w: 1.03,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.1,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 10,
      })

      let sumSaved =
        dataType === 'all'
          ? (dataCurr.postAccountDetail.sumSaved / sumPostCurr).toFixed(2)
          : dataCurr.postAccountDetail.sumSaved
      let subSaved =
        dataType === 'all'
          ? (
              dataCurr.postAccountDetail.sumSaved / sumPostCurr -
              dataLastMonth.postAccountDetail.sumSaved / sumPostCurr
            ).toFixed(2)
          : dataCurr.postAccountDetail.sumSaved - dataLastMonth.postAccountDetail.sumSaved
      accSlideInsights.addText('保存数', {
        x: 3.37,
        y: 4.78,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText(this.checkNaNNumber(sumSaved) + unit, {
        x: 3.37,
        y: 4.9,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText('(' + String(this.checkChangeValue(subSaved)) + ')', {
        x: 3.37,
        y: 5.06,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: subSaved >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 9,
      })

      // tỉ lệ saved
      let saved_rate_current = (dataCurr.postAccountDetail.sumSaved / dataCurr.postAccountDetail.sumPostAccount) * 100
      let check_saved_rate_current = 0
      if (!isNaN(saved_rate_current)) {
        check_saved_rate_current =
          Number.isInteger(saved_rate_current) == true ? saved_rate_current : saved_rate_current.toFixed(2)
      }

      let saved_rate_last_month = (
        (dataLastMonth.postAccountDetail.sumSaved / dataLastMonth.postAccountDetail.sumPostAccount) *
        100
      ).toFixed(2)

      let subRateSaved = saved_rate_current - saved_rate_last_month
      let checkSubRateSaved = 0
      if (!isNaN(subRateSaved)) {
        checkSubRateSaved = Number.isInteger(subRateSaved) == true ? subRateSaved : subRateSaved.toFixed(2)
      }

      accSlideInsights.addText('保存率', {
        x: 3.37,
        y: 5.21,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText(String(check_saved_rate_current) + '％', {
        x: 3.37,
        y: 5.36,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText('(' + String(this.checkChangeValue(checkSubRateSaved)) + ')', {
        x: 3.37,
        y: 5.49,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: checkSubRateSaved >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 9,
      })

      //プロフィールアクセス
      accSlideInsights.addText('プロフィールアクセス', {
        x: 5.03,
        y: 1.14,
        w: 1.44,
        h: 0.41,
        align: 'left',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 10,
      })
      accSlideInsights.addText('アカウント\nリーチ', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 5.27,
        y: 1.58,
        w: 1.03,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 8,
      })
      // account profile reach
      let sumReachSeletedMonthSlideAccount =
        dataType === 'all'
          ? dataCurr.accountDetail.sumRecord == 0
            ? 0
            : (dataCurr.accountDetail.sumReach / dataCurr.accountDetail.sumRecord).toFixed(2)
          : dataCurr.accountDetail.sumReach
      let subReachProfile =
        dataType === 'all'
          ? this.checkSubReachRate(dataCurr, dataLastMonth)
          : dataCurr.accountDetail.sumReach - dataLastMonth.accountDetail.sumReach

      accSlideInsights.addText(
        'プロフィール\nリーチ数' + '\n' + this.checkNaNNumber(sumReachSeletedMonthSlideAccount) + unit,
        {
          x: 5.03,
          y: 2.02,
          w: 1.48,
          h: 0.55,
          align: 'center',
          valign: 'top',
          color: '303030',
          fontSize: 9,
        }
      )
      accSlideInsights.addText('( ' + String(this.checkChangeValue(subReachProfile)) + ')', {
        x: 5.03,
        y: 2.45,
        w: 1.48,
        h: 0.25,
        align: 'center',
        valign: 'top',
        color: subReachProfile >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 9,
      })

      // tỉ lệ account reach/ sumDay
      let reach_rate_current = 0
      let reach_rate_last_month = 0

      if (dataCurr.accountDetail.sumReach == 0 || dataCurr.accountDetail.sumRecord == 0) {
        reach_rate_current = 0
      } else {
        reach_rate_current = ((dataCurr.accountDetail.sumReach / dataCurr.accountDetail.sumRecord) * 100).toFixed(2)
      }

      if (dataLastMonth.accountDetail.sumReach == 0 || dataCurr.accountDetail.sumRecord == 0) {
        reach_rate_last_month = 0
      } else {
        reach_rate_last_month = (
          (dataLastMonth.accountDetail.sumReach / dataLastMonth.accountDetail.sumRecord) *
          100
        ).toFixed(2)
      }
      let subReachRate = reach_rate_current - reach_rate_last_month
      accSlideInsights.addText('プロフィール\nリーチ率' + '\n' + this.checkNaNNumber(reach_rate_current) + '%', {
        x: 5.03,
        y: 2.61,
        w: 1.48,
        h: 0.55,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText('( ' + String(this.checkChangeValue(subReachRate.toFixed(2))) + '）', {
        x: 5.03,
        y: 3.04,
        w: 1.48,
        h: 0.25,
        align: 'center',
        valign: 'top',
        color: subReachRate >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 9,
      })
      accSlideInsights.addText('Webサイトのクリック', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 5.31,
        y: 4.09,
        w: 1.03,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 8,
      })
      // số lượt click vào web
      let sumWebsiteClick =
        dataType === 'all'
          ? dataCurr.accountDetail.sumRecord == 0
            ? 0
            : (dataCurr.accountDetail.sumWebsiteClick / dataCurr.accountDetail.sumRecord).toFixed(2)
          : dataCurr.accountDetail.sumWebsiteClick
      let subClickWeb =
        dataType === 'all'
          ? this.checkSubClickWeb(dataCurr, dataLastMonth)
          : dataCurr.accountDetail.sumWebsiteClick - dataLastMonth.accountDetail.sumWebsiteClick
      accSlideInsights.addText('Webサイトクリック数', {
        x: 5.03,
        y: 4.39,
        w: 1.48,
        h: 0.55,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText(this.checkNaNNumber(sumWebsiteClick) + unit, {
        x: 5.03,
        y: 4.71,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText('(' + String(this.checkChangeValue(subClickWeb)) + ')', {
        x: 5.03,
        y: 4.87,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: subClickWeb >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 9,
      })
      // tỉ lệ click web

      let handle_clickWeb_rate_current = (
        (dataCurr.accountDetail.sumWebsiteClick * 100) /
        dataCurr.accountDetail.sumProfileView
      ).toFixed(2)
      let clickWeb_rate_current = 0
      if (!isNaN(handle_clickWeb_rate_current)) {
        clickWeb_rate_current = handle_clickWeb_rate_current
      }

      let clickWeb_rate_last_month = (
        (dataLastMonth.accountDetail.sumWebsiteClick * 100) /
        dataLastMonth.accountDetail.sumProfileView
      ).toFixed(2)

      let subRateClickWeb = clickWeb_rate_current - clickWeb_rate_last_month
      let checkSubRateClickWeb = 0
      if (!isNaN(subRateClickWeb)) {
        checkSubRateClickWeb = Number.isInteger(subRateClickWeb) == true ? subRateClickWeb : subRateClickWeb.toFixed(2)
      }

      accSlideInsights.addText('Webサイトクリック率', {
        x: 5.03,
        y: 5.02,
        w: 1.48,
        h: 0.55,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText(clickWeb_rate_current + '％', {
        x: 5.03,
        y: 5.34,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: '303030',
        fontSize: 9,
      })
      accSlideInsights.addText('(' + String(this.checkChangeValue(checkSubRateClickWeb)) + ')', {
        x: 5.03,
        y: 5.5,
        w: 1.48,
        h: 0.18,
        align: 'center',
        valign: 'top',
        color: checkSubRateClickWeb >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 9,
      })

      //フォロワーの獲得
      accSlideInsights.addText('フォロワーの獲得', {
        x: 6.72,
        y: 1.14,
        w: 1.58,
        h: 0.41,
        align: 'left',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 10,
      })
      accSlideInsights.addText('フォロワー\n増加数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 6.87,
        y: 2.03,
        w: 0.85,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 8,
      })
      accSlideInsights.addText('フォロワー\n減少数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 7.9,
        y: 2.03,
        w: 0.85,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 8,
      })
      accSlideInsights.addText('フォロワー純増減数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 6.87,
        y: 3.4,
        w: 1.88,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 8,
      })
      // tăng giảm follow  ( lấy follow tháng sau trừ follow tháng trước)
      let subFollow =
        acc.sumData.dataSeletedMonth.accountDetail.followerOfLastDay -
        acc.sumData.dataLastMonth.accountDetail.followerOfLastDay
      let dataSeletedMonthAccountDetailSumFollower = acc.sumData.dataSeletedMonth.accountDetail.followerOfLastDay
      let numAcc = acc.sumData.dataSeletedMonth.accountDetail.numberAccount

      accSlideInsights.addText(this.checkNaNNumber(dataSeletedMonthAccountDetailSumFollower), {
        x: 6.76,
        y: 3.71,
        w: 2.04,
        h: 0.23,
        align: 'center',
        valign: 'middle',
        bold: true,
        color: '303030',
        fontSize: 11.3,
      })
      accSlideInsights.addText('(' + String(this.checkChangeValue(subFollow)) + ')', {
        x: 6.76,
        y: 3.98,
        w: 2.04,
        h: 0.23,
        align: 'center',
        valign: 'middle',
        color: subFollow >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 7.5,
      })
      accSlideInsights.addText('合計フォロワー数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 6.87,
        y: 4.34,
        w: 1.88,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 8,
      })

      accSlideInsights.addText(this.checkNaNNumber(dataSeletedMonthAccountDetailSumFollower), {
        x: 6.76,
        y: 4.79,
        w: 2.04,
        h: 0.23,
        align: 'center',
        valign: 'middle',
        bold: true,
        color: '303030',
        fontSize: 24,
      })
      accSlideInsights.addText('※' + String(acc.sumData.dataSeletedMonth.accountDetail.lastDayRecord) + ' 現在', {
        x: 6.76,
        y: 5.09,
        w: 2.04,
        h: 0.23,
        align: 'center',
        valign: 'middle',
        bold: true,
        color: '303030',
        fontSize: 8,
      })
      //ストーリーズ投稿リーチ
      accSlideInsights.addText('ストーリーズ投稿リーチ', {
        x: 9.1,
        y: 1.14,
        w: 1.44,
        h: 0.41,
        align: 'left',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 10,
      })
      accSlideInsights.addText('ストーリーズ\nリーチ数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 9.24,
        y: 1.59,
        w: 1.17,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 8,
      })
      // tổng số reach của stories
      let dataSlideAccountSumReach = dataCurr.storyAccountDetail.sumReach

      accSlideInsights.addText(
        String(
          this.checkNaNNumber(
            check
              ? dataSlideAccountSumReach
              : (dataSlideAccountSumReach / dataCurr.storyAccountDetail.countStoryAccount).toFixed(2)
          )
        ),
        {
          x: 9.01,
          y: 1.97,
          w: 1.69,
          h: 0.26,
          align: 'center',
          valign: 'middle',
          bold: true,
          color: '303030',
          fontSize: 11.3,
        }
      )
      let subReachStory = dataCurr.storyAccountDetail.sumReach - dataLastMonth.storyAccountDetail.sumReach

      accSlideInsights.addText(
        '(' + String(this.checkChangeValue(check ? subReachStory : (subReachStory / numAcc).toFixed(2))) + ')',
        {
          x: 9.08,
          y: 2.23,
          w: 1.55,
          h: 0.13,
          align: 'center',
          valign: 'middle',
          color: subReachStory >= 0 ? '1f79f2' : 'DC3545',
          fontSize: 7.5,
        }
      )
      accSlideInsights.addText('ストーリーズ\n投稿数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 9.24,
        y: 3.39,
        w: 1.17,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 8,
      })
      // tổng số lần đăng story
      let countStoryAccount = dataCurr.storyAccountDetail.countStoryAccount

      accSlideInsights.addText(this.checkNaNNumber(countStoryAccount), {
        x: 8.92,
        y: 3.69,
        w: 1.85,
        h: 0.26,
        align: 'center',
        valign: 'middle',
        bold: true,
        color: '303030',
        fontSize: 11.3,
      })
      // tỉ lệ so với tháng trước
      let ratCounrStory =
        dataCurr.storyAccountDetail.countStoryAccount - dataLastMonth.storyAccountDetail.countStoryAccount
      accSlideInsights.addText('(' + String(this.checkChangeValue(ratCounrStory)) + ')', {
        x: 9.07,
        y: 3.95,
        w: 1.55,
        h: 0.13,
        align: 'center',
        valign: 'middle',
        color: ratCounrStory >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 7.5,
      })

      //line arrow
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 3.02,
        y: 1.72,
        w: 2.25,
        h: 0.0,
        line: { width: 0.5, endArrowType: 'triangle', color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 5.82,
        y: 3.34,
        w: 0.0,
        h: 0.74,
        line: { width: 0.5, endArrowType: 'triangle', color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 7.82,
        y: 2.6,
        w: 0.0,
        h: 0.79,
        line: { width: 0.5, endArrowType: 'triangle', color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 6.3,
        y: 1.73,
        w: 2.94,
        h: 0.0,
        line: { width: 0.5, beginArrowType: 'triangle', color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 1.8,
        y: 1.96,
        w: 0.0,
        h: 2.65,
        line: { width: 0.5, color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 1.81,
        y: 2.06,
        w: 1.84,
        h: 0.0,
        line: { width: 0.5, color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 1.8,
        y: 3.23,
        w: 1.84,
        h: 0.0,
        line: { width: 0.5, color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 1.8,
        y: 4.61,
        w: 1.84,
        h: 0.0,
        line: { width: 0.5, color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 7.31,
        y: 2.6,
        w: 1.0,
        h: 0.0,
        line: { width: 0.5, color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 7.3,
        y: 2.33,
        w: 0.0,
        h: 0.27,
        line: { width: 0.5, color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 8.3,
        y: 2.33,
        w: 0.0,
        h: 0.27,
        line: { width: 0.5, color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 6.6,
        y: 1.73,
        w: 0.0,
        h: 0.45,
        line: { width: 0.5, color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 6.59,
        y: 2.18,
        w: 0.28,
        h: 0.0,
        line: { width: 0.5, endArrowType: 'triangle', color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 9.0,
        y: 1.8,
        w: 0.0,
        h: 1.76,
        line: { width: 0.5, color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 9.0,
        y: 1.8,
        w: 0.24,
        h: 0,
        line: { width: 0.5, color: '595959' },
      })
      accSlideInsights.addShape(pptx.shapes.LINE, {
        x: 9.0,
        y: 3.56,
        w: 0.24,
        h: 0.0,
        line: { width: 0.5, endArrowType: 'triangle', color: '595959' },
      })

      accSlideInsights.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.29,
        w: 10.14,
        h: 1.56,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return accSlideInsights
    },
    checkChangeValue(val) {
      if (val > 0) {
        return '+' + val
      } else if (val < 0) {
        return '-' + Math.abs(val)
      } else if (isNaN(val)) {
        return '0'
      } else {
        return val
      }
    },
    checkNaNNumber(val) {
      if (isNaN(val)) {
        return 0
      } else {
        return String(val)
      }
    },
    checkSubClickWeb(dataCurr, dataLastMonth) {
      if (dataCurr.accountDetail.sumRecord == 0) {
        return (0 - dataLastMonth.accountDetail.sumWebsiteClick / dataLastMonth.accountDetail.sumRecord).toFixed(2)
      } else if (dataLastMonth.accountDetail.sumRecord == 0) {
        return (dataCurr.accountDetail.sumWebsiteClick / dataCurr.accountDetail.sumRecord).toFixed(2)
      } else {
        return (
          dataCurr.accountDetail.sumWebsiteClick / dataCurr.accountDetail.sumRecord -
          dataLastMonth.accountDetail.sumWebsiteClick / dataLastMonth.accountDetail.sumRecord
        ).toFixed(2)
      }
    },
    checkSubReachRate(dataCurr, dataLastMonth) {
      if (dataCurr.accountDetail.sumRecord == 0) {
        if (dataLastMonth.accountDetail.sumReach) {
          return 0
        }
        return (0 - dataLastMonth.accountDetail.sumReach / dataLastMonth.accountDetail.sumRecord).toFixed(2)
      } else if (dataLastMonth.accountDetail.sumRecord == 0) {
        if (dataCurr.accountDetail.sumReach == 0) {
          return 0
        }
        return (dataCurr.accountDetail.sumReach / dataCurr.accountDetail.sumRecord).toFixed(2)
      } else {
        return (
          dataCurr.accountDetail.sumReach / dataCurr.accountDetail.sumRecord -
          dataLastMonth.accountDetail.sumReach / dataLastMonth.accountDetail.sumRecord
        ).toFixed(2)
      }
    },
  },
}
