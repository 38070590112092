<template>
  <data-table
    :fields="fields"
    :items="hashtags.data"
    :paginate="hashtags.meta"
    :current-page="filter.page || 1"
    @page="handleCurrentPage"
    disable-table-info
    footer-center
    paginate-background
    class="el-table-header__black mt-7"
    v-loading="loading"
  >
    <template #name="{ row }">
      <p class="text-center">
        <a
          :href="`${URL_INSTAGRAM}/${row?.name?.replace('#', '')}`"
          class="text-button_pink underline font-medium text-[13px] cursor-pointer"
          target="_blank"
        >
          {{ row?.name }}
        </a>
      </p>
    </template>
    <template #hashtag_using="{ row }">
      <!-- <p>{{ sumPostUsingHashtag(row) }}</p> -->
      <p>{{ sumPostForHashtag(row) }}</p>
    </template>
    <template #hashtag_likes="{ row }">
      <!-- <p>{{ calculateAverageLike(row) }}</p> -->
      <p>
        {{
          sumLikePostForHashtag(row) == 0
            ? sumLikePostForHashtag(row)
            : convertNumberFloat(sumLikePostForHashtag(row) / sumPostForHashtag(row))
        }}
      </p>
    </template>
    <template #hashtag_comments="{ row }">
      <!-- <p>{{ calculateAverageComment(row) }}</p> -->
      <p>
        {{
          sumCommentPostForHashtag(row) == 0
            ? sumCommentPostForHashtag(row)
            : convertNumberFloat(sumCommentPostForHashtag(row) / sumPostForHashtag(row))
        }}
      </p>
    </template>
    <template #saved="{ row }">
      <p>
        {{
          sumSavedPostForHashtag(row) == 0
            ? sumSavedPostForHashtag(row)
            : convertNumberFloat(sumSavedPostForHashtag(row) / sumPostForHashtag(row))
        }}
      </p>
    </template>
    <template #rate="{ row }">
      <p>
        {{
          (
            (sumLikePostForHashtag(row) == 0
              ? sumLikePostForHashtag(row)
              : convertNumberFloat(sumLikePostForHashtag(row) / sumPostForHashtag(row))) +
            (sumCommentPostForHashtag(row) == 0
              ? sumCommentPostForHashtag(row)
              : convertNumberFloat(sumCommentPostForHashtag(row) / sumPostForHashtag(row)))
          ).toFixed(2)
        }}
      </p>
    </template>
    <template #hashtag_reachs="{ row }">
      <p>
        {{
          sumReachPostForHashtag(row) == 0
            ? sumReachPostForHashtag(row)
            : convertNumberFloat(sumReachPostForHashtag(row) / sumPostForHashtag(row))
        }}
      </p>
    </template>
    <template #hashtag_impressions="{ row }">
      <p>
        {{
          sumImperssionPostForHashtag(row) == 0
            ? sumImperssionPostForHashtag(row)
            : convertNumberFloat(sumImperssionPostForHashtag(row) / sumPostForHashtag(row))
        }}
      </p>
    </template>
    <template #engagement_rate="{ row }">
      <p v-if="sumImperssionPostForHashtag(row) == 0">0%</p>
      <p v-else>
        {{
          (sumLikePostForHashtag(row) + sumCommentPostForHashtag(row) + sumSavedPostForHashtag(row)== 0
            ? 0
            : convertNumberFloat(
                (sumLikePostForHashtag(row) + sumCommentPostForHashtag(row) + sumSavedPostForHashtag(row)) /
                  sumImperssionPostForHashtag(row)
              ) * 100
          ).toFixed(2) + '%'
        }}
      </p>
    </template>
  </data-table>
</template>

<script>
import Table from '@/Components/TableSummaryAccount_2.vue'
import DataTable from '@/Components/UI/DataTable.vue'
import dayjs from 'dayjs'

export default {
  components: { Table, DataTable },
  props: {
    range: { type: Object, default: 0 },
    year: { type: Number, default: 0 },
    analyticType: { type: String, default: undefined },
    group: { type: Object },
  },
  data() {
    return {
      URL_INSTAGRAM: 'https://www.instagram.com/explore/tags',
      hashtags: [],
      hashtags_count: 10,
      loading: false,
      filter: {
        page: 1,
        limit: 10,
      },

      fields: [
        {
          key: 'name',
          label: 'ハッシュタグ',
          width: 200,
          fixed: 'left',
          align: 'left',
          lableClassName: 'text-center',
        },
        {
          key: 'hashtag_using',
          label: '使用投稿数',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
          align: 'center',
        },
        {
          key: 'hashtag_likes',
          label: '平均いいね数',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
          align: 'center',
        },
        {
          key: 'hashtag_comments',
          label: '平均コメント数',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
          align: 'center',
        },
        { key: 'saved', label: '平均保存数', width: screen.width / 8 >= 240 ? screen.width / 8 : 200, align: 'center' },
        {
          key: 'rate',
          label: '平均エンゲージメント数',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
          align: 'center',
        },
        {
          key: 'hashtag_reachs',
          label: '平均リーチ数',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
          align: 'center',
        },
        {
          key: 'hashtag_impressions',
          label: '平均インプレッション数',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
          align: 'center',
        },
        {
          key: 'engagement_rate',
          label: '平均エンゲージメント率',
          width: screen.width / 8 >= 240 ? screen.width / 8 : 200,
          align: 'center',
        },
      ],
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    range: function () {
      this.fetchData()
    },
    year: function () {
      this.fetchData()
    },
  },
  methods: {
    handleCurrentPage(value) {
      this.loading = true
      this.filter.page = value
      this.fetchData()
    },

    // fetch data
    async fetchData() {
      if (!this.range.length) return
      this.loading = true
      let since = this.range[0]
      let until = this.range[1]
      if (this.analyticType === 'year') {
        if (dayjs().year() === dayjs(this.year).year()) {
          since = dayjs(this.year).startOf('year').format('YYYY-M-D')
          until = dayjs().subtract(1, 'day').format('YYYY-M-D')
        } else {
          since = dayjs(this.year).startOf('year').format('YYYY-M-D')
          until = dayjs(this.year).endOf('year').format('YYYY-M-D')
        }
      } else {
        since = dayjs(this.range[0]).format('YYYY-M-D')
        until = dayjs(this.range[1]).format('YYYY-M-D')
      }
      await axios
        .get(route('api.detail.hashtag.group'), {
          params: {
            since: since,
            until: until,
            groupId: this.group.id,
            page: this.filter?.page,
            limit: this.filter?.limit,
          },
        })
        .then((result) => {
          this.hashtags = result?.data
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
        })
    },

    convertNumberFloat(data) {
      return Number(Math.round((data + Number.EPSILON) * 100) / 100)
    },

    // sum post hashtag
    sumPostForHashtag(row) {
      const sumPost = row?.postInsights.length

      return sumPost
    },

    // sum like post hashtag
    sumLikePostForHashtag(row) {
      const postInsights = row?.postInsights
      let sumLike = 0

      postInsights?.forEach((data) => {
        sumLike += data?.likes
      })

      return Number(sumLike.toFixed(2))
    },

    // sum cmt post hashtag
    sumCommentPostForHashtag(row) {
      const postInsights = row?.postInsights
      let sumComment = 0

      postInsights?.forEach((data) => {
        sumComment += data?.comments
      })

      return Number(sumComment.toFixed(2))
    },

    // sum saved post hashtag
    sumSavedPostForHashtag(row) {
      const postInsights = row?.postInsights
      let sumSaved = 0

      postInsights?.forEach((data) => {
        sumSaved += data?.saved
      })

      return sumSaved
    },

    // sum reach post hashtag
    sumReachPostForHashtag(row) {
      const postInsights = row?.postInsights
      let sumReach = 0

      postInsights?.forEach((data) => {
        sumReach += data?.reach
      })

      return sumReach
    },

    // sum imperssion post hashtag
    sumImperssionPostForHashtag(row) {
      const postInsights = row?.postInsights
      let sumImperssion = 0

      postInsights?.forEach((data) => {
        sumImperssion += data?.impressions
      })

      return sumImperssion
    },

    // update limit results
    updateLimitResults() {
      this.limit += 10
      this.fetchData()
    },
    sumPostUsingHashtag(row) {
      if (this.checkStartDate(row)) {
        let sumPost = 0
        row?.hashtag_post?.forEach((hashtag_post) => {
          if (this.checkCreatedTimeHashtag(hashtag_post)) {
            sumPost++
          }
        })
        return sumPost
      }
      return '-'
    },
    //format average like
    calculateAverageLike(row) {
      if (row) {
        if (this.checkStartDate(row)) {
          let sumLikes = 0
          const hashtag_posts = row?.hashtag_post
          hashtag_posts?.forEach((hashtag_post) => {
            if (this.checkCreatedTimeHashtag(hashtag_post)) {
              sumLikes += hashtag_post?.likes
            }
          })
          const sumPostUsing = this.sumPostUsingHashtag(row)
          const sum = sumLikes ? sumLikes / sumPostUsing : 0
          return Number.isInteger(sum) ? sum : sum.toFixed(2)
        }
        return '-'
      }
      return '-'
    },
    //format average comment
    calculateAverageComment(row) {
      if (row) {
        if (this.checkStartDate(row)) {
          let sumComments = 0
          const hashtag_posts = row?.hashtag_post
          hashtag_posts?.forEach((hashtag_post) => {
            if (this.checkCreatedTimeHashtag(hashtag_post)) {
              sumComments += hashtag_post?.comments
            }
          })
          const sumPostUsing = this.sumPostUsingHashtag(row)
          const sum = sumComments ? sumComments / sumPostUsing : 0
          return Number.isInteger(sum) ? sum : sum.toFixed(2)
        }
        return '-'
      }
      return '-'
    },
    // check created_time
    checkCreatedTimeHashtag(row) {
      if (row) {
        const created_time = dayjs(new Date(row?.created_time * 1000))
        const since_time = dayjs(this.range[0])
        const until_time = dayjs(this.range[1])
        if (created_time.isSameOrAfter(since_time) && created_time.isSameOrBefore(until_time)) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    // check start date
    checkStartDate(row) {
      if (row) {
        const start_date = dayjs(row?.start_date)
        const since_time = dayjs(this.range[0])
        const until_time = dayjs(this.range[1])
        if (start_date.isSameOrAfter(since_time) && start_date.isSameOrBefore(until_time)) {
          return true
        } else {
          return false
        }
      }
      return false
    },
  },
}
</script>
