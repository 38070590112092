export default {
  'create notification successfully': 'お知らせの登録が完了しました。',
  'delete notification successfully': 'お知らせの削除が完了しました。',
  'update notification successfully': 'お知らせの更新が完了しました。',
  'do you want to delete this notification': 'このお知らせを削除しても宜しいですか。',
  'something went wrong': 'システムに問題があり、暫くお待ちいただき、再度アクセスしてください。',
  Submit: '送信',
  Cancel: 'キャンセル',
  'email is invalid': '入力したメールは有効ではありません。',
  please_try_again: '再度試してください。',
  'add group successfully': 'グループを正常に追加しました',
  'update group successfully': 'グループを正常に更新しました',
  'delete group successfully': 'グループを正常に削除しました',
  'create post successfully': '投稿を正常に作成しました',
  'update post successfully': '投稿を正常に編集しました。',
  'an error occurred during create post': '投稿を作成する際ににエラーが発生しました',
  'create account successfully': 'アカウントを正常に作成しました',
  'an error occurred during create account': 'アカウント作成する際ににエラーが発生しました',
  'payment success': '支払い成功。',
  'please choose a plan': 'プランをお選びください',
  'update account information successfully': 'アカウント情報を正常に更新しました。',
  'update account information successfully (change mail)':
    'メール変更の流れが完了するために、新しいメールをご確認ください。',
  'delete user successfully': 'ユーザーを正常に削除しました。',
  actions: '行動', //table header
  'Account link successful': 'インスタグラムアカウントに連携しました。',
  'Account link failed': 'インスタグラムアカウントに連携出来ません。',
  'Unlock Account': 'アカウントのロックを解除する',
  'Block Account': 'アカウントをブロックする',
  'Unlock now': 'ロックを解除しました。',
  'unlock account': 'ロックを解除しました。',
  'account will be locked 3 hours': 'アカウントは3時間内にロックされます！',
  'do you want to unlock this account': 'このアカウントのロックを解除しましょうか？',
  'do you want to block this account': 'このアカウントをブロックしましょうか？',
  'Please input select account': '選択したアカウントを入力してください',
  'Account already has posts': 'アカウントにはすでに投稿があります',
  'lock account': 'アカウントをロックする',
  'do you want to lock this account': 'このアカウントをロックしますか',
  'Sign Up Success .Please check your email': '登録完了しました、メールアドレスを確認してください',
  'Registration failed . Please try again': '登録に失敗しました 。 もう一回登録してください',
  change_password_success: 'パスワードの変更が完了しました',
  'Please select the plan you want to upgrade': 'プランを選択してください。',
  Payment: '支払い',
  'Admin has set a plan for you, please pay to use the new plan':
    '管理者がプランを設定しました。新しいプランを使用するには料金をお支払いください。',
  'Request plan update': 'プランの更新をリクエスト',
  Close: '閉じる',
  //PagePlan
  'Create plan successfully': 'プラン作成しました',
  'Update plan successfully': 'プラン更新しました',
  'Update plan error': 'プラン更新が失敗しました',
  'Delete plan successfully': 'プラン削除しました',
  'Delete plan error : Has may be used to be used to current plan to':
    'プラン削除出来ません : このプランは使用されたユーザーがございました。',
  //Page default tab plan :
  'Suggest plan for user successfully': 'ユーザーにプラン開放するリクエストは成功しました',
  'Suggest plan for user error': 'ユーザーにプラン開放するリクエストは失敗しました',
  'Suggest error : User has this plan': 'アップグレードするプランを選択してください',
  'Login proxy success': '代理ログイン成功',
  'Logout proxy success': '代理ログアウト成功',
  media_load_failed_please_select_the_vehicle_again: 'メディアファイルをアップ失敗しました。も一回選択してください',
  Preview: 'プレビュー',
  please_import_file: 'ファイルをインポートしてください',
  'delete susscess account competitor': '競合アカウントを削除しました',
  'In the process of downloading invoice': '請求書のダウンロード',
  'Edit tag': 'タグ編集',
  'Search account': 'アカウント検索',
  please_add_enough_permissions_for_the_linked_account: '連携するアカウントの権限を付与してください',
  'Add Tag': 'タグ追加',
  'Extension plan success': 'プラン自動延長が成功しました',
  'Extension plan error': 'プラン自動延長が失敗しました',
  'Start download invoice': '請求書のダウンロードを開始します。',
  'Start export pdf': 'PDF出力を開始します。',
  'Start export csv': 'CSV出力を開始します。',
  'Pause plan success': '利用しているプランをキャンセルしました',
  'Pause plan error': '利用しているプランをキャンセル出来ませんでした',
  'Resume plan success': '利用しているプランを再度有効にしました',
  'Resume plan error': '利用しているプランを再度有効に出来ませんでした',
  'Create customer failed': '顧客の作成に失敗しました！',
  'Card declined': '「card_declined」支払い不可能です。',
  'Expired Card': '「expired_card」有効期限切れました！',
  'Please choose one group': 'グループを選択してください',
  address_zip_check: '「address_zip_check=failed 」不正な郵便番号です。',
  'cvc_check=failed': '「cvc_check=failed」不正なCVCです。',
  'cvc_check=unavailable': '「cvc_check=unavailable」利用不可能なCVCです。',
  // account competitor
  'you need to link instagram account': 'Instagramアカウントを連携しない状態であれば、競合アカウントを検索出来ません。',
  'you do not have any plans': 'プランが開放されないため、競合アカウントを追加出来ません。',
  'your plan cannot add competition accounts': '利用しているプランに対してもっと競合アカウントを追加出来ません。',
  delete: '削除',
  'Do you really want to delete users ?': 'このアカウントを削除してよろしいでしょうか？',
  'Do you really want to continue paying for this plan package?': '利用しているプランを決済してよろしいでしょうか？',
  'If you want to continue using your current plan, click here': '利用しているプランを引き続き利用する場合、こちらへ',
  'Recurring billing has resumed': '定期課金が再開されました',
  invalid_hashtag_information: 'ハッシュタグの情報が正しくありません。',
  add_hashtag_successfully: 'ハッシュタグを追加完了しました。',
  sign_up_success_please_check_your_email: '登録完了しました。',
  // Payment Card
  client_error: 'リクエストエラー',
  card_error: 'カードに関するエラー',
  server_error: 'PAY.JPや決済ネットワーク側のエラー',
  not_found_error: '存在しないオブジェクト',
  not_allowed_method_error: '許可されていないメソッドエラー',
  auth_error: '認証エラー',
  invalid_request_error: '無効なリクエスト',
  invalid_number: '不正なカード番号',
  invalid_cvc: '不正なCVC',
  invalid_expiration_date: '不正な有効期限年、または月',
  incorrect_card_data: 'いずれかのカード情報が誤っている',
  invalid_expiry_month: '不正な有効期限月',
  invalid_expiry_year: '不正な有効期限年',
  expired_card: '有効期限切れ',
  card_declined: 'カード会社によって拒否されたカード',
  card_flagged: 'カードを原因としたエラーが続いたことによる一時的なロックアウト',
  processing_error: '決済ネットワーク上で生じたエラー',
  missing_card: '顧客がカードを保持していない',
  unacceptable_brand: '対象のカードブランドが許可されていない',
  invalid_id: '不正なID',
  no_api_key: 'APIキーがセットされていない',
  invalid_api_key: '不正なAPIキー',
  invalid_plan: '不正なプラン',
  invalid_expiry_days: '不正な失効日数',
  unnecessary_expiry_days: '失効日数が不要なパラメーターである場合',
  invalid_flexible_id: '不正なID指定',
  invalid_timestamp: '不正なUnixタイムスタンプ',
  invalid_trial_end: '不正なトライアル終了日',
  invalid_string_length: '不正な文字列長',
  invalid_country: '不正な国名コード',
  invalid_currency: '不正な通貨コード',
  invalid_address_zip: '不正な郵便番号',
  invalid_amount: '不正な支払い金額',
  invalid_plan_amount: '不正なプラン金額',
  invalid_card: '不正なカード',
  invalid_card_name: '不正なカードホルダー名',
  invalid_card_address_zip: '不正なカード請求先住所(郵便番号)',
  invalid_card_address_state: '不正なカード請求先住所(都道府県)',
  invalid_card_address_city: '不正なカード請求先住所(市区町村)',
  invalid_card_address_line: '不正なカード請求先住所(番地など)',
  invalid_customer: '不正な顧客',
  invalid_boolean: '不正な論理値',
  invalid_email: '不正なメールアドレス',
  no_allowed_param: 'パラメーターが許可されていない場合',
  no_param: 'パラメーターが何もセットされていない',
  invalid_querystring: '不正なクエリー文字列',
  missing_param: '必要なパラメーターがセットされていない',
  invalid_param_key: '指定できない不正なパラメーターがある',
  no_payment_method: '支払い手段がセットされていない',
  payment_method_duplicate: '支払い手段が重複してセットされている',
  payment_method_duplicate_including_customer: '支払い手段が重複してセットされている(顧客IDを含む)',
  failed_payment: '指定した支払いが失敗している場合',
  invalid_refund_amount: '不正な返金額',
  already_refunded: 'すでに返金済み',
  nvalid_amount_to_not_captured: '確定されていない支払いに対して部分返金ができない',
  refund_amount_gt_net: '返金額が元の支払い額より大きい',
  capture_amount_gt_net: '支払い確定額が元の支払い額より大きい',
  invalid_refund_reason: '不正な返金理由',
  already_captured: 'すでに支払いが確定済み',
  cant_capture_refunded_charge: '返金済みの支払いに対して支払い確定はできない',
  cant_reauth_refunded_charge: '返金済みの支払いに対して再認証はできない',
  charge_expired: '認証が失効している支払い',
  already_exist_id: 'すでに存在しているID',
  token_already_used: 'すでに使用済みのトークン',
  already_have_card: '指定した顧客がすでに保持しているカード',
  dont_has_this_card: '顧客が指定したカードを保持していない',
  doesnt_have_card: '顧客がカードを何も保持していない',
  already_have_the_same_card: 'すでに同じカード番号、有効期限のカードを保持している',
  invalid_interval: '不正な課金周期',
  invalid_trial_days: '不正なトライアル日数',
  invalid_billing_day: '不正な支払い実行日',
  billing_day_for_non_monthly_plan: '支払い実行日は月次プランにしか指定できない',
  exist_subscribers: '購入者が存在するプランは削除できない',
  already_subscribed: 'すでに定期課金済みの顧客',
  already_canceled: 'すでにキャンセル済みの定期課金',
  already_paused: 'すでに停止済みの定期課金',
  subscription_worked: 'すでに稼働している定期課金',
  cannot_change_prorate_status: '日割り課金の設定はプラン変更時のみ可能',
  too_many_metadata_keys: 'metadataキーの登録上限(20)を超過している',
  invalid_metadata_key: '不正なmetadataキー',
  invalid_metadata_value: '不正なmetadataバリュー',
  test_card_on_livemode: '本番モードのリクエストにテストカードが使用されている',
  not_activated_account: '本番モードが許可されていないアカウント',
  too_many_test_request: 'テストモードのリクエストリミットを超過している',
  payjp_wrong: 'PAY.JPのサーバー側でエラーが発生している',
  pg_wrong: '決済代行会社のサーバー側でエラーが発生している',
  not_found: 'リクエスト先が存在しないことを示す',
  not_allowed_method: '許可されていないHTTPメソッド',
  over_capacity: 'レートリミットに到達',
  refund_limit_exceeded: '期限を過ぎた後の返金操作',
  please_enter_your_email: 'Emailを入力してください',
  reset: 'リセットする',
  if_you_have_forgotten_your_password_please_enter_the_email_below:
    'パスワードをお忘れの⽅は、下記より <br> メールを入力してください。',
  add_new_card: '新しいカード追加',
  payment_plan_update: '更新',
  turn_back: '戻る',
  send_payment_card: 'カード情報を送信する',
  //city
  Hokkaido: '北海道',
  Aomori: '青森',
  Iwate: '岩手県',
  Miyagi: '宮城県',
  Akita: '秋田県',
  Yamagata: '山形県',
  Fukushima: '福島県',
  Ibaraki: '茨城県',
  Tochigi: '栃木県',
  Gunma: '群馬県',
  Saitama: '埼玉県',
  Chiba: '千葉県',
  Tokyo: '東京都',
  Kanagawa: '神奈川県',
  Niigata: '新潟県',
  Toyama: '富山県',
  Ishikawa: '石川県',
  Fukui: '福井県',
  Yamanashi: '山梨県',
  Nagano: '長野県',
  Gifu: '岐阜県',
  Shizuoka: '静岡県',
  Aichi: '愛知県',
  Mie: '滋賀県',
  Kyoto: '京都府',
  Shiga: '滋賀県',
  Osaka: '大阪府',
  Hyogo: '兵庫県',
  Nara: '奈良県',
  Wakayama: '和歌山県',
  Tottori: '鳥取県',
  Shimane: '島根県',
  Okayama: '岡山県',
  Hiroshima: '広島県',
  Yamaguchi: '山口県',
  Tokushima: '徳島県',
  Kagawa: '香川県',
  Ehime: '愛媛県',
  Kochi: '高知県',
  Fukuoka: '福岡県',
  Saga: '佐賀県',
  Nagasaki: '長崎県',
  Kumamoto: '熊本県',
  Oita: '大分県',
  Miyazaki: '宮崎県',
  Kagoshima: '鹿児島県',
  Okinawa: '沖縄県',
  //advertisment
  'add ads acccount': '広告アカウントを追加します。',
  'ID tài khoản quảng cáo : ': '広告アカウンID : ',
  'select campains current': '既存なキャンペーンを選択します。',
  'campains new': '新規なキャンペーン',
  'campains current': '既存なキャンペーン',
  'tìm kiếm theo vị trí': '位置によって検索',
  'chọn vị trí': '位置を選択します。',
  setName: '広告セット',
  bidAmount: '結果の単価目標',
  dailyBudget: '１日の予算',
  nameAds: '広告名',
  urlWebSite: 'ウェブサイトのURL',
  messageAds: '説明',
  optimizationGoal: '広告配信への最適化',
  desCarousel: '説明',
  nameCarousel: 'カルーセル名',
  linkCarousel: 'カルーセルリンク',
  fileModel: '広告タイプ',
  ImageOrvideo: 'シングル画像または動画',
  uploadFile: 'メディアのアップロード',
  Carosel: 'カルーセル',
  'Vui lòng nhập tên campaign': 'キャンペーン名を入力してください。',
  'Vui lòng nhập tên objective': '目的を入力してください',
  'Vui lòng nhập setName': '広告を入力してください',
  'Vui lòng Search Taget': '広告を配信するターゲットを設定してください。',
  'Vui lòng nhập Optimization Goal': '広告配信への最適化を入力してください',
  'Vui lòng nhập bidAmount': '結果の単価目標を入力してください',
  'Vui lòng nhập dailyBudget': '１日の予算を入力してください',
  'Vui lòng nhập nameAds': '広告名を入力してください',
  'Vui lòng nhập linksShow': 'ウェブサイトのURLを入力してください',
  'Vui lòng nhập phoneCall': '電話番号を入力',
  'Vui lòng nhập chooseTarget': '広告を配信するターゲットを設定してください。',
  infoCarouselErrLink: 'カルーセルリンクを入力してください',
  infoCarouselErrName: 'この項目は必須です。',
  infoCarouselErrDes: 'この項目は必須です。',
  'Vui lòng nhập start_date': '開始日を入力してください。',
  'Vui lòng nhập end_date': '終了日を入力してください。',
  'Vui lòng nhập destination_type': '宛先タイプを入力してください',
  'Vui lòng upload file': 'ファイルを選択してください',
  'create form pdf successfully': 'Pdfの設定が完了しました。',
  'Reset form pdf success': 'Pdf設定の取り消しが完了しました。',
  tooltip_report_pptx: 'アカウント別、グループ別に月間のダウンロード回数を設定する',
  tooltip_report_pdf: 'アカウント別月間のダウンロード回数を設定する',
  tooltip_report_csv: 'アカウント別月間のダウンロード回数を設定する',
  post_hashtag: 'ハッシュタグ分析',
  reserved_post_function: '投稿/予約投稿機能',
  creative_analysis: 'クリエイティブ分析',
  initial_velocity_analysis: '初速分析',
  reel_analysis: 'リール分析',
  comment_analysis: 'コメント分析',
  cycle_months: '契約期間',
  tooltip_cycle_months: 'プランの契約期間を設定する',
  renewal_cycle_months: '契約期間自動更新',
  tooltip_renewal_cycle_months: '契約期間の期限になる時に何ヶ月ごとが自動更新されるか設定する',
  is_show_user: 'プランをアップグレードする画面',
  is_show_admin: '管理者がプランを開放する画面',
  tooltip_is_show_user: '直ユーザーがプランをアップグレードする画面に表示する有無を設定する',
  tooltip_is_show_admin: 'マスター管理者やOEM管理者がユーザーのプランを開放する画面に表示する有無を設定する',
  range_to_display: '表示する範囲',
  tooltip_co_opetition_analysis: '最大な競合アカウント数を設定する',
  tooltip_post_save_draft: '投稿ステータスの下書き保存にチェックを入れ、実行するをクリックすると下書きに保存されます。',
  tooltip_user_info_insta:
    '新しいInstaアカウントを連携する場合は現在に連携するアカウントの分析データが無くことになります。',
  submit_user_plans_canceled: 'キャンセル',
  user_plans_canceled_success: '現在利用しているプランは自動更新をストップしました。',
  user_plans_canceled_error:
    '現在利用しているプランは自動更新のキャンセルが失敗しました。管理者へご連絡お願いいたします。\n 現在利用しているプランは自動更新のキャンセルが失敗しました。管理者へご連絡お願いいたします。',
  uploadVideo: 'uploadVideojaaa',
  do_you_really_want_to_assign_plan: '{plan_name}に変更してもよろしいでしょうか？',
}
