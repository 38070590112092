<template>
    <div
        class="pt-4 pb-9 pagination flex justify-center text-sm text-slate-400 gap-x-5"
    >
        <div class="flex items-center">
            <div @mouseenter="isHoverPre = true" @mouseleave="isHoverPre = false">
                <button
                    @click="handlePageChange(Number(currentPage - 1))"
                    :disabled="currentPage <= 1"
                    :class="{ 'opacity-25 cursor-not-allowed': currentPage <= 1 }"
                    class="mx-auto text-black py-4 px-7 flex justify-center mr-2"
                    style="background: #fafafa"
                >
                    <svg
                        width="10" 
                        height="10" 
                        viewBox="0 0 10 14" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M9 1L2 7L9 13" :stroke="currentPage > 1 && isHoverPre ? '#409eff' : '#C3C3C3'" stroke-width="2"/>
                    </svg>
                </button>
            </div>
            
            <ul class="flex">
                <li
                    class="mx-1 hover:cursor-pointer py-2 px-7 hover:bg-black hover:text-black border border-black transition text-white rounded-sm"
                    @click="handlePageChange(page)"
                    v-for="page in totalPage"
                    :key="page"
                    :class="{ activePageNumber: page === currentPage }"
                >
                    {{ page }}
                </li>
            </ul>
            <div @mouseenter="isHoverNext = true" @mouseleave="isHoverNext = false">
                <button
                    @click="handlePageChange(Number(currentPage + 1))"
                    :disabled="totalPage <= currentPage"
                    :class="{ 'opacity-25 cursor-not-allowed': totalPage <= currentPage }"
                    class="mx-auto text-black py-4 px-7 flex justify-center ml-2"
                    style="background: #fafafa"
                >
                    <svg 
                        width="10" 
                        height="10" 
                        viewBox="0 0 10 14" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg"
                    >
                    <path d="M1 13L8 7L1 0.999999" :stroke="!(totalPage <= currentPageisHover) && isHoverNext ? '#409eff' : '#C3C3C3'" stroke-width="2"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isHoverNext: false,
            isHoverPre: false
        }
    },
    props: ["totalPage", "currentPage", "updateCurrentPage"],
    methods: {
        handlePageChange(page) {
            this.$emit("updateCurrentPage", page);
        },
    },
};
</script>

<style lang="scss" scoped>
.pagination {
    border-radius: 5px;
    border-color: #8d8e92;
    .activePageNumber {
        background-color: #fff;
        color: #414141;
    }
    li {
        background: #414141;
        &:hover {
            background: #ffffff;
            border: 1px solid #414141;
        }
    }
}
</style>
