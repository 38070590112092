<template>

    <div class="flex">
        <div class="sm:w-0 lg:w-32" :class="isShow ? '': 'hidden'"></div>
        <span class="text-left text-red-600 sm:text-2sm text-base sm:w-72 md:w-96 whitespace-nowrap">
            <slot></slot>
        </span>
    </div>
</template>
<script>
export default {
    props:{
        isShow: {
            typẹ: Boolean,
            default: true
        }
    }
}
</script>
