<script setup>
import AppHeader from '@/Layouts/AppHeader';
import Button from "@/Components/Button";
</script>
<template>
    <div class="flex h-screen overflow-hidden">
        <div class="wrapper relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-gray-100">
            <AppHeader/>
            <Loading v-if="isLoading" />
            <div class="paymentSuccess">
                <div class="mb-10">
                    <img src="/img/favicon-upscale.png" alt="Favicon Upscale" width="152" height="28">
                </div>
              <div v-if="userCanceled == 0">
                <div class="text-center font-normal lg:mb-16 md:mb-10 sm:mb-8">
                  <p class="text-nomal">
                    現在利用しているプランは自動更新をご拒否したい場合は下記のボタンをクリックお願いいたします。
                  </p>
                </div>
                <div class="flex justify-around mt-16 lg:gap-10 sm:gap-5">
                  <button @click="submitCanceled"
                          type="button"
                          class="bg-indigo-500 hover:bg-indigo-700 md:py-5 sm:py-3 lg:px-20 md:px-10 sm:px-5 rounded-sm text-white text-btn-submit">
                    {{$t('submit_user_plans_canceled')}}
                  </button>
                  <Link :href="route('admin.users_infor')" method="get" class="bg-button_pink hover:bg-pink-600 md:py-5 sm:py-3 lg:px-20 md:px-10 sm:px-5 rounded-sm text-white text-btn-submit">
                    管理画面に戻る
                  </Link>
                </div>
              </div>
              <div v-else>
                <div class="text-center font-normal lg:mb-16 md:mb-10 sm:mb-8">
                  <p class="text-nomal">
                    現在利用しているプランは自動更新をキャンセルしました。
                  </p>
                </div>
                <div class="flex justify-around mt-16 lg:gap-10 sm:gap-5">
                  <Link :href="route('admin.users_infor')" method="get" class="bg-button_pink hover:bg-pink-600 md:py-5 sm:py-3 lg:px-20 md:px-10 sm:px-5 rounded-sm text-white text-btn-submit">
                    管理画面に戻る
                  </Link>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Link} from '@inertiajs/inertia-vue3';
import axios from "axios";
import Loading from '@/Components/Loadding'

export default {
    props: {
        user_id: {
            type: String,
            required: false,
        },
        user_plan_id: {
          type: String,
          required: false,
        },
        user_canceled: {
          type: String,
          required: false,
        }
    },

    components: {
        Link,
        Loading
    },

    data(){
      return {
        isLoading: false,
        userCanceled:0
      }
    },
    created() {
      this.canceled()
    },

  methods:{
      canceled(){
        this.userCanceled = this.user_canceled;
      },
      async submitCanceled() {
        this.isLoading = true;
        axios
            .post(route('api.user_plans.canceled', this.user_plan_id))
            .then((res) => {
              this.isLoading = false;
              this.$toast.success(this.$t('user_plans_canceled_success'));
              window.location.href = route('admin.users_infor');
            })
            .catch((err) => {
              this.isLoading = false;
              this.$toast.error(this.$t('user_plans_canceled_error'));
            })
      },
    }
}
</script>

<style lang="scss" scoped>
.paymentSuccess{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #DADADA;
    padding: 40px 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
