<template>
  <div>
    <form v-on:submit.prevent="submitForm">
      <div class="grid grid-cols-1 gap-4 py-8">
        <div class="flex items-center justify-between w-full">
          <h1 class="w-60">タイトル</h1>

          <div class="w-full" :class="{ 'failed-validate': errors.title?.first() }">
            <el-input v-model="form.title" class="w-full" size="large" clearable />
            <span class="msg-error-validate">{{ errors.title?.first() }}</span>
          </div>
        </div>

        <div class="flex items-center justify-between w-full">
          <h1 class="w-60">ラベル</h1>

          <div class="w-full" :class="{ 'failed-validate': errors.label?.first() }">
            <el-select v-model="form.label" class="w-full" placeholder="ラベルを選択" size="large">
              <el-option v-for="item in labels" :key="item" :label="item" :value="item" />
            </el-select>
            <span class="msg-error-validate">{{ errors.label?.first() }}</span>
          </div>
        </div>

        <div class="flex justify-between w-full">
          <h1 class="w-60">本文</h1>

          <div class="w-full" :class="{ 'failed-validate': errors.content?.first() }">
            <el-input v-model="form.content" :rows="6" type="textarea" class="w-full" />
            <span class="msg-error-validate">{{ errors.content?.first() }}</span>
          </div>
        </div>

        <div class="flex items-center w-full">
          <h1 class="w-60">配信スケジュール</h1>

          <div class="flex w-full">
            <div class="flex items-center mr-8">
              <input type="radio" id="now" v-model="form.type" :value="0" class="text-pink-400 mr-2" />

              <label for="now" class="whitespace-nowrap">即時配信</label>
            </div>

            <div class="flex items-center mr-8">
              <input type="radio" id="schedule" :value="1" v-model="form.type" class="text-pink-400 mr-2" />

              <label for="schedule" class="whitespace-nowrap">予約配信</label>
            </div>

            <div v-if="form.type === 1" :class="{ 'failed-validate': errors.date?.first() }">
              <el-date-picker
                v-model="form.date"
                type="datetime"
                size="large"
                format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm"
                :disabled-date="disabledDate"
                placeholder="日時入力"
              />
              <span class="msg-error-validate">{{ errors.date?.first() }}</span>
            </div>
          </div>
        </div>

        <div class="flex items-center w-full mt-2">
          <h1 class="w-60">配信権限</h1>
          <div class="w-full">
            <div class="block">
              <div class="flex items-center float-left mr-12 mb-5">
                <input
                  type="checkbox"
                  id="role0"
                  v-model="form.role"
                  :value="0"
                  class="rounded-sm text-pink-400 mr-2"
                  @click="handleCheckAllRole"
                />
                <label for="role0">全て</label>
              </div>
              <div v-for="role in roles" :key="role.id" class="flex items-center float-left mr-12 mb-5">
                <input
                  type="checkbox"
                  :id="'role' + role.id"
                  v-model="form.role"
                  :value="role.id"
                  class="rounded-sm text-pink-400 mr-2"
                  @change="handleCheckRole"
                />
                <label :for="'role' + role.id">{{ role.name }}</label>
              </div>
              <div class="float-none clear-both"></div>
            </div>
            <div class="block" :class="{ 'failed-validate': errors.role?.first() }">
              <span class="msg-error-validate">{{ errors.role?.first() }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-center my-12 w-full">
        <button class="bg-button_profile text-white px-24 py-6 rounded-sm">配信する</button>
      </div>
    </form>
  </div>
</template>

<script>
import Dashboard from '@/Layouts/Authenticated.vue'
import { getListRole } from '@/Api/Role'
import _ from 'lodash'
import { Back } from '@element-plus/icons-vue'
import { Link } from '@inertiajs/inertia-vue3'

export default {
  name: 'Create',
  components: {
    Dashboard,
    Back,
    Link,
  },

  created() {
    this.getListRole()
  },
  data() {
    return {
      isAllowSubmit: true,
      roles: [],
      normalDate: '',
      form: {
        role: [],
        type: 0,
        label: '',
        date: null,
        title: '',
        content: '',
      },
      errors: [],
      labels: ['お知らせ', '不具合', 'アップデート', '最新情報', 'アンケート'],
    }
  },
  computed: {
    rolesId() {
      return this.roles.map((item) => item.id)
    },
  },
  methods: {
    disabledDate(time) {
      let d = new Date()
      return time.getTime() < d.setDate(d.getDate() - 1)
    },
    getListRole() {
      getListRole().then((res) => {
        this.roles = res.data
      })
    },
    submitForm() {
      if (!this.isAllowSubmit) return
      this.isAllowSubmit = false
      axios
        .post(route('api.delivery_notices.store'), this.form)
        .then((res) => {
          if (res?.data?.status === false) {
            this.isAllowSubmit = true
            this.$toast.error(this.$t('something went wrong'))
          } else {
            this.$toast.success(this.$t('create notification successfully'))
            this.$inertia.visit(route('admin.notifications.index'))
          }
        })
        .catch((error) => {
          this.isAllowSubmit = true
          const { data, status } = error.response

          if (status === 422) {
            this.errors = data
          }
        })
    },
    handleCheckAllRole(e) {
      if (e.target.checked) {
        this.form.role = [0, ...this.rolesId]
      } else {
        this.form.role = []
      }
    },
    handleCheckRole(e) {
      if (!e.target.checked) {
        this.form.role = this.form.role.filter((item) => item !== 0)
      } else if (_.isEqual(this.form.role.sort(), this.rolesId.sort())) {
        this.form.role = [0, ...this.rolesId]
      }
    },
  },
  watch: {
    'form.type': {
      handler(val) {
        val === 0 && (this.form.date = null)
      },
      deep: true,
    },
  },
}
</script>

<style></style>
