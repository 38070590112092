import * as accountAPI from '@/Api/Account'

export default {
  computed: {
    isRole() {
      return this.$page.props?.auth?.user?.roles[0]?.name
    },
    currentUser() {
      return this.$page.props?.auth?.account?.username
    },
  },
  data() {
    const date = new Date()
    const since = new Date(date.getFullYear(), date.getMonth(), 1)
    const until = new Date()
    return {
      accRange: [],
      accHashtagTableData: [],
      tablePostHashtagData: [],
      tableReelHashtagData: [],
      tablePostRankingData: {},
      tableReelRankingData: {},
      postTableData: [],
      listImgPostTableData: [],
      postDetail: {},
      detailPostTableData: [],
      detailPostTableDataImg: '',
      detailPostTableDataCategory: '',
      timePostTableData: [],
      tableAnalyticData: [],
      analyticPostRange: [],
      analyticPostCategory: 'normal',
      tableStoryData: [],
      listImgTableStoryData: [],
      storiesAnalysis: {},
      topBestPosts: [],
      topWorstPosts: [],
      topWorstPostCategory: 'normal',
      postSelected: [],
      postSelectedImg: '',
      cmtPostCategory: 'normal',
      postSelectedComment: [],
      dataHashtagsAnalysis: {},

      accBasicTableData: [],
      accFollowerTableData: [],
      accDetailTableData: [],

      dateRange: [since, until],
      displayDetailPost: false,
      pdfSetting: {},
      fullscreenLoading: true,

      postCategory: '',
      postInformation: {},
      competeAccData: [],
      competeTotalData: [],
      dataFirstDayAcc: [],
      hashtagCurrTab: 'normal',
      analysisHourCurrTab: 'normal',
      analysisCurrTabHour: 'impressions',
      analysisCurrTabTime: 'impressions',
      accTransitionTab: 'follow',
      showLinkInstagram: false,
      hashtagDetail: {},
      hashtagTableData: {},
      dataElacticHashtag: {},
      detailHashtagRange: [],
      detailHashtagLabel: 'likes',
      detailHashtagPopularDate: new Date(),
      numberExport: 0,
    }
  },
  mounted() {
    this.getFollowDataTable()
  },
  methods: {
    onChangeRangeAccount: function (range) {
      this.accRange = range
    },
    mapAccountHashtagTableData: function (data) {
      this.accHashtagTableData = data
    },
    getPostHashtagTableData: function (data) {
      this.tablePostHashtagData = data
    },
    getReelHashtagTableData: function (data) {
      this.tableReelHashtagData = data
    },
    getPostRankingTableData: function (data) {
      this.tablePostRankingData = data
    },
    getReelRankingTableData: function (data) {
      this.tableReelRankingData = data
    },
    getPostTableData: async function (data) {
      this.postTableData = await data
    },
    getListImgPostTableData: async function (data) {
      this.listImgPostTableData = await data
    },
    getDetailPost: function (data) {
      this.postDetail = data
    },
    getDetailPostTableData: function (data) {
      this.detailPostTableData = data
    },
    getDetailPostTableDataImg: function (data) {
      this.detailPostTableDataImg = data
    },
    getDetailPostTableDataCategory: function (data) {
      this.detailPostTableDataCategory = data
    },
    getTimePostTableData: function (data) {
      this.timePostTableData = data
    },
    getAnalyticPostTableData: function (data) {
      this.tableAnalyticData = data
    },
    isData: function (data) {
      this.isData = data
    },
    getAnalyticPostRange: function (data) {
      this.analyticPostRange = data
    },
    getAnalyticPostCategory: function (data) {
      this.analyticPostCategory = data
    },
    getTableStoryData: function (data) {
      this.tableStoryData = data
    },
    getlistImgTableStoryData: function (data) {
      this.listImgTableStoryData = data
    },
    getStoriesAnalysis: function (data) {
      this.storiesAnalysis = data
    },
    getTopBestPosts: function (data) {
      this.topBestPosts = data
    },
    getTopWorstPosts: function (data) {
      this.topWorstPosts = data
    },
    getTopWorstPostCategory: function (data) {
      this.topWorstPostCategory = data
    },
    getPostSelected: function (data) {
      this.postSelected = data
    },
    getPostSelectedImg: async function (data) {
      this.postSelectedImg = await data
    },
    getCmtPostCategory: function (data) {
      this.cmtPostCategory = data
    },
    getPostSelectedComment: function (data) {
      this.postSelectedComment = data
    },
    getDataHashtagsAnalysis: function (data) {
      this.dataHashtagsAnalysis = data
    },
    getPostCategory: function (data) {
      this.postCategory = data
    },
    getPostInfo: function (data) {
      this.postInformation = data
    },
    mapDataCompeteFollower: function (data) {
      this.competeAccData = data
    },
    mapDataCompeteAccount: function (data) {
      this.competeTotalData = data
    },
    mapDataFirstDayAcc: function (data) {
      this.dataFirstDayAcc = data
    },
    getHashtagCurrTab: function (data) {
      this.hashtagCurrTab = data
    },
    getAnalysisHourCurrTab: function (data) {
      this.analysisHourCurrTab = data
    },
    getDetailHashtag: function (data) {
      this.hashtagDetail = data
    },
    getHashtagTableData: function (data) {
      this.hashtagTableData = data
    },
    getDataElacticHashtag: function (data) {
      this.dataElacticHashtag = data
    },
    getDetailHashtagRange: function (data) {
      this.detailHashtagRange = data
    },
    getDetailHashtagLabel: function (data) {
      this.detailHashtagLabel = data
    },
    getAnalysisHourCurrTabHour: function (data) {
      this.analysisCurrTabHour = data
    },
    getAnalysisHourCurrTabTime: function (data) {
      this.analysisCurrTabTime = data
    },
    getAccountTransitionTab: function (data) {
      this.accTransitionTab = data
    },
    getAccCompete: function (data) {
      this.accCompete = data
    },
    getDetailHashtagPopularDate: function (data) {
      this.detailHashtagPopularDate = data
    },
    async getFollowDataTable() {
      const res = await accountAPI.getPdfSetting()
      this.pdfSetting = res.data
    },
    getAccountTableData: function (data) {
      this.getAccBasicTableData(data)
      this.getFollowerTableData(data)
      this.getDetailTableData(data)
    },
    getAccBasicTableData(data) {
      let accBasicFl =
        (data.lastDaySelected?.followers - data.lastDayPrevious?.followers) > 0
          ? '+' + data.lastDaySelected?.followers - data.lastDayPrevious?.followers
          : data.lastDaySelected?.followers - data.lastDayPrevious?.followers
      this.accBasicTableData = []
      this.accBasicTableData.push(data.lastDaySelected?.followers || 0)
      this.accBasicTableData.push(accBasicFl || 0)
      this.accBasicTableData.push(data.calculation?.totalProfileViews || 0)
      this.accBasicTableData.push(data.calculation?.totalWebsiteClicks || 0)
      this.accBasicTableData.push(data.calculation?.totalReach || 0)
      this.accBasicTableData.push(data.lastDaySelected?.media_count || 0)
    },
    getFollowerTableData(data) {
      let followFl =
        (data.lastDaySelected?.followers - data.lastDayPrevious?.followers) > 0
          ? '+' + data.lastDaySelected?.followers - data.lastDayPrevious?.followers
          : data.lastDaySelected?.followers - data.lastDayPrevious?.followers
      this.accFollowerTableData = []
      this.accFollowerTableData.push(data.lastDaySelected?.followers || 0)
      this.accFollowerTableData.push(followFl || 0)
      this.accFollowerTableData.push(this.followerChangeAverage(data) || 0)
      this.accFollowerTableData.push(this.followerIncrease(data) || 0)
      this.accFollowerTableData.push(this.followerIncreaseLevel(data) || 0)
      this.accFollowerTableData.push(this.formatDataPlus(this.followerIncreaseRatio(data)) || 0)
      this.accFollowerTableData.push(this.followerFell(data) || 0)
      this.accFollowerTableData.push(this.followerFellAverage(data) || 0)
      this.accFollowerTableData.push(this.formatDataMinus(this.followerFellRatio(data)) || 0)
    },
    getDetailTableData(data) {
      this.accDetailTableData = []
      this.accDetailTableData.push(data.calculation?.totalProfileViews || 0)
      this.accDetailTableData.push(this.profileImpressionsAverage(data) || 0)
      this.accDetailTableData.push(data.lastDaySelected?.followers ? this.detailFollowerFellRatio(data) : 0)
      this.accDetailTableData.push(data.calculation?.totalWebsiteClicks || 0)
      this.accDetailTableData.push(this.clickNumberAverage(data) || 0)
      this.accDetailTableData.push(this.clickNumberRatio(data) || 0)
    },
    drawTimeFrame() {
      const timeLineCanvas = document.createElement('canvas')
      timeLineCanvas.id = 'canvas-acc-analytic'
      timeLineCanvas.width = 320
      timeLineCanvas.height = 50

      let timeLineCtx = timeLineCanvas.getContext('2d')
      let x = 10
      let y = 10
      let h = 35
      let w = 240
      let radius = 10
      let r = x + w
      let b = y + h
      timeLineCtx.beginPath()
      timeLineCtx.strokeStyle = 'grey'
      timeLineCtx.lineWidth = '1'
      timeLineCtx.moveTo(x + radius, y)
      timeLineCtx.lineTo(r - radius, y)
      timeLineCtx.quadraticCurveTo(r, y, r, y + radius)
      timeLineCtx.lineTo(r, y + h - radius)
      timeLineCtx.quadraticCurveTo(r, b, r - radius, b)
      timeLineCtx.lineTo(x + radius, b)
      timeLineCtx.quadraticCurveTo(x, b, x, b - radius)
      timeLineCtx.lineTo(x, y + radius)
      timeLineCtx.quadraticCurveTo(x, y, x + radius, y)
      timeLineCtx.stroke()
      return timeLineCanvas
    },
  },
}
