<template>
  <DrashBoard>
    <div class="post-ads-dialog">
      <PopupAdsIns :data="adsAccount" v-model="isShow" @onSubmitSuccess="handleSubmitSuccess" />
    </div>
    <template v-slot:breakcumb>
      <Loading v-if="isLoading" />
      <BreadCrumb>
        <li class="mr-4">
          <Link :href="route('admin.post-advertisment.index')" class="font-normal text-13 breakcumb-active">
            広告配信画面
          </Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-2 relative pb-5 active text-13">広告配信画面</li>
        </ul>
      </div>
    </template>
    <div id="main-layout" class="bg-background">
      <div class="flex items-center border-b border-graylight advertisment__user--info">
        <UserNav />
        <div @click="handleLoginInsta" class="md:w-24 cursor-pointer sm:w-16">
          <img src="@shared/uploads/ins-icon.png" />
        </div>
        <p v-if="accountAds" class="text-base font-normal pl-[10px] whitespace-nowrap">{{ accountAds }}</p>
      </div>
      <el-form class="form_advertisment" :model="form" label-position="top" ref="ruleForm" :rules="rules">
        <div id="divFirst" class="flex md:px-14 sm:px-5 pb-20 lg:flex-row sm:flex-col">
          <div class="basis-8/12 lg:pr-10">
            <div class="flex justify-between mt-14">
              <h2 class="title-md text-14">広告配信画面</h2>
            </div>
            <!-- demo file -->
            <div class="flex mt-12 border-gray-300 border-b pb-6 items-center">
              <label class="text-13" for="">アカウント名:</label>
              <p class="text-base text-request font-normal ml-5" v-if="account">{{ account.username }}</p>
            </div>
            <div class="mt-5">
              <el-radio v-model="chooseCampains" label="1">
                <span class="text-13">{{ $t('campains new') }}</span>
              </el-radio>
              <el-radio v-model="chooseCampains" label="2">
                <span class="text-13">{{ $t('campains current') }}</span>
              </el-radio>
            </div>
            <div class="mt-5 border-b border-gray-300" v-if="chooseCampains == 2">
              <el-form-item :label="this.$i18n.t('select campains current')" prop="campainsCurrent">
                <el-select
                  v-model="form.campaign_name"
                  :placeholder="this.$i18n.t('select campains current')"
                  size="large"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="JSON.stringify(item)"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="mt-5 border-b border-gray-300 campains" v-if="chooseCampains == 1">
              <div class="pb-3 border-b border-gray-300" v-if="chooseCampains == 1">
                <el-form-item class="text-13" label="キャンペーン名" prop="campaign_name">
                  <el-input
                    placeholder="キャンペーン名を入力"
                    v-model="form.campaign_name"
                    size="large"
                    class="w-100 text-13"
                  />
                </el-form-item>
              </div>
              <el-form-item class="mt-5 text-13" label="キャンペーンの目的を選択" prop="campaign_objective">
                <el-radio-group class="radio_campaign" v-model="form.campaign_objective">
                  <el-radio
                    v-for="item in listCampain"
                    :value="item.value"
                    :key="item"
                    :label="item.value"
                    size="large"
                    class="lg:w-1/4 md:w-1/5 sm:w-1/2 text-13"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div v-if="form.campaign_objective == 'LINK_CLICKS'" class="mt-5 border-b border-gray-300">
              <el-form-item label="トラフィック" prop="destination_type" class="mt-7 form__item-between text-13">
                <el-radio-group class="radio_campaign" v-model="form.destination_type">
                  <el-radio
                    v-for="item in listTraffic"
                    :value="item.value"
                    :key="item"
                    :label="item.value"
                    size="large"
                    class="lg:w-1/4 md:w-1/5 sm:w-1/2 text-13"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div v-if="form.campaign_objective == 'LEAD_GENERATION'" class="mt-5 border-b border-gray-300">
              <el-form-item label="リード方法" prop="destination_type" class="mt-7 form__item-between text-13">
                <el-radio-group class="radio_campaign" v-model="form.destination_type">
                  <!-- phone call follow locale targeting -->
                  <el-radio label="PHONE_CALL" size="large" class="lg:w-1/4 md:w-1/5 sm:w-1/2">通話</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <!-- b2 -->
            <div class="border-b border-gray-300 pt-7 pb-2">
              <el-form-item prop="setName" :label="this.$i18n.t('setName')">
                <el-input placeholder="広告セットを入力" v-model="form.setName" size="large" class="w-100" />
              </el-form-item>
            </div>
            <div class="py-7 border-b border-gray-300 campains" v-if="this.checkOptimizationGoal">
              <el-form-item
                :label="this.$i18n.t('optimizationGoal')"
                prop="optimizationGoal"
                v-if="
                  !this.convertsions &&
                  !this.reach &&
                  !this.leadGeneration &&
                  !this.appInstalls &&
                  !this.videoViews &&
                  !this.pageLikes &&
                  !this.postEngagement &&
                  !this.brandAwareness
                "
              >
                <el-radio-group class="radio_campaign" v-model="form.optimizationGoal">
                  <el-radio
                    v-for="item in listOptimizationGoal"
                    :value="item.value"
                    :key="item"
                    :label="item.value"
                    size="large"
                    class="lg:w-1/4 md:w-1/5 sm:w-1/2 text-13"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="this.$i18n.t('optimizationGoal')" prop="optimizationGoal" v-if="this.convertsions">
                <el-radio-group class="radio_campaign" v-model="form.optimizationGoal">
                  <el-radio
                    v-for="item in ListConvertsions"
                    :value="item.value"
                    :key="item"
                    :label="item.value"
                    size="large"
                    class="lg:w-1/4 md:w-1/5 sm:w-1/2 text-13"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="this.$i18n.t('optimizationGoal')" prop="optimizationGoal" v-if="this.reach">
                <el-radio-group class="radio_campaign" v-model="form.optimizationGoal">
                  <el-radio
                    v-for="item in ListReach"
                    :value="item.value"
                    :key="item"
                    :label="item.value"
                    size="large"
                    class="lg:w-1/4 md:w-1/5 sm:w-1/2 text-13"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                :label="this.$i18n.t('optimizationGoal')"
                prop="optimizationGoal"
                v-if="this.leadGeneration"
              >
                <el-radio-group class="radio_campaign" v-model="form.optimizationGoal">
                  <el-radio
                    v-for="item in ListLeadGeneration"
                    :value="item.value"
                    :key="item"
                    :label="item.value"
                    size="large"
                    class="lg:w-1/4 md:w-1/5 sm:w-1/2"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="this.$i18n.t('optimizationGoal')" prop="optimizationGoal" v-if="this.appInstalls">
                <el-radio-group class="radio_campaign" v-model="form.optimizationGoal">
                  <el-radio
                    v-for="item in ListAppInstalls"
                    :value="item.value"
                    :key="item"
                    :label="item.value"
                    size="large"
                    class="lg:w-1/4 md:w-1/5 sm:w-1/2"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="this.$i18n.t('optimizationGoal')" prop="optimizationGoal" v-if="this.pageLikes">
                <el-radio-group class="radio_campaign" v-model="form.optimizationGoal">
                  <el-radio
                    v-for="item in ListPageLikes"
                    :value="item.value"
                    :key="item"
                    :label="item.value"
                    size="large"
                    class="lg:w-1/4 md:w-1/5 sm:w-1/2"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                :label="this.$i18n.t('optimizationGoal')"
                prop="optimizationGoal"
                v-if="this.postEngagement"
              >
                <el-radio-group class="radio_campaign" v-model="form.optimizationGoal">
                  <el-radio
                    v-for="item in ListPostEngagement"
                    :value="item.value"
                    :key="item"
                    :label="item.value"
                    size="large"
                    class="lg:w-1/4 md:w-1/5 sm:w-1/2"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                :label="this.$i18n.t('optimizationGoal')"
                prop="optimizationGoal"
                v-if="this.brandAwareness"
              >
                <el-radio-group class="radio_campaign" v-model="form.optimizationGoal">
                  <el-radio
                    v-for="item in ListBrandAwareness"
                    :value="item.value"
                    :key="item"
                    :label="item.value"
                    size="large"
                    class="lg:w-1/4 md:w-1/5 sm:w-1/2"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>

              <!-- VIDEO_VIEWS -->
              <el-form-item :label="this.$i18n.t('optimizationGoal')" prop="optimizationGoal" v-if="this.videoViews">
                <el-radio-group class="radio_campaign" v-model="form.optimizationGoal">
                  <el-radio label="THRUPLAY" size="large" class="lg:w-1/4 md:w-1/5 sm:w-1/2">THRUPLAY</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="grid grid-cols-2 md:gap-0 sm:gap-5 justify-between mt-10">
              <el-form-item
                :label="this.$i18n.t('bidAmount')"
                prop="bidAmount"
                class="budeget-period post-ads-input-money"
                style="max-width: 320px"
              >
                <span class="absolute top-1/2 icon-absolute -translate-y-1/2 z-10 ml-2">¥</span>
                <el-input v-model="form.bidAmount" @keypress="isNumber($event)" size="large" class="w-full">
                  <template #suffix>JPY</template>
                </el-input>
              </el-form-item>
              <el-form-item
                :label="this.$i18n.t('dailyBudget')"
                prop="dailyBudget"
                class="budeget-period post-ads-input-money"
                style="max-width: 320px"
              >
                <span class="absolute top-1/2 icon-absolute -translate-y-1/2 z-10 ml-2">¥</span>
                <el-input v-model="form.dailyBudget" @keypress="isNumber($event)" size="large" class="w-full">
                  <template #suffix>JPY</template>
                </el-input>
              </el-form-item>
              <el-form-item label="開始日時" prop="start_date" class="budeget-period w-full" style="max-width: 320px">
                <el-date-picker
                  v-model="since"
                  type="datetime"
                  size="large"
                  format="YYYY-MM-DD HH:mm"
                  popper-class="cusDatePickerAdStartTime"
                  :disabled-date="disabledStartDate"
                />
                <div class="text-left text-red-600 w-80 text-13" v-if="!since">
                  {{ `${this.$i18n.t('Vui lòng nhập start_date')}` }}
                </div>
              </el-form-item>
              <el-form-item label="終了日" prop="end_date" class="w-full" style="max-width: 320px">
                <el-date-picker
                  v-model="until"
                  type="datetime"
                  size="large"
                  format="YYYY-MM-DD HH:mm"
                  popper-class="cusDatePickerAdEndTime"
                  :disabled-date="disabledEndDate"
                />
                <div class="text-left text-red-600 w-80 text-13" v-if="!until">
                  {{ `${this.$i18n.t('Vui lòng nhập end_date')}` }}
                </div>
              </el-form-item>
            </div>
            <div class="py-6 grid grid-cols-1 mt-3 border-b border-gray-300">
              <div class="pt-7 border-t border-gray-300">
                <p class="text-md text-gray-600 pb-3 text-13">オーディエンス設定</p>
                <el-form-item :label="this.$i18n.t('tìm kiếm theo vị trí')" prop="searchTarget">
                  <el-input
                    prop="searchTarget"
                    v-on:keyup.enter="handleSearchTarget()"
                    v-model="form.searchTarget"
                    size="large"
                    class="w-100"
                    placeholder="位置によって検索を入力"
                  />
                </el-form-item>
                <el-form-item :label="this.$i18n.t('chọn vị trí')" prop="chooseTarget" v-if="openSelectTarget">
                  <el-select v-model="form.chooseTarget" :placeholder="this.$i18n.t('chọn vị trí')" size="large">
                    <el-option
                      v-for="item in dataTarget"
                      :key="item"
                      :label="item.name"
                      :value="JSON.stringify(item)"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>

              <!-- b3 -->
              <div class="grid-cols-5 gap-4">
                <div class="flex flex-1 mt-5 md:flex-row sm:flex-col">
                  <span class="lg:basis-1/5 md:basis-1/4 text-gray-600 text-13">性別</span>
                  <div class="flex-1 flex gap-x-7.5 items-center radio-mr-0 lg:pl-0 md:pl-4">
                    <el-radio v-model="form.gender" :label="0"><span class="text-13">全て</span></el-radio>
                    <el-radio v-model="form.gender" :label="1"><span class="text-13">男性</span></el-radio>
                    <el-radio v-model="form.gender" :label="2"><span class="text-13">女性</span></el-radio>
                  </div>
                </div>
                <div class="flex flex-1 mt-5 md:flex-row sm:flex-col">
                  <span class="lg:basis-1/5 md:basis-1/4 text-gray-600 text-13">年齢</span>
                  <div class="flex-1 flex items-center gap-2 lg:pl-0 md:pl-4">
                    <el-select v-model="ageMin" placeholder="最小年齢を選択" style="width: 200px" size="large">
                      <el-option
                        v-for="item in optionsAgeMin"
                        :key="item.value"
                        :label="item.label"
                        :disabled="item.disabled"
                        :value="item.value"
                      ></el-option>
                    </el-select>

                    <el-select v-model="ageMax" placeholder="最大年齢を選択" style="width: 200px" size="large">
                      <el-option
                        v-for="item in optionsAgeMax"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        :disabled="item.disabled"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <!-- ad locale -->
                <div class="flex flex-1 mt-5 md:flex-row sm:flex-col custom-el-select__search">
                  <span class="lg:basis-1/5 md:basis-1/4 text-gray-600 text-13">言語</span>
                  <el-select
                    v-model="form.addLocale"
                    multiple
                    filterable
                    remote
                    reserve-keyword
                    placeholder="検索を入力"
                    :remote-method="handleSelectLocaleAds"
                    size="large"
                  >
                    <el-option
                      v-for="item in searchLocal"
                      :key="item.key"
                      :label="item.name"
                      :value="item.key"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="mt-10 form-input-column">
              <el-form-item prop="nameAds" :label="this.$i18n.t('nameAds')" class="form-link">
                <el-input placeholder="広告名を入力" v-model="form.nameAds" size="large" class="w-100" />
              </el-form-item>
              <el-form-item
                prop="linksShow"
                :label="this.$i18n.t('urlWebSite')"
                v-if="
                  (fileType == 'video/mp4' && form.destination_type != 'PHONE_CALL') ||
                  (form.modelFile == 2 && form.destination_type != 'PHONE_CALL')
                "
                class="form-link"
              >
                <el-input placeholder="ウェブサイトのURLを入力" v-model="form.linksShow" size="large" class="w-100" />
              </el-form-item>
              <div class="relative">
                <el-form-item
                  prop="phoneCall"
                  :label="'電話番号'"
                  v-if="form.destination_type == 'PHONE_CALL'"
                  class="form-link"
                >
                  <span class="absolute top-1/2 left-2 border-r pr-1.5 z-10 -translate-y-1/2">(+81)</span>
                  <el-input
                    placeholder="電話番号を入力"
                    v-model="form.phoneCall"
                    size="large"
                    class="w-100 input-phone-number"
                  />
                </el-form-item>
              </div>
              <el-form-item prop="messageAds" :label="this.$i18n.t('messageAds')" class="form-link text-13">
                <el-input
                  placeholder="説明を入力"
                  type="textarea text-13"
                  v-model="form.messageAds"
                  size="large"
                ></el-input>
              </el-form-item>
            </div>
            <div class="mt-5 form-input-column text-13">
              <div class="el-form-item el-input__campaing form-link text-13">
                <label class="el-form-item__label text-13">{{ this.$i18n.t('fileModel') }}</label>
                <div class="el-form-item el-input__campaing flex items-center">
                  <input
                    type="radio"
                    id="one"
                    value="1"
                    v-model="form.modelFile"
                    class="mr-2 border-gray-200 text-13"
                    checked
                  />
                  <label for="one" class="text-13 mr-5">{{ this.$i18n.t('ImageOrvideo') }}</label>
                  <input
                    type="radio"
                    class="text-13 mr-2 border-gray-200"
                    id="two"
                    value="2"
                    v-model="form.modelFile"
                  />
                  <label class="text-13" for="two">{{ this.$i18n.t('Carosel') }}</label>
                </div>
              </div>
              <div class="el-form-item el-input__campaing form-link">
                <label class="el-form-item__label text-13">
                  <span class="text-13">{{ this.$i18n.t('uploadFile') }}</span>
                  <span class="text-red-600">*</span>
                </label>
                <input
                  @change="selectImg(e)"
                  v-show="false"
                  type="file"
                  id="file"
                  ref="inputFile"
                  accept="image/jpeg,image/png,image/heic,image/heif,video/mp4,video/quicktime"
                  :multiple="form.modelFile == 1 ? false : true"
                />
                <div v-show="form.modelFile == 1" class="flex items-center">
                  <p
                    v-if="fileUpload == ''"
                    @click="uploadFile"
                    class="text-white text-13 button-shadow bg-blue-600 md:py-2 md:w-44 sm:w-24 sm:py-1 text-center hover:cursor-pointer rounded mr-5 text-13"
                  >
                    アップロード
                  </p>
                  <div class="flex flex-col">
                    <div
                      v-if="fileUpload != '' && fileType != 'video/mp4'"
                      class="w-40 h-40 rounded-md border flex items-center justify-center"
                    >
                      <img :src="fileUpload.slice(-1)[0].url" class="h-full w-full" />
                    </div>
                    <div
                      v-if="fileType == 'video/mp4' && fileUpload != ''"
                      class="w-40 h-40 rounded-md flex items-center justify-center"
                    >
                      <video
                        id="foo"
                        width="300"
                        :src="fileUpload.slice(-1)[0].url"
                        controls
                        autoplay
                        class="h-full w-full"
                      ></video>
                    </div>
                  </div>

                  <span
                    v-if="fileUpload != ''"
                    @click="uploadNewFile"
                    class="text-xs text-button_pink underline hover:cursor-pointer mr-[5px] ml-[5px]"
                  >
                    クリエイティブを変更する
                  </span>
                </div>
                <div v-show="form.modelFile == 2" class="flex items-center gap-5 flex-wrap">
                  <div v-for="(item, index) in fileUpload" :key="item" class="flex items-center w-full">
                    <div class="flex flex-col w-full">
                      <div class="flex lg:flex-row sm:flex-col">
                        <div class="flex mr-5 flex-col w-full form-carousel">
                          <el-form-item prop="nameCarousel" class="w-full">
                            <label class="text-label">
                              <span class="text-13">{{ `${this.$i18n.t('nameCarousel')}` }}</span>
                              <span class="text-red-500">*</span>
                            </label>
                            <el-input
                              placeholder="カルーセル名を入力"
                              v-model="infoCarousel[index].name"
                              :class="
                                infoCarousel[index].name === '' || !infoCarousel[index].name.trim()
                                  ? 'border border-red-600 rounded'
                                  : ''
                              "
                              size="large"
                              class="w-100 text-13"
                            />
                            <span
                              v-if="infoCarousel[index].name === '' || !infoCarousel[index].name.trim()"
                              class="text-left text-red-600 w-80 text-13"
                            >
                              {{ `${this.$i18n.t('infoCarouselErrName')}` }}
                            </span>
                          </el-form-item>
                          <el-form-item prop="linkCarousel" class="w-full">
                            <label class="text-label">
                              <span class="text-13">{{ `${this.$i18n.t('linkCarousel')}` }}</span>
                              <span class="text-red-500">*</span>
                            </label>
                            <el-input
                              placeholder="カルーセルリンクを入力"
                              v-model="infoCarousel[index].link"
                              :class="
                                infoCarousel[index].link === '' || !isValidURL(infoCarousel[index].link)
                                  ? 'border border-red-600 rounded'
                                  : ''
                              "
                              size="large"
                              class="w-100"
                            />
                            <span
                              v-if="infoCarousel[index].link === '' || !isValidURL(infoCarousel[index].link)"
                              class="text-left text-red-600 w-80 text-13"
                            >
                              {{ `${this.$i18n.t('infoCarouselErrLink')}` }}
                            </span>
                          </el-form-item>
                          <el-form-item prop="infoCarousel" class="w-full">
                            <label class="text-label text-13">
                              {{ `${this.$i18n.t('desCarousel')}` }}
                              <span class="text-red-500">*</span>
                            </label>
                            <el-input
                              placeholder="説明を入力"
                              v-model="infoCarousel[index].des"
                              :class="
                                infoCarousel[index].des === '' || !infoCarousel[index].name.trim()
                                  ? 'border border-red-600 rounded'
                                  : ''
                              "
                              size="large"
                              class="w-100"
                            />
                            <span
                              v-if="infoCarousel[index].des === '' || !infoCarousel[index].des.trim()"
                              class="text-left text-red-600 w-80 text-13"
                            >
                              {{ `${this.$i18n.t('infoCarouselErrDes')}` }}
                            </span>
                          </el-form-item>
                        </div>
                        <div class="flex items-center carosel relative mr-5 w-full justify-center">
                          <div
                            v-if="item.type != 'video/mp4' && fileUpload != ''"
                            class="w-40 h-40 rounded-md border flex items-center justify-center"
                          >
                            <img :src="item.url" alt="img" class="h-full w-full" />
                          </div>
                          <div class="carosel-icon flex gap-3 w-40 h-40">
                            <i @click="removeFile(index)" class="fa-solid fa-trash-can text-white"></i>
                          </div>
                          <div
                            v-if="item.type == 'video/mp4' && fileUpload != ''"
                            class="w-40 h-40 rounded-md flex items-center justify-center"
                          >
                            <video id="foo" width="230" :src="item.url" controls autoplay class="h-full w-full"></video>
                          </div>
                        </div>
                      </div>
                      <el-divider><i class="el-icon-star-on"></i></el-divider>
                    </div>
                  </div>
                  <div
                    v-if="fileUpload.length < 10"
                    @click="uploadFile"
                    class="hover:cursor-pointer carolsel-upload w-40 rounded-md h-32 flex items-center justify-center"
                  >
                    <i class="fa-solid fa-plus text-2xl"></i>
                  </div>
                </div>
                <p>&nbsp</p>
                <p v-if="!validateFileUpload" class="text-left text-red-600 w-80 text-13 text-xs">
                  {{ `${this.$i18n.t('Vui lòng upload file')}` }}
                </p>
              </div>
            </div>
            <div class="flex items-center justify-center w-full mt-10 advertisment__btn-submit">
              <button
                type="button"
                class="bg-pink-400 text-white w-72 lg:px-10 md:px-5 sm:px-5 py-4 rounded-sm text-base button-shadow whitespace-nowrap mr-5 text-14"
                @click="goBack()"
              >
                戻る
              </button>

              <button
                type="button"
                class="bg-button_profile text-white w-72 lg:px-10 md:px-5 sm:px-5 py-4 rounded-sm text-base button-shadow whitespace-nowrap text-14"
                @click="handleSubmitForm('ruleForm')"
              >
                公開する
              </button>
            </div>
          </div>
          <!-- preview screen -->
          <div class="basis-4/12 lg:border-l border-gray-300">
            <div class="flex flex-col pt-10">
              <div class="block mx-auto bg-no-repeat px-4 pb-5 advertisment-preview">
                <PostPreview
                  :username="'username'"
                  :arrImage="fileUpload"
                  :file="fileUpload"
                  :fileType="fileType"
                  :validate="fileUpload != ''"
                  :isCarousel="form.modelFile == 2"
                  :content="form.messageAds"
                  :profilePictureUrl="account.profile_picture_url"
                />
              </div>
              <div class="flex align-center w-100" v-if="false">
                <el-button
                  class="w-64 button-shadow text-white mt-10 rounded mx-auto"
                  size="large"
                  style="background-color: #5264cd; boder: 1px solid #5264cd; padding: 25px 0px"
                  @click="openPreview()"
                  :disabled="fileUpload.length === 0"
                >
                  <span class="text-white">投稿のプレビュー</span>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div class="post-ads-dialog">
      <el-dialog
        width="320px"
        v-model="dialogVisible"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <h2 class="text-option text-center font-semibold">Facebookアカウントをリンクしてください</h2>
        <button class="mt-4 w-full">
          <div @click="handleLoginInsta" class="w-32 cursor-pointer mx-auto">
            <img src="@shared/uploads/ins-icon.png" />
          </div>
        </button>
      </el-dialog>
      <!-- dialog preview -->
      <el-dialog v-model="isOpenPreview" width="50%" title="広告配信画面" append-to-body>
        <PostPreview
          :username="'username'"
          :arrImage="fileUpload"
          :file="fileUpload"
          :fileType="fileType"
          :validate="fileUpload != ''"
          :isCarousel="form.modelFile == 2"
          :content="form.messageAds"
        />
      </el-dialog>
    </div>
  </DrashBoard>
</template>

<script>
import PostPreview from './Preview.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import Ins from '@/Services/Ins'
import PopupAdsIns from '@/Components/Ads/PopupAdsIns.vue'
import axios from 'axios'
import Loading from '@/Components/Loadding'
import adsMixins from '@/Mixins/ads.js'
export default {
  name: 'advertisment',
  components: { PostPreview, DrashBoard, UserNav, BreadCrumb, Link, PopupAdsIns, Loading },
  mixins: [adsMixins],

  methods: {
    selectImg: async function (e) {
      if (this.form.modelFile == 1 || (event.target.files && event.target.files.length == 1)) {
        this.fileUpdate = event.target.files[0]
        let fileLocal = {}
        fileLocal.url = URL.createObjectURL(this.fileUpdate)
        fileLocal.type = event.target.files[0].type
        this.fileUpload.push(fileLocal)
        this.infoCarousel.push({
          des: '',
          name: '',
          link: '',
        })
        this.fileType = this.fileUpdate.type
        let checkType = this.fileType.split('/')[0]
        if (checkType == 'video') {
          let fileThumnail = await this.dataUrlToFile(this.fileUpdate, 'thumbnail.jpeg')
          this.form.myThumbnail = {
            file: this.fileUpdate,
            thumnail: fileThumnail,
            type: 'video',
          }
        } else {
          this.form.myImage = {
            file: this.fileUpdate,
            type: 'image',
          }
        }
      } else {
        this.fileUpdate = event.target.files
        Array.from(this.fileUpdate)
          .slice(0, 10)
          .forEach((item) => {
            this.fileUpload.push({
              url: URL.createObjectURL(item),
              type: item.type,
            })
            this.infoCarousel.push({
              des: '',
              name: '',
              link: '',
            })
          })
      }

      if (this.form.modelFile == 1) {
        this.form.file = this.fileUpdate
        let checkType = this.fileUpdate.type.split('/')[0]
        if (checkType == 'video') {
          this.form.myImage = {}
          this.form.type = 'video'
          this.form.file = this.form.myThumbnail
        } else {
          this.form.myThumbnail = {}
          this.form.type = 'image'
        }
      } else if (this.form.modelFile == 2) {
        this.form.type = 'carousel'
        if (Array.from(this.fileUpdate).length === 0) {
          this.arrFileCarosel.push(this.fileUpdate)
        } else {
          Array.from(this.fileUpdate)
            .slice(0, 10)
            .forEach((item) => {
              this.arrFileCarosel.push(item)
            })
        }
        this.form.file = await this.getFileForCarousel()
      }
      this.fileUpload = this.fileUpload.slice(0, 10)
    },
    removeFile: async function (index) {
      this.arrFileCarosel.splice(index, 1)
      this.fileUpload.splice(index, 1)
      this.infoCarousel.splice(index, 1)
      this.form.file = await this.getFileForCarousel()
    },
    getFileForCarousel() {
      let listCarousel = []
      Array.from(this.arrFileCarosel)
        .slice(0, 10)
        .forEach(async (item, index) => {
          listCarousel.push({
            file: item,
            thumnail: item.type.split('/')[0] == 'video' ? await this.dataUrlToFile(item, `thumbnail.jpg`) : '',
            description: ``,
            type: item.type.split('/')[0] === 'video' ? 'video' : 'image',
            name: '',
            link: '',
          })
        })
      return listCarousel
    },
    handleSubmitForm(formRef) {
      let validCarousel = this.checkValidateCarousel()
      this.checkValidateFileUpload()
      this.$refs[formRef].validate(async (valid) => {
        if (valid) {
          if (validCarousel && valid) {
            if (this.form.modelFile == 2) {
              this.form.file.forEach((item, index) => {
                item.description = this.infoCarousel[index].des
                item.name = this.infoCarousel[index].name
                item.link = this.infoCarousel[index].link
              })
            }
            this.form.start_date = this.convertTimeStamp(this.since)
            this.form.end_date = this.convertTimeStamp(this.until)
            this.form.maxAge = this.ageMax
            this.form.minAge = this.ageMin
            this.isLoading = true
            try {
              const { data } = await Ins.createAds(this.form)
              if (!data.status) {
                return this.$toast.error(data.msg)
              }
              this.$toast.success(data.msg)
              this.$inertia.visit(data.redirect)
            } catch (error) {
              console.log(error)
            } finally {
              this.isLoading = false
            }
          }
        } else {
          document.getElementById('divFirst').scrollIntoView()
        }
      })
    },
  },
  mounted() {
    this.formartDate()
    this.cusDatePickerStartText()
    this.cusDatePickerEndText()
  },

  watch: {
    chooseCampains(value) {
      if (value == 1) {
        this.form.campaign_name = ''
      }
      if (value == 2) {
        this.getCampainsAccount()
        this.form.campaign_name = ''
        this.form.campaign_objective = ''
        this.checkOptimizationGoal = false
      }
    },
    'form.modelFile': function (oldVal, newVal) {
      // Save old value
      if (oldVal == 1) {
        this.oldFile = this.form.file
        this.oldFileUpload = this.fileUpload
      }

      if (oldVal == 2) {
        this.oldCarousel = this.form.file
        this.oldArrCarousel = this.arrFileCarosel
        this.oldFilesUpload = this.fileUpload
      }

      // Reset value
      this.form.file = ''
      this.arrFileCarosel = []
      this.fileUpload = []

      // Set old value
      if (newVal == 1) {
        this.form.file = this.oldFile
        this.fileUpload = this.oldFileUpload
      }

      if (newVal == 2) {
        this.form.file = this.oldCarousel
        this.arrFileCarosel = this.oldArrCarousel
        this.fileUpload = this.oldFilesUpload
      }
    },
    'form.campaign_objective': function (value) {
      if (value) {
        this.checkOptimizationGoal = true
      }
      if (value != 'LINK_CLICKS') {
        this.form.destination_type = ''
      }
      if (value == 'CONVERSIONS') {
        this.convertsions = true

        this.reach = false
        this.leadGeneration = false
        this.appInstalls = false
        this.videoViews = false
        this.pageLikes = false
        this.postEngagement = false
        this.brandAwareness = false
      } else {
        this.convertsions = false
      }
      if (value == 'REACH') {
        this.reach = true

        this.convertsions = false
        this.leadGeneration = false
        this.appInstalls = false
        this.videoViews = false
        this.pageLikes = false
        this.postEngagement = false
        this.brandAwareness = false
      } else {
        this.reach = false
      }
      if (value == 'LEAD_GENERATION') {
        this.leadGeneration = true

        this.convertsions = false
        this.reach = false
        this.appInstalls = false
        this.videoViews = false
        this.pageLikes = false
        this.postEngagement = false
        this.brandAwareness = false
      } else {
        this.leadGeneration = false
      }
      if (value == 'APP_INSTALLS') {
        this.appInstalls = true

        this.convertsions = false
        this.reach = false
        this.leadGeneration = false
        this.videoViews = false
        this.pageLikes = false
        this.postEngagement = false
        this.brandAwareness = false
      } else {
        this.appInstalls = false
      }
      if (value == 'VIDEO_VIEWS') {
        this.videoViews = true

        this.convertsions = false
        this.reach = false
        this.leadGeneration = false
        this.appInstalls = false
        this.pageLikes = false
        this.postEngagement = false
        this.brandAwareness = false
      } else {
        this.videoViews = false
      }
      if (value == 'PAGE_LIKES') {
        this.pageLikes = true

        this.convertsions = false
        this.reach = false
        this.leadGeneration = false
        this.appInstalls = false
        this.postEngagement = false
        this.brandAwareness = false
      } else {
        this.pageLikes = false
      }
      if (value == 'POST_ENGAGEMENT') {
        this.postEngagement = true

        this.convertsions = false
        this.reach = false
        this.leadGeneration = false
        this.appInstalls = false
        this.brandAwareness = false
      } else {
        this.postEngagement = false
      }
      if (value == 'BRAND_AWARENESS') {
        this.brandAwareness = true

        this.convertsions = false
        this.reach = false
        this.leadGeneration = false
        this.appInstalls = false
      } else {
        this.brandAwareness = false
      }
    },

    fileUpload: function () {
      if (this.fileUpload.length > 0) {
        this.validateFileUpload = true
      } else {
        this.validateFileUpload = false
      }
    },
    ageMin(v) {
      this.optionsAgeMax.forEach((item) => {
        if (item.value < v) {
          item.disabled = true
        } else {
          item.disabled = false
        }
      })
    },
    ageMax(v) {
      this.optionsAgeMin.forEach((item) => {
        if (item.value > v) {
          item.disabled = true
        } else {
          item.disabled = false
        }
      })
    },
    'form.modelFile': function () {
      this.form.file = ''
      this.arrFileCarosel = []
      this.fileUpload = []
      this.infoCarousel = []
    },
  },
}
</script>

<style lang="scss">
.custom-el-select__search .el-select__input {
  box-shadow: none;
}

.custom-el-select__search .el-select {
  flex: 1;
}

.custom-el-select__search .el-input__inner {
  height: 40px;
}

* {
  scroll-behavior: smooth;
}
.post-ads-dialog {
  .el-dialog {
    top: 30%;
  }
}

.post-ads-input-money {
  .el-input__inner {
    padding-left: 20px !important;
  }
}

.title img,
label img {
  margin-right: 6px;
}
.title {
  margin-top: 23px;
}

.form-wrapper_2col {
  grid-template-columns: 15% auto;
}
.form-wrapper_3col {
  grid-template-columns: max-content max-content auto;
}
.grid-4-col {
  grid-template-columns: max-content auto max-content auto;
}

.bg-ip {
  width: 283px;
  height: 571px;
  background-image: url('@shared/uploads/iPhone-screen.svg');
  filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.25));
}

.carosel .carosel-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: rgb(0, 0, 0, 0.7);
  opacity: 0;
  transition: 0.4s ease-in-out;
  border-radius: 6px;
}
.carosel .carosel-icon:hover {
  cursor: pointer;
}
.carosel:hover .carosel-icon {
  opacity: 1;
}
.carolsel-upload {
  border: 1px;
  border-style: dashed;
  border-color: gray;
}
.radio-mr-0 .el-radio {
  margin-right: 14px;
}
.form_advertisment .el-radio__label {
  font-weight: 400;
}
.form_advertisment .el-form-item__label:before {
  margin-right: 0 !important;
}
.form-carousel {
  .el-input__inner:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
.form_advertisment .el-radio__inner {
  border-radius: 0;
  padding: 9px;
  border: 1px solid #dcdfe6 !important;
}
.form_advertisment .el-radio__label {
  color: #414141 !important;
}
.form_advertisment .input-pl .el-input__inner {
  padding-left: 24px !important;
}
.form_advertisment .el-radio__input.is-checked .el-radio__inner {
  background-image: url(/images/tick.svg?01fd452…);
  background-color: #be3d8f;
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}
.form_advertisment .el-radio__input.is-checked .el-radio__inner::after {
  display: none;
}
.campains .el-form-item__content {
  gap: 10px 50px;
}
.flex-input {
  display: grid !important;
  grid-template-columns: 120px auto;
  align-items: center;
  label {
    margin: 0 !important;
  }
  .el-input__inner {
    padding-left: 2.5rem !important;
  }
  .el-input__prefix,
  .icon-absolute {
    left: 1rem !important;
  }
  .el-input {
    width: 100% !important;
  }
}

.form-link {
  display: grid !important;
  grid-template-columns: 195px auto;
  align-items: center;
}
.form-input-column .el-form-item {
  display: flex;
  margin: 0;
  align-items: center;
  padding: 10px 0;
  .el-form-item__label {
    width: 20%;
    font-size: 13px !important;
  }
  .el-form-item__content {
    flex: 1;
  }
}
.form__item-between .el-form-item__content {
  justify-content: space-between;
  flex-wrap: nowrap;
}
.input-phone-number .el-input__inner {
  padding-left: 54px !important;
}
.radio_campaign {
  width: 100%;
  display: flex;
}
.form-space-beetween {
  width: 100%;
  justify-content: space-between;
  display: flex;
}
@media (max-width: 640px) {
  .advertisment__user--info .user__nav {
    margin: 0 0.5rem !important;
  }
  .campains .el-form-item__content {
    gap: 0;
    .el-radio {
      margin: 0 !important;
    }
  }
  .form-link {
    grid-template-columns: 100%;
  }
  .advertisment__btn-submit {
    padding: 0 10px;
  }
  .form__item-between .el-form-item__content {
    flex-wrap: wrap;
    justify-content: start;
    padding-right: 0;
    .el-radio {
      width: 50%;
      margin-right: 0;
    }
  }
}
@media (max-width: 840px) {
  .advertisment__btn-submit {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .advertisment-preview {
    padding-bottom: 5rem !important;
  }
  .form__item-between .el-form-item__content {
    flex-wrap: wrap;
    padding-right: 0;
    justify-content: start;
    .el-radio {
      width: 50%;
      margin-right: 0;
    }
  }
}

.text-13 {
  font-size: 13px !important;
}

.text-14 {
  font-size: 14px !important;
}

.el-form-item__label {
  font-size: 13px !important;
}
.el-radio__label {
  font-size: 13px !important;
}
</style>
