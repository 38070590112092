<template>
    <div class="sm:px-0 md:px-10 pb-10">
        <div class="statistic sm:px-5 lg:px-10 md:px-0 rounded">
            <div class="flex justify-between items-center my-2">
                <h2 class="text-button_pink !text-[14px] font-semibold">{{ titlePage }}</h2>

                <button v-if="isRole == 'master'" :disabled="numberExportListPost <= 0 && isRole !== 'master'"
                    @click="exportListPostCsv" class="bg-black py-3 px-7 text-white !text-[14px] button-shadow rounded">
                    CSV出力
                </button>

                <el-tooltip v-else :content="'投稿一覧画面今月後 ' + numberExportListPost + ' 回CSV出力出来ます。'" placement="top">
                    <button :disabled="numberExportListPost <= 0 && isRole !== 'master'" @click="exportListPostCsv"
                        class="bg-black py-3 px-7 text-white !text-[14px] button-shadow rounded">
                        CSV出力
                    </button>
                </el-tooltip>
            </div>
            <div class="bg-white p-3">
                <!-- component -->
                <section class="mt-2.5">
                    <div class="w-full overflow-hidden shadow-lg">
                        <div class="w-full overflow-x-auto">
                            <table class="table-fixed w-full">
                                <thead>
                                    <tr class="text-2sm font-semibold text-left bg-gray-100 border-b border-gray-600">
                                        <th class="width-nomal text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2"
                                            :style="{ width: statusSelect == 0 ? '135px' : '12%' }">
                                            投稿画像
                                        </th>
                                        <th class="width-nomal text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2"
                                            :style="{ width: statusSelect == 0 ? '135px' : '12%' }">
                                            タイプ
                                        </th>
                                        <th class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2"
                                            style="width: 133px">
                                            投稿内容
                                        </th>
                                        <th class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2"
                                            v-for="(label, index) in label" :key="index" :style="{
                                                width: label.width + 'px',
                                                minWidth: (label?.minWidth ? label.minWidth : 150) + 'px',
                                            }">
                                            {{ label.text }}
                                        </th>
                                        <th v-if="statusSelect == 0"
                                            class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2"
                                            style="width: 108px">
                                            初速分析
                                        </th>
                                        <th v-if="statusSelect == 0"
                                            class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2 w-[90px]"
                                            style="width: 108px">
                                            Instaページ
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-body_table">
                                    <tr class="text-2sm border-b hover:bg-gray-200" v-for="(item, index) in tableData"
                                        :key="index">
                                        <td class="py-2 flex items-center justify-center h-[105px]">
                                            <video
                                                v-if="checkTypeFile(item?.files?.length > 0 ? item.files[0] : item.file)"
                                                controls width="112" style="height: 64px">
                                                <source :src="item?.files?.length > 0 ? item.files[0] : item.file"
                                                    type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                            <img v-else class="w-28 h-16"
                                                :src="item?.files?.length > 0 ? item.files[0] : item.file" alt="" />
                                        </td>
                                        <td class="py-2">
                                            <p class="post-content showFullText break-all">
                                                {{ item.post_category == 0 ? '通常' : 'リール' }}
                                            </p>
                                        </td>
                                        <td class="py-2">
                                            <p class="post-content showFullText break-all">
                                                {{ item.content }}
                                            </p>
                                            <p @click="viewDetailContent(item)"
                                                class="text-xs text-button_profile font-semibold text-right !text-2sm block mt-2 hover:cursor-pointer">
                                                全文を見る
                                            </p>
                                        </td>
                                        <td class="text-center py-2" v-for="(label, index) in label" :key="index">
                                            <p v-if="label.key != 'hashtag'">
                                                <span
                                                    v-if="label.key === 'created_at' && item.status === 2">下書き保存</span>
                                                <span v-else-if="label.key === 'created_at' && item.status === 1">
                                                    {{ item.scheduled_time }}
                                                </span>

                                                <span v-else>
                                                    <span v-if="label.key !== 'engagement'">{{ item[label.key] }}</span>
                                                    <span v-else>{{
                                                        item.post_category == 0 ? item[label.key] : ' - '
                                                    }}</span>
                                                </span>
                                            </p>
                                            <p class="justify-center flex flex-wrap break-all"
                                                v-if="label.key == 'hashtag'">
                                                {{ item.hashtag }}
                                            </p>
                                        </td>
                                        <td v-if="statusSelect == 0">
                                            <div v-if="this.isRole === 'master' || this.$page.props?.auth?.plan?.initial_velocity_analysis == 1"
                                                @click="jumpToElement(item)"
                                                class="rounded button-shadow px-5 py-2 bg-blue-600 text-white block mx-1 cursor-pointer">
                                                確認する
                                            </div>
                                            <div v-else
                                                class="rounded button-shadow px-5 py-2 text-white block mx-1 cursor-not-allowed"
                                                style="background-color: rgb(169 177 228)">
                                                確認する
                                            </div>
                                        </td>
                                        <td v-if="statusSelect == 0">
                                            <a :href="item.permalink" target="_blank" as="button" style="width: 70px">
                                                <div class="flex justify-center instaColor">
                                                    <i class="fab fa-instagram text-3xl cursor-pointer"></i>
                                                </div>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>

                <!-- dialog detail content -->
                <el-dialog v-model="dialogOpen" width="50%" title="投稿内容" append-to-body>
                    <PopupReviewPost :content="dataContent.content" :file="dataContent.file"
                        :fileType="getTypeFile(dataContent.file)" :hashtag="dataContent.hashtag" :tag="dataContent.tag"
                        :username="accountName" />
                </el-dialog>
                <!-- end dialog detail content -->
                <el-empty v-if="tableData.length == 0" description="データはありません" />
                <div class="pt-5 flex justify-center">
                    <el-pagination :page-size="Number(10)" :pager-count="5" layout="prev, pager, next"
                        :total="Number(totalData)" :current-page="currentPage || 1"
                        @current-change="handleCurrentChange" class="is-background" />
                </div>
            </div>
        </div>
    </div>
    <Loadding v-if="!isloading" />
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import Loadding from '@/Components/Loadding.vue'
import { Link } from '@inertiajs/inertia-vue3'
import Table from '@/Components/Table.vue'
import Pagination from '@/Components/Pagination.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import PopupReviewPost from '@/Components/PopupReviewPost.vue'
import * as postAPI from '@/Api/Posts'
import { getUserDetailByAccountId } from '@/Api/Users'
import * as accountAPI from '@/Api/Account'
import GetThumbnailUrl from '../../../../Mixins/thumbnail.js'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import { fetchNumberExport, addNumberExport } from '../../Mixins/limitExport.js'

export default {
    components: {
        Table,
        Pagination,
        DrashBoard,
        UserNav,
        Loadding,
        PopupReviewPost,
        Link,
        BreadCrumb,
        LinkInstagram,
    },

    computed: {
        isRole() {
            return this.$page.props?.auth?.user?.roles[0]?.name
        },
    },

    props: {
        userId: { type: Number },
        plan: { type: Object, require: true },
    },
    emits: ['getPostTableData', 'getDetailPost', 'getListImgPostTableData'],
    mixins: [GetThumbnailUrl],
    data() {
        return {
            numberExportListPost: 0,
            titlePage: '投稿分析',
            dataContent: [],
            dialogOpen: false,
            label: [
                { text: 'ハッシュタグ', key: 'hashtag', width: 130 },
                { text: '投稿日時', key: 'created_at', width: 140 },
                { text: 'いいね数', key: 'like', width: 125 },
                { text: 'コメント数', key: 'comment', width: 105 },
                { text: '保存数', key: 'saved', width: 105 },
                { text: 'リーチ数', key: 'reach', width: 85 },
                { text: 'エンゲージメント率', key: 'engagement', width: 150 },
                { text: '投稿形式	', key: 'posted_outside', width: 150 },
            ],
            limit: 6,
            totalPage: 1,
            totalData: 1,
            currentPage: 1,
            statusHeader: 'タイプ',
            statusSelect: 0,
            tableData: [],
            searchKeyword: '',
            isloading: true,
            isSelectStatus: false,
            accountName: '',
            showLinkInstagram: true,
        }
    },
    async created() {
        this.$watch((vm) => [vm.statusSelect, vm.currentPage], this.getListPost)
        this.$watch((vm) => [vm.statusSelect, vm.currentPage], this.getListPostForExport)
        this.getListPost(this.searchKeyword, this.statusSelect, this.currentPage)
        this.getListPostForExport(this.searchKeyword, this.statusSelect, this.currentPage)
        this.getAccountPost()
        // this.checkLinkInstagram()
        this.fetchNumberExportMonth()
    },
    methods: {
        viewDetailContent(item) {
            this.dataContent = item
            this.dialogOpen = true
        },
        async jumpToElement(item) {
            document.getElementById('post-detail-element').scrollIntoView()
            await this.viewDetail(item)
        },
        viewDetail(item) {
            this.$emit('getDetailPost', item)
        },
        formartStatus: function (status) {
            if (status == 1) {
                return '通常'
            }
            return 'リール'
        },
        formatTime(date) {
            if (!date) {
                return
            }
            let listDate = date.slice(0, 10).split('-')
            let listTime = date.slice(11, 16)
            let dateFormat = listDate[0] + '年' + listDate[1] + '月' + listDate[2] + '日' + ' ' + listTime
            return dateFormat
        },
        getListPost: async function () {
            const posts = await postAPI.getListPostAccount(this.searchKeyword, this.statusSelect, this.currentPage)
            this.tableData = posts.data.data.map((postData) => ({
                id: postData.id,
                tag: this.formartTag(postData.tag),
                permalink: postData.permalink,
                file: postData.file,
                files: postData.files,
                status: postData.status,
                hashtag: this.formartHashtag(postData),
                created_at: this.formatTime(postData.updated_at),
                like: this.formatToInteger(postData.like),
                content: postData.content,
                comment: this.formatToInteger(postData.comment),
                saved: this.formatToInteger(postData.saved),
                reach: this.formatToInteger(postData.reach),
                engagement: this.formatToFloat(((postData.engagement + Number.EPSILON) * 100) / 100 + '%'),
                posted_outside: postData.posted_outside,
                post_category: postData.post_category,
                scheduled_time: this.formatTime(postData.scheduled_time),
            }))
            this.viewDetail(this.tableData[0])
            this.totalData = posts.data.meta.total
            this.totalPage = Math.ceil(posts.data.meta.total / posts.data.meta.per_page)
            this.currentPage = posts.data.meta.current_page
        },
        getListPostForExport: async function () {
            const posts = await postAPI.listPostForExport(this.searchKeyword, this.statusSelect, this.currentPage)
            let listImgPostTableData = []
            let postTableData = Promise.all(
                posts.data.data.map(async (item, index) => {
                    listImgPostTableData.push(item.thumbnail + '?time=' + Math.floor(Date.now() / 1000))
                    return [
                        index,
                        item.post_category == 1 ? 'リール' : '通常',
                        item.content,
                        item.hashtag,
                        this.formatTime(item.updated_at),
                        item.like,
                        item.comment,
                        item.saved,
                        item.reach,
                        item.post_category == 1 ? '-' : Math.round((item.engagement + Number.EPSILON) * 100) / 100 + '%',
                    ]
                })
            )
            this.$emit('getPostTableData', postTableData)
            this.$emit('getListImgPostTableData', listImgPostTableData)
        },
        async getAccountPost() {
            const posts = await postAPI.getListPostAccount(this.searchKeyword, this.statusSelect, this.currentPage)
            if (posts.data.data.length > 0) {
                const account = await getUserDetailByAccountId(posts.data.data[0].account_id)
                this.accountName = account.data.data
            }
        },
        getHashtagDataExport(item) {
            return item?.hashtag?.replaceAll(',', ' ').slice(0, 25) + '...'
        },
        formartHashtag(item) {
            return item?.hashtag ? item?.hashtag?.replaceAll(',', ' ').slice(0, 25) + '...' : ''
        },
        formartTag(tag) {
            if (tag) {
                return tag
                    .map((tags) => '@' + tags.username)
                    .toString()
                    .replaceAll(',', ' ')
            }
            return tag
        },
        isDataArray: function (label) {
            if (label == 'hashtag' || label == 'tag' || label == 'engagement') {
                return false
            }
            return true
        },
        checkTypeFile(filePath) {
            const arr = filePath.split('.')
            if (arr[arr.length - 1] == 'mp4') {
                return true
            }
            return false
        },
        changeTab(status) {
            this.statusSelect = status
            this.currentPage = 1
        },
        getTypeFile(fileName) {
            return fileName.split('.').pop()
        },
        handleCurrentChange(value) {
            this.currentPage = value
        },

        //Export Csv HashTag
        exportListPostCsv() {
            if (this.numberExportListPost === 0) {
                return
            }
            let checkSuccess = false
            const userId = this.userId ? this.userId : this.$page?.props?.auth?.user?.id

            const url =
                route('api.export.list.post.csv') + '?user_id=' + userId + '&limit=' + this.limit + '&page=' + this.currentPage
            window.location.href = url

            checkSuccess = true

            if (checkSuccess && this.isRole !== 'master') {
                addNumberExport('csv', 'csv_list_post')
                this.fetchNumberExportMonth()
            }
        },

        async fetchNumberExportMonth() {
            const { data } = await fetchNumberExport('csv', 'csv_list_post')
            this.numberExportListPost = this.plan?.number_report_csv - data
        },
    },
    watch: {
        searchKeyword: function () {
            if (this.searchKeyword == '') {
                this.getListPost()
                this.getListPostForExport()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
th {
    ul {
        padding: 0;
        top: 120%;
        border: 1px solid #ebebeb;

        &:hover {
            cursor: pointer;
        }

        li {
            padding: 0.5rem 0;
            transition: all 0.3s;
            color: #020202;

            &:hover {
                color: #fff;
                background: #5264cd;
                cursor: pointer;
            }
        }
    }
}

.width-nomal {
    width: 12%;
}

@media (max-width: 1024px) {
    .width-nomal {
        width: 130px;
    }

    th:last-child {
        width: 120px !important;
    }
}
</style>

<style>
/* .el-message-box {
  width: 50%;
} */
.el-overlay {
    opacity: 1;
}

.showFullText {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

.instaColor {
    color: #be3d8f;
}
</style>
