import { formatToInteger } from '@/Plugins/numberFormat'
export default {
  methods: {
    genCompeteAccountPage(doc) {
      doc.setFontSize(14) // set font size
      doc.setTextColor(190, 61, 143)
      doc.text('競合アカウント分析', 13, 30)
      doc.text('競合アカウント分析', 13, 30)
      doc.text('競合アカウント分析', 13, 30)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(10)

      //---------------------------draw-chart---------------------------
      const pageWidth = doc.internal.pageSize.getWidth()
      const pageHeight = doc.internal.pageSize.getHeight()

      // account table 1
      doc.text('競合アカウント分析', 13, 40)
      doc.autoTable({
        styles: {
          font: 'GenShinGothic',
          fontSize: 8,
          halign: 'center',
          valign: 'middle',
        },
        startY: 45,
        head: [['フォロワー数', 'フォロワー純増減数', 'フィード投稿数']],
        body: [this.mapDataCompeteAcc(this.competeTotalData, this.dataFirstDayAcc)],
      })

      //---------------------------compete-account-line-chart---------------------------
      const competeAccountLineChart = document.getElementById('compete-analytic-chart-v1')
      const competeAccountLineChartCanvas = competeAccountLineChart ? competeAccountLineChart.childNodes[0] : null
      const competeAccountLineChartCanvasWRatio = (pageWidth / 1536) * 0.9
      const competeAccountLineChartCanvasHRatio = (pageHeight / 600) * 0.9
      const accRatio =
        competeAccountLineChartCanvasWRatio > competeAccountLineChartCanvasHRatio
          ? competeAccountLineChartCanvasHRatio
          : competeAccountLineChartCanvasWRatio
      const competeAccountLineChartCanvasWidth = 1536 * accRatio
      const competeAccountLineChartCanvasHeight = 600 * accRatio

      if (competeAccountLineChartCanvas) {
        doc.addImage(
          competeAccountLineChartCanvas,
          'PNG',
          10,
          60,
          competeAccountLineChartCanvasWidth,
          competeAccountLineChartCanvasHeight
        )
      }
      //---------------------------compete-follow-line-chart---------------------------
      const competeFollowLineChart = document.getElementById('compete-analytic-chart-v2')
      const competeFollowLineChartCanvas = competeFollowLineChart ? competeFollowLineChart.childNodes[0] : null
      const competeFollowLineChartCanvasWRatio = (pageWidth / 1536) * 0.9
      const competeFollowLineChartCanvasHRatio = (pageHeight / 600) * 0.9
      const accFollowerRatio =
        competeFollowLineChartCanvasWRatio > competeFollowLineChartCanvasHRatio
          ? competeFollowLineChartCanvasHRatio
          : competeFollowLineChartCanvasWRatio
      const competeFollowLineChartCanvasWidth = 1536 * accFollowerRatio
      const competeFollowLineChartCanvasHeight = 600 * accFollowerRatio

      if (competeFollowLineChartCanvas) {
        doc.text(
          'アカウント推移( ' + this.mapCurrTab(this.accTransitionTab) + ')',
          13,
          competeFollowLineChartCanvasHeight + 65
        )
        doc.addImage(
          competeFollowLineChartCanvas,
          'PNG',
          10,
          competeFollowLineChartCanvasHeight + 70,
          competeFollowLineChartCanvasWidth,
          competeFollowLineChartCanvasHeight
        )
      }
      //---------------------------table-chart---------------------------
      doc.addPage()
      let arrChart = document.getElementsByClassName('compete-analytic-chart-v3')
      let dataCompeteFollowerMap = this.mapCompeteAccData(this.competeAccData)
      let listChart = []
      for (const [index, item] of Object.entries(arrChart)) {
        listChart.push(item.childNodes[0])
      }
      const mapCompeteFollowerData = JSON.parse(JSON.stringify(dataCompeteFollowerMap))
      doc.text('アカウント比較', 13, 40)
      if (dataCompeteFollowerMap.length <= 9) {
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 30 },
            1: { cellWidth: 20 },
            2: { cellWidth: 40 },
            3: { cellWidth: 20 },
          },
          didDrawCell: function (data) {
            if (data.column.index === 4 && data.cell.section === 'body') {
              var textPos = data.cell
              doc.addImage(listChart[data.row.index], 'PNG', textPos.x + 1, textPos.y + 1, 70, 20)
            }
          },
          bodyStyles: { minCellHeight: 24 },
          startY: 45,
          head: [['サムネイル', 'フォロワー数', 'フォロワー増減', '直近7日間のフィード投稿数', 'フォロワー数推移']],
          body: mapCompeteFollowerData,
        })
      } else {
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 30 },
            1: { cellWidth: 20 },
            2: { cellWidth: 40 },
            3: { cellWidth: 20 },
          },
          didDrawCell: function (data) {
            if (data.column.index === 4 && data.cell.section === 'body') {
              var textPos = data.cell
              doc.addImage(listChart[data.row.index], 'PNG', textPos.x + 1, textPos.y + 1, 70, 20)
            }
          },
          bodyStyles: { minCellHeight: 24 },
          startY: 45,
          head: [['サムネイル', 'フォロワー数', 'フォロワー増減', '直近7日間のフィード投稿数', 'フォロワー数推移']],
          body: mapCompeteFollowerData.slice(0, 9),
        })
        doc.addPage()
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 30 },
            1: { cellWidth: 20 },
            2: { cellWidth: 40 },
            3: { cellWidth: 20 },
          },
          didDrawCell: function (data) {
            if (data.column.index === 4 && data.cell.section === 'body') {
              var textPos = data.cell
              doc.addImage(listChart[data.row.index + 9], 'PNG', textPos.x + 1, textPos.y + 1, 70, 20)
            }
          },
          bodyStyles: { minCellHeight: 24 },
          startY: 40,
          head: [['サムネイル', 'フォロワー数', 'フォロワー増減', '直近7日間のフィード投稿数', 'フォロワー数推移']],
          body: mapCompeteFollowerData.slice(9),
        })
      }
    },
    mapCompeteAccData(data) {
      let dataMap = []
      data.forEach((item) => {
        let arr = []
        arr.push(item.fullname)
        arr.push(formatToInteger(item.followers))
        arr.push(this.calcData(item))
        arr.push(formatToInteger(item.number_post_last_week))
        dataMap.push(arr)
      })
      return dataMap
    },
    calculatorPercen(row) {
      if (row) {
        let result = (this.calculator(row) / row?.followers) * 100
        result = result.toFixed(2)
        if (result == '-0.00') {
          return (result = '0.00')
        }

        result = result > 0 ? '+' + result : result
        return result
      }
    },
    calculator(row) {
      let temp = row?.old_followers ? row.old_followers : 0
      return row?.followers - temp
    },
    calcData(item) {
      let dataNumChange = this.calculator(item) ? this.calculator(item) : 0
      let dataAvgChange = this.calculatorPercen(item) + '%'
      return formatToInteger(dataNumChange) + ' (' + dataAvgChange + ')'
    },
    mapDataCompeteAcc(data, dataFirstDay) {
      return [
        data[0]?.followers ? formatToInteger(data[0].followers) : '-',
        this.followerChange(data, dataFirstDay) > 0
          ? '+' + formatToInteger(this.followerChange(data, dataFirstDay))
          : formatToInteger(this.followerChange(data, dataFirstDay)),
        data[0]?.medias ? formatToInteger(data[0].medias) : '-',
      ]
    },
    followerChange(data, dataFirstDay) {
      const lastDaySelected = data[0]?.followers ?? 0
      const lastDayPrevious = dataFirstDay?.followers ?? 0
      const followers = lastDaySelected - lastDayPrevious
      return followers
    },
    mapCurrTab(item) {
      switch (item) {
        case 'follow':
          return 'フォロワー数'
          break
        case 'change_follow':
          return 'フォロワー増減'
          break
        case 'post':
          return 'フィード投稿数'
          break
        default:
          return 'フィード投稿増減'
      }
    },
  },
}
