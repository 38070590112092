<template>
  <div class="sm:px-0 md:px-10 pb-10">
    <div class="statistic lg:px-10 md:px-0 sm:px-5 bg-background">
      <div class="flex justify-between items-center">
        <h2 class="text-xl text-button_pink font-semibold">ストーリーズ分析</h2>

        <button
          v-if="isRole == 'master'"
          :disabled="numberExportStory <= 0 && isRole !== 'master'"
          @click="exportStoryCsv"
          class="bg-black ml-3 py-3 px-7 text-white !text-[14px] button-shadow rounded"
        >
          CSV出力
        </button>

        <el-tooltip
          v-else
          class="tooltip-customer"
          effect="dark"
          :content="'ストーリーズ分析画面今月後 ' + numberExportStory + ' 回CSV出力出来ます。'"
          placement="top"
        >
          <button
            :disabled="numberExportStory <= 0 && isRole !== 'master'"
            @click="exportStoryCsv"
            class="bg-black ml-3 py-3 px-7 text-white !text-[14px] button-shadow rounded"
          >
            CSV出力
          </button>
        </el-tooltip>
      </div>
      <div class="dv-statistical grid md:grid-cols-3 sm:grid-cols-1 gap-7 pt-2 pb-10">
        <div class="bg-white rounded h-40 p-5 border border-gray-200 relative">
          <div class="text-link_pink text-base !text-[14px] font-bold">ストーリーズ投稿数</div>
          <div class="flex justify-between absolute bottom-5 w-90%">
            <p class="!text-2sm">合計</p>
            <p class="font-bold !text-2sm text-gray-600">{{ storiesAnalysis.storiesTotal }}</p>
          </div>
        </div>
        <div class="bg-white rounded h-40 p-5 border border-gray-200 relative">
          <div class="text-link_pink text-base !text-[14px] font-bold">インプレッション数</div>
          <div class="flex justify-between absolute bottom-14 w-90%">
            <p class="!text-2sm">合計</p>
            <p class="font-bold !text-2sm text-gray-600">{{ storiesAnalysis.impressionsTotal }}</p>
          </div>
          <div class="flex justify-between absolute bottom-5 w-90%">
            <p class="!text-2sm">投稿平均</p>
            <p class="font-bold !text-2sm text-gray-600">{{ storiesAnalysis.impressionsAvg }}</p>
          </div>
        </div>
        <div class="bg-white rounded h-40 p-5 border border-gray-200 relative">
          <div class="text-link_pink text-base !text-[14px] font-bold">リーチ数</div>
          <div class="flex justify-between absolute bottom-14 w-90%">
            <p class="!text-2sm">合計</p>
            <p class="font-bold !text-2sm text-gray-600">{{ storiesAnalysis.reachTotal }}</p>
          </div>
          <div class="flex justify-between absolute bottom-5 w-90%">
            <p class="!text-2sm">投稿平均</p>
            <p class="font-bold !text-2sm text-gray-600">{{ storiesAnalysis.reachAvg }}</p>
          </div>
        </div>
      </div>
      <!-- Stories post list -->
      <div class="bg-white p-5">
        <h3 class="text-link_pink text-base !text-[14px] font-bold mb-2">ストーリーズ投稿一覧</h3>
          <div class="flex flex-col md:flex-row">
            <div class="flex">
              <el-input
              type="text"
              v-model="filter.caption"
              placeholder="検索キーワードを入力してください"
              class="w-full md:!w-96"
              size="large"
              clearable
              @change="getStoriesList"
              @keyup.enter="getStoriesList"
              />
              <el-button @click.prevent="getStoriesList" class="el-button__primary w-20 ml-4" size="large">
                <span>検索</span>
              </el-button>
            </div>
            <div class="sm:pl-0 md:pl-5 sm:mt-2 md:mt-0 md:w-1/2 flex items-center">
              <span class="text-gray-600 text-2sm">表示件数: {{ stories?.meta?.total || 0 }}件</span>
            </div>
          </div>
        <data-table
          :fields="fields"
          :items="stories.data"
          :paginate="stories.meta"
          :current-page="filter.page || 1"
          :tableRowClassName="'h-[340px]'"
          disable-table-info
          footer-center
          paginate-background
          @page="handleCurrentPage"
          class="el-table-header__black mt-5 rou"
        >
          <template #thumbnail_url="{ row }">
            <el-image :src="row?.thumbnail_url" lazy />
          </template>

          <!-- created_time -->
          <template #created_time="{ row }">
            <span>{{ formatTime(row?.created_time) }}</span>
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import DrashBoard from '@/Layouts/Authenticated.vue'
import DataTable from '@/Components/UI/DataTable.vue'
import { chartOptions, chartData } from './untils/initData'
import UserNav from '@/Components/UserNav.vue'
import Chart from './Chart.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import dayjs from 'dayjs'
import { fetchNumberExport, addNumberExport } from '../Mixins/limitExport.js'

export default {
  components: { DrashBoard, DataTable, Link, BreadCrumb, Chart, UserNav, LinkInstagram },
  props: {
    userId: { type: Number },
    plan: { type: Object, require: true },
  },
  emits: ['getTableStoryData', 'getlistImgTableStoryData', 'getStoriesAnalysis'],

  computed: {
    isRole() {
      return this.$page.props?.auth?.user?.roles[0]?.name
    },
  },

  data() {
    return {
      numberExportStory: 0,
      loading: false,
      showLinkInstagram: true,
      filter: {
        caption: '',
        page: 1,
        limit: 6,
      },
      stories: [],
      fields: [
        { key: 'thumbnail_url', label: 'サムネイル', width: 200, align: 'center' },
        { key: 'caption', label: '投稿内容', width: 250, align: 'center' },
        { key: 'created_time', label: '投稿日時', width: 170, align: 'center' },
        { key: 'impressions', label: 'インプ', width: 170, align: 'center' },
        { key: 'reach', label: 'リーチ', width: 170, align: 'center' },
        { key: 'back_next', label: '戻る・次へ', width: 170, align: 'center' },
        { key: 'browsing_completion_rate', label: '閲覧完了率', width: 'full', align: 'center' },
      ],
      isShowChart: false,
      chartData: chartData,
      chartOptions: chartOptions,
      storiesAnalysis: {
        impressionsTotal: 0,
        impressionsAvg: 0,
        reachAvg: 0,
        reachTotal: 0,
        storiesTotal: 0,
      },
      storyIdSelect: '',
      isGetStories: true,
    }
  },
  async created() {
    await this.fetchData()
    this.fetchNumberExportMonth()
  },
  methods: {
    handleCurrentPage(value) {
      this.filter.page = value
      this.isGetStories = true
      this.fetchData()
    },
    async fetchData() {
      await this.getStoriesAnalysis()
      await this.getStoriesList()
    },
    async getStoriesAnalysis() {
      await axios
        .get(route('api.story-analysis'))
        .then((response) => {
          this.storiesAnalysis = response?.status == 200 ? { ...response.data } : this.storiesAnalysis
          this.$emit('getStoriesAnalysis', this.storiesAnalysis)
        })
        .catch((e) => {})
    },
    async getStoriesList() {
      await axios
        .get(
          route('api.story-list', { page: this.filter?.page, caption: this.filter?.caption, limit: this.filter?.limit })
        )
        .then((response) => {
          this.stories = response?.status == 200 ? response.data : []
                    if (this.stories?.data.length > 0) {
            this.storyIdSelect = this.stories.data[0].id
            this.getStoryInsights(this.storyIdSelect)
          } else {
            this.storyIdSelect = ''
            this.chartData.labels = []
            this.chartData.datasets[0].data = []
            this.chartData.datasets[1].data = []
            this.chartData.datasets[2].data = []
            this.chartData.datasets[3].data = []
          }
          let listImgTableStoryData = []
          let getTableStoryData = response.data.data.map((item, index) => {
          listImgTableStoryData.push(item.thumbnail_url)
            return [
              index,
              item.caption,
              this.formatTimeStory(item.created_time),
              item.impressions,
              item.reach,
              item.back_next,
              item.browsing_completion_rate,
            ]
          })
          this.$emit('getTableStoryData', getTableStoryData)
          this.$emit('getlistImgTableStoryData', listImgTableStoryData)
        })
        .catch((e) => {})
    },
    formatTimeStory(date) {
        let d = new Date(date * 1000);
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const day = d.getDate();
        const hours = d.getHours();
        const minutes = d.getMinutes();
        const formattedDate = `${year}年${month}月${day}日 ${hours}:${minutes}`;

        return formattedDate
    },
    async getStoryInsights(storyId) {
      if (!storyId) return
      this.storyIdSelect = storyId

      await axios
        .get(route('api.story-insights', { story_id: storyId }))
        .then((response) => {
          let created_time = response.data.map((story) => story.created_time)
          created_time = created_time.map((item) => item.split('-'))

          const reach = response.data.map((story) => story.reach)
          const diff_reach = response.data.map((story) => story.diff_reach)
          const impressions = response.data.map((story) => story.impressions)
          const diff_imp = response.data.map((story) => story.diff_imp)

          // set Y-axis graph max
          const impressionMax = Number(Math.max(...impressions))
          const reachMax = Number(Math.max(...reach))
          let yMaxA = impressionMax > reachMax ? impressionMax : reachMax
          yMaxA = Math.round(yMaxA / 10 + 1) * 10

          const diffReachMax = Number(Math.max(...diff_reach))
          const diffImpMax = Number(Math.max(...diff_imp))
          let yMaxB = diffReachMax > diffImpMax ? diffReachMax : diffImpMax
          yMaxB = Math.round(yMaxB / 10 + 1) * 10

          this.setMaxYAxisGraph(yMaxA, yMaxB)

          // set data for chart
          this.chartData.labels = created_time
          this.chartData.datasets[0].data = reach
          this.chartData.datasets[1].data = impressions
          this.chartData.datasets[2].data = diff_reach
          this.chartData.datasets[3].data = diff_imp

          this.isShowChart = true

          if (!this.isGetStories) {
            this.scrollToChart()
          }
          this.isGetStories = false
        })
        .catch((e) => {
          this.isShowChart = false
        })
    },
    formatTime(date) {
      if (!date) {
        return
      }
      const created_time = new Date(date * 1000)

      let day = created_time.getDate()
      let month = created_time.getMonth() + 1
      let year = created_time.getFullYear()
      let hour = created_time.getHours()
      let minute = (created_time.getMinutes() < 10 ? '0' : '') + created_time.getMinutes()

      return `${year}年${month}月${day}日 ${hour}:${minute}`
    },
    scrollToChart() {
      document.getElementById('chart-wrapper').scrollIntoView({
        behavior: 'smooth',
      })
    },
    setMaxYAxisGraph(yMaxA, yMaxB) {
      this.chartOptions.scales.A.max = yMaxA
      this.chartOptions.scales.A.ticks.stepSize = yMaxA / 10
      this.chartOptions.scales.B.max = yMaxB
      this.chartOptions.scales.B.ticks.stepSize = yMaxB / 10
    },
    //export Story Csv
    async exportStoryCsv() {
      if (this.numberExportStory === 0) {
        return
      }

      const userId = this.userId ? this.userId : this.$page?.props?.auth?.user?.id
      const url =
        route('api.export.story.csv') +
        '?user_id=' +
        userId +
        '&limit=' +
        this.filter.limit +
        '&page=' +
        this.filter.page
      window.location.href = url

      if (this.isRole !== 'master') {
        addNumberExport('csv', 'csv_story')
        this.fetchNumberExportMonth()
      }
    },

    async fetchNumberExportMonth() {
      const { data } = await fetchNumberExport('csv', 'csv_story')
      this.numberExportStory = this.plan?.number_report_csv - data
    },
  },
}
</script>

<style lang="scss" scoped>
.el-button__primary {
  background-color: #5264cd;
  border: 1px solid #5264cd;
}

.el-button__primary span {
  color: white !important;
}
</style>
