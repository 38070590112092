<template>
    <div class="md:px-10 sm:px-5 el__input-height">
        <div>
            <div class="flex justify-between items-center my-4">
                <div class="groupName title-md">グループ名： {{ group.name }}</div>
            </div>
        </div>
    </div>
    <div class="md:px-8 sm:px-5 pb-2 rounded el__input-height">
        <div class="pb-6 flex justify-between md:flex-row sm:flex-col">
            <div class="flex">
                <div class="flex flex-col">
                    <el-select :multiple-limit="2" multiple v-model="arrBusinessId" class="md:m-2 w-full"
                        placeholder="アカウントを選択してください" size="large">
                        <el-option v-for="item in group.accounts" :key="item.id" :label="item.name"
                            :value="item.business_id" />
                    </el-select>
                    <span class="md:mx-4 mt-1 text-nomal whitespace-nowrap">比較したいアカウントを２名指定してください</span>
                </div>
                <el-button size="large" class="md:mt-2 ml-5 bg-blue-600 el-button_background" @click="handleSubmit()">
                    <span class="text-white">送信</span>
                </el-button>
            </div>
            <div class="flex items-center clear-picker__hidden md:mt-0 sm:mt-2">
                <el-date-picker v-model="year" type="year" palaceholder="Pick a year" size="large" :clearable="false"
                    :disabled-date="disabledDate" popper-class="customYearPicker" />
            </div>
        </div>
    </div>
    <div class="md:px-10 sm:px-5 pb-14 rounded">
        <bar-chart :titte="'フォロワー比較'" :chartData="dataAccountFollow"></bar-chart>
    </div>
    <div class="md:px-10 sm:px-5 pb-14 rounded">
        <bar-chart :titte="'いいね比較'" :chartData="dataAccountLikes"></bar-chart>
    </div>
    <div class="md:px-10 sm:px-5 pb-14 rounded">
        <bar-chart :titte="'保存比較'" :chartData="dataAccountSaved"></bar-chart>
    </div>
    <div class="md:px-10 sm:px-5 pb-14 rounded">
        <bar-chart :titte="'コメント数比較'" :chartData="dataAccountComments"></bar-chart>
    </div>
    <div class="md:px-10 sm:px-5 pb-14 rounded">
        <bar-chart :titte="'エンゲージメント比較'" :chartData="dataAccountImpressions"></bar-chart>
    </div>
    <div class="md:px-10 sm:px-5 pb-14 rounded">
        <bar-chart :titte="'リーチ比較'" :chartData="dataAccountReach"></bar-chart>
    </div>
</template>
<script>
import Dashboard from '@/Layouts/Authenticated.vue'
import Notification from '@/Components/Notifications.vue'
import Table from '@/Components/Table.vue'
import Datepicker from '@/Components/Datepicker.vue'
import BarChart from '../Account/Charts/BarChart.vue'
import PieChart from '../Account/Charts/PieChart.vue'
import StackBarChart from '../Account/Charts/StackBarChart.vue'
import HorizontalChart from '../Account/Charts/HorizonBarChat/Basic.vue'
import { Link } from '@inertiajs/inertia-vue3'
import Basic from '../Account/Charts/Tables/Basic.vue'
import Follower from '../Account/Charts/Tables/Follower.vue'
import Hashtag from '../Account/Charts/Tables/Hashtag.vue'
import Detail from '../Account/Charts/Tables/Detail.vue'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import * as accountAPI from '@/Api/Account'
import { getDataMultipleMember } from '@/Api/Enterprise'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    ArcElement,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
} from 'chart.js'
import Ins from '@/Services/Ins'
import { isArray } from 'lodash-es'

ChartJS.register(Title, Tooltip, Legend, LineElement, ArcElement, BarElement, CategoryScale, LinearScale, PointElement)
export default {
    name: 'GroupAnalysis',
    components: {
        Dashboard,
        Notification,
        BarChart,
        PieChart,
        StackBarChart,
        HorizontalChart,
        Table,
        Datepicker,
        Link,
        Basic,
        Follower,
        Hashtag,
        Detail,
        LinkInstagram,
    },

    props: {
        group: {
            type: Object,
            require: true,
        },
        labels: {
            type: Array,
            default: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        },
    },

    data() {
        return {
            accountId: '',
            year: new Date(),
            lineTab: 'all',
            current_tab: 'tab-1',
            deleteSelect: false,
            isShowChart: true,
            arrBusinessId: [],
            dataAccountFollow: {
                labels: this.labels,
                datasets: [],
            },
            dataAccountComments: {
                labels: this.labels,
                datasets: [],
            },
            dataAccountLikes: {
                labels: this.labels,
                datasets: [],
            },
            dataAccountSaved: {
                labels: this.labels,
                datasets: [],
            },
            dataAccountImpressions: {
                labels: this.labels,
                datasets: [],
            },
            dataAccountReach: {
                labels: this.labels,
                datasets: [],
            },
        }
    },

    watch: {
        year: function () {
            this.handleSubmit()
        },
    },
    mounted() {
        this.customDatePickerText()
    },
    methods: {
        disabledDate(time) {
            let d = new Date()
            return time.getTime() > d.setDate(d.getDate() - 1)
        },
        async handleSubmit() {
            await getDataMultipleMember(this.arrBusinessId, this.year, this.group.id)
                .then(async (res) => {
                    let name1 = res.data.user1.name
                    let name2 = res.data.user2.name
                    // map data follow
                    this.dataAccountFollow.datasets = [
                        {
                            label: name1,
                            backgroundColor: ['#C4C4C4', '#C4C4C4'],
                            data: this.mapDataAccount(res.data.user1, 'sumFollow'),
                        },
                        {
                            label: name2,
                            backgroundColor: ['#00BDD7', '#00BDD7'],
                            data: this.mapDataAccount(res.data.user2, 'sumFollow'),
                        },
                    ]

                    // map data comment
                    this.dataAccountComments.datasets = [
                        {
                            label: name1,
                            backgroundColor: ['#C4C4C4', '#C4C4C4'],
                            data: this.mapDataAccount(res.data.user1, 'sumComments'),
                        },
                        {
                            label: name2,
                            backgroundColor: ['#00BDD7', '#00BDD7'],
                            data: this.mapDataAccount(res.data.user2, 'sumComments'),
                        },
                    ]
                    // map data likes
                    this.dataAccountLikes.datasets = [
                        {
                            label: name1,
                            backgroundColor: ['#C4C4C4', '#C4C4C4'],
                            data: this.mapDataAccount(res.data.user1, 'sumLikes'),
                        },
                        {
                            label: name2,
                            backgroundColor: ['#00BDD7', '#00BDD7'],
                            data: this.mapDataAccount(res.data.user2, 'sumLikes'),
                        },
                    ]
                    // map data likes
                    this.dataAccountSaved.datasets = [
                        {
                            label: name1,
                            backgroundColor: ['#C4C4C4', '#C4C4C4'],
                            data: this.mapDataAccount(res.data.user1, 'sumSaved'),
                        },
                        {
                            label: name2,
                            backgroundColor: ['#00BDD7', '#00BDD7'],
                            data: this.mapDataAccount(res.data.user2, 'sumSaved'),
                        },
                    ]
                    // map data impressions
                    this.dataAccountImpressions.datasets = [
                        {
                            label: name1,
                            backgroundColor: ['#C4C4C4', '#C4C4C4'],
                            data: this.mapDataAccount(res.data.user1, 'sumEngagement'),
                        },
                        {
                            label: name2,
                            backgroundColor: ['#00BDD7', '#00BDD7'],
                            data: this.mapDataAccount(res.data.user2, 'sumEngagement'),
                        },
                    ]
                    // map data reach
                    this.dataAccountReach.datasets = [
                        {
                            label: name1,
                            backgroundColor: ['#C4C4C4', '#C4C4C4'],
                            data: this.mapDataAccount(res.data.user1, 'sumReach'),
                        },
                        {
                            label: name2,
                            backgroundColor: ['#00BDD7', '#00BDD7'],
                            data: this.mapDataAccount(res.data.user2, 'sumReach'),
                        },
                    ]
                })
                .catch(() => { })
        },

        mapDataAccount(user, keyword) {
            let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            delete user.name
            for (const [key, value] of Object.entries(user)) {
                let month = value.month
                data[month - 1] = value[keyword] ? value[keyword] : 0
            }
            return data
        },
        customDatePickerText() {
            let yearPicker = document.getElementsByClassName('customYearPicker')
            let btnPreYear = yearPicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0]
            const preYearNode = document.createTextNode("<<前年");
            btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0]);
            btnPreYear.style.cssText += 'margin: 0';

            let btnNextYear = yearPicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[4]
            const nextYearNode = document.createTextNode("翌年>>");
            btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0]);
            btnNextYear.style.cssText += 'margin: 0';
        }
    },
}
</script>
<style scoped>
table {
    border-collapse: collapse;
}

.user-tags {
    border-radius: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.search_icon {
    padding: 10px 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #d02d92;
}

.select__status {
    background: #ffffff;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
}

.el-button_background {
    background: #5264cd;
}
</style>
<style>
.clear-picker__hidden .el-input__inner {
    padding-left: 40px !important;
}
</style>
