import { formatToInteger } from '@/Plugins/numberFormat'

export default {
  methods: {
    genHashtagAccountPage(doc) {
      doc.setFontSize(14)
      doc.setTextColor(190, 61, 143)
      doc.text('ハッシュタグ分析', 13, 30)
      doc.text('ハッシュタグ分析', 13, 30)
      doc.text('ハッシュタグ分析', 13, 30)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(10)
      const margin = {
        left: 15,
        right: 15,
        top: 20,
        bottom: 20,
      }
      const printWidht = doc.internal.pageSize.width - (margin.left + margin.right)
      const sectionWidth = (printWidht - 10) / 3
      if (this.hashtagCurrTab === 'normal') {
        doc.text('通常投稿', 13, 40)
        doc.text('通常投稿', 13, 40)
        //----------------------------------------------post-feed----------------------------------------------
        let dataRankingPost = this.mapPostRankingData(this.tablePostRankingData)
        doc.autoTable({
          tableWidth: sectionWidth,
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          startY: 45,

          head: [['順位', 'ハッシュタグ名', 'インプ']],
          body: dataRankingPost[0],
        })
        doc.autoTable({
          tableWidth: sectionWidth,
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          startY: 45,
          margin: {
            left: sectionWidth + 20,
          },
          head: [['順位', 'ハッシュタグ名', 'いいね数']],
          body: dataRankingPost[1],
        })
        doc.autoTable({
          tableWidth: sectionWidth,
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          startY: 45,
          margin: {
            left: 2 * (sectionWidth + 5) + 15,
          },

          head: [['順位', 'ハッシュタグ名', '使用回数']],
          body: dataRankingPost[2],
        })
        const tablePostHashtagDataMap = JSON.parse(JSON.stringify(this.tablePostHashtagData))

        if (this.tablePostHashtagData.length > 25) {
          doc.autoTable({
            styles: {
              font: 'GenShinGothic',
              fontSize: 8,
              halign: 'center',
              valign: 'middle',
            },
            startY: 100,
            head: [
              [
                'ハッシュタグ',
                '使用回数',
                'いいね数',
                'リーチ数',
                'コメント数',
                '保存数',
                'インプレッション数',
                'エンゲージメント率',
              ],
            ],
            body: tablePostHashtagDataMap.slice(0, 25),
          })
          doc.addPage()
          doc.autoTable({
            styles: {
              font: 'GenShinGothic',
              fontSize: 8,
              halign: 'center',
              valign: 'middle',
            },
            startY: 45,
            head: [
              [
                'ハッシュタグ',
                '使用回数',
                'いいね数',
                'リーチ数',
                'コメント数',
                '保存数',
                'インプレッション数',
                'エンゲージメント率',
              ],
            ],
            body: tablePostHashtagDataMap.slice(25),
          })
        } else if (this.tablePostHashtagData.length <= 25) {
          doc.autoTable({
            styles: {
              font: 'GenShinGothic',
              fontSize: 8,
              halign: 'center',
              valign: 'middle',
            },
            startY: 100,
            head: [
              [
                'ハッシュタグ',
                '使用回数',
                'いいね数',
                'リーチ数',
                'コメント数',
                '保存数',
                'インプレッション数',
                'エンゲージメント率',
              ],
            ],
            body: this.tablePostHashtagData,
          })
        }
      } else {
        doc.text('リール投稿', 13, 40)
        doc.text('リール投稿', 13, 40)
        //----------------------------------------------post-reel----------------------------------------------
        let dataRankingReel = this.mapReelRankingData(this.tableReelRankingData)
        doc.autoTable({
          tableWidth: sectionWidth,
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          startY: 45,

          head: [['順位', 'ハッシュタグ名', 'コメント数']],
          body: dataRankingReel[0],
        })
        doc.autoTable({
          tableWidth: sectionWidth,
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          startY: 45,
          margin: {
            left: sectionWidth + 20,
          },
          head: [['順位', 'ハッシュタグ名', 'いいね数']],
          body: dataRankingReel[1],
        })
        doc.autoTable({
          tableWidth: sectionWidth,
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          startY: 45,
          margin: {
            left: 2 * (sectionWidth + 5) + 15,
          },

          head: [['順位', 'ハッシュタグ名', '使用回数']],
          body: dataRankingReel[2],
        })
        const tableReelHashtagDataMap = JSON.parse(JSON.stringify(this.tableReelHashtagData))

        if (this.tableReelHashtagData.length > 25) {
          doc.autoTable({
            styles: {
              font: 'GenShinGothic',
              fontSize: 8,
              halign: 'center',
              valign: 'middle',
            },
            startY: 100,
            head: [['ハッシュタグ', '使用回数', 'いいね数', 'リーチ数', 'コメント数', '保存数', 'エンゲージメント数']],
            body: tableReelHashtagDataMap.slice(0, 25),
          })
          doc.addPage()
          doc.autoTable({
            styles: {
              font: 'GenShinGothic',
              fontSize: 8,
              halign: 'center',
              valign: 'middle',
            },
            startY: 40,
            head: [['ハッシュタグ', '使用回数', 'いいね数', 'リーチ数', 'コメント数', '保存数', 'エンゲージメント数']],
            body: tableReelHashtagDataMap.splice(25),
          })
        } else if (this.tableReelHashtagData.length <= 25) {
          doc.autoTable({
            styles: {
              font: 'GenShinGothic',
              fontSize: 8,
              halign: 'center',
              valign: 'middle',
            },
            startY: 100,
            head: [['ハッシュタグ', '使用回数', 'いいね数', 'リーチ数', 'コメント数', '保存数', 'エンゲージメント数']],
            body: this.tableReelHashtagData,
          })
        }
      }
    },
    mapPostRankingData(data) {
      let impressions = data.impressions.map((item, index) => {
        return [index + 1 + '位', item.name, this.formatToInteger(item.sumImpressions)]
      })
      let likes = data.likes.map((item, index) => {
        return [index + 1 + '位', item.name, this.formatToInteger(item.sumLikes)]
      })
      let posts = data.posts.map((item, index) => {
        return [index + 1 + '位', item.name, this.formatToInteger(item.sumPost)]
      })
      return [impressions, likes, posts]
    },
    mapReelRankingData(data) {
      let comments = data.comments.map((item, index) => {
        return [index + 1 + '位', item.name, this.formatToInteger(item.sumComments)]
      })
      let likes = data.likes.map((item, index) => {
        return [index + 1 + '位', item.name, this.formatToInteger(item.sumLikes)]
      })
      let posts = data.posts.map((item, index) => {
        return [index + 1 + '位', item.name, this.formatToInteger(item.sumPost)]
      })
      return [comments, likes, posts]
    },
  },
}
