<template>
  <div class="flex flex-col h-full">
    <h2 style="font-size : 14px" class="title-md pb-12.5 text-center">{{ this.$i18n.t('Preview') }}</h2>
    <div class="bg-ip bg-contain flex mx-auto bg-no-repeat pt-9 px-4 pb-5 flex-col justify-between" :class="{'!h-[571px]': isCreatePost, '!h-[430px] md:!h-[571px]': !isCreatePost}">
      <div class="header-screen flex items-center justify-between border-b">
        <div class="basis-2/6">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Instagram_logo.svg/1200px-Instagram_logo.svg.png"
            alt=""
          />
        </div>
        <div>
          <i class="fas fa-plus-square"></i>
          <i class="fa-brands fa-facebook-messenger ml-3.5"></i>
        </div>
      </div>
      <div class="post-main py-3 flex-1">
        <div class="user-info flex items-center justify-between">
          <div class="flex items-center">
            <div
              class="
                rounded-full
                inline-flex
                justify-center
                items-center
                avatar
                w-11
                h-11
                header__avatar header__avatar--border
                border-2 border-solid
                mr-2
              "
            >
              <span v-if="!profilePictureUrl" class="text-black text-xl">{{ account.username.slice(0, 1).toUpperCase() }}</span>
              <img class="w-full h-full rounded-full" v-else :src="profilePictureUrl" />

              <!-- avatar  default -->
            </div>
            <div>
              <div class="mr-5">
                <span class="name-account">@{{ account.username }}</span>
              </div>
              <p class="text-slate-400 font-bold ml-2 text-sm">
                sponsored
                <i class="fa-solid fa-earth-americas"></i>
              </p>
            </div>
          </div>
          <i class="fa-solid fa-ellipsis"></i>
        </div>
        <div class="mt-2 w-full">
          <div v-if="validate == false" class="custom-bg-ins">
            <p class="text-black">画像または動画をアップロードしてください</p>
          </div>
          <div class="relative">
            <div id="img-preview" v-if="file.length > 0 && fileType != 'video/mp4' && file != '' && validate == true && !isCarousel" class="rounded-md border h-40 w-full flex items-center justify-center">
              <img :src="file.slice(-1)[0].url" alt="imagePreview" class="h-full w-full" />
              <!-- <el-image
                style="width: 50%; height: 100%"
                :src="file.slice(-1)[0].url"
                :fit="cover">
              </el-image> -->
            </div>
            <div class="block carousel-preview" v-if="isCarousel">
              <el-carousel height="200px" class="w-full" v-if="arrImage.length > 0">
                <el-carousel-item v-for="item in arrImage" :key="item">
                  <div v-if="item.type != 'video/mp4'" class="rounded-md border h-40 w-full flex items-center justify-center">
                    <img :src="item.url" alt="" class="w-full h-full"/>
                  </div>
                  <div v-if="item.type == 'video/mp4'" class="flex items-center justify-center">
                    <video
                      id="foo"
                      width="260"
                      class="mr-2"
                      :src="item.url"
                      controls
                      autoplay
                    >
                    </video>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <video
            v-if="file.length > 0 && fileType == 'video/mp4' && validate == true && !isCarousel"
            width="320"
            height="150"
            :src="file.slice(-1)[0].url"
            controls
            autoplay
            style="max-height: 150px"
          ></video>
        </div>
        <div class="post-image-option flex justify-between mt-2">
          <div class="flex gap-4 items-center">
            <i class="far fa-heart"></i>
            <i class="far fa-comment-alt"></i>
            <i class="far fa-paper-plane"></i>
          </div>
          <div class="bookmark"><i class="far fa-bookmark"></i></div>
        </div>
        <div>
          <div>
            <span class="text-black font-bold text-xs">{{ account.username }}</span>
            <span class="text-xs text-break">{{ content }}</span>
          </div>
        </div>
      </div>
      <div class="bottom-foot-container">
        <i class="fas fa-home"></i>
        <i class="fas fa-search"></i>
        <i class="fas fa-plus-square"></i>
        <i class="far fa-heart"></i>
        <i class="fas fa-user-circle"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { Inertia } from '@inertiajs/inertia'
export default {
  props: {
    username: {
      type: String,
    },
    content: {
      type: String,
      default: 'Content is empty',
    },
    tag: {
      default: '@tag',
    },
    hashtag: {
      default: '',
    },
    file: {
      default: null,
    },
    fileType: {
      default: null,
    },
    validate: {
      default: null,
    },
    profilePictureUrl: {
      type: String,
    },
    arrImage: {
      type: Array,
      default: [],
    },
    isCarousel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVideo: false,
    }
  },
  computed: {
    account() {
      if (this.$page.props.auth?.account !== null) {
        return this.$page.props.auth?.account
      }
      return null
    },
    isCreatePost() {
      return Inertia?.page?.url === '/post-advertisment'
    },
  },
  watch: {
    file: function () {
      if (this.fileType == 'video/mp4') {
        this.isVideo = true
      } else {
        this.isVideo = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-bg-ins {
  display: flex;
  height: 200px;
  background-color: gray;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
}
.bg-ip {
  width: 283px;
  height: 571px;
  background-image: url('@shared/uploads/iPhone-screen.svg');
  filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.25));
}
.bottom-foot-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  border-top: #d8d8d8 1px solid;
  color: #d8d8d8;
  font-size: 3vmin;
  text-align: center;
  user-select: none;
  padding-top: 4px;
  i {
    font-size: 1rem;
    padding: 10px 0;
    color: black;
  }
}
.text-break {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  visibility: visible;
  word-wrap: break-word;
}

.hashtag {
  color: rgba(var(--fe0, 0, 55, 107), 1);
}
.tag-flag {
  &::before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-bottom: 10px solid #d1d5db;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: -5px;
    left: 10px;
    margin: 0 auto;
  }
}
.header__avatar.header__avatar--border {
  border-color: #bf3d8f;
}
</style>
<style>
.carousel-preview .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
}
.carousel-preview .el-carousel__container .el-carousel__arrow
{
  transform: translateY(-30px);
}
.carousel-preview .el-carousel__container img 
{
  height: 190px;
}
</style>