<template>
  <DrashBoard>
    <LinkInstagram v-if="!showLinkInstagram" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
    <template v-slot:breakcumb>
      <Loading v-if="isLoading" />
      <BreadCrumb>
        <li class="mr-4">
          <Link :href="route('admin.post.create')" class="font-normal breakcumb-active">投稿</Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-6 relative pb-5 active">投稿画面</li>
        </ul>
      </div>
    </template>
    <UserNav />
    <div class="lg:px-10 bg-background flex sm:flex-col lg:flex-row md:px-5 sm:px-2">
      <div class="flex-1 lg:pr-10 md:pr-0">
        <h2 class="title-md md:pt-14 md:pb-12.5 sm:pt-5 sm:pb-5">投稿</h2>
        <form class="grid gird-two-column items-center post-create-form">
          <label for="">アカウント名</label>
          <p class="text-base text-request font-normal text-position__sm">{{ userName }}</p>
          <label class="md:mt-8.5 md:mb-4 sm:mt-4 sm:mb-2">
            投稿ステータス
            <span class="text-red-500">*</span>
          </label>
          <div class="form md:mt-8.5 items-center md:mb-4 sm:mt-4 sm:mb-2">
            <div class="flex custom-icon-question">
              <div v-for="item in postStatus" :key="item">
                <input
                  class="w-5 h-5"
                  type="radio"
                  :value="item.value"
                  v-model="form.status"
                  @change="form.errors.status = ''"
                />
                <label for="予約投稿">{{ item.label }}</label>
              </div>
              <div class="tooltip">
                <img src="@shared/uploads/question.png" />
                <span class="tooltiptext">
                  投稿ステータスの下書き保存にチェックを入れ、実行するをクリックすると下書きに保存されます。
                </span>
              </div>
            </div>
            <div class="text-left text-red-600 text-sm mt-2" v-if="form.errors.status">
              {{ form.errors.status }}
            </div>
          </div>
          <label for=""></label>
          <div>
            <div v-if="form.status === '1'">
              <v-date-picker
                is24hr
                class="relative w-max"
                v-model="date"
                mode="dateTime"
                locale="ja"
                :min-date="new Date()"
                :max-date="new Date(new Date().setMonth(new Date().getMonth() + 3))"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input class="text-item_link" type="text" :value="inputValue" v-on="inputEvents" />
                  <img class="absolute right-2 top-1/2 -translate-y-1/2" src="/images/calendar.svg" alt="" />
                </template>
              </v-date-picker>
              <div class="text-left text-[12px] text-red-600" v-if="validateSchedule">
                {{ messageSchedule }}
              </div>
            </div>
          </div>
          <label for="">
            クリエイティブ
            <span class="text-red-500">*</span>
          </label>
          <div class="flex md:flex-row sm:flex-col gap-col-sm">
            <div class="flex items-center">
              <div v-if="validateFile">
                <img
                  v-if="fileType != 'video/mp4' && imgUpload != ''"
                  :src="imgUpload.slice(-1)[0]"
                  class="w-36.2 mr-2"
                />
                <video
                  v-if="fileType == 'video/mp4'"
                  id="foo"
                  width="200"
                  class="mr-2"
                  :src="imgUpload.slice(-1)[0]"
                  controls
                  autoplay
                >
                  Your browser does not support the video tag.
                </video>
              </div>
              <span
                v-if="validateFile"
                @click="uploadNewImg"
                class="text-xs text-button_pink underline hover:cursor-pointer mr-[5px] ml-[5px]"
              >
                クリエイティブを変更する
              </span>
            </div>
            <div>
              <input
                @change="selectImg(e)"
                v-show="false"
                type="file"
                id="file"
                ref="inputFile"
                accept="image/jpeg,image/png,image/heic,image/heif,video/mp4,video/quicktime"
              />
              <p
                v-if="!validateFile"
                @click="uploadImg"
                class="text-white text-xs button-shadow bg-blue-600 md:py-2 md:w-44 sm:w-24 sm:py-1 text-center hover:cursor-pointer rounded"
              >
                アップロード
              </p>
              <p v-if="validateMedia == true" class="text-red-600 pt-[5px] text-[12px]">ファイルを選択してください</p>
            </div>
            <p v-if="validateFile == false" class="text-[12px] md:ml-[30px] sm:ml-0 text-red-600">
              最大サイズ 5000＊10000px＆＆容量8MBアップしてください。
              <br />
              画像だけではなく動画もアップ可能です。
            </p>
            <div class="text-left text-red-600 text-sm mt-2 ml-5" v-if="form.errors.file">
              {{ form.errors.file }}
            </div>
            <div class="text-left text-red-600 text-sm mt-2 ml-5" v-if="fileError">
              {{ fileError }}
            </div>
          </div>
          <label class="-translate-y-3/4">
            本文
            <span class="text-red-500"></span>
          </label>
          <div class="relative">
            <el-input v-model="form.content" maxlength="2000" show-word-limit type="textarea" />
          </div>
          <label for="">ハッシュタグ</label>
          <el-select
            class="el-input-nomal"
            placeholder="&nbsp"
            v-model="form.hashtag"
            multiple
            filterable
            allow-create
            @change="handChangeHashTags($event)"
            default-first-option
            :automatic-dropdown="false"
            size="large"
          ></el-select>
          <label for="">タグ付</label>
          <div class="flex md:items-center md:flex-row sm:flex-col sm:items-start gap-col-sm">
            <span
              v-if="!validateFile"
              @click="uploadImg"
              class="text-white text-xs button-shadow bg-blue-600 py-2 hover:cursor-pointer rounded md:py-2 md:w-44 sm:w-24 sm:py-1 text-center"
            >
              アップロード
            </span>
            <el-dialog
              v-model="openDialogTag"
              :title="this.$i18n.t('Add Tag')"
              :close-on-click-modal="false"
              append-to-body
            >
              <div class="flex items-center justify-center">
                <div class="background relative hover:cursor-crosshair flex items-center">
                  <img
                    :style="[
                      openTagBox.width < 800 ? { width: openTagBox.width + 'px' } : { width: 800 + 'px' },
                      openTagBox.height < 800 ? { height: openTagBox.height + 'px' } : { height: 600 + 'px' },
                    ]"
                    @click="openSelectHashtag($event)"
                    :src="imgUpload.slice(-1)[0]"
                    v-if="fileType != 'video/mp4' && imgUpload != ''"
                    class="object-contain mr-2 image-upload"
                  />
                  <p class="mr-4" v-if="fileType == 'video/mp4' && imgUpload != ''">{{ item }}</p>
                  <span
                    :style="{ top: item.y * 100 + '%', left: item.x * 100 + '%' }"
                    v-for="(item, index) in dataTag"
                    :key="index"
                    class="bg-gray-300 rounded-xl py-2 leading-none px-4 absolute flex items-center tag-flag"
                  >
                    <span class="mr-4 text-xs whitespace-nowrap">{{ item.username }}</span>
                    <svg
                      @click="removeHashtag(index)"
                      class="inline-block"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.44662 1.17242L8.50661 0.232422L4.77995 3.95909L1.05328 0.232422L0.113281 1.17242L3.83995 4.89909L0.113281 8.62576L1.05328 9.56576L4.77995 5.83909L8.50661 9.56576L9.44662 8.62576L5.71995 4.89909L9.44662 1.17242Z"
                        fill="#5264CD"
                      />
                    </svg>
                  </span>
                  <SelectHashtag
                    :dialogFormVisible="openTagBox.status"
                    :left="openTagBox.left"
                    :top="openTagBox.top"
                    :width="openTagBox.width"
                    :height="openTagBox.height"
                    @closeSelectTag="closeHashtag($event)"
                  />
                </div>
              </div>
              <template #footer>
                <span class="dialog-footer">
                  <el-button type="primary" @click="openDialogTag = false">
                    {{ $t('Submit') }}
                  </el-button>
                </span>
              </template>
            </el-dialog>
            <div class="flex items-center">
              <div v-if="validateFile" class="background relative flex items-center">
                <img
                  :src="imgUpload.slice(-1)[0]"
                  v-if="fileType != 'video/mp4' && imgUpload != ''"
                  class="object-contain w-36.2 mr-2"
                />
                <video
                  v-if="fileType == 'video/mp4'"
                  id="foo"
                  width="200"
                  class="mr-2"
                  :src="imgUpload.slice(-1)[0]"
                  controls
                  autoplay
                >
                  Your browser does not support the video tag.
                </video>
              </div>
              <span
                v-if="validateFile"
                @click="uploadNewImg"
                class="text-xs text-button_pink underline hover:cursor-pointer mr-[5px] ml-[5px]"
              >
                クリエイティブを変更する
              </span>
            </div>
            <span
              v-if="validateFile && fileType != 'video/mp4'"
              @click="openDialogTag = true"
              class="text-xs text-button_pink underline hover:cursor-pointer mr-[10px] ml-[5px]"
            >
              {{ this.$i18n.t('Edit tag') }}
            </span>
            <p v-if="validateFile == false" class="text-[12px] md:ml-[30px] sm:ml-0 text-red-600">
              最大サイズ 5000＊10000px＆＆容量8MBアップしてください。
              <br />
              画像だけではなく動画もアップ可能です。
            </p>
          </div>
        </form>
        <div class="flex justify-center lg:mt-16.3 lg:pb-64 md:pb-10 md:mt-10 sm:mt-10">
          <button
            @click="backtoList"
            class="lg:w-72 md:w-4/12 sm:w-5/12 md:py-4 sm:py-2 lg:py-5 bg-button_pink text-white button-shadow rounded"
          >
            取り消し
          </button>
          <button
            @click="submitForm()"
            as="button"
            type="submit"
            class="lg:w-72 lg:py-5 md:w-4/12 sm:w-5/12 md:py-4 sm:py-2 bg-blue-600 ml-6 text-white button-shadow rounded"
          >
            実行する
          </button>
        </div>
      </div>
      <div class="basis-3/12 lg:border-l sm:border-0 lg:pb-0 md:pb-5 sm:pb-10">
        <PostPreview
          :validate="validateFile"
          :profile-picture-url="profilePictureUrl"
          :content="form.content"
          :username="userName"
          :tag="form.tag"
          :hashtag="form.hashtag"
          :file="imgUpload"
          :fileType="fileType"
        />
      </div>
    </div>
  </DrashBoard>
</template>
<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import SelectHashtag from './SelectTag.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import PostPreview from '@/Components/InstagramScreen.vue'
import { Inertia } from '@inertiajs/inertia'
import { Link, useForm } from '@inertiajs/inertia-vue3'
import * as userAPI from '@/Api/Users'
import Loading from '@/Components/Loadding'
import axios from 'axios'
import { getVideoInfo } from '@/Helpers/video'
import UserNav from '@/Components/UserNav.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'

export default {
  props: ['userId'],
  components: {
    DrashBoard,
    PostPreview,
    BreadCrumb,
    SelectHashtag,
    Link,
    Loading,
    UserNav,
    LinkInstagram,
  },
  data() {
    return {
      openDialogTag: false,
      isLoading: false,
      openTagBox: {
        status: false,
        clicked: true,
        left: 0,
        width: 0,
        height: 0,
        top: 0,
      },
      dataTag: '',
      fileType: '',
      fileSize: 0,
      date: null,
      btnUpload: true,
      fileError: false,
      btnUploadTag: true,
      validateFile: false,
      showLinkInstagram: true,
      imgUpload: [],
      fileUpdate: '',
      userName: '',
      form: useForm({
        status: '0',
        content: '',
        file: '',
        scheduled_time: new Date(),
        hashtag: [],
        tag: [],
      }),
      postStatus: [
        {
          label: '即時配信',
          value: '0', // now
        },
        {
          label: '予約投稿',
          value: '1', //scheduled
        },
        {
          label: '下書き保存',
          value: '2', //draft
        },
      ],
      profilePictureUrl: '',
      validateMedia: false,
      validateSchedule: false,
      messageSchedule: '',
    }
  },
  methods: {
    onInstaSubmitSuccess() {
      this.showLinkInstagram = true
    },
    handChangeHashTags(e) {
      this.form.hashtag = this.convertHashtag(e)
    },
    backtoList() {
      Inertia.visit('/post')
    },
    handChangeTags(e) {
      this.form.tag = this.convertTag(e)
    },
    async submitForm() {
      try {
        if (this.form.status == 1) {
          const dateNow = new Date()
          dateNow.setMinutes(dateNow.getMinutes() + 29)
          let formatDateNow = new Date(dateNow).getTime()
          let timeSchedule = new Date(this.date).getTime()
          if (timeSchedule < formatDateNow) {
            this.validateSchedule = true
            return this.errorSchedule(dateNow)
          }

          if (this.validateSchedule == true) {
            return
          }

          this.form.scheduled_time = this.convertTime(this.date)
        }

        if (this.form.status != 1) {
          this.form.scheduled_time = ''
        }

        this.isLoading = true
        const formData = new FormData()
        if (this.validateFile == false) {
          this.form.file = ''
          this.validateMedia = true
        }

        formData.append('file', this.form.file)
        formData.append('status', this.form.status)
        formData.append('content', this.form.content)
        formData.append('scheduled_time', this.form.scheduled_time)
        formData.append('hashtag', this.form.hashtag)
        formData.append('tag', this.form.tag.length == 0 ? [] : JSON.stringify(this.form.tag))

        if (!this.form.file) {
          return
        }

        const { data } = await axios.post(route('api.posts.store'), formData)
        if (!data.status) {
          return this.$toast.error(data.msg)
        }

        this.$toast.success(data.msg)
        this.$inertia.visit(data.redirect)
      } catch (error) {
        // alert validate file
        // const err= error.response.data.file[0];
        // return  this.$toast.error(err);
      } finally {
        this.isLoading = false
      }
    },
    uploadNewImg: function () {
      this.$refs.inputFile.click()
      this.form.tag.splice(index, 1)
      this.dataTag.splice(index, 1)
    },
    uploadImg: function () {
      this.$refs.inputFile.click()
    },
    selectImg: async function (e) {
      this.form.errors.file = null
      this.fileUpdate = event.target.files[0]
      this.imgUpload.push(URL.createObjectURL(this.fileUpdate))
      this.form.file = this.fileUpdate
      await this.checkFile(this.fileUpdate)
    },
    convertHashtag: function (hashtags) {
      const newHashtags = hashtags.map(function (hashtag) {
        if (hashtag.charAt(0) != '#') {
          return '#' + hashtag
        }
        return hashtag
      })
      return newHashtags
    },
    checkFile: async function (file) {
      const maxImageSize = 8192
      const maxVideoSize = 102400
      const checkFormatFile = file.type.split('/')[0]
      if (checkFormatFile == 'image') {
        this.validateFile = true
        this.fileType = file.type
        await this.checkFileSize(file.size, maxImageSize)
        this.checkImageUpload(file)
      } else if (checkFormatFile == 'video') {
        this.validateFile = true
        this.openDialogTag = false
        this.fileType = file.type
        await this.checkFileSize(file.size, maxVideoSize)
        this.checkVideoUpload(file)
      } else {
        this.openDialogTag = false
        this.setError()
      }
    },
    checkFileSize: function (size, sizeMax) {
      if (size / 1000 >= sizeMax) {
        this.setError()
      } else {
        this.fileError = false
      }
    },

    async checkVideoUpload(file) {
      const url = URL.createObjectURL(file)
      const video = document.createElement('video')
      video.src = url
      video.addEventListener('loadedmetadata', function () {
        if (this.videoWidth > 1920 || this.videoHeight > 1080) {
          this.setError()
        }
      })

      const info = await getVideoInfo(file)
      if (info.duration < 3 || info.duration > 60) {
        this.setError()
      }
      if (info.frameRate < 23 && info.frameRate > 60) {
        this.setError()
      }
      this.validateMedia = false
    },

    checkImageUpload(file) {
      var _URL = window.URL || window.webkitURL
      var file, img
      let vm = this

      img = new Image()
      img.onload = function () {
        const ratio = this.width / this.height
        if (!(ratio >= 0.8 && ratio <= 1.91)) {
          vm.setError()
        } else if (this.width > 5000 || this.width < 320) {
          vm.setError()
        } else if (this.height > 10000) {
          vm.setError()
        } else {
          vm.openTagBox.width = this.width
          vm.openTagBox.height = this.height
        }
      }

      img.onerror = function () {
        vm.setError()
      }
      img.src = _URL.createObjectURL(file)
      this.validateMedia = false
    },

    async setError() {
      this.openDialogTag = false
      this.$toast.error('メディアファイルをアップ失敗しました。も一回選択してください')
      document.getElementById('file').value = ''
      return (this.validateFile = false)
    },

    convertTag: function (tags) {
      const newTags = tags.map(function (tag) {
        if (tag.charAt(0) != '@') {
          return '@' + tag
        }
        return tag
      })
      return newTags
    },

    convertTime(data) {
      const moth = data.getMonth() + 1
      const date = data.getFullYear() + '-' + '0' + moth + '-' + data.getDate()
      const time = data.getHours() + ':' + data.getMinutes()
      const dateTime = date + ' ' + time
      return dateTime
    },

    errorSchedule(date) {
      const dateFormat = this.formatDateJA(date)
      this.messageSchedule = 'scheduled timeには、' + dateFormat + 'より後の日付を指定してください。'
    },

    formatDateJA(date) {
      if (date) {
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hour = date.getHours()
        let minute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()

        return `${year}年${month}月${day}日 ${hour}:${minute}`
      }
    },

    convertDateString(date) {
      if (this.form.status !== '1') {
        return ''
      } else {
        if (date) {
          const dateTime = date.toISOString().split('.')[0].replace('T', ' ')
          return dateTime.split(':')[0] + ':' + dateTime.split(':')[1]
        }
        return ''
      }
    },
    openSelectHashtag(e) {
      if (this.openTagBox.clicked) {
        var rect = e.target.getBoundingClientRect()
        var x = e.clientX - rect.left //x position within the element.
        var y = e.clientY - rect.top //y position within the element.
        this.openTagBox.status = true
        this.openTagBox.left = x
        this.openTagBox.top = y
      }
    },
    closeHashtag(data) {
      this.dataTag = data
      this.form.tag = this.dataTag.map((tags) => ({
        username: tags.username,
        x: tags.x,
        y: tags.y,
      }))
      this.openTagBox.status = false
    },
    removeHashtag(index) {
      this.form.tag.splice(index, 1)
      this.dataTag.splice(index, 1)
    },
    handler() {
      if (event.keyCode == 27) {
        this.openTagBox.status = false
      }
    },
  },
  async mounted() {
    window.addEventListener('keyup', this.handler)
    window.addEventListener('click', (e) => {
      if (e.target.className == 'el-overlay-dialog') {
        this.openTagBox.status = false
      }
    })
    // this.date.setMinutes(this.date.getMinutes() + 30)
    const {
      data: { data },
    } = await userAPI.getUserDetail(this.userId)
    this.userName = data.account_name
    this.profilePictureUrl = data.profile_picture_url
  },
  watch: {
    'form.status'(value) {
      if (value == 1) {
        const date = new Date()
        date.setMinutes(date.getMinutes() + 30)
        return (this.date = date)
      }
    },

    date(value) {
      const date = new Date()
      date.setMinutes(date.getMinutes() + 29)
      if (value < date) {
        this.validateSchedule = true
        this.errorSchedule(date)
      } else {
        this.validateSchedule = false
      }
    },
  },

  destroyed() {
    window.removeEventListener('keyup', this.handler)
    window.removeEventListener('click')
  },
}
</script>

<style lang="scss" scoped>
.tooltip {
  align-items: center;
  width: 20px;
  position: relative;
  display: flex;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 542px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  margin-left: -260px;
  font-size: 12px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.gird-two-column {
  grid-template-columns: max-content auto;
  gap: 30px 40px;
  input::placeholder {
    opacity: 0.8;
  }
  .form {
    label {
      margin-left: 13px;
      margin-right: 46px;
    }
  }
}
.custom-icon-question .tooltip {
  margin-left: -32px;
}
.leading-none {
  line-height: 1;
}

.tag-flag {
  &::before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-bottom: 10px solid #d1d5db;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: -5px;
    left: 10px;
    margin: 0 auto;
  }
}
</style>
