<template>
    <DrashBoard>
        <LinkInstagram v-if="!showLinkInstagram" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li class="mr-4">
                    <Link :href="route('admin.post-hashtag.index')" class="font-normal breakcumb-active">ハッシュタグ分析</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5" :class="{ active: currentTabPostCategory == 'normal' }"
                        @click="currentTabPostCategory = 'normal'">
                        通常投稿
                    </li>
                    <li v-if="isShowReel" class="px-2 relative pb-5 ml-3"
                        :class="{ active: currentTabPostCategory == 'reel' }" @click="currentTabPostCategory = 'reel'">
                        リール投稿
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:manual>
            <UserManual :isShow="isShowManual" :content="arrContent" @closeManualPopup="toogleManual" />
        </template>
        <div class="bg-background pb-20" v-loading="loading">
            <UserNav />
            <div class="statistic px-5 md:px-10">
                <div class="flex items-center md:relative">
                    <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                        <p class="text-button_pink font-semibold text-3sm">ハッシュタグ分析
                        </p>
                        <div class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer" @click="toogleManual">
                            <img :src="suggestIcon" />
                        </div>
                    </div>
                    <!-- <UserManual :isShow="isShowManual" :content="arrContent" /> -->
                </div>
                <div class="section-ranking mb-7">
                    <el-row>
                        <el-col :md="8">
                            <div class="p-3 md:mr-5 bg-white border border-gray-300 rounded mb-5">
                                <div class="my-2 text-center text-gray-600 text-3sm font-semibold">
                                    {{ currentTabPostCategory == 'normal' ? 'インプレッション数TOP5' : 'コメント数TOP5' }}
                                </div>
                                <el-table
                                    :data="currentTabPostCategory == 'normal' ? rankings.impressions : rankings.comments"
                                    header-row-class-name="padding-0" style="width: 100%">
                                    <el-table-column prop="ranking" label="順位" label-class-name="bg-gray-100"
                                        width="100" align="left" type="index" :index="indexMethod" />

                                    <el-table-column prop="name" label="ハッシュタグ名"
                                        label-class-name=" bg-gray-100 text-center">
                                        <template #default="scope">
                                            <el-link :href="`${URL_INSTAGRAM}/${scope?.row?.name?.replace('#', '')}`"
                                                class="text-pink !text-[13px]" target="_blank">
                                                <p>{{ scope?.row?.name }}</p>
                                            </el-link>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sumImpressions"
                                        :label="currentTabPostCategory == 'normal' ? 'インプ' : 'コメント数'"
                                        label-class-name=" bg-gray-100" align="right" width="100">
                                        <template #default="scope">
                                            <p v-if="currentTabPostCategory == 'normal'">{{
                                                    formatToInteger(scope?.row?.sumImpressions)
                                            }}</p>
                                            <p v-else>{{ formatToInteger(scope?.row?.sumComments) }}</p>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-col>
                        <el-col :md="8">
                            <div class="p-3 md:mr-5 bg-white border border-gray-300 rounded mb-5">
                                <div class="my-2 text-center text-gray-600 text-3sm font-semibold">いいね数TOP5</div>
                                <el-table :data="rankings.likes" header-row-class-name="padding-0" style="width: 100%">
                                    <el-table-column prop="ranking" label="順位" label-class-name=" bg-gray-100"
                                        width="100" align="left" type="index" :index="indexMethod" />
                                    <el-table-column prop="name" label="ハッシュタグ名"
                                        label-class-name=" bg-gray-100 text-center">
                                        <template #default="scope">
                                            <el-link :href="`${URL_INSTAGRAM}/${scope?.row?.name?.replace('#', '')}`"
                                                class="text-pink !text-[13px]" target="_blank">
                                                <p>{{ scope?.row?.name }}</p>
                                            </el-link>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sumLikes" label="いいね数" label-class-name=" bg-gray-100"
                                        align="right" width="100">
                                        <template #default="scope">
                                            <p>{{ formatToInteger(scope?.row?.sumLikes) }}</p>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-col>
                        <el-col :md="8">
                            <div class="p-3 md:mr-5 bg-white border border-gray-300 rounded mb-5">
                                <div class="my-2 text-center text-gray-600 text-3sm font-semibold">使用回数TOP５</div>
                                <el-table :data="rankings.posts" header-row-class-name="padding-0" style="width: 100%">
                                    <el-table-column prop="ranking" label="順位" label-class-name=" bg-gray-100"
                                        width="100" align="left" type="index" :index="indexMethod" />
                                    <el-table-column prop="name" label="ハッシュタグ名"
                                        label-class-name=" bg-gray-100 text-center">
                                        <template #default="scope">
                                            <el-link :href="`${URL_INSTAGRAM}/${scope?.row?.name?.replace('#', '')}`"
                                                class="text-pink !text-[13px]" target="_blank">
                                                <p>{{ scope?.row?.name }}</p>
                                            </el-link>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sumPost" label-class-name=" bg-gray-100" label="使用回数"
                                        align="right" width="100">
                                        <template #default="scope">
                                            <p>{{ formatToInteger(scope?.row?.sumPost) }}</p>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="menu flex items-center mb-5 justify-between">
                    <div class="flex relative flex-1">
                        <el-input type="primary" v-model="filter.name" placeholder="検索キーワードを入力してください"
                            class="mr-5 md:!w-96" size="large" @keyup.enter="fetchData" clearable />
                        <el-button class="el-button__primary md:w-20" @click="onSearch" size="large">
                            <span class="text-white">検索</span>
                        </el-button>
                    </div>
                </div>
                <!-- datatable lis hash tag -->
                <div class="bg-white p-2">
                    <data-table :fields="fields_list" :items="hashtags.data" :paginate="hashtags.meta"
                        :current-page="filter.page || 1" disable-table-info footer-center paginate-background
                        @page="handleCurrentPage" class="el-table-header__black" v-if="!loading">
                        <template #hashtag_name="{ row }">
                            <el-link :href="`${URL_INSTAGRAM}/${row?.hashtag_name?.replace('#', '')}`"
                                class="text-pink !text-[13px]" target="_blank">
                                <p>{{ row?.hashtag_name }}</p>
                            </el-link>
                        </template>
                        <template #hashtag_using="{ row }">
                            <p>{{ formatToInteger(row?.totalCount) }}</p>
                        </template>
                        <template #hashtag_likes="{ row }">
                            <p>{{ formatToInteger(row?.hashtag_likes) }}</p>
                        </template>
                        <template #hashtag_reachs="{ row }">
                            <p>{{ formatToInteger(row?.hashtag_reachs) }}</p>
                        </template>
                        <template #hashtag_comments="{ row }">
                            <p>{{ formatToInteger(row?.hashtag_comments) }}</p>
                        </template>
                        <template #hashtag_saves="{ row }">
                            <p>{{ formatToInteger(row?.hashtag_saves) }}</p>
                        </template>
                        <template #hashtag_impressions="{ row }">
                            <p>{{ row?.hashtag_impressions ? formatToInteger(row?.hashtag_impressions) : '0' }}</p>
                        </template>
                        <template #engagement_rate="{ row }">
                            <p>{{ formatToFloat(row?.engagement_rate + '%') }}</p>
                        </template>
                    </data-table>
                </div>
            </div>
        </div>
    </DrashBoard>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import DataTable from '@/Components/UI/DataTable.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import UserManual from '@/Components/UserManual.vue'
const FIELDS = [
    { key: 'hashtag_name', label: 'ハッシュタグ', minWidth: 300, fixed: window.innerWidth > 600 ? 'left' : false, align: 'center' },
    { key: 'hashtag_using', label: '使用回数', width: 170, align: 'center' },
    { key: 'hashtag_likes', label: 'いいね数', width: 170, align: 'center' },
    { key: 'hashtag_reachs', label: 'リーチ数', width: 170, align: 'center' },
    { key: 'hashtag_comments', label: 'コメント数', width: 170, align: 'center' },
    { key: 'hashtag_saves', label: '保存数', width: 170, align: 'center' },
    { key: 'hashtag_impressions', label: 'インプレッション数', width: 170, align: 'center' },
    { key: 'engagement_rate', label: 'エンゲージメント率', width: 170, align: 'center' },
    { key: 'engagement', label: 'エンゲージメント数', width: 170, align: 'center' },
]
export default {
    components: { DrashBoard, UserNav, DataTable, Link, BreadCrumb, LinkInstagram, UserManual },

    computed: {
        isShowReel() {
            if (this.$page.props?.auth?.user?.roles[0]?.name === 'master') {
                return true
            }
            return this?.$page?.props?.auth?.plan?.reel_analysis == 1
        },
    },

    data() {
        return {
            loading: false,
            hashtags: {},
            rankings: [],
            showLinkInstagram: true,
            filter: {
                page: 1,
                name: null,
                limit: 10,
            },
            fields_list: [FIELDS],
            URL_INSTAGRAM: 'https://www.instagram.com/explore/tags',
            currentTabPostCategory: 'normal',
            arrContent: ['投稿に紐付いたハッシュタグを分析・確認することができます。', 'ページ上部にある「インプレッションTOP5」「いいね数TOP5」「使用回数TOP5」では過去の投稿内のうち、それぞれの数値で高い順番に並んでいます。', 'インプレッション数・いいね数のランキングと使用回数のランキングが一致しているか確認し、なるべく一致させるようにしましょう。', '各ハッシュタグの結果については、表から確認することができ、エンゲージメントが高いハッシュタグを投稿に付随させることで、コンテンツ評価を上げることが可能です。'],
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowManual: false
        }
    },

    async created() {
        this.loading = true
        this.fields_list = FIELDS.filter((v) => v.key !== 'engagement')
        await this.getRankings()
        await this.fetchData()
        await this.checkLinkInstagram()
        this.loading = false
    },

    watch: {
        'filter.name': {
            handler() {
                if (!this.filter.name) {
                    this.fetchData()
                }
            },
            deep: true,
        },
        currentTabPostCategory: {
            handler() {
                this.loading = true
                this.filter.page = 1

                if (this.currentTabPostCategory) {
                    this.filter.name = ''
                }

                if (this.currentTabPostCategory == 'normal') {
                    this.fields_list = FIELDS.filter((v) => v.key != 'engagement')
                    this.arrContent = ['投稿に紐付いたハッシュタグを分析・確認することができます。', 'ページ上部にある「インプレッションTOP5」「いいね数TOP5」「使用回数TOP5」では過去の投稿内のうち、それぞれの数値で高い順番に並んでいます。', 'インプレッション数・いいね数のランキングと使用回数のランキングが一致しているか確認し、なるべく一致させるようにしましょう。', '各ハッシュタグの結果については、表から確認することができ、エンゲージメントが高いハッシュタグを投稿に付随させることで、コンテンツ評価を上げることが可能です。']
                } else {
                    this.fields_list = FIELDS.filter((v) => v.key != 'engagement_rate' && v.key != 'hashtag_impressions')
                    this.arrContent = ['投稿に紐付いたハッシュタグを分析・確認することができます。', 'ページ上部にある「コメント数TOP5」「いいね数TOP5」「使用回数TOP5」では過去の投稿内のうち、それぞれの数値で高い順番に並んでいます。', 'コメント数・いいね数のランキングと使用回数のランキングが一致しているか確認し、なるべく一致させるようにしましょう。', '各ハッシュタグの結果については、表から確認することができ、エンゲージメントが高いハッシュタグを投稿に付随させることで、コンテンツ評価を上げることが可能です。']
                }
                this.fetchData()
                this.getRankings()
                this.isShowManual = false
                this.loading = false
            },
        },
    },

    methods: {
        toogleManual() {
            this.isShowManual = !this.isShowManual
        },
        onInstaSubmitSuccess() {
            this.showLinkInstagram = true
        },
        // fetch data
        async fetchData() {
            const postCategory = this.currentTabPostCategory
            await axios
                .get(
                    route('api.account.get-hashtag', {
                        filter: { name: this.filter?.name },
                        limit: this.filter?.limit,
                        page: this.filter?.page,
                        post_category: postCategory,
                    })
                )
                .then((res) => {
                    this.hashtags = res?.data
                })
                .catch((err) => {
                    console.log(err)
                })
        },

        async getRankings() {
            const postCategory = this.currentTabPostCategory
            await axios.get(route('api.account.get-rankings', { post_category: postCategory })).then((res) => {
                this.rankings = res?.data
            })
        },
        indexMethod(index) {
            return index + 1 + '位'
        },
        onSearch() {
            this.filter.page = 1
            this.fetchData()
        },

        handleCurrentPage(value) {
            this.filter.page = value
            this.fetchData()
        },

        // link instagram
        async checkLinkInstagram() {
            const check = await accountAPI.checkInsAccount()
            if (check.data) {
                this.showLinkInstagram = true
            } else {
                this.showLinkInstagram = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.el-button__primary {
    background-color: #5264cd;
    border: 1px solid #5264cd;
}

.el-button__primary span {
    color: white !important;
}

.text-pink {

    &:hover,
    p {
        color: #be3d8f !important;
        text-decoration: underline;
    }
}

.el-link.is-underline:hover:after {
    border-bottom: none !important;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    border-bottom: 0 !important;
}
</style>

<style>
.section-ranking .el-table__inner-wrapper::before {
    background-color: white !important;
}
</style>
