<template>
  <div :style="{paddingLeft : pl+'px'}" class="flex flex-col pt-10 border-l-2 h-full">
    <button class="bg-blue-600 button-shadow rounded text-white py-3 mb-10">
      投稿のプレビュー
    </button>
    <div class="bg-ip bg-contain block mx-auto bg-no-repeat pt-9 px-4 pb-5">
      <img
        class="w-full h-full object-cover rounded-4xl"
        src="/images/girl.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["pl"],
  mounted() {
  },
};
</script>

<style scoped>
.bg-ip {
  width: 283px;
  height: 571px;
  background-image: url("@shared/uploads/iPhone-screen.svg");
  filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.25));
}
</style>
