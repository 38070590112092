<template>
  <div>
    <div
      class="flex p-5 w-full bg-notice mt-4 cursor-pointer"
      @click="handleDetailNotification"
      v-if="notifications?.length > 0"
    >
      <Transition name="fade" mode="out-in">
        <div class="text-notice_date mr-8 whitespace-nowrap text-2sm" v-if="isShow">
          {{ notification.deliveredTime }}
        </div>
      </Transition>

      <Transition name="fade" mode="out-in">
        <div
          class="text-notice_content whitespace-nowrap content-notice text-2sm"
          v-if="isShow"
          v-html="parseTagA(`${notification.label} | ${notification.title}`)"
        ></div>
      </Transition>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { Inertia } from '@inertiajs/inertia'
import { computed, nextTick, watch } from '@vue/runtime-core'
// import PopupNotification from '@/Components/PopupNotification';
import { parseTagA } from '@/Helpers/xss'

export default {
  name: 'NotificationPanel',
  components: {
    // PopupNotification
  },
  setup() {
    const TIME_NEXT_NOTICE = 5000 //s
    const dialogTableVisible = ref(false)
    const isShowDetail = ref(false)

    const notifications = computed((_) => {
      return Inertia?.page?.props?.notifications?.data || []
    })

    let currentNotice = 0
    const isShow = ref(true)
    const notification = ref(notifications.value[currentNotice])
    const currentNotification = ref({})

    watch(isShow, async (val) => {
      await nextTick()
      if (!val) {
        if (currentNotice++ === notifications.value.length - 1) {
          currentNotice = 0
        }

        notification.value = notifications.value[currentNotice]
        isShow.value = true
      }
    })

    if (notifications.value.length > 1) {
      setInterval((_) => {
        isShow.value = false
      }, TIME_NEXT_NOTICE)
    }

    const handleDetailNotification = (_) => {
      Inertia.visit(route('admin.notifications.show', notification.value?.id))
    }

    return {
      notification,
      isShow,
      notifications,
      isShowDetail,
      handleDetailNotification,
      dialogTableVisible,
      currentNotification,
      parseTagA,
    }
  },
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.content-notice {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
