import dayjs from 'dayjs'

export default {
  methods: {
    genChangePostAnalyticPage(doc) {
      const analyticPostChart = document.getElementById('analytic-post-chart')
      const analyticPostChartCanvas = analyticPostChart ? analyticPostChart.childNodes[0] : null
      const pageWidth = doc.internal.pageSize.getWidth()
      const pageHeight = doc.internal.pageSize.getHeight()
      const analyticPostChartCanvasWRatio = (pageWidth / 1536) * 0.9
      const analyticPostChartCanvasHRatio = (pageHeight / 400) * 0.9
      const analyticPostRatio =
        analyticPostChartCanvasWRatio > analyticPostChartCanvasHRatio
          ? analyticPostChartCanvasHRatio
          : analyticPostChartCanvasWRatio
      const analyticPostChartCanvasWidth = 1536 * analyticPostRatio
      const analyticPostChartCanvasHeight = 400 * analyticPostRatio
      let analyticPostRangeStart = dayjs(this.analyticPostRange[0]).format('YYYY-MM-DD')
      let analyticPostRangeStartFormat =
        analyticPostRangeStart.split('-')[0] +
        '年' +
        analyticPostRangeStart.split('-')[1] +
        '月' +
        analyticPostRangeStart.split('-')[2] +
        '日'
      let analyticPostRangeEnd = dayjs(this.analyticPostRange[1]).format('YYYY-MM-DD')
      let analyticPostRangeEndFormat =
        analyticPostRangeEnd.split('-')[0] +
        '年' +
        analyticPostRangeEnd.split('-')[1] +
        '月' +
        analyticPostRangeEnd.split('-')[2] +
        '日'
      let textRangePostWidth = doc.getTextWidth(analyticPostRangeStartFormat + '  /  ' + analyticPostRangeEndFormat)
      doc.text(analyticPostRangeStartFormat + '  /  ' + analyticPostRangeEndFormat, 197 - textRangePostWidth, 8, {
        baseline: 'center',
      })

      doc.setFontSize(14) // set font size
      doc.setTextColor(190, 61, 143)
      doc.text('投稿数の推移分析', 13, 30)
      doc.text('投稿数の推移分析', 13, 30)
      doc.text('投稿数の推移分析', 13, 30)
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(10)
      doc.addImage(this.drawTimeFrame(), 'PNG', 133, 0)

      doc.text(this.analyticPostCategory == 'normal' ? '通常投稿' : 'リール投稿', 13, 40)
      if (analyticPostChartCanvas) {
        doc.addImage(
          analyticPostChartCanvas,
          'PNG',
          10,
          45,
          analyticPostChartCanvasWidth,
          analyticPostChartCanvasHeight
        )
      }
      const dataAnalyticMap = JSON.parse(JSON.stringify(this.tableAnalyticData))
      let engagementLabel = this.analyticPostCategory == 'normal' ? 'エンゲージメント率' : 'エンゲージメント数'
      if (this.tableAnalyticData.length > 24) {
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          startY: analyticPostChartCanvasHeight + 53,
          head: [['日付', '投稿数', 'いいね数', 'コメント数', '保存数', 'リーチ数', engagementLabel]],
          body: dataAnalyticMap.slice(0, 24),
        })
        doc.addPage()
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          startY: 40,
          head: [['日付', '投稿数', 'いいね数', 'コメント数', '保存数', 'リーチ数', engagementLabel]],
          body: dataAnalyticMap.splice(24),
        })
      } else if (this.tableAnalyticData.length <= 24) {
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          startY: analyticPostChartCanvasHeight + 53,
          head: [['日付', '投稿数', 'いいね数', 'コメント数', '保存数', 'リーチ数', engagementLabel]],
          body: dataAnalyticMap,
        })
      }
    },
  },
}
