<template>
  <div class="flex flex-row h-full">
    <p class="mb-5 whitespace-pre-line w-1/2 text-justify overflow-auto max-h-content px-2">
      <span class="content__style" v-html="replaceURLs(content?.split('#')[0])"></span>
      <span class="color-hashtag">{{ splitHashtag(content) }}</span>
    </p>
    <div
      class="bg-ip bg-contain flex mx-auto bg-no-repeat pt-9 px-4 pb-5 flex-col justify-between w-1/2 !h-[430px] md:!h-[571px]"
    >
      <div class="header-screen flex items-center justify-between border-b">
        <div class="basis-2/6">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Instagram_logo.svg/1200px-Instagram_logo.svg.png"
            alt=""
          />
        </div>
        <div>
          <i class="fas fa-plus-square"></i>
          <i class="fa-brands fa-facebook-messenger ml-3.5"></i>
        </div>
      </div>
      <div class="post-main py-3 flex-1 overflow-auto">
        <div class="user-info flex items-center justify-between">
          <div class="flex items-center">
            <div
              class="rounded-ful inline-flex justify-center items-center avatar w-11 h-11 rounded-full header__avatar header__avatar--border border-2 border-solid mr-2"
            >
              <span v-if="!username.profile_picture_url" class="text-black text-xl">
                {{ username.account_name?.slice(0, 1)?.toUpperCase() }}
              </span>
              <img v-else :src="username.profile_picture_url" class="w-full h-full rounded-full" alt="" />
            </div>
            <p class="text-black font-bold ml-2 text-xs">
              {{ username.account_name ? username.account_name : username.username }}
            </p>
          </div>
          <i class="fa-solid fa-ellipsis"></i>
        </div>
        <div class="mt-2 w-full">
          <img
            class="h-52"
            v-if="file == '' && !carouselItems.length"
            src="https://jes.edu.vn/wp-content/uploads/2017/10/h%C3%ACnh-%E1%BA%A3nh.jpg"
            alt=""
            style="max-height: 200px"
          />
          <template v-if="!carouselItems.length">
            <img
              v-if="fileType != 'mp4' && fileType != 'm4v' && file != ''"
              :src="file"
              class="mr-2 w-full h-52"
              style="max-height: 200px"
            />
            <video
              v-if="fileType == 'mp4' || fileType == 'm4v'"
              width="320"
              height="200"
              :src="file"
              controls
              autoplay
              style="max-height: 200px"
            >
              Your browser does not support the video tag.
            </video>
          </template>
          <template v-else>
            <div class="block carousel-preview">
              <el-carousel height="200px" class="w-full">
                <el-carousel-item v-for="item in carouselItems" :key="item">
                  <div
                    v-if="getTypeFile(item) != 'mp4' || getTypeFile(item) != 'm4v'"
                    class="rounded-md border h-40 w-full flex items-center justify-center"
                  >
                    <img :src="item" alt="" class="w-full h-full" />
                  </div>
                  <div v-else class="flex items-center justify-center">
                    <video id="foo" width="260" class="mr-2" :src="item" controls autoplay></video>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </template>
        </div>
        <div class="post-image-option flex justify-between mt-3">
          <div class="flex gap-4 items-center">
            <i class="far fa-heart"></i>
            <i class="far fa-comment-alt"></i>
            <i class="far fa-paper-plane"></i>
          </div>
          <div class="bookmark"><i class="far fa-bookmark"></i></div>
        </div>
        <div>
          <div>
            <span class="text-black font-bold text-xs">{{ username.account_name }}</span>
            <span class="text-xs ml-1 mt-2 whitespace-pre-line">
              <span class="content__style" v-html="replaceURLs(content?.split('#')[0])"></span>
              <span class="color-hashtag">{{ splitHashtag(content) }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="bottom-foot-container">
        <i class="fas fa-home"></i>
        <i class="fas fa-search"></i>
        <i class="fas fa-plus-square"></i>
        <i class="far fa-heart"></i>
        <i class="fas fa-user-circle"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listHashtag: [],
    }
  },
  props: {
    username: {
      type: Object,
    },
    content: {
      type: String,
      default: 'Content is empty',
    },
    tag: {
      default: '@tag',
    },
    hashtag: {
      default: '',
    },
    file: {
      default: null,
    },
    fileType: {
      default: null,
    },
    carouselItems: {
      default: [],
    },
    group: {
      default: null,
    },
  },
  methods: {
    getTypeFile(fileName) {
      return fileName.split('.').pop()
    },
    splitHashtag(content) {
      let contentSplitHashtag = content?.split('#')
      let listHashtag = ''
      for (let i = 1; i < contentSplitHashtag?.length; i++) {
        listHashtag = listHashtag + '#' + contentSplitHashtag[i]
      }
      return listHashtag
    },
    replaceURLs(message) {
      if (!message) return

      var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
      return message.replace(urlRegex, function (url) {
        var hyperlink = url
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink
        }
        return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-ip {
  width: 283px;
  height: 571px;
  background-image: url('@shared/uploads/iPhone-screen.svg');
  filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.25));
}
.max-h-content {
  max-height: 600px;
}
.bottom-foot-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  border-top: #d8d8d8 1px solid;
  color: #d8d8d8;
  font-size: 3vmin;
  text-align: center;
  user-select: none;
  padding-top: 4px;
  i {
    font-size: 1rem;
    padding: 10px 0;
    color: black;
  }
}
.text-break {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  visibility: visible;
  word-wrap: break-word;
}
.content-break {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow-y: scroll;
  visibility: visible;
  word-wrap: break-word;
}
.hashtag {
  color: rgba(var(--fe0, 0, 55, 107), 1);
}
.header__avatar.header__avatar--border {
  border-color: #bf3d8f;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(182, 173, 173);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.post-main {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}
</style>
<style>
.content__style > a {
  color: rgba(var(--fe0, 0, 55, 107), 1);
}
.content__style > a:hover {
  text-decoration: underline;
}
.carousel-preview .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
}
.carousel-preview .el-carousel__container .el-carousel__arrow {
  transform: translateY(-30px);
}
.carousel-preview .el-carousel__container img {
  height: 190px;
}
</style>
