import { EMPTY_IMG } from '../Mixins/media.js'
export default {
  methods: {
    genSlideStoryAnalytic(pptx, dataStory, labels, text, title) {
      const slideStoryAnalytic = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideStoryAnalytic.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      slideStoryAnalytic.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 8.01,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 22,
      })

      slideStoryAnalytic.addText('合計投稿数', {
        shape: pptx.shapes.RECTANGLE,
        x: 0.64,
        y: 1.14,
        w: 2.7,
        h: 0.4,
        bold: true,
        align: 'center',
        valign: 'middle',
        color: '303030',
        fontSize: 12,
        line: { color: '303030' },
      })
      slideStoryAnalytic.addText(String(dataStory.storiesTotal), {
        x: 0.64,
        y: 1.77,
        w: 2.7,
        h: 0.5,
        bold: true,
        align: 'center',
        valign: 'middle',
        color: 'e7289e',
        fontSize: 24,
      })

      slideStoryAnalytic.addText('合計リーチ数', {
        shape: pptx.shapes.RECTANGLE,
        x: 4.16,
        y: 1.14,
        w: 2.7,
        h: 0.4,
        bold: true,
        align: 'center',
        valign: 'middle',
        valign: 'middle',
        fontSize: 12,
        line: { color: '303030' },
      })
      slideStoryAnalytic.addText(String(dataStory.reachTotal), {
        x: 4.16,
        y: 1.77,
        w: 2.7,
        h: 0.5,
        bold: true,
        align: 'center',
        valign: 'middle',
        color: 'e7289e',
        fontSize: 24,
      })

      slideStoryAnalytic.addText('合計インプレッション数', {
        shape: pptx.shapes.RECTANGLE,
        x: 7.68,
        y: 1.14,
        w: 2.7,
        h: 0.4,
        bold: true,
        align: 'center',
        valign: 'middle',
        color: '303030',
        fontSize: 12,
        line: { color: '303030' },
      })
      slideStoryAnalytic.addText(String(dataStory.impressionsTotal), {
        x: 7.68,
        y: 1.77,
        w: 2.7,
        h: 0.5,
        bold: true,
        align: 'center',
        valign: 'middle',
        color: 'e7289e',
        fontSize: 24,
      })
      if (dataStory.storiesTotal == 0 && dataStory.reachTotal == 0 && dataStory.impressionsTotal == 0) {
        slideStoryAnalytic.addImage({ path: EMPTY_IMG, x: 3.9, y: 2.5, w: 3.56, h: 3.56 })
      } else {
        // chart
        let opts = {
          x: 0.64,
          y: 2.59,
          w: 9.76,
          h: 3.31,
          barDir: 'col',
          barGrouping: 'clustered',
          catAxisLabelColor: '787878',
          catAxisLabelFontSize: 12,
          catAxisOrientation: 'minMax',
          showTitle: true,
          title: 'ストーリーズ指標推移',
          titleColor: '787878',
          titleFontSize: 12,
          showLegend: true,
          legendPos: 'b',
          chartColors: ['BCBCBC', '2BB6CE'],
          valAxes: [
            {
              showValAxisTitle: false,
              valAxisTitle: 'Primary Value Axis',
            },
            {
              showValAxisTitle: false,
              valAxisTitle: 'Secondary Value Axis',
              catAxisOrientation: 'maxMin',
              valGridLine: { style: 'none' },
            },
          ],
          catAxes: [
            {
              catAxisTitle: 'Primary Category Axis',
            },
            {
              catAxisHidden: true,
            },
          ],
        }

        let chartTypes = [
          {
            type: pptx.charts.BAR,
            data: [
              {
                name: 'リーチ数',
                labels: labels,
                values: dataStory.reachOfDay,
              },
              {
                name: 'インプレッション数',
                labels: labels,
                values: dataStory.impressionsOfDay,
              },
            ],
          },
          {
            type: pptx.charts.LINE,
            data: [
              {
                name: 'ストーリーズ数',
                labels: labels,
                values: dataStory.totalStoryOfDay,
              },
            ],
            options: {
              chartColors: ['e7299e', '1F3151'],
              lineDataSymbol: 'circle',
              secondaryValAxis: !!opts.valAxes,
              secondaryCatAxis: !!opts.catAxes,
            },
          },
        ]
        slideStoryAnalytic.addChart(chartTypes, opts)
        // end chart
      }

      slideStoryAnalytic.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.29,
        w: 10.14,
        h: 1.56,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slideStoryAnalytic
    },
  },
}
