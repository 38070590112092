<template>
  <div class="mt-3 md:ml-10">
    <el-select
      v-model="user"
      filterable
      size="large"
      placeholder="アカウント名を入力してください。"
      class="w-full md:w-96"
      suffix-icon=""
      @change="changeUser"
      clearable
    >
      <el-option v-for="item in listUsers" :key="item.id" :label="item.name" :value="item.id">
        <span style="float: left">{{ limit(item.name) }}</span>
        <span style="margin-left: 15px; float: right; color: var(--el-text-color-secondary); font-size: 13px">{{ item.roleName }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'InputFilterAccount',
  emits: ['changeUserSelected'],
  props: {
    type : { type: Number}
  },
  data() {
    return {
      listUsers: [],
      user: '',
    }
  },
  created() {
    this.getListUser()
  },
  methods: {
    getListUser: async function () {
      await axios
        .get(route('api.getListUserExport'))
        .then((res) => {
          this.listUsers = res?.data?.data?.map((v) => {
            return {
              id: v?.user_id,
              name: v?.name,
              roleName: v?.roleName,
              business_id: v.business_id
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    changeUser: function (event) {
      const user = this.listUsers.find((v) => v.id === event)
      this.$emit('changeUserSelected', user)
    },
    limit(item) {
      return item.length > 10 ? item.substr(0, 9) + '...' : item
    }
  },
}
</script>

<style></style>
