<template>
  <div class="p-4">
    <div class="text-request">
        対象期間のデータがありません。
    </div>
    <div class="analytics-panel-no-data-image">
        <img :src="'/img/no_data_chart_horizontal.svg'" alt="">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
