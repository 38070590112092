<template>
    <DrashBoard v-loading="loading">
        <LinkInstagram v-if="!showLinkInstagram" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li class="mr-4">
                    <Link :href="route('admin.post-advertisment.index')" class="font-normal breakcumb-active">
                    広告分析
                    </Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5 active">広告分析</li>
                </ul>
            </div>
        </template>
        <UserNav />
        <template v-slot:manual>
            <UserManual :isShow="isShowManual" :content="arrContent" @closeManualPopup="toogleManual" />
        </template>
        <div class="statistic md:px-14.5 sm:px-4 h-[90%] bg-background">
            <div class="flex justify-between items-center md:block">
                <div class="flex items-center md:relative">
                    <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                        <p class="text-link_pink text-3sm font-bold py-4">広告分析</p>
                        <div class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer" @click="toogleManual">
                            <img :src="suggestIcon" />
                        </div>
                    </div>
                    <!-- <UserManual :isShow="isShowManual" :content="arrContent" /> -->
                </div>
                <div class="bg-blue-600 text-white px-7 py-2 w-max rounded md:ml-auto sm:ml-0 block md:hidden">
                    <Link :href="route('admin.post-advertisment.index')" as="button" type="button" class="text-3sm">
                    +新規作成</Link>
                </div>
            </div>
            <div class="pt-2 md:py-5 mb-7">
                <div class="grid md:grid-cols-2 sm:grid-cols-1">
                    <div class="flex md:mb-0 sm:mb-4">
                        <el-input @keyup.enter="fetchData()" @change="fetchData()" clearable
                            placeholder="検索キーワードを入力してください" type="text" v-model="filter.content"
                            class="md:!w-96 sm:!w-72" size="large" />
                        <el-button @click.prevent="fetchData" class="el-button__primary w-20 ml-4" size="large">
                            <span>検索</span>
                        </el-button>
                    </div>
                    <div class="bg-blue-600 text-white px-7 py-2 w-max rounded md:ml-auto sm:ml-0 hidden md:block">
                        <Link :href="route('admin.post-advertisment.index')" as="button" type="button" class="text-3sm">
                        +新規作成</Link>
                    </div>
                </div>
                <data-table :fields="fields" :items="advertises.data" :paginate="advertises.meta"
                    :current-page="filter.page || 1" disable-table-info footer-center paginate-background
                    @page="handleCurrentPage" class="el-table-header__black mt-6">
                    <template #url_file="{ row }">
                        <template v-if="row?.type == 'carousel'">
                            <video v-if="isVideoType(row?.url_file[0])" controls width="112" style="height: 64px">
                                <source :src="row?.url_file[0]" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <img v-else class="w-28 h-16" :src="row?.url_file[0]" alt="" />
                        </template>
                        <template v-else>
                            <video v-if="isVideoType(row?.url_file)" controls width="112" style="height: 64px">
                                <source :src="row?.url_file" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <img v-else class="w-28 h-16" :src="row?.url_file" alt="" />
                        </template>
                    </template>

                    <template #message_ads="{ row }">
                        <p class="whitespace-pre-line limit-text min-h-[70px]">{{ row?.message_ads }}</p>
                        <p v-if="row" @click="viewDetailContent(row)"
                            class="text-xs text-button_profile font-semibold text-right block mt-2 hover:cursor-pointer">
                            全文を見る
                        </p>
                    </template>

                    <template #ads_set_start_time="{ row }">
                        <p>{{ formatTime(row?.ads_set_start_time) }}</p>
                    </template>

                    <template #ads_set_end_time="{ row }">
                        <p>{{ formatTime(row?.ads_set_end_time) }}</p>
                    </template>

                    <template #clicks="{ row }">
                        <p>{{ formatToInteger(row?.ad_insight?.clicks) }}</p>
                    </template>

                    <template #type="{ row }">
                        <p>{{ mapType(row?.type) }}</p>
                    </template>

                    <template #impressions="{ row }">
                        <p>{{ formatToInteger(row?.ad_insight?.impressions) }}</p>
                    </template>

                    <template #reach="{ row }">
                        <p>{{ formatToInteger(row?.ad_insight?.reach) }}</p>
                    </template>

                    <template #frequency="{ row }">
                        <p>{{ formatToFloat(row?.ad_insight?.frequency) }}</p>
                    </template>

                    <template #spend="{ row }">
                        <p>{{ formatToInteger(row?.ad_insight?.spend) }}</p>
                    </template>
                    <template #action="{ row }">
                        <a class="rounded button-shadow px-5 py-2 bg-blue-600 text-white block mx-auto"
                            :href="'/advertise-analysis/detail/' + row?.id">
                            確認する
                        </a>
                    </template>
                </data-table>

                <!-- dialog detail content -->
                <el-dialog v-model="dialogShowPost" width="50%" title="投稿内容" append-to-body>
                    <PopupReviewPost :content="postSelected.message_ads"
                        :file="postSelected?.type == 'carousel' ? postSelected.url_file[0] : postSelected.url_file"
                        :fileType="getTypeFile(postSelected?.type == 'carousel' ? postSelected.url_file[0] : postSelected.url_file)"
                        :carouselItems="postSelected?.type == 'carousel' ? postSelected.url_file : []"
                        :hashtag="postSelected.hashtag" :tag="postSelected.tag" :username="postAuthor" />
                </el-dialog>
                <!-- end dialog detail content -->
            </div>
        </div>
    </DrashBoard>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import DrashBoard from '@/Layouts/Authenticated.vue'
import DataTable from '@/Components/UI/DataTable.vue'
import UserNav from '@/Components/UserNav.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import PopupReviewPost from '@/Components/PopupReviewPost.vue'
import { getUserDetail } from '@/Api/Users'
import UserManual from '@/Components/UserManual.vue'
export default {
    components: { DrashBoard, DataTable, Link, BreadCrumb, UserNav, LinkInstagram, PopupReviewPost, UserManual },
    data() {
        return {
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowManual: false,
            arrContent: ['広告出稿を行った投稿のインプレッション数・リーチ数・フリークエンシー数などを確認することができます。', '目的に合った広告効果を発揮しているか、データを参考にしながら、適切な広告運用が行えているか確認することができます。'],
            loading: false,
            showLinkInstagram: true,
            filter: {
                content: '',
                page: 1,
            },
            advertises: [],
            fields: [
                { key: 'url_file', label: '投稿画像', width: 150, align: 'center' },
                { key: 'type', label: 'タイプ', width: 100, align: 'center' },
                { key: 'message_ads', label: '投稿内容', width: 400, align: 'center' },
                { key: 'ads_set_start_time', label: '開始日時', width: 200, align: 'center' },
                { key: 'ads_set_end_time', label: '終了日時', width: 200, align: 'center' },
                { key: 'clicks', label: 'Webサイトクリック数', width: 200, align: 'center' },
                { key: 'impressions', label: 'インプレッション数', width: 200, align: 'center' },
                { key: 'reach', label: 'リーチ数', width: 150, align: 'center' },
                { key: 'frequency', label: 'フリークエンシー数', width: 150, align: 'center' },
                { key: 'spend', label: '消化金額数', width: 150, align: 'center' },
                { key: 'action', label: '初速分析', width: 150, align: 'center' },
            ],
            dialogShowPost: false,
            postSelected: {},
            postAuthor: {},
        }
    },
    async created() {
        this.checkLinkInstagram()
        this.fetchData()
    },
    methods: {
        toogleManual() {
            this.isShowManual = !this.isShowManual
        },
        // link instagram
        async checkLinkInstagram() {
            const check = await accountAPI.checkInsAccount()
            if (check.data) {
                this.showLinkInstagram = true
            } else {
                this.showLinkInstagram = false
            }
        },
        handleCurrentPage(value) {
            this.filter.page = value
            this.fetchData()
        },
        async fetchData() {
            await axios
                .get(route('api.fb-ads.analysis', { ...this.filter }))
                .then((response) => {
                    this.advertises = response?.status == 200 ? response.data : this.advertises
                })
                .catch((e) => { })
        },
        formatTime(date) {
            if (!date) {
                return
            }
            const created_time = new Date(date * 1000)

            let day = created_time.getDate()
            let month = created_time.getMonth() + 1
            let year = created_time.getFullYear()
            let hour = created_time.getHours()
            let minute = (created_time.getMinutes() < 10 ? '0' : '') + created_time.getMinutes()

            return `${year}年${month}月${day}日 ${hour}:${minute}`
        },

        mapType(type) {
            let text = ''
            switch (type) {
                case 'carousel':
                    text = 'カルーセル'
                    break
                case 'image':
                    text = '画像'
                    break
                case 'video':
                    text = '動画'
                    break
                default:
                    text = 'undefine'
                    break
            }
            return text
        },
        isVideoType(filePath) {
            if (!filePath) return
            const arr = filePath.split('.')
            return arr[arr.length - 1] == 'mp4' || arr[arr.length - 1] == 'm4v'
        },
        getTypeFile(fileName) {
            return fileName.split('.').pop()
        },
        async viewDetailContent(post) {
            await this.getAccountPost(post)

            this.postSelected = post
            this.dialogShowPost = true
        },
        async getAccountPost(post) {
            if (!post?.ad_account?.user_id) return

            const account = await getUserDetail(post?.ad_account?.user_id)
            this.postAuthor = account.data.data
        },
    },
}
</script>

<style lang="scss" scoped>
.el-button__primary {
    background-color: #5264cd;
    border: 1px solid #5264cd;
}

.el-button__primary span {
    color: white !important;
}
</style>
<style>
.limit-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
</style>t
