<template>
    <Dashboard>
        <Loading v-if="isLoading" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li v-if="check_role_admin">
                    <Link :href="route('admin.users.index')" class="font-normal">ユーザー管理</Link>
                </li>
                <span v-if="check_role_admin" class="font-normal text-notice_date mx-2">></span>
                <li>
                    <Link :href="route('admin.users_infor')" class="font-normal breakcumb-active">ユーザー登録</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-8 tabs">
                <ul class="flex">
                    <li class="px-2 relative pb-5 active">ユーザー登録</li>
                </ul>
            </div>
        </template>
        <div class="md:p-12 pt-5 bg-background container-wrapper">
            <div class="text-pink-400 font-semibold text-3sm lg:ml-0 sm:ml-6">ユーザー登録</div>
            <!-- form -->
            <div class="md:container mt-2">
                <form @submit.prevent="submitForm" class="px-5 mobile-ml-form">
                    <!-- data auth login -->
                    <div class="grid md:grid-cols-2 sm:grid-cols-1 lg:gap-x-28 md:gap-x-10 py-4">
                        <div class="form-left">
                            <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">
                                    親アカウント
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="col-span-3">
                                    <el-select v-if="role_auth === 'master'" class="w-full rounded account-select"
                                        :class="form.errors.parent_user_id ? 'border border-red-600' : ''"
                                        v-model="form.parent_user.id" @change="handleChangeParentUser()" size="large">
                                        <el-option v-for="item in parent_users" :key="item.id" :label="item.text"
                                            :value="item.id" @change="form.errors.parent_user_id = ''" />
                                    </el-select>
                                    <input v-else v-model="form.parent_user.text" type="text"
                                        class="col-span-3 h-14 border rounded w-full disable-color" disabled />
                                    <div class="text-left text-red-600 text-nomal" v-if="form.errors.parent_user_id">
                                        {{ form.errors.parent_user_id }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-right">
                            <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">
                                    会社名
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="col-span-3">
                                    <input v-model="form.company" @change="form.errors.company = ''" type="text"
                                        class="col-span-3 h-14 border rounded w-full"
                                        :class="form.errors.company ? 'border-red-600' : 'border-input'"
                                        placeholder="会社名を入力" />
                                    <div v-if="form.errors.company" class="text-left text-red-600 text-nomal">
                                        {{ form.errors.company }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-left">
                            <div class="form-group align-items grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">アカウント名</label>
                                <input v-model="form.account_name" type="text"
                                    class="md:col-span-3 h-14 border border-input rounded" placeholder="アカウント名を入力" />
                            </div>
                        </div>
                        <div class="form-right">
                            <div class="form-group align-items grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">
                                    ジャンル
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="col-span-3">
                                    <el-select class="w-full rounded account-select"
                                        :class="form.errors.category ? 'border border-red-600 rounded' : ''"
                                        v-model="form.category" placeholder="ジャンルを入力" size="large">
                                        <el-option v-for="item in categories" :key="item" :label="item" :value="item"
                                            @click="resetValidate()" />
                                    </el-select>
                                    <div class="text-left text-red-600 text-nomal" v-if="form.errors.category">
                                        {{ form.errors.category }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="my-2" />
                    <!-- data new user -->
                    <!-- desktop -->
                    <div class="md:grid-cols-2 sm:grid-cols-1 lg:gap-x-28 md:gap-x-10 sm:gap-10 py-4 md:grid sm:hidden">
                        <div class="form-left">

                            <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">
                                    氏名（姓）
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="col-span-3">
                                    <input v-model="form.first_name" @change="form.errors.first_name = ''"
                                        :class="form.errors.first_name ? 'border-red-600' : 'border-input'" type="text"
                                        class="col-span-3 h-14 border rounded w-full" placeholder="氏名を入力" />
                                    <div class="text-left text-red-600 text-nomal" v-if="form.errors.first_name">
                                        {{ form.errors.first_name }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">
                                    メールアドレス
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="col-span-3">
                                    <input v-model="form.email" @change="form.errors.email = ''" type="email"
                                        :class="form.errors.email ? 'border-red-600' : 'border-input'"
                                        class="col-span-3 h-14 border rounded w-full" placeholder="メールアドレスを入力" />
                                    <div class="text-left text-red-600 text-nomal" v-if="form.errors.email">
                                        {{ form.errors.email }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                                <div class="zip_code">
                                    <label class="text-black col-span-1 block text-2sm">郵便番号</label>
                                    <span class="text-xs block mt-3">(ハイフンなし)</span>
                                </div>

                                <input v-model="form.zip_code" type="text"
                                    class="col-span-3 h-14 border rounded border-input" maxlength="7"
                                    @change="checkZipCode(form.zip_code)" placeholder="郵便番号を入力" />
                            </div>
                            <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">住所</label>
                                <input v-model="form.address" type="text"
                                    class="col-span-3 h-14 border rounded border-input" placeholder="住所を入力" />
                            </div>
                            <div v-if="isHiddenPass" class="form-group align-items mb-4 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">
                                    電話番号
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="col-span-3">
                                    <input v-model="form.phone" @change="form.errors.phone = ''" type="text"
                                        :class="form.errors.phone ? 'border-red-600' : 'border-input'"
                                        class="col-span-3 h-14 border rounded w-full" placeholder="電話番号を入力" />
                                    <div class="text-left text-red-600 text-nomal" v-if="form.errors.phone">
                                        {{ form.errors.phone }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-right">
                            <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">
                                    名前（名）
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="col-span-3">
                                    <input v-model="form.last_name" @change="form.errors.last_name = ''" type="text"
                                        :class="form.errors.last_name ? 'border-red-600' : 'border-input'"
                                        class="col-span-3 h-14 border rounded w-full" placeholder="名前を入力" />
                                    <div class="text-left text-red-600 text-nomal" v-if="form.errors.last_name">
                                        {{ form.errors.last_name }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="isHiddenPass" class="form-group align-items mb-4 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">パスワード
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="col-span-3">
                                    <input v-model="form.password" type="password"
                                        class="col-span-3 w-full h-14 border rounded border-input"
                                        placeholder="パスワードを入力" />
                                    <div class="text-left text-red-600 text-nomal" v-if="form.errors.password">
                                        {{ form.errors.password }}
                                    </div>
                                </div>
                            </div>
                            <div v-else class="form-group align-items mb-4 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">
                                    電話番号
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="col-span-3">
                                    <input v-model="form.phone" @change="form.errors.phone = ''" type="text"
                                        :class="form.errors.phone ? 'border-red-600' : 'border-input'"
                                        class="col-span-3 h-14 border rounded w-full" placeholder="電話番号を入力" />
                                    <div class="text-left text-red-600 text-nomal" v-if="form.errors.phone">
                                        {{ form.errors.phone }}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group align-items mb-4 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">都道府県</label>
                                <input v-model="form.address_1" type="text"
                                    class="col-span-3 h-14 border rounded border-input" placeholder="都道府県を入力" />
                            </div>
                            <div class="form-group align-items grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">市区町村</label>
                                <input v-model="form.address_2" type="text"
                                    class="col-span-3 h-14 border rounded border-input" placeholder="市区町村を入力" />
                            </div>
                        </div>
                    </div>

                    <!-- mobile -->
                    <div
                        class="grid md:grid-cols-2 sm:grid-cols-1 lg:gap-x-28 md:gap-x-10 sm:gap-10 py-4 sm:grid md:hidden">
                        <div class="form-left">
                            <div class="form-group align-items mb-2 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">
                                    氏名（姓）
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="col-span-3">
                                    <input v-model="form.first_name" @change="form.errors.first_name = ''"
                                        :class="form.errors.first_name ? 'border-red-600' : 'border-input'" type="text"
                                        class="col-span-3 h-14 border rounded w-full" placeholder="氏名を入力" />
                                    <div class="text-left text-red-600 text-nomal" v-if="form.errors.first_name">
                                        {{ form.errors.first_name }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group align-items mb-2 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">
                                    名前（名）
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="col-span-3">
                                    <input v-model="form.last_name" @change="form.errors.last_name = ''" type="text"
                                        :class="form.errors.last_name ? 'border-red-600' : 'border-input'"
                                        class="col-span-3 h-14 border rounded w-full" placeholder="名前を入力" />
                                    <div class="text-left text-red-600 text-nomal" v-if="form.errors.last_name">
                                        {{ form.errors.last_name }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group align-items mb-2 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">
                                    電話番号
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="col-span-3">
                                    <input v-model="form.phone" @change="form.errors.phone = ''" type="text"
                                        :class="form.errors.phone ? 'border-red-600' : 'border-input'"
                                        class="col-span-3 h-14 border rounded w-full" placeholder="電話番号を入力" />
                                    <div class="text-left text-red-600 text-nomal" v-if="form.errors.phone">
                                        {{ form.errors.phone }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group align-items mb-2 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">
                                    メールアドレス
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="col-span-3">
                                    <input v-model="form.email" @change="form.errors.email = ''" type="email"
                                        :class="form.errors.email ? 'border-red-600' : 'border-input'"
                                        class="col-span-3 h-14 border rounded w-full" placeholder="メールアドレスを入力" />
                                    <div class="text-left text-red-600 text-nomal" v-if="form.errors.email">
                                        {{ form.errors.email }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group align-items mb-2 grid grid-cols-4 items-center">
                                <div class="zip_code">
                                    <label class="text-black col-span-1 block text-2sm">郵便番号</label>
                                    <span class="text-xs block mt-3">(ハイフンなし)</span>
                                </div>

                                <input v-model="form.zip_code" type="text"
                                    class="col-span-3 h-14 border rounded border-input" maxlength="7"
                                    @change="checkZipCode(form.zip_code)" placeholder="郵便番号を入力" />
                            </div>
                            <div class="form-group align-items mb-2 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">都道府県</label>
                                <input v-model="form.address_1" type="text"
                                    class="col-span-3 h-14 border rounded border-input" placeholder="都道府県を入力" />
                            </div>
                            <div class="form-group align-items mb-2 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">市区町村</label>
                                <input v-model="form.address_2" type="text"
                                    class="col-span-3 h-14 border rounded border-input" placeholder="市区町村を入力" />
                            </div>
                            <div class="form-group align-items mb-2 grid grid-cols-4 items-center">
                                <label class="text-black col-span-1 text-2sm">住所</label>
                                <input v-model="form.address" type="text"
                                    class="col-span-3 h-14 border rounded border-input" placeholder="住所を入力" />
                            </div>
                        </div>
                    </div>
                    <!-- end data new user -->

                    <hr class="my-2" />
                    <div class="grid grid-cols-payment gap-2 py-4">
                        <p class="text-2sm flex items-center">
                            権限
                            <span class="text-red-500">*</span>
                        </p>
                        <div class="grid lg:grid-cols-4 md:grid-cols-2">
                            <div class="col-span-4 text-left text-red-600 text-nomal pb-2" v-if="form.errors.role">
                                {{ form.errors.role }}
                            </div>
                            <template v-for="(role, index) in roles" :key="index">
                                <div class="flex items-center my-2">
                                    <input id="first" type="radio" name="role" v-model="form.role" @change="changeRole"
                                        :value="role.label" class="mr-2 border-input text-pink-400"
                                        :disabled="role.checkRole" />
                                    <label for="first" class="text-2sm">{{ role.name }}</label>
                                </div>
                            </template>
                        </div>
                    </div>

                    <hr class="my-2" v-if="check_role_set_plan" />
                    <!-- change amount plan for role-user -->
                    <div class="grid grid-cols-payment gap-2 py-4 items-center" v-if="check_role_set_plan">
                        <label class="text-2sm">
                            プラン選択
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="flex flex-wrap items-center">
                            <!-- <div class="w-full" v-if="form.role === 'user_oem'">
                                <button @click="fetchDataPlan()" type="button"
                                    class="text-white bg-button_profile px-3 py-2 text-xs rounded-sm mb-5">
                                    プラン料金をリセットする
                                </button>
                            </div> -->
                            <template v-for="(plan, index) in form.plans" :key="index">
                                <div class="flex md:w-1/2 sm:w-full items-center my-2">
                                    <input v-model="plan.checkbox" @change="choosePlanActive(plan.id)" type="checkbox"
                                        :value="index" class="mr-2 border-input text-pink-400" />
                                    <p class="px-3 w-full md:w-1/3 overflow-x-hidden text-2sm">{{ plan.name }}</p>
                                    <!-- <template v-if="form.role === 'user_oem' && plan.free == 0">
                                        <input v-model="plan.amount" min="50" maxlength="7" type="number"
                                            class="rounded-sm p-1 w-24 border-gray-200"
                                            @change="updateAmountValue($event, index)" />
                                        <p class="px-3 text-2sm">{{ plan.amount == 0 ? 0 : '' }}円 （税抜）</p>
                                    </template> -->
                                </div>
                            </template>
                            <div class="col-span-4 text-left text-red-600 text-nomal pb-2 w-full"
                                v-if="form.errors.plans">
                                {{ form.errors.plans }}
                            </div>
                        </div>
                    </div>
                    <hr class="my-2" v-if="isPassCreated" />
                    <!-- change amount plan for role-user -->
                    <div class="grid grid-cols-payment gap-2 py-4 items-center" v-if="isPassCreated">
                        <label class="text-2sm">
                            通知設定
                            <span class="text-red-500">*</span>
                        </label>
                        <div class="flex flex-wrap items-center">
                            <div class="flex md:w-1/2 sm:w-full items-center my-2">
                                <input v-model="form.type_register" type="radio" name="typeRegister" value="0"
                                    class="mr-2 border-input text-pink-400" />
                                <p class="px-3 w-full md:w-1/3 overflow-x-hidden text-2sm">配下のユーザーに通知する</p>
                            </div>
                            <div class="flex md:w-1/2 sm:w-full items-center my-2">
                                <input v-model="form.type_register" type="radio" name="typeRegister" value="1"
                                    class="mr-2 border-input text-pink-400" />
                                <p class="px-3 w-full md:w-1/3 overflow-x-hidden text-2sm">配下のユーザーに通知しない</p>
                            </div>
                            <!-- <div class="col-span-4 text-left text-red-600 text-nomal pb-2 w-full"
                                v-if="form.errors.plans">
                                {{ form.errors.plans }}
                            </div> -->
                        </div>
                    </div>
                    <!-- <div v-if="form.type_register == 1" class="grid grid-cols-payment gap-2 py-4 items-center">
                        <label class="text-black col-span-1 text-2sm">パスワード
                            <span class="text-red-500">*</span>
                        </label>
                        <input v-model="form.password" type="password" class="w-1/2 h-14 border rounded border-input"
                            placeholder="パスワード" />
                    </div> -->

                    <!-- upload contract -->
                    <hr class="my-2" v-if="check_role_set_plan" />
                    <div class="align-items my-4 grid grid-cols-payment gap-2 py-4 items-center col-span-3">
                        <label class="text-2sm">
                            契約書アップロード
                            <span v-if="isRole == 'master'" class="text-red-500">*</span>
                        </label>
                        <div v-show="nameFileContract == null" class="flex items-center">
                            <button @click="fileUploadContract()" type="button"
                                class="text-white bg-button_profile px-8 py-2 text-xs rounded-sm">
                                アップロード
                            </button>
                            <input type="file" hidden ref="uploadContract" @input="onSelectFileContract"
                                accept="application/pdf" />
                            <div class="text-left text-red-600 text-nomal mt-2">{{ form.errors.contact ?? null }}</div>
                        </div>
                        <div v-show="nameFileContract != null" class="flex items-center">
                            <p class="px-5 text-2sm">{{ nameFileContract }}</p>
                            <p class="px-5 text-2sm text-button_pink underline cursor-pointer"
                                @click="fileUploadContract()">
                                アップロードファイルを変更する
                            </p>
                            <div class="text-left text-red-600 text-nomal mt-2">{{ form.errors.contact ?? null }}</div>
                        </div>
                    </div>
                    <!-- upload avt -->

                    <hr class="my-2" v-if="check_role_set_plan" />
                    <div v-if="!isUserNormal"
                        class="align-items mb-4 grid grid-cols-payment py-4 gap-2 items-center col-span-3">
                        <label class="text-2sm">ロゴアップロード</label>
                        <div>
                            <div class="pt-2 w-full">
                                <button v-if="urlLogo == null" @click="fileUploadLogo()" type="button"
                                    class="text-white bg-button_profile px-8 py-2 text-xs rounded-sm">
                                    アップロード
                                </button>
                                <div v-else class="flex items-center">
                                    <img :src="urlLogo" alt="Logo" class="w-44 h-auto" />
                                    <div class="flex-1">
                                        <p class="px-5 custom-file">{{ nameFileLogo }}</p>
                                        <p class="px-5 text-button_pink underline cursor-pointer whitespace-nowrap"
                                            @click="fileUploadLogo()">
                                            ロゴを変更する
                                        </p>
                                        <div v-if="form.errors.logo" class="text-left text-red-600 mt-2 w-96">
                                            {{ form.errors.logo }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-left text-red-600 text-sm mt-2">{{ form.errors.logo ?? null }}
                            </div>
                            <UploadImage field="logo" @crop-success="cropLogoSuccess"
                                @crop-upload-success="cropUploadSuccess" @crop-upload-fail="cropUploadFail"
                                v-model="showCropLogo" :width="195" :height="40" langType="ja" img-format="png">
                            </UploadImage>
                        </div>
                    </div>

                    <hr class="my-2" v-if="!isUserNormal" />

                    <div v-if="role_auth != 'master'" class="w-full flex items-center justify-center mt-12">
                        <input v-model="agreeTerms" type="checkbox" :checked="role_auth == 'master'"
                            class="mr-2 border-input text-pink-400 cursor-pointer" />
                        <p class="sm:text-sm md:text-2sm">
                            <a href="/term" target="_blank">
                                <span class="text-button_pink hover:cursor-pointer">利用規約・</span>
                            </a>
                            <a href="/privacy" target="_blank">
                                <span class="text-button_pink hover:cursor-pointer">プライバシーポリシ</span>
                            </a>
                            ーに同意する
                        </p>
                    </div>
                    <div class="flex items-center justify-center my-12 w-full sm:gap-2 md:gap-0">
                        <button
                            class="md:py-5.5 sm:py-4 bg-button_pink md:w-72 sm:w-1/2 px-10 mr-4 text-white button-shadow rounded text-3sm"
                            @click.prevent="goBack">
                            取り消し
                        </button>
                        <button type="submit"
                            class="md:py-5.5 sm:py-4 bg-blue-600 md:w-72 sm:w-1/2 md:px-10 md:mr-4 text-white button-shadow rounded text-3sm"
                            :disabled="role_auth == 'master' ? agreeTerms : !agreeTerms"
                            :class="{ button_disabled: role_auth == 'master' ? agreeTerms : !agreeTerms }">
                            上記内容で登録する
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </Dashboard>
</template>

<script>
import Dashboard from '@/Pages/Dashboard.vue'
import { Link, useForm } from '@inertiajs/inertia-vue3'
import Multiselect from '@suadelabs/vue3-multiselect'
import { Back } from '@element-plus/icons-vue'
import Loading from '@/Components/Loadding'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { getDataZipCode } from '@/Api/Users'
import UploadImage from 'vue-image-crop-upload'

export default {
    components: {
        Dashboard,
        Link,
        Multiselect,
        Back,
        useForm,
        Loading,
        BreadCrumb,
        UploadImage
    },

    computed: {
        isRole() {
            return this.$page.props?.auth?.user?.roles[0]?.name
        },
    },

    data() {
        return {
            showCropLogo: false,
            form: useForm({
                last_name: '',
                first_name: '',
                email: '',
                phone: '',
                company: '',
                zip_code: '',
                address: '',
                address_1: '',
                address_2: '',
                parent_user: {
                    id: null,
                    text: null,
                },
                role: '',
                plan_id: null,
                parent_user_id: null,
                account_name: '',
                parent_user_name: null,
                plans: [],
                logo: null,
                contact: null,
                category: '',
                type_register: '0',
                password: null,
            }),
            parent_users: [],
            roles: [],
            isUserNormal: false,
            isPassCreated: true,
            check_role_set_plan: false,
            check_role_admin: false,
            role_auth: '',
            urlLogo: null,
            nameFileLogo: null,
            nameFileContract: null,
            agreeTerms: false,
            isLoading: false,
            categories: [
                '美容',
                'ショッピング',
                'スタイル',
                '食べ物/グルメ',
                '動物',
                'インテリアデザイン',
                'DIY',
                '建築物',
                'アート',
                'コミック',
                '自然',
                'テレビ/映画',
                '旅行',
                '雑貨',
                'ファッション',
                'フィットネス',
                'ガジェット',
            ],
            isHiddenPass: false
        }
    },

    created() {
        this.$page.props.auth.user.roles.map((role) => {
            if (role.name == 'master' || role.name == 'admin_oem') {
                return (this.check_role_set_plan = true), (this.role_auth = role.name)
            } else {
                return (this.check_role_set_plan = false), (this.role_auth = role.name)
            }
        })

        this.form.role =
            this.role_auth == 'master' ? 'admin_agency' : this.role_auth == 'admin_oem' ? 'user_oem' : 'user_enterprise'
        this.fetchDataPlan()
        this.fetchDataParentUser()
        this.fetchDataRole()
        this.checkRoleAdmin()
    },

    methods: {
        cropLogoSuccess(imgDataUrl) {
            this.urlLogo = imgDataUrl
            this.form.logo = this.dataURLtoFile(imgDataUrl, 'img_logo')
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n)

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }

            return new File([u8arr], filename, { type: mime })
        },
        cropUploadSuccess(jsonData, field) {
            console.log('-------- upload success --------')
            if (field == 'img_logo') {
                console.log(jsonData, field)
            } else {
                console.log(jsonData, field)
            }
        },

        cropUploadFail(status, field) {
            console.log('-------- upload fail --------')
            if (field == 'img_logo') {
                console.log(status, field)
            } else {
                console.log(status, field)
            }
        },
        resetValidate() {
            this.form.errors.category = ''
        },
        goBack() {
            return this.$inertia.visit(route('admin.users.index'))
        },
        async fetchDataPlan(page = null) {
            //   this.isLoading = true
            await axios
                .get('/api/plans?nolimit')
                .then((res) => {
                    this.form.plans = res.data.data
                        .filter((plan) => {
                            return plan.is_show_admin == 1
                        })
                        .map((plan) => ({
                            id: plan.id,
                            name: plan.name,
                            amount: plan.amount,
                            free: plan.free,
                            checkbox: false,
                        }))
                    this.isLoading = false
                })
                .catch((err) => {
                    console.error(err)
                    this.isLoading = false
                })
        },
        checkRoleAdmin() {
            this.$page.props.auth.user.roles.filter((role) => {
                if (
                    role.name == 'user_agency' ||
                    role.name == 'user_oem' ||
                    role.name == 'user_enterprise' ||
                    role.name == 'user_normal'
                ) {
                    this.check_role_admin = false
                } else {
                    this.check_role_admin = true
                }
            })
        },
        async fetchDataRole() {
            // this.isLoading = true
            await axios
                .get('/api/roles')
                .then((res) => {
                    this.isLoading = true
                    this.roles = res.data.map((role) => ({
                        ...role,
                        checkRole:
                            (this.role_auth == 'admin_enterprise' && role.label == 'user_enterprise') ||
                                (this.role_auth == 'admin_oem' && role.label == 'user_oem') ||
                                this.role_auth == 'master'
                                ? false
                                : 'disabled',
                    }))
                    this.isLoading = false
                })
                .catch((err) => {
                    console.error(err)
                    this.isLoading = false
                })
        },
        handleChangeParentUser() {
            console.log(this.form.parent_user);
        },
        async fetchDataParentUser() {
            if (['admin_agency', 'admin_oem', 'admin_enterprise'].includes(this.$page.props.auth.user.roles[0].name)) {
                this.isLoading = true
                this.form.parent_user.text = this.$page.props.auth.user.first_name + ' ' + this.$page.props.auth.user.last_name
                this.form.parent_user.id = this.$page.props.auth.user.id
                this.isLoading = false
                return
            }
            if (this.form.role !== '') {
                this.isLoading = true
                await axios
                    .get('/api/parent_user?role=' + this.form.role)
                    .then((res) => {
                        this.parent_users = res.data.data
                            .filter((user) => {
                                return (
                                    user.role_name == 'master' ||
                                    user.status != '解約済' &&
                                    user.status != '契約終了' &&
                                    user.status != '未決済' &&
                                    user.status != '解約中'
                                )
                            })
                            .map((user) => ({
                                text: user.full_name,
                                id: user.id,
                            }))
                        // this.form.parent_user = this.parent_users[0]
                        this.isLoading = false
                    })
                    .catch((err) => {
                        this.isLoading = false
                    })
            }
        },

        submitForm() {
            this.isLoading = true
            this.form.parent_user_id = this.form.parent_user?.id
            this.form.parent_user_name = this.form.parent_user?.text
            this.form.logo = this.form.role.split('_')[0] === 'user' ? null : this.form.logo
            this.form.post(route('api.users.create_local'), {
                onFinish: () => {
                    if (!this.form.hasErrors) {
                        this.$toast.success(this.$t('create account successfully'))
                        this.$inertia.get(route('admin.users.index'))
                    } else {
                        console.log(this.form.errors)
                        // this.$toast.error(this.$t('an error occurred during create account'))
                    }
                    this.isLoading = false
                },
            })
        },

        changeRole() {
            this.fetchDataParentUser()
            this.fetchDataPlan()
            this.form.errors.email = ''
            // this.form.parent_user.id = this.$page.props.auth.user.id
            // this.form.parent_user.text = this.$page.props.auth.user.first_name + ' ' + this.$page.props.auth.user.last_name
        },

        choosePlanActive(plan_id) {
            this.form.plans = this.form.plans.map((plan) => ({
                id: plan.id,
                name: plan.name,
                amount: plan.amount,
                checkbox: plan.id == plan_id ? true : false,
                free: plan.free,
            }))
            this.form.errors.plans = false
        },

        fileUploadLogo() {
            this.form.logo = null
            this.showCropLogo = !this.showCropLogo
        },

        fileUploadContract() {
            // this.form.contact = null
            this.$refs.uploadContract.click()
        },

        onSelectFileContract(e) {
            let files = e.target.files || e.dataTransfer.files
            let array_application_type = ['application/pdf']
            if (array_application_type.includes(files[0]['type'])) {
                this.nameFileContract = files[0].name
                this.form.contact = files[0]
                this.form.errors.contact = ''
            } else {
                this.form.errors.contact = 'pdf ファイルを使用してください。'
            }
        },

        onSelectFileLogo(e) {
            let files = e.target.files || e.dataTransfer.files
            let array_img_type = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg']
            if (array_img_type.includes(files[0]['type'])) {
                this.urlLogo = URL.createObjectURL(files[0])
                this.nameFileLogo = files[0].name
                this.form.logo = files[0]
                this.form.errors.logo = ''
            } else {
                this.form.errors.logo = 'jpeg, jpg, png, gif, svg ファイルを使用してください。'
            }
        },

        async checkZipCode(zip_code) {
            await getDataZipCode(zip_code)
                .then(async (res) => {
                    if (res.data.results !== null) {
                        let data = res.data.results[0]
                        this.form.address = data.address3
                        this.form.address_1 = data.address1
                        this.form.address_2 = data.address2
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        },

        updateAmountValue(event, index) {
            let value = event.target.value
            let num = value.match(/^[0-9]+$/)
            if (num === null) {
                this.form.plans[index].amount = null
            } else {
                this.form.plans[index].amount = Math.abs(value.slice(0, 7))
            }
        },
    },
    watch: {
        'form.role'(val) {
            this.isUserNormal = val.split('_')[0] === 'user' ? true : false
            this.isPassCreated = ['admin_agency', 'admin_oem'].includes(val) ? true : false
            if (!['user_agency', 'user_oem', 'user_enterprise'].includes(val)) {
                this.form.parent_user.id = this.$page.props.auth.user.id
                this.form.parent_user.text = this.$page.props.auth.user.first_name + ' ' + this.$page.props.auth.user.last_name
            } else {
                this.form.parent_user.id = null
                this.form.parent_user.text = null

            }
        },
        'form.parent_user.id'(val) {
            if (val != null) {
                this.form.errors.parent_user_id = ''
                let parent_id = val
                axios.get(route("api.users.parent_user_info", parent_id)).then((res) => {
                    this.isHiddenPass = res.data == 1 ? true : false
                })
            }
        },
        'form.password'(val) {
            if (val !== '') {
                this.form.errors.password = ''
            }
        }
    },
}
</script>
<style src="@suadelabs/vue3-multiselect/dist/vue3-multiselect.css">

</style>
<style>
.button_disabled {
    background: #e3e3e3;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
}

input.multiselect__input:focus {
    --tw-ring-shadow: transparent;
}

.account-select .el-input__inner {
    height: 56px;
}

@media (max-width: 640px) {
    .form-group.grid-cols-4 input {
        width: 100%;
    }
}
</style>
<style scoped>
@media (max-width: 640px) {
    .form-group.grid-cols-4 {
        grid-template-columns: auto;
        gap: 0.5rem;
        margin-bottom: 1rem;
    }
}

@media (max-width: 840px) {
    .zip_code {
        display: flex;
        gap: 0 10px;
        align-items: center;
    }

    .zip_code .mt-3 {
        margin: 0;
    }

    .form-right,
    .form-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .form-left .grid-cols-4,
    .form-right .grid-cols-4 {
        grid-template-columns: auto;
        gap: 0.5rem;
        margin-bottom: 1rem;
    }

    .disable-color {
        color: #81858b;
    }
}
</style>
<style lang="scss">
.vicp-operate a:first-child {
    background: #be3d8f;
    color: white !important;

    &:hover {
        background: #be3d8f !important;
    }
}

.vicp-operate-btn {
    background: #5264cd !important;
    border-radius: 4px !important;
    color: white !important;

    &:hover {
        background: #5264cd !important;
    }
}
</style>
