<template>
  <div class="sm:px-0 md:px-10 pb-20 mt-5">
    <div class="bg-background md:px-10 sm:px-5 py-5">
      <div class="flex justify-between items-center">
        <h2 class="text-button_pink !text-[14px] font-semibold py-5">投稿数の推移</h2>
        <button
          v-if="this.$page.props?.auth?.user?.roles[0]?.name === 'master'"
          :disabled="numberExportPostAnalysis <= 0 && isRole !== 'master'"
          @click="exportPostCsv"
          class="bg-black ml-3 py-3 px-7 text-white !text-[14px] button-shadow rounded"
        >
          CSV出力
        </button>

        <el-tooltip
          v-else
          class="tooltip-customer"
          effect="dark"
          :content="'投稿数の推移画面今月後 ' + numberExportPostAnalysis + ' 回CSV出力出来ます。'"
          placement="top"
        >
          <button
            :disabled="numberExportPostAnalysis <= 0 && isRole !== 'master'"
            @click="exportPostCsv"
            class="bg-black ml-3 py-3 px-7 text-white !text-[14px] button-shadow rounded"
          >
            CSV出力
          </button>
        </el-tooltip>
      </div>

      <div class="relative bg-white z-10 md:px-5 md:py-5 sm:px-3 sm:py-3 lg:mt-0 sm:mt-5">
        <div class="flex-1 flex justify-end relative post-calendar my-2.5">
          <el-date-picker
            v-model="dateRange"
            :type="'daterange'"
            size="large"
            range-separator="-"
            start-placeholder="Start date"
            end-placeholder="End date"
            :disabled-date="disabledDate"
          />
        </div>
        <el-empty v-if="!isData" description="データはありません" />
        <ChartAnalytic
          v-if="chartData.labels && chartData.labels.length > 0 && !loading && isData"
          :width="500"
          :height="heigthChart"
          :dataChart="chartData"
          :max_engagement="max_engagement"
          :max_post="max_post"
          :canvasId="canvasId"
        />
        <div class="" v-else v-loading="loading" />
      </div>

      <div
        class="bg-white md:px-5 md:py-5 sm:px-3 sm:py-3 mt-5 z-0 overflow-y-scroll"
        v-loading="loading"
        v-if="isData"
      >
        <table class="table-fixed overflow-y-scroll w-full">
          <thead>
            <tr>
              <th
                class="text-sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2 whitespace-nowrap"
                style="width: 150px"
              >
                日付
              </th>
              <th
                class="text-sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2 whitespace-nowrap"
                v-for="(item, index) in label"
                :key="index"
                style="width: 150px"
              >
                {{ item.text }}
              </th>
              <th
                class="text-sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2 whitespace-nowrap"
                style="width: 150px"
              >
                エンゲージメント率
              </th>
            </tr>
          </thead>
          <tbody class="bg-body_table overflow-y-scroll">
            <tr class="!text-2sm" v-for="(item, index) in tableData" :key="index">
              <td class="py-3.5">
                <p class="text-center text-xs">
                  {{ formatTime(item.postCreated) }}
                </p>
              </td>
              <td class="py-3.5" v-for="(label, index) in label" :key="index">
                <p class="text-center text-xs">{{ item[label.key] }}</p>
              </td>
              <td class="py-3.5">
                <p class="text-center text-xs">{{ item.Reach }}%</p>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="isData != 0">
          <p
            class="text-right !text-2sm hover:cursor-pointer text-button_profile font-semibold block mt-2"
            v-if="!isSeemore"
            @click="hiddenData()"
          >
            隠れる
          </p>
          <p
            class="text-right !text-2sm hover:cursor-pointer text-button_profile font-semibold block mt-2"
            v-if="isSeemore"
            @click="getFullData()"
          >
            全文を見る
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import ChartAnalytic from './ChartAnalytic.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import { Link } from '@inertiajs/inertia-vue3'
import * as postAPI from '@/Api/Posts'
import UserNav from '@/Components/UserNav.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import dayjs from 'dayjs'
import { fetchNumberExport, addNumberExport } from './Mixins/limitExport.js'
import { formatToInteger } from '@/Plugins/numberFormat'

export default {
  props: ['account_id'],
  props: {
    canvasId: { type: String },
    userId: { type: Number },
    plan: { type: Object, require: true },
  },
  emits: ['getAnalyticPostTableData', 'getAnalyticPostRange'],
  components: {
    DrashBoard,
    UserNav,
    ChartAnalytic,
    BreadCrumb,
    Link,
    LinkInstagram,
  },

  computed: {
    isRole() {
      return this.$page.props?.auth?.user?.roles[0]?.name
    },
  },

  data() {
    const date = new Date()
    const since = new Date(date.getFullYear(), date.getMonth(), 1)
    const until = new Date()
    return {
      numberExportPostAnalysis: 0,
      loading: false,
      valueDate: '',
      isSeemore: true,
      showLinkInstagram: true,
      label: [
        { text: '投稿数', key: 'sumPost' },
        { text: 'いいね数', key: 'sumLike' },
        { text: 'コメント数', key: 'sumComment' },
        { text: 'リーチ数', key: 'sumReach' },
      ],
      tableData: [],
      isData: true,
      dateRange: [since, until],
      chartData: {
        datasets: [
          {
            label: 'いいね数',
            data: [],
            type: 'line',
            backgroundColor: '#C71585',
            borderColor: '#C71585',
            lineTension: 0.5,
          },
          {
            label: 'コメント数',
            data: [],
            type: 'line',
            borderColor: '#000D8D',
            backgroundColor: '#000D8D',
            lineTension: 0.5,
          },
          {
            id: 3,
            label: 'エンゲージメント率',
            data: [],
            type: 'line',
            yAxisID: 'B',
            borderColor: '#7FFFD4',
            backgroundColor: '#7FFFD4',
            lineTension: 0.5,
          },
          {
            label: 'リーチ数',
            data: [],
            type: 'line',
            borderColor: '#6A5ACD',
            backgroundColor: '#6A5ACD',
            lineTension: 0.5,
          },
          {
            label: '投稿数',
            data: [],
            type: 'bar',
            yAxisID: 'A',
            backgroundColor: '#F0C478',
            borderColor: '#F0C478',
            borderWidth: 4,
          },
        ],
        labels: [],
      },
      max_engagement: 0,
      max_post: 0,
    }
  },
  computed: {
    heigthChart() {
      let widthScreen = screen.width
      if (widthScreen > 640) {
        return 400
      } else {
        return 1050
      }
    },
  },
  created() {
    const offset = this.dateRange[0].getTimezoneOffset()
    this.dateRange[0] = new Date(new Date().setDate(new Date().getDate() - 30))
    this.dateRange[1] = new Date(new Date().setDate(new Date().getDate() - 1))
    this.getData(this.dateRange[0].toISOString().split('T')[0], this.dateRange[1].toISOString().split('T')[0])
    this.$emit('getAnalyticPostRange', this.dateRange)

    this.fetchNumberExportMonth()
  },
  methods: {
    disabledDate(date) {
      let d = new Date()
      return date > d.setDate(d.getDate() - 1)
    },
    async getData(since, until) {
      const res = await postAPI.analyticsPost(this.$page.props.auth.account.business_id, since, until)
      this.chartData.labels = res?.data.data.postAnalytic.map((postAnalytic) =>
        postAnalytic.postCreated.replaceAll('-', '/')
      )
      this.chartData.datasets[0].data = res.data.data.postAnalytic.map((postAnalytic) => postAnalytic.sumLike)
      this.chartData.datasets[1].data = res.data.data.postAnalytic.map((postAnalytic) => postAnalytic.sumComment)
      this.chartData.datasets[3].data = res.data.data.postAnalytic.map((postAnalytic) => postAnalytic.sumReach)
      this.chartData.datasets[4].data = res.data.data.postAnalytic.map((postAnalytic) => postAnalytic.sumPost)
      this.chartData.datasets[2].data = res.data.data.postAnalytic.map((postAnalytic) =>
        this.checkReach(postAnalytic.sumLike, postAnalytic.sumComment, postAnalytic.sumImpressions)
      )
      this.$emit(
        'getAnalyticPostTableData',
        res?.data.data.postAnalytic.map((postAnalytic) => {
          return [
            postAnalytic.postCreated.slice(0, 4) +
              '年' +
              postAnalytic.postCreated.slice(5, 7) +
              '月' +
              postAnalytic.postCreated.slice(8, 10) +
              '日',
            postAnalytic.sumPost,
            postAnalytic.sumLike,
            postAnalytic.sumComment,
            postAnalytic.sumReach,
            this.checkReach(postAnalytic.sumLike, postAnalytic.sumComment, postAnalytic.sumImpressions) + '%',
          ]
        })
      )

      await this.getMaxLineChart(this.chartData.datasets)
      await this.checkIsDate(res.data.data.postAnalytic)
      this.hiddenData()
      // this.loading = false
    },
    async getFullData() {
      const res = await postAPI.analyticsPost(
        this.$page.props.auth.account.business_id,
        this.dateRange[0].toISOString().split('T')[0],
        this.dateRange[1].toISOString().split('T')[0]
      )
      this.tableData = res.data.data.postAnalytic.map((postAnalytic) => ({
        postCreated: postAnalytic.postCreated,
        sumPost: this.formatToInteger(postAnalytic.sumPost),
        sumLike: this.formatToInteger(postAnalytic.sumLike),
        sumComment: this.formatToInteger(postAnalytic.sumComment),
        sumReach: this.formatToInteger(postAnalytic.sumReach),
        Reach: this.formatToFloat(
          this.checkReach(postAnalytic.sumLike, postAnalytic.sumComment, postAnalytic.sumImpressions)
        ),
      }))
      this.isSeemore = false
    },
    async getAnalyticPostTableData() {
      const res = await postAPI.analyticsPost(
        this.$page.props.auth.account.business_id,
        this.dateRange[0].toISOString().split('T')[0],
        this.dateRange[1].toISOString().split('T')[0]
      )
      this.isSeemore = false
    },
    async hiddenData() {
      const res = await postAPI.analyticsPost(
        this.$page.props.auth.account.business_id,
        this.dateRange[0].toISOString().split('T')[0],
        this.dateRange[1].toISOString().split('T')[0]
      )
      this.tableData = res.data.data.postAnalytic
        .map((postAnalytic) => ({
          postCreated: postAnalytic.postCreated,
          sumPost: this.formatToInteger(postAnalytic.sumPost),
          sumLike: this.formatToInteger(postAnalytic.sumLike),
          sumComment: this.formatToInteger(postAnalytic.sumComment),
          sumReach: this.formatToInteger(postAnalytic.sumReach),
          Reach: this.formatToFloat(
            this.checkReach(postAnalytic.sumLike, postAnalytic.sumComment, postAnalytic.sumImpressions)
          ),
        }))
        .slice(0, 5)
      this.isSeemore = true
    },
    // get max value post
    async getMaxLineChart(data) {
      const arrMaxValue = []
      data.forEach((element) => {
        if (element.id === 3) {
          this.max_engagement = Number(Math.max(...element.data))
        } else {
          arrMaxValue.push(Math.max(...element.data))
        }
      })
      this.max_post = Number(Math.max(...arrMaxValue))

      //resize max
      this.max_post = Math.round(this.max_post / 10 + 1) * 10
      this.max_engagement = Math.round(this.max_engagement / 10 + 1) * 10
    },
    // get number
    resizeNumber(numberValue) {
      if (numberValue) {
        if (numberValue % 100 === 0) {
          return numberValue
        }
        numberValue++
        return this.resizeNumber(numberValue)
      }
      return 0
    },
    checkIsDate(data) {
      if (data.reduce((n, { sumPost }) => n + sumPost, 0) > 0) {
        this.isData = true
        this.chartData.datasets.forEach(async (rating) => {
          rating.borderWidth = 3
          rating.pointRadius = 3
        })
      } else {
        this.isData = false
        this.chartData.datasets.forEach(async (rating) => {
          rating.borderWidth = 0
          rating.pointRadius = 0
        })
      }
    },

    formatTime(date) {
      if (!date) {
        return
      }
      let listDate = date.slice(0, 10).split('-')
      let listTime = date.slice(11, 19)
      let dateFormat = listDate[0] + '年' + listDate[1] + '月' + listDate[2] + '日' + ' ' + listTime
      return dateFormat
    },
    formatDate(dateTime) {
      return new Date(dateTime).toISOString().split('T')[0]
    },
    checkReach(sumPost, sumLike, sumImpressions) {
      if (sumImpressions > 0) {
        const dataRevert = (((parseInt(sumPost) + parseInt(sumLike)) / parseInt(sumImpressions)) * 100).toFixed(2)
        if (dataRevert.split('.')[1] > 0) {
          return dataRevert
        } else {
          return dataRevert.split('.')[0]
        }
      }
      return 0
    },
    //export post csv
    exportPostCsv() {
      if (this.numberExportPostAnalysis === 0) {
        return
      }
      const userId = this.userId ? this.userId : this.$page?.props?.auth?.user?.id
      const start_time = dayjs(this.dateRange['0']).format('YYYY-MM-DD')
      const end_time = dayjs(this.dateRange['1']).format('YYYY-MM-DD')

      const url =
        route('api.export.post.csv') + '?user_id=' + userId + '&start_time=' + start_time + '&end_time=' + end_time
      window.location.href = url

      if (this.isRole !== 'master') {
        addNumberExport('csv', 'csv_post_analysis')
        this.fetchNumberExportMonth()
      }
    },

    async fetchNumberExportMonth() {
      const { data } = await fetchNumberExport('csv', 'csv_post_analysis')
      this.numberExportPostAnalysis = this.plan?.number_report_csv - data
    },
  },
  watch: {
    dateRange: function () {
      const offset = this.dateRange[0].getTimezoneOffset()
      this.dateRange[0] = new Date(this.dateRange[0].getTime() - offset * 60 * 1000)
      this.dateRange[1] = new Date(this.dateRange[1].getTime() - offset * 60 * 1000)
      this.getData(this.dateRange[0].toISOString().split('T')[0], this.dateRange[1].toISOString().split('T')[0])
      this.$emit('getAnalyticPostRange', this.dateRange)
    },
  },
}
</script>

<style lang="scss" scoped>
.title-no-data {
  position: absolute;
  top: 45%;
  font-size: 24px;
  color: #7a838c;
  z-index: 999;
  text-align: center;
  width: 100%;
  font-style: italic;
}

table tr:hover {
  background-color: #ddd;
}
</style>

<style>
.is-dark span {
  color: #fff !important;
}
</style>
