<template>
    <div class="el__input-height">
        <div class="md:px-10 sm:px-5 mb-8">
            <div>
                <div class="flex justify-between pb-5 mb-5 md:items-center sm:items-start">
                    <!-- <div class="items-center text-[20px] mb-4 m-1"></div> -->
                    <div class="md:pr-14 pb-2 rounded">
                        <div class="groupName title-md">グループ名： {{ group.name }}</div>
                    </div>
                    <div class="flex md:justify-end sm:items-center sm:w-2/3">
                        <el-date-picker v-model="range" type="daterange" :disabled-date="disabledDate" range-separator="-"
                            start-placeholder="Start date" end-placeholder="End date" clear-icon="CircleClose" size="large"
                            popper-class="customDatePicker" />
                    </div>
                </div>

                <div class="tabs relative bg-white py-8 px-4 sm:!mx-0 table-scroll">
                    <ul class="flex md:ml-0 sm:ml-2">
                        <li class="pr-4 relative pb-5 text-nomal" :class="{ active: current_tab == 'tab-1' }"
                            @click="current_tab = 'tab-1'">
                            基本サマリー
                        </li>
                        <li class="md:px-6 sm:px-3 relative pb-5 text-nomal" :class="{ active: current_tab == 'tab-2' }"
                            @click="current_tab = 'tab-2'">
                            フォロワーサマリー
                        </li>
                        <li class="md:px-6 sm:px-3 relative pb-5 text-nomal" :class="{ active: current_tab == 'tab-3' }"
                            @click="current_tab = 'tab-3'">
                            インサイトサマリー
                        </li>
                    </ul>
                    <div class="mt-10" v-if="current_tab == 'tab-1'">
                        <Basic class="mt-10" :range="range" :groupTable="groupTable" v-if="current_tab == 'tab-1'" />
                    </div>
                    <Follower class="mt-10" :range="range" :groupTable="groupTable" v-if="current_tab == 'tab-2'" />
                    <Detail class="mt-10" :range="range" :groupTable="groupTable" v-if="current_tab == 'tab-3'" />
                </div>
            </div>
        </div>
        <div class="md:px-10 sm:px-5 pb-28 rounded">
            <div class="pt-8 tabs flex items-center justify-between bg-white pl-4 sm:!mx-0">
                <ul class="flex">
                    <li class="pr-4 relative pb-2" :class="{ active: lineTab == 'all' }"
                        @click.prevent="showLineChart('all')">
                        推移
                    </li>
                    <li class="px-6 relative pb-2" :class="{ active: lineTab == 'followers' }"
                        @click.prevent="showLineChart('followers')">
                        フォロワー推移
                    </li>
                    <li class="px-6 relative pb-2" :class="{ active: lineTab == 'interactives' }"
                        @click.prevent="showLineChart('interactives')">
                        エンゲージメント推移
                    </li>
                </ul>
            </div>

            <!-- line-chart -->
            <LineChart :group="group" :range="range" :showLine="lineTab" />

            <hr class="border border-solid border-[#DADADA] bg-white rounded my-8" />
            <div class="my-4">
                <div class="items-center text-option font-semibold mb-4 m-1">
                    <p class="text-title text-button_pink font-bold">フォロワー属性</p>
                </div>
            </div>
            <div class="grid md:grid-cols-3 sm:grid-cols-1 gap-4">
                <!-- pie-chart -->
                <div class="border border-solid border-[#DADADA] bg-white rounded">
                    <PieChart :range="range" :dataGenderForChart="dataGenderForChart"
                        :totalMemberGroup="totalMemberGroup" />
                </div>

                <!-- stack-bar-chart -->
                <div class="border border-solid border-[#DADADA] bg-white rounded">
                    <StackBarChart :range="range" :dataGenderForChart="dataGenderForChart"
                        :totalMemberGroup="totalMemberGroup" />
                </div>

                <!-- horizontal-chart -->
                <div class="border border-solid border-[#DADADA] bg-white rounded">
                    <HorizontalChart :range="range" :dataCityForChart="dataCityForChart"
                        :totalMemberGroup="totalMemberGroup" />
                </div>
            </div>
            <div class="hash_analysis mt-8 rounded">
                <div class="my-4">
                    <div class="items-center text-option font-semibold mb-4 m-1">
                        <p class="text-title text-button_pink font-bold">ハッシュタグ分析</p>
                    </div>
                </div>
                <Hashtag :range="range" :group="group" />
            </div>
        </div>
    </div>
</template>

<script>
import Basic from './ComponentAnalysis/Basic.vue'
import Follower from './ComponentAnalysis/Follower.vue'
import Detail from './ComponentAnalysis/Detail.vue'
import Hashtag from './ComponentAnalysis/Hashtag.vue'
import Datepicker from '@/Components/Datepicker.vue'
import LineChart from './Base/LineChart.vue'
import PieChart from './Base/PieChart.vue'
import StackBarChart from './Base/StackBarChart.vue'
import HorizontalChart from './Base/HorizonBarChat/Basic.vue'
import { Link } from '@inertiajs/inertia-vue3'
import { getDataMember } from '@/Api/Enterprise'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    ArcElement,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, ArcElement, BarElement, CategoryScale, LinearScale, PointElement)
export default {
    name: 'GroupAnalysis',
    components: {
        LineChart,
        PieChart,
        StackBarChart,
        HorizontalChart,
        Datepicker,
        Link,
        Basic,
        Follower,
        Hashtag,
        Detail,
    },

    props: {
        group: {
            type: Object,
            require: true,
        },
    },

    data() {
        return {
            groupTable: [],
            groupInsightsFirstDay: [],
            interval: 1,
            accountInsights: [],
            totalMemberGroup: 0,
            calculation: [],
            accountId: '',
            range: [],
            lineTab: 'all',
            current_tab: 'tab-1',
            ChangeTab: 'tab-1',
            deleteSelect: false,
            isShowChart: true,
            dataGenderForChart: [],
            dataCityForChart: [],
        }
    },
    async created() {
        this.range = this.getDateOfMonth(30)
        this.getDataMember()
    },
    mounted() {
        this.customDatePickerText()
    },
    watch: {
        range() {
            this.getDataMember()
        },
    },

    methods: {
        async getDataMember() {
            await getDataMember(this.group.id, this.range)
                .then(async (res) => {
                    this.groupTable = res.status === 200 ? res.data : []
                    this.accountInsights = res.status === 200 ? res.data?.accountInsights : []
                    this.totalMemberGroup = res.status === 200 ? res.data?.totalMemberGroup : 0
                    this.dataGenderForChart = res.status === 200 ? res.data?.dataGenderForChart : []
                    this.dataCityForChart = res.status === 200 ? res.data?.dataCityForChart : []
                })
                .catch(() => { })
        },

        showLineChart(type) {
            this.lineTab = type
        },
        // disabledDate
        disabledDate(time) {
            let d = new Date()
            return time.getTime() > d.setDate(d.getDate() - 1)
        },

        getDateOfMonth(day) {
            const date = []
            date[0] = new Date(new Date().setDate(new Date().getDate() - day))
            date[1] = new Date(new Date().setDate(new Date().getDate() - 1))
            return date
        },
        customDatePickerText() {
            let datePicker = document.getElementsByClassName('customDatePicker')

            let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[0]
            const preYearNode = document.createTextNode("<<前年");
            btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0]);
            btnPreYear.style.cssText += 'margin: 0 5px 0 0';

            let btnPreMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[1]
            const preMonthNode = document.createTextNode("<前月");
            btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0]);
            btnPreMonth.style.cssText += 'margin: 0';

            let btnNextYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[2]
            const nextYearNode = document.createTextNode("翌年>>");
            btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0]);
            btnNextYear.style.cssText += 'margin: 0 0 0 5px';

            let btnNextMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[3]
            const nextMonthNode = document.createTextNode("翌月>");
            btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0]);
            btnNextMonth.style.cssText += 'margin: 0';
        }
    },
}
</script>

<style scoped>
table {
    border-collapse: collapse;
}

.user-tags {
    border-radius: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.search_icon {
    padding: 10px 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #d02d92;
}

.select__status {
    background: #ffffff;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
}
</style>
