String.prototype.toInt = function () {
  return Number.parseInt(this) || 0;
};

Array.prototype.first = function() {
  if (this.length == 0) {
    return null;
  }
  return this[0];
}
