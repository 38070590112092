<template>
  <DrashBoard>
    <div class="bg-background pb-20">
      <UserNav />
      <div class="statistic px-10">
        <h2 class="text-xl text-button_pink font-semibold py-5">投稿一覧</h2>
        <div class="menu flex items-center mb-6 justify-between">
          <div class="menu__search flex relative">
            <input
              v-model="searchKeyword"
              class="flex-1 mr-5 input-cm"
              placeholder="検索"
              type="text"
              @keyup.enter="getHashtag"
            />
            <div class="bg-blue-600 text-white px-7 py-4.5 w-max rounded">
              <button @click="getHashtag">検索</button>
            </div>
          </div>
          <div class="bg-blue-600 text-white px-7 py-4.5 w-max rounded">
            <Link href="/hashtag/create" as="a" type="button">+新規作成</Link>
          </div>
        </div>
        <Table :label="tableLabel" :data="tableData"></Table>
        <h3 v-if="tableData.length == 0" class="text-center mt-10">検索結果がみつかりません。</h3>
        <div class="pt-4 pb-9 flex justify-center">
            <el-pagination
            :page-size="Number(10)"
            :pager-count="5"
            layout="prev, pager, next"
            :total="Number(totalData)"
            :current-page="currentPage || 1"
            @current-change="handleCurrentChange"
            class="is-background "
          />
    </div>
      </div>
    </div>
  </DrashBoard>
</template>

<script>
import Table from '@/Components/Table.vue'
import Pagination from '@/Components/Pagination.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import { Link } from '@inertiajs/inertia-vue3'
import * as hashTagApi from '@/Api/Hashtag'
export default {
  components: {
    Table,
    DrashBoard,
    Pagination,
    UserNav,
    Link,
  },
  async mounted() {
    this.getHashtag()
  },
  methods: {
    getHashtag: async function () {
      const hashtagList = await hashTagApi.getHashTag(this.currentPage, this.searchKeyword)
      this.tableData = hashtagList.data.data
      this.totalData = hashtagList.data.meta.total
      this.totalPage = Math.floor(hashtagList.data.meta.total / hashtagList.data.meta.per_page) + 1
      this.currentPage = hashtagList.data.meta.current_page
    },
    handleCurrentChange(value)
    {
     this.currentPage = value
    },
  },
  watch: {
    currentPage: function () {
      this.getHashtag()
    },
    searchKeyword: function () {
      if (this.searchKeyword == '') {
        this.getHashtag()
      }
    },
  },
  data() {
    return {
      currentPage: 1,
      totalPage: 1,
      totalData:1,
      searchKeyword: '',
      tableLabel: [
        { text: 'Hashtag', key: 'name' },
        { text: 'Like', key: 'likes' },
        { text: 'Comment', key: 'comments' },
      ],
      tableData: [],
    }
  },
}
</script>

<style lang="scss" scoped></style>
