<template>
    <Dashboard>
        <div class="container mx-auto">
            <div class="p-20 border border-gay-300 m-20">
                <div class="font-medium text-xl text-center">
                    お申し込みありがとうございます。<br>
                    ご登録のメールアドレスにお申し込み情報の詳細をお送りしていますのでご確認ください
                </div>

                <div class="flex justify-around mt-16">
                    <Link :href="route('admin.accounts.index')" method="get" class="bg-indigo-500 hover:bg-indigo-700 py-5 px-20 rounded-sm text-white">
                        管理画面に戻る
                    </Link>
                    <Link :href="route('logout')" method="post" class="bg-button_pink hover:bg-pink-600 py-5 px-20 rounded-sm text-white">
                        ログアウトして閉じる
                    </Link>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

<script>
import Dashboard from "@/Pages/Dashboard.vue";
import BreezeInput from "@/Components/Input.vue";
import BreezeLabel from "@/Components/Label.vue";
import BreezeValidationErrors from '@/Components/ValidationErrors.vue';
import {Link} from "@inertiajs/inertia-vue3";

export default {
    components: {
        Dashboard,
        BreezeValidationErrors,
        BreezeInput,
        BreezeLabel,
        Link
    },
}
</script>
