<template>
    <div class="px-6 register__admin">
        <h2 class="text-xl text-button_pink font-semibold py-5">
            お知らせ|testtesttesttesttest
        </h2>
        <div class="flex">
            <form class="basis-5/12 pr-5">
                <div class="grid items-center gap-y-5 gap-x-3 form__wrapper">
                    <label for="">タイトル</label>
                    <input type="text" />
                    <label for="">ラベル</label>
                    <input type="text" />
                    <label for="">本文</label>
                    <input type="text" />
                </div>
                <h2 class="text-xl text-button_pink font-semibold py-5">
                    配信スケジュール
                </h2>
                <div class="flex items-center">
                    <input
                        type="radio"
                        id="html"
                        name="fav_language"
                        value="HTML"
                    />
                      <label class="ml-2 mr-8" for="html">○即時配信</label>
                    <input
                        type="radio"
                        id="html"
                        name="fav_language"
                        value="HTML"
                    />
                      <label class="ml-2" for="html">○即時配信</label>
                </div>
                <h2 class="text-xl text-button_pink font-semibold py-5">
                    配信スケジュール
                </h2>
                <div v-for="item in deliverySchedules" :key="item" class="grid form__radio">
                    <div v-for="(value, label) in item" :key="label">
                        <input
                            type="radio"
                            id="html"
                            name="fav_language"
                            :value="{ value }"
                        />
                        <label class="ml-2 mr-8" for="html">{{label}}</label>
                    </div>
                </div>
            </form>
            <div class="basis-7/12 px-6 border-l border-zinc-400">
                <h2 class="text-xl text-button_pink font-semibold py-5">
                    利用明細
                </h2>
                <Table :label="tableLabel" :data="tableData" :action="'詳細'" />
                <Pagination />
            </div>
        </div>
    </div>
</template>

<script>
import Table from "@/Components/Table.vue";
import Pagination from "@/Components/Pagination.vue";
export default {
    components: {
        Table,
        Pagination,
    },
    data() {
        return {
            tableLabel: ["日付", "ラベル", "タイトル", "詳細"],
            tableData: [
                {
                    startDate: "2021/12/1",
                    status: "決済完了",
                    content: "testtesttesttetstetttste",
                },
                {
                    startDate: "2021/12/1",
                    status: "決済完了",
                    content: "testtesttesttetstetttste",
                },
                {
                    startDate: "2021/12/1",
                    status: "決済完了",
                    content: "testtesttesttetstetttste",
                },
                {
                    startDate: "2021/12/1",
                    status: "決済完了",
                    content: "testtesttesttetstetttste",
                },
                {
                    startDate: "2021/12/1",
                    status: "決済完了",
                    content: "testtesttesttetstetttste",
                },
                {
                    startDate: "2021/12/1",
                    status: "決済完了",
                    content: "testtesttesttetstetttste",
                },
            ],
            deliverySchedules: [
                {
                    "○全て": 1,
                    "エンタープライズユーザー": 1,
                    "○エンタープライズ管理者": 1,
                    "○予約配信": 1,
                    "代理店ユーザー": 1,
                    "○代理店ユーザー": 1,
                    "○OEM管理者": 1,
                    "○代理店ユーザー":1
                },
            ],
        };
    },
};
</script>

<style scoped>
.form__wrapper {
    grid-template-columns: max-content auto;
}
.form__radio {
    grid-template-columns: auto max-content;
    grid-gap: 20px 0px;
}
.register__admin {
    background: #f5f5f5;
}
.row__radio > div {
    padding-bottom: 21px;
}
</style>
