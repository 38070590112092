<template>
    <BreezeGuestLayout bg_form_login="bg-red-200">
        <Head title="Log in" />

        <form @submit.prevent="submit">
            <div class="flex items-start flex-col justify-center flex-sm">
                <BreezeLabel for="email" value="" class="w-32 font-bold mr-1 text-nomal">
                    メールアドレス
                    <span class="text-red-600">*</span>
                </BreezeLabel>
                <BreezeInput
                    id="email"
                    type="text"
                    class="block sm:w-full md:w-96 rounded-sm p-3 text-nomal"
                    v-model="form.email"
                    autofocus
                    autocomplete="username"
                    placeholder="メールアドレスを入力"
                    :class="form.errors.email ? 'border-red-600' : ''"
                />
                <validate-authentication :isShow="false" v-if="form.errors.email">{{ form.errors.email }}</validate-authentication>
            </div>
            <div class="mt-5 flex items-start flex-col justify-center flex-sm">
                <BreezeLabel for="password" value="" class="w-32 font-bold mr-1 text-nomal">
                    パスワード
                    <span class="text-red-600">*</span>
                </BreezeLabel>
                <BreezeInput
                    id="password"
                    type="password"
                    class="block sm:w-full md:w-96 rounded-sm p-3 text-nomal"
                    v-model="form.password"
                    autocomplete="current-password"
                    placeholder="パスワードを入力"
                    :class="form.errors.password ? 'border-red-600' : ''"
                />
                <validate-authentication :isShow="false" v-if="form.errors.password">{{ form.errors.password }}</validate-authentication>
            </div>
            <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                {{ status }}
            </div>


            <div class="flex items-center justify-center mt-7">
                <!-- <BreezeLabel value="&nbsp" class="sm:hidden lg:block w-32" /> -->
                <BreezeButton
                    class="bg-indigo-500 hover:bg-indigo-700 pl-16 pr-16 sm:py-3 md:py-5 md:px-16 rounded-sm text-center text-btn-submit"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                >
                    ログイン
                </BreezeButton>
            </div>

            <div class="flex items-center justify-center mt-12">
                <Link
                    v-if="canResetPassword"
                    :href="route('user.password.request', {page_login : 'master'})"
                    class="text-indigo-600 hover:text-indigo-400 text-2sm"
                >
                    パスワードをお忘れの方はこちら
                </Link>
            </div>
        </form>
    </BreezeGuestLayout>
</template>

<script>
import BreezeButton from "@/Components/Button.vue";
import BreezeGuestLayout from "@/Layouts/Guest.vue";
import BreezeInput from "@/Components/Input.vue";
import BreezeLabel from "@/Components/Label.vue";
import BreezeValidationErrors from "@/Components/ValidationErrors.vue";
import { Head, Link, useForm } from "@inertiajs/inertia-vue3";
import ValidateAuthentication from "@/Components/ValidateAuth.vue"

export default {
    components: {
        BreezeButton,
        BreezeGuestLayout,
        BreezeInput,
        BreezeLabel,
        BreezeValidationErrors,
        ValidateAuthentication,
        Head,
        Link,
        useForm,
    },

    props: {
        status: {
            type: String,
            required: false,
        },
        canResetPassword: {
            type: Boolean,
            required: false,
        },
    },

    data() {
        return {
            form: useForm({
                email: "",
                password: "",
                remember: false,
            }),
        };
    },

    methods: {
        submit() {
            this.form.post(route("login.master"), {
                onFinish: () => {
                    this.form.reset("password");
                },
            });
        },
    },
    watch: {
        'form.email': function(){
            this.form.errors.email = false
        },
        'form.password': function(){
            this.form.errors.password = false
        }
    }
};
</script>
