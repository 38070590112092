<template>
  <DrashBoard>
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li class="mr-4">
          <Link :href="route('admin.post-advertisment.index')" class="font-normal breakcumb-active">広告配信画面</Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-2 relative pb-5 active">広告配信画面</li>
        </ul>
      </div>
    </template>
    <div class="bg-background">
      <UserNav />
      <el-form :model="form" label-position="top" :rules="rules">
        <div class="flex px-14 pb-20">
          <div class="basis-8/12 pr-10">
            <h2 class="title-md mt-14">投稿画面</h2>
            <div class="flex mt-12 border-gray-300 border-b pb-6">
              <label for="">アカウント名:</label>
              <p class="text-base text-request font-normal">KIGEKI 株式会社</p>
            </div>
            <div class="mt-5 border-b border-gray-300">
              <el-form-item label="キャンペーンの目的を選択" prop="campains">
                <el-checkbox-group v-model="form.campains">
                  <el-checkbox v-for="item in listCampain" :key="item" :label="item.value" size="large" class="w-1/4">
                    {{ item.label }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </div>
            <div class="py-7 border-b border-gray-300">
              <el-form-item label="キャンペーン名" prop="campaign_name">
                <el-input v-model="form.campaign_name" size="large" class="w-100" />
              </el-form-item>
            </div>
            <div class="gap-x-7.5 items-center py-7 border-b border-gray-300">
              <el-form-item label="キャンペーン名" prop="trafics">
                <el-checkbox-group v-model="form.trafics">
                  <el-checkbox v-for="item in listTraffic" :key="item" :label="item.value" size="large" class="w-36">
                    {{ item.label }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </div>
            <div class="grid grid-cols-5 gap-4 mt-7">
              <el-form-item
                label="予算と掲載期間"
                prop="trafics"
                style="display: flex"
                class="budeget-period col-span-3"
              >
                <el-select v-model="form.budget" class="w-96" size="large">
                  <el-option label="1日の予算" :value="0" />
                </el-select>
              </el-form-item>
              <el-form-item class="col-span-2">
                <el-input v-model="form.period" size="large" class="w-96">
                  <template #suffix>JPY</template>
                </el-input>
              </el-form-item>
            </div>
            <div class="grid grid-cols-5 gap-4 pb-7 mt-3 border-b border-gray-300">
              <el-form-item label="開始日時" prop="start_date" class="budeget-period col-span-3" style="display: flex">
                <el-date-picker v-model="form.start_date" type="date" size="large" class="w-96" />
              </el-form-item>
              <el-form-item label=" 終了日" prop="end_date" class="col-span-2 advertis-end-at" style="display: flex">
                <el-date-picker v-model="form.end_date" type="date" size="large" class="w-96" />
              </el-form-item>
            </div>
            <div class="py-6 grid mt-3 border-b border-gray-300">
              <p class="text-md text-gray-600">オーディエンス設定</p>
              <div class="grid-cols-5 gap-4">
                <div class="flex flex-1 gap-10 mt-5">
                  <span class="basis-2/12 text-gray-600 text-md">地域　日本　</span>
                  <span class="text-link_pink basis-5/12 text-md">編集</span>
                  <span class="basis-2/12 text-gray-600 text-md">性別　女性</span>
                  <span class="text-link_pink basis-3/12 text-md">編集</span>
                </div>
                <div class="flex flex-1 gap-10 mt-5">
                  <span class="basis-2/12 text-gray-600 text-md">年齢　18-65+</span>
                  <span class="text-link_pink basis-5/12 text-md">編集</span>
                  <span class="basis-2/12 text-gray-600 text-md">詳細ターゲット設定</span>
                  <span class="text-link_pink basis-3/12 text-md">編集</span>
                </div>
              </div>
            </div>
            <div class="gap-x-7.5 items-center pt-7 pb-3 border-b border-gray-300">
              <el-form-item label="広告設定" prop="advertising_settings">
                <el-checkbox-group v-model="form.trafics">
                  <el-checkbox
                    v-for="item in listAdvertisingSettings"
                    :key="item"
                    :label="item.value"
                    size="large"
                    class="w-52"
                  >
                    {{ item.label }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </div>

            <div class="mt-10">
              <template v-for="campain in listCampain" :key="campain">
                <el-form-item :label="campain.label" class="el-input__campaing">
                  <el-input v-model="form.campaign_name" size="large" class="w-100" />
                </el-form-item>
              </template>
            </div>
            <div class="flex align-center w-100">
              <el-button
                class="w-64 button-shadow text-white mt-10 rounded mx-auto"
                size="large"
                style="background-color: #5264cd; boder: 1px solid #5264cd; padding: 25px 0px"
              >
                <span class="text-white">公開する</span>
              </el-button>
            </div>
          </div>
          <!-- preview screen -->
          <div class="basis-4/12">
            <div :style="{ paddingLeft: pl + 'px' }" class="flex flex-col pt-10 border-l border-gray-300 h-full">
              <div class="bg-ip bg-contain block mx-auto bg-no-repeat pt-9 px-4 pb-5">
                <img class="w-full h-full object-cover rounded-4xl" src="/images/girl.png" alt="" />
              </div>
              <div class="flex align-center w-100">
                <el-button
                  class="w-64 button-shadow text-white mt-10 rounded mx-auto"
                  size="large"
                  style="background-color: #5264cd; boder: 1px solid #5264cd; padding: 25px 0px"
                >
                  <span class="text-white">投稿のプレビュー</span>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </DrashBoard>
</template>

<script>
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import { purposeCampaigns, traffics, advertisingSettings } from '@/Constants/options'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link} from '@inertiajs/inertia-vue3'
export default {
  name: 'advertisment',
  components: { DrashBoard, UserNav ,BreadCrumb ,Link },
  data() {
    return {
      form: {
        campains: [],
        trafics: [],
        budget: 0,
        period: '¥2,000',
      },
      rules: [],
      checkedCities: [],

      until: new Date(),
      since: new Date(),
      untilDate: new Date(),
    }
  },
  computed: {
    listCampain() {
      return purposeCampaigns
    },
    listTraffic() {
      return traffics
    },
    listAdvertisingSettings() {
      return advertisingSettings
    },
  },
  created() {},
  methods: {
    formartDate() {
      this.untilDate =
        this.untilDate.toISOString().split('T')[0].replaceAll('-', '/') +
        ' ' +
        this.untilDate.getHours() +
        ':' +
        this.untilDate.getMinutes()
    },
  },
  mounted() {
    this.formartDate()
  },
  watch: {},
}
</script>

<style lang="scss" scoped>
.title img,
label img {
  margin-right: 6px;
}
.title {
  margin-top: 23px;
}

.form-wrapper_2col {
  grid-template-columns: 15% auto;
}
.form-wrapper_3col {
  grid-template-columns: max-content max-content auto;
}
.grid-4-col {
  grid-template-columns: max-content auto max-content auto;
}

.bg-ip {
  width: 283px;
  height: 571px;
  background-image: url('@shared/uploads/iPhone-screen.svg');
  filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.25));
}
</style>
