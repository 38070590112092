<template>
  <div class="py-3" v-loading="loading">
    <div class="text-right mb-4">
      <el-button class="el-button__primary" size="large" @click="onCreate">
        <span class="text-white">新規作成</span>
      </el-button>
    </div>
    <data-table
      :fields="fields"
      :items="categories.data"
      :paginate="categories.meta"
      :current-page="filter.page || 1"
      disable-table-info
      footer-center
      paginate-background
      @page="handleCurrentPage"
      class="el-table-header__black"
    >
      <template #genres="{ row }">
        <el-tag v-for="(genre, index) in row?.genres" :key="index" class="ml-2 mb-2 px-2">{{ genre }}</el-tag>
      </template>
      <template #actions="{ row }">
        <button class="px-6 ml-2 py-1.5 bg-blue-600 rounded button-shadow mr-2 text-white" @click="onEdit(row)">
          <span class="text-white">変更</span>
        </button>
        <button class="px-6 py-1.5 bg-button_table rounded button-shadow text-white" type="info" @click="onDelete(row)">
          <span class="text-white">削除</span>
        </button>
      </template>
    </data-table>

    <el-dialog
      v-model="dialogVisible"
      :title="form.id ? '更新' : '新規作成'"
      width="40%"
      draggable
      :key="dialogVisible"
      append-to-body
    >
      <el-form ref="ruleFormRef" :model="form" label-position="top" @submit.prevent="">
        <el-form-item label="名前" prop="name" :rules="[{ required: true, message: 'この項目は必須項目です' }]">
          <el-input v-model="form.name" placeholder="名前を入力" />
        </el-form-item>
        <el-form-item
          label="ジャンル"
          prop="genres"
          :rules="[{ required: true, message: 'この項目は必須項目です', trigger: 'blur' }]"
        >
          <el-tag
            v-for="(genre, index) in form.genres"
            :key="index"
            class="mx-1 mb-2"
            closable
            :disable-transitions="false"
            @close="handleClose(genre)"
            size="large"
          >
            {{ genre }}
          </el-tag>
          <el-input
            v-if="inputVisible"
            ref="InputRef"
            v-model="inputValue"
            class="ml-1 mb-2"
            @keyup.enter="handleInputConfirm"
            @blur="handleInputConfirm"
            style="width: 10rem"
          />
          <el-button v-else class="button-new-tag ml-1 mb-2" @click="showInput">+ 新規ジャンル</el-button>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" size="large">キャンセル</el-button>
          <el-button type="primary" @click="onSubmit" :loading="button_loading" size="large" class="el-button__primary">
            <span class="text-white">{{ form.id ? '更新' : '新規作成' }}</span>
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import DataTable from '@/Components/UI/DataTable.vue'

export default {
  name: 'Category',
  components: { DataTable },
  emits: ['getCategories'],
  data() {
    return {
      loading: false,
      fields: [
        { key: 'name', label: '名前', width: 400, align: 'center' },
        { key: 'genres', label: 'ジャンル', width: 'w-full', align: 'center' },
        { key: 'actions', label: '操作', width: '300', align: 'center' },
      ],
      categories: [],
      filter: {
        limit: 10,
        page: 1,
        name: '',
      },
      form: {
        name: '',
        genres: [],
      },
      inputValue: '',
      inputVisible: false,
      dialogVisible: false,
      button_loading: false,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true
      await axios
        .get(
          route('api.comment-categories.index', {
            limit: this.filter?.limit,
            page: this.filter?.page,
          })
        )
        .then((res) => {
          this.categories = res?.data
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    onCreate() {
      this.form = {
        name: '',
        genres: [],
      }
      this.dialogVisible = !this.dialogVisible
    },
    onEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogVisible = !this.dialogVisible
    },
    onDelete(row) {
      this.$confirm('削除でよろしいでしょうか？', '警告', {
        confirmButtonText: '同意',
        cancelButtonText: 'キャンセル',
        type: 'warning',
      }).then(async () => {
        this.loading = true
        await axios
          .delete(route('api.comment-categories.destroy', row?.id))
          .then(() => {
            this.$toast.success('カテゴリの削除が完了しました。')
            this.fetchData()
            this.$emit('getCategories')
          })
          .catch(() => {
            this.$toast.error('カテゴリの削除が失敗しました。')
          })
      })
    },
    onSubmit() {
      this.$refs.ruleFormRef.validate(async (valid) => {
        if (valid) {
          this.button_loading = true
          if (!this.form.id) {
            await axios
              .post(route('api.comment-categories.store'), this.form)
              .then(() => {
                this.$toast.success('カテゴリの作成が完了しました。')
                this.dialogVisible = this.button_loading = false
                this.fetchData()
                this.$emit('getCategories')
              })
              .catch(() => {
                this.button_loading = false
                this.$toast.error('カテゴリの作成が失敗しました。')
              })
          } else {
            await axios
              .put(route('api.comment-categories.update', this.form?.id), this.form)
              .then(() => {
                this.$toast.success('カテゴリの更新が完了しました。')
                this.dialogVisible = this.button_loading = false
                this.fetchData()
                this.$emit('getCategories')
              })
              .catch(() => {
                this.button_loading = false
                this.$toast.error('カテゴリの更新が失敗しました。')
              })
          }
        } else {
          this.button_loading = false
          return false
        }
      })
    },
    handleClose(genre) {
      this.form.genres.splice(this.form.genres.indexOf(genre), 1)
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.InputRef.focus()
      })
    },
    handleInputConfirm() {
      if (this.inputValue) {
        if (!this.form.genres.find((v) => v === this.inputValue)) {
          this.form.genres.push(this.inputValue)
        }
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    handleCurrentPage(value) {
      this.filter.page = value
      this.fetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.el-button__primary {
  background-color: #5264cd;
  border: 1px solid #5264cd;
}

.el-button__primary span {
  color: white !important;
}

.el-button__info {
  background-color: #be3d8f;
  border: 1px solid #be3d8f;
}

.el-button__info span {
  color: white !important;
}
</style>
