require("./bootstrap");

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

import 'v-calendar/dist/style.css';
import VCalendar from "v-calendar";

import { vfmPlugin } from 'vue-final-modal'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import ja from 'element-plus/es/locale/lang/ja';

//i18n
import { createI18n } from 'vue-i18n';
import messages from './Lang';

//mixins global
import useGlobalMixin from "@/Mixins/index";
import { formatToInteger, formatToFloat } from './Plugins/numberFormat';


createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => require(`./Pages/${name}.vue`),
    setup({ el, app, props, plugin }) {
        const i18n = createI18n({
          locale: 'ja', // set locale
          fallbackLocale: 'ja',
          allowComposition: true,
          messages
        })

        const root = createApp({ render: () => h(app, props) })
            .use(VCalendar, {})
            .use(plugin)
            .use(vfmPlugin({
                key: '$vfm',
                componentName: 'VueFinalModal',
                dynamicContainerName: 'ModalsContainer'
            }))
            .use(ElementPlus, {
                locale: ja,
            })
            .mixin({ methods: { route } })
            .use(VueToast, {position: 'top-right'})
            .use(i18n)
            .mixin({methods: {formatToInteger, formatToFloat}});

            useGlobalMixin(root);
            root.mount(el);
        return root;
    },
});



InertiaProgress.init({ color: "#4B5563" });
