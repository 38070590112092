<script setup>
import BreezeGuestLayout from '@/Layouts/Guest.vue';
import BreezeButton from '@/Components/Button.vue';
</script>

<template>
    <BreezeGuestLayout>
        <div class=" my-10 text-base text-gray-600">
            ご登録メールに新しいパスワードをお送りしていますので
            <br>
            ご確認ください。
        </div>

        <div class="flex items-center justify-center mt-7">
            <a href="/" style="background: #5B7DDD"
               class="hover:bg-indigo-700 pt-5 pb-5 pl-16 pr-16 rounded-sm text-white mt-10">
                ログイン画面へ
            </a>
        </div>
    </BreezeGuestLayout>
</template>
