<template>
  <!-- table -->
  <section class="mx-auto mt-2.5">
    <div class="w-full overflow-hidden shadow-lg">
      <div class="w-full overflow-x-auto">
        <table class="table-fixed w-full">
          <thead>
            <tr class="text-md font-semibold text-left bg-gray-100 border-b border-gray-600">
              <slot name="first-header"></slot>
              <th
                v-for="(item, index) in label"
                :key="index"
                class="
                  whitespace-nowrap
                  text-xs
                  py-4
                  font-normal
                  bg-header_table
                  text-white
                  border-r
                  text-center
                  rounded-md
                  border-2
                "
              >
                {{ item.text }}
              </th>
              <slot name="end-header"></slot>
            </tr>
          </thead>
          <tbody>
            <tr class="text-xs border-0 hover:bg-gray-200 whitespace-nowrap" v-for="(item, index) in data" :key="index">
              <td v-for="hashtag in item" :key="hashtag" class="py-5.5 text-center font-semibold text-3sm">
                {{ hashtag}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ["label", "data", "action", "dataFirstColumn", "mode"],
  methods: {
    checkStatus: function (data) {},
  },
};
</script>
<style lang="scss" scoped>
table {
  // font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;

  tr {
    box-shadow: 0px 1px 0px rgba(169, 169, 169, 0.25);
    border-bottom: solid 1px rgba(169, 169, 169, 0.25);
  }

  tr:last-of-type {
    border-bottom: 0;
  }

  &.light {
    tr {
      background-color: #ffffff;
    }

    thead tr {
      border: 1px solid #e2e2e2;
    }

    th {
      background: #ffffff;
      color: #414141;
      border: 0;
    }
  }
}
</style>
