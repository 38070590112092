<template>
    <dashboard>
        <div class="paracel p-3">
            <form action="" method="post">
                <div class="paracel-left">
                    <div class="form-group align-items mb-4 grid grid-cols-auto">
                        <div class="communication flex">
                            <img :src="'/images/Polygon 1.svg'" alt="" class="mr-2">
                            <label class="mr-2 grow" for="">アカウント名:</label>
                        </div>
                        <span class="username">------------</span>
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-auto">
                        <div class="communication flex">
                            <img :src="'/images/Polygon 1.svg'" alt="" class="mr-2">
                            <label class="mr-2 grow" for="">投稿タイプ:</label>
                        </div>
                        <div class="flex">
                            <div class="flex items-center">
                                <input
                                    :class="[radio]"
                                    type="radio"
                                    id="normal"
                                    value="normal"
                                    v-model="type"
                                />
                                <label for="normal" class="d-flex ml-3 mb-0" :style="{color: textColor}">
                                    通常投稿
                                </label>
                            </div>
                            <div class="flex items-center ml-5">
                                <input
                                    :class="[radio]"
                                    type="radio"
                                    id="story"
                                    value="story"
                                    v-model="type"
                                />
                                <label for="story" class="d-flex ml-3 mb-0" :style="{color: textColor}">
                                    ストーリーズ投稿
                                </label>
                            </div>
                            <div class="flex items-center ml-5">
                                <input
                                    :class="[radio]"
                                    type="radio"
                                    id="status"
                                    value="status"
                                    v-model="type"
                                />
                                <label for="status" class="d-flex ml-3 mb-0" :style="{color: textColor}">
                                    ストーリーズ投稿
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-auto">
                        <div class="communication flex">
                            <img :src="'/images/Polygon 1.svg'" alt="" class="mr-2">
                            <label class="mr-2 grow" for="">投稿ステータス:</label>
                        </div>
                        <div class="flex">
                            <div class="flex items-center">
                                <input
                                    :class="[radio]"
                                    type="radio"
                                    id="are_placing"
                                    value="are_placing"
                                    v-model="status"
                                />
                                <label for="are_placing" class="d-flex ml-3 mb-0" :style="{color: textColor}">
                                    予約投稿
                                </label>
                            </div>
                            <div class="flex items-center ml-5" >
                                <input
                                    :class="[radio]"
                                    type="radio"
                                    id="immediately"
                                    value="immediately"
                                    v-model="status"
                                />
                                <label for="immediately" class="d-flex ml-3 mb-0" :style="{color: textColor}">
                                    即時配信
                                </label>
                            </div>
                            <div class="flex items-center ml-5">
                                <input
                                    :class="[radio]"
                                    type="radio"
                                    id="save_draft"
                                    value="save_draft"
                                    v-model="status"
                                />
                                <label for="save_draft" class="d-flex ml-3 mb-0" :style="{color: textColor}">
                                    下書き保存
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group align-items mb-4 grid grid-cols-date">
                        <div></div>
                        <div class="relative cursor-pointer" v-if="status">
                            <datepicker/>
                            <img :src="'/images/Vector.svg'" alt="" class="absolute top-2 right-2">
                        </div>
                    </div>
                    <div class="form-group items-center mb-4 grid grid-cols-input">
                        <div class="communication flex">
                            <img :src="'/images/Polygon 1.svg'" alt="" class="mr-2">
                            <label class="mr-2 grow" for="">クリエイティブ:</label>
                        </div>
                        <input type="text" value="" placeholder="ファイルを選択する" :class="[input]">
                    </div>
                    <div class=" form-group items-center mb-4 grid grid-cols-input">
                        <div class="communication flex">
                            <img :src="'/images/Polygon 1.svg'" alt="" class="mr-2">
                            <label class="mr-2 grow" for="">本文</label>
                        </div>
                        <div class="post relative">
                            <input type="text" value="" :class="[input]" class="w-full">
                            <span class="absolute right-0.5 bottom-0 font-normal text-xs text-item_link">1/2,000文字</span>
                        </div>
                    </div>
                    <div class=" form-group items-center mb-4 grid grid-cols-input">
                        <div class="communication flex">
                            <img :src="'/images/Polygon 1.svg'" alt="" class="mr-2">
                            <label class="mr-2 grow" for="">ハッシュタグ</label>
                        </div>
                        <input type="text" value="" :class="[input]">
                    </div>
                    <div class=" form-group items-center mb-4 grid grid-cols-input">
                        <div class="communication flex">
                            <img :src="'/images/Polygon 1.svg'" alt="" class="mr-2">
                            <label class="mr-2 grow" for="">タグ付</label>
                        </div>
                        <input type="text" value="" :class="[input]" class="w-[120px] h-[100px]">
                    </div>
                    <div class=" form-group items-center mb-4 grid grid-cols-input">
                        <div class="communication flex">
                            <img :src="'/images/Polygon 1.svg'" alt="" class="mr-2">
                            <label class="mr-2 grow" for="">場所を追加</label>
                        </div>
                        <input type="text" value="" :class="[input]">
                    </div>
                    <div class="form-group flex items-center">
                        <button class="py-2 px-6 border bg-[#4D65D4] text-white font-semibold hover:bg-blue-700 shadow-btn">
                            実行する
                        </button>
                        <button class="py-2 px-6 border border-solid border-[#4D65D4] text-[#4D65D4] ml-2">
                            取り消し
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </dashboard>


</template>

<script>
import Dashboard from '../Dashboard.vue'
import Datepicker from '@/Components/Datepicker.vue'
export default {
    components: {
        Dashboard,
        Datepicker
    },
    data(){
        return{
            input:'border border-solid border-[#D0D0D0] bg-none',
            radio: 'border border-solid border-textColor !bg-none checked:bg-[#09AB36] disabled:hover checked:text-[#09AB36] focus:outline-[#7A7A7A]',
            textColor: '#7A7A7A',
            status: false,
            type: false,
        }
    },



}
</script>

<style>

</style>
