<template>
  <DrashBoard v-loading="loading">
    <LinkInstagram v-if="!showLinkInstagram" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
    <template v-slot:breakcumb>
      <Loading v-if="isLoading" />
      <BreadCrumb>
        <li class="mr-4">
          <Link :href="route('admin.export.report.pptx')" class="font-normal breakcumb-active">
            レポート作成（PowerPoint）
          </Link>
        </li>
      </BreadCrumb>
    </template>

    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-2 relative pb-5 active">レポート作成（PowerPoint）</li>
        </ul>
      </div>
    </template>

    <UserNav />
    <div class="statistic md:px-14.5 sm:px-4 bg-background pb-16 min-h-screen max-h-full">
      <p class="text-link_pink text-3sm font-bold py-4">レポート作成（PowerPoint）</p>
      <div class="py-5">
        <div class="flex md:mb-0 sm:mb-4">
          <div class="bg-white w-full">
            <div v-if="isRole !== 'master'">
              <h2 class="text-center mt-10 mb-4 font-bold text-3sm">
                {{ plan?.name }} では毎月 {{ plan?.number_report_pptx }} 回まで作成できます。
              </h2>
              <p class="text-center text-2sm">
                ＠{{ currentUser }}では今月後 {{ numberExport < 0 ? 0 : numberExport }} 回作成できます。
              </p>
            </div>
            <div class="flex justify-center my-16 sm:px-2 md:px-4 lg:px-0">
              <img src="/images/step-pptx.svg" />
            </div>

            <div class="border rounded-xl md:mx-12 md:px-8 sm:px-2 md:py-12 option-export">
              <div class="md:h-44 sm:my-4 md:my-0">
                <div class="mb-5">
                  <div>
                    <h2 class="text-3sm font-bold xl:text-left text-center xl:mt-0 mt-2">月次で作成</h2>
                  </div>
                  <div class="xl:text-left text-center text-2sm xl:mt-0 sm:mt-4 mb-2 xl:mb-0">
                    レポートを作成する月を選んでください。
                  </div>
                </div>
                <div class="xl:flex xl:justify-between">
                  <div>
                    <div class="xl:text-left text-center text-2sm xl:mt-0 sm:mt-4 mt-2 hidden-mobile mb-3">
                      作成する月
                    </div>
                    <div class="xl:justify-start justify-center xl:mb-0 mb-3 block flex sm:gap-2 md:mt-0 sm:mt-4">
                      <span class="xl:text-left text-center text-2sm xl:mt-0 mt-2 show-only--mobile">作成する月</span>
                      <el-date-picker
                        :clearable="false"
                        v-model="month"
                        type="month"
                        placeholder="Pick a month"
                        format="YYYY-MM"
                        value-format="YYYY-MM"
                        :disabled-date="disabledDate"
                        popper-class="customDatePickerPPTX"
                      />
                    </div>
                  </div>
                  <div>
                    <div class="xl:text-left text-center text-2sm xl:mt-0 sm:mt-4 mb-3">レポート種別</div>
                    <div>
                      <div class="flex flex-col md:flex-row xl:justify-start justify-center">
                        <div class="mb-3 md:mb-0 mr-5 flex w-1/2 translate-x-[50%] md:translate-x-0">
                          <input
                            @click="userSelected = null"
                            class="w-5 h-5 mr-2 border-gray-200"
                            type="radio"
                            :value="1"
                            v-model="typePptx"
                          />
                          <label class="text-2sm whitespace-nowrap" for="予約投稿">アカウント向け</label>
                        </div>
                        <div
                          v-if="affiliatedAccNumber"
                          class="mb-3 md:mb-0 mr-5 flex w-1/2 translate-x-[50%] md:translate-x-0"
                        >
                          <input class="w-5 h-5 mr-2 border-gray-200" type="radio" :value="2" v-model="typePptx" />
                          <label class="text-2sm whitespace-nowrap" for="予約投稿">配下にあるアカウント</label>
                        </div>
                        <div
                          v-if="hasEnterprise == 1 && listGroup.length > 0"
                          class="mb-3 md:mb-0 flex w-1/2 translate-x-[50%] md:translate-x-0"
                        >
                          <input class="w-5 h-5 mr-2 border-gray-200" type="radio" :value="0" v-model="typePptx" />
                          <label class="text-2sm whitespace-nowrap" for="予約投稿">エンタープライズ向け</label>
                        </div>
                      </div>

                      <div v-if="typePptx == 0" class="w-full mt-3 sm:flex items-center sm:gap-2">
                        <div class="w-full">
                          <el-select
                            class="w-full md:w-[24rem]"
                            v-model="groupSelected"
                            filterable
                            suffix-icon=""
                            clearable
                            placeholder="グループ名を入力してください。"
                            size="large"
                          >
                            <el-option v-for="item in listGroup" :key="item.id" :label="item.name" :value="item.id" />
                          </el-select>
                        </div>
                      </div>
                      <div v-if="typePptx == 2" class="flex items-center">
                        <div class="w-8/10 md:ml-[-40px] m-auto">
                          <InputFilterAccount @changeUserSelected="changeUserSelected" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-center lg:mt-20 md:mt-32 sm:mt-2 my-4">
                <button
                  :disabled="
                    (numberExport <= 0 && isRole !== 'master') ||
                    (userSelected == null && typePptx == 2) ||
                    (groupSelected === '' && typePptx == 0)
                  "
                  @click="exportPptx()"
                  class="text-3sm mx-auto mt-5 lg:w-81 lg:py-5 md:w-4/12 sm:w-5/12 md:py-4 sm:py-2 bg-blue-600 text-white button-shadow rounded"
                >
                  ダウンロードする
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DrashBoard>
</template>

<script>
import Loading from '@/Components/Loadding'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import DrashBoard from '@/Layouts/Authenticated.vue'
import * as accountAPI from '@/Api/Account'
import * as PptxAPI from './Mixins/pptx.js'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import { ElMessageBox } from 'element-plus'
import InputFilterAccount from './InputFilterAccount.vue'

import GetThumbnailUrl from '../../Mixins/thumbnail.js'
import UserNav from '@/Components/UserNav.vue'
import ImgToBase64Mixin from './Mixins/imgToBase64Mixin'
import MasterSlide from './CommonPptxSlide/masterSlide.js'
import genSlideCover from './CommonPptxSlide/genSlideCover.js'
import genSlideTitle from './CommonPptxSlide/genSlideTitle.js'
import genSlideTerms from './CommonPptxSlide/genSlideTerms.js'
import genSlideOverviewAcc from './CommonPptxSlide/genSlideOverviewAcc.js'
import genSlideInsights from './CommonPptxSlide/genSlideInsights.js'
import genAccSlideInsights from './CommonPptxSlide/genAccSlideInsights.js'
import genSlideHashtag from './CommonPptxSlide/genSlideHashtag.js'
import genSlideCmt from './CommonPptxSlide/genSlideCmt.js'
import genSlideTopCreative from './CommonPptxSlide/genSlideTopCreative.js'
import genSlideReachOfPostChart from './CommonPptxSlide/genSlideReachOfPostChart.js'
import genSlideReachOfPost from './CommonPptxSlide/genSlideReachOfPost.js'
import genSlideCreativePost from './CommonPptxSlide/genSlideCreativePost.js'
import genSlideStoryAnalytic from './CommonPptxSlide/genSlideStoryAnalytic.js'
import genSlideStory from './CommonPptxSlide/genSlideStory.js'
import Slide27 from './CommonPptxSlide/slide27.js'

// account
import genSlideInteractive from './AccountPptxSlide/genSlideInteractive.js'
import genSlideInteractiveChange from './AccountPptxSlide/genSlideInteractiveChange.js'
import Slide26 from './AccountPptxSlide/slide26.js'
import genSlideSpecifiedHashtag from './AccountPptxSlide/genSlideSpecifiedHashtag.js'
import genSlideHashtagDetail from './AccountPptxSlide/genSlideHashtagDetail.js'
import genSlideHashtagImg from './AccountPptxSlide/genSlideHashtagImg.js'
import genSlideEngagementAnalysis from './AccountPptxSlide/genSlideEngagementAnalysis.js'
import genSlideEngagementChart from './AccountPptxSlide/genSlideEngagementChart.js'

// enterprise
import SlideCmtAnalysis from './EnterPrisePptxSlide/SlideCmtAnalysis.js'
import genSlideGroupPostAnalysis from './EnterPrisePptxSlide/genSlideGroupPostAnalysis.js'
import EnterpriseSlide18 from './EnterPrisePptxSlide/slide18.js'
import EnterpriseSlide27 from './EnterPrisePptxSlide/slide27.js'
import EnterpriseSlide28 from './EnterPrisePptxSlide/slide28.js'
import genSlideFollower from './EnterPrisePptxSlide/genSlideFollower.js'

import {
  TITLE_SLIDE_2,
  TITLE_SLIDE_5,
  TITLE_SLIDE_13,
  TITLE_SLIDE_21,
  TITLE_SLIDE_25,
  TITLE_SLIDE_28,
  TITLE_SLIDE_33,
  SUB_MENU_TITLE,
  TERMS_TITLE,
  TERM_CONTENT_1,
  TERM_CONTENT_2,
} from './Mixins/enums.js'

export default {
  name: 'ReportPptx',
  components: { DrashBoard, Link, BreadCrumb, LinkInstagram, Loading, UserNav, InputFilterAccount },
  mixins: [
    GetThumbnailUrl,
    ImgToBase64Mixin,
    MasterSlide,
    genSlideCover,
    genSlideTitle,
    genSlideTerms,
    genSlideOverviewAcc,
    genSlideInsights,
    genAccSlideInsights,
    genSlideHashtag,
    genSlideCmt,
    SlideCmtAnalysis,
    genSlideTopCreative,
    genSlideReachOfPostChart,
    genSlideReachOfPost,
    genSlideCreativePost,
    genSlideStoryAnalytic,
    genSlideStory,
    Slide27,

    // image.png
    genSlideInteractive,
    genSlideInteractiveChange,
    Slide26,
    genSlideSpecifiedHashtag,
    genSlideHashtagDetail,
    genSlideHashtagImg,
    genSlideEngagementAnalysis,
    genSlideEngagementChart,

    // enterprise
    genSlideGroupPostAnalysis,
    EnterpriseSlide18,
    EnterpriseSlide27,
    EnterpriseSlide28,
    genSlideFollower,
  ],

  props: {
    plan: { type: Object, default: null },
    hasEnterprise: { type: Number, require: true },
  },

  data() {
    return {
      loading: false,
      showLinkInstagram: true,
      month: new Date().toISOString().slice(0, 7),
      groupSelected: '',
      typePptx: 1,
      isAdminExport: 1,
      listGroup: [],
      isLoading: false,
      numberExport: 0,
      logoMaster: '',
      textMaster: '',
      userSelected: null,
      affiliatedAccNumber: 0,
      filterListHashtag: {
        page: 1,
        name: null,
        limit: 10,
        isCheckOfAccount: 1,
      },
      filterHashtagAnalysis: {
        sort_field: 'likes',
        isCheckOfAccount: 1,
        limitPostPopular: 5,
        limitPostRecent: 5,
      },
      listAnalysisHashtag: null,
      dataDetailAnalysisHashtag: [],
      dataAnalysisPost: [],
      dataGroupPostAndHashTag: [],
    }
  },

  computed: {
    isRole() {
      return this.$page.props?.auth?.user?.roles[0]?.name
    },
    currentUser() {
      return this.$page.props?.auth?.account?.username
    },
    userIdSelected() {
      return this.userSelected?.id
    },
    businessIdSelected() {
      return this.userSelected?.business_id
    },
  },

  async created() {
    this.fetchNumberExportMonth()
    this.checkLinkInstagram()
    this.getListGroup()
    this.fetchPptxSetting()
    this.getListUser()
  },
  mounted() {
    this.customDatePickerText()
  },
  watch: {
    month() {
      this.listAnalysisHashtag = null
      this.dataDetailAnalysisHashtag = []
      this.dataAnalysisPost = []
    },

    userSelected() {
      this.listAnalysisHashtag = null
      this.dataDetailAnalysisHashtag = []
      this.dataAnalysisPost = []
    },
  },
  methods: {
    onInstaSubmitSuccess() {
      this.showLinkInstagram = true
    },
    //select user for Master and OEM-Admin
    changeUserSelected(user) {
      this.userSelected = user
    },
    async getListUser() {
      await axios
        .get(route('api.getListUserExport'))
        .then((res) => {
          this.affiliatedAccNumber = res?.data?.data.length
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // confirm export pptx
    exportPptx() {
      ElMessageBox.confirm('レポートをダウンロードしてよろしいでしょうか？', '警告', {
        confirmButtonText: '同意',
        cancelButtonText: 'キャンセル',
        type: 'warning',
      }).then(async () => {
        console.log(this.$page.props.auth.plan)
        await this.fetchData()
      })
    },

    // link instagram
    async checkLinkInstagram() {
      const check = await accountAPI.checkInsAccount()
      if (check.data) {
        this.showLinkInstagram = true
      } else {
        this.showLinkInstagram = false
      }
    },

    // fetch data for account or enterprise
    async fetchData() {
      if (this.numberExport === 0) {
        return
      }

      let checkSuccess = false
      try {
        this.isLoading = true
        if (this.typePptx == 1 || this.typePptx == 2) {
          const { data } = await PptxAPI.fetchAccData(this.month, this.userSelected?.id)
          if (data.status == false) {
            return this.$toast.error(data.msg)
          }

          await this.updateSliPptx(this.userSelected?.id)

          await this.createPptxAccount(
            data,
            this.textMaster,
            this.logoMaster,
            this.listAnalysisHashtag,
            this.dataDetailAnalysisHashtag,
            this.dataAnalysisPost,
            this.$page.props.auth.plan
          )
          this.dataDetailAnalysisHashtag = []
          checkSuccess = true
        } else {
          const { data } = await PptxAPI.fetchGroupData(this.month, this.groupSelected)
          if (data.status == false) {
            return this.$toast.error(data.msg)
          }

          const hashtagAnalysis = await axios.get(route('api.pptx.get-group-post-hashtag'), {
            params: {
              ...this.filterListHashtag,
              groupId: this.groupSelected,
              typePptx: true,
              monthSelected: this.month,
              sort_field: this.filterHashtagAnalysis.sort_field,
              isCheckOfAccount: this.filterHashtagAnalysis.isCheckOfAccount,
              limitPostPopular: this.filterHashtagAnalysis.limitPostPopular,
              limitPostRecent: this.filterHashtagAnalysis.limitPostRecent,
            },
          })

          await this.createPptxEnterprise(
            data,
            this.textMaster,
            this.logoMaster,
            hashtagAnalysis.data.dataGroup,
            hashtagAnalysis.data.dataPostAnalysis,
            this.$page.props.auth.plan
          )
          checkSuccess = true
        }
        this.isLoading = false
      } catch (error) {
        console.log(error)
      }

      if (checkSuccess && this.isRole !== 'master') {
        await axios.post(route('api.user.export'), {
          type: 'pptx',
          btn_id: 'pptx',
        })
        this.fetchNumberExportMonth()
      }
    },

    async updateSliPptx(userId) {
      //sli 29
      const listAnalysisHashtag = await axios.get(route('api.analysis-hashtag.index'), {
        params: {
          ...this.filterListHashtag,
          typePptx: true,
          monthSelected: this.month,
          userSelected: userId,
        },
      })

      this.listAnalysisHashtag = listAnalysisHashtag?.data?.data
      for (let l = 0; l < this.listAnalysisHashtag.length; l++) {
        let item = this.listAnalysisHashtag[l]
        //sli 30, 31
        const res = await axios.get(route('api.analysis-hashtag.show', item.ig_hashtag_id), {
          params: {
            typePptx: true,
            monthSelected: this.month,
            userSelected: userId,
            sort_field: this.filterHashtagAnalysis.sort_field,
            isCheckOfAccount: this.filterHashtagAnalysis.isCheckOfAccount,
            limitPostPopular: this.filterHashtagAnalysis.limitPostPopular,
            limitPostRecent: this.filterHashtagAnalysis.limitPostRecent,
          },
        })
        this.dataDetailAnalysisHashtag.push(res?.data)
      }

      //sli 32
      const dataAnalysisPost = await axios.get(route('api.post.analysis.index'), {
        params: {
          typePptx: true,
          monthSelected: this.month,
          userSelected: userId,
        },
      })
      this.dataAnalysisPost = dataAnalysisPost?.data
    },

    // export slide account
    async createPptxAccount(
      data,
      textMaster,
      logoMaster,
      listAnalysisHashtag,
      dataDetailAnalysisHashtag,
      dataAnalysisPost,
      plans
    ) {
      let pptx = this.genMasterSlide(textMaster, logoMaster)
      // Account Slide 1
      this.genSlideCover(pptx, data.curentDate, data.endDateSelectedMonth, data.startDateSelectedMonth)
      // Account Slide 2
      this.genSlideTitle(pptx, '指標名・用語', TITLE_SLIDE_2)
      // Account Slide 3
      this.genSlideTerms(pptx, TERMS_TITLE[0], TERM_CONTENT_1, '基本用語集')
      // Account Slide 4
      this.genSlideTerms(pptx, TERMS_TITLE[1], TERM_CONTENT_2, '各データの意味')
      // Account Slide 5
      this.genSlideTitle(pptx, '全体サマリー', TITLE_SLIDE_5)
      // Account Slide 6
      this.genSlideOverviewAcc(pptx, data.masterDataAccount, '全体サマリー', 'アカウントステータス')
      // Account Slide 7
      this.genSlideInsights(
        pptx,
        data,
        data.sumDay,
        'single',
        '合計',
        '全体サマリー',
        'インサイト（合計）',
        this.typePptx
      )
      // Account Slide 8
      this.genAccSlideInsights(
        pptx,
        data,
        data.sumDay,
        'all',
        '平均',
        '全体サマリー',
        'インサイト（平均）',
        this.typePptx
      )
      if (this.isRole === 'master' || plans.post_hashtag == 1) {
        // Account Slide 9
        this.genSlideHashtag(pptx, data.masterDataAccount, '全体サマリー', 'ハッシュタグ分析')
      }
      if (this.isRole === 'master' || plans.comment_analysis == 1) {
        // Account Slide 10
        this.genSlideCmt(
          pptx,
          data.commentAccount,
          '全体サマリー',
          'コメントのネガポジ判定',
          '合計コメント数',
          'コメント評価の割合'
        )
      }
      // Account Slide 11
      if (this.isRole === 'master' || plans.creative_analysis == 1) {
        let creativeData = data.masterDataAccount.postRating.creativePost.topPost
        for (let i = 0; i < creativeData.length; i++) {
          const code = {
            hexCode: await this.getHexColor(creativeData[i].thumbnail_url),
          }
          creativeData[i] = Object.assign(creativeData[i], code)
        }

        this.genSlideTopCreative(pptx, creativeData, '全体サマリー', 'クリエイティブ分析')
      }
      // Account Slide 12
      this.genSlideTitle(pptx, 'フィード投稿サマリー', TITLE_SLIDE_13, '全体サマリー')
      // Account Slide 13
      this.genSlideReachOfPostChart(pptx, data.masterDataAccount, 'フィード投稿', 'フィード投稿リーチ（日別）')
      // Account Slide 14
      this.genSlideReachOfPost(
        pptx,
        data.masterDataAccount.postRating.topPostAndWorstPost.topPost,
        'BEST',
        'フィード投稿',
        'リーチ数ランキング'
      )
      // Account Slide 15
      this.genSlideReachOfPost(
        pptx,
        data.masterDataAccount.postRating.topPostAndWorstPost.worstPost,
        'WORST',
        'フィード投稿',
        'リーチ数ランキング'
      )
      // Account Slide 16
      this.genSlideInteractive(pptx, data.dataEngagement, 'フィード投稿', 'フィード投稿エンゲージメント')
      // Account Slide 17
      this.genSlideInteractiveChange(pptx, data.dataEngagement, 'フィード投稿', 'いいね・コメント・保存数 推移')
      // Account Slide 18
      this.genSlideCreativePost(
        pptx,
        data.masterDataAccount.postRating.creativePost.topPost,
        'BEST',
        'フィード投稿',
        'エンゲージメント数ランキング'
      )
      // Account Slide 19
      this.genSlideCreativePost(
        pptx,
        data.masterDataAccount.postRating.creativePost.worstReach,
        'WORST',
        'フィード投稿',
        'エンゲージメント数ランキング'
      )

      if (this.isRole === 'master' || plans.story_analysis == 1) {
        // Account Slide 20
        this.genSlideTitle(pptx, 'ストーリーズ投稿サマリー', TITLE_SLIDE_21)
        // Account Slide 21
        this.genSlideStoryAnalytic(
          pptx,
          data.dataStory,
          data.masterDataAccount.dataLabels,
          'ストーリーズ投稿',
          'リーチ数（日別）'
        )
        // Account Slide 22
        this.genSlideStory(
          pptx,
          data.dataStory.topAndWorstStory.top_stories,
          'BEST',
          'ストーリーズ投稿',
          'リーチ数ランキング'
        )
        // Account Slide 23
        this.genSlideStory(
          pptx,
          data.dataStory.topAndWorstStory.worst_stories,
          'WORST',
          'ストーリーズ投稿',
          'リーチ数ランキング'
        )
      }
      // Account Slide 24
      this.genSlideTitle(pptx, 'プロフィールへのアクセス', TITLE_SLIDE_25)
      // Account Slide 25
      // this.genSlide26(pptx, data.masterDataAccount, data.dataEngagement, 'プロフィール', 'エンゲージメント')
      // Account Slide 26
      this.genSlide27(pptx, data.masterDataAccount, 'フォロワー', 'フォロワーの増減数')

      if (this.isRole === 'master' || plans.hashtag_analysis == 1) {
        // slide 28
        this.genSlideTitle(pptx, '指定ハッシュタグ分析', TITLE_SLIDE_28)
        // slide 29
        this.genSlideSpecifiedHashtag(pptx, listAnalysisHashtag, '指定ハッシュタグ', '指定ハッシュタグの一覧')

        for (let k = 0; k < dataDetailAnalysisHashtag.length; k++) {
          let item = dataDetailAnalysisHashtag[k]
          this.genSlideHashtagDetail(
            pptx,
            data?.masterDataAccount?.dataLabels,
            item,
            '指定ハッシュタグ',
            item.hashtagName + '投稿数/いいね数/コメント数の推移'
          )
          // slide 32
          await this.genSlideHashtagImg(pptx, item, '指定ハッシュタグ', item?.hashtagName + 'ベスト/ワースト投稿')
        }
      }
      if (this.isRole === 'master' || plans.user_analysis == 1) {
        this.genSlideTitle(pptx, '時間/曜日別分析', TITLE_SLIDE_33)

        this.genSlideEngagementAnalysis(pptx, dataAnalysisPost.info, '時間/曜日別', '全体')

        this.genSlideEngagementChart(
          pptx,
          dataAnalysisPost,
          '時間/曜日別分析',
          'インプレッション/リーチ/保存数/動画再生数'
        )
      }
      console.log('export account success !')
      // Send the PPTX Presentation to the user, using your choice of file name
      if (this.typePptx == 1) {
        pptx.writeFile({ fileName: '【個別アカウント】Instagramレポート_' + this.month.replace('-', '_') }).then(() => {
          this.isLoading = false
        })
      } else {
        pptx
          .writeFile({ fileName: '【配下にあるアカウント】Instagramレポート_' + this.month.replace('-', '_') })
          .then(() => {
            this.isLoading = false
          })
      }
    },

    async createPptxEnterprise(data, textMaster, logoMaster, dataGroup, dataPostAnalysis, plans) {
      let pptx = this.genMasterSlide(textMaster, logoMaster)
      // Enterprise Slide 1
      this.genSlideCover(pptx, data.curentDate, data.endDateSelectedMonth, data.startDateSelectedMonth)
      // Enterprise Slide 2
      this.genSlideTitle(pptx, '指標名・用語', TITLE_SLIDE_2)
      // Enterprise Slide 3
      this.genSlideTerms(pptx, TERMS_TITLE[0], TERM_CONTENT_1, '基本用語集')
      // Enterprise Slide 4
      this.genSlideTerms(pptx, TERMS_TITLE[1], TERM_CONTENT_2, '各データの意味')
      // Enterprise Slide 5
      this.genSlideTitle(pptx, '全体サマリー', TITLE_SLIDE_5)
      // Enterprise Slide 6
      this.genSlideOverviewAcc(pptx, data.masterDataGroup, '全体サマリー', 'グループ合計ステータス')
      // Enterprise Slide 7
      this.genSlideInsights(
        pptx,
        data,
        data.sumDay,
        'single',
        '合計',
        '全体サマリー',
        'グループインサイト（合計）',
        this.typePptx
      )
      // Enterprise Slide 8
      this.genSlideInsights(
        pptx,
        data,
        data.sumDay,
        'all',
        '平均',
        '全体サマリー',
        'グループインサイト（平均）',
        this.typePptx
      )
      // Enterprise Slide 9
      this.genSlideHashtag(pptx, data.masterDataGroup, '全体サマリー', 'グループ内ハッシュタグ分析')
      // Enterprise Slide 10
      this.genSlideCmt(
        pptx,
        data.commentAccount,
        '全体サマリー',
        'グループ内コメントのネガポジ判定',
        'グループ内合計コメント数',
        'グループ内コメント評価の割合'
      )
      // Enterprise Slide 11
      this.genSlideCmtAnalysis(pptx, data.commentGroupAnalysis, '全体サマリー')
      // Enterprise Slide 12
      this.genSlideTitle(pptx, 'フィード投稿サマリー', TITLE_SLIDE_13)
      // Enterprise Slide 13
      this.genSlideReachOfPostChart(
        pptx,
        data.masterDataGroup,
        'フィード投稿',
        'グループ全体フィード投稿リーチ（日別）'
      )
      // Enterprise Slide 14
      this.genSlideGroupPostAnalysis(pptx, data.postGroupAnalysis, 'slide14', 'フィード投稿')
      // Enterprise Slide 15
      this.genSlideReachOfPost(
        pptx,
        data.masterDataGroup.postRating.topPostAndWorstPost.topReach,
        'BEST',
        'フィード投稿',
        'グループ内リーチ数ランキング'
      )
      // Enterprise Slide 16
      this.genSlideReachOfPost(
        pptx,
        data.masterDataGroup.postRating.topPostAndWorstPost.worstReach,
        'WORST',
        'フィード投稿',
        'グループ内リーチ数ランキング'
      )
      // Enterprise Slide 17
      this.genSlideGroupPostAnalysis(pptx, data.postGroupAnalysis, 'slide17', 'フィード投稿')
      // Enterprise Slide 18
      this.genSlide18Enterprise(
        pptx,
        data.masterDataGroupAll.postStatisticForAccount.selectedMonth.postInsight,
        'フィード投稿'
      )
      // Enterprise Slide 19
      this.genSlideCreativePost(
        pptx,
        data.masterDataGroup.postRating.creativePost.topPost,
        'BEST',
        'フィード投稿',
        'グループ内エンゲージメント数ランキング'
      )
      // Enterprise Slide 20
      this.genSlideCreativePost(
        pptx,
        data.masterDataGroup.postRating.creativePost.worstPost,
        'WORST',
        'フィード投稿',
        'グループ内エンゲージメント数ランキング'
      )
      // Enterprise Slide 21
      this.genSlideTitle(pptx, 'ストーリーズ投稿サマリー', TITLE_SLIDE_21)
      // Enterprise Slide 22
      this.genSlideStoryAnalytic(
        pptx,
        data.masterDataGroup.story,
        data.masterDataGroup.dataLabels,
        'ストーリーズ投稿',
        'グループ内リーチ数（日別）'
      )
      // Enterprise Slide 23
      this.genSlideStory(
        pptx,
        data.masterDataGroup.story.topAndWorstStory.top_stories,
        'BEST',
        'ストーリーズ投稿',
        'グループ内リーチ数ランキング'
      )
      // Enterprise Slide 24
      this.genSlideStory(
        pptx,
        data.masterDataGroup.story.topAndWorstStory.worst_stories,
        'WORST',
        'ストーリーズ投稿',
        'グループ内リーチ数ランキング'
      )
      // Enterprise Slide 25
      this.genSlideTitle(pptx, 'プロフィールへのアクセス', TITLE_SLIDE_25)
      // Enterprise Slide 26
      this.genSlideFollower(pptx, data.masterDataGroup.dataFollowerGroup, 'フォロワー')
      // Enterprise Slide 27
      this.genSlide27Enterprise(
        pptx,
        data.masterDataGroupAll.followerAccountStatistic,
        'フォロワー',
        'アカウント別 フォロワーの増減数'
      )
      // Enterprise Slide 28
      this.genSlide28Enterprise(pptx, data.masterDataGroupAll.postStatisticForAccount, 'フォロワー')

      // slide title
      this.genSlideTitle(pptx, '指定ハッシュタグ分析', TITLE_SLIDE_28)

      for (let m = 0; m < dataGroup.length; m++) {
        let item = dataGroup[m]

        //slide 30
        if (item.listHashtags.length != 0) {
          // slide list hashtag Account
          this.genSlideSpecifiedHashtag(
            pptx,
            item.listHashtags,
            '指定ハッシュタグ',
            '@' + item.nameAccount + ' 指定ハッシュタグの一覧'
          )
        }

        if (item.detailHashtags.length != 0) {
          for (let j = 0; j < item.detailHashtags.length; j++) {
            let hashtag = item.detailHashtags[j]
            // slide detail hashtag account
            this.genSlideHashtagDetail(
              pptx,
              data?.masterDataGroup?.dataLabels,
              hashtag,
              '指定ハッシュタグ',
              '「@' + item.nameAccount + '」' + '「' + hashtag?.hashtagName + '」投稿数/いいね数/コメント数の推移'
            )
            // slide image hashtag account
            await this.genSlideHashtagImg(
              pptx,
              hashtag,
              '指定ハッシュタグ',
              '「@' + item.nameAccount + '」' + '「' + hashtag?.hashtagName + '」 ベスト/ワースト投稿'
            )
          }
        }
      }
      this.genSlideTitle(pptx, '時間/曜日別分析', TITLE_SLIDE_33)
      // dataPostAnalysis
      this.genSlideEngagementAnalysis(pptx, dataPostAnalysis.info, '時間/曜日別', '全体')
      this.genSlideEngagementChart(
        pptx,
        dataPostAnalysis,
        '時間/曜日別分析',
        'インプレッション/リーチ/保存数/動画再生数'
      )
      console.log('export enterprise success !')

      // Send the PPTX Presentation to the user, using your choice of file name
      pptx
        .writeFile({ fileName: '【エンタープライズ向け】Instagramレポート_' + this.month.replace('-', '_') })
        .then(() => {
          this.isLoading = false
        })
    },

    // fetch data
    async getListGroup() {
      const { data } = await PptxAPI.getListGroup()
      this.listGroup = data
    },

    disabledDate(date) {
      let d = new Date()
      return date > d.setDate(d.getDate() - 1)
    },

    // get data setting pptx for master slide
    async fetchPptxSetting() {
      const {
        data: { data },
      } = await axios.get(route('api.get.pptx.setting'))

      this.logoMaster = data?.img_copyright ? data.img_copyright : null
      this.textMaster = data?.text_copyright ? data.text_copyright : null
    },

    // get number export per month
    async fetchNumberExportMonth() {
      const { data } = await axios.get(
        route('api.user.exported', {
          type: 'pptx',
          btn_id: 'pptx',
        })
      )
      this.numberExport = this.plan?.number_report_pptx - data
    },
    async getHexColor(imgEl) {
      let base64Src = await this.getBaseCodeImg(imgEl + '?time=' + Math.floor(Date.now() / 1000))
      let hexCode = await this.getHexCode(base64Src)
      return hexCode
    },
    customDatePickerText() {
      let datePicker = document.getElementsByClassName('customDatePickerPPTX')

      let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0]
      const preYearNode = document.createTextNode('<<前年')
      btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0])
      btnPreYear.style.cssText += 'margin: 0'

      let btnNextYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[4]
      const nextYearNode = document.createTextNode('翌年>>')
      btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0])
      btnNextYear.style.cssText += 'margin: 0'
    },
  },
}
</script>
<style lang="scss">
.el-button--primary {
  background-color: #5264cd !important;
  border: 1px solid #5264cd !important;
}

.el-button--primary span {
  color: white !important;
}
</style>
<style lang="scss" scoped>
.option-export {
  margin: 4rem 5% 4rem;
}
.show-only--mobile {
  display: none;
}
@media (max-width: 820px) {
  .show-only--mobile {
    display: inline-block;
  }
  .hidden-mobile {
    display: none;
  }
  .option-export {
    margin: 2rem 1rem;
  }
}
</style>
