<template>
    <header class="sticky top-0 bg-white z-index__max px-2 border-b">
        <AlertHeader v-if="isHasToken" :content="textNoti" />
        <div class="flex items-center justify-between py-4">
            <!-- Header: Right side -->
            <div>
                <button @click="showMenuMobile" class="icon-menu-mobile">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2.97491 5.97461C2.97491 5.42232 3.42263 4.97461 3.97491 4.97461H19.9749C20.5272 4.97461 20.9749 5.42232 20.9749 5.97461C20.9749 6.52689 20.5272 6.97461 19.9749 6.97461H3.97491C3.42263 6.97461 2.97491 6.52689 2.97491 5.97461ZM2.97491 11.9746C2.97491 11.4223 3.42263 10.9746 3.97491 10.9746H19.9749C20.5272 10.9746 20.9749 11.4223 20.9749 11.9746C20.9749 12.5269 20.5272 12.9746 19.9749 12.9746H3.97491C3.42263 12.9746 2.97491 12.5269 2.97491 11.9746ZM2.97491 17.9746C2.97491 17.4223 3.42263 16.9746 3.97491 16.9746H19.9749C20.5272 16.9746 20.9749 17.4223 20.9749 17.9746C20.9749 18.5269 20.5272 18.9746 19.9749 18.9746H3.97491C3.42263 18.9746 2.97491 18.5269 2.97491 17.9746Z"
                            fill="black" fill-opacity="0.65" />
                    </svg>
                </button>
            </div>
            <div class="mr-[auto]">
                <p v-if="isShowUpdate($page.props.auth.user)"
                    @click="this.$inertia.visit(route('admin.page_update_plan'))"
                    class="ml-8 text-white text-xs md:py-2 md:w-44 sm:w-24 sm:py-1 text-center hover:cursor-pointer rounded"
                    style="background: #ff8c00">
                    プランを変更する
                </p>
            </div>
            <ul class="flex items-center lg:pl-0 md:pl-2 sm:pl-3">
                <!-- Logout function -->
                <li>
                    <button v-if="$page.props.auth.impersonate" @click="leaveImpersonate()"
                        class="bg-orange-600 text-white px-3.5 py-2 m-auto button-shadow rounded text-2sm mr-3 flex items-center">
                        <span class="mr-1 text-white whitespace-nowrap">代理ログアウト</span>
                        <el-icon :size="20"><switch-button /></el-icon>
                    </button>
                </li>

                <li class="inline-flex items-center mx-2 relative text-2sm lg:text-base">
                    <button class="inline-flex justify-center items-center">
                        <div v-if="$page.props.auth.user?.avatar_image"
                            class="avatar w-11 h-11 rounded-full header__avatar header__avatar--border border-2 border-solid overflow-hidden">
                            <img v-if="$page.props.auth.user?.avatar_image" :src="$page.props.auth.user?.avatar_image"
                                class="w-full h-full object-cover" />
                            <p v-if="!$page.props.auth.user?.avatar_image">
                                {{ userSelected?.first_name?.[0] }}
                            </p>
                        </div>
                        <span v-else
                            class="inline-flex justify-center items-center avatar w-11 h-11 rounded-full header__avatar header__avatar--border border-2 border-solid mr-2">
                            {{ $page.props.auth.user.first_name.charAt(0) }}
                        </span>

                        <span class="flex items-center lg:ml-2 sm:ml-0" @click="isActive = !isActive">
                            <span class="lg:ml-2 sm:ml-0 text-[#6F747B]">
                                {{ $page.props.auth.user.first_name + ' ' + $page.props.auth.user.last_name }}
                            </span>
                            <span class="dropdown lg:p-4 md:p-2 sm:p-0">
                                <Icon :icon="icon"></Icon>
                            </span>
                        </span>
                    </button>
                    <div class="arrow origin-top-right z-10 absolute top-full min-w-50 bg-sidebar border mt-1.5 w-full"
                        :class="{ active: isActive }">
                        <ul>
                            <li class="text-2sm text-center hover:bg-blue-100 cursor-pointer">
                                <Link class="block w-full p-2" :href="route('admin.users_infor')" as="button">
                                <span>プロフィール</span>
                                </Link>
                            </li>
                            <li v-if="!$page.props.auth.impersonate"
                                class="text-2sm text-center hover:bg-blue-100 cursor-pointer">
                                <Link class="block w-full p-2" :href="route('logout')" method="post" as="button">
                                <span>ログアウト</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div class="arrow arrow-up w-0 h-0 mx-1 my-1 border-l-[10px] border-r-[10px] border-b-[10px] border-solid border-b-[#EBEBEB] border-l-transparent border-r-transparent absolute -bottom-2.5 right-1/2"
                        :class="{ active: isActive }"></div>
                </li>
                <li class="flex items-center" v-if="checkRoleUser()">
                    <div class="help text-help font-normal text-2sm whitespace-nowrap">
                        <a href="https://tryhatch.tayori.com/f/cscontact/" target="_blank">サービスサポート</a>
                    </div>
                    <button
                        class="w-6 h-6 flex items-center justify-center hover:bg-gray-200 transition duration-150 rounded ml-2"
                        aria-controls="search-modal">
                        <span class="sr-only">Help</span>
                        <a href="https://tryhatch.tayori.com/f/cscontact/" target="__blank">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.345 4.16667H17.5C17.721 4.16667 17.933 4.25446 18.0893 4.41074C18.2456 4.56702 18.3334 4.77899 18.3334 5V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.933 17.4122 17.721 17.5 17.5 17.5H2.50002C2.27901 17.5 2.06704 17.4122 1.91076 17.2559C1.75448 17.0996 1.66669 16.8877 1.66669 16.6667V3.33333C1.66669 3.11232 1.75448 2.90036 1.91076 2.74408C2.06704 2.5878 2.27901 2.5 2.50002 2.5H8.67835L10.345 4.16667ZM3.33335 4.16667V15.8333H16.6667V5.83333H9.65502L7.98835 4.16667H3.33335ZM10 10V7.5L13.3334 10.8333L10 14.1667V11.6667H6.66669V10H10Z"
                                    fill="#172444" />
                            </svg>
                        </a>
                    </button>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
import { ElMessageBox } from 'element-plus'
import AlertHeader from '@/Components/AlertHeader.vue'
// import PopupNotification from '@/Components/PopupNotifications.vue'
import Breadcrumb from '@/Components/Breadcrumb.vue'
import Icon from '@/Components/Icon.vue'
import { SwitchButton } from '@element-plus/icons-vue'
import { Link } from '@inertiajs/inertia-vue3'
export default {
    name: 'AppHeader',
    components: {
        Breadcrumb,
        Icon,
        Link,
        // PopupNotification,
        AlertHeader,
        SwitchButton,
    },
    data() {
        return {
            // countNotificationUser: 0,
            // showAlertExpire: {},
            isHasToken: false,
            isActive: false,
            icon: 'down',
            crumbs: [
                {
                    name: '投稿',
                    link: '/post',
                },
                {
                    name: 'お知らせ管理',
                    link: '/notifications',
                },
                {
                    name: 'プラン管理',
                    link: '/plans',
                },
                {
                    name: 'ユーザー管理',
                    link: '/users',
                },
            ],
            textNoti: '',
        }
    },
    mounted() {
        this.checkExpireToken()
    },
    methods: {
        checkExpireToken() {
            if (this.$page?.props?.auth?.account == null) return
            if (this.$page?.props?.auth?.account.token_expires_at == 0) return
            let numberDate = this.$page?.props?.auth.dateExpireToken

            if (numberDate <= 3 && numberDate > 0) {
                this.textNoti = '連携しているInstaアカウントのトークン期限が近付くです。'
                return (this.isHasToken = true)
            }

            if (numberDate == 0) {
                this.textNoti = '連携しているInstaアカウントのトークン期限が切れました。'

                if (window.location.pathname != '/users-infor') {
                    ElMessageBox.alert('連携しているInstaアカウントのトークン期限が切れました。', '警告', {
                        showCancelButton: false,
                        confirmButtonText: 'トークン更新',
                        type: 'warning',
                    })
                        .then(() => {
                            window.location.href = route('admin.users_infor')
                        })
                        .catch(() => { })
                }

                return (this.isHasToken = true)
            }
        },

        isShowUpdate(user) {
            return user.roles[0].name == 'user_normal' && !this.isWaitCancel() && !this.$page.props.auth.impersonate
        },

        isWaitCancel() {
            let userPlan = this.$page.props.auth.userPlan
            let now = new Date().getTime()

            return userPlan.plan.amount > 0 && userPlan.apply == 1 && userPlan.canceled == 1 && userPlan.time_canceled < now
        },

        showMenuMobile() {
            let sidebarMenu = document.getElementById('sidebar-menu')
            sidebarMenu.classList.add('sidebar-mobile__active')
            let mainPage = document.getElementById('main-page')
            mainPage.classList.add('main-page__active')
        },
        checkRoleUser() {
            const roleUser = this.$page.props.auth.user.roles[0]?.name
            if (roleUser == 'user_oem' || roleUser == 'user_agency') {
                return false
            }

            return true
        },

        leaveImpersonate() {
            this.deleteOldParamsSearchByUser()
            this.$inertia.visit(route('admin.users.leave-impersonate'))

            this.$toast.success(this.$t('Logout proxy success'))
        },
        deleteOldParamsSearchByUser() {
            const key = 'search_users_params' + this.$page?.props?.auth?.user?.id
            sessionStorage.removeItem(key)
        },
    },
}
</script>

<style scoped>
.header__avatar.header__avatar--border {
    border-color: #bf3d8f;
}

.arrow {
    display: none;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.active {
    display: block;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}
</style>

<style>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .el-message-box {
        width: 90%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .el-message-box {
        width: 69%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .el-message-box {
        width: 47%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .el-message-box {
        width: 39%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .el-message-box {
        width: 28%;
    }
}

.el-button--primary {
    background-color: #5264cd !important;
    border: 1px solid #5264cd !important;
}

.el-button--primary span {
    color: white !important;
}
</style>
