<template>
    <div class="sm:px-0 md:px-10 pb-10">
        <div class="bg-background">
            <div class="sm:px-5 lg:px-10 md:px-0">
                <div class="flex justify-between items-center">
                    <h2 class="text-button_pink !text-[14px] font-semibold">投稿初速分析</h2>
                    <button v-if="isRole == 'master'" :disabled="numberExportPostDetail <= 0 && isRole !== 'master'"
                        class="bg-black py-3 px-7 text-white !text-[14px] button-shadow rounded"
                        @click="exportFastAnalysis">
                        CSV出力
                    </button>

                    <el-tooltip v-else class="tooltip-customer" effect="dark"
                        :content="'投稿初速分析画面今月後 ' + numberExportPostDetail + ' 回CSV出力出来ます。'" placement="top">
                        <button :disabled="numberExportPostDetail <= 0 && isRole !== 'master'"
                            class="bg-black py-3 px-7 text-white !text-[14px] button-shadow rounded"
                            @click="exportFastAnalysis">
                            CSV出力
                        </button>
                    </el-tooltip>
                </div>
                <div class="overflow-x-auto">
                    <table class="mt-2.5 overflow-y-scroll w-full">
                        <thead>
                            <tr>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2">
                                    投稿画像
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2">
                                    タイプ
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2 w-80">
                                    投稿内容
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2">
                                    ハッシュタグ
                                </th>
                                <th class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2"
                                    style="width: 130px">
                                    投稿日時
                                </th>
                                <th class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2"
                                    v-for="(label, index) in label" :key="index">
                                    {{ label.text }}
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2">
                                    {{ postItem?.post_category == 0 ? 'エンゲージメント率' : 'エンゲージメント数' }}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-body_table">
                            <tr class="border text-2sm" v-for="(item, index) in tableData" :key="index">
                                <td class="py-2 flex items-center justify-center h-[105px]">
                                    <video v-if="checkTypeFile(item?.files?.length > 0 ? item.files[0] : item.file)"
                                        controls width="112" style="height: 64px">
                                        <source :src="item?.files?.length > 0 ? item.files[0] : item.file"
                                            type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <img v-else class="w-28 h-16"
                                        :src="item?.files?.length > 0 ? item.files[0] : item.file" alt="" />
                                </td>
                                <td class="py-2">
                                    <p class="post-content showFullText break-all">
                                        {{ item.post_category == 0 ? '通常' : 'リール' }}
                                    </p>
                                </td>
                                <td class="py-2">
                                    <p class="post-content showFullText break-all">
                                        {{ item.content }}
                                    </p>
                                    <p @click="viewDetailContent(item)"
                                        class="text-xs text-button_profile !text-2sm font-semibold text-right block mt-2 hover:cursor-pointer">
                                        全文を見る
                                    </p>
                                </td>
                                <td class="py-2">
                                    <p class="text-center">
                                        <span class="px-1 color-hashtag paragraph-overflow">{{
                                            formartHashtag(item)
                                        }}</span>
                                    </p>
                                </td>
                                <td class="py-2">
                                    <p class="text-center">{{ formatTime(item.updated_at) }}</p>
                                </td>
                                <td class="py-2" v-for="(label, index) in label" :key="index">
                                    <p class="text-center">{{ item[label.key] }}</p>
                                </td>
                                <td class="py-2">
                                    <p v-if="item.post_category == 0" class="text-center">
                                        {{ item.engagement ? formatToFloat(item.engagement + '%') : '0%' }}
                                    </p>
                                    <p v-else class="text-center">{{ Number(item?.like) + Number(item?.comment) +
                                    Number(item?.saved)}}</p>
                                </td>
                            </tr>
                        </tbody>
                        <!-- dialog detail content  -->
                        <el-dialog v-model="openDialog" title="投稿内容" width="50%" append-to-body>
                            <PopupReviewPost :content="dataDetailContent.content" :file="dataDetailContent.file"
                                :fileType="getTypeFile(dataDetailContent.file)" :hashtag="dataDetailContent.hashtag"
                                :tag="dataDetailContent.tag" :username="accountName" />
                        </el-dialog>
                        <!-- end dialog detail content  -->
                    </table>
                </div>
                <LineChart :canvasID="postAnalyticChart" class="bg-white py-10 px-10 border-2 mt-10" :width="500"
                    :height="150" :chartData="chartData" />
                <div class="my-5 overflow-y-scroll">
                    <!-- <Table :label="timeTableLabel" :data="timeTableData" /> -->
                    <table class="mt-2.5 overflow-y-scroll w-full">
                        <thead>
                            <tr>
                                <th
                                    class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2">
                                    時間
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2">
                                    いいね数
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2">
                                    コメント数
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2">
                                    リーチ数
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2">
                                    保存数
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-body_table">
                            <tr class="border text-2sm" v-for="(item, index) in timeTableData" :key="index">
                                <td class="py-2 text-center">
                                    <p>{{ item.time_crawl.slice(11, 19) }}</p>
                                </td>
                                <td class="py-2 text-center">
                                    <p>{{ formatToInteger(item?.like) }}</p>
                                </td>
                                <td class="py-2 text-center">
                                    <p>{{ formatToInteger(item?.comment) }}</p>
                                </td>
                                <td class="py-2 text-center">
                                    <p>{{ formatToInteger(item?.reach) }}</p>
                                </td>
                                <td class="py-2 text-center">
                                    <p>{{ formatToInteger(item?.saved) }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import LineChart from './LineChart.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import Table from '@/Components/Table.vue'
import { Link } from '@inertiajs/inertia-vue3'
import PopupReviewPost from '@/Components/PopupReviewPost.vue'
import { Back } from '@element-plus/icons-vue'
import * as postAPI from '@/Api/Posts'
import { getUserDetailByAccountId } from '@/Api/Users'
import GetBase64Url from '../../Mixins/imgToBase64Mixin.js'
import { parseTagA } from '@/Helpers/xss'
import GetThumbnailUrl from '../../../../Mixins/thumbnail.js'
import { fetchNumberExport, addNumberExport } from '../../Mixins/limitExport.js'

export default {
    components: {
        Table,
        DrashBoard,
        BreadCrumb,
        LineChart,
        PopupReviewPost,
        Link,
        Back,
    },
    props: ['data'],
    props: {
        postItem: { type: Object, required: true },
        postAnalyticChart: { type: String },
        userId: { type: Number },
        plan: { type: Object, require: true },
    },

    created() {
        this.fetchNumberExportMonth()
    },

    computed: {
        isRole() {
            return this.$page.props?.auth?.user?.roles[0]?.name
        },
    },
    emits: [
        'getDetailPostTableData',
        'getDetailPostTableDataImg',
        'getDetailPostTableDataCategory',
        'getTimePostTableData',
    ],
    mixins: [GetBase64Url, GetThumbnailUrl],
    data() {
        return {
            numberExportPostDetail: 0,
            openDialog: false,
            // idPost: 1,
            dataDetailContent: [],
            accountName: '',
            label: [
                { text: 'いいね数', key: 'like' },
                { text: 'コメント数', key: 'comment' },
                { text: '保存数', key: 'saved' },
                { text: 'リーチ数', key: 'reach' },
            ],
            timeTableLabel: [
                {
                    text: 'いいね数',
                    key: 'like',
                },
                {
                    text: 'コメント数',
                    key: 'comment',
                },
                {
                    text: 'リーチ数',
                    key: 'reach',
                },
                {
                    text: '保存数',
                    key: 'saved',
                },
            ],
            timeTableData: [],
            tableData: [],
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: 'いいね数',
                        data: [],
                        borderColor: '#868686',
                        fill: false,
                    },
                    {
                        label: 'コメント数',
                        data: [],
                        borderColor: 'red',
                        fill: false,
                    },
                    {
                        label: 'リーチ数',
                        data: [],
                        borderColor: 'black',
                        fill: false,
                    },
                    {
                        label: '保存数',
                        data: [],
                        borderColor: 'green',
                        fill: false,
                    },
                ],
            },
        }
    },
    watch: {
        async postItem() {
            if (!this.postItem?.id) return
            await this.getPost(this.postItem?.id)
            await this.getCrawlPost(this.postItem?.id)
            this.getAccountPost(this.postItem?.id)
        },
    },
    methods: {
        parseString(str) {
            return parseTagA(str)
        },
        async getAccountPost(postId) {
            if (!postId) return
            const posts = await postAPI.getPost(postId)
            const account = await getUserDetailByAccountId(posts.data.data.account_id)
            this.accountName = account.data.data
        },
        checkTypeFile(filePath) {
            const arr = filePath.split('.')
            if (arr[arr.length - 1] == 'mp4') {
                return true
            }
            return false
        },
        getTypeFile(fileName) {
            return fileName.split('.').pop()
        },
        viewDetailContent(item) {
            this.dataDetailContent = item
            this.openDialog = true
        },
        formartTag(tag) {
            if (tag) {
                return tag
                    .map((tags) => '@' + tags.username)
                    .toString()
                    .replaceAll(',', ' ')
            }
            return tag
        },
        getCrawlPost: async function (postId) {
            if (!postId) return
            const {
                data: { data: data },
            } = await postAPI.getPostCrawl(postId)
            this.timeTableData = data
            const likeArr = []
            const commentArr = []
            const reachArr = []
            const savedArr = []
            const timeArr = []
            data.forEach((postCrawl) => {
                likeArr.push(postCrawl.like)
                commentArr.push(postCrawl.comment)
                reachArr.push(postCrawl.reach)
                savedArr.push(postCrawl.saved)
                timeArr.push(this.formartTime(postCrawl.time_crawl))
            })
            this.chartData.labels = timeArr
            this.chartData.datasets[0].data = likeArr
            this.chartData.datasets[1].data = commentArr
            this.chartData.datasets[2].data = reachArr
            this.chartData.datasets[3].data = savedArr
            this.$emit(
                'getTimePostTableData',
                this.timeTableData.map((item) => {
                    return [item.time_crawl.slice(10, 16), item.like, item.comment, item.reach, item.saved]
                })
            )
        },
        getPost: async function (postId) {
            if (!postId) return
            const post = await postAPI.getPost(postId)
            post.data.data.tag = this.formartTag(post.data.data.tag)
            post.data.data.hashtag = this.formartHashtag(post.data.data)
            post.data.data.like = this.formatToInteger(post?.data?.data?.like)
            post.data.data.comment = this.formatToInteger(post?.data?.data?.comment)
            post.data.data.saved = this.formatToInteger(post?.data?.data?.saved)
            post.data.data.reach = this.formatToInteger(post?.data?.data?.reach)
            this.tableData = post.data
            let detailPostTableDataImg = post?.data?.data?.thumbnail + '?time=' + Math.floor(Date.now() / 1000)
            let detailPostTableDataCategory = post?.data?.data?.post_category

            let arrPostData = []
            let detailPostTableData = []

            for (const [key, value] of Object.entries(this.tableData)) {
                arrPostData.push('')
                arrPostData.push(value?.post_category === 1 ? 'リール' : '通常')
                arrPostData.push(
                    value?.content && value?.content.length > 25 ? value?.content.slice(0, 25) + '...' : value?.content
                )
                arrPostData.push(value?.hashtag ? this.formartHashtag(value) : '')
                arrPostData.push(this.formatTime(value.updated_at))
                arrPostData.push(value?.like)
                arrPostData.push(value?.comment)
                arrPostData.push(value?.saved)
                arrPostData.push(value?.reach)
                arrPostData.push(
                    this.formatToFloat(value?.post_category == 0 ? value.engagement + '%' : value.like + value.comment + value.saved)
                )
                arrPostData.push(value?.post_category)
            }
            detailPostTableData.push(arrPostData)
            this.$emit('getDetailPostTableData', detailPostTableData)
            this.$emit('getDetailPostTableDataImg', detailPostTableDataImg)
            this.$emit('getDetailPostTableDataCategory', detailPostTableDataCategory)
        },
        formartTime(time) {
            return time.split(' ')[1]
        },
        formartStatus: function (status) {
            if (status == 1) {
                return '通常'
            } else if (status == 0) {
                return 'リール'
            }
            return '下書き'
        },
        formatTime(date) {
            if (!date) {
                return
            }
            let listDate = date.slice(0, 10).split('-')
            let listTime = date.slice(11, 19)
            let dateFormat = listDate[0] + '年' + listDate[1] + '月' + listDate[2] + '日' + ' ' + listTime
            return dateFormat
        },

        formartHashtag(item) {
            return item?.hashtag ? item?.hashtag?.replaceAll(',', ' ').slice(0, 25) + '...' : ''
        },

        exportFastAnalysis() {
            if (this.numberExportPostDetail === 0) {
                return
            }
            const userId = this.userId ? this.userId : this.$page?.props?.auth?.user?.id
            const url = route('api.export.analysis.csv') + '?user_id=' + userId + '&post_id=' + this.postItem.id
            window.location.href = url

            if (this.isRole !== 'master') {
                addNumberExport('csv', 'csv_analysis')
                this.fetchNumberExportMonth()
            }
        },

        async fetchNumberExportMonth() {
            const { data } = await fetchNumberExport('csv', 'csv_analysis')
            this.numberExportPostDetail = this.plan?.number_report_csv - data
        },
    },
}
</script>

<style lang="scss" scoped>
.showFullText {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

th {
    white-space: nowrap;
}

th,
td {
    min-width: 150px;
}

.bg-body_table {
    background-color: white;
}

.paragraph-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
