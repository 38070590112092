export default {
  methods: {
    genPostSelectedPage(doc, detailPostTableDataImg, detailPostCategory) {
      const postAnalyticChart = document.getElementById('post-analytic-chart')
      const postAnalyticChartCanvas = postAnalyticChart ? postAnalyticChart.childNodes[0] : null

      const pageWidth = doc.internal.pageSize.getWidth()
      const pageHeight = doc.internal.pageSize.getHeight()
      const postAnalyticChartCanvasWRatio = (pageWidth / postAnalyticChartCanvas.width) * 0.9
      const postAnalyticChartCanvasHRatio = (pageHeight / postAnalyticChartCanvas.height) * 0.9
      const postChartRatio =
        postAnalyticChartCanvasWRatio > postAnalyticChartCanvasHRatio
          ? postAnalyticChartCanvasHRatio
          : postAnalyticChartCanvasWRatio
      const postAnalyticChartCanvasWidth = postAnalyticChartCanvas.width * postChartRatio
      const postAnalyticChartCanvasHeight = postAnalyticChartCanvas.height * postChartRatio

      if (postAnalyticChartCanvas) {
        doc.setFontSize(14) // set font size
        doc.setTextColor(190, 61, 143)
        doc.text('投稿初速分析', 13, 30)
        doc.text('投稿初速分析', 13, 30)
        doc.text('投稿初速分析', 13, 30)
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(10)
        let engagementLabel = detailPostCategory == 0 ? 'エンゲージメント率' : 'エンゲージメント数'
        doc.autoTable({
          styles: {
            font: 'GenShinGothic',
            fontSize: 8,
            halign: 'center',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 26 },
            2: { cellWidth: 20 },
            4: { cellWidth: 25 },
          },
          didDrawCell: function (data) {
            if (data.column.index === 0 && data.cell.section === 'body') {
              var textPos = data.cell
              let base64Img = detailPostTableDataImg
              doc.addImage(base64Img, 'jpeg', textPos.x + 1, textPos.y + 1, 20, 20)
            }
          },
          bodyStyles: { minCellHeight: 26 },
          startY: 45,
          head: [
            [
              '投稿画像',
              'タイプ',
              '投稿内容',
              'ハッシュタグ',
              '投稿日時',
              'いいね数',
              'コメント数',
              '保存数',
              'リーチ数',
              engagementLabel,
            ],
          ],
          body: this.detailPostTableData,
        })
        doc.addImage(
          postAnalyticChartCanvas,
          'PNG',
          10,
          85,
          postAnalyticChartCanvasWidth,
          postAnalyticChartCanvasHeight
        )
        const timePostTableDataMap = JSON.parse(JSON.stringify(this.timePostTableData))

        if (this.timePostTableData.length > 19) {
          doc.autoTable({
            styles: {
              font: 'GenShinGothic',
              fontSize: 8,
              halign: 'center',
              valign: 'middle',
            },
            columnStyles: {
              1: { cellWidth: 30 },
              2: { cellWidth: 30 },
              3: { cellWidth: 30 },
              4: { cellWidth: 30 },
            },
            startY: postAnalyticChartCanvasHeight + 90,
            head: [['時間', 'いいね数', 'コメント数', 'リーチ数', '保存数']],
            body: timePostTableDataMap.slice(0, 19),
          })
          doc.addPage()
          doc.autoTable({
            styles: {
              font: 'GenShinGothic',
              fontSize: 8,
              halign: 'center',
              valign: 'middle',
            },
            columnStyles: {
              1: { cellWidth: 30 },
              2: { cellWidth: 30 },
              3: { cellWidth: 30 },
              4: { cellWidth: 30 },
            },
            startY: 45,
            head: [['時間', 'いいね数', 'コメント数', 'リーチ数', '保存数']],
            body: timePostTableDataMap.splice(19),
          })
        } else if (this.timePostTableData.length <= 19) {
          doc.autoTable({
            styles: {
              font: 'GenShinGothic',
              fontSize: 8,
              halign: 'center',
              valign: 'middle',
            },
            columnStyles: {
              1: { cellWidth: 30 },
              2: { cellWidth: 30 },
              3: { cellWidth: 30 },
              4: { cellWidth: 30 },
            },
            startY: postAnalyticChartCanvasHeight + 90,
            head: [['時間', 'いいね数', 'コメント数', 'リーチ数', '保存数']],
            body: this.timePostTableData,
          })
        }
      }
    },
  },
}
