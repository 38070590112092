// import { } from "../Mixins/enums.js";
import { EMPTY_IMG } from '../Mixins/media.js'

export default {
  methods: {
    genSlideCmtAnalysis(pptx, commentGroupAnalysis, text) {
      const slide11 = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slide11.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      slide11.addText('アカウント別 コメントネガポジ判定', {
        x: 2.45,
        y: 0.25,
        w: 8.01,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 22,
      })
      slide11.addText('テキストをここに記載します。テキストをここに記載します。テキストをここに記載します', {
        x: 0.36,
        y: 1.03,
        w: 7.4,
        h: 0.3,
        align: 'left',
        color: '808080',
        fontSize: 12,
      })
      if (
        commentGroupAnalysis.checkArrFrown &&
        commentGroupAnalysis.checkArrLaugh &&
        commentGroupAnalysis.totalCMT &&
        commentGroupAnalysis.checkArrMeh
      ) {
        slide11.addImage({ path: EMPTY_IMG, x: 3.74, y: 2.64, w: 3.56, h: 3.56 })
      } else {
        let arrDataHighVals = [
          {
            name: 'コメント数',
            labels: commentGroupAnalysis.nameAccount,
            values: commentGroupAnalysis.totalCMT,
          },
          {
            name: 'ネガティブコメント数',
            labels: commentGroupAnalysis.nameAccount,
            values: commentGroupAnalysis.arrFrown,
          },
          {
            name: 'ポジティブコメント数',
            labels: commentGroupAnalysis.nameAccount,
            values: commentGroupAnalysis.arrLaugh,
          },
        ]
        let optsChartBar4 = {
          x: 0.54,
          y: 1.61,
          w: 9.95,
          h: 5.71,
          barDir: 'col',
          barGrouping: 'clustered',
          barGapWidthPct: 50,
          chartColors: ['BFBFBF', '032060', 'E7289E'],
          valAxisMinVal: 0,
          // valAxisMaxVal: 120,

          catAxisLabelColor: '787878',
          catAxisLabelFontSize: 12,
          catAxisLabelFrequency: 1,
          catAxisOrientation: 'minMax',

          showLegend: true,
          legendPos: 'b',
          legendFontSize: 12,
          legendColor: '787878',

          showTitle: true,
          title: 'アカウント別 コメントネガポジ判定',
          titleColor: '787878',
          titleFontSize: 18.6,
        }
        slide11.addChart(pptx.charts.BAR, arrDataHighVals, optsChartBar4)
      }

      return slide11
    },
  },
}
