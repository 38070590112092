export default {
  methods: {
    genSlideSpecifiedHashtag(pptx, listAnalysisHashtag, text, title) {
      this.genSlide(pptx, listAnalysisHashtag.slice(0, 5), text, title)
      if (listAnalysisHashtag.length > 5) {
        this.genSlide(pptx, listAnalysisHashtag.slice(5, 10), text, title)
      }
    },

    genSlide(pptx, listAnalysisHashtag, text, title) {
      let slideSpecifiedHashtag = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideSpecifiedHashtag.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      slideSpecifiedHashtag.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 6.05,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 22,
      })
      slideSpecifiedHashtag.addText('ハッシュタグ', {
        shape: pptx.shapes.RECTANGLE,
        x: 0.5,
        y: 1.3,
        w: 1.2,
        h: 0.4,
        bold: true,
        align: 'center',
        color: 'ffffff',
        fill: { color: '000000' },
        fontSize: 7,
      })

      slideSpecifiedHashtag.addText('投稿数', {
        shape: pptx.shapes.RECTANGLE,
        x: 1.7,
        y: 1.3,
        w: 1.2,
        h: 0.4,
        bold: true,
        align: 'center',
        color: 'ffffff',
        fill: { color: '000000' },
        fontSize: 7,
      })

      slideSpecifiedHashtag.addText('総いいね数', {
        shape: pptx.shapes.RECTANGLE,
        x: 2.9,
        y: 1.3,
        w: 1.2,
        h: 0.4,
        bold: true,
        align: 'center',
        color: 'ffffff',
        fill: { color: '000000' },
        fontSize: 7,
      })

      slideSpecifiedHashtag.addText('総コメント数', {
        shape: pptx.shapes.RECTANGLE,
        x: 4.1,
        y: 1.3,
        w: 1.2,
        h: 0.4,
        bold: true,
        align: 'center',
        color: 'ffffff',
        fill: { color: '000000' },
        fontSize: 7,
      })

      slideSpecifiedHashtag.addText('投稿数推移', {
        shape: pptx.shapes.RECTANGLE,
        x: 5.3,
        y: 1.3,
        w: 5.3,
        h: 0.4,
        bold: true,
        align: 'center',
        color: 'ffffff',
        fill: { color: '000000' },
        fontSize: 7,
      })

      // list hashtag
      listAnalysisHashtag.forEach((item, index) => {
        // gach doc
        for (let i = 0; i <= 4; i++) {
          slideSpecifiedHashtag.addShape(pptx.shapes.LINE, {
            x: i * 1.2 + 0.5,
            y: 1.7,
            w: 0,
            h: (index + 1) * 0.8,
            line: { width: 0.5, color: '595959' },
          })
        }
        slideSpecifiedHashtag.addShape(pptx.shapes.LINE, {
          x: 10.58,
          y: 1.7,
          w: 0,
          h: (index + 1) * 0.8,
          line: { width: 0.5, color: '595959' },
        })
        slideSpecifiedHashtag.addShape(pptx.shapes.LINE, {
          x: 0.5,
          y: index * 0.8 + 2.5,
          w: 10.08,
          h: 0.0,
          line: { width: 0.5, color: '595959' },
        })

        let chartData = [
          {
            labels: item?.analysis?.date,
            values: item?.analysis?.posts_count,
          },
        ]
        let OPTS_CHART = {
          x: 5.3,
          y: index * 0.8 + 1.7,
          w: 5.3,
          h: 0.8,
          catAxisHidden: true,
          valAxisLabelFontSize: 8,
          chartColors: ['e7289e'],
          lineDataSymbol: 'none',

          valAxisMinVal: 0,
        }
        slideSpecifiedHashtag.addText(item?.name, {
          shape: pptx.shapes.RECTANGLE,
          x: 0.5,
          y: index * 0.8 + 1.7,
          w: 1.2,
          h: 0.8,
          align: 'center',
          color: '000000',
          fontSize: 7,
        })
        slideSpecifiedHashtag.addText(this.sumArray(item?.analysis?.posts_count), {
          shape: pptx.shapes.RECTANGLE,
          x: 1.7,
          y: index * 0.8 + 1.7,
          w: 1.2,
          h: 0.8,
          align: 'center',
          color: '000000',
          fontSize: 7,
        })
        slideSpecifiedHashtag.addText(this.sumArray(item?.analysis?.likes_count), {
          shape: pptx.shapes.RECTANGLE,
          x: 2.9,
          y: index * 0.8 + 1.7,
          w: 1.2,
          h: 0.8,
          align: 'center',
          color: '000000',
          fontSize: 7,
        })
        slideSpecifiedHashtag.addText(this.sumArray(item?.analysis?.comments_count), {
          shape: pptx.shapes.RECTANGLE,
          x: 4.1,
          y: index * 0.8 + 1.7,
          w: 1.2,
          h: 0.8,
          align: 'center',
          color: '000000',
          fontSize: 7,
        })

        slideSpecifiedHashtag.addChart(pptx.charts.LINE, chartData, OPTS_CHART)
      })
      // end listhashtag

      slideSpecifiedHashtag.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.1,
        w: 10.14,
        h: 1.74,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })
      return slideSpecifiedHashtag
    },

    sumArray(arr) {
      if (!arr) return 0
      return String(arr.reduce((a, b) => a + b, 0))
    },
  },
}
