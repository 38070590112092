<template>
    <Infomation />
</template>

<script>
import Infomation from "./Infomation.vue";
import NotificationDelivery from "./NotificationDelivery.vue";
export default {
  components: {
    Infomation,
    NotificationDelivery,
  },
};
</script>

<style>
</style>