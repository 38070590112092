import { TABLE_SLIDE_10_1, OPTION_CHART_SLIDE_10 } from '../Mixins/enums.js'
import { EMPTY_IMG } from '../Mixins/media.js'

export default {
  methods: {
    genSlideCmt(pptx, commentAccount, text, title, title2, title3) {
      const slideCmt = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideCmt.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      slideCmt.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 8.01,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 22,
      })
      slideCmt.addText('テキストをここに記載します。テキストをここに記載します。テキストをここに記載します', {
        x: 0.36,
        y: 1.03,
        w: 7.4,
        h: 0.3,
        align: 'left',
        color: '808080',
        fontSize: 12,
      })
      slideCmt.addText(title2, {
        shape: pptx.shapes.RECTANGLE,
        x: 0.36,
        y: 1.68,
        w: 4.87,
        h: 0.4,
        bold: true,
        align: 'center',
        fontSize: 16,
        line: { color: '303030' },
      })
      slideCmt.addText(title3, {
        shape: pptx.shapes.RECTANGLE,
        x: 5.81,
        y: 1.68,
        w: 4.87,
        h: 0.4,
        bold: true,
        align: 'center',
        fontSize: 16,
        line: { color: '303030' },
      })
      slideCmt.addText(String(commentAccount.sumComment), {
        x: 0.36,
        y: 2.23,
        w: 4.87,
        h: 0.43,
        align: 'center',
        bold: true,
        color: 'e7289e',
        fontSize: 20,
      })
      let listComment = commentAccount.limitComment
      if (listComment.length > 0) {
        let tableSlideCmt = []
        tableSlideCmt.push(TABLE_SLIDE_10_1)
        listComment.forEach((item) => {
          tableSlideCmt.push([
            {
              text: item.post.account.username,
              options: { color: '000000', align: 'center', valign: 'middle', fontSize: 10 },
            },
            { text: item.from.username, options: { color: '000000', align: 'center', valign: 'middle', fontSize: 10 } },
            {
              text: item.timestamp.slice(0, 16),
              options: { color: '000000', align: 'center', valign: 'middle', fontSize: 10 },
            },
            { text: item.emotionsImage, options: { color: '000000', align: 'center', valign: 'middle', fontSize: 10 } },
          ])
        })
        slideCmt.addTable(tableSlideCmt, { x: 0.36, y: 2.81, w: 4.87, h: 4.39, border: { color: '000000' } })
        slideCmt.addText('※一部抜粋して掲載しています。', {
          x: 0.28,
          y: 7.32,
          w: 2.72,
          h: 0.3,
          align: 'left ',
          color: 'ff0000',
          fontSize: 12,
        })
      } else {
        slideCmt.addImage({ path: EMPTY_IMG, x: 0.94, y: 3.25, w: 3.75, h: 3.75 })
      }

      // data for chart
      let percentLaugh = !isNaN(commentAccount.countLaugh / commentAccount.sumComment)
        ? ((commentAccount.countLaugh / commentAccount.sumComment) * 100).toFixed(2)
        : '〇〇'
      let percentFrown = !isNaN(commentAccount.countFrown / commentAccount.sumComment)
        ? ((commentAccount.countFrown / commentAccount.sumComment) * 100).toFixed(2)
        : '〇〇'
      let percentMeh = !isNaN(commentAccount.countMeh / commentAccount.sumComment)
        ? ((commentAccount.countMeh / commentAccount.sumComment) * 100).toFixed(2)
        : '〇〇'

      if (percentLaugh !== '〇〇' && percentFrown !== '〇〇' && percentMeh !== '〇〇') {
        let dataChart = [
          {
            name: 'コメント評価の割合',
            labels: ['ポジティブ', 'ネガティブ', 'ベター'],
            values: [percentMeh, percentLaugh, percentFrown],
          },
        ]

        slideCmt.addChart(pptx.charts.DOUGHNUT, dataChart, OPTION_CHART_SLIDE_10)

        slideCmt.addText('ポジティブ\n' + percentFrown + '%', {
          shape: pptx.shapes.RECTANGLE,
          x: 5.72,
          y: 2.56,
          w: 1.47,
          h: 0.7,
          align: 'center',
          fontSize: 18,
          color: 'ffffff',
          fill: { color: 'e7289e' },
        })
        // slideCmt.addShape(pptx.shapes.LINE, { x: 7.14, y: 3.22, w: 0.46, h: 0.58, rotate: 135, line: { width:0.5, color: 'e7289e' }});
        slideCmt.addText('ベター\n' + percentMeh + '%', {
          shape: pptx.shapes.RECTANGLE,
          x: 9.5,
          y: 2.42,
          w: 1.4,
          h: 0.66,
          align: 'center',
          fontSize: 18,
          color: 'ffffff',
          fill: { color: '3e63a3' },
        })
        slideCmt.addText('ネガティブ\n' + percentLaugh + '%', {
          shape: pptx.shapes.RECTANGLE,
          x: 5.72,
          y: 6.35,
          w: 1.47,
          h: 0.7,
          align: 'center',
          fontSize: 18,
          color: 'ffffff',
          fill: { color: '002060' },
        })
      } else {
        slideCmt.addImage({ path: EMPTY_IMG, x: 6.37, y: 3.25, w: 3.75, h: 3.75 })
      }
      return slideCmt
    },
  },
}
