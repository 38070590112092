<template>
    <UserNav v-if="!isExport"></UserNav>
    <div class="flex md:flex-row sm:flex-col justify-between">
        <div class="flex items-center md:relative">
            <div class="w-72 flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                <h2 class="text-xl text-button_pink font-semibold text-14" :class="{ 'pb-5': isExport }">競合アカウント分析
                </h2>
                <div class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer" @click="toogleManual">
                    <img :src="suggestIcon" />
                </div>
            </div>
            <UserManual :isShow="isShowManual" :content="arrContent" @closeManualPopup="toogleManual" />
        </div>
    </div>
    <div class="flex justify-between md:flex-row sm:flex-col">
        <el-select class="xl:w-1/5 sm:w-full md:w-64 sm:m-0" v-model="accountCompetitor" placeholder="競合アカウントを選択してください"
            size="large">
            <el-option v-for="item in allAccount" :key="item.id" :label="item.fullname"
                :value="item.business_account_id" />
        </el-select>
        <el-date-picker class="w-96 md:my-0 sm:my-2 max-w-full" v-model="range" type="daterange" range-separator="-"
            start-placeholder="Start date" end-placeholder="End date" :disabled-date="disabledDate" :clearable="false"
            size="large" popper-class="customDatePicker" />
    </div>
    <div class="md:mb-8 sm:mb-2">
        <Basic class="md:mt-5 sm:mt-5" :range="range" :dataFirstDay="dataFirstDay" :accountTables="accountTables" />
    </div>
    <div class="rounded" :class="{ 'pb-10': isExport, 'pb-28': !isExport }">
        <div class="bg-white">
            <div class="pt-8 tabs flex items-center justify-start p-5">
                <ul class="flex">
                    <li class="pr-4 relative pb-2" :class="{ active: lineTab == 'all' }"
                        @click.prevent="showLineChart('all')">
                        推移
                    </li>
                    <li class="px-6 relative pb-2" :class="{ active: lineTab == 'followers' }"
                        @click.prevent="showLineChart('followers')">
                        フォロワー推移
                    </li>
                </ul>
            </div>
            <LineChart class="md:mt-0 sm:mt-2" :range="range" :dataLabels="dataLabels" :dataFollowers="dataFollowers"
                :showLine="lineTab" :analyticType="analyticType" :canvasID="IDChart + '-v1'" />
        </div>
        <div class="text-xl bg-white mt-8 px-5 pb-10">
            <h2 class="text-button_pink font-semibold pt-5 text-14">アカウント推移</h2>
            <div class="flex items-center sm:justify-center md:justify-between bg-white my-5">
                <ul class="flex gap-7">
                    <li class="pb-2 cursor-pointer" :class="{ 'tab-active': accountTransitionTab == 'follow' }"
                        @click="accountTransitionTab = 'follow'">
                        フォロワー数
                    </li>
                    <li class="pb-2 cursor-pointer" :class="{ 'tab-active': accountTransitionTab == 'change_follow' }"
                        @click="accountTransitionTab = 'change_follow'">
                        フォロワー増減
                    </li>
                    <li class="pb-2 cursor-pointer" :class="{ 'tab-active': accountTransitionTab == 'post' }"
                        @click="accountTransitionTab = 'post'">
                        フィード投稿数
                    </li>
                    <li class="pb-2 cursor-pointer" :class="{ 'tab-active': accountTransitionTab == 'change_post' }"
                        @click="accountTransitionTab = 'change_post'">
                        フィード投稿増減
                    </li>
                </ul>
            </div>

            <!-- chart -->
            <div v-if="accountTransitionTab == 'follow'">
                <AccountTransition :width="500" :height="150" :dataLabel="dataLabelTransition"
                    :dataChart="dataFollowerTransition" :type="'follow'" :canvasID="IDChart + '-v2'" />
            </div>
            <div v-if="accountTransitionTab == 'change_follow'">
                <AccountTransition :width="500" :height="150" :dataLabel="dataLabelTransition"
                    :dataChart="dataFollowerTransition" :type="'follow_change'" :canvasID="IDChart + '-v2'" />
            </div>
            <div v-if="accountTransitionTab == 'post'">
                <AccountTransition :width="500" :height="150" :dataLabel="dataLabelTransition"
                    :dataChart="dataFollowerTransition" :type="'post'" :canvasID="IDChart + '-v2'" />
            </div>
            <div v-if="accountTransitionTab == 'change_post'">
                <AccountTransition :width="500" :height="150" :dataLabel="dataLabelTransition"
                    :dataChart="dataFollowerTransition" :type="'change_post'" :canvasID="IDChart + '-v2'" />
            </div>
        </div>

        <div class="mt-8">
            <AccountCompareTable :dataAllAccount="dataAllAccount" :canvasID="IDChart" />
        </div>
    </div>
</template>

<script>
import Basic from './Basic.vue'
import LineChart from './LineChart.vue'
import { Link } from '@inertiajs/inertia-vue3'
import Table from '@/Components/Table.vue'
import Datepicker from '@/Components/Datepicker.vue'
import { getDataAccountCompetitor, getDataAnalysis } from '@/Api/Account'
import dayjs from 'dayjs'
import AccountTransition from './AccountTransition.vue'
import AccountCompareTable from './AccountCompareTable.vue'
import UserNav from '@/Components/UserNav.vue'
import { Inertia } from "@inertiajs/inertia";
import UserManual from '@/Components/UserManual.vue'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    ArcElement,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, ArcElement, BarElement, CategoryScale, LinearScale, PointElement)
export default {
    name: 'GroupAnalysis',
    components: {
        LineChart,
        Table,
        Datepicker,
        Link,
        Basic,
        AccountTransition,
        AccountCompareTable,
        UserNav,
        UserManual
    },
    emits: ['getDataCompeteFollower', 'getDataCompeteAccount', 'getDataFirstDayAcc', 'getAccountTransitionTab'],
    props: {
        allAccount: {
            type: Object,
            required: false,
        },
        IDChart: {
            type: String,
        },
        isShowManual: {
            type: Boolean,
        },
        suggestIcon: {
            type: String,
        },
        arrContent: {
            type: Array,
            default: ['競合アカウントの推移(フォロワー数、フィード投稿)を日時毎に確認することができます。', '競合のデータと比較することで、自社のアカウント運用だけでは発見できない、ノウハウや知見を得ることが可能です。', 'より効果的なアカウントの運用に繋がります。']
        },
    },

    data() {
        return {
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowManual: false,
            arrContent: ['競合アカウントの推移(フォロワー数、フィード投稿)を日時毎に確認することができます。', '競合のデータと比較することで、自社のアカウント運用だけでは発見できない、ノウハウや知見を得ることが可能です。', 'より効果的なアカウントの運用に繋がります。'],
            range: [],
            accountCompetitor: null,
            analyticType: 'month',
            lineTab: 'all',
            ChangeTab: 'tab-1',
            isShowChart: true,
            accountTables: [],
            dataFirstDay: [],
            dataFollowers: [],
            dataLabels: [],
            accountTransitionTab: 'follow',
            dataLabelTransition: [],
            dataFollowerTransition: [],
            dataPostTransition: [],
            dataAllAccount: [],
        }
    },
    async created() {
        this.range = this.getDateOfMonth(30)
    },
    mounted() {
        this.customDatePickerText()
    },
    computed: {
        isExport() {
            return Inertia?.page?.url === '/export/report-preview'
        }
    },
    watch: {
        analyticType() {
            this.range = []
            setTimeout(() => {
                if (this.analyticType == 'year') {
                    this.range = new Date()
                }
                if (this.analyticType == 'month') {
                    this.range = this.getDateOfMonth(30)
                }
                if (this.analyticType == 'week') {
                    this.range = this.getDateOfMonth(7)
                }
            }, 100)
        },

        range() {
            this.getDataAccountCompetitor(this.accountCompetitor)
            this.getDataAnalysis()
        },

        accountCompetitor() {
            this.getDataAccountCompetitor(this.accountCompetitor)
        },
    },
    methods: {
        toogleManual() {
            this.isShowManual = !this.isShowManual
        },
        // disabledDate
        disabledDate(time) {
            let d = new Date()
            return time.getTime() > d.setDate(d.getDate() - 1)
        },

        formatRange() {
            let sinceTime = this.range[0] ? this.range[0] : this.range
            let untilTime = this.range[1] ? this.range[1] : ''

            return [dayjs(sinceTime).format('YYYY-M-D'), dayjs(untilTime).format('YYYY-M-D')]
        },

        async getDataAccountCompetitor(account_id) {
            if (account_id === null) {
                return
            }

            let params = this.formatRange()
            await getDataAccountCompetitor(account_id, params[0], params[1]).then(async (res) => {
                this.accountTables = res.data.competitor_account_insights
                this.$emit('getDataCompeteAccount', this.accountTables)
                this.dataFirstDay = res.data.accountFirstday ?? {}
                this.$emit('getDataFirstDayAcc', this.dataFirstDay)
                this.dataFollowers = res.data.dataFollowers
                this.dataLabels = res.data.dataLabels
            })
        },

        showLineChart(type) {
            this.lineTab = type
        },
        getDateOfMonth(day) {
            const date = []
            date[0] = new Date(new Date().setDate(new Date().getDate() - day))
            date[1] = new Date(new Date().setDate(new Date().getDate() - 1))
            return date
        },

        async getDataAnalysis() {
            let params = this.formatRange()
            await getDataAnalysis(params[0], params[1]).then(async (res) => {
                this.dataLabelTransition = res.data.dataLabels
                this.dataFollowerTransition = res.data.dataFollowers
                this.dataPostTransition = res.data.dataPosts
                this.dataAllAccount = res.data.allAccount
                this.$emit('getDataCompeteFollower', this.dataAllAccount)
                this.$emit('getAccountTransitionTab', this.accountTransitionTab)
            })
        },
        customDatePickerText() {
            let datePicker = document.getElementsByClassName('customDatePicker')

            let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[0]
            const preYearNode = document.createTextNode("<<前年");
            btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0]);
            btnPreYear.style.cssText += 'margin: 0 5px 0 0';

            let btnPreMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[1]
            const preMonthNode = document.createTextNode("<前月");
            btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0]);
            btnPreMonth.style.cssText += 'margin: 0';

            let btnNextYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[2]
            const nextYearNode = document.createTextNode("翌年>>");
            btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0]);
            btnNextYear.style.cssText += 'margin: 0 0 0 5px';

            let btnNextMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[3]
            const nextMonthNode = document.createTextNode("翌月>");
            btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0]);
            btnNextMonth.style.cssText += 'margin: 0';
        }
    },
}
</script>
<style>
.tab-active {
    border-bottom: 3px solid #be3d8f;
}
</style>
