import axios from "axios";

export async function getDataMultipleMember(arrBusinessId, year, group_id) {
    let convertYear = year.getFullYear()
    return await axios.get(route("api.enterprise.get-multiple-member"), {
        params: {
            arrBusinessId: arrBusinessId,
            year: convertYear,
            group_id: group_id,
        },
    });
}

export async function getDataMember(business_id, range) {
    return await axios.get(route("api.enterprise.get-member", business_id), {
        params: {
            since: range[0],
            until: range[1],
        },
    });
}
