<template>
    <Dashboard v-loading="loading">
        <LinkInstagram v-if="!showLinkInstagram" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li class="mr-4">
                    <Link :href="route('admin.accounts.index')" class="font-normal breakcumb-active">アカウント分析</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="tabs mt-11 ml-10">
                <ul class="flex">
                    <li class="sm:text-sm md:text-base pr-4 relative pb-5 px-sm"
                        :class="{ active: current_tab == 'tab-1' }" @click="current_tab = 'tab-1'">
                        基本サマリー
                    </li>
                    <li class="sm:text-sm md:text-base px-6 relative pb-5 px-sm"
                        :class="{ active: current_tab == 'tab-2' }" @click="current_tab = 'tab-2'">
                        フォロワーサマリー
                    </li>
                    <li class="sm:text-sm md:text-base px-6 relative pb-5 px-sm"
                        :class="{ active: current_tab == 'tab-3' }" @click="current_tab = 'tab-3'">
                        インサイトサマリー
                    </li>
                </ul>
            </div>
        </template>
        <UserNav />
        <template v-slot:manual>
            <UserManual :isShow="isShowManual" :content="arrContent" @closeManualPopup="toogleManual" />
        </template>
        <div class="bg-background el__input-height">
            <div class="sm:px-5 md:px-10 md:mb-8">
                <div class="flex md:flex-row sm:flex-col justify-between">
                    <div class="flex items-center">
                        <div class="md:relative">
                            <div class="flex flex-col md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                                <div class="flex">
                                    <p class="text-button_pink text-3sm font-bold">アカウント分析</p>
                                    <div v-if="hasManual" class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer"
                                        @click="toogleManual">
                                        <img :src="suggestIcon" />
                                    </div>
                                </div>
                                <div class="block">
                                    <div>フォロワー増減通知メール</div>
                                    <el-switch
                                    v-model="isSend"
                                    style="--el-switch-on-color: #c23c8e; --el-switch-off-color: #c1c1c1"
                                    active-text="ON"
                                    inactive-text="OFF"
                                    :before-change="changeStatusSendMail"
                                    />
                                    <!-- :loading="loadingSwitch" -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center md:flex-nowrap sm:flex-wrap">
                        <el-date-picker v-model="range" size="large" type="daterange" range-separator="-"
                            start-placeholder="Start date" end-placeholder="End date" :disabled-date="disabledDate"
                            v-if="analyticType !== 'year'" :clearable="false" class="sm:my-2 md:my-0"
                            popper-class="customDatePicker" />
                        <el-date-picker v-model="year" size="large" type="year" :disabled-date="disabledDate"
                            :clearable="false" class="sm:my-2 md:my-0" popper-class="customYearPicker" v-else />
                        <el-select v-model="analyticType" class="ml-2 w-28 sm:ml-0 md:ml-2" size="large">
                            <el-option v-for="item in analytic_type_options" :key="item.value" :label="item.label"
                                :value="item.value" />
                        </el-select>
                    </div>
                </div>
                <div class="">
                    <Basic class="mt-5" :range="range" :accountTables="accountTables" v-if="current_tab == 'tab-1'" />
                    <Follower class="mt-5" :range="range" :accountTables="accountTables"
                        v-if="current_tab == 'tab-2'" />
                    <Detail class="mt-5" :range="range" :accountTables="accountTables" v-if="current_tab == 'tab-3'" />
                </div>
            </div>

            <div class="sm:px-5 md:px-10 pb-28 rounded">
                <div class="pt-8 tabs tab-mr-0 flex items-center justify-start bg-white p-5">
                    <ul class="flex">
                        <li class="pr-4 relative pb-2 px-sm" :class="{ active: lineTab == 'all' }"
                            @click.prevent="showLineChart('all')">
                            推移
                        </li>
                        <li class="px-6 relative pb-2 px-sm" :class="{ active: lineTab == 'followers' }"
                            @click.prevent="showLineChart('followers')">
                            フォロワー推移
                        </li>
                        <li class="px-6 relative pb-2 px-sm" :class="{ active: lineTab == 'interactives' }"
                            @click.prevent="showLineChart('interactives')">
                            エンゲージメント推移
                        </li>
                    </ul>
                </div>
                <LineChart class="p-4 mt-0" :range="range" :year="year" :showLine="lineTab"
                    :analyticType="analyticType" />
                <hr class="border border-solid border-[#DADADA] bg-white rounded my-8" />
                <div class="my-4">
                    <div class="items-center text-option font-semibold mb-4 m-1">
                        <p class="text-button_pink text-3sm">フォロワー属性</p>
                    </div>
                </div>
                <div class="flex sm:justify-center md:justify-between sm:flex-wrap md:flex-nowrap">
                    <div class="border border-solid border-[#DADADA] bg-white rounded sm:w-full md:w-[32%] mb-2">
                        <PieChart :range="range" :dataGenderForChart="dataGenderForChart" />
                    </div>
                    <div
                        class="border border-solid border-[#DADADA] bg-white rounded sm:w-full md:w-[32%] mb-2 sm:mx-0 md:mx-5">
                        <StackBarChart :range="range" :dataGenderForChart="dataGenderForChart" />
                    </div>
                    <div class="border border-solid border-[#DADADA] bg-white rounded sm:w-full md:w-[32%] mb-2">
                        <HorizontalChart :range="range" :dataCityForChart="dataCityForChart" />
                    </div>
                </div>
                <div class="hash_analysis mt-8 rounded">
                    <div class="my-4">
                        <div class="items-center text-option font-semibold mb-4 m-1">
                            <p class="text-button_pink text-3sm">ハッシュタグ分析</p>
                        </div>
                    </div>
                    <Hashtag :range="range" :year="year" :analyticType="analyticType" />
                </div>
            </div>
        </div>
    </Dashboard>
</template>

<script>
import Dashboard from '@/Layouts/Authenticated.vue'
import Notification from '@/Components/Notifications.vue'
import Table from '@/Components/Table.vue'
import Datepicker from '@/Components/Datepicker.vue'
import LineChart from './LineChart.vue'
import PieChart from './PieChart.vue'
import StackBarChart from './StackBarChart.vue'
import HorizontalChart from './HorizonBarChat/Basic.vue'
import { Link } from '@inertiajs/inertia-vue3'
import Basic from './Tables/Basic.vue'
import Follower from './Tables/Follower.vue'
import Hashtag from './Tables/Hashtag.vue'
import Detail from './Tables/Detail.vue'
import * as accountAPI from '@/Api/Account'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import UserNav from '@/Components/UserNav.vue'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import UserManual from '@/Components/UserManual.vue'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    ArcElement,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
} from 'chart.js'
import dayjs from 'dayjs'
import { ElMessageBox } from 'element-plus'

ChartJS.register(Title, Tooltip, Legend, LineElement, ArcElement, BarElement, CategoryScale, LinearScale, PointElement)
export default {
    name: 'Index.vue',
    components: {
        BreadCrumb,
        Dashboard,
        Notification,
        LineChart,
        PieChart,
        StackBarChart,
        HorizontalChart,
        Table,
        Datepicker,
        Link,
        Basic,
        Follower,
        Hashtag,
        Detail,
        UserNav,
        LinkInstagram,
        UserManual
    },

    data() {
        return {
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowManual: false,
            hasManual: true,
            arrContent: ['フォロワー数の推移、フォロワーの属性(男女比・年齢層・地域)を確認することができます。', 'また、投稿で使用したハッシュタグの分析(いいね数・リーチ数・インプレッション数など)を行うことができ、エンゲージメント率の高いハッシュタグの選定が可能です。', '各分析項目のより詳細な解説は左タブから対象項目を選択することで確認することができます。'],
            loading: false,
            showLinkInstagram: true,
            analytic_type_options: [
                { label: '週間', value: 'week' },
                { label: 'ヶ月', value: 'month' },
                { label: '年', value: 'year' },
            ],
            range: [],
            year: new Date(),
            analyticType: 'month',
            lineTab: 'all',
            current_tab: 'tab-1',
            accountInsights: [],
            accountTables: [],
            calculation: {},
            dataGenderForChart: {},
            dataCityForChart: {},
            isSend: true,
            loadingSwitch: true,
        }
    },

    async created() {
        this.loading = true
        this.checkLinkInstagram()
    },
    mounted() {
        this.customDatePickerText()
    },
    updated() {
        this.customDatePickerText()
    },
    watch: {
        range() {
            this.fetchData()
            this.getStatusSendMail()
        },
        year() {
            this.fetchData()
            this.getStatusSendMail()
        },
        current_tab(val) {
            this.isShowManual = false
            // this.hasManual = val === 'tab-1' ? true : false
        },
        analyticType() {
            this.loading = true
            // check rang is year, month, week
            if (this.analyticType == 'year') {
                this.year = new Date()
            }
            if (this.analyticType == 'month') {
                this.range = this.getDateOfMonth(30)
            }
            if (this.analyticType == 'week') {
                this.range = this.getDateOfMonth(7)
            }
            this.loading = false
        },
    },

    methods: {
        changeStatusSendMail() {
            return new Promise((resolve) => {
                axios
                    .post(route('api.change-send-mail-status'))
                    .then((_) => {
                        this.getStatusSendMail()
                        let msg = this.isSend == true ? 'フォロワー増減通知メールをOFFに切り替えました。' : 'フォロワー増減通知メールをONに切り替えました。'
                        this.$toast.success(msg)
                        return resolve(true)
                    }).catch((_)=>{
                        return resolve(false)
                    })
            })

        },
        getStatusSendMail() {
            axios
                .get(route('api.get-send-mail-status'))
                .then((res) => {
                    this.isSend = res.data == 0 ? false : true
                    this.loadingSwitch = false
                })
        },
        toogleManual() {
            this.isShowManual = !this.isShowManual
        },
        onInstaSubmitSuccess() {
            this.showLinkInstagram = true
        },
        // fetch data
        async fetchData() {
            this.loading = true
            this.getFollowDataTable()
            this.getAccountChartData()
            setTimeout(() => {
                this.loading = false
            }, 1000)
        },
        // get account chart data
        async getAccountChartData() {
            this.loading = true
            let start_at = this.range[0]
            let end_at = this.range[1]
            if (this.analyticType === 'year') {
                if (dayjs().year() === dayjs(this.year).year()) {
                    start_at = dayjs(this.year).startOf('year').format('YYYY-M-D')
                    end_at = dayjs().subtract(1, 'day').format('YYYY-M-D')
                } else {
                    start_at = dayjs(this.year).startOf('year').format('YYYY-M-D')
                    end_at = dayjs(this.year).endOf('year').format('YYYY-M-D')
                }
            } else {
                start_at = dayjs(this.range[0]).format('YYYY-M-D')
                end_at = dayjs(this.range[1]).format('YYYY-M-D')
            }
            const response = await accountAPI.getMultipleAccountData(start_at, end_at, this.analyticType)
            this.accountInsights = response.status === 200 ? response.data?.accountInsights : []
            this.calculation = response.status === 200 ? response.data?.calculation : {}
            this.dataGenderForChart = response.status === 200 ? response.data?.dataGenderForChart : {}
            this.dataCityForChart = response.status === 200 ? response.data?.dataCityForChart : {}
            this.loading = false
        },
        // get followers dataTable
        async getFollowDataTable() {
            let start_at = this.range[0]
            let end_at = this.range[1]
            if (this.analyticType === 'year') {
                if (dayjs().year() === dayjs(this.year).year()) {
                    start_at = dayjs(this.year).startOf('year').format('YYYY-M-D')
                    end_at = dayjs().subtract(1, 'day').format('YYYY-M-D')
                } else {
                    start_at = dayjs(this.year).startOf('year').format('YYYY-M-D')
                    end_at = dayjs(this.year).endOf('year').format('YYYY-M-D')
                }
            } else {
                start_at = dayjs(this.range[0]).format('YYYY-M-D')
                end_at = dayjs(this.range[1]).format('YYYY-M-D')
            }
            const responseTable = await accountAPI.getFollowSummary(start_at, end_at, this.analyticType)
            this.accountTables = responseTable?.data
        },
        // get date of month
        getDateOfMonth(day) {
            const date = []
            date[0] = new Date(new Date().setDate(new Date().getDate() - day))
            date[1] = new Date(new Date().setDate(new Date().getDate() - 1))
            return date
        },
        // disabledDate
        disabledDate(time) {
            let d = new Date()
            return time.getTime() > d.setDate(d.getDate() - 1)
        },
        // show line chart
        showLineChart(type) {
            this.lineTab = type
        },
        // link instagram
        async checkLinkInstagram() {
            const check = await accountAPI.checkInsAccount()
            if (check.data) {
                this.range = this.getDateOfMonth(30)
                this.showLinkInstagram = true
            } else {
                this.showLinkInstagram = false
            }
        },
        customDatePickerText() {
            if (this.analyticType === 'year') {
                let yearPicker = document.getElementsByClassName('customYearPicker')
                let btnPreYear = yearPicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0]
                const preYearNode = document.createTextNode("<<前年");
                btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0]);
                btnPreYear.style.cssText += 'margin: 0';

                let btnNextYear = yearPicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[4]
                const nextYearNode = document.createTextNode("翌年>>");
                btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0]);
                btnNextYear.style.cssText += 'margin: 0';

            } else {
                let datePicker = document.getElementsByClassName('customDatePicker')

                let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[0]
                const preYearNode = document.createTextNode("<<前年");
                btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0]);
                btnPreYear.style.cssText += 'margin: 0 5px 0 0';

                let btnPreMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[1]
                const preMonthNode = document.createTextNode("<前月");
                btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0]);
                btnPreMonth.style.cssText += 'margin: 0';

                let btnNextYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[2]
                const nextYearNode = document.createTextNode("翌年>>");
                btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0]);
                btnNextYear.style.cssText += 'margin: 0 0 0 5px';

                let btnNextMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[3]
                const nextMonthNode = document.createTextNode("翌月>");
                btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0]);
                btnNextMonth.style.cssText += 'margin: 0';
            }
        }
    },
}
</script>
<style scoped>
table {
    border-collapse: collapse;
}

.user-tags {
    border-radius: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.search_icon {
    padding: 10px 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #d02d92;
}

.select__status {
    background: #ffffff;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
}

@media (max-width: 640px) {
    .tab-mr-0 {
        margin: 10px 0 0 0 !important;
    }
}
</style>
