<script setup>
import BreezeButton from "@/Components/Button.vue";
import BreezeGuestLayout from "@/Layouts/Guest.vue";
import BreezeInput from "@/Components/Input.vue";
import BreezeValidationErrors from "@/Components/ValidationErrors.vue";
import { Head, Link, useForm } from "@inertiajs/inertia-vue3";

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: "",
    password: "",
    remember: false,
});

const submit = () => {
    form.post(route("login"), {
        onFinish: () => form.reset("password"),
    });
};
</script>

<template>
    <BreezeGuestLayout>
        <Head title="Log in" />

        <form @submit.prevent="submit">
            <div class="flex items-center justify-center">
                <BreezeInput
                    id="email"
                    type="email"
                    class="block w-96 rounded-sm p-3"
                    v-model="form.email"
                    required
                    autofocus
                    autocomplete="username"
                    placeholder="メールを入力してくだいさい"
                />
            </div>

            <div class="mt-5 flex items-center justify-center">
                <BreezeInput
                    id="password"
                    type="password"
                    class="block w-96 rounded-sm p-3"
                    v-model="form.password"
                    required
                    autocomplete="current-password"
                    placeholder="パスワードを入力してください"
                />
            </div>

            <BreezeValidationErrors class="mb-5" />

            <div class="flex items-center justify-center mt-7">
                <BreezeButton
                    class="bg-indigo-500 hover:bg-indigo-700 pt-5 pb-5 pl-16 pr-16 rounded-sm"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                >
                    ログイン
                </BreezeButton>
            </div>
            <div class="flex items-center justify-center mt-12">
                <Link
                    v-if="canResetPassword"
                    :href="route('password.request')"
                    class="text-base text-indigo-600 hover:text-indigo-400 text-2sm"
                >
                    パスワードをお忘れの方はこちら
                </Link>
            </div>
        </form>
    </BreezeGuestLayout>
</template>
