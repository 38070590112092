import axios from 'axios'
export async function getListPostAccount(keyword, status, currentPage) {
  return await axios.get(route('api.posts.index'), {
    params: {
      page: currentPage,
      search: keyword,
      status: status,
    },
  })
}
export async function listPostForExport(keyword, status, currentPage) {
  return await axios.get(route('api.list_post_for_export'), {
    params: {
      page: currentPage,
      search: keyword,
      status: status,
    },
  })
}
export async function getPost(postId) {
  if (!postId) return {}
  return await axios.get(route('api.posts.show', { id: postId }))
}

export async function analyticsPost(accountId, startDate, endDate, postCategory) {
  return await axios.get(route('api.analytic.post'), {
    params: {
      account_id: accountId,
      since: startDate,
      until: endDate,
      post_category: postCategory
    },
  })
}

export async function getPostCrawl(postId) {
  return await axios.get(route('api.post_crawl.index'), {
    params: {
      post_id: postId,
    },
  })
}

export async function getListPostOfGroup(currentTab, search, limit, page) {
  return await axios.get(route('api.list_post_on_group'), {
    params: {
      currentTab: currentTab,
      search: search,
      limit: limit,
      page: page,
    },
  })
}
