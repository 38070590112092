<template>
    <dashboard>
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li v-if="check_role_admin">
                    <Link :href="route('admin.users.index', { set_old_params: true})" class="font-normal">管理画面</Link>
                </li>
                <span v-if="check_role_admin" class="font-normal text-notice_date mx-2">></span>
                <li>
                    <Link :href="route('admin.users_infor')" class="font-normal breakcumb-active">アカウント情報</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li @click="toggleTabs(1)" class="sm:px-3 md:px-6 relative pb-5">アカウント管理</li>
                    <li @click="toggleTabs(2)" class="sm:px-3 md:px-6 relative pb-5">決済方法・プラン管理</li>
                    <li @click="toggleTabs(3)" class="sm:px-3 md:px-6 relative pb-5 active">ご利用明細</li>
                </ul>
            </div>
        </template>
        <div class="">
            <!-- <Notifications/> -->
            <div class="py-5">
                <UserInformation :userSelected="user"/>
            </div>
            <div class="sm:p-5 md:p-8">
                <h2 class="mb-5 text-xl font-semibold text-pink-500 custom-current-size-title">ご利用明細</h2>
                <div style="width: 100%" class="overflow-x-auto">
                    <table class="mt-2.5 overflow-y-scroll" style="min-width: 870px">
                        <tbody class="bg-body_table">
                        <tr v-for="(item, index) in tableData" :key="index">
                            <td class="p-5" v-for="(label, index) in tableLabel" :key="index">
                                <p class="whitespace-nowrap">{{ item[label.key] }}</p>
                            </td>
                            <td style="width: 200px">
                                <div class="flex items-center justify-center align-middle py-4">
                                    <button @click="downloadInvoice(item.ch_id)"
                                            class="bg-pink text-white px-8 py-3 mx-4 rounded-sm whitespace-nowrap">
                                        請求書
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </dashboard>
</template>

<script>
import Dashboard from '../../Dashboard.vue'
import Table from '@/Components/Table.vue'
import UserInformation from '@/Components/UserInformation.vue'
import Notifications from '@/Components/Notifications.vue'
import {Link} from '@inertiajs/inertia-vue3'
import * as users from '@/Api/Users'
import Label from '@/Components/Label'
import BreadCrumb from '@/Components/Breadcrumb.vue'

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },

        toggleTabs: {
            type: Function,
            required: true,
        },
    },

    components: {
        Label,
        Dashboard,
        Table,
        UserInformation,
        Notifications,
        Link,
        BreadCrumb,
    },

    created() {
        this.getListInvoice()
          this.checkRoleAdmin()
    },
    data() {
        return {
            tableLabel: [{key: 'date_invoice'}, {key: 'content'}, {key: 'status'}, {key: 'amount'}],
            tableData: [],
        }
    },
    methods: {
        formatDate(oldDate) {
            if (oldDate) {
                const date = new Date(oldDate)
                let day = date.getDate()
                let month = date.getMonth() + 1
                let year = date.getFullYear()
                return `${year}年${month}月${day}日`
            }
        },
        checkRoleAdmin() {
        this.$page.props.auth.user.roles.filter((role) => {
            if(role.name == 'user_agency' || role.name == 'user_oem' || role.name == 'user_enterprise' || role.name == 'user_normal')
            {
            this.check_role_admin =  false
            }
            else
            {
            this.check_role_admin =  true
            }
        })
        },
        async getListInvoice() {
            const res = await users.getListInvoice(this.user.id)
            this.tableData = res.data.data.map((data, key) => ({
                id: key + 1,
                date_invoice: this.formatDate(data.date_invoice),
                content: data.content,
                status: "決済完了",
                amount: (data.amount).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' }) + '（税込）',
                ch_id: data.ch_id,
            }))
        },

        downloadInvoice(ch_id) {
            window.location.href = route('admin.invoices.download', ch_id)
        },
    },
}
</script>

<style lang="scss" scoped>
*{
    font-size: 13px !important;
}

.custom-current-size-title{
    font-size: 14px !important;
}

button{
    font-size: 14px !important;
}

table {
    border-collapse: collapse;
    width: 100%;

    tr {
        box-shadow: 0px 1px 0px rgba(169, 169, 169, 0.25);
        border-bottom: solid 1px rgba(169, 169, 169, 0.25);
    }

    tr:last-of-type {
        border-bottom: 0;
    }

    &.light {
        tr {
            background-color: #ffffff;
        }

        thead tr {
            border: 1px solid #e2e2e2;
        }

        th {
            background: #ffffff;
            color: #414141;
            border: 0;
        }
    }
}
</style>
