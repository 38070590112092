<script setup>
import BreezeButton from "@/Components/Button.vue";
import BreezeGuestLayout from "@/Layouts/GuestRegister.vue";
import BreezeInput from "@/Components/Input.vue";
import BreezeLabel from "@/Components/Label.vue";
import { Head, useForm } from "@inertiajs/inertia-vue3";

const form = useForm({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    terms: false,
});
const submit = () => {
    form.post(route("register"), {
        onFinish: () => form.reset("password", "password_confirmation"),
    });
};
</script>

<template>
    <BreezeGuestLayout>
        <Head title="Register" />

        <!-- <BreezeValidationErrors class="mb-4 " /> -->

        <!-- Title -->
        <div class="mb-10">
            <div class="flex justify-center">
                <h1 class="text-pink-400 font-bold text-2xl">
                    デモのお申し込み
                </h1>
            </div>

            <div class="flex justify-center my-3">
                <h5>テキストテキストテキストテキストテキストテキスト</h5>
            </div>
        </div>
        <!-- Form -->
        <form @submit.prevent="submit">
            <div class="flex justify-center mb-16">
                <input
                    id=""
                    ref="avartarUpload"
                    class="hidden"
                    name=""
                    type="file"
                />
                <div
                    class="flex items-center flex-col justify-cente py-16 px-36 bg-white"
                >
                    <svg
                        fill="none"
                        height="23"
                        viewBox="0 0 22 23"
                        width="22"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M22 9.2217V12.9972H0V9.2217H22ZM13.0501 0V23H8.97194V0H13.0501Z"
                            fill="black"
                        />
                    </svg>
                    <p class="text-black text-base-xl mt-2">画像</p>
                </div>
            </div>

            <!-- Title -->
            <div class="flex justify-center mb-9">
                <h1 class="text-pink-400 font-bold text-2xl">
                    フリートライアルに申し込む
                </h1>
            </div>

            <!-- Last Name -->
            <div class="flex justify-center items-center">
                <BreezeLabel class="text-xs w-24">氏名（姓）</BreezeLabel>
                <BreezeInput
                    id="lastName"
                    v-model="form.lastName"
                    autocomplete="name"
                    autofocus
                    class="block rounded-sm p-3 w-full"
                    placeholder="テキストテキスト"
                    required
                    type="text"
                />
            </div>

            <!-- First Name -->
            <div class="flex justify-center items-center mt-4">
                <BreezeLabel class="text-xs w-24">名前（名）</BreezeLabel>
                <BreezeInput
                    id="firstName"
                    v-model="form.firstName"
                    autocomplete="name"
                    class="block rounded-sm p-3 w-full"
                    placeholder="テキストテキスト"
                    required
                    type="text"
                />
            </div>

            <!-- Email -->
            <div class="flex justify-center items-center mt-4">
                <BreezeLabel class="text-xs w-24">メールアドレス</BreezeLabel>
                <BreezeInput
                    id="email"
                    v-model="form.email"
                    autocomplete="username"
                    class="block rounded-sm p-3 w-full"
                    placeholder="テキストテキスト"
                    required
                    style="border: 0"
                    type="email"
                />
            </div>

            <!-- Company Name -->
            <div class="flex justify-center items-center mt-4">
                <BreezeLabel class="text-xs w-24">会社名</BreezeLabel>
                <BreezeInput
                    id="company"
                    v-model="form.company"
                    autocomplete="new-password"
                    class="block rounded-sm p-3 w-full"
                    placeholder="テキストテキスト"
                    required
                    type="text"
                />
            </div>

            <!-- Company Location -->
            <div class="flex justify-center items-center mt-4">
                <BreezeLabel class="text-xs w-24">会社の所在地</BreezeLabel>
                <BreezeInput
                    id="location"
                    v-model="form.location"
                    autocomplete="new-password"
                    class="block rounded-sm p-3 w-full"
                    placeholder="テキストテキスト"
                    required
                    type="text"
                />
            </div>

            <!-- Phone Number -->
            <div class="flex justify-center items-center mt-4">
                <BreezeLabel class="text-xs w-24">電話番号</BreezeLabel>
                <BreezeInput
                    id="phone"
                    v-model="form.phone"
                    class="block rounded-sm p-3 w-full"
                    placeholder="テキストテキスト"
                    required
                    style="border: 0"
                    type="text"
                />
            </div>

            <!-- Term of Service -->
            <div class="flex justify-end items-center mt-4 pr-10">
                <div class="flex">
                    <input
                        id="flexCheckChecked"
                        class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        value=""
                    />
                    <label
                        class="form-check-label inline-block text-gray-800"
                        for="flexCheckChecked"
                    >
                        利用規約・プライバシーポリシーに同意する
                    </label>
                </div>
            </div>

            <!--            <div class="flex justify-center items-center mt-4">-->
            <!--                <BreezeInput id="password_confirmation" type="password" class="mt-1 block w-full" v-model="form.password_confirmation" required autocomplete="new-password" placeholder="テキストテキスト" />-->
            <!--            </div>-->

            <div class="flex items-center justify-center mt-7">
                <BreezeButton
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                    class="bg-indigo-500 hover:bg-indigo-700 pt-5 pb-5 px-56 rounded-sm"
                >
                    送信
                </BreezeButton>
            </div>
        </form>
    </BreezeGuestLayout>
</template>
