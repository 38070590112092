<template>
    <DrashBoard>
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li>
                    <Link :href="route('admin.post.index')" :data="{ tab: oldTab, page: oldPage }" class="font-normal">
                    投稿分析</Link>
                </li>
                <span class="font-normal text-notice_date mx-2">></span>
                <li>
                    <Link href="/post/analytic" :data="{ id: idPost }" class="font-normal breakcumb-active">投稿初速分析
                    </Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li class="px-6 relative pb-5 active">投稿初速分析</li>
                </ul>
            </div>
        </template>
        <UserNav />
        <template v-slot:manual>
            <UserManual :isShow="isShowManulal" :content="arrContent" @closeManualPopup="toogleManual" />
        </template>
        <div class="bg-background">
            <div class="md:px-14.5 sm:px-3">
                <div class="flex items-center md:relative">
                    <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">
                        <h2 class="text-title text-button_pink font-semibold">投稿初速分析</h2>
                        <div class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer" @click="toogleManual">
                            <img :src="suggestIcon" />
                        </div>
                    </div>
                </div>
                <div class="overflow-x-auto">
                    <table class="mt-2.5 overflow-y-scroll w-full">
                        <thead>
                            <tr>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2">
                                    投稿画像
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2">
                                    タイプ
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2 w-80">
                                    投稿内容
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2">
                                    ハッシュタグ
                                </th>
                                <th class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2"
                                    style="width: 200px">
                                    投稿日時
                                </th>
                                <th class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2"
                                    v-for="(label, index) in label" :key="index">
                                    {{ label.text }}
                                </th>
                                <th class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2"
                                    v-for="(item, index) in tableData" :key="index">
                                    {{ item.post_category == 0 ? 'エンゲージメント率' : 'エンゲージメント数' }}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-body_table">
                            <tr class="border-" v-for="(item, index) in tableData" :key="index">
                                <td class="py-5.5 flex items-center justify-center">
                                    <video v-if="checkTypeFile(item?.is_carousel ? item.files[0] : item.file)" controls
                                        width="112" style="height: 64px">
                                        <source :src="item?.is_carousel ? item.files[0] : item.file" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <img v-else class="w-28 h-16" :src="item?.is_carousel ? item.files[0] : item.file"
                                        alt="" />
                                </td>
                                <td class="pl-4 py-2">
                                    <p class="post-content showFullText break-all">
                                        {{ item.post_category == 0 ? '通常' : 'リール' }}
                                    </p>
                                </td>
                                <td class="pl-4 py-2">
                                    <p class="post-content showFullText break-all">
                                        {{ item.content }}
                                    </p>
                                    <p @click="viewDetailContent(item)"
                                        class="text-xs text-button_profile font-semibold text-right block mt-2 hover:cursor-pointer">
                                        全文を見る
                                    </p>
                                </td>
                                <td class="py-5.5">
                                    <p class="text-center">
                                        <span class="px-1 paragraph-overflow">{{ formartHashtag(item.hashtag) }}</span>
                                    </p>
                                </td>
                                <td class="py-5.5">
                                    <p class="text-center">{{ formatTime(item.updated_at) }}</p>
                                </td>
                                <td class="py-5.5" v-for="(label, index) in label" :key="index">
                                    <p class="text-center">{{ item[label.key] }}</p>
                                </td>
                                <td class="py-5.5">
                                    <p class="text-center" v-if="item.post_category == 0">
                                        {{ item.engagement ? formatToFloat(item.engagement + '%') : '0%' }}
                                    </p>
                                    <p class="text-center" v-else>{{ formatToFloat(item.reels) }}</p>
                                </td>
                            </tr>
                        </tbody>
                        <!-- dialog detail content  -->
                        <el-dialog v-model="openDialog" title="投稿内容" width="50%" append-to-body>
                            <PopupReviewPost :content="dataDetailContent.content"
                                :file="dataDetailContent?.is_carousel ? dataDetailContent.files[0] : dataDetailContent.file"
                                :fileType="
                                    getTypeFile(dataDetailContent?.is_carousel ? dataDetailContent.files[0] : dataDetailContent.file)
                                " :carouselItems="dataDetailContent?.is_carousel ? dataDetailContent.files : []"
                                :hashtag="dataDetailContent.hashtag" :tag="dataDetailContent.tag"
                                :username="accountName" />
                        </el-dialog>
                        <!-- end dialog detail content  -->
                    </table>
                </div>
                <LineChart class="bg-white py-10 md:px-10 border-2 mt-10" :chartData="chartData" />
                <div class="my-5 overflow-auto">
                    <!-- <Table :label="timeTableLabel" :data="timeTableData" /> -->
                    <table class="mt-2.5 overflow-y-scroll w-full">
                        <thead>
                            <tr>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2">
                                    時間
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2">
                                    いいね数
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2">
                                    コメント数
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2">
                                    リーチ数
                                </th>
                                <th
                                    class="text-2sm py-4 font-normal px-5 bg-header_table text-white border-r text-center rounded-md border-2">
                                    保存数
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-body_table">
                            <tr class="border-" v-for="(item, index) in timeTableData" :key="index">
                                <td class="py-5.5 text-center">
                                    <p>{{ item.time_crawl.slice(11, 19) }}</p>
                                </td>
                                <td class="py-5.5 text-center">
                                    <p>{{ formatToInteger(item?.like) }}</p>
                                </td>
                                <td class="py-5.5 text-center">
                                    <p>{{ formatToInteger(item?.comment) }}</p>
                                </td>
                                <td class="py-5.5 text-center">
                                    <p>{{ formatToInteger(item?.reach) }}</p>
                                </td>
                                <td class="py-5.5 text-center">
                                    <p>{{ formatToInteger(item?.saved) }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </DrashBoard>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import LineChart from './LineChart.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import Table from '@/Components/Table.vue'
import { Link } from '@inertiajs/inertia-vue3'
import PopupReviewPost from '@/Components/PopupReviewPost.vue'
import { Back } from '@element-plus/icons-vue'
import * as postAPI from '@/Api/Posts'
import { getUserDetailByAccountId } from '@/Api/Users'
import { parseTagA } from '@/Helpers/xss'
import UserManual from '@/Components/UserManual.vue'
import UserNav from '@/Components/UserNav.vue'

export default {
    components: {
        Table,
        DrashBoard,
        BreadCrumb,
        LineChart,
        PopupReviewPost,
        Link,
        Back,
        UserManual,
        UserNav
    },
    props: ['data'],
    data() {
        return {
            openDialog: false,
            idPost: 1,
            oldTab: '',
            oldPage: '',
            dataDetailContent: [],
            accountName: '',
            label: [
                { text: 'いいね数', key: 'like' },
                { text: 'コメント数', key: 'comment' },
                { text: '保存数', key: 'saved' },
                { text: 'リーチ数', key: 'reach' },
            ],
            timeTableLabel: [
                {
                    text: 'いいね数',
                    key: 'like',
                },
                {
                    text: 'コメント数',
                    key: 'comment',
                },
                {
                    text: '保存数',
                    key: 'saved',
                },
                {
                    text: 'リーチ数',
                    key: 'reach',
                },
            ],
            timeTableData: [],
            tableData: [],
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: 'いいね数',
                        data: [],
                        borderColor: '#868686',
                        fill: false,
                    },
                    {
                        label: 'コメント数',
                        data: [],
                        borderColor: 'red',
                        fill: false,
                    },
                    {
                        label: 'リーチ数',
                        data: [],
                        borderColor: 'black',
                        fill: false,
                    },
                    {
                        label: '保存数',
                        data: [],
                        borderColor: 'green',
                        fill: false,
                    },
                ],
            },
            suggestIcon: window.location.origin + '/img/suggestion.png',
            isShowManulal: false,
            arrContent: ['初速分析では、投稿されてから24時間以内のエンゲージメントを分析することができます。', 'Instagramでは、投稿後24時間以内のエンゲージメントによってその後のインプレッションにも影響があります。', '初速の結果と、最終的なエンゲージメントの結果を比較して、投稿の良し悪しを判断しましょう。', 'また、投稿時間を基軸として24時間計測できるため、投稿時間の分析や、バズる投稿であるかの判断軸にもなります。'],
        }
    },
    async created() {
        let url = new URL(window.location.href)
        let searchParams = new URLSearchParams(url.search)
        await this.getPost(searchParams.get('id'))
        this.idPost = searchParams.get('id')
        this.oldTab = searchParams.get('tab')
        this.oldPage = searchParams.get('page')
        await this.getCrawlPost(searchParams.get('id'))
        this.getAccountPost(searchParams.get('id'))
    },
    methods: {
        toogleManual() {
            this.isShowManulal = !this.isShowManulal
        },
        parseString(str) {
            return parseTagA(str)
        },
        async getAccountPost(postId) {
            const posts = await postAPI.getPost(postId)
            const account = await getUserDetailByAccountId(posts.data.data.account_id)
            this.accountName = account.data.data
        },
        checkTypeFile(filePath) {
            const arr = filePath.split('.')
            if (arr[arr.length - 1] == 'mp4' || arr[arr.length - 1] == 'm4v') {
                return true
            }
            return false
        },
        getTypeFile(fileName) {
            return fileName.split('.').pop()
        },
        viewDetailContent(item) {
            this.dataDetailContent = item
            this.openDialog = true
        },
        formartTag(tag) {
            if (tag) {
                return tag
                    .map((tags) => '@' + tags.username)
                    .toString()
                    .replaceAll(',', ' ')
            }
            return tag
        },
        getCrawlPost: async function (postId) {
            const data = await postAPI.getPostCrawl(postId)
            this.timeTableData = data.data.data
            const likeArr = []
            const commentArr = []
            const reachArr = []
            const savedArr = []
            const timeArr = []
            data.data.data.forEach((postCrawl) => {
                likeArr.push(postCrawl.like)
                commentArr.push(postCrawl.comment)
                reachArr.push(postCrawl.reach)
                savedArr.push(postCrawl.saved)
                timeArr.push(this.formartTime(postCrawl.time_crawl))
            })
            this.chartData.labels = timeArr
            this.chartData.datasets[0].data = likeArr
            this.chartData.datasets[1].data = commentArr
            this.chartData.datasets[2].data = reachArr
            this.chartData.datasets[3].data = savedArr
        },
        formartHashtag(hashtag) {
            return hashtag?.replaceAll(',', ' ')
        },
        getPost: async function (postId) {
            let post = await postAPI.getPost(postId)
            post.data.data.tag = this.formartTag(post.data.data.tag)
            post.data.data.hashtag = this.formartHashtag(post.data.data.hashtag)
            post.data.data.like = this.formatToInteger(post?.data?.data?.like)
            post.data.data.comment = this.formatToInteger(post?.data?.data?.comment)
            post.data.data.saved = this.formatToInteger(post?.data?.data?.saved)
            post.data.data.reach = this.formatToInteger(post?.data?.data?.reach)
            this.tableData = post.data
        },
        formartTime(time) {
            return time.split(' ')[1]
        },
        formartStatus: function (status) {
            if (status == 1) {
                return '通常'
            } else if (status == 0) {
                return 'リール'
            }
            return '下書き'
        },
        formatTime(date) {
            if (!date) {
                return
            }
            let listDate = date.slice(0, 10).split('-')
            let listTime = date.slice(11, 19)
            let dateFormat = listDate[0] + '年' + listDate[1] + '月' + listDate[2] + '日' + ' ' + listTime
            return dateFormat
        },
    },
}
</script>

<style lang="scss" scoped>
.showFullText {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

th {
    white-space: nowrap;
}

th,
td {
    min-width: 150px;
}

.paragraph-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
