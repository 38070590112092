<template>
 <!--content-->
    <div class="popup-instagram">
    <el-dialog
        width="25%"
        v-model="dialogVisible"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape = "false"
        >
        <h2 class="text-option text-center font-semibold">インスタグラムアカウントを連携してください</h2>
        <button class="mt-4 w-full">
            <div @click="handleLoginInsta" class="w-32 cursor-pointer mx-auto">
                <img src="@shared/uploads/ins-icon.png" />
            </div>
        </button>
    </el-dialog>
    </div>
    <PopupIns :data="businessAccount" v-model="isShow"  :user-id="userId" @onSubmitSuccess="$emit('onInstaSubmitSuccess')"/>

</template>

<script>
import Ins from "@/Services/Ins";
import PopupIns from "@/Components/PopupIns.vue";


export default {
    components: {
        PopupIns
    },
    data() {
        return {
            isShow: false,
            dialogVisible : true,
            businessAccount: [],
            userId: "",
        }
    },
    mounted() {
        this.getUserId();
    },
    methods: {
    handleLoginInsta() {
      FB.login(
        async (response) => {
          if (!response.authResponse) {
            return;
          }
          const {
            data: { data },
          } = await Ins.businessAccount(response.authResponse.accessToken);
          const checkAllAccountIns = await Ins.checkAllAccountIns(Ins.filterBusinessAccount(data));
          this.businessAccount = checkAllAccountIns;

          if(this.businessAccount.length == 0){
            this.checkPermissionLinkAccountIns()
          }else{
            this.isShow = true;
          }
          // FB.logout();
        },
        { scope: Ins.addPermission() }
      );
    },
    checkPermissionLinkAccountIns(){
      return this.$toast.error(this.$t("please_add_enough_permissions_for_the_linked_account"));
    },
    getUserId(){
        const userId = this.$page.props.auth.user.id;
        this.userId = userId;
      }
    },
}
</script>

<style>
.dialog-footer button:first-child {
  margin-right: 10px;
}
.popup-instagram .el-dialog__header{
    display: none;
}
.popup-instagram .el-overlay-dialog{
    display: flex;
    align-items: center;
    justify-content: center;
    left: 11.5%;
    top: 30%;
}
.popup-instagram .el-dialog{
    border-radius: 16px;
    margin-top:auto;
    margin-bottom:auto;
}

</style>
