import { formatToInteger } from '@/Plugins/numberFormat'
import { EMPTY_IMG } from '../Mixins/media.js'
import dayjs from 'dayjs'
import GetThumbnailUrl from '../../../Mixins/thumbnail.js'

export default {
  mixins: [GetThumbnailUrl],
  methods: {
    async genHashtagHourAnalyticIMGPage(doc) {
      doc.addPage()
      doc.setFontSize(10)
      doc.text('ベスト5' + this.mapLabelTop(this.detailHashtagLabel), 13, 40)
      for (
        let hashtagBestIndex = 0;
        hashtagBestIndex < this.dataElacticHashtag.hashtagBest.length;
        hashtagBestIndex++
      ) {
        let item = this.dataElacticHashtag.hashtagBest[hashtagBestIndex]
        doc.addImage(
          item.thumbnail_url
            ? String(await this.createFile(item.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)))
            : EMPTY_IMG,
          'jpeg',
          hashtagBestIndex * 37 + 13,
          45,
          33,
          30
        )
      }
      doc.text('ワースト5' + this.mapLabelTop(this.detailHashtagLabel), 13, 80)
      for (
        let hashtagWorstIndex = 0;
        hashtagWorstIndex < this.dataElacticHashtag.hashtagWorst.length;
        hashtagWorstIndex++
      ) {
        let item = this.dataElacticHashtag.hashtagWorst[hashtagWorstIndex]
        doc.addImage(
          item.thumbnail_url
            ? String(await this.createFile(item.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)))
            : EMPTY_IMG,
          'jpeg',
          hashtagWorstIndex * 37 + 13,
          85,
          33,
          30
        )
      }
      doc.text('最近の投稿', 13, 120)
      for (
        let hashtagRecentIndex = 0;
        hashtagRecentIndex < this.dataElacticHashtag.hashtagRecent.length;
        hashtagRecentIndex++
      ) {
        let item = this.dataElacticHashtag.hashtagRecent[hashtagRecentIndex]
        if (hashtagRecentIndex <= 4) {
          doc.addImage(
            item.thumbnail_url
              ? String(await this.createFile(item.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)))
              : EMPTY_IMG,
            'jpeg',
            hashtagRecentIndex * 37 + 13,
            125,
            33,
            30
          )
        } else if (hashtagRecentIndex > 4) {
          doc.addImage(
            item.thumbnail_url
              ? String(await this.createFile(item.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)))
              : EMPTY_IMG,
            'jpeg',
            (hashtagRecentIndex - 5) * 37 + 13,
            160,
            33,
            30
          )
        }
      }
      if (this.dataElacticHashtag.hashtagPopular.length > 0) {
        const hashtagPopularDate = dayjs(this.detailHashtagPopularDate).format('YYYY/MM/DD')
        let posLength = this.dataElacticHashtag.hashtagRecent.length
        let titleData = '人気投稿「' + hashtagPopularDate + '」'
        let likeText = '平均いいね数: ' + this.dataElacticHashtag.avgHashtagPopular.likeAvg
        let cmtText =
          '平均コメント数: ' +
          formatToInteger(this.dataElacticHashtag.avgHashtagPopular.commentAvg) +
          '.' +
          String(this.dataElacticHashtag.avgHashtagPopular.commentAvg).split('.')[1]
        let postText = '投稿数: ' + this.dataElacticHashtag.avgHashtagPopular.countPost
        doc.text(titleData, 13, posLength > 5 ? 195 : 160)
        doc.text(likeText, doc.getTextWidth(titleData) + 18, posLength > 5 ? 195 : 160)
        doc.text(cmtText, doc.getTextWidth(titleData) + doc.getTextWidth(likeText) + 23, posLength > 5 ? 195 : 160)
        doc.text(
          postText,
          doc.getTextWidth(titleData) + doc.getTextWidth(likeText) + doc.getTextWidth(cmtText) + 28,
          posLength > 5 ? 195 : 160
        )
        for (
          let hashtagPopularIndex = 0;
          hashtagPopularIndex < this.dataElacticHashtag.hashtagPopular.length;
          hashtagPopularIndex++
        ) {
          let item = this.dataElacticHashtag.hashtagPopular[hashtagPopularIndex]
          if (hashtagPopularIndex <= 4) {
            doc.addImage(
              item.thumbnail_url
                ? String(await this.createFile(item.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)))
                : EMPTY_IMG,
              'jpeg',
              hashtagPopularIndex * 37 + 13,
              posLength > 5 ? 200 : 165,
              33,
              30
            )
          } else if (hashtagPopularIndex > 4) {
            doc.addImage(
              item.thumbnail_url
                ? String(await this.createFile(item.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)))
                : EMPTY_IMG,
              'jpeg',
              (hashtagPopularIndex - 5) * 37 + 13,
              posLength > 5 ? 235 : 200,
              33,
              30
            )
          }
        }
      }
    },
    mapHashtagDetailTableData(data) {
      let arrData = []
      data.forEach((item) => {
        let mapData = []
        mapData.push(item?.key)
        mapData.push(formatToInteger(item?.doc_count))
        mapData.push(formatToInteger(item?.likes_count?.value.toFixed(2)))
        mapData.push(formatToInteger(item?.comments_count?.value.toFixed(2)))
        arrData.push(mapData)
      })

      return arrData
    },
    mapLabelTop(item) {
      switch (item) {
        case 'likes':
          return '「いいね数」'

        default:
          return '「コメント数」'
      }
    },
  },
}
