<template>
  <h3 class="text-option font-semibold !text-2sm pl-4 pt-4">フォロワーの地域</h3>
  <Bar
    v-if="showData"
    class="mx-auto p-4"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :plugins="plugins"
    :id="canvasId"
  />
  <NoData v-else></NoData>
  <div v-if="showData" class="loadmore p-2 flex items-center justify-end">
    <p class="text-button_pink text-2sm font-bold cursor-pointer" @click="toggleModal()" >もっとみる</p>
  </div>
  <!-- modal -->
  <div
    v-if="showModal"
    @click="showModal = false"
    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative w-auto my-6 mx-auto max-w-6xl model-chart">
      <!--content-->
      <div class="border-0 shadow-lg rounded-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
           style="top:10px">
        <!--header-->
        <div class="flex items-start justify-between p-5 border-b border-solid border-slate-200">
          <h3 class="text-title text-option font-bold">フォロワーの地域</h3>
          <button v-on:click="toggleModal()">
              <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <!--body-->
        <div class="overflow-y-auto" style="height: 65vh">
          <horizon-bar-chat-all :accountInsights="accountInsights"></horizon-bar-chat-all>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  <!-- modal -->
</template>

<script>
import { Bar } from "vue-chartjs";
import HorizonBarChatAll from "./AllData.vue";
import * as accountAPI from "@/Api/Account";
import NoData from "../NoData";
export default {
  name: "ChartByAddress",
  components: {
    Bar,
    HorizonBarChatAll,
    NoData,
  },
  props: {
    range: {
      type: Object,
      default: 0,
    },
    chartId: {
      type: String,
      default: "bar-chart",
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    accountInsights: {
      type: Array,
      default: [],
    },
    canvasId: { type: String},
  },
  data() {
    return {
      listCountry: [],
      showModal: false,
      showData: true,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "フォロワー",
            backgroundColor: "#0097D3",
            data: [],
          },
        ],
      },
      chartOptions: {
        maintainAspectRatio: false,
        indexAxis: "y",
        plugins: {
          tooltip: {
            xAlign: {
              label: function (context) {
                context.dataset.label.xAlign = "left";

                return context.dataset.label.xAlign;
              },
            },
          },
          legend: {
            position: "top",
            align: "start",
            title: {
              display: true,
            },
          },
          datalabels: {
            display: false,
          },
        },
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        scales: {
          x: {
            grid: {
              borderColor: "transparent",
            },
          },
          y: {
            min: 0,
            max: 6,
            grid: {
              display: false,
              borderColor: "transparent",
            },
          },
        },
      },
      chartOptionsAll: {
        maintainAspectRatio: false,
        indexAxis: "y",
        plugins: {
          tooltip: {
            xAlign: {
              label: function (context) {
                context.dataset.label.xAlign = "left";

                return context.dataset.label.xAlign;
              },
            },
          },
          legend: {
            position: "top",
            align: "start",
            title: {
              display: true,
            },
          },
          datalabels: {
            display: false,
          },
        },
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        scales: {
          x: {
            grid: {
              borderColor: "transparent",
            },
          },
          y: {
            grid: {
              display: false,
              borderColor: "transparent",
            },
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
          },
        },
      },
    };
  },
  watch: {
    accountInsights: function () {
      this.getData();
    },
  },
  methods: {
    async getData() {
      if (this.accountInsights.length > 0) {
        this.prepareDataForChart();
      } else {
          this.showData = false
          this.chartData.labels = [];
          this.chartData.datasets[0].data = [];
      }
    },
    prepareDataForChart() {
      this.listCountry = [];
      this.accountInsights.forEach((accountInsight) => {
        if (accountInsight.audience_city) {
          this.listCountry.push(accountInsight.audience_city);
        }
      });

      this.showData = this.listCountry.length > 0

      let totalDataCountry = {};
      this.listCountry.forEach((country) => {
        const dataCountry = JSON.parse(country);

        for (const [index, item] of Object.entries(dataCountry)) {
          if (totalDataCountry.hasOwnProperty(index)) {
            totalDataCountry[index] += item;
          } else {
            totalDataCountry[index] = item;
          }
        }
      });

      for (const [key, item] of Object.entries(totalDataCountry)) {
        if (!this.chartData.labels.includes(key)) {
          this.chartData.labels.push(key);
        }
      }

      for (const city of this.chartData.labels) {
        this.chartData.datasets[0].data = [
          ...this.chartData.datasets[0]?.data,
          totalDataCountry[city],
        ];
      }

        this.chartData.labels = this.chartData.labels.map((label) => {
            return this.$t(label);
        })
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      this.chartOptions.scales.y.max = 89;
    },
  },
};
</script>

<style scoped>
.model-chart
{
    top:10%;
}
</style>
