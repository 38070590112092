<template>
  <div class="tabs" :class="{ '!mt-0 ml-10': !isExport, '!mt-0 ml-5 md:ml-20': isExport }">
    <ul class="flex">
      <li
        class="px-2 relative pb-5"
        :class="{ active: currentTabPostCategory == 'normal' }"
        @click="currentTabPostCategory = 'normal'"
      >
        通常投稿
      </li>
      <li
        v-if="isShowReel"
        class="px-2 relative pb-5 ml-3"
        :class="{ active: currentTabPostCategory == 'reel' }"
        @click="currentTabPostCategory = 'reel'"
      >
        リール投稿
      </li>
    </ul>
  </div>
  <div class="bg-background pb-10" v-loading="loading">
    <div class="statistic" :class="{ 'px-10': !isExport, 'px-5 md:px-20': isExport }">
      <div class="flex justify-between items-center">
        <p class="text-button_pink font-semibold py-5 text-3sm">ハッシュタグ分析</p>
        <button
          v-if="isRole == 'master'"
          :disabled="numberExportHashtag <= 0 && isRole !== 'master'"
          @click="exportHashtagCsv"
          class="bg-black py-3 px-7 text-white !text-[14px] button-shadow rounded"
        >
          CSV出力
        </button>
        <el-tooltip
          v-else
          class="tooltip-customer"
          effect="dark"
          :content="'ハッシュタグ分析画面今月後 ' + numberExportHashtag + ' 回CSV出力出来ます。'"
          placement="top"
        >
          <button
            :disabled="numberExportHashtag <= 0 && isRole !== 'master'"
            @click="exportHashtagCsv"
            class="bg-black py-3 px-7 text-white !text-[14px] button-shadow rounded"
          >
            CSV出力
          </button>
        </el-tooltip>
      </div>
      <div class="section-ranking">
        <el-row>
          <el-col :md="8">
            <div class="p-3 md:mr-5 bg-white border border-gray-300 rounded mb-5">
              <div class="my-2 text-center text-gray-600 text-3sm font-semibold">
                {{ currentTabPostCategory == 'normal' ? 'インプレッション数TOP5' : 'コメント数TOP5' }}
              </div>
              <el-table
                :data="currentTabPostCategory == 'normal' ? rankings.impressions : rankings.comments"
                header-row-class-name="padding-0"
                style="width: 100%"
              >
                <el-table-column
                  prop="ranking"
                  label="順位"
                  label-class-name="bg-gray-100"
                  width="100"
                  align="left"
                  type="index"
                  :index="indexMethod"
                />

                <el-table-column prop="name" label="ハッシュタグ名" label-class-name=" bg-gray-100 text-center">
                  <template #default="scope">
                    <el-link
                      :href="`${URL_INSTAGRAM}/${scope?.row?.name?.replace('#', '')}`"
                      class="text-pink !text-[13px]"
                      target="_blank"
                    >
                      <p>{{ scope?.row?.name }}</p>
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="sumImpressions"
                  :label="currentTabPostCategory == 'normal' ? 'インプ' : 'コメント数'"
                  label-class-name=" bg-gray-100"
                  align="right"
                  width="100"
                >
                  <template #default="scope">
                    <p v-if="currentTabPostCategory == 'normal'">{{ formatToInteger(scope?.row?.sumImpressions) }}</p>
                    <p v-else>{{ formatToInteger(scope?.row?.sumComments) }}</p>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
          <el-col :md="8">
            <div class="p-3 md:mr-5 bg-white border border-gray-300 rounded mb-5">
              <div class="my-2 text-center text-gray-600 text-3sm font-semibold">いいね数TOP5</div>
              <el-table :data="rankings.likes" header-row-class-name="padding-0" style="width: 100%">
                <el-table-column
                  prop="ranking"
                  label="順位"
                  label-class-name=" bg-gray-100"
                  width="100"
                  align="left"
                  type="index"
                  :index="indexMethod"
                />
                <el-table-column prop="name" label="ハッシュタグ名" label-class-name=" bg-gray-100 text-center">
                  <template #default="scope">
                    <el-link
                      :href="`${URL_INSTAGRAM}/${scope?.row?.name?.replace('#', '')}`"
                      class="text-pink !text-[13px]"
                      target="_blank"
                    >
                      <p>{{ scope?.row?.name }}</p>
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="sumLikes"
                  label="いいね数"
                  label-class-name=" bg-gray-100"
                  align="right"
                  width="100"
                >
                  <template #default="scope">
                    <p>{{ formatToInteger(scope?.row?.sumLikes) }}</p>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
          <el-col :md="8">
            <div
              class="p-3 bg-white border border-gray-300 rounded mb-5"
              :class="{ 'mr-5': !isExport, 'mr-0': isEport }"
            >
              <div class="my-2 text-center text-gray-600 text-3sm font-semibold">使用回数TOP５</div>
              <el-table :data="rankings.posts" header-row-class-name="padding-0" style="width: 100%">
                <el-table-column
                  prop="ranking"
                  label="順位"
                  label-class-name=" bg-gray-100"
                  width="100"
                  align="left"
                  type="index"
                  :index="indexMethod"
                />
                <el-table-column prop="name" label="ハッシュタグ名" label-class-name=" bg-gray-100 text-center">
                  <template #default="scope">
                    <el-link
                      :href="`${URL_INSTAGRAM}/${scope?.row?.name?.replace('#', '')}`"
                      class="text-pink !text-[13px]"
                      target="_blank"
                    >
                      <p>{{ scope?.row?.name }}</p>
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="sumPost"
                  label-class-name=" bg-gray-100"
                  label="使用回数"
                  align="right"
                  width="100"
                >
                  <template #default="scope">
                    <p>{{ formatToInteger(scope?.row?.sumPost) }}</p>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="menu flex items-center mb-5 justify-between">
        <div class="flex relative flex-1">
          <el-input
            type="primary"
            v-model="filter.name"
            placeholder="検索キーワードを入力してください"
            class="mr-5 md:!w-96"
            size="large"
            @keyup.enter="fetchData"
            clearable
          />
          <el-button class="el-button__primary md:w-20" @click="onSearch" size="large">
            <span class="text-white">検索</span>
          </el-button>
        </div>
      </div>
      <!-- datatable lis hash tag -->
      <div class="bg-white p-2">
        <data-table
          :fields="fields_list"
          :items="hashtags.data"
          :paginate="hashtags.meta"
          :current-page="filter.page || 1"
          disable-table-info
          footer-center
          paginate-background
          @page="handleCurrentPage"
          class="el-table-header__black"
          v-if="!loading"
        >
          <template #hashtag_name="{ row }">
            <el-link
              :href="`${URL_INSTAGRAM}/${row?.hashtag_name?.replace('#', '')}`"
              class="text-pink !text-[13px]"
              target="_blank"
            >
              <p>{{ row?.hashtag_name }}</p>
            </el-link>
          </template>
          <template #hashtag_using="{ row }">
            <p>{{ formatToInteger(row?.totalCount) }}</p>
          </template>
          <template #hashtag_likes="{ row }">
            <p>{{ formatToInteger(row?.hashtag_likes) }}</p>
          </template>
          <template #hashtag_reachs="{ row }">
            <p>{{ formatToInteger(row?.hashtag_reachs) }}</p>
          </template>
          <template #hashtag_comments="{ row }">
            <p>{{ formatToInteger(row?.hashtag_comments) }}</p>
          </template>
          <template #hashtag_saves="{ row }">
            <p>{{ formatToInteger(row?.hashtag_saves) }}</p>
          </template>
          <template #hashtag_impressions="{ row }">
            <p>{{ row?.hashtag_impressions ? formatToInteger(row?.hashtag_impressions) : '0' }}</p>
          </template>
          <template #engagement_rate="{ row }">
            <p>{{ formatToFloat(row?.engagement_rate + '%') }}</p>
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { Link } from '@inertiajs/inertia-vue3'
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import DataTable from '@/Components/UI/DataTable.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import { fetchNumberExport, addNumberExport } from './Mixins/limitExport.js'
import { Inertia } from '@inertiajs/inertia'

const FIELDS = [
  {
    key: 'hashtag_name',
    label: 'ハッシュタグ',
    minWidth: 300,
    fixed: window.innerWidth > 600 ? 'left' : false,
    align: 'center',
  },
  { key: 'hashtag_using', label: '使用回数', width: 170, align: 'center' },
  { key: 'hashtag_likes', label: 'いいね数', width: 170, align: 'center' },
  { key: 'hashtag_reachs', label: 'リーチ数', width: 170, align: 'center' },
  { key: 'hashtag_comments', label: 'コメント数', width: 170, align: 'center' },
  { key: 'hashtag_saves', label: '保存数', width: 170, align: 'center' },
  { key: 'hashtag_impressions', label: 'インプレッション数', width: 170, align: 'center' },
  { key: 'engagement_rate', label: 'エンゲージメント率', width: 170, align: 'center' },
  { key: 'engagement', label: 'エンゲージメント数', width: 170, align: 'center' },
]

export default {
  components: { DrashBoard, UserNav, DataTable, Link, BreadCrumb, LinkInstagram },
  emits: [
    'getPostHashtagTableData',
    'getReelHashtagTableData',
    'getPostRankingTableData',
    'getReelRankingTableData',
    'getHashtagCurrTab',
  ],
  props: {
    plan: { type: Object, required: true },
  },
  computed: {
    isShowReel() {
      if (this.$page.props?.auth?.user?.roles[0]?.name === 'master') {
        return true
      }
      return this?.$page?.props?.auth?.plan?.reel_analysis == 1
    },
    isRole() {
      return this.$page.props?.auth?.user?.roles[0]?.name
    },
    isExport() {
      return Inertia?.page?.url === '/export/report-preview'
    },
  },

  data() {
    return {
      loading: false,
      hashtags: {},
      rankings: [],
      rankingsPost: [],
      rankingsReel: [],
      showLinkInstagram: true,
      numberExportHashtag: 0,
      filter: {
        page: 1,
        name: null,
        limit: 10,
      },
      fields_list: [FIELDS],
      URL_INSTAGRAM: 'https://www.instagram.com/explore/tags',
      currentTabPostCategory: 'normal',
    }
  },

  async created() {
    this.loading = true
    this.fields_list = FIELDS.filter((v) => v.key !== 'engagement')
    await this.getRankings()
    await this.fetchData()
    await this.fetchDataPostForPDF()
    await this.fetchDataReelForPDF()
    await this.getRankingsPost()
    await this.getRankingsReel()
    await this.fetchNumberExportMonth()
    this.loading = false
  },

  watch: {
    'filter.name': {
      handler() {
        if (!this.filter.name) {
          this.fetchData()
        }
      },
      deep: true,
    },
    currentTabPostCategory: {
      handler() {
        this.loading = true
        this.filter.page = 1

        if (this.currentTabPostCategory) {
          this.filter.name = ''
        }

        if (this.currentTabPostCategory == 'normal') {
          this.fields_list = FIELDS.filter((v) => v.key != 'engagement')
        } else {
          this.fields_list = FIELDS.filter((v) => v.key != 'engagement_rate' && v.key != 'hashtag_impressions')
        }
        this.fetchData()
        this.getRankings()
        this.loading = false
      },
    },
  },

  methods: {
    onInstaSubmitSuccess() {
      this.showLinkInstagram = true
    },
    // fetch data
    async fetchData() {
      const postCategory = this.currentTabPostCategory
      this.$emit('getHashtagCurrTab', postCategory)
      await axios
        .get(
          route('api.account.get-hashtag', {
            filter: { name: this.filter?.name },
            limit: this.filter?.limit,
            page: this.filter?.page,
            post_category: postCategory,
          })
        )
        .then((res) => {
          this.hashtags = res?.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchDataPostForPDF() {
      await axios
        .get(
          route('api.account.get-hashtag', {
            limit: 55,
            post_category: 'normal',
          })
        )
        .then((res) => {
          let tableHashtagData = res?.data.data.map((item) => {
            return [
              item.name.replace(/#+/g, '#'),
              this.formatToInteger(item.totalCount),
              this.formatToInteger(item.hashtag_likes),
              this.formatToInteger(item.hashtag_reachs),
              this.formatToInteger(item.hashtag_comments),
              this.formatToInteger(item.hashtag_saves),
              item.hashtag_impressions ? this.formatToInteger(item.hashtag_impressions) : '0',
              this.formatToFloat(item?.engagement_rate + '%'),
            ]
          })
          this.$emit('getPostHashtagTableData', tableHashtagData)
          return
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchDataReelForPDF() {
      await axios
        .get(
          route('api.account.get-hashtag', {
            limit: 55,
            post_category: 'reel',
          })
        )
        .then((res) => {
          let tableHashtagData = res?.data.data.map((item) => {
            return [
              item.name.replace(/#+/g, '#'),
              this.formatToInteger(item.postInsights.length),
              this.formatToInteger(item.hashtag_likes),
              this.formatToInteger(item.hashtag_reachs),
              this.formatToInteger(item.hashtag_comments),
              this.formatToInteger(item.hashtag_saves),
              this.formatToFloat(item.hashtag_likes + item.hashtag_comments),
            ]
          })
          this.$emit('getReelHashtagTableData', tableHashtagData)
          return
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getRankings() {
      const postCategory = this.currentTabPostCategory
      await axios.get(route('api.account.get-rankings', { post_category: postCategory })).then((res) => {
        this.rankings = res?.data
      })
    },
    async getRankingsPost() {
      await axios.get(route('api.account.get-rankings', { post_category: 'normal' })).then((res) => {
        this.rankingsPost = res?.data
        this.$emit('getPostRankingTableData', this.rankingsPost)
      })
    },
    async getRankingsReel() {
      await axios.get(route('api.account.get-rankings', { post_category: 'reel' })).then((res) => {
        this.rankingsReel = res?.data
        this.$emit('getReelRankingTableData', this.rankingsReel)
      })
    },
    indexMethod(index) {
      return index + 1 + '位'
    },
    onSearch() {
      this.filter.page = 1
      this.fetchData()
    },

    handleCurrentPage(value) {
      this.filter.page = value
      this.fetchData()
    },
    //Export Csv HashTag
    async exportHashtagCsv() {
      if (this.numberExportHashtag === 0) {
        return
      }
      let checkSuccess = false
      const userId = this.$page.props.auth.user.id
      const url =
        route('api.export.hashtag.csv') +
        '?user_id=' +
        userId +
        '&limit=' +
        this.filter.limit +
        '&page=' +
        this.filter.page +
        '&category=' +
        this.currentTabPostCategory

      window.location.href = url

      checkSuccess = true

      if (checkSuccess && this.isRole !== 'master') {
        addNumberExport('csv', 'csv_hashtag')
        this.fetchNumberExportMonth()
      }
    },
    // get number export per month
    async fetchNumberExportMonth() {
      const { data } = await fetchNumberExport('csv', 'csv_hashtag')
      this.numberExportHashtag = this.plan?.number_report_csv - data
    },
  },
}
</script>

<style lang="scss" scoped>
.el-button__primary {
  background-color: #5264cd;
  border: 1px solid #5264cd;
}

.el-button__primary span {
  color: white !important;
}

.text-pink {
  &:hover,
  p {
    color: #be3d8f !important;
    text-decoration: underline;
  }
}

.el-link.is-underline:hover:after {
  border-bottom: none !important;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 0 !important;
}
</style>

<style>
.section-ranking .el-table__inner-wrapper::before {
  background-color: white !important;
}
</style>
