// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../../uploads/iPhone-screen.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title img[data-v-82c45fc6],\nlabel img[data-v-82c45fc6] {\n  margin-right: 6px;\n}\n.title[data-v-82c45fc6] {\n  margin-top: 23px;\n}\n.form-wrapper_2col[data-v-82c45fc6] {\n  grid-template-columns: 15% auto;\n}\n.form-wrapper_3col[data-v-82c45fc6] {\n  grid-template-columns: max-content max-content auto;\n}\n.grid-4-col[data-v-82c45fc6] {\n  grid-template-columns: max-content auto max-content auto;\n}\n.bg-ip[data-v-82c45fc6] {\n  width: 283px;\n  height: 571px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.25));\n}", "",{"version":3,"sources":["webpack://./resources/js/Pages/Report/Posts/Advertisment/Index.vue"],"names":[],"mappings":"AACA;;EAEE,iBAAA;AAAF;AAEA;EACE,gBAAA;AACF;AAEA;EACE,+BAAA;AACF;AACA;EACE,mDAAA;AAEF;AAAA;EACE,wDAAA;AAGF;AAAA;EACE,YAAA;EACA,aAAA;EACA,yDAAA;EACA,qDAAA;AAGF","sourcesContent":["\n.title img,\nlabel img {\n  margin-right: 6px;\n}\n.title {\n  margin-top: 23px;\n}\n\n.form-wrapper_2col {\n  grid-template-columns: 15% auto;\n}\n.form-wrapper_3col {\n  grid-template-columns: max-content max-content auto;\n}\n.grid-4-col {\n  grid-template-columns: max-content auto max-content auto;\n}\n\n.bg-ip {\n  width: 283px;\n  height: 571px;\n  background-image: url('@shared/uploads/iPhone-screen.svg');\n  filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.25));\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
