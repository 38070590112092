import { EMPTY_IMG } from '../Mixins/media.js'

export default {
  methods: {
    genSlide28Enterprise(pptx, dataAccount, text) {
      let slide28 = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slide28.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })

      let selectedMonth = dataAccount.selectedMonth
      let lastMonthAccountInsight = dataAccount.lastMonth.accountInsight

      slide28.addText('アカウント別 エンゲージメント', {
        x: 2.45,
        y: 0.25,
        w: 6.05,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 22,
      })

      slide28.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.45,
        y: 1.42,
        w: 2.71,
        h: 4.37,
        rectRadius: 0.1,
        fill: { color: 'f8f8f8' },
      })

      slide28.addText('基本アクセス情報', {
        x: 0.5,
        y: 1.6,
        w: 1.61,
        h: 0.29,
        align: 'left',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 10,
      })

      slide28.addText('合計アカウントリーチ数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 0.77,
        y: 2.21,
        w: 1.98,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 10,
      })

      slide28.addText(String(selectedMonth?.accountInsight?.sumReach), {
        x: 0.27,
        y: 2.58,
        w: 3.0,
        h: 0.41,
        align: 'center',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 19,
      })
      let compareMonthSumReach =
        selectedMonth?.accountInsight?.sumReach > lastMonthAccountInsight?.sumReach
          ? '(+' + String(selectedMonth?.accountInsight?.sumReach - lastMonthAccountInsight?.sumReach) + ')'
          : '(' + String(selectedMonth?.accountInsight?.sumReach - lastMonthAccountInsight?.sumReach) + ')'

      slide28.addText(compareMonthSumReach, {
        x: 0.8,
        y: 2.9,
        w: 1.96,
        h: 0.26,
        align: 'center',
        valign: 'top',
        color: selectedMonth?.accountInsight?.sumReach - lastMonthAccountInsight?.sumReach >= 0 ? '1f79f2' : 'DC3545',
        fontSize: 10,
      })

      slide28.addText('合計プロフィールアクセス数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 0.77,
        y: 3.26,
        w: 2.08,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 10,
      })

      slide28.addText(String(selectedMonth?.accountInsight?.sumProfileView), {
        x: 0.27,
        y: 3.69,
        w: 3.0,
        h: 0.41,
        align: 'center',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 19,
      })

      let compareMonthProfileView =
        selectedMonth?.accountInsight?.sumProfileView > lastMonthAccountInsight?.sumProfileView
          ? '(+' + String(selectedMonth?.accountInsight?.sumProfileView - lastMonthAccountInsight?.sumProfileView) + ')'
          : '(' + String(selectedMonth?.accountInsight?.sumProfileView - lastMonthAccountInsight?.sumProfileView) + ')'

      slide28.addText(String(compareMonthProfileView), {
        x: 0.8,
        y: 4.01,
        w: 1.96,
        h: 0.26,
        align: 'center',
        valign: 'top',
        color:
          selectedMonth?.accountInsight?.sumProfileView - lastMonthAccountInsight?.sumProfileView >= 0
            ? '1f79f2'
            : 'DC3545',
        fontSize: 10,
      })

      slide28.addText('合計行動数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 0.77,
        y: 4.39,
        w: 1.98,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 10,
      })

      slide28.addText(String(selectedMonth?.accountInsight?.sumReach + selectedMonth?.accountInsight?.sumProfileView), {
        x: 0.27,
        y: 4.9,
        w: 3.0,
        h: 0.41,
        align: 'center',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 19,
      })

      let compareMonthReach =
        selectedMonth?.accountInsight?.sumReach - lastMonthAccountInsight?.sumReach >
        selectedMonth?.accountInsight?.sumProfileView - lastMonthAccountInsight?.sumProfileView
          ? '(+' +
            String(
              selectedMonth?.accountInsight?.sumReach -
                lastMonthAccountInsight?.sumReach +
                (selectedMonth?.accountInsight?.sumProfileView - lastMonthAccountInsight?.sumProfileView)
            ) +
            ')'
          : '(' +
            String(
              selectedMonth?.accountInsight?.sumReach -
                lastMonthAccountInsight?.sumReach +
                (selectedMonth?.accountInsight?.sumProfileView - lastMonthAccountInsight?.sumProfileView)
            ) +
            ')'

      slide28.addText(String(compareMonthReach), {
        x: 0.8,
        y: 5.22,
        w: 1.96,
        h: 0.26,
        align: 'center',
        valign: 'top',
        color:
          selectedMonth?.accountInsight?.sumReach -
            lastMonthAccountInsight?.sumReach +
            (selectedMonth?.accountInsight?.sumProfileView - lastMonthAccountInsight?.sumProfileView) >=
          0
            ? '1f79f2'
            : 'DC3545',
        fontSize: 10,
      })
      if (
        selectedMonth.postInsight.checkLike &&
        selectedMonth.postInsight.checkComment &&
        selectedMonth.postInsight.checkEngagement
      ) {
        slide28.addImage({ path: EMPTY_IMG, x: 5.14, y: 1.8, w: 3.56, h: 3.56 })
      } else {
        // chart
        let labels = selectedMonth?.postInsight?.accountNameIns
        let arrDataHighVals = [
          {
            name: 'いいね数',
            labels: labels,
            values: selectedMonth?.postInsight?.likes,
          },
          {
            name: 'コメント数',
            labels: labels,
            values: selectedMonth?.postInsight?.comments,
          },
          {
            name: 'エンゲージメント率',
            labels: labels,
            values: selectedMonth?.postInsight?.engagement,
          },
        ]
        let optsChartBar4 = {
          x: 3.48,
          y: 1.35,
          w: 7.1,
          h: 4.37,
          barDir: 'col',
          barGrouping: 'clustered',
          barGapWidthPct: 50,
          chartColors: ['BFBFBF', 'E7289E', '032060'],
          valAxisMinVal: 0,
          // valAxisMaxVal: 120,
          catAxisLabelRotate: -45,

          catAxisLabelColor: '787878',
          catAxisLabelFontSize: 12,
          catAxisLabelFrequency: 1,
          catAxisOrientation: 'minMax',

          showLegend: true,
          legendPos: 'b',
          legendFontSize: 12,
          legendColor: '787878',

          showTitle: true,
          title: 'アカウント別エンゲージメント',
          titleColor: '787878',
          titleFontSize: 18.6,
        }
        slide28.addChart(pptx.charts.BAR, arrDataHighVals, optsChartBar4)
      }
      // end chart

      slide28.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.29,
        w: 10.27,
        h: 1.56,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slide28
    },
  },
}
