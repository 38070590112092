<template>
  <DrashBoard>
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li>
          <Link :href="route('admin.advertise.list')" class="font-normal">広告分析</Link>
        </li>
        <span class="font-normal text-notice_date mx-2">></span>
        <li>
          <Link :href="'/advertise-analysis/detail/' + idPost" class="font-normal breakcumb-active">広告初速分析</Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="mt-12 ml-10 tabs">
        <ul class="flex">
          <li class="px-6 relative pb-5 active">広告初速分析</li>
        </ul>
      </div>
    </template>
    <div class="bg-background">
      <div class="md:px-10 sm:px-5">
        <h2 class="text-title text-button_pink font-semibold py-5">広告初速分析</h2>

        <div class="mt-7">
          <data-table
            :fields="fields"
            :items="basic_infos"
            disable-table-info
            footer-center
            paginate-background
            class="el-table-header__black"
          >
            <template #url_file="{ row }">
              <template v-if="row?.type == 'carousel'">
                <video v-if="isVideoType(row?.url_file[0])" controls width="112" style="height: 64px">
                  <source :src="row?.url_file[0]" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <img v-else class="w-28 h-16" :src="row?.url_file[0]" alt="" />
              </template>
              <template v-else>
                <video v-if="isVideoType(row?.url_file)" controls width="112" style="height: 64px">
                  <source :src="row?.url_file" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <img v-else class="w-28 h-16" :src="row?.url_file" alt="" />
              </template>
            </template>

            <template #message_ads="{ row }">
              <p class="whitespace-pre-line limit-text">{{ row?.message_ads }}</p>
              <p
                v-if="row"
                @click="viewDetailContent(row)"
                class="text-xs text-button_profile font-semibold text-right block mt-2 hover:cursor-pointer"
              >
                全文を見る
              </p>
            </template>

            <template #ads_set_start_time="{ row }">
              <p>{{ formatTimeInt(row?.ads_set_start_time) }}</p>
            </template>

            <template #ads_set_end_time="{ row }">
              <p>{{ formatTimeInt(row?.ads_set_end_time) }}</p>
            </template>

            <template #clicks="{ row }">
              <p>{{ formatToInteger(row?.ad_insight?.clicks) }}</p>
            </template>

            <template #type="{ row }">
              <p>{{ mapType(row?.type) }}</p>
            </template>

            <template #impressions="{ row }">
              <p>{{ formatToInteger(row?.ad_insight?.impressions) }}</p>
            </template>

            <template #reach="{ row }">
              <p>{{ formatToInteger(row?.ad_insight?.reach) }}</p>
            </template>

            <template #frequency="{ row }">
              <p>{{ formatToFloat(row?.ad_insight?.frequency) }}</p>
            </template>

            <template #spend="{ row }">
              <p>{{ formatToInteger(row?.ad_insight?.spend) }}</p>
            </template>
          </data-table>

          <!-- dialog detail content -->
          <el-dialog v-model="dialogShowPost" width="50%" title="投稿内容" append-to-body>
            <PopupReviewPost
              :content="postSelected.message_ads"
              :file="postSelected?.type == 'carousel' ? postSelected.url_file[0] : postSelected.url_file"
              :fileType="
                getTypeFile(postSelected?.type == 'carousel' ? postSelected.url_file[0] : postSelected.url_file)
              "
              :carouselItems="postSelected?.type == 'carousel' ? postSelected.url_file : []"
              :hashtag="postSelected.hashtag"
              :tag="postSelected.tag"
              :username="postAuthor"
            />
          </el-dialog>
          <!-- end dialog detail content -->
        </div>

        <LineChart
          class="bg-white md:py-10 md:px-10 border-2 mt-10"
          :width="500"
          :height="150"
          :isShowChart="isShowChart"
          :chartData="chartData"
        />

        <div class="my-5">
          <data-table
            :fields="fieldsHourly"
            :items="dataHours"
            disable-table-info
            footer-center
            paginate-background
            class="el-table-header__black el-table-nowrap"
          >
            <template #created_at="{ row }">
              <p>{{ formatHour(row?.created_at) }}</p>
            </template>
          </data-table>
        </div>
      </div>
    </div>
  </DrashBoard>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import LineChart from './LineChart.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import Table from '@/Components/Table.vue'
import { Link } from '@inertiajs/inertia-vue3'
import PopupReviewPost from '@/Components/PopupReviewPost.vue'
import { Back } from '@element-plus/icons-vue'
import DataTable from '@/Components/UI/DataTable.vue'
import { getUserDetail } from '@/Api/Users'
export default {
  name: 'AnalysisDetail',
  components: {
    Table,
    DrashBoard,
    BreadCrumb,
    LineChart,
    PopupReviewPost,
    Link,
    Back,
    DataTable,
  },
  props: {
    fbAdsInsight: {
      type: Object,
      default: {},
    },
    fbAdsHourlys: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      openDialog: false,
      fields: [
        { key: 'url_file', label: '投稿画像', width: 150, align: 'center' },
        { key: 'type', label: 'タイプ', width: 100, align: 'center' },
        { key: 'message_ads', label: '投稿内容', width: 400, align: 'center' },
        { key: 'ads_set_start_time', label: '開始日時', width: 200, align: 'center' },
        { key: 'ads_set_end_time', label: '終了日時', width: 200, align: 'center' },
        { key: 'clicks', label: 'Webサイトクリック数', width: 200, align: 'center' },
        { key: 'impressions', label: 'インプレッション数', width: 200, align: 'center' },
        { key: 'reach', label: 'リーチ数', width: 150, align: 'center' },
        { key: 'frequency', label: 'フリークエンシー数', width: 150, align: 'center' },
        { key: 'spend', label: '消化金額数', width: 150, align: 'center' },
      ],
      fieldsHourly: [
        { key: 'created_at', label: '日時', align: 'center' },
        { key: 'clicks', label: 'Webサイトクリック数', width: 250, align: 'center' },
        { key: 'impressions', label: 'インプレッション数', width: 250, align: 'center' },
        { key: 'reach', label: 'リーチ数', width: 250, align: 'center' },
        { key: 'frequency', label: 'フリークエンシー数', width: 250, align: 'center' },
        { key: 'spend', label: '消化金額数', width: 250, align: 'center' },
      ],
      basic_infos: [],
      dataHours: [],
      isShowChart: false,
      chartData: {
        labels: [],
        datasets: [
          { label: 'Webサイトクリック数', data: [], borderColor: '#868686', fill: false },
          { label: 'インプレッション数', data: [], borderColor: 'red', fill: false },
          { label: 'リーチ数', data: [], borderColor: 'black', fill: false },
          { label: 'フリークエンシー数', data: [], borderColor: 'green', fill: false },
          { label: '消化金額数', data: [], borderColor: 'pink', fill: false },
        ],
      },
      idPost: 1,
      dialogShowPost: false,
      postSelected: {},
      postAuthor: {},
    }
  },
  async created() {
    let url = new URL(window.location.href)
    let searchParams = new URLSearchParams(url.search)
    this.idPost = searchParams.get('id')
    this.prepareData()
  },
  methods: {
    prepareData() {
      this.isShowChart = true
      this.basic_infos.push(this.fbAdsInsight)
      this.dataHours = this.fbAdsHourlys

      const clickArr = []
      const impressionArr = []
      const reachArr = []
      const frequencyArr = []
      const spendArr = []
      const labelArr = []
      this.dataHours.forEach((item) => {
        clickArr.push(item.clicks)
        impressionArr.push(item.impressions)
        reachArr.push(item.reach)
        frequencyArr.push(item.frequency.toFixed(2))
        spendArr.push(item.spend)
        labelArr.push(this.formatHour(item.created_at))
      })

      this.chartData.labels = labelArr
      this.chartData.datasets[0].data = clickArr
      this.chartData.datasets[1].data = impressionArr
      this.chartData.datasets[2].data = reachArr
      this.chartData.datasets[3].data = frequencyArr
      this.chartData.datasets[4].data = spendArr
    },

    mapType(type) {
      let text = ''
      switch (type) {
        case 'carousel':
          text = 'カルーセル'
          break
        case 'image':
          text = '画像'
          break
        case 'video':
          text = '動画'
          break
        default:
          text = 'undefine'
          break
      }
      return text
    },

    formatHour(time) {
      if (!time) return
      return time.split(' ')[1]
    },
    formatTime(date) {
      if (!date) {
        return
      }
      let listDate = date.slice(0, 10).split('-')
      let listTime = date.slice(11, 19)
      let dateFormat = listDate[0] + '年' + listDate[1] + '月' + listDate[2] + '日' + ' ' + listTime
      return dateFormat
    },
    formatTimeInt(date) {
      if (!date) {
        return
      }
      const created_time = new Date(date * 1000)

      let day = created_time.getDate()
      let month = created_time.getMonth() + 1
      let year = created_time.getFullYear()
      let hour = created_time.getHours()
      let minute = (created_time.getMinutes() < 10 ? '0' : '') + created_time.getMinutes()

      return `${year}年${month}月${day}日 ${hour}:${minute}`
    },
    isVideoType(filePath) {
      if (!filePath) return
      const arr = filePath.split('.')
      return arr[arr.length - 1] == 'mp4' || arr[arr.length - 1] == 'm4v'
    },
    getTypeFile(fileName) {
      return fileName.split('.').pop()
    },
    async viewDetailContent(post) {
      await this.getAccountPost(post)

      this.postSelected = post
      this.dialogShowPost = true
    },
    async getAccountPost(post) {
      if (!post?.ad_account?.user_id) return

      const account = await getUserDetail(post?.ad_account?.user_id)
      this.postAuthor = account.data.data
    },
  },
}
</script>

<style lang="scss" scoped>
.showFullText {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}
th {
  white-space: nowrap;
}
th,
td {
  min-width: 150px;
}
.limit-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
</style>
<style>
.el-table-nowrap .el-table__cell .thead .cell {
  white-space: nowrap;
}
</style>
