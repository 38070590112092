<template>
  <div class="bg-white">
    <p class="text-title p-2 font-bold text-button_pink">{{ titte }}</p>
    <div class="p-4 chart--scroll linechart-contain">
      <div v-if="chartData.datasets.length > 0" class="pt-4">
        <Bar :chart-options="chartOptions" :chart-data="chartData" />
      </div>
      <el-empty v-else description="データはありません"></el-empty>
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
export default {
  name: 'BarChart',
  components: {
    Bar,
  },
  props: {
    titte: {
      type: String,
    },
    chartData: {
      type: Object,
      require: true,
    },
  },

  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: '',
          },
          datalabels: {
            display: false,
          },
        },
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.linechart-contain #line-chart {
  height: 400px !important;
}
@media (max-width: 640px) {
  .linechart-contain {
    #line-chart {
      height: 350px !important;
    }
  }
  .chart--scroll {
    padding: 1rem 0 !important;
    width: 100%;
    overflow: scroll;
    & > div {
      width: 800px;
      height: 400px;
    }
  }
}
</style>
