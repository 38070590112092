export async function fetchAccData(month, userId) {
  return await axios.get(route('api.pptx.account.statistic'), {
    params: {
      month: month,
      user_id: userId,
    },
  })
}

export async function fetchGroupData(month, groupId) {
  return await axios.get(route('api.pptx.group.statistic'), {
    params: {
      month: month,
      groupId: groupId,
    },
  })
}

export async function getListGroup() {
  return await axios.get(route('api.pptx.list-group'))
}
