<template>
    <div>
        <div v-if="openTab === 1">
            <Information :user="user" :toggleTabs="toggleTabs" :fetchDataUser="fetchDataUser"
                @onInstaSubmitSuccess="onInstaSubmitSuccess" />
        </div>
        <div v-if="openTab === 2">
            <MyPlan :user="user" :toggleTabs="toggleTabs" :fetchDataUser="fetchDataUser" />
        </div>
        <div
            v-if="openTab === 3 && !['admin_agency', 'admin_oem', 'user_agency', 'user_oem'].includes($page.props.auth.user.roles[0].name)">
            <Invoice :user="user" :toggleTabs="toggleTabs" />
        </div>
    </div>
</template>

<script>
import Information from "./Tabs/Information.vue";
import Invoice from "./Tabs/Invoice.vue";
import MyPlan from "./Tabs/MyPlan.vue";

export default {
    props: {
        user_id: {
            type: Number,
            required: true,
        },
    },

    components: {
        Invoice,
        MyPlan,
        Information,
    },

    data() {
        return {
            openTab: 0,
            user: {},
            check_role: false,
        };
    },

    async created() {
        await this.fetchDataUser();

        if (this.$page.props.flash.success) {
            this.$toast.success(this.$page.props.flash.success)
        }

        if (this.$page.props.flash.error) {
            this.$toast.error(this.$page.props.flash.error)
        }
    },

    methods: {
        toggleTabs(tab) {
            this.openTab = tab;
        },

        async fetchDataUser(tab = 1) {
            await axios
                .get(route("api.users.show", this.user_id))
                .then((res) => {
                    this.user = res.data.data;
                    this.openTab = tab;
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        onInstaSubmitSuccess() {
            this.fetchDataUser();
        },
    },
};
</script>
