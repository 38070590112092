import { EMPTY_IMG } from '../Mixins/media.js'

export default {
  methods: {
    async genSlideHashtagImg(pptx, hashtag, text, title) {
      let slideHashtagImg = pptx.addSlide({ masterName: 'MASTER_SLIDE' })

      slideHashtagImg.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })

      slideHashtagImg.addText(title, {
        x: 2.3,
        y: 0.25,
        w: 7,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 20,
      })

      slideHashtagImg.addText('▼ ベスト/ワースト投稿', {
        x: 0.9,
        y: 1.5,
        w: 4,
        h: 0.5,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 16,
      })

      slideHashtagImg.addText('▼ 最新投稿/人気投稿', {
        x: 6.3,
        y: 1.5,
        w: 4,
        h: 0.5,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 16,
      })

      slideHashtagImg.addText('ベスト5', {
        x: 0.75,
        y: 2.12,
        w: 1,
        align: 'left',
        color: '000000',
        fontSize: 9,
      })

      slideHashtagImg.addText('最近の投稿', {
        x: 6.2,
        y: 2.12,
        w: 1,
        align: 'left',
        color: '000000',
        fontSize: 9,
      })

      slideHashtagImg.addText('ワースト5', {
        x: 0.75,
        y: 3.5,
        w: 1,
        align: 'left',
        color: '000000',
        fontSize: 9,
      })

      slideHashtagImg.addText('人気投稿', {
        x: 6.2,
        y: 3.5,
        w: 1,
        align: 'left',
        color: '000000',
        fontSize: 9,
      })

      // ベスト5
      for (let i = 0; i < hashtag.hashtagBest.length; i++) {
        let item = hashtag.hashtagBest[i]
        if (await this.checkUrlAlive(item?.thumbnail_url)) {
          const best = this.isVideo(item?.thumbnail_url)
            ? await this.createFile(item?.thumbnail_url)
            : item?.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)
          slideHashtagImg.addImage({
            x: i * 0.9 + 0.9,
            y: 2.3,
            w: 0.8,
            h: 1,
            path: best,
          })
        } else {
          slideHashtagImg.addImage({ path: EMPTY_IMG, x: i * 0.9 + 0.9, y: 2.3, w: 0.8, h: 1 })
        }
      }
      // ワースト5
      for (let i = 0; i < hashtag.hashtagWorst.length; i++) {
        let item = hashtag.hashtagWorst[i]
        if (await this.checkUrlAlive(item?.thumbnail_url)) {
          const worst = this.isVideo(item?.thumbnail_url)
            ? await this.createFile(item?.thumbnail_url)
            : item?.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)
          slideHashtagImg.addImage({
            x: i * 0.9 + 0.9,
            y: 3.7,
            w: 0.8,
            h: 1,
            path: worst,
          })
        } else {
          slideHashtagImg.addImage({ path: EMPTY_IMG, x: i * 0.9 + 0.9, y: 3.7, w: 0.8, h: 1 })
        }
      }

      // 最近の投稿
      for (let i = 0; i < hashtag.hashtagRecent.length; i++) {
        let item = hashtag.hashtagRecent[i]
        if (await this.checkUrlAlive(item?.thumbnail_url)) {
          const recent = this.isVideo(item?.thumbnail_url)
            ? await this.createFile(item?.thumbnail_url)
            : item?.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)
          slideHashtagImg.addImage({
            x: i * 0.9 + 6.2,
            y: 2.3,
            w: 0.8,
            h: 1,
            path: recent,
          })
        } else {
          slideHashtagImg.addImage({ path: EMPTY_IMG, x: i * 0.9 + 6.2, y: 2.3, w: 0.8, h: 1 })
        }
      }

      // 人気投稿
      for (let i = 0; i < hashtag.hashtagPopular.length; i++) {
        let item = hashtag.hashtagPopular[i]
        if (await this.checkUrlAlive(item?.thumbnail_url)) {
          const popular = this.isVideo(item?.thumbnail_url)
            ? await this.createFile(item?.thumbnail_url)
            : item?.thumbnail_url + '?time=' + Math.floor(Date.now() / 1000)
          slideHashtagImg.addImage({
            x: i * 0.9 + 6.2,
            y: 3.7,
            w: 0.8,
            h: 1,
            path: popular,
          })
        } else {
          slideHashtagImg.addImage({ path: EMPTY_IMG, x: i * 0.9 + 6.2, y: 3.7, w: 0.8, h: 1 })
        }
      }

      slideHashtagImg.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.1,
        w: 10.14,
        h: 1.74,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slideHashtagImg
    },
    async checkUrlAlive(url){
        const res = await fetch(url + '?time=' + Math.floor(Date.now() / 1000))
        return res?.status === 200
    },
    isVideo(linkS3) {
      if (linkS3 == null) {
        return false
      }
      let arr = linkS3.split('.')
      const typeVieo = ['mp4', 'm4v', 'avi', 'mov', 'mpg', 'mpeg', 'wmv', 'avi', 'mpeg-2']
      return typeVieo.includes(arr[arr.length - 1])
    },

    async createFile(link) {
      let response = await fetch(link + '?time=' + Math.floor(Date.now() / 1000))
      let data = await response.blob()
      let metadata = {
        type: 'image/jpg',
      }
      let file = new File([data], 'test.jpg', metadata)
      let dataUrl = await this.generateVideoThumbnail(file)
      const res = await fetch(dataUrl)
      return res?.url
    },

    generateVideoThumbnail(file) {
      return new Promise((resolve) => {
        const canvas = document.createElement('canvas')
        const video = document.createElement('video')

        video.autoplay = true
        video.muted = true
        video.src = URL.createObjectURL(file)

        video.onloadeddata = () => {
          let ctx = canvas.getContext('2d')

          canvas.width = video.videoWidth
          canvas.height = video.videoHeight

          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
          video.pause()
          return resolve(canvas.toDataURL('image/png'))
        }
      })
    },
  },
}
