import { EMPTY_IMG } from '../Mixins/media.js'
export default {
  methods: {
    genSlide26(pptx, data, items, text, title) {
      let slide26 = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slide26.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      slide26.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 5.15,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '000000',
        fontSize: 22,
      })

      slide26.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.45,
        y: 1.42,
        w: 2.71,
        h: 4.37,
        rectRadius: 0.1,
        fill: { color: 'f8f8f8' },
      })

      slide26.addText('基本アクセス情報', {
        x: 0.5,
        y: 1.6,
        w: 1.61,
        h: 0.29,
        align: 'left',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 10,
      })

      slide26.addText('アカウントリーチ数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 0.77,
        y: 2.21,
        w: 1.98,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 10,
      })

      slide26.addText(String(data.sumData.dataSeletedMonth.accountDetail.sumReach), {
        x: 0.27,
        y: 2.58,
        w: 3.0,
        h: 0.41,
        align: 'center',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 19,
      })

      slide26.addText('( + 0)', {
        x: 0.8,
        y: 2.9,
        w: 1.96,
        h: 0.26,
        align: 'center',
        valign: 'top',
        color: '578DF4',
        fontSize: 10,
      })

      slide26.addText('プロフィールアクセス数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 0.77,
        y: 3.26,
        w: 1.98,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 10,
      })

      slide26.addText(String(data.sumData.dataSeletedMonth.accountDetail.sumProfileView), {
        x: 0.27,
        y: 3.69,
        w: 3.0,
        h: 0.41,
        align: 'center',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 19,
      })

      slide26.addText('( + 0)', {
        x: 0.8,
        y: 4.01,
        w: 1.96,
        h: 0.26,
        align: 'center',
        valign: 'top',
        color: '578DF4',
        fontSize: 10,
      })

      slide26.addText('行動合計数', {
        shape: pptx.shapes.ROUNDED_RECTANGLE,
        x: 0.77,
        y: 4.39,
        w: 1.98,
        h: 0.3,
        bold: true,
        align: 'center',
        rectRadius: 0.05,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        fontSize: 10,
      })

      slide26.addText('0', {
        x: 0.27,
        y: 4.9,
        w: 3.0,
        h: 0.41,
        align: 'center',
        valign: 'top',
        bold: true,
        color: '303030',
        fontSize: 19,
      })

      slide26.addText('( + 0)', {
        x: 0.8,
        y: 5.22,
        w: 1.96,
        h: 0.26,
        align: 'center',
        valign: 'top',
        color: '578DF4',
        fontSize: 10,
      })
      if (
        data.sumData.dataSeletedMonth.accountDetail.sumProfileView == 0 &&
        data.sumData.dataSeletedMonth.accountDetail.sumReach == 0
      ) {
        slide26.addImage({ path: EMPTY_IMG, x: 5.14, y: 1.8, w: 3.56, h: 3.56 })
      } else {
        // chart
        let opts = {
          x: 3.96,
          y: 1.35,
          w: 6.89,
          h: 4.37,
          barDir: 'col',
          barGrouping: 'clustered',
          catAxisLabelColor: '787878',
          catAxisLabelFontSize: 12,
          catAxisOrientation: 'minMax',
          showTitle: true,
          title: title,
          titleColor: '787878',
          titleFontSize: 18.6,
          showLabel: false,
          showLegend: false,
          legendPos: 'b',
          legendFontSize: 12,
          legendColor: '787878',
          chartColors: ['3E63A3', 'E7299E'],
          catAxisLabelFontSize: 9,
          catAxisLabelRotate: -45,
          valAxes: [
            {
              showValAxisTitle: false,
              valAxisTitle: 'Primary Value Axis',
            },
            {
              showValAxisTitle: false,
              valAxisTitle: 'Secondary Value Axis',
              catAxisOrientation: 'maxMin',
              valAxisMinVal: 0,
              valGridLine: { style: 'none' },
            },
          ],
          catAxes: [
            {
              catAxisTitle: 'Primary Category Axis',
            },
            {
              catAxisHidden: true,
            },
          ],
        }
        let chartTypes = [
          {
            type: pptx.charts.BAR,
            data: [
              {
                name: 'いいね',
                labels: data.dataLabels,
                values: data.dataLike,
              },
              {
                name: 'コメント',
                labels: data.dataLabels,
                values: data.dataComment,
              },
            ],
          },
          {
            type: pptx.charts.LINE,
            data: [
              {
                name: 'Current',
                labels: data.dataLabels,
                values: items.dataSlide18.dataEngagementRate,
              },
            ],
            options: {
              chartColors: ['C2C2C2'],
              lineDataSymbol: 'none',
              secondaryValAxis: !!opts.valAxes,
              secondaryCatAxis: !!opts.catAxes,
            },
          },
        ]
        slide26.addChart(chartTypes, opts)
        // end chart
      }

      slide26.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.29,
        w: 10.27,
        h: 1.56,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })

      return slide26
    },
  },
}
