<template>
  <data-table
    :fields="fields"
    :items="hashtags.data"
    :paginate="hashtags.meta"
    :current-page="filter.page || 1"
    disable-table-info
    footer-center
    paginate-background
    @page="handleCurrentPage"
    class="el-table-header__black"
  >
  <template #hashtag_name="{ row }">
      <p class="text-center">
        <a
          :href="`${URL_INSTAGRAM}/${row?.hashtag_name?.replace('#', '')}`"
          class="text-button_pink underline font-medium text-[13px] cursor-pointer"
          target="_blank"
        >
          {{ row?.hashtag_name }}
        </a>
      </p>
    </template>
    <template #hashtag_using="{ row }">
      <p>{{ formatToInteger(row?.totalCount) }}</p>
    </template>
    <template #hashtag_likes="{ row }">
      <p>{{ formatToInteger(row?.hashtag_likes) }}</p>
    </template>
    <template #hashtag_reachs="{ row }">
      <p>{{ formatToInteger(row?.hashtag_reachs) }}</p>
    </template>
    <template #hashtag_comments="{ row }">
      <p>{{ formatToInteger(row?.hashtag_comments) }}</p>
    </template>
    <template #hashtag_saves="{ row }">
      <p>{{ formatToInteger(row?.hashtag_saves) }}</p>
    </template>
    <template #hashtag_impressions="{ row }">
      <p>{{ row?.hashtag_impressions ? formatToInteger(row?.hashtag_impressions) : '0' }}</p>
    </template>
    <template #engagement_rate="{ row }">
      <p>{{ formatToFloat(row?.engagement_rate + '%') }}</p>
    </template>
  </data-table>
</template>

<script>
import DataTable from '@/Components/UI/DataTable.vue'

export default {
  components: { DataTable },
  emits: ['getAccountHashtagTable'],
  props: {
    range: { type: Object, default: 0 },
    year: { type: Object, default: 0 },
    analyticType: { type: String, default: undefined },
  },
  data() {
    return {
      URL_INSTAGRAM: 'https://www.instagram.com/explore/tags',
      hashtags: {},
      filter: {
        page: 1,
        limit: 10,
      },
      fields: [
        {
          key: 'hashtag_name',
          label: 'ハッシュタグ',
          minWidth: 300,
          fixed: window.innerWidth > 600 ? 'left' : false,
          align: 'left',
          lableClassName: 'text-center',
        },
        { key: 'hashtag_using', label: '使用回数', width: 170, align: 'center' },
        { key: 'hashtag_likes', label: 'いいね数', width: 170, align: 'center' },
        { key: 'hashtag_reachs', label: 'リーチ数', width: 170, align: 'center' },
        { key: 'hashtag_comments', label: 'コメント数', width: 170, align: 'center' },
        { key: 'hashtag_saves', label: '保存数', width: 170, align: 'center' },
        { key: 'hashtag_impressions', label: 'インプレッション数', width: 170, align: 'center' },
        { key: 'engagement_rate', label: 'エンゲージメント率', width: 170, align: 'center' },
      ],
    }
  },
  created() {
    this.fetchData()
    this.getHashtagTableData()
  },
  watch: {
    range: function () {
      this.fetchData()
      this.getHashtagTableData()
    },
    year: function () {
      this.fetchData()
    },
  },
  methods: {
    // fetch data
    async fetchData() {
      await axios
        .get(
          route('api.account.get-hashtag.all', {
            filter: { name: this.filter?.name },
            limit: this.filter?.limit,
            page: this.filter?.page,
            checkHashtagAll: true,
          })
        )
        .then((res) => {
          this.hashtags = res?.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getHashtagTableData() {
      if (!this.range.length) return
      await axios
        .get(
          route('api.account.get-hashtag.all', {
            checkHashtagAll: true,
            limit: 30,
          })
        )
        .then((res) => {
          let tableHashtagData = res?.data.data.map((item) => {
            return [
              item.name.replace(/#+/g, '#'),
              this.formatToInteger(item.totalCount),
              this.formatToInteger(item.hashtag_likes),
              this.formatToInteger(item.hashtag_reachs),
              this.formatToInteger(item.hashtag_comments),
              this.formatToInteger(item.hashtag_saves),
              item.hashtag_impressions ? this.formatToInteger(item.hashtag_impressions) : '0',
              this.formatToFloat(item?.engagement_rate + '%'),
            ]
          })
          this.$emit('getAccountHashtagTable', tableHashtagData)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleCurrentPage(value) {
      this.filter.page = value
      this.fetchData()
    },
  },
}
</script>
