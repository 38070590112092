<template>
  <div class="flex h-screen overflow-hidden">
    <div class="wrapper relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-gray-100">
      <!-- <AppHeader /> -->
      <div class="success">
        <div class="mb-10">
          <img src="/img/favicon-upscale.png" alt="Favicon Upscale" width="152" height="28" />
        </div>
        <div class="text-center font-normal lg:mb-16 md:mb-10 sm:mb-8">
          <p class="text-nomal">
            この度はInstaチェキ フリープランへのご登録ありがとうございます。
            <br />
            ご登録頂いたメールアドレスにパスワード設定のお知らせをさせていただいていますのでメールをご確認ください。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dadada;
  padding: 40px 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
