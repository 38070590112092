<template>
    <BreezeGuestLayout my_form_login="my-0 pb-5">

        <Head title="Reset Password" />
        <form @submit.prevent="submit" class="container mx-auto px-5 pb-5 mb-10">
            <div
                class="flex justify-between py-5 mb-5 border font-bold border-t-gray-300 border-l-0 border-r-0 border-b-gray-300 w-full">
                <div class="text-button_pink font-bold text-title">お客様情報</div>
                <p>
                    {{ name }}
                </p>
            </div>

            <p class="font-bold py-5 text-button_pink text-title">
                パスワード設定
            </p>

            <div class="flex items-center mt-5 flex-sm">
                <BreezeLabel for="email" class="sm:w-48 md:w-48 font-bold text-2sm">
                    メールアドレス <span class="text-red-600">*</span>
                </BreezeLabel>
                <BreezeInput id="email" disabled="disabled" type="email"
                    class="mt-1 block sm:w-72 md:w-96 rounded-sm p-3 text-2sm"
                    :class="form.errors.email ? 'border-red-600' : ''" @change="form.errors.email = ''"
                    v-model="form.email" autofocus autocomplete="username" />
            </div>
            <div class="flex">
                <div class="sm:w-0 md:w-48"></div>
                <div class="text-left sm:text-2sm text-baseo text-red-600 mt-2 sm:w-72 md:w-auto"
                    v-if="form.errors.email">
                    {{ form.errors.email }}
                </div>
            </div>

            <div class="flex items-center mt-5  flex-sm">
                <BreezeLabel for="password" class="sm:w-48 md:w-48 font-bold text-2sm">パスワード
                    <span class="text-red-600">*</span>
                </BreezeLabel>
                <BreezeInput id="password" type="password" class="mt-1 block sm:w-72 md:w-96 rounded-sm p-3 text-2sm"
                    :class="form.errors.password ? 'border-red-600' : ''" @change="form.errors.password = ''"
                    v-model="form.password" placeholder="パスワードを入力" autocomplete="new-password" />
            </div>
            <div class="flex">
                <div class="sm:w-0 md:w-48"></div>
                <div class="text-left sm:text-2sm text-baseo text-red-600 mt-2 sm:w-72 md:w-auto"
                    v-if="form.errors.password">
                    {{ form.errors.password }}
                </div>
            </div>

            <div class="flex items-center mt-5  flex-sm">
                <BreezeLabel for="password_confirmation" class="w-48 font-bold text-2sm">
                    パスワード（確認）<span class="text-red-600">*</span>
                </BreezeLabel>
                <BreezeInput id="password_confirmation" type="password"
                    class="mt-1 block sm:w-72 md:w-96 rounded-sm p-3 text-2sm"
                    :class="form.errors.password_confirmation ? 'border-red-600' : ''"
                    @change="form.errors.password_confirmation = ''" v-model="form.password_confirmation"
                    placeholder="パスワードを入力" autocomplete="new-password" />
            </div>
            <div class="flex">
                <div class="sm:w-0 md:w-48"></div>
                <div class="text-left sm:text-2sm text-baseo text-red-600 mt-2 sm:w-72 md:w-auto"
                    v-if="form.errors.password_confirmation">
                    {{ form.errors.password_confirmation }}
                </div>
            </div>
            <div class="flex items-center mt-5  flex-sm">
                <BreezeLabel value="出身地は？" class="w-48 font-bold text-2sm" />
                <BreezeInput type="text" class="mt-1 block sm:w-72 md:w-96 rounded-sm p-3 text-2sm"
                    autocomplete="new-password" placeholder="出身地を入力" v-model="form.user_from" />
            </div>

            <!-- Term of Service -->
            <div class="flex justify-center items-center mt-10 mb-2">
                <div>
                    <div class="flex">
                        <input id="flexCheckChecked"
                            class="form-check-input appearance-none h-4 w-4border border-gray-300 rounded-sm bg-white checked:bg-pink checked:border-bg-pink focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="checkbox" v-model="form.checkbox" @change="form.errors.checkbox = ''" />
                        <label class="form-check-label inline-block text-gray-800 text-nomal" for="flexCheckChecked">
                            <a href="/term" target="_blank">
                                <span class="text-button_pink hover:cursor-pointer text-nomal">利用規約・</span>
                            </a>
                            <a href="/privacy" target="_blank">
                                <span class="text-button_pink hover:cursor-pointer text-nomal">プライバシーポリシ</span>
                            </a>
                            ーに同意する
                        </label>
                    </div>
                    <div class="text-left mt-2">
                        <span v-if="form.errors.checkbox" class="text-red-600"> {{ form.errors.checkbox }} </span>
                    </div>
                </div>
            </div>
            <div class="flex justify-center">
                <BreezeButton class="bg-indigo-500 hover:bg-indigo-700 py-5 flex justify-center rounded-sm mt-2 w-72">
                    <span class="text-white text-btn-submit">登録する</span>
                </BreezeButton>
            </div>

        </form>
    </BreezeGuestLayout>
</template>

<script>
import BreezeButton from "@/Components/Button.vue";
import BreezeGuestLayout from "@/Layouts/Guest.vue";
import BreezeInput from "@/Components/Input.vue";
import BreezeLabel from "@/Components/Label.vue";
import BreezeValidationErrors from "@/Components/ValidationErrors.vue";
import { Head, useForm, Link } from "@inertiajs/inertia-vue3";

export default {
    components: {
        BreezeButton,
        BreezeGuestLayout,
        BreezeInput,
        BreezeLabel,
        BreezeValidationErrors,
        Head,
        useForm,
        Link
    },

    props: {
        email: {
            type: String,
            required: true,
        },

        name: {
            type: String,
            required: true,
        },

        token: {
            type: String,
            required: false,
        },
        status: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            form: useForm({
                token_register: this.token,
                email: this.email,
                password: "",
                password_confirmation: "",
                checkbox: false,
                user_from: "",
            }),
        };
    },
    watch: {
        "form.password"(val) {
            let password = document.getElementById("password");
            password.onkeyup = function (e) {
                if (e.key == " " || e.code == "Space" || e.keyCode == 32) {
                    this.form.password.value = val.replace(/ +/g, "");
                }
            };
        },
        "form.password_confirmation"(val) {
            let password_confirm = document.getElementById("password_confirmation");
            password_confirm.onkeyup = function (e) {
                if (e.key == " " || e.code == "Space" || e.keyCode == 32) {
                    this.form.password_confirmation.value = val.replace(/ +/g, "");
                }
            };
        },
    },

    methods: {
        submit() {
            this.form.post(route("user.register.update"), {
                onFinish: () => {
                    if (!this.form.hasErrors) {
                        this.$toast.success("登録完了しました。");
                        this.$inertia.get(route("admin.users_infor"));
                    }
                },
            });
        },
    },
};
</script>
