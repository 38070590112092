<template>
    <DrashBoard>
        <LinkInstagram v-if="!showLinkInstagram" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
        <template v-slot:breakcumb>
            <Loading v-if="isLoading" />
            <BreadCrumb>
                <li class="mr-4">
                    <Link :href="route('admin.post.create')" class="font-normal breakcumb-active">投稿作成</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="mt-12 ml-10 tabs">
                <ul class="flex">
                    <li class="px-6 relative pb-5 active">投稿作成</li>
                </ul>
            </div>
        </template>
        <UserNav />
        <div class="lg:pl-10 bg-background flex sm:flex-col lg:flex-row md:pl-5 sm:pl-2">
            <div class="flex-1 lg:pr-10 md:pr-0">
                <h2 class="text-title text-button_pink font-semibold md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">投稿作成</h2>
                <form v-on:submit.prevent class="grid gird-two-column items-center post-create-form">
                    <label for="">アカウント名</label>
                    <p class="text-base text-request font-normal text-position__sm">{{ userName }}</p>

                    <!-- new reels -->
                    <label class="">
                        投稿タイプ
                        <span class="text-red-500">*</span>
                    </label>
                    <div class="form items-center">
                        <div class="flex">
                            <div v-for="item in listCategory" :key="item">
                                <input class="w-5 h-5 border-gray-200" type="radio" :value="item.value"
                                    v-model="form.postCategory" />
                                <label>{{ item.label }}</label>
                            </div>
                        </div>
                    </div>
                    <!-- end reels -->

                    <label>
                        投稿ステータス
                        <span class="text-red-500">*</span>
                    </label>
                    <div class="form">
                        <div class="flex">
                            <div class="flex items-center" v-for="item in postStatus" :key="item">
                                <input class="w-5 h-5 border-gray-200" type="radio" :value="item.value"
                                    v-model="form.status" @change="form.errors.status = ''" />
                                <label :for="item.label">
                                    {{ item.label }}
                                    <el-tooltip v-if="item.value == 2" class="box-item ml-1" effect="light"
                                        :content="$t('tooltip_post_save_draft')" placement="top">
                                        <el-icon class="ml-1" :size="16">
                                            <QuestionFilled />
                                        </el-icon>
                                    </el-tooltip>
                                </label>
                            </div>
                        </div>
                        <div class="text-left text-red-600 text-[12px] mt-2" v-if="form.errors.status">
                            {{ form.errors.status }}
                        </div>
                    </div>
                    <label v-if="form.status === '1'" for=""></label>
                    <div v-show="form.status === '1'">
                        <div class="md:-my-5 picker-style">
                            <el-date-picker v-model="date" type="datetime" format="YYYY-MM-DD HH:mm" size="large"
                                popper-class="cusDatePickerCreatePost" :disabled-date="disabledDate" />
                            <div class="text-left text-[12px] text-red-600 mt-2" v-if="validateSchedule">
                                {{ messageSchedule }}
                            </div>
                        </div>
                    </div>

                    <label for="">
                        クリエイティブ
                        <span class="text-red-500">*</span>
                    </label>

                    <!-- new select post category -->
                    <div v-if="form.postCategory == 0" class="flex items-center">
                        <div v-if="form.postCategory == 0">
                            <input type="radio" :value="0" v-model="form.is_multiple_file"
                                class="mr-3.2 border-gray-200" />
                            <label for="one" class="mr-11.5">{{ this.$i18n.t('ImageOrvideo') }}</label>
                            <input type="radio" class="mr-3.2 border-gray-200" :value="1"
                                v-model="form.is_multiple_file" />
                            <label for="two">{{ this.$i18n.t('Carosel') }}</label>
                        </div>
                    </div>
                    <!-- end new -->

                    <label v-if="form.postCategory == 0" class="hidden-mobile" for=""></label>
                    <div class="flex md:flex-row sm:flex-col gap-col-sm">
                        <!-- One file feed-->
                        <div class="flex md:flex-row sm:flex-col gap-col-sm items-center"
                            v-show="!form.is_multiple_file && form.postCategory == 0">
                            <div class="flex items-center">
                                <div v-if="validateFile">
                                    <img v-if="fileType != 'video/mp4' && imgUpload != ''" :src="imgUpload.slice(-1)[0]"
                                        class="w-36.2 mr-2" />
                                    <video v-if="fileType == 'video/mp4'" id="foo" width="200" class="mr-2"
                                        :src="imgUpload.slice(-1)[0]" controls autoplay>
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <span v-if="validateFile" @click="uploadNewImg"
                                    class="text-xs text-button_pink underline hover:cursor-pointer mr-[5px] ml-[5px]">
                                    クリエイティブを変更する
                                </span>
                            </div>
                            <div>
                                <input @change="selectImg" v-show="false" type="file" id="file" ref="inputFile"
                                    :accept="form.postCategory == 0 ? customFileNormal : customFileReels" />
                                <p v-if="!validateFile" @click="uploadImg"
                                    class="text-white text-xs bg-blue-600 md:py-2 md:w-44 sm:w-24 sm:py-1 text-center hover:cursor-pointer rounded">
                                    アップロード
                                </p>
                                <p v-if="validateMedia == true && !validateFile"
                                    class="pt-[5px] text-[12px] text-red-600">
                                    ファイルを選択してください
                                </p>
                            </div>
                            <p v-if="validateFile == false"
                                class="text-[12px] md:ml-[30px] sm:ml-0 text-red-600 sm:text-center lg:text-left">
                                最大サイズ 5000＊10000px＆＆容量8MBアップしてください。
                                <br />
                                画像だけではなく動画もアップ可能です。
                            </p>
                            <div class="text-left text-red-600 text-[12px] mt-2 ml-5" v-if="form.errors.file">
                                {{ form.errors.file }}
                            </div>
                            <div class="text-left text-red-600 text-[12px] mt-2 ml-5" v-if="fileError">
                                {{ fileError }}
                            </div>
                        </div>
                        <!-- One file reel-->
                        <div class="flex md:flex-row sm:flex-col gap-col-sm items-center"
                            v-show="!form.is_multiple_file && form.postCategory == 1">
                            <div class="flex items-center">
                                <div v-if="validateFileReel">
                                    <img v-if="fileTypeReel != 'video/mp4' && imgUploadReel != ''"
                                        :src="imgUploadReel.slice(-1)[0]" class="w-36.2 mr-2" />
                                    <video v-if="fileTypeReel == 'video/mp4'" id="foo" width="200" class="mr-2"
                                        :src="imgUploadReel.slice(-1)[0]" controls autoplay>
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <span v-if="validateFileReel" @click="uploadNewImgReel"
                                    class="text-xs text-button_pink underline hover:cursor-pointer mr-[5px] ml-[5px]">
                                    クリエイティブを変更する
                                </span>
                            </div>
                            <div>
                                <input @change="selectImgReel" v-show="false" type="file" id="fileReel"
                                    ref="inputFileReel"
                                    :accept="form.postCategory == 0 ? customFileNormal : customFileReels" />
                                <p v-if="!validateFileReel" @click="uploadImgReel"
                                    class="text-white text-xs bg-blue-600 md:py-2 md:w-44 sm:w-24 sm:py-1 text-center hover:cursor-pointer rounded">
                                    アップロード
                                </p>
                                <p v-if="validateMediaReel == true && !validateFileReel"
                                    class="pt-[5px] text-[12px] text-red-600">
                                    ファイルを選択してください
                                </p>
                            </div>
                            <p v-if="validateFileReel == false"
                                class="text-[12px] md:ml-[30px] sm:ml-0 text-red-600 sm:text-center lg:text-left">
                                最大サイズ 5000＊10000px＆＆容量8MBアップしてください。
                                <br />
                                画像だけではなく動画もアップ可能です。
                            </p>
                            <div class="text-left text-red-600 text-[12px] mt-2 ml-5" v-if="form.errors.fileReel">
                                {{ form.errors.fileReel }}
                            </div>
                            <div class="text-left text-red-600 text-[12px] mt-2 ml-5" v-if="fileErrorReel">
                                {{ fileErrorReel }}
                            </div>
                        </div>

                        <!-- Multiple files -->
                        <upload-file class="-my-5" v-show="form.is_multiple_file" @uploadFile="handleUploadFile"
                            @removeFile="removeFile" :isMultiple="true"
                            :accept="form.postCategory == 0 ? customFileNormal : customFileReels"
                            :listFiles="listFiles">
                        </upload-file>
                    </div>

                    <label>
                        本文
                        <span class="text-red-500"></span>
                    </label>
                    <div class="relative">
                        <el-input v-model="form.content" maxlength="2000" show-word-limit type="textarea"
                            placeholder="本文を入力" />
                    </div>
                    <label for="">ハッシュタグ</label>
                    <div class="flex custom-select">
                        <el-tag :key="tag" v-for="tag in form.hashtag" closable :disable-transitions="false"
                            @close="handleClose(tag)">
                            {{ tag }}
                        </el-tag>
                        <el-input class="input-new-tag" :placeholder="selectTagPlaceholder" v-model="inputValueHashtag"
                            ref="saveTagInput" @keyup.enter="handleInputConfirm" @blur="handleInputConfirm"></el-input>
                    </div>

                    <label v-if="!form.is_multiple_file && form.postCategory == 0" for="">タグ付</label>
                    <div v-if="!form.is_multiple_file && form.postCategory == 0"
                        class="flex md:items-center md:flex-row sm:flex-col sm:items-start gap-col-sm">
                        <span v-if="!validateFile" @click="uploadImg"
                            class="text-white text-xs bg-blue-600 py-2 hover:cursor-pointer rounded md:py-2 md:w-44 sm:w-24 sm:py-1 text-center sm:mx-auto lg:mx-0">
                            アップロード
                        </span>
                        <el-dialog width="30%" v-model="openDialogTag" :title="this.$i18n.t('Add Tag')"
                            :close-on-click-modal="false" append-to-body>
                            <div class="flex items-center justify-center">
                                <div class="background relative hover:cursor-crosshair flex items-center">
                                    <img @click="openSelectHashtag($event)" :src="imgUpload.slice(-1)[0]"
                                        v-if="fileType != 'video/mp4' && imgUpload != ''" class="w-100 h-full" />
                                    <p class="mr-4" v-if="fileType == 'video/mp4' && imgUpload != ''">{{ item }}</p>
                                    <span :style="{ top: item.y * 100 + '%', left: item.x * 100 + '%' }"
                                        v-for="(item, index) in dataTag" :key="index"
                                        class="bg-gray-300 rounded-xl py-2 leading-none px-4 absolute flex items-center tag-flag">
                                        <span class="mr-4 text-xs whitespace-nowrap">{{ item.username }}</span>
                                        <svg @click="removeHashtag(index)" class="inline-block cursor-pointer"
                                            width="10" height="10" viewBox="0 0 10 10" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.44662 1.17242L8.50661 0.232422L4.77995 3.95909L1.05328 0.232422L0.113281 1.17242L3.83995 4.89909L0.113281 8.62576L1.05328 9.56576L4.77995 5.83909L8.50661 9.56576L9.44662 8.62576L5.71995 4.89909L9.44662 1.17242Z"
                                                fill="#5264CD" />
                                        </svg>
                                    </span>
                                    <SelectHashtag :dialogFormVisible="openTagBox.status" :left="openTagBox.left"
                                        :top="openTagBox.top" :width="openTagBox.width" :height="openTagBox.height"
                                        @closeSelectTag="closeHashtag($event)" />
                                </div>
                            </div>
                        </el-dialog>
                        <div class="flex items-center">
                            <div v-if="validateFile" class="background relative flex items-center">
                                <img :src="imgUpload.slice(-1)[0]" v-if="fileType != 'video/mp4' && imgUpload != ''"
                                    class="object-contain w-36.2 mr-2" />
                                <video v-if="fileType == 'video/mp4'" id="foo" width="200" class="mr-2"
                                    :src="imgUpload.slice(-1)[0]" controls autoplay>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <span v-if="validateFile" @click="uploadNewImg"
                                class="text-xs text-button_pink underline hover:cursor-pointer mr-[5px] ml-[5px]">
                                クリエイティブを変更する
                            </span>
                        </div>
                        <span v-if="validateFile && fileType != 'video/mp4'" @click="openDialogTag = true"
                            class="text-xs text-button_pink underline hover:cursor-pointer mr-[10px] ml-[5px]">
                            {{ this.$i18n.t('Edit tag') }}
                        </span>
                        <p v-if="validateFile == false"
                            class="text-[12px] md:ml-[30px] sm:ml-0 text-red-600 sm:text-center lg:text-left">
                            最大サイズ 5000＊10000px＆＆容量8MBアップしてください。
                            <br />
                            画像だけではなく動画もアップ可能です。
                        </p>
                    </div>
                </form>
                <div class="flex justify-center lg:mt-16.3 lg:pb-64 md:pb-10 md:mt-10 sm:mt-10">
                    <button @click="backtoList"
                        class="text-btn-submit lg:w-72 md:w-4/12 sm:w-5/12 md:py-4 sm:py-2 lg:py-5 bg-button_pink text-white button-shadow rounded">
                        取り消し
                    </button>
                    <button @click="submitForm()" as="button" type="submit"
                        class="text-btn-submit lg:w-72 lg:py-5 md:w-4/12 sm:w-5/12 md:py-4 sm:py-2 bg-blue-600 ml-6 text-white button-shadow rounded">
                        実行する
                    </button>
                </div>
            </div>
            <div class="basis-3/12 lg:border-l sm:border-0 lg:pb-0 md:pb-5 sm:pb-10">
                <PostPreview :validate="form.postCategory == 0 ? validateFile : validateFileReel"
                    :profile-picture-url="profilePictureUrl" :content="form.content" :username="userName"
                    :tag="form.tag" :hashtag="form.hashtag" :file="form.postCategory == 0 ? imgUpload : imgUploadReel"
                    :fileType="form.postCategory == 0 ? fileType : fileTypeReel"
                    :isCarousel="form.is_multiple_file == 1" :arrImage="listFiles.arrImage" />
            </div>
        </div>
    </DrashBoard>
</template>
<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import SelectHashtag from './SelectTag.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import PostPreview from '@/Components/InstagramScreen.vue'
import { Inertia } from '@inertiajs/inertia'
import { Link, useForm } from '@inertiajs/inertia-vue3'
import * as userAPI from '@/Api/Users'
import Loading from '@/Components/Loadding'
import axios from 'axios'
import { getVideoInfo } from '@/Helpers/video'
import UserNav from '@/Components/UserNav.vue'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import GetThumbnailUrl from '../../../Mixins/thumbnail.js'
import UploadFile from '@/Components/UI/UploadFile.vue'
import { QuestionFilled } from '@element-plus/icons-vue'

export default {
    props: ['userId', 'plan'],
    components: {
        DrashBoard,
        PostPreview,
        BreadCrumb,
        SelectHashtag,
        Link,
        QuestionFilled,
        Loading,
        UserNav,
        LinkInstagram,
        UploadFile,
    },
    mixins: [GetThumbnailUrl],
    data() {
        return {
            multiCheck: 1,
            feedCheck: 1,
            reelCheck: 1,
            inputValueHashtag: '',
            selectTagPlaceholder: 'ハッシュタグを入力',
            openDialogTag: false,
            isLoading: false,
            openTagBox: {
                status: false,
                clicked: true,
                left: 0,
                width: 0,
                height: 0,
                top: 0,
            },
            dataTag: [],
            fileType: '',
            fileTypeReel: '',
            fileSize: 0,
            date: new Date(),
            btnUpload: true,
            fileError: false,
            fileErrorReel: false,
            btnUploadTag: true,
            validateFile: false,
            validateFileReel: false,
            showLinkInstagram: true,
            imgUpload: [],
            imgUploadReel: [],
            fileUpdate: '',
            fileUpdateReel: '',
            userName: '',
            form: useForm({
                status: '0',
                content: '',
                file: '',
                fileReel: '',
                thumbnail: '',
                scheduled_time: new Date(),
                hashtag: [],
                tag: [],
                is_multiple_file: 0,
                files: [],
                postCategory: 0,
            }),
            postStatus: [
                {
                    label: '即時配信',
                    value: '0', // now
                },
                {
                    label: '予約投稿',
                    value: '1', //scheduled
                },
                {
                    label: '下書き保存',
                    value: '2', //draft
                },
            ],
            listCategory: [
                {
                    label: '通常投稿',
                    value: '0', // Normal post
                },
                {
                    label: 'リール投稿',
                    value: '1', //Reel post
                },
            ],
            profilePictureUrl: '',
            validateMedia: false,
            validateMediaReel: false,
            validateSchedule: false,
            listFiles: {},
            checkPlan: '',
            customFileNormal: 'image/jfif,image/pjpeg,image/jpeg,image/pjp,image/png,image/gif,video/mp4,video/m4v',
            customFileReels: 'video/mp4,video/m4v',
        }
    },
    created() {
        this.checkPlan = this.plan
        if (this.checkPlan) {
            if (this.checkPlan['reserved_post_function'] != 1) {
                this.postStatus.splice(1, 1)
            }
        }
    },
    methods: {
        onInstaSubmitSuccess() {
            this.showLinkInstagram = true
        },
        backtoList() {
            Inertia.visit('/post')
        },
        handChangeTags(e) {
            this.form.tag = this.convertTag(e)
        },
        disabledDate(time) {
            let d = new Date()
            let minDate = d.setDate(d.getDate() - 1)
            let maxDate = new Date(d.setMonth(d.getMonth() + 3)).getTime()
            return time.getTime() < minDate || time.getTime() >= maxDate
        },
        async submitForm() {
            try {
                if (this.form.status == 1) {
                    const dateNow = new Date()
                    dateNow.setMinutes(dateNow.getMinutes() + 29)
                    let formatDateNow = new Date(dateNow).getTime()
                    let timeSchedule = new Date(this.date).getTime()
                    if (timeSchedule < formatDateNow) {
                        this.validateSchedule = true
                        return this.errorSchedule(dateNow)
                    }

                    if (this.validateSchedule == true) {
                        return
                    }

                    this.form.scheduled_time = this.convertTime(this.date)
                } else {
                    this.form.scheduled_time = ''
                }

                this.isLoading = true
                const formData = new FormData()
                if (this.form.postCategory == 0 && this.validateFile == false) {
                    this.form.file = ''
                    this.validateMedia = true
                }
                if (this.form.postCategory == 1 && this.validateFileReel == false) {
                    this.form.fileReel = ''
                    this.validateMediaReel = true
                }
                if (this.form.files.length) {
                    for (var i = 0; i < this.form.files.length; i++) {
                        let file = this.form.files[i]
                        formData.append('files[' + i + ']', file)
                    }
                    this.form.thumbnail = this.form.files[0].type.split('/')[0] == 'video' ? await this.dataUrlToFile(this.form.files[0], 'thumbnail.jpeg') : this.form.files[0]
                } else {
                    let fileHasThumb = this.form.postCategory == 0 ? this.form.file : this.form.fileReel
                    this.form.thumbnail = fileHasThumb !== '' && fileHasThumb.type.split('/')[0] == 'video' ? await this.dataUrlToFile(fileHasThumb, 'thumbnail.jpeg') : fileHasThumb
                }
                formData.append('file', this.form.file)
                formData.append('file_reel', this.form.fileReel)
                formData.append('thumbnail', this.form.thumbnail)
                formData.append('status', this.form.status)
                formData.append('content', this.form.content)
                formData.append('scheduled_time', this.form.scheduled_time)
                formData.append('hashtag', this.form.hashtag)
                formData.append('tag', this.form.tag.length == 0 ? [] : JSON.stringify(this.form.tag))
                formData.append('is_multiple_file', this.form.is_multiple_file)
                formData.append('post_category', this.form.postCategory)
                formData.append('reel_check', this.reelCheck)
                formData.append('feed_check', this.feedCheck)

                const { data } = await axios.post(route('api.posts.store'), formData)
                if (!data.status) {
                    return this.$toast.error(data.msg)
                }

                this.$toast.success(data.msg)
                if (this.form.status == 0) {
                    this.$inertia.visit('/post?tab=0')
                } else if (this.form.status == 1) {
                    this.$inertia.visit('/post?tab=1')
                } else {
                    this.$inertia.visit('/post?tab=2')
                }
            } catch (error) {
                // alert validate file
                // const err= error.response.data.file[0];
                // return  this.$toast.error(err);
            } finally {
                this.isLoading = false
            }
        },
        uploadNewImg: function () {
            this.$refs.inputFile.click()
            this.dataTag = []
            this.form.tag = []
        },
        uploadNewImgReel: function () {
            this.$refs.inputFileReel.click()
            this.dataTag = []
            this.form.tag = []
        },
        uploadImg: function () {
            this.dataTag = []
            this.form.tag = []
            this.$refs.inputFile.click()
        },
        uploadImgReel: function () {
            this.$refs.inputFileReel.click()
            this.dataTag = []
            this.form.tag = []
        },
        selectImg: async function (event) {
            const newFileChange = event.target.files[0] || ''
            if (!newFileChange) return false

            this.dataTag = []
            this.form.errors.file = null
            this.fileUpdate = newFileChange
            this.imgUpload = []
            this.imgUpload.push(URL.createObjectURL(this.fileUpdate))
            this.form.file = this.fileUpdate
            const checkFile = await this.checkAllFile(this.fileUpdate)
            if (checkFile == false) {
                return this.setError()
            }

            await this.checkFile(this.fileUpdate)
            await this.syncReelPost(this.fileUpdate)
        },
        selectImgReel: async function (event) {
            const newFileChange = event.target.files[0] || ''
            if (!newFileChange) return false

            this.dataTag = []
            this.form.errors.fileReel = null
            this.fileUpdateReel = newFileChange
            this.imgUploadReel = []
            this.imgUploadReel.push(URL.createObjectURL(this.fileUpdateReel))
            this.form.fileReel = this.fileUpdateReel
            const checkFileReel = await this.checkAllFileReel(this.fileUpdateReel)
            if (checkFileReel == false) {
                return this.setErrorReel()
            }

            await this.checkFileReel(this.fileUpdateReel)
            await this.syncFeedPost(this.fileUpdateReel)
        },

        checkFileReels(file) {
            const checkFormatFile = file.type.split('/')[0]
            if (checkFormatFile == 'video') {
                return true
            }
            return false
        },

        checkAllFile(file) {
            const fileFormat = file.name.split('.').pop()
            const checkFormatFile = file.type.split('/')[0]
            if (checkFormatFile == 'video') {
                if (fileFormat != 'mp4' && fileFormat != 'm4v') {
                    return false
                }
            }

            return true
        },
        checkAllFileReel(file) {
            const fileFormat = file.name.split('.').pop()
            const checkFormatFile = file.type.split('/')[0]
            if (checkFormatFile == 'video') {
                if (fileFormat != 'mp4' && fileFormat != 'm4v') {
                    return false
                }
            }
            return true
        },
        handleClose(tag) {
            this.form.hashtag.splice(this.form.hashtag.indexOf(tag), 1)
        },

        showInput() {
            this.inputVisible = true
            this.$nextTick((_) => {
                this.$refs.saveTagInput.$refs.input.focus()
            })
        },

        handleInputConfirm() {
            let inputValue = this.inputValueHashtag
            if (inputValue) {
                this.selectTag = ''
                this.form.hashtag.push(this.convertHashtag(inputValue))
            }
            this.inputValueHashtag = ''
        },
        convertHashtag: function (hashtag) {
            if (hashtag.charAt(0) != '#') {
                return '#' + hashtag
            }
            return hashtag
        },
        checkFile: async function (file) {
            const maxImageSize = 8192
            const maxVideoSize = 102400
            const checkFormatFile = file.type.split('/')[0]
            if (checkFormatFile == 'image') {
                this.validateFile = true
                this.fileType = file.type
                await this.checkFileSize(file.size, maxImageSize)
                this.checkImageUpload(file)
            } else if (checkFormatFile == 'video') {
                this.validateFile = true
                this.reelCheck = 1
                this.feedCheck = 1
                this.openDialogTag = false
                this.fileType = file.type
                await this.checkFileSize(file.size, maxVideoSize)
                this.checkVideoUpload(file)
            } else {
                this.openDialogTag = false
                this.setError()
            }
        },
        checkFileReel: async function (file) {
            const maxImageSize = 8192
            const maxVideoSize = 1024000
            const checkFormatFile = file.type.split('/')[0]
            if (checkFormatFile == 'image') {
                this.validateFileReel = true
                this.fileTypeReel = file.type
                await this.checkFileSizeReel(file.size, maxImageSize)
                // this.checkImageUploadReel(file)
            } else if (checkFormatFile == 'video') {
                this.validateFileReel = true
                this.feedCheck = 1
                this.reelCheck = 1
                this.openDialogTag = false
                this.fileTypeReel = file.type
                await this.checkFileSizeReel(file.size, maxVideoSize)
                this.checkVideoUploadReel(file)
            } else {
                this.openDialogTag = false
                this.setErrorReel()
            }
        },

        checkFileSize: function (size, sizeMax) {
            if (size / 1000 >= sizeMax) {
                this.setError()
            } else {
                this.fileError = false
            }
        },
        checkFileSizeReel: function (size, sizeMax) {
            if (size / 1000 >= sizeMax) {
                this.setErrorReel()
            } else {
                this.fileErrorReel = false
            }
        },

        async checkVideoUpload(file) {
            const url = URL.createObjectURL(file)
            const video = document.createElement('video')
            video.src = url
            let vm = this
            video.addEventListener('loadedmetadata', function () {
                if (
                    this.videoWidth > 1920 ||
                    this.videoWidth / this.videoHeight < 4 / 5 ||
                    this.videoWidth / this.videoHeight > 16 / 9
                ) {
                    vm.setError()
                }
            })

            const info = await getVideoInfo(file)
            if (info.duration < 3 || info.duration > 60) {
                this.setError()
            }
            if (info.frameRate < 23 && info.frameRate > 60) {
                this.setError()
            }
            this.validateMedia = false
        },
        async checkVideoUploadReel(file) {
            const url = URL.createObjectURL(file)
            const video = document.createElement('video')
            video.src = url
            let vm = this
            video.addEventListener('loadedmetadata', function () {
                if (
                    this.videoWidth > 1920 ||
                    this.videoWidth / this.videoHeight < 0.01 ||
                    this.videoWidth / this.videoHeight > 10
                ) {
                    vm.setErrorReel()
                }
            })

            const info = await getVideoInfo(file)
            if (info.duration < 3 || info.duration > 15 * 60) {
                this.setErrorReel()
            }
            if (info.frameRate < 23 && info.frameRate > 60) {
                this.setErrorReel()
            }
            this.validateMediaReel = false
            this.syncFeedPost(file)
        },
        async syncFeedPost(file) {
            const url = URL.createObjectURL(file)
            const video = document.createElement('video')
            video.src = url
            let vm = this
            video.addEventListener('loadedmetadata', function () {
                if (
                    this.videoWidth > 1920 ||
                    this.videoWidth / this.videoHeight < 4 / 5 ||
                    this.videoWidth / this.videoHeight > 16 / 9
                ) {
                    vm.feedCheck = 0
                }
            })

            const info = await getVideoInfo(file)
            if (info.duration < 3 || info.duration > 60) {
                this.feedCheck = 0
            }
            if (info.frameRate < 23 && info.frameRate > 60) {
                this.feedCheck = 0
            }
            this.validateMedia = false
            if (this.feedCheck == 1) {
                this.form.file = this.form.fileReel
            }
        },
        async syncReelPost(file) {
            const url = URL.createObjectURL(file)
            const video = document.createElement('video')
            video.src = url
            let vm = this
            video.addEventListener('loadedmetadata', function () {
                if (
                    this.videoWidth > 1920 ||
                    this.videoWidth / this.videoHeight < 0.01 ||
                    this.videoWidth / this.videoHeight > 10
                ) {
                    vm.reelCheck = 0
                }
            })

            const info = await getVideoInfo(file)
            if (info.duration < 3 || info.duration > 15 * 60) {
                this.reelCheck = 0
            }
            if (info.frameRate < 23 && info.frameRate > 60) {
                this.reelCheck = 0
            }
            this.validateMediaReel = false
            if (this.reelCheck == 1) {
                this.form.fileReel = this.form.file
            }
        },

        checkImageUpload(file) {
            this.reelCheck = 0
            var _URL = window.URL || window.webkitURL
            var file, img
            let vm = this

            if (this.form.postCategory == 1) {
                const checkFileReels = this.checkFileReels(this.fileUpdate)
                if (!checkFileReels) {
                    return vm.setError()
                }
            }

            img = new Image()
            img.onload = function () {
                const ratio = this.width / this.height
                if (!(ratio >= 0.8 && ratio <= 1.91)) {
                    vm.setError()
                } else if (this.width > 5000 || this.width < 320) {
                    vm.setError()
                } else if (this.height > 10000) {
                    vm.setError()
                } else {
                    vm.openTagBox.width = this.width
                    vm.openTagBox.height = this.height
                }
            }

            img.onerror = function () {
                vm.setError()
            }
            img.src = _URL.createObjectURL(file)
            this.validateMedia = false
        },

        async setError() {
            this.feedCheck = 0
            this.openDialogTag = false
            this.$toast.error('メディアファイルをアップ失敗しました。も一回選択してください')
            document.getElementById('file').value = ''
            return (this.validateFile = false)
        },
        async setErrorReel() {
            this.reelCheck = 0
            this.openDialogTag = false
            this.$toast.error('メディアファイルをアップ失敗しました。も一回選択してください')
            document.getElementById('fileReel').value = ''
            return (this.validateFileReel = false)
        },

        convertTag: function (tags) {
            const newTags = tags.map(function (tag) {
                if (tag.charAt(0) != '@') {
                    return '@' + tag
                }
                return tag
            })
            return newTags
        },

        convertTime(data) {
            const moth = data.getMonth() + 1
            let date = ''
            if (moth < 10) {
                date = data.getFullYear() + '-' + '0' + moth + '-' + data.getDate()
            } else {
                date = data.getFullYear() + '-' + moth + '-' + data.getDate()
            }
            const time = data.getHours() + ':' + data.getMinutes()
            const dateTime = date + ' ' + time
            return dateTime
        },

        errorSchedule(date) {
            const dateFormat = this.formatDateJA(date)
            this.messageSchedule = dateFormat + 'より後の日付を指定してください。'
        },

        formatDateJA(date) {
            if (date) {
                let day = date.getDate()
                let month = date.getMonth() + 1
                let year = date.getFullYear()
                let hour = date.getHours()
                let minute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()

                return `${year}年${month}月${day}日 ${hour}:${minute}`
            }
        },

        convertDateString(date) {
            if (this.form.status !== '1') {
                return ''
            } else {
                if (date) {
                    const dateTime = date.toISOString().split('.')[0].replace('T', ' ')
                    return dateTime.split(':')[0] + ':' + dateTime.split(':')[1]
                }
                return ''
            }
        },
        openSelectHashtag(e) {
            if (this.openTagBox.clicked) {
                var rect = e.target.getBoundingClientRect()
                var x = e.clientX - rect.left //x position within the element.
                var y = e.clientY - rect.top //y position within the element.
                this.openTagBox.status = true
                this.openTagBox.left = x
                this.openTagBox.top = y
            }
        },
        closeHashtag(data) {
            this.form.tag.push({
                username: data.username,
                x: data.x,
                y: data.y,
            })
            this.dataTag.push({
                username: data.username,
                x: data.x,
                y: data.y,
            })
            this.openTagBox.status = false
        },
        removeHashtag(index) {
            this.form.tag.splice(index, 1)
            this.dataTag.splice(index, 1)
        },
        handler() {
            if (event.keyCode == 27) {
                this.openTagBox.status = false
            }
        },
        // link instagram
        async checkLinkInstagram() {
            const check = await accountAPI.checkInsAccount()
            if (check.data) {
                this.showLinkInstagram = true
            } else {
                this.showLinkInstagram = false
            }
        },
        handleUploadFile(file) {
            if (file) {
                this.form.files.push(file)
            }
        },
        removeFile(index) {
            this.form.files.splice(index, 1)
        },
        cusDatePickerText() {
            let datePicker = document.getElementsByClassName('cusDatePickerCreatePost')

            let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0]
            const preYearNode = document.createTextNode('<<前年')
            btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0])
            btnPreYear.style.cssText += 'margin: 0 5px 0 0'

            let btnPreMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[1]
            const preMonthNode = document.createTextNode('<前月')
            btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0])
            btnPreMonth.style.cssText += 'margin: 0'

            let btnNextYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[4]
            const nextYearNode = document.createTextNode('翌年>>')
            btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0])
            btnNextYear.style.cssText += 'margin: 0 0 0 5px'

            let btnNextMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[5]
            const nextMonthNode = document.createTextNode('翌月>')
            btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0])
            btnNextMonth.style.cssText += 'margin: 0'
        },
    },
    async mounted() {
        window.addEventListener('keyup', this.handler)
        window.addEventListener('click', (e) => {
            if (e.target.className == 'el-overlay-dialog') {
                this.openTagBox.status = false
            }
        })
        // this.date.setMinutes(this.date.getMinutes() + 30)
        const {
            data: { data },
        } = await userAPI.getUserDetail(this.userId)
        this.userName = data.account_name
        this.profilePictureUrl = data.profile_picture_url
        this.checkLinkInstagram()
        this.cusDatePickerText()
    },
    watch: {
        'form.status'(value) {
            if (value == 1) {
                const date = new Date()
                date.setMinutes(date.getMinutes() + 30)
                return (this.date = date)
            }
        },
        'form.postCategory'(value) {
            if (value == 1) {
                this.multiCheck = this.form.is_multiple_file
                if (this.fileUpdate && this.reelCheck == 1 && this.feedCheck == 1) {
                    this.fileReel = this.file
                    this.imgUploadReel = this.imgUpload
                    this.fileTypeReel = this.fileType
                    this.validateFileReel = true
                }
                this.form.is_multiple_file = 0
            } else {
                if (this.fileUpdateReel && this.reelCheck == 1 && this.feedCheck == 1) {
                    this.file = this.fileReel
                    this.imgUpload = this.imgUploadReel
                    this.fileType = this.fileTypeReel
                    this.validateFile = true
                }
                this.form.is_multiple_file = this.multiCheck
            }
        },

        date(value) {
            const date = new Date()
            date.setMinutes(date.getMinutes() + 29)
            if (value < date) {
                this.validateSchedule = true
                this.errorSchedule(date)
            } else {
                this.validateSchedule = false
            }
        },
    },

    destroyed() {
        window.removeEventListener('keyup', this.handler)
        window.removeEventListener('click')
    },
}
</script>
<style>
.custom-select .input-new-tag {
    width: 50%;
}

.input-new-tag .el-input__inner {
    box-shadow: none !important;
    padding: 0 !important;
}

.custom-select .el-tag {
    margin-right: 8px;
}
</style>
<style lang="scss" scoped>
.post-create-form label {
    font-size: 13px;
}

.tooltip {
    align-items: center;
    width: 20px;
    position: relative;
    display: flex;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 542px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    margin-left: -260px;
    font-size: 12px;
}

.tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.gird-two-column {
    grid-template-columns: max-content auto;
    gap: 45px 40px;

    input::placeholder {
        opacity: 0.8;
    }

    .form {
        label {
            margin-left: 13px;
            margin-right: 46px;
        }
    }
}

.custom-icon-question .tooltip {
    margin-left: -32px;
}

.leading-none {
    line-height: 1;
}

.tag-flag {
    &::before {
        content: '';
        width: 0px;
        height: 0px;
        position: absolute;
        border-bottom: 10px solid #d1d5db;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        top: -5px;
        left: 10px;
        margin: 0 auto;
    }
}

@media (max-width: 640px) {
    .gird-two-column {
        grid-template-columns: max-content auto;
        gap: 45px 40px;

        input::placeholder {
            opacity: 0.8;
        }

        .form {
            label {
                margin-left: 5px;
                margin-right: 46px;
            }
        }
    }
}

.custom-select {
    padding: 5px 15px;
    background-color: var(--el-input-bg-color, var(--el-fill-color-blank));
    background-image: none;
    border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
    box-sizing: border-box;
    color: var(--el-input-text-color, var(--el-text-color-regular));
    font-size: inherit;
    transition: var(--el-transition-box-shadow);
    width: 100%;
    box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;
    border: none;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 0;
}
</style>
<style lang="scss">
.picker-style {
    .el-input {
        .el-input__inner {
            padding-left: 35px !important;
        }
    }
}
</style>
