<script setup>
import BreezeButton from '@/Components/Button.vue';
import BreezeGuestLayout from '@/Layouts/Guest.vue';
import BreezeInput from '@/Components/Input.vue';
import BreezeValidationErrors from '@/Components/ValidationErrors.vue';
import {Head, useForm} from '@inertiajs/inertia-vue3';


defineProps({
    status: String,
});

const form = useForm({
    email: '',
});

const submit = () => {
    form.post(route('password.email'));
};
</script>

<template>
    <BreezeGuestLayout>
        <Head title="Forgot Password"/>

        <div class="mb-7 flex items-center justify-center text-base text-gray-600">
            {{$t('if_you_have_forgotten_your_password_please_enter_the_email_below')}}
        </div>

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>


        <form @submit.prevent="submit">
            <div class="flex items-center justify-center">
                <BreezeInput id="email" type="email" class="block w-96 rounded-sm p-3" v-model="form.email" required
                             autofocus autocomplete="username" :placeholder="$t('please_enter_your_email')"/>
            </div>

            <BreezeValidationErrors class="my-3"/>

            <div class="flex items-center justify-center mt-8">
                <BreezeButton class="bg-indigo-500 hover:bg-indigo-700 pt-5 pb-5 pl-16 pr-16 rounded-sm"
                              :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    {{$t('reset')}}
                </BreezeButton>
            </div>
        </form>
    </BreezeGuestLayout>
</template>
