<template>
  <div class="bg-background el__input-height">
    <div class="sm:px-5 lg:px-10 md:px-0">
      <div class="flex flex-row md:justify-between sm:justify-between md:items-center pt-5">
        <div class="py-4">
          <p class="text-button_pink font-bold !text-[14px]">アカウント分析</p>
        </div>
        <div class="flex justify-end">
          <button
            :disabled="numberExport <= 0 && isRole !== 'master'"
            @click="this.$emit('exportPdf')"
            class="md:mr-10 sm:mr-2 md:py-3 md:px-7 sm:px-5 sm:py-2 sm:text-xs text-white md:text-[14px] button-shadow rounded"
            style="background-color: #5264cd"
          >
            PDF出力
          </button>

          <button
            v-if="isRole == 'master'"
            :disabled="numberExportAccount <= 0 && isRole !== 'master'"
            @click="exportAccountCsv"
            class="bg-black md:py-3 md:px-7 sm:px-5 sm:py-2 sm:text-xs text-white md:text-[14px] button-shadow rounded"
          >
            CSV出力
          </button>

          <el-tooltip
            v-else
            class="tooltip-customer"
            effect="dark"
            :content="'アカウント分析画面今月後 ' + numberExportAccount + ' 回CSV出力出来ます。'"
            placement="top"
          >
            <button
              :disabled="numberExportAccount <= 0 && isRole !== 'master'"
              @click="exportAccountCsv"
              class="bg-black md:py-3 md:px-7 sm:px-5 sm:py-2 sm:text-xs text-white md:text-[14px] button-shadow rounded"
            >
              CSV出力
            </button>
          </el-tooltip>
        </div>
      </div>
      <div class="flex md:flex-row sm:flex-col justify-between tabs export-preview">
        <ul class="flex">
          <li
            class="!text-2sm pr-4 relative pb-5 px-sm"
            :class="{ active: current_tab == 'tab-1' }"
            @click="current_tab = 'tab-1'"
          >
            基本サマリー
          </li>
          <li
            class="!text-2sm px-6 relative pb-5 px-sm"
            :class="{ active: current_tab == 'tab-2' }"
            @click="current_tab = 'tab-2'"
          >
            フォロワーサマリー
          </li>
          <li
            class="!text-2sm px-6 relative pb-5 px-sm"
            :class="{ active: current_tab == 'tab-3' }"
            @click="current_tab = 'tab-3'"
          >
            インサイトサマリー
          </li>
        </ul>
        <div class="flex items-center md:flex-nowrap sm:flex-wrap">
          <span class="mr-4 !text-2sm">指定期間</span>
          <div v-if="analyticType !== 'year'" :id="accountRangeDateTimeID">
            <el-date-picker
              v-model="range"
              type="daterange"
              range-separator="-"
              start-placeholder="Start date"
              end-placeholder="End date"
              :disabled-date="disabledDate"
              :clearable="false"
              class="sm:my-2 md:my-0"
              popper-class="customDatePickerAccExport"
            />
          </div>
        </div>
      </div>
      <div class="">
        <Basic class="mt-5" :range="range" :accountTables="accountTables" v-if="current_tab == 'tab-1'" />
        <Follower class="mt-5" :range="range" :accountTables="accountTables" v-if="current_tab == 'tab-2'" />
        <Detail class="mt-5" :range="range" :accountTables="accountTables" v-if="current_tab == 'tab-3'" />
      </div>
      <div class="rounded">
        <div class="tabs flex items-center sm:justify-center md:justify-between bg-white p-5">
          <ul class="flex">
            <li
              class="pr-4 relative pb-2 px-sm !text-2sm"
              :class="{ active: lineTab == 'all' }"
              @click.prevent="showLineChart('all')"
            >
              推移
            </li>
            <li
              class="px-6 relative pb-2 px-sm !text-2sm"
              :class="{ active: lineTab == 'followers' }"
              @click.prevent="showLineChart('followers')"
            >
              フォロワー推移
            </li>
            <li
              class="px-6 relative pb-2 px-sm !text-2sm"
              :class="{ active: lineTab == 'interactives' }"
              @click.prevent="showLineChart('interactives')"
            >
              エンゲージメント推移
            </li>
          </ul>
        </div>
        <LineChart
          class="p-4"
          :range="range"
          :year="year"
          :showLine="lineTab"
          :analyticType="analyticType"
          :canvasID="accountLineChartID"
        />
        <div class="my-4">
          <div class="items-center text-option font-semibold mb-4 m-1">
            <p class="!text-2sm font-bold text-button_pink">フォロワー属性</p>
          </div>
        </div>
        <div class="flex sm:justify-center md:justify-between sm:flex-wrap md:flex-nowrap max-w-full overflow-auto">
          <div class="border border-solid border-[#DADADA] bg-white rounded w-full mb-2">
            <PieChart :canvasID="accountPieChartID" :dataGenderForChart="dataGenderForChart" />
          </div>
          <div class="border border-solid border-[#DADADA] bg-white rounded w-full mb-2 sm:mx-0 md:mx-5">
            <StackBarChart :canvasID="accountStackBarChartID" :dataGenderForChart="dataGenderForChart" />
          </div>
          <div class="border border-solid border-[#DADADA] bg-white rounded w-full mb-2">
            <HorizontalChart :canvasID="accountHorizontalChartID" :dataCityForChart="dataCityForChart" />
          </div>
        </div>
        <div class="hash_analysis mt-4 rounded">
          <div class="items-center text-option !text-2sm font-semibold mb-2">
            <p class="font-bold text-button_pink">ハッシュタグ分析</p>
          </div>
          <Hashtag
            :range="range"
            :year="year"
            :analyticType="analyticType"
            @getAccountHashtagTable="getAccountHashtagTableData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from '@/Layouts/Authenticated.vue'
import Notification from '@/Components/Notifications.vue'
import Table from '@/Components/Table.vue'
import Datepicker from '@/Components/Datepicker.vue'
import LineChart from './LineChart.vue'
import PieChart from './PieChart.vue'
import StackBarChart from './StackBarChart.vue'
import HorizontalChart from './HorizonBarChat/Basic.vue'
import { Link } from '@inertiajs/inertia-vue3'
import Basic from './Tables/Basic.vue'
import Follower from './Tables/Follower.vue'
import Hashtag from './Tables/Hashtag.vue'
import Detail from './Tables/Detail.vue'
import * as accountAPI from '@/Api/Account'
import BreadCrumb from '@/Components/Breadcrumb.vue'
import { fetchNumberExport, addNumberExport } from '../../Mixins/limitExport.js'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js'
import dayjs from 'dayjs'

ChartJS.register(Title, Tooltip, Legend, LineElement, ArcElement, BarElement, CategoryScale, LinearScale, PointElement)
export default {
  name: 'Index.vue',
  components: {
    BreadCrumb,
    Dashboard,
    Notification,
    LineChart,
    PieChart,
    StackBarChart,
    HorizontalChart,
    Table,
    Datepicker,
    Link,
    Basic,
    Follower,
    Hashtag,
    Detail,
  },
  emits: ['onChangeRangeAccount', 'getAccountTableData', 'mapAccountHashtagTableData'],
  props: {
    accountRangeDateTimeID: { type: String },
    accountLineChartID: { type: String },
    accountPieChartID: { type: String },
    accountStackBarChartID: { type: String },
    accountHorizontalChartID: { type: String },
    userId: { type: Number },
    numberExport: { type: Number },
    plan: { type: Object },
  },

  computed: {
    isRole() {
      return this.$page.props?.auth?.user?.roles[0]?.name
    },
  },

  data() {
    return {
      loading: false,
      analytic_type_options: [
        { label: '週間', value: 'week' },
        { label: 'ヶ月', value: 'month' },
        { label: '年', value: 'year' },
      ],
      range: [],
      year: new Date(),
      analyticType: 'month',
      lineTab: 'all',
      current_tab: 'tab-1',
      accountInsights: [],
      accountTables: [],
      calculation: {},
      numberExportAccount: 0,
      dataGenderForChart: {},
      dataCityForChart: {},
    }
  },
  watch: {
    range() {
      this.fetchData()
      this.$emit('onChangeRangeAccount', this.range)
      // this.$emit("onChangeRangeAccountHashtag", this.range);
    },
    year() {
      this.fetchData()
    },
    analyticType() {
      // check rang is year, month, week
      if (this.analyticType == 'year') {
        this.year = new Date()
      }
      if (this.analyticType == 'month') {
        this.range = this.getDateOfMonth(30)
      }
      if (this.analyticType == 'week') {
        this.range = this.getDateOfMonth(6)
      }
    },
  },
  created() {
    this.range = this.getDateOfMonth(30)
    this.fetchNumberExportMonth()
  },
  mounted() {
    this.customDatePickerText()
  },
  methods: {
    getAccountHashtagTableData: function (data) {
      this.$emit('mapAccountHashtagTableData', data)
    },

    // fetch data
    async fetchData() {
      this.getFollowDataTable()
      this.getAccountChartData()
      this.fetchNumberExportMonth()
    },
    // get account chart data
    async getAccountChartData() {
      let start_at = this.range[0]
      let end_at = this.range[1]
      if (start_at && end_at) {
        start_at = dayjs(start_at).format('YYYY-M-D')
        end_at = dayjs(end_at).format('YYYY-M-D')
        const response = await accountAPI.getMultipleAccountData(start_at, end_at, this.analyticType)
        this.accountInsights = response.status === 200 ? response.data?.accountInsights : []
        this.calculation = response.status === 200 ? response.data?.calculation : {}
        this.dataGenderForChart = response.status === 200 ? response.data?.dataGenderForChart : {}
        this.dataCityForChart = response.status === 200 ? response.data?.dataCityForChart : {}
      }
    },
    // get followers dataTable
    async getFollowDataTable() {
      let start_at = this.range[0]
      let end_at = this.range[1]
      if (this.analyticType === 'year') {
        if (dayjs().year() === dayjs(this.year).year()) {
          start_at = dayjs(this.year).startOf('year').format('YYYY-M-D')
          end_at = dayjs().subtract(1, 'day').format('YYYY-M-D')
        } else {
          start_at = dayjs(this.year).startOf('year').format('YYYY-M-D')
          end_at = dayjs(this.year).endOf('year').format('YYYY-M-D')
        }
      } else {
        start_at = dayjs(this.range[0]).format('YYYY-M-D')
        end_at = dayjs(this.range[1]).format('YYYY-M-D')
      }
      const responseTable = await accountAPI.getFollowSummary(start_at, end_at, this.analyticType)
      this.accountTables = responseTable?.data
      this.$emit('getAccountTableData', this.accountTables)
    },
    //get follower change
    followerChange() {
      const lastDaySelected = this.accountTables?.lastDaySelected?.followers ?? 0
      const lastDayPrevious = this.accountTables?.lastDayPrevious?.followers ?? 0
      const followers = lastDaySelected - lastDayPrevious
      return followers > 0 ? '+' + followers : followers
    },

    // get date of month
    getDateOfMonth(day) {
      const date = []
      date[0] = new Date(new Date().setDate(new Date().getDate() - day))
      date[1] = new Date(new Date().setDate(new Date().getDate() - 1))
      return date
    },
    // disabledDate
    disabledDate(time) {
      let d = new Date()
      return time.getTime() > d.setDate(d.getDate() - 1)
    },
    // show line chart
    showLineChart(type) {
      this.lineTab = type
    },

    async exportAccountCsv() {
      if (this.numberExportAccount === 0) {
        return
      }
      let checkSuccess = false
      try {
        const userId = this.userId ? this.userId : this.$page?.props?.auth?.user?.id
        const article = {
          user_id: userId,
          start_time: dayjs(this.range['0']).format('YYYY-MM-DD'),
          end_time: dayjs(this.range['1']).format('YYYY-MM-DD'),
        }
        const url =
          route('api.export.account.csv') +
          '?user_id=' +
          userId +
          '&start_time=' +
          article.start_time +
          '&end_time=' +
          article.end_time
        window.location.href = url

        checkSuccess = true

        if (checkSuccess && this.isRole !== 'master') {
          addNumberExport('csv', 'csv_account')
          this.fetchNumberExportMonth()
        }
      } catch (error) {
        console.log(error)
      }
    },

    async fetchNumberExportMonth() {
      const { data } = await fetchNumberExport('csv', 'csv_account')
      this.numberExportAccount = this.plan?.number_report_csv - data
    },
    customDatePickerText() {
      let datePicker = document.getElementsByClassName('customDatePickerAccExport')
        
      let btnPreYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[0]
      const preYearNode = document.createTextNode("<<前年");
      btnPreYear.replaceChild(preYearNode, btnPreYear.childNodes[0]);
      btnPreYear.style.cssText += 'margin: 0 5px 0 0';
      
      let btnPreMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[1].childNodes[0].childNodes[1]
      const preMonthNode = document.createTextNode("<前月");
      btnPreMonth.replaceChild(preMonthNode, btnPreMonth.childNodes[0]);
      btnPreMonth.style.cssText += 'margin: 0';

      let btnNextYear = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[2]
      const nextYearNode = document.createTextNode("翌年>>");
      btnNextYear.replaceChild(nextYearNode, btnNextYear.childNodes[0]);
      btnNextYear.style.cssText += 'margin: 0 0 0 5px';
      
      let btnNextMonth = datePicker[0].childNodes[6].childNodes[0].childNodes[3].childNodes[2].childNodes[0].childNodes[3]
      const nextMonthNode = document.createTextNode("翌月>");
      btnNextMonth.replaceChild(nextMonthNode, btnNextMonth.childNodes[0]);
      btnNextMonth.style.cssText += 'margin: 0';
    }
  },
}
</script>
<style lang="scss" scoped>
table {
  border-collapse: collapse;
}
.user-tags {
  border-radius: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.search_icon {
  padding: 10px 16px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #d02d92;
}
.select__status {
  background: #ffffff;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
}
@media (max-width: 640px) {
  .tabs {
    margin: 10px 0 0 0 !important;
  }
  .export-preview.tabs {
    li {
      font-size: 12px !important;
    }
    ul {
      margin-bottom: 2rem;
    }
  }
}
</style>
<style>
.el__input-height .el-input__inner {
  height: 40px !important;
}
</style>
