<template>
  <DrashBoard>
    <template v-slot:breakcumb>
      <BreadCrumb>
        <li class="mr-4">
          <Link :href="route('admin.plans.index')" class="font-normal">プラン管理</Link>
        </li>
        <span class="font-normal text-notice_date mx-2">></span>
        <li class="mr-4">
          <Link :href="route('admin.plans.create')" class="font-normal breakcumb-active">プラン新規登録</Link>
        </li>
      </BreadCrumb>
    </template>
    <template v-slot:tabs>
      <div class="mt-12 ml-8 tabs">
        <ul class="flex">
          <li class="px-2 relative pb-5 active">プラン新規登録</li>
        </ul>
      </div>
    </template>
    <Loading v-if="isLoading" />
    <div class="bg-background md:px-10 sm:px-5 pt-5">
      <h2 class="text-title text-button_pink font-semibold">プラン新規登録</h2>
      <form @submit.prevent="createPlan">
        <!-- Name Plan And Amount Plan-->
        <div class="flex md:flex-row sm:flex-col flex-wrap items-stretch pb-5 border-b">
          <div class="grid grid-cols-3 sm:gap-y-4 md:gap-y-6 gap-x-3 md:w-1/2 sm:w-full items-center">
            <label class="relative">
              プラン名
              <span class="text-red-500">*</span>
            </label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input
                type="text"
                v-model="form.name"
                class="py-4 w-full border-gray-200 input-select-date-custom"
                @change="form.errors.name = ''"
                :class="{ 'form-error': form.errors.name }"
                placeholder="プラン名を入力"
              />
              <div v-if="form.errors.name" class="text-red-600 mt-1 text-nomal text-nomal">
                {{ form.errors.name }}
              </div>
            </div>
            <label class="flex items-center">
              連携可能アカウント数
              <el-tooltip class="box-item ml-1" effect="light" content="最大連携可能のInstaアカウント数です。" placement="top">
                <el-icon class="ml-1" :size="16"><QuestionFilled /></el-icon>
              </el-tooltip>
            </label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input
                type="number"
                class="w-24 border-gray-200 text-center"
                v-model="form.number_accounts"
                min="1"
                @change="form.errors.number_accounts = ''"
                @input="updateNumberAccountsValue"
                :class="{ 'form-error': form.errors.number_accounts }"
              />
              <div v-if="form.errors.number_accounts" class="text-red-600 mt-1 text-nomal text-nomal">
                {{ form.errors.number_accounts }}
              </div>
            </div>
          </div>
          <div class="md:w-1/2 sm:w-full md:pl-10">
            <div class="flex py-4 mt-3 items-center vh-60">
              <label class="relative w-1/3">料金</label>
              <div class="flex items-center">
                <input v-model="form.type_amount" class="mr-2 border-gray-200" type="radio" :value="0" />
                <label class="lg:mr-7 mr-2 min-w-[30px]">無料</label>
              </div>
              <div class="flex items-center lg:pr-8 sm:pl-0 md:pl-4 lg:pl-0">
                <input v-model="form.type_amount" class="mr-2 ml-3 border-gray-200" type="radio" :value="1" />
                 <label class="lg:mr-10 mr-2 min-w-[30px]">料金</label>
              </div>
              <div class="flex-1 flex items-center" v-if="form.type_amount == 1">
                <input
                  type="number"
                  v-model="form.amount"
                  class="lg:w-24 w-14 lg:h-9 h-7 border-gray-200"
                  @change="form.errors.amount = ''"
                  @input="updateAmountValue"
                  :class="{ 'form-error': form.errors.amount }"
                />
                <span class="ml-4 text-nomal">円</span>
              </div>
              <div v-if="form.errors.amount" class="w-full flex text-red-600 mt-1 text-nomal leading-none">
                <div class="w-1/3"></div>
                {{ form.errors.amount }}
              </div>
            </div>

             <div class="flex py-4 mt-10 items-center vh-60">
              <label class="relative w-1/3">データ保有期間</label>
              <div class="flex items-center sm:pl-0 md:pl-4 lg:pl-0">
                <input v-model="form.type_storage_time" class="mr-2 border-gray-200" type="radio" :value="0" />
                <label class="lg:mr-7 mr-2 min-w-[40px]">無制限</label>
              </div>
              <div class="flex items-center lg:pr-8 sm:pl-0 md:pl-4 lg:pl-0">
                <input v-model="form.type_storage_time" class="mr-2 border-gray-200" type="radio" :value="1" />
                <label class="lg:mr-10 mr-2 min-w-[30px]">日数</label>
              </div>
              <div class="flex-1" v-if="form.type_storage_time == 1">
              <div class="flex">
                <input
                  type="number"
                  v-model="form.storage_time"
                  class="lg:py-2 lg:w-24 w-14 lg:h-9 h-7 border-gray-200"
                  min="1"
                  @change="form.errors.storage_time = ''"
                  @input="updateStoragTimeValue"
                  :class="{ 'form-error': form.errors.storage_time }"
                />
                <span class="ml-4 mt-2 text-nomal">日</span>
              </div>
                <div v-if="form.errors.storage_time" class="text-red-600 mt-1 text-nomal">
                  {{ form.errors.storage_time }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- analysis -->
        <div class="flex flex-wrap items-h-60">
          <!-- is_show_user -->
          <div class="grid grid-cols-1 gap-x-1 gap-y-1 pt-5 sm:w-full">
            <h2 class="text-title text-button_pink">{{ $t('range_to_display') }}</h2>
          </div>
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full lg:items-center"
          >
            <label class="flex items-center">
              {{ $t('is_show_user') }}
              <el-tooltip class="box-item ml-1" effect="light" :content="$t('tooltip_is_show_user')" placement="top">
                <el-icon class="ml-1" :size="16"><QuestionFilled /></el-icon>
              </el-tooltip>
            </label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.is_show_user" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.is_show_user"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- is_show_admin -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full md:pl-10 lg:items-center"
          >
            <label class="flex items-center">
              {{ $t('is_show_admin') }}
              <el-tooltip class="box-item ml-1" effect="light" :content="$t('tooltip_is_show_admin')" placement="top">
                <el-icon class="ml-1" :size="16"><QuestionFilled /></el-icon>
              </el-tooltip>
            </label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.is_show_admin" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.is_show_admin"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- cycle_months -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full lg:items-center"
          >
            <label class="flex items-center">
              {{ $t('cycle_months') }}
              <el-tooltip class="box-item ml-1" effect="light" :content="$t('tooltip_cycle_months')" placement="top">
                <el-icon class="ml-1" :size="16"><QuestionFilled /></el-icon>
              </el-tooltip>
            </label>
            <div class="col-span-2 sm:pl-8 sm:ml-0 md:pl-4 lg:pl-0">
              <input v-model="form.cycle_months" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>月数</label>
              <input
                v-model="form.cycle_months"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>無制限</label>
              <input
                type="number"
                v-if="form.cycle_months == 1"
                v-model="form.value_cycle_months"
                class="md:mr-4 sm:mr-0 md:mt-0 sm:mt-2 md:ml-5 w-24 border-gray-200"
                min="1"
                @input="valueCycleMonths"
              />
              <label v-if="form.cycle_months == 1">月</label>
              <div v-if="form.errors.value_cycle_months" class="text-red-600 mt-1 text-nomal">
                {{ form.errors.value_cycle_months }}
              </div>
            </div>
          </div>
          <!-- renewal_cycle_months -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full md:pl-10 lg:items-center"
            v-if="form.cycle_months == 1"
          >
            <label class="flex items-center">
              {{ $t('renewal_cycle_months') }}
              <el-tooltip
                class="box-item ml-1"
                effect="light"
                :content="$t('tooltip_renewal_cycle_months')"
                placement="top"
              >
                <el-icon class="ml-1" :size="16"><QuestionFilled /></el-icon>
              </el-tooltip>
            </label>
            <div class="col-span-2 sm:pl-8 sm:ml-0 md:pl-4 lg:pl-0">
              <input v-model="form.renewal_cycle_months" class="mr-4 border-gray-200" type="radio" :value="1" />
              <!--              <input v-model="form.renewal_cycle_months" class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200" type="radio" :value="0" />-->
              <!--              <label>無制限</label>-->
              <input
                type="number"
                v-if="form.renewal_cycle_months == 1"
                v-model="form.value_renewal_cycle_months"
                class="md:mr-4 sm:mr-0 md:mt-0 sm:mt-2 md:ml-5 w-24 border-gray-200"
                min="1"
                @input="valueRenewalCycleMonths"
              />
              <label>ヶ月ごと</label>
              <div v-if="form.errors.value_renewal_cycle_months" class="text-red-600 mt-1 text-nomal">
                {{ form.errors.value_renewal_cycle_months }}
              </div>
            </div>
          </div>
          <div
            v-else
            class="grid grid-cols-3 md:border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full md:pl-10 lg:items-center"
          ></div>
          <!-- creative_analysis -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full lg:items-center"
          >
            <label>{{ $t('creative_analysis') }}</label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.creative_analysis" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.creative_analysis"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- reserved_post_function -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full md:pl-10 lg:items-center"
          >
            <label>{{ $t('reserved_post_function') }}</label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.reserved_post_function" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.reserved_post_function"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- comment_analysis -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full lg:items-center"
          >
            <label>{{ $t('comment_analysis') }}</label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.comment_analysis" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.comment_analysis"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- reel_analysis -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full md:pl-10 lg:items-center"
          >
            <label>{{ $t('reel_analysis') }}</label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.reel_analysis" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.reel_analysis"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- initial_velocity_analysis -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full lg:items-center"
          >
            <label>{{ $t('initial_velocity_analysis') }}</label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.initial_velocity_analysis" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.initial_velocity_analysis"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- account_analysis -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full md:pl-10 lg:items-center"
          >
            <label>アカウント分析</label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.account_analysis" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.account_analysis"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- post_hashtag -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full lg:items-center"
          >
            <label>{{ $t('post_hashtag') }}</label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.post_hashtag" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.post_hashtag"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- post_analysis -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full md:pl-10 lg:items-center"
          >
            <label>投稿分析</label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.post_analysis" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.post_analysis"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- co_opetition_analysis -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full lg:items-center"
          >
            <label class="flex items-center">
              競合分析
              <el-tooltip
                class="box-item ml-1"
                effect="light"
                :content="$t('tooltip_co_opetition_analysis')"
                placement="top"
              >
                <el-icon class="ml-1" :size="16"><QuestionFilled /></el-icon>
              </el-tooltip>
            </label>
            <div class="col-span-2 sm:pl-8 sm:ml-0 md:pl-4 lg:pl-0">
              <input v-model="form.co_opetition_analysis" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.co_opetition_analysis"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
              <input
                type="number"
                v-if="form.co_opetition_analysis == 1"
                v-model="form.number_account_competition"
                class="md:mr-4 sm:mr-0 md:mt-0 sm:mt-2 w-24 border-gray-200 px-4"
                min="1"
                @input="numberAccountCompetitionValue"
              />
              <label class="ml-3 mr-2" v-if="form.co_opetition_analysis == 1">最大</label>
              <div v-if="form.errors.number_account_competition" class="text-red-600 mt-1 text-nomal">
                {{ form.errors.number_account_competition }}
              </div>
            </div>
          </div>
          <!-- advertising_analysis -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full md:pl-10 lg:items-center"
          >
            <label>広告分析</label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.advertising_analysis" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.advertising_analysis"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- user_analysis -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full lg:items-center"
          >
            <label>時間・曜日別分析</label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.user_analysis" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.user_analysis"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- Story manager -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full md:pl-10 lg:items-center"
          >
            <label>ストーリーズ分析</label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.story_analysis" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.story_analysis"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- giới hạn số lần xuất PowerPoint  -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full lg:items-center"
          >
            <label class="flex items-center whitespace-pre-line">
              レポート作成 (PowerPoint)
              <el-tooltip class="box-item ml-1" effect="light" :content="$t('tooltip_report_pptx')" placement="top">
                <el-icon class="ml-1" :size="16"><QuestionFilled /></el-icon>
              </el-tooltip>
            </label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.report_pptx" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.report_pptx"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
            <label
              class="flex sm:w-[130%] md:w-full sm:mr-20 md:mr-0 items-center whitespace-pre-line"
              v-if="form.report_pptx == 1"
            >
              月間のダウンロード回数
            </label>
            <input
              v-if="form.report_pptx == 1"
              v-model="form.number_report_pptx"
              class="col-span-2 border-gray-200 w-24 sm:ml-8 md:ml-0"
              min="1"
              @input="updateNumberReportPptx"
              type="number"
            />
          </div>
          <!-- giới hạn số lần xuất PDF  -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full md:pl-10 lg:items-center"
          >
            <label class="flex items-center whitespace-pre-line">
              レポート作成 (PDF)
              <el-tooltip class="box-item ml-1" effect="light" :content="$t('tooltip_report_pdf')" placement="top">
                <el-icon class="ml-1" :size="16"><QuestionFilled /></el-icon>
              </el-tooltip>
            </label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.report_pdf" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.report_pdf"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
              <div v-if="form.errors.number_report_pdf" class="text-red-600 mt-1 text-nomal">
                {{ form.errors.number_report_pdf }}
              </div>
            </div>
            <label class="sm:w-[130%] md:w-full flex items-center whitespace-pre-line" v-if="form.report_pdf == 1">
              月間のダウンロード回数
            </label>
            <input
              v-if="form.report_pdf == 1"
              v-model="form.number_report_pdf"
              class="col-span-2 border-gray-200 w-24 sm:ml-8 md:ml-0"
              min="1"
              @input="updateNumberReportPDF"
              type="number"
            />
          </div>
          <!-- giới hạn số lần xuất CSV  -->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full lg:items-center"
          >
            <label class="flex items-center whitespace-pre-line">
              レポート作成 (CSV)
              <el-tooltip class="box-item ml-1" effect="light" :content="$t('tooltip_report_csv')" placement="top">
                <el-icon class="ml-1" :size="16"><QuestionFilled /></el-icon>
              </el-tooltip>
            </label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.report_csv" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.report_csv"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
              <div v-if="form.errors.number_report_csv" class="text-red-600 mt-1 text-nomal">
                {{ form.errors.number_report_csv }}
              </div>
            </div>
            <label class="sm:w-[130%] md:w-full flex items-center whitespace-pre-line" v-if="form.report_csv == 1">
              月間のダウンロード回数
            </label>
            <input
              v-if="form.report_csv == 1"
              v-model="form.number_report_csv"
              class="col-span-2 border-gray-200 w-24 sm:ml-8 md:ml-0"
              min="1"
              @input="updateNumberReportCSV"
              type="number"
            />
          </div>
          <!-- business_analysis-->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full md:pl-10 lg:items-center"
          >
            <label>エンタープライズ機能</label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.business_analysis" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.business_analysis"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
          <!-- hashtag_analysis-->
          <div
            class="grid grid-cols-3 border-b border-gray-200 gap-x-3 gap-y-6 py-5 md:w-1/2 sm:w-full lg:items-center"
          >
            <label>指定ハッシュタグ分析</label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <input v-model="form.hashtag_analysis" class="mr-4 border-gray-200" type="radio" :value="1" />
              <label>はい</label>
              <input
                v-model="form.hashtag_analysis"
                class="md:mr-4 md:ml-10 sm:mr-2 sm:ml-4 border-gray-200"
                type="radio"
                :value="0"
              />
              <label>いいえ</label>
            </div>
          </div>
        </div>
        <!-- note-->
        <div class="py-3">
          <div class="grid grid-cols-3 gap-x-3 md:w-1/2 sm:w-full items-center">
            <label class="relative">
              説明
              <span class="text-red-500">*</span>
            </label>
            <div class="col-span-2 sm:pl-8 md:pl-4 lg:pl-0">
              <textarea
                type="text"
                placeholder="説明を入力"
                v-model="form.note"
                class="mt-1 w-full input-select-date-custom"
                @change="form.errors.note = ''"
                :class="{ 'form-error': form.errors.note }"
              />
              <div v-if="form.errors.note" class="text-red-600 mt-1 text-nomal leading-none">
                {{ form.errors.note }}
              </div>
            </div>
          </div>
        </div>
        <!-- submit-->
        <div class="flex justify-center pt-16.3 pb-64">
          <button
            class="py-5 bg-button_pink rounded w-72 px-5 text-white button-shadow text-title"
            @click.prevent="goBackPlan"
          >
            取り消し
          </button>

          <button
            class="md:py-5 sm:py-3 bg-blue-600 ml-6 w-72 px-5 text-white button-shadow rounded text-title"
            type="submit"
          >
            プランを登録する
          </button>
        </div>
      </form>
    </div>
  </DrashBoard>
</template>

<script>
import DrashBoard from '@/Layouts/Authenticated.vue'
import Notification from '@/Components/Notifications.vue'
import { Link, useForm } from '@inertiajs/inertia-vue3'
import { Back, QuestionFilled } from '@element-plus/icons-vue'
import Loading from '@/Components/Loadding'
import BreadCrumb from '@/Components/Breadcrumb.vue'
export default {
  props: {
    status: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      form: useForm({
        name: '',
        number_accounts: 1,
        amount: 50,
        storage_time: 0,
        type_amount: 1,
        type_storage_time: 0,
        account_analysis: 0,
        post_analysis: 0,
        co_opetition_analysis: 0,
        advertising_analysis: 0,
        user_analysis: 0,
        report_analysis: 0,
        business_analysis: 0,
        story_analysis: 0,
        hashtag_analysis: 0,
        number_account_competition: 10,
        report_pptx: 0,
        number_report_pptx: 2,
        report_pdf: 0,
        number_report_pdf: 5,
        report_csv: 0,
        number_report_csv: 5,
        note: '',
        trial_days: 0, //default
        currency: 'jpy', //default
        interval: 'month', //default
        cycle_months: 0,
        value_cycle_months: 0,
        renewal_cycle_months: 1,
        value_renewal_cycle_months: 1,
        reserved_post_function: 0,
        creative_analysis: 0,
        post_hashtag: 0,
        comment_analysis: 0,
        reel_analysis: 0,
        initial_velocity_analysis: 0,
        is_show_user: 0,
        is_show_admin: 0,
      }),
      isLoading: false,
    }
  },

  components: {
    DrashBoard,
    Notification,
    Link,
    Back,
    QuestionFilled,
    useForm,
    Loading,
    BreadCrumb,
  },

  methods: {
    goBackPlan() {
      return this.$inertia.visit(route('admin.plans.index'))
    },
    createPlan() {
      this.isLoading = true
      this.form.amount = this.form.type_amount == 0 ? 0 : this.form.amount
      this.form.storage_time = this.form.type_storage_time == 0 ? 0 : this.form.storage_time
      this.form.number_account_competition =
        this.form.co_opetition_analysis == 1 ? this.form.number_account_competition : 0

      this.form.post(route('api.plans.store'), {
        onFinish: () => {
          if (!this.form.hasErrors) {
            this.$toast.success(this.$t('Create plan successfully'))
            this.$inertia.get(route('admin.plans.index'))
            this.isLoading = false
          } else {
            //   this.$toast.error(this.$t('Create plan error'))
            this.isLoading = false
          }
        },
      })
    },
    updateAmountValue(event) {
      const value = event.target.value
      var num = value.match(/^[0-9]+$/)
      if (num === null) {
        this.form.amount = null
      } else {
        this.form.amount = Math.abs(value.slice(0, 7))
      }
    },
    updateStoragTimeValue(event) {
      const value = event.target.value
      var num = value.match(/^[0-9]+$/)
      if (num === null) {
        this.form.storage_time = null
      } else {
        this.form.storage_time = Math.abs(value.slice(0, 7))
      }
    },
    numberAccountCompetitionValue(event) {
      const value = event.target.value
      var num = value.match(/^[0-9]+$/)
      if (num === null) {
        this.form.number_account_competition = null
      } else {
        this.form.number_account_competition = Math.abs(value.slice(0, 7))
      }
    },
    updateNumberAccountsValue(event) {
      const value = event.target.value
      var num = value.match(/^[0-9]+$/)
      if (num === null) {
        this.form.number_accounts = null
      } else {
        this.form.number_accounts = Math.abs(value.slice(0, 7))
      }
    },
    updateNumberReportPptx(event) {
      const value = event.target.value
      var num = value.match(/^[0-9]+$/)
      if (num === null) {
        this.form.number_report_pptx = null
      } else {
        this.form.number_report_pptx = Math.abs(value.slice(0, 7))
      }
    },
    valueCycleMonths(event) {
      const value = event.target.value
      var num = value.match(/^[0-9]+$/)
      if (num === null) {
        this.form.value_cycle_months = null
      } else {
        this.form.value_cycle_months = Math.abs(value.slice(0, 7))
      }
    },
    valueRenewalCycleMonths(event) {
      const value = event.target.value
      var num = value.match(/^[0-9]+$/)
      if (num === null) {
        this.form.value_renewal_cycle_months = null
      } else {
        this.form.value_renewal_cycle_months = Math.abs(value.slice(0, 7))
      }
    },
    updateNumberReportPDF(event) {
      const value = event.target.value
      var num = value.match(/^[0-9]+$/)
      if (num === null) {
        this.form.number_report_pdf = null
      } else {
        this.form.number_report_pdf = Math.abs(value.slice(0, 7))
      }
    },
    updateNumberReportCSV(event) {
      const value = event.target.value
      var num = value.match(/^[0-9]+$/)
      if (num === null) {
        this.form.number_report_csv = null
      } else {
        this.form.number_report_csv = Math.abs(value.slice(0, 7))
      }
    },
  },
}
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.items-h-90 > div,
.h-90 {
  height: 90px;
  align-items: center;
}
form label {
  font-size: 13px;
  white-space: initial;
}
@media (max-width: 820px) {
  .items-h-90 > div,
  .h-90 {
    height: unset;
  }
}
@media (max-width: 640px) {
  .pl-30-persent {
    padding-left: 33%;
  }
}
.tooltip-base-box {
  width: 600px;
}
.tooltip-base-box .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tooltip-base-box .center {
  justify-content: center;
}
.tooltip-base-box .box-item {
  width: 110px;
  margin-top: 10px;
}
</style>
