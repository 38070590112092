<template>
  <data-table
    :fields="fields"
    :items="base_infos"
    disable-table-info
    footer-center
    paginate-background
    class="el-table-header__black mt-7"
  >
    <template #followers="{ row }">
      <p v-if="row" class="font-bold">{{ row?.followers ? formatNumber(row.followers) : '-' }}</p>
    </template>
    <template #change_follows="{ row }">
      <p class="font-bold" :class="{ plus_active: followerChange(row) > 0, minus_active: followerChange(row) < 0 }">
        {{ followerChange(row) > 0 ? '+' + formatToInteger(followerChange(row)) : formatToInteger(followerChange(row)) }}
      </p>
    </template>
    <template #medias="{ row }">
      <p class="font-bold">{{ row?.medias ? formatNumber(row.medias) : '-' }}</p>
    </template>
  </data-table>
</template>

<script>
import DataTable from '@/Components/UI/DataTable.vue'

export default {
  components: { DataTable },
  props: {
    range: { type: Object, default: 0 },
    accountTables: { type: Object, default: {} },
    dataFirstDay: { type: Object, default: {} },
  },
  data() {
    return {
      base_infos: [],
      fields: [
        {
          key: 'followers',
          label: 'フォロワー数',
          minWidth:  300,
          align: 'center'
        },
        {
          key: 'change_follows',
          label: 'フォロワー純増減数',
          minWidth: 300,
          align: 'center',
        },
        {
          key: 'medias',
          label: 'フィード投稿数',
          minWidth: 300,
          align: 'center',
        },
      ],
    }
  },
  watch: {
    range: function () {
      this.fetchData()
    },
    accountTables: function () {
      this.fetchData()
    },
  },
  methods: {
    async fetchData() {
      this.base_infos = this.accountTables
    },
    //follower change
    followerChange(row) {
      const lastDaySelected = this.accountTables[0]?.followers ?? 0
      const lastDayPrevious = this.dataFirstDay?.followers ?? 0
      const followers = lastDaySelected - lastDayPrevious
      return followers
    },
    formatNumber(number) {
      if(!number) return

      const str = number.toString().split('.')
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return str.join('.')
    },
  },
}
</script>
<style>
.plus_active {
  color: #4a80d3;
}
.minus_active {
  color: #dc3545;
}
</style>
