import ImgToBase64Mixin from '../Mixins/imgToBase64Mixin'
import GetThumbnailUrl from '../../../Mixins/thumbnail.js'

export default {
  mixins: [ImgToBase64Mixin, GetThumbnailUrl],
  methods: {
    async genHeaderFooterPage(doc, pdfSetting) {
      const pageWidth = doc.internal.pageSize.getWidth()
      const pageHeight = doc.internal.pageSize.getHeight()
      let pageCount = doc.internal.getNumberOfPages() //Total Page Number
      for (i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text('Username', 90, 21, { baseline: 'center' })
        doc.text('Username', 90, 21, { baseline: 'center' })
        doc.text('Username', 90, 21, { baseline: 'center' })
        doc.text('Instagram ID', 130, 21, { baseline: 'center' })
        doc.text('Instagram ID', 130, 21, { baseline: 'center' })
        doc.text('Instagram ID', 130, 21, { baseline: 'center' })
        doc.text('Source', 180, 21, { baseline: 'center' })
        doc.text('Source', 180, 21, { baseline: 'center' })
        doc.text('Source', 180, 21, { baseline: 'center' })
        doc.text('Source', 180, 21, { baseline: 'center' })
        doc.setFontSize(9)
        doc.text(this.$page.props.auth.account.username, 90, 31, { baseline: 'center' })
        doc.text(this.$page.props.auth.account.business_id, 130, 31, { baseline: 'center' })
        doc.text(pdfSetting?.data?.name_source ? pdfSetting.data.name_source : 'Insta Cheki', 180, 31, {
          baseline: 'center',
        })
        doc.setFontSize(6)
        const footer = `Page ${i}/${pageCount}`
        doc.text(footer, 2, pageHeight - 2, { baseline: 'bottom' })
        let textCopyright =
          pdfSetting?.data?.text_copyright && pdfSetting?.data?.text_copyright !== null
            ? '© ' + pdfSetting.data.text_copyright
            : '©2022 Insta Cheki'
        doc.setFontSize(10)
        let textCopyrightWidth = doc.getTextWidth(textCopyright)
        doc.setFontSize(6)
        doc.text(textCopyright, (pageWidth - textCopyrightWidth) / 2, pageHeight - 2, { baseline: 'bottom' })
        doc.setFontSize(10)
        doc.addImage(
          pdfSetting?.data?.img_copyright_base
            ? String(
                await this.createFile(pdfSetting.data.img_copyright_base + '?time=' + Math.floor(Date.now() / 1000))
              )
            : this.getCenterLogo(),
          pageWidth / 2 - 11,
          pageHeight - 12,
          22,
          6
        )
        doc.addImage(
          pdfSetting?.data?.img_logo_base
            ? String(await this.createFile(pdfSetting.data.img_logo_base + '?time=' + Math.floor(Date.now() / 1000)))
            : this.getFooterLogo(),
          pageWidth - 26,
          pageHeight - 6,
          24,
          4
        )
        doc.addImage(this.getHeaderLogo(), 12, 2, 8, 8)
      }
    },
  },
}
