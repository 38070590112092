import { EMPTY_IMG } from '../Mixins/media.js'
import { JA_LANG } from '../Mixins/ja.js'
export default {
  methods: {
    genSlideOverviewAcc(pptx, dataSlideAccount, text, title) {
      let slideOverviewAcc = pptx.addSlide({ masterName: 'MASTER_SLIDE' })
      slideOverviewAcc.addText(text, {
        shape: pptx.shapes.RECTANGLE,
        x: 0,
        y: 0.25,
        w: 2.3,
        h: 0.55,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 17.7,
      })
      // one
      let sumReachLastMonth = dataSlideAccount.sumData.dataLastMonth.postAccountDetail.sumReach
      slideOverviewAcc.addText(String(sumReachLastMonth), {
        x: 5.34,
        y: 2.41,
        w: 1.05,
        h: 0.3,
        align: 'center',
        bold: false,
        color: '060607',
        fontSize: 10.5,
      })

      let sumReachSeletedMonth = dataSlideAccount.sumData.dataSeletedMonth.postAccountDetail.sumReach
      slideOverviewAcc.addText(String(sumReachSeletedMonth), {
        x: 6.8,
        y: 2.35,
        w: 1.16,
        h: 0.4,
        align: 'center',
        bold: true,
        color: 'E7289E',
        fontSize: 12,
      })

      slideOverviewAcc.addShape(pptx.shapes.LINE, {
        x: 6.32,
        y: 2.56,
        w: 0.5,
        h: 0.0,
        line: { width: 6, endArrowType: 'triangle', color: 'D8D8D8' },
      })

      // two
      let sumLikeLastMonth = dataSlideAccount.sumData.dataLastMonth.postAccountDetail.sumLike
      slideOverviewAcc.addText(String(sumLikeLastMonth), {
        x: 8.01,
        y: 2.41,
        w: 1.01,
        h: 0.3,
        align: 'center',
        bold: false,
        color: '060607',
        fontSize: 10.5,
      })

      let sumLikeSeletedMonth = dataSlideAccount.sumData.dataSeletedMonth.postAccountDetail.sumLike
      slideOverviewAcc.addText(String(sumLikeSeletedMonth), {
        x: 9.59,
        y: 2.36,
        w: 0.99,
        h: 0.4,
        align: 'center',
        bold: true,
        color: 'E7289E',
        fontSize: 12,
      })

      slideOverviewAcc.addShape(pptx.shapes.LINE, {
        x: 9.0,
        y: 2.56,
        w: 0.5,
        h: 0.0,
        line: { width: 6, endArrowType: 'triangle', color: 'D8D8D8' },
      })

      // three
      let sumCommentLastMonth = dataSlideAccount.sumData.dataLastMonth.postAccountDetail.sumComment
      slideOverviewAcc.addText(String(sumCommentLastMonth), {
        x: 5.33,
        y: 4.17,
        w: 1.05,
        h: 0.3,
        align: 'center',
        bold: false,
        color: '060607',
        fontSize: 10.5,
      })

      let sumCommentSeletedMonth = dataSlideAccount.sumData.dataSeletedMonth.postAccountDetail.sumComment
      slideOverviewAcc.addText(String(sumCommentSeletedMonth), {
        x: 6.8,
        y: 4.12,
        w: 1.01,
        h: 0.4,
        align: 'center',
        bold: true,
        color: 'E7289E',
        fontSize: 12,
      })

      slideOverviewAcc.addShape(pptx.shapes.LINE, {
        x: 6.32,
        y: 4.3,
        w: 0.5,
        h: 0.0,
        line: { width: 6, endArrowType: 'triangle', color: 'D8D8D8' },
      })

      // four
      let sumSavedLastMonth = dataSlideAccount.sumData.dataLastMonth.postAccountDetail.sumSaved
      slideOverviewAcc.addText(String(sumSavedLastMonth), {
        x: 8.13,
        y: 4.17,
        w: 1.01,
        h: 0.3,
        align: 'center',
        bold: false,
        color: '060607',
        fontSize: 10.5,
      })

      let sumSavedSeletedMonth = dataSlideAccount.sumData.dataSeletedMonth.postAccountDetail.sumSaved
      slideOverviewAcc.addText(String(sumSavedSeletedMonth), {
        x: 9.59,
        y: 4.12,
        w: 0.99,
        h: 0.4,
        align: 'center',
        bold: true,
        color: 'E7289E',
        fontSize: 12,
      })

      slideOverviewAcc.addShape(pptx.shapes.LINE, {
        x: 9.08,
        y: 4.3,
        w: 0.5,
        h: 0.0,
        line: { width: 6, endArrowType: 'triangle', color: 'D8D8D8' },
      })

      // five
      let sumFollowerLastMonth = dataSlideAccount.sumData.dataLastMonth.accountDetail.followerOfLastDay
      slideOverviewAcc.addText(String(sumFollowerLastMonth), {
        x: 5.35,
        y: 5.72,
        w: 1.05,
        h: 0.3,
        align: 'center',
        bold: false,
        color: '060607',
        fontSize: 10.5,
      })

      let sumFollowerSeletedMonth = dataSlideAccount.sumData.dataSeletedMonth.accountDetail.followerOfLastDay
      slideOverviewAcc.addText(String(sumFollowerSeletedMonth), {
        x: 6.8,
        y: 5.65,
        w: 1.01,
        h: 0.4,
        align: 'center',
        bold: true,
        color: 'E7289E',
        fontSize: 12,
      })

      slideOverviewAcc.addShape(pptx.shapes.LINE, {
        x: 6.32,
        y: 5.85,
        w: 0.5,
        h: 0.0,
        line: { width: 6, endArrowType: 'triangle', color: 'D8D8D8' },
      })

      // six
      let egement_lastMonth = dataSlideAccount.sumData.dataLastMonth.postAccountDetail.sumInteractive
      let egementLastMonth = 0
      if (!isNaN(egement_lastMonth)) {
        egementLastMonth =
          Number.isInteger(egement_lastMonth) == true ? egement_lastMonth : egement_lastMonth.toFixed(2)
      }
      let egement_curent = dataSlideAccount.sumData.dataSeletedMonth.postAccountDetail.sumInteractive

      slideOverviewAcc.addText(String(egementLastMonth) + '%', {
        x: 8.07,
        y: 5.71,
        w: 1.01,
        h: 0.3,
        align: 'center',
        bold: false,
        color: '060607',
        fontSize: 10.5,
      })

      let egementCurent = 0
      if (!isNaN(egement_lastMonth)) {
        egementCurent = Number.isInteger(egement_curent) == true ? egement_curent : egement_curent.toFixed(2)
      }

      slideOverviewAcc.addText(String(egementCurent) + '%', {
        x: 9.59,
        y: 5.65,
        w: 0.99,
        h: 0.4,
        align: 'center',
        bold: true,
        color: 'E7289E',
        fontSize: 12,
      })

      slideOverviewAcc.addShape(pptx.shapes.LINE, {
        x: 9.0,
        y: 5.85,
        w: 0.5,
        h: 0.0,
        line: { width: 6, endArrowType: 'triangle', color: 'D8D8D8' },
      })

      slideOverviewAcc.addText(title, {
        x: 2.45,
        y: 0.25,
        w: 8.01,
        h: 0.55,
        align: 'left',
        bold: true,
        color: '303030',
        fontSize: 22,
      })

      const OPTS_CHART = {
        x: 0.44,
        y: 1.09,
        w: 4.48,
        h: 2.33,
        catAxisLabelColor: '787878',
        catAxisLabelFontSize: 8,
        catAxisOrientation: 'minMax',
        showTitle: false,
        title: 'フォロワー増減数',
        titleColor: '787878',
        titleFontSize: 14,
        showLegend: true,
        legendPos: 'b',
        legendFontSize: 8,
        legendColor: '787878',
        valAxes: [
          {
            showValAxisTitle: false,
            valAxisTitle: 'Primary Value Axis',
          },
          {
            showValAxisTitle: false,
            valAxisTitle: 'Secondary Value Axis',
            catAxisOrientation: 'maxMin',
            valAxisMinVal: 0,
            valGridLine: { style: 'none' },
          },
        ],
        catAxes: [
          {
            catAxisTitle: 'Primary Category Axis',
          },
          {
            catAxisHidden: true,
          },
        ],
        valAxisMinVal: 0,
      }
      let labels = dataSlideAccount.dataLabels
      let chartData = [
        {
          type: pptx.charts.LINE,
          data: [
            {
              name: 'フォロワー数',
              labels: labels,
              values: dataSlideAccount.dataFollowers,
            },
          ],
          options: {
            chartColors: ['3e95cd'],
          },
        },
        {
          type: pptx.charts.LINE,
          data: [
            {
              name: 'エンゲージメント率( %)',
              labels: labels,
              values: this.mapDataEngagement(dataSlideAccount.dataEngagement),
            },
          ],
          options: {
            chartColors: ['e7289e'],
            lineDataSymbol: 'none',
            secondaryValAxis: !!OPTS_CHART.valAxes,
            secondaryCatAxis: !!OPTS_CHART.catAxes,
          },
        },
      ]
      slideOverviewAcc.addChart(chartData, OPTS_CHART)

      // pieChart
      slideOverviewAcc.addText('フォロワー男女比', {
        x: 0.35,
        y: 3.56,
        w: 1.35,
        h: 0.2,
        bold: true,
        align: 'left',
        color: '303030',
        fontSize: 7,
      })

      const COLORS_SPECTRUM = ['e7289e', '1E88BE', 'C1CCD5']
      let dataPieChart = this.mapDataPieChart(dataSlideAccount.accountInsights)
      if (dataPieChart.length == []) {
        slideOverviewAcc.addImage({ path: EMPTY_IMG, x: 0.44, y: 4.27, w: 1.25, h: 1.25 })
      } else {
        const dataChartPieStat = [
          {
            labels: ['女性', '男性', 'その他'],
            values: dataPieChart,
          },
        ]
        slideOverviewAcc.addChart(pptx.charts.PIE, dataChartPieStat, {
          x: 0.44,
          y: 3.72,
          w: 1.43,
          h: 2.35,
          showPercent: false,
          showLegend: true,
          legendColor: '787878',
          legendPos: 'b',

          dataBorder: { pt: 1, color: 'ffffff' },

          chartColors: COLORS_SPECTRUM,
        })
      }

      //stack-bar
      slideOverviewAcc.addText('フォロワー年齢層', {
        x: 2,
        y: 3.56,
        w: 1.35,
        h: 0.2,
        bold: true,
        align: 'left',
        color: '303030',
        fontSize: 7,
      })

      let dataStackBarChart = this.mapDataStackBar(dataSlideAccount.accountInsights)
      if (dataStackBarChart.length == []) {
        slideOverviewAcc.addImage({ path: EMPTY_IMG, x: 2.06, y: 4.27, w: 1.25, h: 1.25 })
      } else {
        let optsChartBar1 = {
          x: 1.98,
          y: 3.72,
          w: 1.43,
          h: 2.35,
          barDir: 'bar',
          barGrouping: 'stacked',
          chartColors: COLORS_SPECTRUM,

          catAxisOrientation: 'maxMin',
          catAxisLabelColor: '787878',
          catAxisLabelFontSize: 6,
          catAxisLabelFontBold: true,

          valAxisLabelFontSize: 6,

          dataLabelColor: '787878',
          showPercent: false,
          showLegend: true,
          legendColor: '787878',
          legendPos: 'b',
          showValue: false,
        }
        slideOverviewAcc.addChart(pptx.charts.BAR, dataStackBarChart, optsChartBar1)
      }
      // horizontal-chart
      slideOverviewAcc.addText('フォロワーの地域', {
        x: 3.5,
        y: 3.56,
        w: 1.35,
        h: 0.2,
        bold: true,
        align: 'left',
        color: '303030',
        fontSize: 7,
      })
      let dataHorizontalChart = this.mapDataHorizontal(dataSlideAccount.accountInsights)
      if (dataHorizontalChart.length == []) {
        slideOverviewAcc.addImage({ path: EMPTY_IMG, x: 3.6, y: 4.27, w: 1.25, h: 1.25 })
      } else {
        let optsChartBar2 = {
          x: 3.5,
          y: 3.72,
          w: 1.43,
          h: 2.35,
          barDir: 'bar',
          chartColors: ['1E88BE'],

          catAxisOrientation: 'maxMin',
          catAxisLabelColor: '787878',
          catAxisLabelFontSize: 6,
          catAxisLabelFontBold: true,

          valAxisLabelFontSize: 6,

          dataLabelColor: '787878',
          showPercent: false,
          showLegend: true,
          legendColor: '787878',
          legendPos: 'b',
          showValue: false,
        }
        slideOverviewAcc.addChart(pptx.charts.BAR, dataHorizontalChart, optsChartBar2)
      }

      slideOverviewAcc.addText('各指標の先月比較', {
        shape: pptx.shapes.RECTANGLE,
        x: 5.32,
        y: 1.08,
        w: 5.26,
        h: 0.33,
        bold: true,
        color: 'ffffff',
        fill: { color: 'e7289e' },
        align: 'center',
        valign: 'middle',
        fontSize: 14,
      })
      slideOverviewAcc.addText('リーチ数', {
        shape: pptx.shapes.RECTANGLE,
        x: 5.34,
        y: 1.58,
        w: 2.44,
        h: 0.53,
        bold: true,
        align: 'center',
        fontSize: 14,
        line: { color: '303030', size: 1 },
      })
      slideOverviewAcc.addText('いいね数', {
        shape: pptx.shapes.RECTANGLE,
        x: 8.14,
        y: 1.58,
        w: 2.44,
        h: 0.53,
        bold: true,
        align: 'center',
        fontSize: 14,
        line: { color: '303030', size: 1 },
      })
      slideOverviewAcc.addText('コメント数', {
        shape: pptx.shapes.RECTANGLE,
        x: 5.34,
        y: 3.25,
        w: 2.44,
        h: 0.53,
        bold: true,
        align: 'center',
        fontSize: 14,
        line: { color: '303030', size: 1 },
      })
      slideOverviewAcc.addText('保存数', {
        shape: pptx.shapes.RECTANGLE,
        x: 8.14,
        y: 3.25,
        w: 2.44,
        h: 0.53,
        bold: true,
        align: 'center',
        fontSize: 14,
        line: { color: '303030', size: 1 },
      })
      slideOverviewAcc.addText('フォロワー数', {
        shape: pptx.shapes.RECTANGLE,
        x: 5.34,
        y: 4.93,
        w: 2.44,
        h: 0.53,
        bold: true,
        align: 'center',
        fontSize: 14,
        line: { color: '303030', size: 1 },
      })
      slideOverviewAcc.addText('エンゲージメント率', {
        shape: pptx.shapes.RECTANGLE,
        x: 8.14,
        y: 4.93,
        w: 2.44,
        h: 0.53,
        bold: true,
        align: 'center',
        fontSize: 14,
        line: { color: '303030', size: 1 },
      })
      slideOverviewAcc.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.44,
        y: 6.29,
        w: 10.14,
        h: 1.56,
        rectRadius: 0.2,
        line: 'e7289e',
        lineSize: 1,
      })
      return slideOverviewAcc
    },

    mapDataHorizontal(dataSlideAccount) {
      let result = [
        {
          name: 'フォロワー',
          labels: [],
          values: [],
        },
      ]

      let listCountry = []
      dataSlideAccount.forEach((accountInsight) => {
        if (accountInsight.audience_city) {
          listCountry.push(accountInsight.audience_city)
        }
      })

      let totalDataCountry = {}
      if (listCountry.length == 0) {
        result = []
      } else {
        listCountry.forEach((country) => {
          const dataCountry = JSON.parse(country)

          for (const [index, item] of Object.entries(dataCountry)) {
            if (totalDataCountry.hasOwnProperty(index)) {
              totalDataCountry[index] += item
            } else {
              totalDataCountry[index] = item
            }
          }
        })

        for (const [key, item] of Object.entries(totalDataCountry)) {
          if (!result[0].labels.includes(key)) {
            result[0].labels.push(key)
          }
        }

        for (const city of result[0].labels) {
          result[0].values = [...result[0].values, totalDataCountry[city]].slice(0, 7)
        }
      }
      if (result.length > 0) {
        let translatedLabels = result[0].labels.slice(0, 7)
        translatedLabels.forEach((item, index) => {
          for (const [key, value] of Object.entries(JA_LANG)) {
            if (item === key) {
              translatedLabels[index] = value
              break
            } else {
              translatedLabels[index] = item
            }
          }
        })
        result[0].labels = [...new Set(translatedLabels)]
      }
      return result
    },

    mapDataStackBar(dataSlideAccount) {
      this.listAges = []
      dataSlideAccount.forEach((account) => {
        if (account.audience_gender_age) {
          this.listAges.push(account.audience_gender_age)
        }
      })

      let dataLabel = []
      let totalDataAge = {}
      let result = []
      if (this.listAges.length == 0) {
        result = []
      } else {
        this.listAges.forEach((listAge) => {
          const dataAge = JSON.parse(listAge)

          for (const [index, item] of Object.entries(dataAge)) {
            //add labels
            const key = index.split('.')[1]
            if (!dataLabel.includes(key)) {
              dataLabel.push(key) || 0
            }

            //Calculation total
            if (totalDataAge.hasOwnProperty(index)) {
              totalDataAge[index] += item
            } else {
              totalDataAge[index] = item
            }
          }
        })
        this.showData = this.listAges.length > 0
        dataLabel = dataLabel.sort()
        //Set data gender by age default = 0
        let dataInit = []
        for (let val of dataLabel) {
          dataInit.push(0)
        }

        let dataWomen = [...dataInit],
          dataMen = [...dataInit],
          dataOther = [...dataInit]

        for (const [index, item] of Object.entries(totalDataAge)) {
          const indexOfLabel = dataLabel.indexOf(index.split('.')[1])
          if (index.includes('F.')) {
            dataWomen[indexOfLabel] = item
          }
          if (index.includes('M.')) {
            dataMen[indexOfLabel] = item
          }
          if (index.includes('U.')) {
            dataOther[indexOfLabel] = item
          }
        }

        result = [
          {
            name: '女性',
            labels: dataLabel,
            values: dataWomen,
          },
          {
            name: '男性',
            labels: dataLabel,
            values: dataMen,
          },
          {
            name: 'その他',
            labels: dataLabel,
            values: dataOther,
          },
        ]
      }
      return result
    },
    mapDataEngagement(arr) {
      let data = arr.map((item) => {
        return item * 100
      })
      return data
    },
    mapDataPieChart(dataSlideAccount) {
      this.listDataGender = []

      dataSlideAccount.forEach((account) => {
        if (account.audience_gender_age) {
          this.listDataGender.push(account.audience_gender_age)
        }
      })

      let dataWomen = 0
      let dataMen = 0
      let dataOther = 0
      let dataGenderForChart = []
      if (this.listDataGender.length == 0) {
        dataGenderForChart = []
      } else {
        this.listDataGender.forEach((listData) => {
          const isJson = this.IsJsonString(listData)
          if (!isJson) return

          for (const [index, item] of Object.entries(JSON.parse(listData))) {
            if (index.includes('F.')) {
              dataWomen += item
            }
            if (index.includes('M.')) {
              dataMen += item
            }
            if (index.includes('U.')) {
              dataOther += item
            }
          }
        })
        const totalDataGender = dataWomen + dataMen + dataOther
        dataGenderForChart = [
          ((dataWomen / totalDataGender) * 100).toFixed(2),
          ((dataMen / totalDataGender) * 100).toFixed(2),
          ((dataOther / totalDataGender) * 100).toFixed(2),
        ]
      }

      return dataGenderForChart
    },

    IsJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
  },
}
