<template>
    <DrashBoard>
        <LinkInstagram v-if="!showLinkInstagram" @onInstaSubmitSuccess="onInstaSubmitSuccess" />
        <template v-slot:breakcumb>
            <BreadCrumb>
                <li class="mr-4">
                    <Link :href="route('admin.post.index')" class="font-normal breakcumb-active">投稿分析</Link>
                </li>
            </BreadCrumb>
        </template>
        <template v-slot:tabs>
            <div class="md:mt-12 md:ml-10 tabs sm:mt-3 ml:0 overflow-auto">
                <ul class="flex justify-start w-[500px] md:w-full">
                    <li @click="changeTab(null)" :class="statusSelect == null ? 'active' : ''"
                        class="px-10 relative pb-5 px-sm">
                        投稿一覧
                    </li>
                    <li @click="changeTab(0)" :class="statusSelect == 0 ? 'active' : ''"
                        class="px-10 relative pb-5 px-sm">
                        投稿済み一覧
                    </li>
                    <li @click="changeTab(1)" :class="statusSelect == 1 ? 'active' : ''"
                        class="px-10 relative pb-5 px-sm">
                        予約投稿一覧
                    </li>
                    <li @click="changeTab(2)" :class="statusSelect == 2 ? 'active' : ''"
                        class="px-10 relative pb-5 px-sm">
                        下書き保存一覧
                    </li>
                    <li @click="changeTab(3)" :class="statusSelect == 3 ? 'active' : ''"
                        class="flex items-center px-10 relative pb-5 px-sm">
                        <div>
                            投稿失敗一覧
                        </div>
                        <div v-if="this.$page?.props?.auth?.errorPost > 0">
                            <div class="w-6 h-6 bg-[#ff0000] text-white text-[13px] ml-1 flex justify-center items-center rounded-[50%]">{{ this.$page?.props?.auth?.errorPost }}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </template>
        <template v-slot:manual>
            <UserManual :isShow="isShowManulal" :content="arrContent" @closeManualPopup="toogleManual" />
        </template>
        <div class="bg-background h-screen">
            <UserNav />
            <div class="statistic px-10 px-sm bg-background">
                <div class="flex justify-between items-center md:block">
                    <div class="flex items-center md:relative">
                        <div class="flex items-center md:pt-10 md:pb-12.5 sm:pt-5 sm:pb-5">

                            <h2 class="text-button_pink font-semibold text-3sm">{{
                                    titlePage
                            }}</h2>
                            <div v-if="hasManualIcon" class="ml-2 rounded-[50%] w-6 h-6 cursor-pointer"
                                @click="toogleManual">
                                <img :src="suggestIcon" />
                            </div>
                        </div>
                    </div>
                    <Link href="/post/create" as="button" type="button"
                        class="text-2sm bg-blue-600 text-white px-7 py-2.5 w-max rounded md:ml-auto h-10 md:hidden flex">
                    +新規作成
                    </Link>
                </div>
                <div class="grid md:grid-cols-2 sm:grid-cols-1 pb-3.5">
                    <div class="flex">
                        <div class="md:mr-5 sm:mr-2 md:w-96 sm:w-full md:mb-0 sm:mb-2 md:h-10">
                            <el-input v-model="searchKeyword" placeholder="検索キーワードを入力してください" size="large"
                                class="text-2sm" clearable @keyup.enter="getListPost" />
                        </div>
                        <el-button class="el-button__primary md:mb-0 md:!h-10 md:w-20 sm:mb-2" size="large"
                            @click="getListPost">
                            <span class="text-white">検索</span>
                        </el-button>
                    </div>
                    <Link href="/post/create" as="button" type="button"
                        class="text-2sm bg-blue-600 text-white px-7 py-2.5 w-max rounded md:ml-auto h-10 md:flex hidden">
                    +新規作成
                    </Link>
                </div>

                <!-- component -->
                <section class="mt-2.5">
                    <div class="w-full overflow-hidden shadow-lg">
                        <div class="w-full overflow-x-auto">
                            <table class="table-fixed w-full">
                                <thead>
                                    <tr class="text-2sm font-semibold text-left bg-gray-100 border-b border-gray-600">
                                        <th class="width-nomal text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2"
                                            :style="{ width: statusSelect == 0 ? '135px' : '12%' }">
                                            投稿画像
                                        </th>
                                        <th class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2"
                                            style="width: 70px">
                                            タイプ
                                        </th>
                                        <th class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2"
                                            style="width: 133px">
                                            投稿内容
                                        </th>
                                        <th class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2"
                                            v-for="(label, index) in label" :key="index" :style="{
                                                width: label.width + 'px',
                                                minWidth: (label?.minWidth ? label.minWidth : 150) + 'px',
                                            }">
                                            {{ label.text }}
                                        </th>
                                        <th v-if="statusSelect == 0"
                                            class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2"
                                            style="width: 108px">
                                            初速分析
                                        </th>
                                        <th v-if="statusSelect == 0"
                                            class="text-2sm py-4 font-normal bg-header_table text-white border-r text-center rounded-md border-2 w-[90px]"
                                            style="width: 108px">
                                            Instaページ
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-body_table">
                                    <tr class="text-2sm border-b hover:bg-gray-200" v-for="(item, index) in tableData"
                                        :key="index">
                                        <td class="py-4 flex items-center justify-center px-3">
                                            <video v-if="checkTypeFile(item?.is_carousel ? item.files[0] : item.file)"
                                                controls width="112" style="height: 64px">
                                                <source :src="item?.is_carousel ? item.files[0] : item.file"
                                                    type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                            <img v-else class="w-28 h-16"
                                                :src="item?.is_carousel ? item.files[0] : item.file" alt="" />
                                        </td>
                                        <td class="py-2">
                                            <p class="post-content showFullText break-all">
                                                {{ item.post_category }}
                                            </p>
                                        </td>
                                        <td class="py-2">
                                            <p class="post-content showFullText break-all">
                                                {{ item.content }}
                                            </p>
                                            <p @click="viewDetailContent(item)"
                                                class="text-xs text-button_profile font-semibold text-right block mt-2 hover:cursor-pointer">
                                                全文を見る
                                            </p>
                                        </td>
                                        <td class="text-center" v-for="(label, index) in label" :key="index">
                                            <p v-if="label.key != 'hashtag'">
                                                <span
                                                    v-if="label.key === 'updated_at' && item.status === 2">下書き保存</span>
                                                <span v-else-if="label.key === 'updated_at' && item.status === 1">
                                                    {{ item.scheduled_time }}
                                                </span>
                                                <span v-else>{{ item[label.key] }}</span>
                                            </p>
                                            <!-- has button delete post -->
                                            <p v-if="label.key == 'delete' && statusSelect == 1"
                                                @click="deletePost(item)"
                                                class="rounded button-shadow w-16 px-2 py-2 bg-button_table text-white block mx-auto cursor-pointer">
                                                削除
                                            </p>
                                            <Link v-if="label.key == 'edit' && statusSelect == 2"
                                                :href="'post/edit/' + item.id" as="button" type="button"
                                                class="rounded button-shadow w-16 px-2 py-2 bg-blue-600 text-white block mx-auto cursor-pointer"
                                                :disabled="item.has_posts">
                                            編集
                                            </Link>

                                            <p class="justify-center flex flex-wrap break-all paragraph-overflow"
                                                v-if="label.key == 'hashtag'">
                                                {{ item.hashtag }}
                                            </p>
                                        </td>
                                        <td v-if="statusSelect == 0">
                                            <Link href="post/analytic"
                                                :data="{ id: item.id, tab: tab, page: currentPage }"
                                                class="rounded button-shadow px-5 py-2 bg-blue-600 text-white block mx-auto"
                                                as="button"
                                                :disabled="checkPlan?.initial_velocity_analysis == 0 ? 'disabled' : false">
                                            確認する
                                            </Link>
                                        </td>
                                        <td v-if="statusSelect == 0">
                                            <a :href="item.permalink" target="_blank" as="button" style="width: 70px">
                                                <div class="flex justify-center instaColor">
                                                    <i class="fab fa-instagram text-3xl cursor-pointer"></i>
                                                </div>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>

                <!-- dialog detail content -->
                <el-dialog v-model="dialogOpen" width="50%" title="投稿内容" append-to-body>
                    <PopupReviewPost :content="dataContent.content"
                        :file="dataContent?.is_carousel ? dataContent.files[0] : dataContent.file"
                        :fileType="getTypeFile(dataContent?.is_carousel ? dataContent.files[0] : dataContent.file)"
                        :carouselItems="dataContent?.is_carousel ? dataContent.files : []"
                        :hashtag="dataContent.hashtag" :tag="dataContent.tag" :username="accountName" />
                </el-dialog>
                <!-- end dialog detail content -->
                <el-empty v-if="tableData.length == 0" description="データはありません" />
                <div v-if="tableData.length != 0" class="pt-4 pb-9 flex justify-center">
                    <el-pagination v-if="totalData > perPage" :page-size="Number(perPage)" :pager-count="5"
                        layout="prev, pager, next" :total="Number(totalData)" :current-page="currentPage || 1"
                        @current-change="handleCurrentChange" class="is-background" />
                </div>
            </div>
        </div>
        <Loadding v-if="!isloading" />
    </DrashBoard>
</template>

<script>
import BreadCrumb from '@/Components/Breadcrumb.vue'
import Loadding from '@/Components/Loadding.vue'
import { Link } from '@inertiajs/inertia-vue3'
import Table from '@/Components/Table.vue'
import Pagination from '@/Components/Pagination.vue'
import DrashBoard from '@/Layouts/Authenticated.vue'
import UserNav from '@/Components/UserNav.vue'
import PopupReviewPost from '@/Components/PopupReviewPost.vue'
import * as postAPI from '@/Api/Posts'
import { getUserDetailByAccountId } from '@/Api/Users'
import * as accountAPI from '@/Api/Account'
import LinkInstagram from '@/Components/LinkInstagram.vue'
import axios from 'axios'
import { ElMessageBox } from 'element-plus'
import UserManual from '@/Components/UserManual.vue'

export default {
    props: ['plan', 'tab', 'page'],
    components: {
        Table,
        Pagination,
        DrashBoard,
        UserNav,
        Loadding,
        PopupReviewPost,
        Link,
        BreadCrumb,
        LinkInstagram,
        UserManual
    },
    data() {
        return {
            hasManual: false,
            hasManualIcon: true,
            isShowManulal: false,
            suggestIcon: window.location.origin + '/img/suggestion.png',
            arrContent: ['本画面では過去投稿や予約投稿を「投稿タイプ」「投稿内容」「ハッシュタグ」「投稿日時」の情報と共に一覧で確認できるため、投稿管理に利用しましょう。', '画面右上にある「新規作成」をクリックすることで、新たな投稿を行うことも可能です。', 'また、投稿を探す場合は、画面上部の検索バーからキーワード検索機能を使うことで、指定したキーワードの含まれる投稿を探すことができます。'],
            titlePage: '投稿一覧',
            dataContent: [],
            dialogOpen: false,
            label: [
                { text: 'ハッシュタグ', key: 'hashtag', width: 130 },
                { text: '投稿日時', key: 'updated_at', width: window.innerHeight > 675 ? 60 : 100 },
            ],
            totalData: 1,
            currentPage: 1,
            perPage: '',
            statusHeader: 'タイプ',
            statusSelect: null,
            tableData: [],
            searchKeyword: '',
            isloading: true,
            isSelectStatus: false,
            accountName: '',
            showLinkInstagram: true,
            windowHeight: window.innerHeight,
            checkPlan: '',
            // normalCount: 0
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },

    async created() {
        this.statusSelect = this.tab ? Number(this.tab) : null
        this.currentPage = this.page ?? 1

        this.checkPlan = this.plan
        this.$watch((vm) => [vm.statusSelect, vm.currentPage], this.getListPost)
        this.getListPost(this.searchKeyword, this.statusSelect, this.currentPage)
        this.getAccountPost()
        this.checkLinkInstagram()
        // this.getErrorPost()
    },
    methods: {
        // getErrorPost(){
        //     axios
        //     .get(route('api.get-total-error-post'))
        //     .then((res) => {
        //             this.normalCount = res.data
        //             // this.groupCount = res.data.groupCount
        //         })
        // },
        async deletePost(id) {
            ElMessageBox.confirm('この投稿を削除しても宜しいですか。', '警告', {
                confirmButtonText: '同意',
                cancelButtonText: 'キャンセル',
                type: 'warning',
            }).then(async () => {
                await axios.delete(route('api.posts.destroy', id)).then((res) => {
                    if (res.status) {
                        this.$toast.success('投稿削除しました。')
                        this.getListPost(this.searchKeyword, this.statusSelect, this.currentPage)
                    }
                })
            })
        },
        onResize() {
            this.windowHeight = window.innerHeight
        },
        onInstaSubmitSuccess() {
            this.showLinkInstagram = true
        },
        viewDetailContent(item) {
            this.dataContent = item
            this.dialogOpen = true
        },
        formartStatus: function (status) {
            if (status == 1) {
                return '通常'
            }
            return 'リール'
        },
        formatTime(date) {
            if (!date) {
                return
            }
            let listDate = date.slice(0, 10).split('-')
            let listTime = date.slice(11, 16)
            let dateFormat = listDate[0] + '年' + listDate[1] + '月' + listDate[2] + '日' + ' ' + listTime
            return dateFormat
        },
        getListPost: async function () {
            const posts = await postAPI.getListPostAccount(this.searchKeyword, this.statusSelect, this.currentPage)
            this.tableData = posts.data.data.map((postData) => ({
                id: postData.id,
                tag: this.formartTag(postData.tag),
                permalink: postData.permalink,
                error_ins: postData.error_ins,
                post_category: postData.post_category == 0 ? '通常' : 'リール',
                posted_outside: postData.posted_outside,
                file: postData.file,
                files: postData.files,
                is_carousel: postData.is_carousel,
                status: postData.status,
                hashtag: this.formartHashtag(postData.hashtag),
                updated_at: this.formatTime(postData.updated_at),
                like: this.formatToInteger(postData.like),
                content: postData.content,
                comment: this.formatToInteger(postData.comment),
                saved: this.formatToInteger(postData.saved),
                reach: this.formatToInteger(postData.reach),
                engagement:
                    postData.post_category == 0
                        ? this.formatToFloat(((postData.engagement + Number.EPSILON) * 100) / 100 + '%')
                        : ' - ',
                scheduled_time: this.formatTime(postData.scheduled_time),
            }))
            this.totalData = posts.data.meta.total
            this.currentPage = posts.data.meta.current_page
            this.perPage = posts.data.meta.per_page
        },
        async getAccountPost() {
            const posts = await postAPI.getListPostAccount(this.searchKeyword, this.statusSelect, this.currentPage)
            if (posts.data.data.length > 0) {
                const account = await getUserDetailByAccountId(posts.data.data[0].account_id)
                this.accountName = account.data.data
            }
        },
        formartHashtag(hashtag) {
            if (hashtag) {
                return hashtag.toString().replaceAll(',', ' ')
            }
        },
        formartTag(tag) {
            if (tag) {
                return tag
                    .map((tags) => '@' + tags.username)
                    .toString()
                    .replaceAll(',', ' ')
            }
            return tag
        },
        isDataArray: function (label) {
            if (label == 'hashtag' || label == 'tag' || label == 'engagement') {
                return false
            }
            return true
        },
        checkTypeFile(filePath) {
            if (!filePath) return
            const arr = filePath.split('.')
            if (arr[arr.length - 1] == 'mp4' || arr[arr.length - 1] == 'm4v') {
                return true
            }
            return false
        },
        changeTab(status) {
            this.$inertia.get(route('admin.post.index'), { tab: status }, { replace: true, preserveState: true })
            this.isShowManulal = false
            this.statusSelect = status
            this.currentPage = 1
        },
        getTypeFile(fileName) {
            return fileName.split('.').pop()
        },
        // link instagram
        async checkLinkInstagram() {
            const check = await accountAPI.checkInsAccount()
            if (check.data) {
                this.showLinkInstagram = true
            } else {
                this.showLinkInstagram = false
            }
        },
        handleCurrentChange(value) {
            this.currentPage = value
        },
        toogleManual() {
            this.isShowManulal = !this.isShowManulal
        }
    },
    watch: {
        searchKeyword: function () {
            if (this.searchKeyword == '') {
                this.getListPost()
            }
        },
        statusSelect: function () {
            switch (this.statusSelect) {
                case null: {
                    this.label = [
                        { text: 'ハッシュタグ', key: 'hashtag', width: 130 },
                        { text: '投稿日時', key: 'updated_at', width: this.windowHeight > 675 ? 60 : 100 },
                    ]
                    this.titlePage = '投稿一覧'
                    this.hasManualIcon = true
                    this.arrContent = ['本画面では過去投稿や予約投稿を「投稿タイプ」「投稿内容」「ハッシュタグ」「投稿日時」の情報と共に一覧で確認できるため、投稿管理に利用しましょう。', '画面右上にある「新規作成」をクリックすることで、新たな投稿を行うことも可能です。', 'また、投稿を探す場合は、画面上部の検索バーからキーワード検索機能を使うことで、指定したキーワードの含まれる投稿を探すことができます。']
                    break
                }
                case 0: {
                    this.label = [
                        { text: 'ハッシュタグ', key: 'hashtag', width: 130 },
                        { text: '投稿日時', key: 'updated_at', width: 140 },
                        { text: 'いいね数', key: 'like', width: 125 },
                        { text: 'コメント数', key: 'comment', width: 105 },
                        { text: '保存数', key: 'saved', width: 105 },
                        { text: 'リーチ数', key: 'reach', width: 85 },
                        { text: 'エンゲージメント率', key: 'engagement', width: 150 },
                        { text: '投稿形式', key: 'posted_outside', width: 150 },
                    ]
                    this.titlePage = '投稿済み一覧'
                    this.hasManualIcon = true
                    this.arrContent = ['投稿済み一覧では過去投稿のエンゲージメント推移（いいね数、コメント数、リーチ数）を確認・分析することが可能です。', 'エンゲージメントを確認し、投稿に対する関心度を確認しましょう。', 'エンゲージメントを高めることで、より多くのユーザーに閲覧されやすくなります。', 'また、表の右側にある初速分析のボタンをクリックすることで、投稿されてから24時間以内のエンゲージメントを詳しく分析することができます。']
                    break
                }
                case 1: {
                    this.label = [
                        { text: 'ハッシュタグ', key: 'hashtag', width: 130 },
                        { text: '投稿日時', key: 'scheduled_time', width: 130 },
                        { text: '削除', key: 'delete', width: 60 },
                    ]
                    this.titlePage = '予約投稿一覧'
                    this.hasManualIcon = false
                    break
                }
                case 2: {
                    this.label = [
                        { text: 'ハッシュタグ', key: 'hashtag', width: 130 },
                        { text: '投稿日時', key: 'updated_at', width: this.windowHeight > 675 ? 60 : 100 },
                        { text: '操作', key: 'edit', width: 60 },
                    ]
                    this.hasManualIcon = false
                    this.titlePage = '下書き保存一覧'
                    break
                }
                case 3: {
                    this.label = [
                        { text: 'エラーメッセージ', key: 'error_ins' },
                        { text: 'ハッシュタグ', key: 'hashtag', width: 130 },
                        { text: '投稿日時', key: 'updated_at', align: 'center', width: this.windowHeight > 678 ? 60 : 100 },
                    ]
                    this.titlePage = '投稿失敗一覧'
                    this.hasManualIcon = true
                    this.arrContent = ['<p>失敗した投稿の一覧を確認することができます。</p>' + '<p>投稿が失敗している場合、下記の要因が考えられます。</p>', '<ul style="list-style: inside;font-weight: bold;" >' +
                        '<li style="font-size: 13px;">' + 'Instagramとの連携が外れている' + '</li>' +
                        '<li style="font-size: 13px;">' + '投稿内容がコミュニティガイドラインを違反している' + '</li>' +
                        '<li style="font-size: 13px;">' + 'アクションブロックがされている' + '</li>' +
                        '<li style="font-size: 13px;">' + 'ハッシュタグが30件以上追加されている' + '</li>' +
                        '<li style="font-size: 13px;">' + '著作権に違反している' + '</li>'
                        + '</ul>', '上記ご確認頂き、解決できない場合はサポートへお問い合わせください。']

                    break
                }
                default:
                    this.hasManualIcon = false
                    this.label = [
                        { text: 'ハッシュタグ', key: 'hashtag', width: 130 },
                        { text: '投稿日時', key: 'updated_at', width: this.windowHeight > 675 ? 60 : 100 },
                    ]
            }
        },
    },
}
</script>

<style lang="scss" scoped>
th {
    ul {
        padding: 0;
        top: 120%;
        border: 1px solid #ebebeb;

        &:hover {
            cursor: pointer;
        }

        li {
            padding: 0.5rem 0;
            transition: all 0.3s;
            color: #020202;

            &:hover {
                color: #fff;
                background: #5264cd;
                cursor: pointer;
            }
        }
    }
}

.width-nomal {
    width: 12%;
}

@media (max-width: 1024px) {
    .width-nomal {
        width: 130px;
    }

    th:last-child {
        width: 130px !important;
    }
}

.el-select-dropdown__item {
    width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
</style>

<style>
.el-button__primary {
    background-color: #5264cd !important;
    border: 1px solid #5264cd !important;
}

.el-button__primary span {
    color: white !important;
}

/* .el-message-box {
  width: 50%;
} */
.el-overlay {
    opacity: 1;
}

.showFullText {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

.instaColor {
    color: #be3d8f;
}

.paragraph-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
